import { driverLeave } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getDriverLeaveList(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(driverLeave.driverLeaveBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getDriverLeaveOptions(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .options(driverLeave.driverLeaveBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getDriverLeaveObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${driverLeave.driverLeaveBase}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  addDriverLeave(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(driverLeave.driverLeaveBase, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  updateDriverLeave(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${driverLeave.driverLeaveBase}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  partiallyUpdateDriverLeave(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${driverLeave.driverLeaveBase}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
