<template>
  <v-tooltip :bottom="bottom" :top="top" :left="left" :right="right">
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on" :class="tooltipClass">
        {{ tooltipText }}
      </span>
    </template>
    <span>{{ tooltipTitle }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    tooltipTitle: {
      type: String,
      required: true,
    },
    tooltipText: {
      type: [Number, String],
      required: true,
    },
    bottom: {
      type: Boolean,
    },
    top: {
      type: Boolean,
    },
    left: {
      type: Boolean,
    },
    right: {
      type: Boolean,
    },
    tooltipClass: {
      type: String,
    },
  },
};
</script>
