<template>
  <div>
    <v-dialog v-model="ShowBulkRemarks" max-width="500">
      <v-card>
        <v-card-title class="secondary white--text">
          <span>Remarks</span>

          <v-spacer></v-spacer>

          <v-btn
            icon
            @click="(ShowBulkRemarks = false), (formData[fieldKey] = null)"
          >
            <v-icon class="white--text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-2">
          <v-form v-model="isValid" v-if="ShowBulkRemarks">
            <v-row class="my-0">
              <v-col cols="12" class="Form-Columns">
                <InputField
                  :label="`${label}${isRequiredRemarks ? '*' : ''}`"
                  :required="isRequiredRemarks"
                  :rules="
                    isRequiredRemarks
                      ? [(val) => !!val || 'Remarks is Required!']
                      : []
                  "
                  hide-details="auto"
                  v-model="formData[fieldKey]"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions class="justify-end">
          <v-btn
            depressed
            color="primary"
            :disabled="!isValid"
            @click="submitbulkDaRemarks()"
          >
            <span>Submit</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";

export default {
  components: { InputField },
  props: {
    value: {
      type: Boolean,
    },
    formDetails: {
      type: Object,
    },
    fieldKey: {
      type: String,
    },
    label: {
      type: String,
    },
    isRequiredRemarks: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      formErrors: {},
      isValid: true,
    };
  },
  computed: {
    formData: {
      get() {
        return this.formDetails;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    ShowBulkRemarks: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    submitbulkDaRemarks() {
      this.$confirm({
        status: "secondery",
        title: "Confirmation",
        text: "Are you sure you want to cancel?",
      })
        .then(() => {
          this.$emit("submitRemarks");
          

        })
        .catch(() => {
          console.log("Rejected");
        });
    },
  },

  mounted() {},
};
</script>
