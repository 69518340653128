var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-h5"},[_vm._v("Spot Rate")])]),_c('v-col',{staticClass:"px-0",attrs:{"cols":"12"}},[_c('v-divider')],1),_c('v-col',{staticClass:"Height-Class pa-0",attrs:{"cols":"12","md":"12"}},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-form',{ref:"generateQuotesForm",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('MultiSelect',{attrs:{"hide-details":"auto","label":"Source Location *","items":_vm.sourceList,"itemText":"name","itemValue":"id","item-disabled":(item) => {
                    return item.id == _vm.spotRateDetails.destination_location;
                  },"returnObject":true,"rules":[(v) => !!v || 'Source Location is Required'],"error-messages":_vm.formErrors.source_location},on:{"change":function($event){(_vm.formErrors.source_location = ''),
                    _vm.checkData($event, 'source_location')}},model:{value:(_vm.spotRateDetails.source_location),callback:function ($$v) {_vm.$set(_vm.spotRateDetails, "source_location", $$v)},expression:"spotRateDetails.source_location"}})],1),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('v-text-field',{attrs:{"hide-details":"auto","label":"Source State","dense":"","outlined":"","readonly":"","clearable":false},model:{value:(_vm.source_state),callback:function ($$v) {_vm.source_state=$$v},expression:"source_state"}})],1),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('v-text-field',{attrs:{"hide-details":"auto","label":"Source Country","dense":"","outlined":"","readonly":"","clearable":false},model:{value:(_vm.source_country),callback:function ($$v) {_vm.source_country=$$v},expression:"source_country"}})],1),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('MultiSelect',{attrs:{"hide-details":"auto","label":"Destination Location *","items":_vm.destinationList,"itemText":"name","itemValue":"id","item-disabled":(item) => {
                    return item.id == _vm.spotRateDetails.source_location;
                  },"returnObject":true,"rules":[(v) => !!v || 'Destination Location is Required'],"error-messages":_vm.formErrors.destination_location},on:{"change":function($event){(_vm.formErrors.destination_location = ''),
                    _vm.checkData($event, 'destination_location')}},model:{value:(_vm.spotRateDetails.destination_location),callback:function ($$v) {_vm.$set(_vm.spotRateDetails, "destination_location", $$v)},expression:"spotRateDetails.destination_location"}})],1),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('v-text-field',{attrs:{"hide-details":"auto","label":"Destination State","dense":"","outlined":"","readonly":"","clearable":false},model:{value:(_vm.destination_state),callback:function ($$v) {_vm.destination_state=$$v},expression:"destination_state"}})],1),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('v-text-field',{attrs:{"hide-details":"auto","label":"Destination Country","dense":"","outlined":"","readonly":"","clearable":false},model:{value:(_vm.destination_country),callback:function ($$v) {_vm.destination_country=$$v},expression:"destination_country"}})],1),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('v-text-field',{attrs:{"hide-details":"auto","label":"Distance","dense":"","outlined":"","readonly":"","clearable":false},model:{value:(_vm.spotRateDetails.distance),callback:function ($$v) {_vm.$set(_vm.spotRateDetails, "distance", $$v)},expression:"spotRateDetails.distance"}})],1),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('v-text-field',{attrs:{"hide-details":"auto","label":"Service Type","dense":"","outlined":"","readonly":"","clearable":false},model:{value:(_vm.spotRateDetails.service_type),callback:function ($$v) {_vm.$set(_vm.spotRateDetails, "service_type", $$v)},expression:"spotRateDetails.service_type"}})],1),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('DateField',{attrs:{"inputProps":{
                  dense: true,
                  label: 'Shipment Date *',
                  'hide-details': 'auto',
                  required: true,
                  rules: [(val) => !!val || 'Shipment Date is Required!'],
                  'error-messages': _vm.formErrors.shipment_date,
                  min: _vm.today,
                },"min":_vm.today},on:{"input":function($event){_vm.formErrors.shipment_date = ''}},model:{value:(_vm.spotRateDetails.shipment_date),callback:function ($$v) {_vm.$set(_vm.spotRateDetails, "shipment_date", $$v)},expression:"spotRateDetails.shipment_date"}})],1),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('MultiSelect',{attrs:{"hide-details":"auto","label":"Job Type *","items":_vm.allJobType,"itemText":"display_name","itemValue":"value","rules":[(v) => !!v || 'Job Type is Required'],"error-messages":_vm.formErrors['job_type']},on:{"change":function($event){_vm.formErrors['job_type'] = '';
                  _vm.checkData($event, 'job_type');}},model:{value:(_vm.spotRateDetails.job_type),callback:function ($$v) {_vm.$set(_vm.spotRateDetails, "job_type", $$v)},expression:"spotRateDetails.job_type"}})],1),_c('v-col',{staticClass:"pa-2 d-flex",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.isValid,"depressed":""},on:{"click":_vm.submitForm}},[_vm._v(" Get Rates ")]),_c('v-switch',{directives:[{name:"show",rawName:"v-show",value:(_vm.requestedOnce),expression:"requestedOnce"}],staticClass:"mt-1 ml-4",attrs:{"hide-details":"auto","label":`Request Quotation Location : ${
                  _vm.showQuotationForm ? 'Yes' : 'No'
                }`},model:{value:(_vm.showQuotationForm),callback:function ($$v) {_vm.showQuotationForm=$$v},expression:"showQuotationForm"}})],1)],1)],1)],1),_c('v-col',{staticClass:"px-0 py-2",attrs:{"cols":"12"}},[_c('v-divider')],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.responseGenerateQuotes),expression:"responseGenerateQuotes"}],staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12"}},[(_vm.rateInfoList.length > 0)?_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.rateInfoList,"selectable-key":"is_selectable","item-key":"bmr","show-select":_vm.contract_permissions &&
                (_vm.contract_permissions.add || _vm.contract_permissions.change),"hide-default-footer":""},model:{value:(_vm.selectedRate),callback:function ($$v) {_vm.selectedRate=$$v},expression:"selectedRate"}}):_vm._e()],1),(_vm.existingContracts.length > 0)?_c('v-col',{staticClass:"px-2 pt-2 pb-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-h6"},[_vm._v(" Existing Tariff")])]):_vm._e(),(_vm.existingContracts.length > 0)?_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.existingContractHeaders,"items":_vm.existingContracts,"item-key":"bmr","hide-default-footer":""}})],1):_vm._e(),(_vm.existingContracts.length > 0)?_c('v-col',{staticClass:"px-0 py-2",attrs:{"cols":"12"}},[_c('v-divider')],1):_vm._e(),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[(
                _vm.contract_permissions &&
                (_vm.contract_permissions.add || _vm.contract_permissions.change) &&
                (_vm.showButton == 'Create' || _vm.showButton == 'Update')
              )?_c('v-btn',{attrs:{"color":"primary","depressed":"","disabled":!_vm.selectedRate.length},on:{"click":_vm.showContractForm}},[_vm._v(" "+_vm._s(_vm.showButton)+" Tariff ")]):_vm._e()],1)],1)],1),(_vm.showQuotationForm)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{ref:"requestQuotesForm",model:{value:(_vm.isQuotationFormValid),callback:function ($$v) {_vm.isQuotationFormValid=$$v},expression:"isQuotationFormValid"}},[_vm._l((_vm.quotationRates),function(cr,i){return _c('v-row',{key:i,staticClass:"ma-0 heading rounded-lg mb-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"text-h6"},[_vm._v(" Container Details "+_vm._s(i + 1)+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","depressed":"","small":""},on:{"click":_vm.addContainer}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-btn',{attrs:{"color":"secondary","depressed":"","small":"","disabled":i == 0},on:{"click":function($event){return _vm.removeContainer(i)}}},[_c('v-icon',[_vm._v("mdi-minus")])],1)],1),_c('v-divider')],1),_c('v-col',{staticClass:"Form-Columns",attrs:{"cols":"12","sm":"6"}},[_c('MultiSelect',{attrs:{"label":"Container Type *","items":_vm.allContainersType,"itemText":"title","itemValue":"id","rules":[(v) => !!v || 'Container Type is Required'],"error-messages":_vm.quotationErrors &&
                  _vm.quotationErrors.quotation_rates &&
                  _vm.quotationErrors.quotation_rates[i] &&
                  _vm.quotationErrors.quotation_rates[i]['container_type']
                    ? _vm.quotationErrors.quotation_rates[i]['container_type']
                    : null},on:{"change":function($event){_vm.quotationErrors &&
                  _vm.quotationErrors.quotation_rates &&
                  _vm.quotationErrors.quotation_rates[i] &&
                  _vm.quotationErrors.quotation_rates[i]['container_type']
                    ? delete _vm.quotationErrors.quotation_rates[i][
                        'container_type'
                      ]
                    : null}},model:{value:(cr.container_type),callback:function ($$v) {_vm.$set(cr, "container_type", $$v)},expression:"cr.container_type"}})],1),_c('v-col',{staticClass:"Form-Columns",attrs:{"cols":"12","sm":"6"}},[_c('InputField',{attrs:{"type":"number","label":"Container Count*","max":"4","min":"1","rules":[
                  (v) => !!v || 'Count is Required',
                  (v) => (v > 0 && v < 5) || 'Count must be between 1 to 4',
                ],"error-messages":_vm.quotationErrors &&
                  _vm.quotationErrors.quotation_rates &&
                  _vm.quotationErrors.quotation_rates[i] &&
                  _vm.quotationErrors.quotation_rates[i]['container_count']
                    ? _vm.quotationErrors.quotation_rates[i]['container_count']
                    : null},on:{"change":function($event){_vm.quotationErrors &&
                  _vm.quotationErrors.quotation_rates &&
                  _vm.quotationErrors.quotation_rates[i] &&
                  _vm.quotationErrors.quotation_rates[i]['container_count']
                    ? delete _vm.quotationErrors.quotation_rates[i][
                        'container_count'
                      ]
                    : null}},model:{value:(cr.container_count),callback:function ($$v) {_vm.$set(cr, "container_count", $$v)},expression:"cr.container_count"}})],1)],1)}),_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-divider'),_c('v-btn',{staticClass:"mt-4",attrs:{"color":"primary","depressed":"","disabled":!_vm.isQuotationFormValid},on:{"click":function($event){_vm.showRemarksForm = true}}},[_vm._v(" Request Quotation(s) ")])],1)],1)],2)],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('GoogleMap',{ref:"shipperRateMap",staticClass:"Map-Height",staticStyle:{"display":"none"},attrs:{"mapWidth":"100%","mapHeight":"100%"},scopedSlots:_vm._u([{key:"mapInfo",fn:function(){return [_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center pa-2",attrs:{"cols":"12"}},[_c('img',{staticStyle:{"width":"18px"},attrs:{"src":_vm.sourceMarker}}),_c('span',{staticClass:"ml-4 text-body-2 font-weight-bold"},[_vm._v("Source")])]),_c('v-col',{staticClass:"d-flex align-center pa-2",attrs:{"cols":"12"}},[_c('img',{staticStyle:{"width":"18px"},attrs:{"src":_vm.destinationMarker}}),_c('span',{staticClass:"ml-4 text-body-2 font-weight-bold"},[_vm._v(" Destination ")])])],1)],1)]},proxy:true}])})],1),_c('RejectionRemarksDialog',{attrs:{"formDetails":_vm.spotRateDetails,"fieldKey":"customer_remarks","label":"Remarks","isRequiredRemarks":false},on:{"submitRemarks":function($event){return _vm.requestQuotation()}},model:{value:(_vm.showRemarksForm),callback:function ($$v) {_vm.showRemarksForm=$$v},expression:"showRemarksForm"}}),_c('ContractCreateForm',{attrs:{"formData":_vm.formData,"editMode":_vm.isEditing},on:{"createContract":_vm.submitContractForm},model:{value:(_vm.isContractForm),callback:function ($$v) {_vm.isContractForm=$$v},expression:"isContractForm"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }