<template>
  <v-row class="ma-0" justify="center">
    <v-col cols="11">
      <div id="invoiceAttachmen">
        <v-row class="ma-0 borderClass">
          <v-col cols="6" class="text-center align-self-center">
            <img
              alt="Brand Logo"
              class="Brand-Logo"
              src="@/assets/images/brand-logo.png"
          /></v-col>
          <v-col cols="6" class="text-right">
            <p class="text-h5 font-weight-bold mb-0">
              Momentum Company Limited Saudi Arabia
            </p>
            <p class="text-h5 font-weight-bold mb-0">
              شركة مومينتم السعودية المحدودة شركة شخص واحد
            </p>
            <p class="subtitle-1 font-weight-medium mb-0">
              Khalid Bin Al Walid St Building 7953 Sharafiyah Dist
            </p>
            <p class="subtitle-1 font-weight-medium mb-0">
              Jeddah 22234-4959 kingdom of Saudi Arabia
            </p>
            <p class="tsubtitle-1 font-weight-medium mb-0">
              لشارع خالد بن الوليد المبنى رقم 7953 حي الشرفية
            </p>
            <p class="tsubtitle-1 font-weight-medium mb-0">
              جدة 22234-4959 المملكة العربية السعودية
            </p>

            <p class="text-subtitle-1 mb-0">
              Tel:+966591257663 E-mail:info@momentumlogistics.com
            </p>
            <p class="text-h6 font-weight-bold mb-0">
              C.R No: 4030457682 VAT No: 310562221900003
            </p>
          </v-col>
          <v-col cols="12" class="text-center py-0">
            <span class="text-h6 font-weight-bold">Tax Invoice</span>
            <span class="text-h6 font-weight-bold"> ضريبية </span>
          </v-col>
          <v-col cols="7" class="pa-0 ma-0 borderClass">
            <v-row class="ma-0">
              <v-col cols="6" class="pa-0">
                <span class="text-subtitle-1 font-weight-bold px-1"
                  >Bill To</span
                >
              </v-col>
              <v-col
                cols="6"
                class="text-subtitle-1 font-weight-bold pa-0 text-right px-1"
              >
                <span>اتورة ال</span>
              </v-col>
              <v-col cols="6" class="pa-0">
                <span class="px-1">
                  {{ invoiceDetails.company_details.company_code }}</span
                >
                <br />
                <span class="px-1">{{
                  invoiceDetails.company_details.company_name
                }}</span>
                <br />
                <span class="px-1">
                  {{
                    invoiceDetails.company_details.address_detail.address
                  }}</span
                >
                <br />
                <span class="px-1">
                  {{
                    invoiceDetails.company_details.full_company_contact_number
                  }}</span
                >
              </v-col>
              <v-col cols="6" class="pa-0 text-right px-1">
                <span class="px-1">
                  {{ invoiceDetails.company_details.company_code }}</span
                >
                <br />
                <span class="px-1">{{
                  invoiceDetails.company_details.arabic_company_name
                }}</span>
                <br />
                <span class="px-1">
                  {{
                    invoiceDetails.company_details.address_detail.arabic_address
                  }}</span
                >
                <br />
                <span class="px-1">
                  {{
                    invoiceDetails.company_details.full_company_contact_number
                  }}</span
                >
              </v-col>
              <v-col cols="6" class="pa-0">
                <span class="text-subtitle-1 font-weight-bold px-1"
                  >VAT NO.</span
                >
              </v-col>
              <v-col
                cols="6"
                class="text-subtitle-1 font-weight-bold pa-0 text-right px-1"
              >
                <span>رقم التعريف الضريبي</span>
              </v-col>
              <v-col cols="6" class="pa-0">
                <span class="px-1">
                  {{ invoiceDetails.company_vat_number }} CR
                  {{ invoiceDetails.company_cr_number }}</span
                >
              </v-col>
              <v-col cols="6" class="pa-0 text-right px-1">
                <span>
                  {{ invoiceDetails.company_vat_number }} CR
                  {{ invoiceDetails.company_cr_number }}</span
                >
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="5" class="pa-0 ma-0 borderClass">
            <v-row class="ma-0">
              <v-col cols="6" class="pa-0 ma-0 borderClass">
                <v-row class="ma-0 pa-0">
                  <v-col cols="12" class="pa-0">
                    <span class="text-subtitle-1 font-weight-bold px-1">
                      VAT No. رقم التعريف الضريبي
                    </span>
                  </v-col>
                  <v-col cols="12" class="pa-0">
                    <span class="text-subtitle-1 font-weight-bold px-1">
                      Invoice Date الفاتورة ت
                    </span>
                  </v-col>
                  <v-col cols="12" class="pa-0">
                    <span class="text-subtitle-1 font-weight-bold px-1">
                      Due Date تاريخ الاستحقاق
                    </span>
                  </v-col>
                  <v-col cols="12" class="pa-0">
                    <span class="text-subtitle-1 font-weight-bold px-1">
                      Job No رقم ملف العملية
                    </span>
                  </v-col>
                  <v-col cols="12" class="pa-0">
                    <span class="text-subtitle-1 font-weight-bold px-1">
                      UOM وحدة قياس
                    </span>
                  </v-col>
                  <v-col cols="12" class="pa-0">
                    <span class="text-subtitle-1 font-weight-bold px-1">
                      Type طريقة الشحن
                    </span>
                  </v-col>
                  <v-col cols="12" class="pa-0">
                    <span class="text-subtitle-1 font-weight-bold px-1">
                      Volume الكمية
                    </span>
                  </v-col>
                  <v-col cols="12" class="pa-0">
                    <span class="text-subtitle-1 font-weight-bold px-1">
                      Ref No.
                    </span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6" class="pa-0 ma-0 borderClass">
                <v-row class="ma-0">
                  <v-col cols="12" class="pa-0 ma-0">
                    <span class="text-subtitle-1 font-weight-bold px-1">
                      310562221900003
                    </span>
                  </v-col>
                </v-row>

                <v-row class="ma-0">
                  <v-col cols="12" class="pa-0">
                    <span class="text-subtitle-1 font-weight-bold px-1">
                      {{ invoiceDetails.invoice_date | formatDate(false) }}
                    </span>
                  </v-col>
                </v-row>
                <v-row class="ma-0">
                  <v-col cols="12" class="pa-0">
                    <span class="text-subtitle-1 font-weight-bold px-1">
                      {{ invoiceDetails.invoice_due_date | formatDate(false) }}
                    </span>
                  </v-col>
                </v-row>
                <v-row class="ma-0">
                  <v-col cols="12" class="pa-0">
                    <span class="text-subtitle-1 font-weight-bold px-1">{{ invoiceDetails.job_no }} </span>
                  </v-col>
                </v-row>
                <v-row class="ma-0">
                  <v-col cols="12" class="pa-0">
                    <span class="text-subtitle-1 font-weight-bold px-1">
                      {{
                        invoiceDetails.invoice_uom
                          ? invoiceDetails.invoice_uom
                          : "NIL"
                      }}
                    </span>
                  </v-col>
                </v-row>
                <v-row class="ma-0">
                  <v-col cols="12" class="pa-0">
                    <span class="text-subtitle-1 font-weight-bold px-1">
                      ROA - Road Freight</span
                    >
                  </v-col>
                </v-row>
                <v-row class="ma-0">
                  <v-col cols="12" class="pa-0">
                    <span class="text-subtitle-1 font-weight-bold px-1"> </span>
                  </v-col>
                </v-row>
                <v-row class="ma-0">
                  <v-col cols="12" class="pa-0">
                    <span class="text-subtitle-1 font-weight-bold px-1"
                      >{{ invoiceDetails.bill_of_lading }}
                    </span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="7" class="pa-0 ma-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="pa-0 ma-0">
                <v-row class="ma-0 pa-0">
                  <v-col
                    cols="12"
                    class="borderClass pa-0 ma-0 text-subtitle-1 font-weight-bold px-1"
                  >
                    <div class="d-flex justify-space-between align-centre">
                      <div>
                        <span> Shipper </span>
                      </div>
                      <div>
                        <span class="text-right"> الشاحن </span>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" class="borderClass pa-0 ma-0 px-1">
                    <p>{{ invoiceDetails.shipper }}</p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6" class="pa-0 ma-0">
                <v-row class="ma-0 pa-0">
                  <v-col
                    cols="12"
                    class="borderClass pa-0 ma-0 text-subtitle-1 font-weight-bold px-1"
                  >
                    <div class="d-flex justify-space-between align-centre">
                      <div>
                        <span> Consignee </span>
                      </div>
                      <div>
                        <span class="text-left"> المرسل إليه </span>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" class="borderClass pa-0 ma-0 px-1">
                    <p>{{ invoiceDetails.consignee }}</p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="5" class="pa-0 ma-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="pa-0 ma-0">
                <v-row class="ma-0">
                  <v-col
                    cols="12"
                    class="borderClass pa-0 ma-0 text-subtitle-1 font-weight-bold px-1"
                  >
                    <div class="d-flex justify-space-between align-centre">
                      <div>
                        <span> Bill of Lading </span>
                      </div>
                      <div>
                        <span> بوليصة الشحن </span>
                      </div>
                    </div>
                    <span> </span>
                  </v-col>
                  <v-col cols="12" class="borderClass pa-0 ma-0 px-1">
                    <p>{{ invoiceDetails.bill_of_lading }}</p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6" class="pa-0 ma-0">
                <v-row class="ma-0">
                  <v-col
                    cols="12"
                    class="borderClass pa-0 ma-0 text-subtitle-1 font-weight-bold px-1"
                  >
                    <div class="d-flex justify-space-between align-centre">
                      <div>
                        <span> EST Arrival </span>
                      </div>
                      <div>
                        <span> الوصول المتوقع </span>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" class="borderClass pa-0 ma-0 px-1">
                    <p>{{ invoiceDetails.est_arrival | formatDate(false) }}</p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="7" class="pa-0 ma-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="pa-0 ma-0">
                <v-row class="ma-0 pa-0">
                  <v-col
                    cols="12"
                    class="borderClass pa-0 ma-0 text-subtitle-1 font-weight-bold px-1"
                  >
                    <div class="d-flex justify-space-between align-centre">
                      <div>
                        <span> Port of Loading </span>
                      </div>
                      <div>
                        <span> ميناء التحميل</span>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" class="borderClass pa-0 ma-0 px-1">
                    <p>{{ invoiceDetails.port_of_loading }}</p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6" class="pa-0 ma-0">
                <v-row class="ma-0 pa-0">
                  <v-col
                    cols="12"
                    class="borderClass pa-0 ma-0 text-subtitle-1 font-weight-bold px-1"
                  >
                    <div class="d-flex justify-space-between align-centre">
                      <div>
                        <span> Port of Discharge </span>
                      </div>
                      <div>
                        <span> ميناء التحميل</span>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" class="borderClass pa-0 ma-0 px-1">
                    <p>{{ invoiceDetails.port_of_discharge }}</p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="5" class="pa-0 ma-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="pa-0 ma-0">
                <v-row class="ma-0">
                  <v-col
                    cols="12"
                    class="borderClass pa-0 ma-0 text-subtitle-1 font-weight-bold px-1"
                  >
                    <div class="d-flex justify-space-between align-centre">
                      <div>
                        <span> Gross Weight </span>
                      </div>
                      <div>
                        <span> لوزن الإجمالي </span>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" class="borderClass pa-0 ma-0 px-1">
                    <p v-if="invoiceDetails.gross_weight">
                      {{ invoiceDetails.gross_weight }}
                    </p>
                    <p v-else>N/A</p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6" class="pa-0 ma-0">
                <v-row class="ma-0">
                  <v-col
                    cols="12"
                    class="borderClass pa-0 ma-0 text-subtitle-1 font-weight-bold px-1"
                  >
                    <div class="d-flex justify-space-between align-centre">
                      <div>
                        <span> Date of Delivery </span>
                      </div>
                      <div>
                        <span> تاريخ التسليم </span>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" class="borderClass pa-0 ma-0 px-1">
                    <p v-if="invoiceDetails.date_of_delivery">
                      {{ invoiceDetails.date_of_delivery| formatDate(false) }}
                    </p>
                    <p v-else>N/A</p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="pa-0 ma-0">
            <v-row class="pa-0 ma-0">
              <v-col cols="3" class="pa-0 ma-0">
                <v-row class="ma-0">
                  <v-col
                    cols="12"
                    class="borderClass pa-0 ma-0 text-subtitle-1 font-weight-bold px-1"
                  >
                    <div class="d-flex flex-column">
                      <div>
                        <span> Description </span>
                      </div>
                      <div>
                        <span> الوصف </span>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="1" class="pa-0 ma-0">
                <v-row class="ma-0 text-center align-self-center">
                  <v-col
                    cols="12"
                    class="borderClass pa-0 ma-0 text-subtitle-1 font-weight-bold"
                  >
                    <div class="d-flex flex-column">
                      <div>
                        <span> Quantity </span>
                      </div>
                      <div>
                        <span>لكمية</span>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="1" class="pa-0 ma-0">
                <v-row class="ma-0 text-center align-self-center">
                  <v-col
                    cols="12"
                    class="borderClass pa-0 ma-0 text-subtitle-1 font-weight-bold"
                  >
                    <div class="d-flex flex-column">
                      <div>
                        <span> CURRENCY </span>
                      </div>
                      <div>
                        <span>العملة</span>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="2" class="pa-0 ma-0">
                <v-row class="ma-0 text-right">
                  <v-col
                    cols="12"
                    class="borderClass pa-0 ma-0 text-subtitle-1 font-weight-bold px-1"
                  >
                    <div class="d-flex flex-column">
                      <div>
                        <span> UNIT PRICE </span>
                      </div>
                      <div>
                        <span> سعر الوحدة</span>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="2" class="pa-0 ma-0">
                <v-row class="ma-0 text-right">
                  <v-col
                    cols="12"
                    class="borderClass pa-0 ma-0 text-subtitle-1 font-weight-bold px-1"
                  >
                    <div class="d-flex flex-column">
                      <div>
                        <span> Taxable Value </span>
                      </div>
                      <div>
                        <span>الخاضعة للضريبة</span>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="1" class="pa-0 ma-0">
                <v-row class="ma-0 text-right">
                  <v-col
                    cols="12"
                    class="borderClass pa-0 ma-0 text-subtitle-1 font-weight-bold px-1"
                  >
                    <div class="d-flex flex-column">
                      <div>
                        <span> VAT % </span>
                      </div>
                      <div>
                        <span>ضريبة</span>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="1" class="pa-0 ma-0">
                <v-row class="ma-0 text-right">
                  <v-col
                    cols="12"
                    class="borderClass pa-0 ma-0 text-subtitle-1 font-weight-bold px-1"
                  >
                    <div class="d-flex flex-column">
                      <div>
                        <span> VAT </span>
                      </div>
                      <div>
                        <span>مبلغ ضربيه</span>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="1" class="pa-0 ma-0">
                <v-row class="ma-0 text-right">
                  <v-col
                    cols="12"
                    class="borderClass pa-0 ma-0 text-subtitle-1 font-weight-bold px-1"
                  >
                    <div class="d-flex flex-column">
                      <div>
                        <span> Total </span>
                      </div>
                      <div>
                        <span> المجموع</span>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row
              class="ma-0"
              v-for="(charge, i) in invoiceDetails.invoice_charges"
              :key="i"
            >
              <v-col cols="3" class="pa-0 ma-0">
                <v-row class="ma-0">
                  <v-col cols="12" class="borderClass pa-0 ma-0 px-1">
                    <p>
                      {{ charge.description }} {{ charge.arabic_description }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="1" class="pa-0 ma-0">
                <v-row class="ma-0 text-center align-self-center">
                  <v-col cols="12" class="borderClass pa-0 ma-0">
                    <p>{{ charge.quantity }}</p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="1" class="pa-0 ma-0">
                <v-row class="ma-0 text-center align-self-center">
                  <v-col cols="12" class="borderClass pa-0 ma-0">
                    <p>SAR</p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="2" class="pa-0 ma-0">
                <v-row class="ma-0 text-right">
                  <v-col cols="12" class="borderClass pa-0 ma-0 px-1">
                    <p>{{ charge.da_rate | formatNumber(true) }}</p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="2" class="pa-0 ma-0">
                <v-row class="ma-0 text-right">
                  <v-col cols="12" class="borderClass pa-0 ma-0 px-1">
                    <p>{{ charge.taxable_amount | formatNumber(true) }}</p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="1" class="pa-0 ma-0">
                <v-row class="ma-0 text-right">
                  <v-col cols="12" class="borderClass pa-0 ma-0 px-1">
                    <p>{{ `${charge.tax_percentage}` }}</p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="1" class="pa-0 ma-0">
                <v-row class="ma-0 text-right">
                  <v-col cols="12" class="borderClass pa-0 ma-0 px-1">
                    <p>{{ charge.tax_amount | formatNumber(true) }}</p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="1" class="pa-0 ma-0">
                <v-row class="ma-0 text-right">
                  <v-col cols="12" class="borderClass pa-0 ma-0 px-1">
                    <p>{{ charge.gross_amount | formatNumber(true) }}</p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-row class="ma-0">
              <v-col cols="3" class="d-flex justify-end borderClass"></v-col>
              <v-col cols="6" class="borderClass pa-0 ma-0">
                <v-row class="pa-0 ma-0 text-right text-subtitle-2 font-weight-bold">
                  <v-col cols="12" class="borderClass"
                    >AMOUNT BEFORE 15% TAX المضافة القيمة ضريبة قبل
                    المبلغ</v-col
                  >
                  <v-col cols="12" class="borderClass"
                    >VALUE ADDED TAX 15% المضافة القيمة ض</v-col
                  >

                  <v-col cols="12" class="borderClass"
                    >TOTAL INVOICE AMOUNT للفاتورة الإجمالي المبلغ</v-col
                  >
                </v-row>
              </v-col>
              <v-col cols="3" class="borderClass pa-0 ma-0">
                <v-row class="pa-0 ma-0 text-right">
                  <v-col cols="12" class="borderClass">
                    {{
                      invoiceDetails.total_amount | formatNumber(true)
                    }}</v-col
                  >
                  <v-col cols="12" class="borderClass">{{
                    invoiceDetails.total_tax_amount | formatNumber(true)
                  }}</v-col>

                  <v-col cols="12" class="borderClass">{{
                    invoiceDetails.total_gross_amount | formatNumber(true)
                  }}</v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="pa-0 ma-0">
            <v-row class="pa-0 ma-0 text-subtitle-1 font-weight-bold">
              <v-col cols="3" class="borderClass pa-0 ma-0 px-1"
                >Amount in words SAR المبلغ بالكلمات</v-col
              >
              <v-col cols="9" class="borderClass pa-0 ma-0 px-1"
                ><span class="text-subtitle-1 font-weight-bold">{{
                  $globals.currency
                }}</span>
                {{ invoiceDetails.total_gross_amount_words }} 
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="py-0 borderClass">
            <span class="text-h6 font-weight-bold">BANK ACCOUNT DETAIL</span>
          </v-col>
          <v-col cols="12" class="pa-0 ma-0 borderClass">
            <v-row class="ma-0">
              <v-col cols="3" class="ma-0 pa-0">
                <span class="text-subtitle-1 font-weight-bold px-3"
                  >Beneficiary Name
                </span>
              </v-col>
              <v-col cols="3" class="pa-0">
                <span class="text-subtitle-1 font-weight-bold"
                  >Momentum Company Limited Saudi Arabia (LLC)
                </span>
              </v-col>
              <v-col cols="6" class="pa-0 text-right px-1">
                <span class="text-subtitle-1 font-weight-bold px-1"> </span>
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col cols="3" class="ma-0 pa-0">
                <span class="text-subtitle-1 font-weight-bold px-3"
                  >Bank name
                </span>
              </v-col>
              <v-col cols="3" class="pa-0">
                <span class="text-subtitle-1 font-weight-bold">
                  BANQUE SAUDI FRANSI
                </span>
              </v-col>
              <v-col cols="6" class="pa-0 text-right px-1">
                <span class="text-subtitle-1 font-weight-bold px-1"> </span>
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col cols="3" class="ma-0 pa-0">
                <span class="text-subtitle-1 font-weight-bold px-3"
                  >Swift Code
                </span>
              </v-col>
              <v-col cols="3" class="pa-0">
                <span class="text-subtitle-1 font-weight-bold"
                  >BSFRSARIXXX
                </span>
              </v-col>
              <v-col cols="6" class="pa-0 text-right px-1">
                <span class="text-subtitle-1 font-weight-bold px-1"> </span>
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col cols="3" class="ma-0 pa-0">
                <span class="text-subtitle-1 font-weight-bold px-3"
                  >Account number
                </span>
              </v-col>
              <v-col cols="3" class="pa-0">
                <span class="text-subtitle-1 font-weight-bold"
                  >988037-002-56
                </span>
              </v-col>
              <v-col cols="6" class="pa-0 text-right px-1">
                <span class="text-subtitle-1 font-weight-bold px-1"> </span>
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col cols="3" class="ma-0 pa-0">
                <span class="text-subtitle-1 font-weight-bold px-3"
                  >Account Currency
                </span>
              </v-col>
              <v-col cols="3" class="pa-0">
                <span class="text-subtitle-1 font-weight-bold"
                  >Saudi Riyal
                </span>
              </v-col>
              <v-col cols="6" class="pa-0 text-right px-1">
                <span class="text-subtitle-1 font-weight-bold px-1"> </span>
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col cols="3" class="ma-0 pa-0">
                <span class="text-subtitle-1 font-weight-bold px-3"
                  >IBAN Number
                </span>
              </v-col>
              <v-col cols="3" class="pa-0">
                <span class="text-subtitle-1 font-weight-bold"
                  >SA5655000000098803700256
                </span>
              </v-col>
              <v-col cols="6" class="pa-0 text-right px-1">
                <span class="text-subtitle-1 font-weight-bold px-1"> </span>
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col cols="3" class="ma-0 pa-0">
                <span class="text-subtitle-1 font-weight-bold px-3"
                  >Bank Address
                </span>
              </v-col>
              <v-col cols="3" class="pa-0">
                <span class="text-subtitle-1 font-weight-bold"
                  >KING ABDUL AZIZ STREET BRANCH, AL KHOBAR, <br />
                  31952, KSA
                </span>
              </v-col>
              <v-col cols="6" class="pa-0 text-right px-1">
                <span class="text-subtitle-1 font-weight-bold px-1"> </span>
              </v-col>
              
            </v-row>
          </v-col>
        </v-row>
        <br />
        <br />
        <br />
        <v-row>
          <v-col cols="12" class="pa-0 ma-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="12" class="text-subtitle-1 font-weight-bold px-1">
                <span>Terms & Conditions :</span>
              </v-col>
              <v-col cols="12">
                <span
                  >Verify the invoice and report discrepancy, if any, within 7
                  days from the date of receipt of the invoice, otherwise the
                  invoice will be be assumed as accurate and accepted by
                  customer</span
                >
              </v-col>
              <v-col cols="12" class="text-subtitle-1 font-weight-bold px-1">
                <span>الشروط و الحكام :</span>
              </v-col>

              <v-col cols="12">
                <span
                  >تحقق من الفاتورة و الفوارق، إن وجدت، على أن يتم التبليغ عنها
                  في غضون 7 أيام من تاريخ استلم الفاتورة ، وإل سيتم افتراض
                  الفاتورة على أنها دقيقة وقبله العميل</span
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    invoiceDetails: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style scoped>
#invoiceAttachment {
  font-family: "Roboto", sans-serif !important;
}

.Brand-Logo {
  width: 200px;
}

.borderClass {
  border: 1px solid #000;
  /* padding: 3px 2px 0px 2px; */
}
</style>