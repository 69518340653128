<template>
  <div>
    <!-- Top Navigation -->
    <AppBar
      :companyNotifications="companyNotifications"
      :unreadCount="unreadCount"
      :unreadMessages="unreadMessages"
      @openChat="openChat"
      @toggleDrawer="drawer = !drawer"
      @showRightDrawer="rightDrawer = true"
      @getOldNotifications="getOldNotifications"
      @refreshNotificationHistory="getNotificationHistory"
      @closeWebSocket="onClose"
    />

    <!-- Left Side Navigation -->
    <SideNavigation v-model="drawer" />

    <!-- Right Side Navigation -->
    <RightDrawer v-model="rightDrawer" />

    <!-- Base Content -->
    <v-main>
      <v-container fluid class="pa-0">
        <router-view />
      </v-container>
    </v-main>
    <div class="chat-container" v-if="tripDetails">
      <v-card width="520" class="chat-card">
        <v-card-title class="py-2 primary white--text">
          <span>
            {{ tripDetails.trip_id }}
          </span>
          <v-tooltip bottom v-if="!isActiveOperations">
            <template #activator="{ on, attrs }">
              <v-icon
                size="20"
                color="white"
                class="ml-2"
                v-on="on"
                v-bind="attrs"
                @click="openOperations()"
              >
                mdi-arrow-right-top
              </v-icon>
            </template>
            <span>Go to Trip</span>
          </v-tooltip>

          <v-spacer />
          <v-icon
            v-if="minimizeChat"
            color="white"
            small
            @click="minimizeChat = false"
          >
            mdi-arrow-expand
          </v-icon>
          <v-icon v-else color="white" small @click="minimizeChat = true">
            mdi-window-minimize
          </v-icon>
          <v-icon @click="closeChat" color="white" class="ml-2">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text
          id="chatCard"
          class="message-container pa-0 mt-2"
          :class="{ 'minimize-chat-window': minimizeChat }"
          @scroll="getOldChats"
        >
          <div class="px-3" v-for="(msg, i) in messageList" :key="i">
            <div
              v-if="msg.message_format == 'Text'"
              :class="`${
                msg.sender_type && msg.sender_type.toLowerCase() == userType
                  ? 'justify-end'
                  : 'justify-start'
              }`"
              no-gutters
              class="d-flex"
            >
              <div
                :class="`${
                  msg.sender_type && msg.sender_type.toLowerCase() == userType
                    ? 'rounded-br-0 grey lighten-2 send'
                    : 'rounded-bl-0 primary lighten-4 recieved'
                }`"
                class="message px-3 py-2 rounded-lg"
              >
                {{ msg.message }}
              </div>
            </div>
            <div
              v-if="msg.message_format == 'Image'"
              :class="`${
                msg.sender_type && msg.sender_type.toLowerCase() == userType
                  ? 'justify-end'
                  : 'justify-start'
              }`"
              no-gutters
              class="d-flex"
            >
              <div
                :class="`${
                  msg.sender_type && msg.sender_type.toLowerCase() == userType
                    ? 'rounded-br-0 grey lighten-2 send'
                    : 'rounded-bl-0 primary lighten-4 recieved'
                }`"
                class="message px-3 py-2 rounded-lg pa-4"
              >
                <img width="100%" :src="msg.attachment_url" alt="File" />
              </div>
            </div>
            <div
              v-if="msg.message_format == 'Document'"
              :class="`${
                msg.sender_type && msg.sender_type.toLowerCase() == userType
                  ? 'justify-end'
                  : 'justify-start'
              }`"
              no-gutters
              class="d-flex"
            >
              <div
                :class="`${
                  msg.sender_type && msg.sender_type.toLowerCase() == userType
                    ? 'rounded-br-0 grey lighten-2 send'
                    : 'rounded-bl-0 primary lighten-4 recieved'
                }`"
                class="message px-3 py-2 rounded-lg pa-4"
              >
                <v-list dense class="pa-0">
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <v-list-item
                        @click="downloadFile(msg.attachment_url)"
                        dense
                        v-bind="attrs"
                        v-on="on"
                        class="pa-0"
                        :class="`${
                          msg.sender_type &&
                          msg.sender_type.toLowerCase() == userType
                            ? 'grey lighten-2'
                            : 'primary lighten-4'
                        }`"
                      >
                        <v-list-item-avatar>
                          <v-icon size="20" class="grey lighten-1" dark>
                            mdi-file
                          </v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title
                            v-text="getFileName(msg.attachment_url)"
                          />
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <span>{{ getFileName(msg.attachment_url) }}</span>
                  </v-tooltip>
                </v-list>
              </div>
            </div>
            <div
              v-if="msg.message_format == 'Audio'"
              :class="`${
                msg.sender_type && msg.sender_type.toLowerCase() == userType
                  ? 'justify-end'
                  : 'justify-start'
              }`"
              no-gutters
              class="d-flex"
            >
              <div
                :class="`${
                  msg.sender_type && msg.sender_type.toLowerCase() == userType
                    ? 'rounded-br-0 grey lighten-2 send'
                    : 'rounded-bl-0 primary lighten-4 recieved'
                }`"
                class="message rounded-lg pa-3"
              >
                <audio controls :src="msg.attachment_url">
                  Your browser does not support the
                  <code>audio</code> element.
                </audio>
              </div>
            </div>
            <div
              :class="`${
                msg.sender_type && msg.sender_type.toLowerCase() == userType
                  ? 'text-right'
                  : ''
              }`"
              class="text-caption pb-1"
            >
              {{ msg.sender_type }} | {{ msg.sender_name }} |
              {{ msg.created | formatDate(true) }}
            </div>
          </div>
        </v-card-text>
        <v-card-actions
          v-if="!minimizeChat"
          class="px-3 border-top-light d-flex"
        >
          <v-icon
            @click="toggleRecording"
            :disabled="tripDetails.status == 'Completed'"
            class="pr-2"
          >
            mdi-microphone
          </v-icon>

          <span class="Full-Width" v-if="recording"> Recording ... </span>
          <audio class="Full-Width" v-if="audio" controls :src="audioPreview">
            Your browser does not support the
            <code>audio</code> element.
          </audio>
          <v-text-field
            v-if="!recording && !audio"
            placeholder="Type Here..."
            prepend-inner-icon="mdi-attachment"
            v-model="message"
            filled
            rounded
            dense
            hide-details
            :disabled="tripDetails.status == 'Completed'"
            :readonly="!!selectedFile"
            :loading="uploadLoading"
            @keyup.enter="sendMessage"
            @click:prepend-inner="triggerFileSelect()"
          />

          <v-icon
            :disabled="tripDetails.status == 'Completed'"
            @click="sendMessage"
            size="20"
            class="pl-2 pr-1"
          >
            mdi-send
          </v-icon>
        </v-card-actions>
      </v-card>
      <input
        ref="fileSelector"
        @change="selectionChanged"
        v-show="false"
        type="file"
        accept="audio/mp3,audio/aac,audio/wav,image/png,image/jpg,image/jpeg,.doc,.docx,.pdf,.xlsx,.xls"
      />
    </div>
  </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";
import AppBar from "@/components/BaseComponents/AppBar.vue";
import AppFooter from "@/components/BaseComponents/AppFooter.vue";
import RightDrawer from "@/components/BaseComponents/RightDrawer.vue";
import SideNavigation from "@/components/BaseComponents/SideNavigation.vue";

export default {
  components: {
    AppBar,
    SideNavigation,
    AppFooter,
    RightDrawer,
  },

  name: "DefaultLayout",

  data() {
    return {
      userCompanyId: null,
      userType: null,
      unreadCount: 0,
      companyNotifications: [],
      // Drawer vars
      drawer: false,
      rightDrawer: false,

      // Snackbar vars
      snackbar: false,
      vertical: false,
      hasActions: true,
      text: null,
      color: null,
      timeout: 2000,
      position: null,

      // Loader Var
      showOverlay: false,

      minimizeChat: false,
      uploadLoading: false,
      recording: false,
      audio: null,
      message: null,
      unreadMessages: [],
      mediaRecorder: null,
      audioPreview: null,
      selectedFile: null,
      newMessageAlert: false,
      chatHistoryTimeout: null,
      messageList: [],
      tripDetails: null,
      initialLoad: true,
    };
  },
  computed: {
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (!obj) {
        return {};
      } else {
        return obj;
      }
    },
    isActiveOperations() {
      return this.$route.path.indexOf("/app/admin/trip/details/") > -1;
    },
  },
  methods: {
    downloadFile(url) {
      const a = document.createElement("a");
      a.href = url;
      a.download = this.getFileName(url);
      a.target = "blank";
      a.id = "custom_file_download_element";
      a.click();
    },

    getFileName(path) {
      if (path) {
        path = path.split("/");
        return path[path.length - 1];
      } else {
        return "undefined";
      }
    },

    triggerFileSelect() {
      if (this.$refs.fileSelector) {
        this.$refs.fileSelector.click();
      }
    },

    selectionChanged(ev) {
      this.selectedFile = null;
      this.message = null;
      if (ev && ev.target && ev.target.files && ev.target.files.length > 0) {
        this.selectedFile = ev.target.files[0];
        this.message = this.selectedFile.name;
        ev.target.value = null;
      }
    },

    openOperations() {
      if (!this.isActiveOperations) {
        this.$router.push(`/app/admin/trip/details/${this.tripDetails.id}`);
      }
    },
    setupWS(userType) {
      if (!this.$ws.instance) {
        this.$ws.createInstance(userType);
        this.$ws.instance.onopen = this.onOpen;
      } else {
        this.onOpen();
      }
      this.$ws.instance.onmessage = this.onMessage;
    },
    getOldNotifications(refreshHistory) {
      if (refreshHistory) {
        this.getNotificationHistory();
      }
    },
    onClose() {
      this.$ws.removeInstance();
    },
    onMessage(ev) {
      if (ev && ev.data) {
        const data =
          typeof ev.data == typeof "" ? JSON.parse(ev.data) : ev.data;
        this.$bus.$emit("socketMessageReceived", data);
        if (
          data.type &&
          [
            "chat_history",
            "new_message",
            "message_from_admin",
            "message_from_transporter",
            "message_from_driver",
          ].indexOf(data.type) > -1
        ) {
          if (data.type && data.type == "chat_history") {
            this.messageList.unshift(...data.data);
            if (this.initialLoad) {
              this.scrollChatToBottom();
            }
            this.initialLoad = false;
          } else {
            if (this.tripDetails) {
              if (this.tripDetails.id != data.data.trip_pk) {
                this.addToUnreadMessage(data);
              } else {
                this.messageList.push(data.data);
                if (this.isActiveChat()) {
                  this.scrollChatToBottom();
                } else {
                  this.newMessageAlert = true;
                }
              }
            } else {
              this.addToUnreadMessage(data);
            }
          }
        }
      }
    },
    addToUnreadMessage(data) {
      let msgIndex = this.unreadMessages.indexOf(
        this.unreadMessages.find((msg) => msg.trip_id == data.data.trip_id)
      );
      if (msgIndex > -1) {
        this.unreadMessages.splice(msgIndex);
      }
      this.unreadMessages.unshift(data.data);
    },

    openChat(id) {
      this.showOverlay = true;
      this.$api.trip
        .getTripChatDetails(id)
        .then(async (res) => {
          this.initialLoad = true;
          this.showOverlay = false;
          this.tripDetails = res.data;
          if (!this.$ws.instance) {
            await this.$ws.createInstance("admin");
          }
          this.getChatHistory();
          this.removeOpenedTripChat();
        })
        .catch(() => {
          this.showOverlay = false;
        });
    },
    removeOpenedTripChat() {
      let msgIndex = this.unreadMessages.indexOf(
        this.unreadMessages.find((msg) => msg.trip_pk == this.tripDetails.id)
      );
      if (msgIndex > -1) {
        this.unreadMessages.splice(msgIndex, 1);
      }
    },
    closeChat() {
      this.tripDetails = null;
      this.resetChat();
    },
    onOpen() {
      this.getNotificationHistory();
    },
    getNotificationHistory(params = {}) {
      params = {
        company: this.currentProfile.company,
        limit: this.limit,
        offset: this.offset,
        ...params,
      };
      if (this.companyNotifications.length && !params.clearAll) {
        params.offset = this.companyNotifications.length;
      }
      this.$api.company
        .getCompanyNotifications(params)
        .then((res) => {
          if (res.additional_info) {
            this.unreadCount = res.additional_info.unread_count;
          }
          if (this.companyNotifications.length && !params.clearAll) {
            this.companyNotifications.push(...res.data);
          } else {
            this.companyNotifications = res.data;
          }
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    sendMessage() {
      if (this.tripDetails.status != "Completed") {
        if (this.selectedFile || this.audio) {
          this.uploadFile();
        } else if (this.message && this.message.trim().length > 0) {
          this.$ws.instance.send(
            JSON.stringify({
              type: "new_message",
              data: {
                trip_id: this.tripDetails.id,
                message: this.message.trim(),
              },
            })
          );
          this.resetMessage();
        }
      }
    },

    toggleRecording(ev) {
      if (navigator && navigator.mediaDevices) {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((stream) => {
            if (this.recording && this.mediaRecorder) {
              this.mediaRecorder.stop();
            } else {
              this.recording = true;

              this.mediaRecorder = new MediaRecorder(stream);
              this.mediaRecorder.start();

              const chunks = [];
              this.mediaRecorder.addEventListener("dataavailable", (event) => {
                chunks.push(event.data);
              });

              this.mediaRecorder.addEventListener("stop", () => {
                this.recording = false;
                this.audio = new Blob(chunks, {
                  type: "audio/mp3",
                });
                this.audioPreview = URL.createObjectURL(this.audio);
                this.mediaRecorder = null;
              });
            }
          })
          .catch((err) => {
            alert(
              "Audio permissions denied or disabled from browser check permissions and try again."
            );
          });
      }
    },

    getOldChats(ev) {
      if (ev.target.scrollTop == 0) {
        this.getChatHistory();
      }
      if (this.isActiveChat()) {
        this.newMessageAlert = false;
      }
    },

    getChatHistory() {
      if (this.chatHistoryTimeout) {
        clearTimeout(this.chatHistoryTimeout);
      }

      this.chatHistoryTimeout = setTimeout(() => {
        this.$ws.instance.send(
          JSON.stringify({
            type: "chat_history",
            data: {
              trip_id: this.tripDetails.id,
              offset: this.messageList.length,
            },
          })
        );
      }, 500);
    },

    isActiveChat() {
      const el = document.getElementById("chatCard");
      return el && el.scrollHeight <= el.scrollTop + 620;
    },

    scrollChatToBottom() {
      this.newMessageAlert = false;
      setTimeout(() => {
        let messageContainer = document.getElementById("chatCard");
        if (messageContainer) {
          messageContainer.scrollTo({
            top: messageContainer.scrollHeight,
            behavior: "smooth",
          });
        }
      }, 300);
    },

    uploadFile() {
      this.uploadLoading = true;

      let payload = new FormData();

      if (this.selectedFile) {
        payload.append("attachment", this.selectedFile);
      } else if (this.audio) {
        payload.append(
          "attachment",
          this.audio,
          `${Date.parse(new Date())}.mp3`
        );
      }

      payload.append("trip", this.tripDetails.id);
      payload.append("driver", this.tripDetails.driver);

      this.resetMessage();

      this.$api.general
        .uploadFile(payload)
        .then((res) => {
          this.uploadLoading = false;
        })
        .catch((err) => {
          if (err && err.data && err.data.non_field_errors) {
            this.$bus.$emit("showToastMessage", {
              message: err.data.non_field_errors[0],
              color: "error",
              hasActions: true,
            });
          } else if (err && err.data && err.data.attachment) {
            this.$bus.$emit("showToastMessage", {
              message: err.data.attachment.join(),
              color: "error",
            });
          }
          this.uploadLoading = false;
        });
    },

    resetChat() {
      this.resetMessage();
      this.messageList = [];
    },

    resetMessage() {
      this.message = null;
      this.selectedFile = null;
      this.audio = null;
      this.audioPreview = null;
    },
  },
  beforeCreate() {
    this.$bus.$emit("showLoader", true);
    this.$api.authentication
      .getProfileInfo()
      .then((res) => {
        this.$bus.$emit("showLoader", false);
        localStorage.setItem("userProfile", JSON.stringify(res.data));
        localStorage.setItem("user_type", res.data.user_type);
        const preference = res?.data?.user_preferences || {};
        localStorage.setItem("userPreferences", JSON.stringify(preference));
        localStorage.setItem(
          "permissions",
          JSON.stringify(res.data.permissions)
        );
        if (res.data) {
          this.userType = "admin";
          if (this.userType) {
            this.setupWS(this.userType);
          }
        }
      })
      .catch((err) => {
        this.$bus.$emit("showLoader", false);
      });
  },
  created() {
    this.$bus.$on("socketMessageReceived", (data) => {
      let socketDataType = null;
      if (
        data.type &&
        (data.type == "notification_received" ||
          data.type == "report_notification")
      ) {
        let newNotification = { ...data.data };
        this.unreadCount = data.data.unread_count;
        this.companyNotifications.unshift(newNotification);
      }
      if (
        this.$route &&
        this.$route.path &&
        !this.$route.path.includes("operations")
      ) {
        if (this.userType == "customer") {
          socketDataType = "message_from_transporter";
        } else if (this.userType == "transporter") {
          socketDataType = "message_from_customer";
        }
        if (
          socketDataType &&
          data.type &&
          [socketDataType].indexOf(data.type) > -1
        ) {
          this.$bus.$emit("showToastMessage", {
            message: `New message in Trip: ${data.data.trip_id} from "${data.data.company_name}"`,
            color: "blue",
            hasActions: true,
          });
        }
      }
    });
  },
  async mounted() {
    this.$bus.$on("openTripChat", this.openChat);
    // Google map loading code
    if (window.google == undefined) {
      const loader = new Loader({
        apiKey: process.env.VUE_APP_MAP_KEY,
        version: "weekly",
        libraries: ["places", "geometry", "drawing", "directions"],
      });
      await loader.load();
    }
  },
  beforeDestroy() {
    this.$bus.$off("openTripChat");
  },
};
</script>
