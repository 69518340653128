import Blank from "@/layouts/blank.vue"
import DeliveryAdviceList from "@/pages/admin/delivery-advice/DeliveryAdviceList.vue";
import DirectDeliveryAdviceAdd from "@/pages/admin/delivery-advice/DirectDeliveryAdviceAdd.vue";
import DirectDeliveryAdviceEdit from "@/pages/admin/delivery-advice/DirectDeliveryAdviceEdit.vue";
import CashDeliveryAdviceAdd from "@/pages/admin/delivery-advice/CashDeliveryAdviceAdd.vue";
import CashDeliveryAdviceEdit from "@/pages/admin/delivery-advice/CashDeliveryAdviceEdit.vue";
import DeliveryAdviceEdit from "@/pages/admin/delivery-advice/DeliveryAdviceEdit.vue";
import DeliveryAdviceDetails from "@/pages/admin/delivery-advice/DeliveryAdviceDetails.vue";

const deliveryAdviceRoutes = [
  {
    path: "delivery-advice",
    name: "app-admin-delivery-advice",
    component: Blank,
    children: [
      {
        path: "/",
        redirect: "/app/admin/delivery-advice/list",
      },
      {
        path: "list",
        name: "app-admin-delivery-advice-list",
        component: DeliveryAdviceList,
      },
      {
        path: "direct-da-add",
        name: "app-admin-delivery-advice-direct-da-add",
        component: DirectDeliveryAdviceAdd,
      },
      {
        path: "direct-da-edit",
        name: "app-admin-delivery-advice-direct-da-edit",
        component: Blank,
        children: [
          {
            path: "/",
            redirect: "/app/admin/delivery-advice/list",
          },
          {
            path: ":id",
            name: "app-admin-delivery-advice-direct-da-edit-id",
            component: DirectDeliveryAdviceEdit,
          }
        ]
      },
      {
        path: "cash-da-add",
        name: "app-admin-delivery-advice-cash-da-add",
        component: CashDeliveryAdviceAdd,
      },
      {
        path: "cash-da-edit",
        name: "app-admin-delivery-advice-cash-da-edit",
        component: Blank,
        children: [
          {
            path: "/",
            redirect: "/app/admin/delivery-advice/list",
          },
          {
            path: ":id",
            name: "app-admin-delivery-advice-cash-da-edit-id",
            component: CashDeliveryAdviceEdit,
          }
        ]
      },
      {
        path: "edit",
        name: "app-admin-delivery-advice-edit",
        component: Blank,
        children: [
          {
            path: "/",
            redirect: "/app/admin/delivery-advice/list",
          },
          {
            path: ":id",
            name: "app-admin-delivery-advice-edit-id",
            component: DeliveryAdviceEdit,
          }
        ]
      },
      {
        path: "details",
        name: "app-admin-delivery-advice-details",
        component: Blank,
        children: [
          {
            path: "/",
            redirect: "/app/admin/delivery-advice/list",
          },
          {
            path: ":id",
            name: "app-admin-delivery-advice-details-id",
            component: DeliveryAdviceDetails,
          }
        ]
      },
    ]
  },
]

export default deliveryAdviceRoutes