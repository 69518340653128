<template>
  <v-container class="Details-View">
    <v-row class="heading">
      <v-col cols="12">
        <div class="d-flex flex-column">
          <Tooltip
            tooltipTitle="Driver Name"
            v-if="driverDetails.driver_name"
            :tooltipText="`${driverDetails.driver_name}`"
            top
            tooltipClass="text-h5 font-weight-medium"
          />

          <Tooltip
            tooltipTitle="Contact Number"
            v-if="driverDetails.full_contact_number"
            :tooltipText="driverDetails.full_contact_number"
            right
            tooltipClass="text-subtitle-1 font-weight-medium"
          />
        </div>
      </v-col>
    </v-row>

    <v-row v-if="Object.keys(driverDetails).length">
      <v-col cols="12" class="px-0">
        <v-tabs fixed-tabs v-model="detailsTab">
          <v-tabs-slider color="white"></v-tabs-slider>
          <v-tab>Detail View</v-tab>
          <v-tab v-if="!readOnly">Driver Leaves</v-tab>
          <v-tab v-if="!readOnly">Documents</v-tab>
          <v-tab v-if="!readOnly">Logs</v-tab>
        </v-tabs>
      </v-col>

      <v-col cols="12">
        <v-tabs-items v-model="detailsTab">
          <v-tab-item class="Height-Class">
            <v-expansion-panels focusable multiple v-model="driverDetailView">
              <CardDetails
                title="Driver Info"
                :displayObject="driverDetails.extra_info"
              />
            </v-expansion-panels>

            <v-expansion-panels
              v-if="
                driverDetails.driver_tags &&
                driverDetails.driver_tags.length > 0
              "
              class="mt-4"
              focusable
              multiple
              v-model="driverTags"
            >
              <CardDetails
                title="Driver Tags"
                :displayObject="driverDetails.driver_tags"
              />
            </v-expansion-panels>
          </v-tab-item>

          <v-tab-item v-if="!readOnly">
            <div class="text-right">
              <v-btn
                color="primary"
                depressed
                @click="addDriverLeave"
                small
                class="mb-2"
                v-if="permissions && permissions.add"
              >
                <v-icon>mdi-plus</v-icon>
                <span>Add Leave</span>
              </v-btn>
            </div>

            <v-list class="Leave-Height-Class py-0">
              <v-list-item
                class="subHeading rounded-lg mb-2"
                v-for="(item, i) in driverLeavesList"
                :key="i"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-subtitle-1 font-weight-medium">
                    {{ `${i + 1}. ${item.leave_type}` }}
                  </v-list-item-title>

                  <v-list-item-subtitle class="pt-2">
                    <p class="mb-2">
                      Status : <strong>{{ item.status }}</strong>
                    </p>

                    <p class="mb-2">
                      Number of Days : <strong>{{ item.total_days }}</strong>
                    </p>

                    <p class="mb-0">
                      From
                      <strong>{{ item.start_date | formatDate }}</strong> to
                      <strong>{{ item.end_date | formatDate }}</strong>
                    </p>
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-icon>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        @click="editDriverLeave(item.id)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-tab-item>

          <v-tab-item v-if="!readOnly" class="Height-Class">
            <DocumentList :documentList="driverDetails.driver_documents" />
          </v-tab-item>

          <v-tab-item v-if="!readOnly">
            <v-sheet
              style="
                height: calc(100vh - 180px);
                overflow-y: auto;
                overflow-x: hidden;
              "
            >
              <v-timeline
                v-if="logs && logs.length"
                class="px-0"
                align-top
                dense
              >
                <v-timeline-item
                  v-for="(log, index) in logs"
                  :key="index"
                  color="primary"
                  icon="mdi-circle"
                  small
                >
                  <v-row class="pt-1">
                    <v-col>
                      <strong>{{ log.created | formatDate(true) }}</strong>
                      <div class="text-body-2">{{ log.message }}</div>
                      <div class="text-caption">- {{ log.updated_by }}</div>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
              <div v-else>No Logs to show</div>
            </v-sheet>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <DriverLeaveForm
      v-model="showDriverLeaveForm"
      :driverId="driverId"
      :editDriverLeaveID="selectedDriverLeaveId"
      :editMode="editMode"
      @refreshList="getDriverLeaveList"
    />
  </v-container>
</template>

<script>
import CardDetails from "@/components/General/CardDetails.vue";
import DocumentList from "@/components/General/DocumentList.vue";
import Tooltip from "@/components/General/Tooltip.vue";
import DriverLeaveForm from "../dialogForms/DriverLeaveForm.vue";

export default {
  components: { CardDetails, Tooltip, DriverLeaveForm, DocumentList },
  props: {
    driverId: {
      type: Number,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    driverId(val) {
      this.logs = [];
      this.getDriverDetails(val);
      this.driverActivityLogs();
      this.getDriverLeaveList();
    },
  },
  data() {
    return {
      detailsTab: 0,
      driverDetails: {},
      driverLeavesList: [],
      driverTags: [],
      // Driver Leave vars
      editMode: false,
      showDriverLeaveForm: false,
      selectedDriverLeaveId: null,

      driverDetailView: [0],
      logs: [],
    };
  },
  methods: {
    getDriverDetails(id) {
      this.$api.driver
        .getDriverDetails(id)
        .then((res) => {
          this.driverDetails = res.data;
          res.data.driver_tags.forEach((element, i) => {
            this.driverTags.push(i);
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    addDriverLeave() {
      this.selectedDriverLeaveId = null;
      this.editMode = false;
      this.showDriverLeaveForm = true;
    },
    editDriverLeave(id) {
      this.selectedDriverLeaveId = id;
      this.editMode = true;
      this.showDriverLeaveForm = true;
    },
    getDriverLeaveList() {
      this.$api.driverLeave
        .getDriverLeaveList({ driver: this.driverId, limit: "all" })
        .then((res) => {
          this.driverLeavesList = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    driverActivityLogs(params = {}) {
      params = {
        ...params,
        driver: this.driverId,
        limit: "50",
        offset: this.logs.length,
      };
      this.$api.activityLogs
        .driverActivityLogs(params)
        .then((res) => {
          this.logs.push(...res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  mounted() {
    this.logs = [];
    this.getDriverDetails(this.driverId);
    this.getDriverLeaveList();
    this.driverActivityLogs();
  },
};
</script>

<style scoped>
.Height-Class {
  height: calc(100vh - 280px);
  overflow-y: auto;
  overflow-x: hidden;
}

.Leave-Height-Class {
  height: calc(100vh - 316px);
  overflow-y: auto;
}
</style>
