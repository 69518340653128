import axios from "@/plugins/axios"

import company from "@/services/company";
import authentication from "@/services/authentication";
import country from "@/services/country";
import currency from "@/services/currency";
import role from "@/services/role";
import user from "@/services/user";
import vehicle from "@/services/vehicle";
import branch from "@/services/branch";
import depot from "@/services/depot";
import trailer from "@/services/trailer";
import driver from "@/services/driver";
import driverApp from "@/services/driverApp";
import vehicleType from "@/services/vehicleType";
import trailerType from "@/services/trailerType";
import containerType from "@/services/containerType";
import lane from "@/services/lane";
import location from "@/services/location";
import bmr from "@/services/bmr";
import enquiry from "@/services/enquiry";
import customerContract from "@/services/customerContract";
import city from "@/services/city";
import companyFuelSurcharge from "@/services/companyFuelSurcharge";
import customerAddress from "@/services/customerAddress";
import emailSubscriber from "@/services/emailSubscriber";
import customerOrder from "@/services/customerOrder";
import deliveryAdvice from "@/services/deliveryAdvice";
import deliveryAdviceCharge from "@/services/deliveryAdviceCharge";
import daHazardousCargo from "@/services/daHazardousCargo";
import legCharges from "@/services/legCharges";
import tripMilestone from "@/services/tripMilestone";
import charge from "@/services/charge";
import checkList from "@/services/checkList";
import driverLeave from "@/services/driverLeave";
import maintenance from "@/services/maintenance";
import trip from "@/services/trip";
import department from "@/services/department";
import quotation from "@/services/quotation";
import dashboard from "@/services/dashboard";
import integration from "@/services/integration";
import milestone from "@/services/milestone";
import nationality from "@/services/nationality";
import general from "@/services/general";
import customerInvoice from "@/services/customerInvoice";
import transporterInvoice from "@/services/transporterInvoice";
import activityLogs from "@/services/activityLogs";
import reports from "@/services/reports";
import ticket from "@/services/ticket"
import unClass from "@/services/unClass"
import imoClass from "@/services/imoClass"
import adrClass from "@/services/adrClass"
import bankDetails from "@/services/bankDetails";
import report from "@/services/report";
import paymentReceipt from "@/services/paymentReceipt";
import bulkUpload from "@/services/bulkUpload";
import subHire from "@/services/subHire";

const api = {
  dashboard: dashboard(axios),
  company: company(axios),
  authentication: authentication(axios),
  country: country(axios),
  currency: currency(axios),
  city: city(axios),
  role: role(axios),
  user: user(axios),
  vehicle: vehicle(axios),
  trailer: trailer(axios),
  driver: driver(axios),
  driverApp: driverApp(axios),
  vehicleType: vehicleType(axios),
  depot: depot(axios),
  trailerType: trailerType(axios),
  containerType: containerType(axios),
  lane: lane(axios),
  location: location(axios),
  bmr: bmr(axios),
  enquiry: enquiry(axios),
  customerContract: customerContract(axios),
  companyFuelSurcharge: companyFuelSurcharge(axios),
  customerAddress: customerAddress(axios),
  legCharges: legCharges(axios),
  customerOrder: customerOrder(axios),
  deliveryAdvice: deliveryAdvice(axios),
  deliveryAdviceCharge: deliveryAdviceCharge(axios),
  daHazardousCargo: daHazardousCargo(axios),
  tripMilestone: tripMilestone(axios),
  charge: charge(axios),
  checkList: checkList(axios),
  driverLeave: driverLeave(axios),
  maintenance: maintenance(axios),
  trip: trip(axios),
  department: department(axios),
  quotation: quotation(axios),
  integration: integration(axios),
  milestone: milestone(axios),
  nationality: nationality(axios),
  general: general(axios),
  customerInvoice: customerInvoice(axios),
  transporterInvoice: transporterInvoice(axios),
  activityLogs: activityLogs(axios),
  reports: reports(axios),
  branch: branch(axios),
  ticket: ticket(axios),
  emailSubscriber: emailSubscriber(axios),
  unClass: unClass(axios),
  imoClass: imoClass(axios),
  adrClass: adrClass(axios),
  bankDetails: bankDetails(axios),
  report: report(axios),
  paymentReceipt: paymentReceipt(axios),
  bulkUpload: bulkUpload(axios),
  subHire: subHire(axios),
};

export default api;