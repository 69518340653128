<template>
  <v-dialog persistent max-width="50%" v-model="showCoordinateForm">
    <v-card>
      <v-card-text class="pa-2">
        <div>
          <v-alert dense type="info">
            Move marker if you find it's position inaccurate!
            <br />
            <br />
            Note: The "Marker" you plot will be used as actual location but you
            can add extra Information like local area name or landmarks in form.
          </v-alert>
        </div>
        <GoogleMap
          mapWidth="100%"
          mapHeight="50vh"
          ref="CoordinateMap"
          class="Map-Height"
        />
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn depressed color="primary" @click="confirmLocation"
          >Confirm</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import GoogleMap from "@/components/General/GoogleMap.vue";
export default {
  components: { GoogleMap },
  props: {
    value: {
      type: Boolean,
    },
    coordinates: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    value(val) {
      if (val) {
        if (this.coordinates) {
          this.setEventListener();
        }
      }
    },
  },
  data() {
    return {
      markerInstance: null,
    };
  },
  computed: {
    position: {
      get() {
        return this.coordinates;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    showCoordinateForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    setEventListener() {
      let interval = setInterval(() => {
        if (this.$refs.CoordinateMap) {
          clearInterval(interval);
          if (this.markerInstance) {
            this.markerInstance.setMap(null);
          }
          this.markerInstance = this.$refs.CoordinateMap.addMarker(
            this.coordinates,
            true
          );
        }
      }, 400);
    },
    confirmLocation() {
      let location = {
        latitude: this.markerInstance.getPosition().lat(),
        longitude: this.markerInstance.getPosition().lng(),
      };
      this.$emit("confirmLocation", location);
      this.showCoordinateForm = false;
    },
  },
};
</script>

<style>
</style>