<template>
  <v-dialog
    :persistent="persistent"
    :max-width="maxWidth"
    v-model="showFilterDialog"
  >
    <v-card>
      <v-card-title class="secondary white--text py-2 px-4">
        <span> {{ module }} Filters </span>

        <v-spacer></v-spacer>

        <v-btn depressed icon @click="closeDialog">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pa-2">
        <slot name="filterContent" />
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="justify-end">
        <slot name="filterAction" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: String,
      default: "50%",
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    module: {
      type: String,
      default: "Title",
    },
  },
  computed: {
    showFilterDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    closeDialog() {
      this.$emit("onDialogClose");
      this.showFilterDialog = false;
    },
  },
};
</script>

<style>
</style>