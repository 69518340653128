<template>
  <v-dialog width="500" v-model="showVehicleTypeForm">
    <v-card>
      <v-card-title class="secondary white--text py-2 px-4">
        <span> {{ editMode ? "Update" : "Add" }} Vehicle Type </span>

        <v-spacer />

        <v-btn icon @click="resetForm(), (showVehicleTypeForm = false)">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pa-2">
        <v-form v-model="isValid" ref="vehicleTypeForm">
          <v-row class="ma-0">
            <v-col cols="12" class="Form-Columns-Dense">
              <InputField
                type="text"
                label="Type Name *"
                :rules="[(v) => !!v || 'Type name is Required']"
                required
                hide-details="auto"
                v-model="vehicleTypeDetails.type_name"
                :error-messages="formErrors.type_name"
                @input="delete formErrors.type_name"
              />
            </v-col>
            <v-col cols="12" class="Form-Columns-Dense">
              <InputField
                type="number"
                label="Capacity in Ton *"
                step="0.01"
                min="0"
                :rules="[
                  (val) => !!val || 'Capacity is Required',
                  (val) =>
                    val > 0 ||
                    'Number of Capacity can not be less than or equal to 0',
                ]"
                required
                hide-details="auto"
                v-model="vehicleTypeDetails.payload_capacity"
                :error-messages="formErrors.payload_capacity"
                @input="delete formErrors.payload_capacity"
              />
            </v-col>
            <v-col cols="12" class="Form-Columns-Dense">
              <v-switch
                :label="`Trailer can be Attached : ${
                  vehicleTypeDetails.attach_trailer == 'Yes' ? 'Yes' : 'No'
                }`"
                true-value="Yes"
                false-value="No"
                class="mt-1"
                hide-details="auto"
                v-model="vehicleTypeDetails.attach_trailer"
                :error-messages="formErrors.attach_trailer"
                @change="delete formErrors.attach_trailer"
              />
            </v-col>
            <v-col cols="12" class="Form-Columns-Dense">
              <v-switch
                :label="`Temperature Controlled : ${
                  vehicleTypeDetails.temperature_controlled == 'Yes'
                    ? 'Yes'
                    : 'No'
                }`"
                true-value="Yes"
                false-value="No"
                class="mt-1"
                hide-details="auto"
                v-model="vehicleTypeDetails.temperature_controlled"
                :error-messages="formErrors.temperature_controlled"
                @change="delete formErrors.temperature_controlled"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          depressed
          :disabled="!isValid"
          @click="submitForm"
        >
          Submit
        </v-btn>
        <v-btn class="ml-3" color="secondary" depressed @click="resetForm">
          Reset
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";

export default {
  components: {
    InputField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    editVehicleTypeID: {
      type: [Number, null],
      default: null,
    },
  },
  watch: {
    value(val) {
      if (val) {
        if (this.editMode) {
          this.getVehicleTypeObject(this.editVehicleTypeID);
        }
      } else {
        this.resetForm();
      }
    },
  },
  computed: {
    showVehicleTypeForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    return {
      formErrors: {},
      isValid: true,

      vehicleTypeDetails: {
        attach_trailer: "No",
        temperature_controlled: "No",
      },
    };
  },
  methods: {
    submitForm() {
      this.$bus.$emit("showLoader", true);
      if (!this.editMode) {
        this.$api.vehicleType
          .addVehicleType(this.vehicleTypeDetails)
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Vehicle Type added successfully!",
              color: "success",
            });
            this.resetForm();
            this.showVehicleTypeForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't add Vehicle Type!",
              color: "error",
            });
            this.formErrors = err.data;
          });
      } else {
        this.$api.vehicleType
          .updateVehicleType({
            id: this.vehicleTypeDetails.id,
            data: this.vehicleTypeDetails,
          })
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Vehicle Type updated successfully!",
              color: "success",
            });
            this.resetForm();
            this.showVehicleTypeForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't update Vehicle Type!",
              color: "error",
            });
            this.formErrors = err.data;
          });
      }
    },

    resetForm() {
      if (this.$refs.vehicleTypeForm) {
        this.$refs.vehicleTypeForm.reset();
      }
      this.vehicleTypeDetails = {
        attach_trailer: "No",
        temperature_controlled: "No",
      };
    },

    getVehicleTypeObject(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.vehicleType
        .getVehicleTypeObject(id)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.vehicleTypeDetails = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
};
</script>