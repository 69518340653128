import ContainerTypeList from "@/pages/admin/lane-management/ContainerTypeList.vue";

const containerTypeRoutes = [
  {
    path: "container-type",
    name: "app-admin-lane-management-container-type",
    component: ContainerTypeList,
  },
]

export default containerTypeRoutes