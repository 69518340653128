<template>
  <ListComponent title="Milestone" :totalCount="totalItems">
    <template #list>
      <v-row class="ma-0">
        <v-col cols="4" class="px-0 pt-0 pb-2">
          <v-text-field
            label="Search"
            outlined
            dense
            append-icon="mdi-magnify"
            clearable
            hide-details="auto"
            v-model="filters.search"
            @input="searchMilestone"
          ></v-text-field>
        </v-col>

        <v-col v-if="totalItems > 0" cols="8" class="px-0 pt-0 pb-2 d-flex justify-end">
          <Pagination
            :pageNo="pageNo"
            :totalItems="totalItems"
            :pageSize="itemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>

        <v-col cols="12" class="pa-0">
          <AgGridVue
            style="width: 100%; height: calc(100vh - 172px)"
            class="ag-theme-alpine"
            id="myGrid"
            :columnDefs="headers"
            :context="context"
            @grid-ready="onGridReady"
            :defaultColDef="defaultColDef"
            :grid-options="gridOptions"
            :suppressDragLeaveHidesColumns="true"
            :rowData="allMilestoneList"
          ></AgGridVue>
        </v-col>
      </v-row>
    </template>

    <template #dialogs>
      <MilestoneForm
        v-if="permissions && (permissions.add || permissions.change)"
        v-model="showMilestoneForm"
        :editMode="isEditing"
        :editMilestoneID="editMilestoneID"
        @refreshList="itemsPerPageChanged(20)"
      />
    </template>

    <template #actions>
      <v-btn
        v-if="permissions && permissions.add"
        color="primary"
        depressed
        @click="addMilestone"
      >
        <v-icon>mdi-plus</v-icon>
        <span>Add Milestone</span>
      </v-btn>
    </template>
  </ListComponent>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

import ListComponent from "@/components/General/ListComponent.vue";
import AdminMilestoneButton from "@/components/AgGridComponents/AdminMilestoneButton.vue";
import MilestoneForm from "@/components/ModuleBased/dialogForms/MilestoneForm.vue";
import Pagination from "@/components/General/Pagination.vue";

export default {
  name: "MilestoneList",
  components: {
    ListComponent,
    AgGridVue,
    Pagination,
    MilestoneForm,
    AdminMilestoneButton,
  },
  data() {
    return {
      headers: [
        {
          headerName: "Milestone Name",
          field: "milestone_name",
          sortable: true,
        },
        {
          headerName: "Job Type",
          field: "job_type",
          sortable: true,
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: "AdminMilestoneButton",
          pinned: "right",
          width: 90,
        },
      ],
      allMilestoneList: [],
      permissions: null,

      // pagination vars
      pageNo: 1,
      itemsPerPage: 20,
      totalItems: 0,
      filters: {},

      // ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.gridOptions.api.sizeColumnsToFit();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },

      //Milestone form
      showMilestoneForm: false,
      isEditing: false,
      editMilestoneID: null,
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    searchMilestone() {
      this.pageNo = 1;
      this.getMilestoneList();
    },
    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getMilestoneList();
    },
    prevPage() {
      this.pageNo--;
      this.getMilestoneList();
    },
    nextPage() {
      this.pageNo++;
      this.getMilestoneList();
    },
    applyGridSort(key, type) {
      if (this.filters && !this.filters.ordering) {
        this.filters.ordering = [];
      }

      if (type == null) {
        delete this.sorting[key];
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(`-${key}`),
          1
        );
      } else if (type == "asc") {
        this.filters.ordering.push(key);
      } else if (type == "desc") {
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(key),
          1,
          `-${key}`
        );
      }
      if (this.filters.ordering.length == 0) {
        delete this.filters.ordering;
      }
      this.itemsPerPageChanged(20);
    },
    getMilestoneList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        offset: this.offset,
        limit: this.itemsPerPage,
        ...params,
        ...this.filters,
      };
      this.$api.milestone
        .getMilestoneList(params)
        .then((res) => {
          this.allMilestoneList = res.data;
          this.totalItems = res.count;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    addMilestone() {
      this.editMilestoneID = null;
      this.isEditing = false;
      this.showMilestoneForm = true;
    },
    editMilestone(id) {
      this.editMilestoneID = id;
      this.isEditing = true;
      this.showMilestoneForm = true;
    },
  },
  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("configuration");

      if (!permissions || !permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.permissions = { ...permissions };
        this.getMilestoneList();
      }
    }
  },
};
</script>