import { trailer } from '@/utils/constants'
import { handleError, handleResponse } from '@/utils/functions'

export default (axios) => ({
  getTrailerList(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(trailer.trailerBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res))
        })
        .catch((err) => {
          reject(handleError(err))
        })
    })
  },

  getTrailerOptions(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .options(trailer.trailerBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res))
        })
        .catch((err) => {
          reject(handleError(err))
        })
    })
  },

  getTrailerDetails(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${trailer.trailerBase}${id}/view/`)
        .then((res) => {
          resolve(handleResponse(res))
        })
        .catch((err) => {
          reject(handleError(err))
        })
    })
  },

  getTrailerObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${trailer.trailerBase}${id}/`)
        .then((res) => {
          resolve(handleResponse(res))
        })
        .catch((err) => {
          reject(handleError(err))
        })
    })
  },

  addTrailer(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(trailer.trailerBase, data)
        .then((res) => {
          resolve(handleResponse(res))
        })
        .catch((err) => {
          reject(handleError(err))
        })
    })
  },

  updateTrailer(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${trailer.trailerBase}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res))
        })
        .catch((err) => {
          reject(handleError(err))
        })
    })
  },

  partiallyUpdateTrailer(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${trailer.trailerBase}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res))
        })
        .catch((err) => {
          reject(handleError(err))
        })
    })
  },

  uploadTrailerDocument(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${trailer.trailerBase}${payload.id}/upload/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res))
        })
        .catch((err) => {
          reject(handleError(err))
        })
    })
  },

  deleteTrailerDocument(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${trailer.trailerDocBase}${id}/`)
        .then((res) => {
          resolve(handleResponse(res))
        })
        .catch((err) => {
          reject(handleError(err))
        })
    })
  },
})