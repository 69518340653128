<template>
  <ListComponent title="Route" :totalCount="totalItems">
    <template #list>
      <v-row class="ma-0">
        <v-col cols="4" class="px-0 pt-0 pb-2 d-flex align-center">
          <v-text-field
            label="Search On Enter"
            outlined
            dense
            append-icon="mdi-magnify"
            clearable
            @click:clear="onEnter()"
            hide-details="auto"
            v-model="filters.search"
            v-on:keyup.enter="onEnter"
          ></v-text-field>

          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                depressed
                icon
                v-on="on"
                v-bind="attrs"
                @click="showFilterDialog = true"
                class="mx-2"
              >
                <v-badge
                  dot
                  color="primary"
                  :value="Object.keys(filters).length"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-badge>
              </v-btn>
            </template>

            <span v-if="Object.keys(filters).length == 0">Filters</span>

            <v-row v-else no-gutters>
              <v-col cols="12" class="font-weight-bold">Applied Filters</v-col>
              <v-col cols="12">
                <span
                  v-for="(value, key) in filters"
                  :key="key"
                  class="text-capitalize"
                  >{{ key.replace("_", " ") }}
                  <br />
                </span>
              </v-col>
            </v-row>
          </v-tooltip>

          <Columns :headers="headers" :gridColInstance="gridColumnApi" />
           <v-spacer></v-spacer>
           
           <span class="text-h6 font-weight-regular ml-5">
            Selected Route-
            <span class="font-weight-bold"> {{ routesCount }} </span>
        </span>
        </v-col>

        <v-col
          v-if="totalItems > 0"
          cols="8"
          class="px-0 pt-0 pb-2 d-flex justify-end"
        >
          <Pagination
            :pageNo="pageNo"
            :totalItems="totalItems"
            :pageSize="itemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>

        <v-col cols="12" class="pa-0">
          <AgGridVue
            style="width: 100%; height: calc(100vh - 172px)"
            class="ag-theme-alpine"
            id="myGrid"
            :columnDefs="headers"
            :context="context"
            @grid-ready="onGridReady"
            @columnMoved="resizeGrid"
            @columnVisible="resizeGrid"
            :defaultColDef="defaultColDef"
            :grid-options="gridOptions"
            :suppressDragLeaveHidesColumns="true"
            :rowData="allLaneList"
            rowSelection="multiple"
            @selection-changed="onSelectionChanged"
          ></AgGridVue>
        </v-col>
      </v-row>

      <DetailsParent :resizable="true" v-model="detailsDrawer">
        <template #details>
          <LaneDetails :laneObj="laneObj" :permissions="permissions" />
        </template>
      </DetailsParent>
    </template>

    <template #dialogs>
      <AdminLaneFilters
        v-model="showFilterDialog"
        :bindingObject="filters"
        @resetFilters="resetFilters"
        @applyFilters="itemsPerPageChanged(20)"
      />
      <BulkUpdateRouteForm
        v-model="showBulkUpdateForm"
        :routeData="selectedRoute"
        @closeRouteForm="closeBulkUpdateForm"
        @refreshList="
          getLaneList();
          selectedRoute = [];
        "
      />
      <BulkDownload
        v-model="routeBulkDownloadDialog"
        :downloadTo="downloadType"
      />
      <BulkUpload
        v-model="routeBulkUploadDialog"
        :uploadTo="uploadType"
        @refreshList="itemsPerPageChanged(20)"
      />
    </template>

    <template #actions>
      <v-btn
        v-if="permissions && permissions.add"
        @click="addLane"
        color="primary"
        depressed
      >
        <v-icon>mdi-plus</v-icon>
        <span>Add Route</span>
      </v-btn>

      <v-btn
        v-if="permissions && permissions.add"
        @click="updateBulkRoute()"
        color="primary"
        depressed
        class="mx-1"
      >
        <v-icon>mdi-plus</v-icon>
        <span>Bulk Update</span>
      </v-btn>
      <v-btn
        v-if="permissions && permissions.add"
        @click="openRouteBulkUpdate('route')"
        color="primary"
        depressed
      >
        <v-icon>mdi-plus</v-icon>
        <span>Update Bonus</span>
      </v-btn>
    </template>
  </ListComponent>
</template>

<script>
import Columns from "@/components/General/Columns.vue";
import ListComponent from "@/components/General/ListComponent.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";
import LaneButton from "@/components/AgGridComponents/LaneButton.vue";
import LaneDetails from "@/components/ModuleBased/details/LaneDetails.vue";
import DetailsParent from "@/components/General/DetailsParent.vue";
import CustomHeader from "@/components/AgGridComponents/General/CustomHeader.vue";
import AdminLaneFilters from "@/components/ModuleBased/dialogs/filters/AdminLaneFilters.vue";
import BulkUpdateRouteForm from "@/components/ModuleBased/dialogForms/BulkUpdateRouteForm.vue";
import BulkDownload from "@/components/General/BulkDownload.vue";
import BulkUpload from "@/components/General/BulkUpload.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    ListComponent,
    Columns,
    AgGridVue,
    Pagination,
    LaneButton,
    LaneDetails,
    DetailsParent,
    agColumnHeader: CustomHeader,
    AdminLaneFilters,
    BulkUpdateRouteForm,
    BulkDownload,
    BulkUpload,
  },
  data() {
    return {
      sorting: {},
      headers: [
        {
          headerName: "Source",
          field: "source",
          sortable: true,
          tooltipField: "source",
          minWidth: 250,
          checkboxSelection: true,
        },
        {
          headerName: "Mid",
          field: "mid",
          sortable: true,
          tooltipField: "mid",
          minWidth: 250,
        },
        {
          headerName: "Destination",
          field: "destination",
          sortable: true,
          tooltipField: "destination",
          minWidth: 250,
        },
        {
          headerName: "Route Code",
          field: "title",
          sortable: false,
          tooltipField: "title",
          minWidth: 130,
        },
        {
          headerName: "Service Type",
          field: "service_type",
          sortable: true,
          cellStyle: { "text-transform": "capitalize" },
          minWidth: 120,
        },
        {
          headerName: "Distance",
          field: "distance",
          sortable: true,
          minWidth: 80,
        },
        {
          headerName: "Bonus",
          field: "bonus",
          minWidth: 80,
        },
        {
          headerName: "Duration (Min)",
          field: "duration",
          sortable: true,
          minWidth: 80,
        },
        {
          headerName: "Is Active",
          field: "is_active",
          sortable: true,
          minWidth: 80,
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: "LaneButton",
          pinned: "right",
          minWidth: 110,
        },
      ],
      allLaneList: [],

      permissions: null,

      // pagination vars
      itemsPerPage: 20,
      pageNo: 1,
      totalItems: 0,

      // filter vars
      filters: {},
      showFilterDialog: false,
      downloadType: "route",
      uploadType: "route",
      showBulkUpdateForm: false,
      routeBulkDownloadDialog: false,
      routeBulkUploadDialog: false,
      selectedRoute: [],
      routesCount: 0,

      // ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },

      // Details vars
      detailsDrawer: false,
      laneObj: null,
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },
  methods: {
    openRouteBulkUpdate(type) {
      this.uploadType = type;
      this.routeBulkUploadDialog = true;
    },

    onEnter() {
      this.routesCount = 0;
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(() => {
        this.pageNo = 1;
        this.getLaneList();
      }, 100);
    },
    updateBulkRoute() {
      this.showBulkUpdateForm = true;
    },
    closeBulkUpdateForm() {
      this.selectedRoute = [];
      this.getLaneList();
      this.showBulkUpdateForm = false;
    },
    resizeGrid() {
      if (this.gridOptions && this.gridOptions.api) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },
    resetFilters() {
      this.filters = {};
      this.itemsPerPageChanged(20);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },
    searchLane() {
      this.routesCount = 0;
      this.pageNo = 1;
      this.getLaneList();
    },
    itemsPerPageChanged(e) {
      this.routesCount = 0;
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getLaneList();
    },
    prevPage() {
      this.routesCount = 0;
      this.pageNo--;
      this.getLaneList();
    },
    nextPage() {
      this.routesCount = 0;
      this.pageNo++;
      this.getLaneList();
    },
    applyGridSort(key, type) {
      if (this.filters && !this.filters.ordering) {
        this.filters.ordering = [];
      }

      if (type == null) {
        delete this.sorting[key];
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(`-${key}`),
          1
        );
      } else if (type == "asc") {
        this.filters.ordering.push(key);
      } else if (type == "desc") {
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(key),
          1,
          `-${key}`
        );
      }
      if (this.filters.ordering.length == 0) {
        delete this.filters.ordering;
      }
      this.itemsPerPageChanged(20);
    },
    onSelectionChanged() {
      this.selectedRoute = this.gridApi.getSelectedRows();
      this.routesCount =  this.selectedRoute.length;
    },
    getLaneList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        offset: this.offset,
        limit: this.itemsPerPage,
        ...params,
        ...this.filters,
      };
      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }
      this.$api.lane
        .getLaneList(params)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.allLaneList = res.data;
          this.totalItems = res.count;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    addLane() {
      this.$router.push("/app/admin/lane-management/lane/add");
    },
    editLane(id) {
      this.$router.push(`/app/admin/lane-management/lane/edit/${id}`);
    },
    viewLane(obj) {
      this.laneObj = obj;
      this.detailsDrawer = true;
    },
    deleteLane() {
      this.$bus.$emit("showToastMessage", {
        message: "This is a test",
      });
    },
  },
  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("lane");
      if (!permissions || !permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.permissions = { ...permissions };
        this.getLaneList();
      }
    }
  },
};
</script>
