var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
          _vm.params.context &&
          _vm.params.context.parentComponent &&
          _vm.params.context.parentComponent.invoiceCreditNoteDetails &&
          _vm.params.context.parentComponent.invoiceCreditNoteDetails.status != 'Accepted'
        )?_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1 mr-2",attrs:{"depressed":"","icon":""},on:{"click":_vm.editCharges}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-pencil")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Edit Charge")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
          _vm.params.context &&
          _vm.params.context.parentComponent &&
          _vm.params.context.parentComponent.invoiceCreditNoteDetails &&
          _vm.params.context.parentComponent.invoiceCreditNoteDetails.status !=
            'Accepted'
        )?_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2 mr-1",attrs:{"depressed":"","icon":""},on:{"click":_vm.removeDa}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-trash-can")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Remove From Credit Note")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }