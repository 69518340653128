<template>
  <v-dialog v-model="showInvoicePreview">
    <v-card>
      <v-card-title>
        <span class="text-h5 font-weight-bold">Invoice Preview</span>

        <v-spacer></v-spacer>
        <v-btn color="primary" class="mr-2" depressed @click="generateInvoice">
          Generate Invoice
        </v-btn>

        <v-btn icon color="primary" @click="showInvoicePreview = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-row>
          <v-col cols="12" v-for="(invoice, i) in invoiceList" :key="i">
            <v-row>
              <v-col cols="12" class="d-flex justify-center align-center">
                <span class="pa-4 text-h6 font-weight-bold">
                  Invoice - {{ i + 1 }}
                </span>
              </v-col>

              <v-col cols="12">
                <v-data-table
                  :headers="daHeaders"
                  :items="invoice.delivery_advice_details"
                  disable-pagination
                  hide-default-footer
                  class="elevation-1"
                >
                </v-data-table>
              </v-col>

              <v-col cols="12">
                <InvoiceTemplateNew :invoiceDetails="invoice" />
              </v-col>

              <v-col cols="12" v-if="i != invoiceList.length - 1">
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import InvoiceTemplate from "@/components/General/InvoiceTemplate.vue";
import InvoiceTemplateNew from "@/components/General/InvoiceTemplateNew.vue";
export default {
  components: { InvoiceTemplate, InvoiceTemplateNew },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    invoiceObject: {
      type: Object,
      required: true,
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.getInvoicePreviewDetails();
      }
    },
  },
  data() {
    return {
      invoiceList: [],

      daHeaders: [
        {
          text: "Delivery Advice No.",
          value: "da_no",
        },
        {
          text: "Container Type",
          value: "da_container_details.da_container_type",
        },
        {
          text: "Container Count",
          value: "da_container_details.da_container_count",
        },
        {
          text: "Container No.",
          value: "da_container_details.da_container_no",
        },
        {
          text: "Created At",
          value: "created",
        },
        {
          text: "Movement Date",
          value: "movement_date",
        },
        {
          text: "BOE",
          value: "boe",
        },
        {
          text: "Source",
          value: "source_location_code",
        },
        {
          text: "Mid",
          value: "mid_location_code",
        },
        {
          text: "Destination",
          value: "destination_location_code",
        },
        {
          text: `Rate (${this.$globals.currency})`,
          value: "rate",
        },
        {
          text: `Tax Amount (${this.$globals.currency})`,
          value: "tax_amount",
        },
        {
          text: `Additional Charges(${this.$globals.currency})`,
          value: "da_additional_charges",
        },
      ],
    };
  },
  computed: {
    showInvoicePreview: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    getInvoicePreviewDetails() {
      this.$bus.$emit("showLoader", true);

      let payload = {
        ...this.invoiceObject,
      };
      if (
        "delivery_advices" in payload &&
        payload.delivery_advices.length > 0
      ) {
        payload.delivery_advices = payload.delivery_advices.map(
          (obj) => obj.id
        );
      }

      this.$api.deliveryAdvice
        .generateInvoiceFromDA(payload)
        .then((res) => {
          this.invoiceList = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    generateInvoice() {
      this.$bus.$emit("showLoader", true);
      let payload = JSON.parse(JSON.stringify(this.invoiceList));
      payload.map((obj) => {
        delete obj.company_details;
        delete obj.delivery_advice_details;
        delete obj.total_gross_amount_words;
        obj.destination_country = obj.destination_country;
        return obj;
      });
      this.$api.customerInvoice
        .addCustomerInvoiceBulkCreate(payload)
        .then((res) => {
          this.$bus.$emit("showToastMessage", {
            message: "Invoice created successfully",
            color: "success",
          });
          this.$bus.$emit("showLoader", false);
          this.$router.push("/app/admin/invoice/customer/list");
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
};
</script>

