<template>
  <v-navigation-drawer
    ref="detailsDrawer"
    v-if="detailsDrawer"
    right
    v-model="detailsDrawer"
    :clipped="true"
    app
    :width="optionsChanged.width"
    height="100%"
  >
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click="detailsDrawer = false"
          name="details-drawer-close"
          class="Drawer-Close-Button"
          absolute
          right
          fab
          small
          depressed
          v-bind="attrs"
          v-on="on"
          color="primary"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>

      <span>Close Drawer</span>
    </v-tooltip>

    <slot name="details" />
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },

    options: {
      type: Object,
      default: () => ({
        width: 500,
        borderSize: 3,
      }),
    },

    resizable: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    value(val) {
      if (val) {
        if (this.resizable) {
          let interval = setInterval(() => {
            if (this.$refs.detailsDrawer) {
              clearInterval(interval);
              this.setBorderWidth();
              this.setEvents();
            }
          }, 500);
        }
      }
    },
  },

  computed: {
    detailsDrawer: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    optionsChanged: {
      get() {
        return this.options;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    maxWidth() {
      return window.innerWidth;
    },
  },
  methods: {
    setBorderWidth() {
      let i = this.$refs.detailsDrawer.$el.querySelector(
        ".v-navigation-drawer__border"
      );
      i.style.width = this.options.borderSize + "px";
      i.style.cursor = "ew-resize";
      i.style.backgroundColor = "#BDBDBD";
    },
    setEvents() {
      const maxWidth = window.innerWidth / 2;
      const minSize = 500;
      const el = this.$refs.detailsDrawer.$el;
      const drawerBorder = el.querySelector(".v-navigation-drawer__border");
      const vm = this;
      const direction = el.classList.contains("v-navigation-drawer--right")
        ? "right"
        : "left";

      const resize = (e) => {
        e.preventDefault();
        document.body.style.cursor = "ew-resize";
        let f =
          direction === "right"
            ? document.body.scrollWidth - e.clientX
            : e.clientX;
        if (f < maxWidth && f > minSize) {
          el.style.width = f + "px";
        }
      };

      drawerBorder.addEventListener(
        "mousedown",
        (e) => {
          e.preventDefault();
          if (e.offsetX < minSize) {
            el.style.transition = "initial";
            document.addEventListener("mousemove", resize, false);
          }
        },
        false
      );

      document.addEventListener(
        "mouseup",
        (e) => {
          e.preventDefault();
          el.style.transition = "";

          this.optionsChanged.width = el.style.width;
          document.body.style.cursor = "";
          document.removeEventListener("mousemove", resize, false);
        },
        false
      );
    },
  },
};
</script>

<style></style>
