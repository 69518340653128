var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"justify-center align-center Login-Section"},[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"6","lg":"4"}},[_c('v-form',{ref:"forgotPasswordForm",on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-card',{staticClass:"pa-2",attrs:{"elevation":"10"}},[_c('v-card-title',{staticClass:"d-flex justify-center"},[_c('img',{staticClass:"Brand-Logo mb-10",attrs:{"alt":"Brand Logo","src":require("@/assets/images/brand-logo.png")}})]),_c('v-card-text',[_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"cols":"12"}},[_c('InputField',{staticStyle:{"width":"calc(100% - 120px)","float":"left"},attrs:{"hide-details":"auto","label":"Username","autocomplete":"off","required":"","dense":false,"rules":[(val) => !!val || 'Username is required']},model:{value:(_vm.userDetails.username),callback:function ($$v) {_vm.$set(_vm.userDetails, "username", $$v)},expression:"userDetails.username"}}),_c('v-btn',{staticClass:"ml-2",attrs:{"type":"button","color":"primary","depressed":"","disabled":!_vm.userDetails.username || _vm.disableOtpBtn},on:{"click":_vm.sendOtp}},[_vm._v(" "+_vm._s(!_vm.disableOtpBtn ? "Get Otp" : `${_vm.timer}s`)+" ")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('InputField',{attrs:{"hide-details":"auto","label":"Password","autocomplete":"off","required":"","dense":false,"rules":[(val) => !!val || 'Password is required'],"append-icon":_vm.passwordEye ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.passwordEye ? 'text' : 'password'},on:{"click:append":function($event){_vm.passwordEye = !_vm.passwordEye},"input":_vm.validateForm},model:{value:(_vm.userDetails.new_password),callback:function ($$v) {_vm.$set(_vm.userDetails, "new_password", $$v)},expression:"userDetails.new_password"}})],1),_c('v-col',{class:{
                'pb-0':
                  _vm.userDetails.new_password &&
                  _vm.userDetails.confirm_password &&
                  _vm.userDetails.confirm_password != _vm.userDetails.new_password,
              },attrs:{"cols":"12"}},[_c('InputField',{attrs:{"hide-details":"auto","label":"Confirm Password","autocomplete":"off","required":"","dense":false,"rules":[(val) => !!val || 'Confirm Password is required'],"type":_vm.passwordEye ? 'text' : 'password'},on:{"input":_vm.validateForm},model:{value:(_vm.userDetails.confirm_password),callback:function ($$v) {_vm.$set(_vm.userDetails, "confirm_password", $$v)},expression:"userDetails.confirm_password"}})],1),(
                _vm.userDetails.new_password &&
                _vm.userDetails.confirm_password &&
                _vm.userDetails.confirm_password != _vm.userDetails.new_password
              )?_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('span',{staticClass:"px-3 error--text v-messages"},[_vm._v(" Password dosen't match. ")])]):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('InputField',{attrs:{"hide-details":"auto","label":"Verification Code","autocomplete":"off","required":"","dense":false,"rules":[(val) => !!val || 'Verification Code is required']},model:{value:(_vm.userDetails.verification_code),callback:function ($$v) {_vm.$set(_vm.userDetails, "verification_code", $$v)},expression:"userDetails.verification_code"}})],1)],1)],1),_c('v-card-actions',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"type":"submit","color":"primary","depressed":"","disabled":!_vm.isValid ||
                  (_vm.userDetails.new_password &&
                    _vm.userDetails.confirm_password &&
                    _vm.userDetails.confirm_password !== _vm.userDetails.new_password),"loading":_vm.btnLoading,"large":""}},[_vm._v(" Submit ")])],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"type":"button","text":"","color":"primary","depressed":"","large":""},on:{"click":_vm.goToLogin}},[_vm._v(" Go To Login ")])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }