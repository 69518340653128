import { handleError, handleResponse } from "@/utils/functions";
import { version, version2 } from "@/utils/constants";




export default (axios) => ({
    bulkUpload(payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${version2}bulk_upload/`, payload)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(err.response.data);

                });
        });
    },
    bulkUploadFile(url, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${url}`, payload)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(err.response.data);

                });
        });
    },
    downloadSampleFile(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/api/v2/get_excel_sample/`, {
                    params: params,
                    responseType: "blob",
                })
                .then((res) => {
                    resolve(res.data);
                    // resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },
});