<template>
  <ListComponent title="Countries" :totalCount="totalItems">
    <template #list>
      <v-row class="ma-0">
        <v-col cols="4" class="px-0 pt-0 pb-2">
          <v-text-field
            label="Search"
            outlined
            dense
            append-icon="mdi-magnify"
            clearable
            hide-details="auto"
            v-model="filters.search"
            @input="searchCountry"
          ></v-text-field>
        </v-col>

        <v-col
          v-if="totalItems > 0"
          cols="8"
          class="px-0 pt-0 pb-2 d-flex justify-end"
        >
          <Pagination
            :pageNo="pageNo"
            :totalItems="totalItems"
            :pageSize="itemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>

        <v-col cols="12" class="pa-0">
          <AgGridVue
            style="width: 100%; height: calc(100vh - 172px)"
            class="ag-theme-alpine"
            id="myGrid"
            :columnDefs="headers"
            :context="context"
            @grid-ready="onGridReady"
            :defaultColDef="defaultColDef"
            :grid-options="gridOptions"
            :suppressDragLeaveHidesColumns="true"
            :rowData="allCountryList"
          ></AgGridVue>
        </v-col>
      </v-row>

      <DetailsParent :resizable="true" v-model="detailsDrawer">
        <template #details>
          <CountryDetails :countryObj="countryObj" :permissions="permissions" />
        </template>
      </DetailsParent>
    </template>

    <template #dialogs>
      <CountryForm
        v-if="permissions && (permissions.add || permissions.change)"
        v-model="showCountryForm"
        :editMode="isEditing"
        :editCountryID="editCountryID"
        @refreshList="itemsPerPageChanged(20)"
      />
    </template>

    <template #actions>
      <v-btn
        v-if="permissions && permissions.add"
        color="primary"
        depressed
        @click="addCountry"
      >
        <v-icon>mdi-plus</v-icon>
        <span>Add Country</span>
      </v-btn>
    </template>
  </ListComponent>
</template>

<script>
import ListComponent from "@/components/General/ListComponent.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";
import CountryButton from "@/components/AgGridComponents/CountryButton.vue";
import CountryForm from "@/components/ModuleBased/dialogForms/CountryForm.vue";
import DetailsParent from "@/components/General/DetailsParent.vue";
import CountryDetails from "@/components/ModuleBased/details/CountryDetails.vue";
import CustomHeader from "@/components/AgGridComponents/General/CustomHeader.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    ListComponent,
    AgGridVue,
    Pagination,
    CountryButton,
    CountryForm,
    DetailsParent,
    CountryDetails,
    agColumnHeader: CustomHeader,
  },
  data() {
    return {
      sorting: {},
      headers: [
        {
          headerName: "Country Name",
          field: "name",
          sortable: true,
        },
        {
          headerName: "Country Code",
          field: "country_code",
          sortable: true,
        },
        {
          headerName: "Phone Code",
          field: "phone_code",
          sortable: true,
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: "CountryButton",
          pinned: "right",
          width: 90,
        },
      ],
      allCountryList: [],
      detailsDrawer: false,
      countryObj: null,

      // pagination vars
      pageNo: 1,
      itemsPerPage: 20,
      totalItems: 0,
      filters: {},

      // ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.gridOptions.api.sizeColumnsToFit();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
      },

      //Country form
      showCountryForm: false,
      isEditing: false,
      editCountryID: null,

      permissions: null,
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    currentProfile() {
      if (JSON.parse(localStorage.getItem("userProfile"))) {
        return JSON.parse(localStorage.getItem("userProfile"));
      } else {
        return null;
      }
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },
  methods: {
    applyGridSort(key, type) {
      if (this.filters && !this.filters.ordering) {
        this.filters.ordering = [];
      }

      if (type == null) {
        delete this.sorting[key];
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(`-${key}`),
          1
        );
      } else if (type == "asc") {
        this.filters.ordering.push(key);
      } else if (type == "desc") {
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(key),
          1,
          `-${key}`
        );
      }
      if (this.filters.ordering.length == 0) {
        delete this.filters.ordering;
      }
      this.itemsPerPageChanged(20);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    searchCountry() {
      this.pageNo = 1;
      this.getCountryList();
    },
    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getCountryList();
    },
    prevPage() {
      this.pageNo--;
      this.getCountryList();
    },
    nextPage() {
      this.pageNo++;
      this.getCountryList();
    },
    getCountryList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        company: this.currentProfile.company,
        offset: this.offset,
        limit: this.itemsPerPage,
        ...params,
        ...this.filters,
      };
      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }
      this.$api.country
        .getCountryList(params)
        .then((res) => {
          this.allCountryList = res.data;
          this.totalItems = res.count;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    addCountry() {
      this.editCountryID = null;
      this.isEditing = false;
      this.showCountryForm = true;
    },
    editCountry(id) {
      this.editCountryID = id;
      this.isEditing = true;
      this.showCountryForm = true;
    },
    viewCountry(obj) {
      this.countryObj = obj;
      this.detailsDrawer = true;
    },
  },
  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("configuration");
      if (!permissions || !permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.permissions = { ...permissions };
        this.getCountryList();
      }
    }
  },
};
</script>
