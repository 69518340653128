<template>
  <ListComponent title="Company Edit">
    <template #list>
      <v-form ref="compantEditForm" v-model="compantEditForm">
        <v-row class="ma-0">
          <v-col cols="12" class="px-0 pb-0">
            <span class="text-h6 font-weight-regular">Basic Details</span>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" class="px-1">
            <InputField
              type="text"
              label="Customer Code *"
              :rules="[(val) => !!val || 'Customer Code is Required!']"
              disabled
              hide-details="auto"
              v-model="companyDetails.company_code"
              :error-messages="formErrors.company_code"
              @input="delete formErrors.company_code"
            />
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" class="px-1">
            <InputField
              type="text"
              label="Customer Name *"
              :rules="[(val) => !!val || 'Customer Name is Required!']"
              required
              hide-details="auto"
              v-model="companyDetails.company_name"
              :error-messages="formErrors.company_name"
              @input="delete formErrors.company_name"
            />
          </v-col>

          <v-col cols="12" class="px-0 pb-0">
            <span class="text-h6 font-weight-regular"> Address Details </span>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" class="px-1">
            <InputField
              type="text"
              label="Address Line 1 *"
              :rules="[(val) => !!val || 'Address Line 1 is Required!']"
              required
              hide-details="auto"
              v-model="companyDetails.address_line_1"
              :error-messages="formErrors.address_line_1"
              @input="delete formErrors.address_line_1"
            />
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" class="px-1">
            <InputField
              type="text"
              label="Address Line 2"
              hide-details="auto"
              v-model="companyDetails.address_line_2"
              :error-messages="formErrors.address_line_2"
              @input="delete formErrors.address_line_2"
            />
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" class="px-1">
            <MultiSelect
              label="Country *"
              :items="countryList"
              itemText="name"
              itemValue="id"
              :rules="[(val) => !!val || 'Country is Required!']"
              :returnObject="true"
              hide-details="auto"
              v-model="companyDetails.country"
              :error-messages="formErrors.country"
              @change="delete formErrors.country, checkData($event, 'country')"
            ></MultiSelect>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" class="px-1">
            <MultiSelect
              label="City *"
              :items="cityList"
              itemText="city_name"
              itemValue="id"
              hide-details="auto"
              :rules="[(val) => !!val || 'City is Required!']"
              v-model="companyDetails.city"
              :error-messages="formErrors.city"
              @change="delete formErrors.city"
            ></MultiSelect>
          </v-col>

          <v-col cols="12" class="px-0 pb-0">
            <span class="text-h6 font-weight-regular"> Contact Details </span>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" class="px-1">
            <InputField
              type="number"
              label="Phone Code *"
              readonly
              :clearable="false"
              :rules="[(val) => !!val || 'Phone Code is Required!']"
              required
              hide-details="auto"
              v-model="companyDetails.phone_code"
              :error-messages="formErrors.phone_code"
              @input="delete formErrors.phone_code"
            />
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" class="px-1">
            <InputField
              type="number"
              label="Contact Number *"
              :rules="[
                (val) => !!val || 'Customer Contact Number is Required!',
              ]"
              required
              hide-details="auto"
              v-model="companyDetails.company_contact_number"
              :error-messages="formErrors.company_contact_number"
              @input="delete formErrors.company_contact_number"
            />
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" class="px-1">
            <InputField
              type="email"
              label="Company Email *"
              :rules="[
                (val) => !!val || 'Company Email is Required!',
                (val) => /.+@.+\..+/.test(val) || 'Email must be valid',
              ]"
              required
              hide-details="auto"
              v-model="companyDetails.company_email"
              :error-messages="formErrors.company_email"
              @input="delete formErrors.company_email"
            />
          </v-col>
          <v-col cols="12" class="px-1">
            <v-btn
              color="primary"
              @click="submitForm"
              :disabled="!compantEditForm"
            >
              Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </ListComponent>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import ListComponent from "@/components/General/ListComponent.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    InputField,
    MultiSelect,
    ListComponent
  },

  data() {
    return {
      countryList: [],
      cityList: [],

      formErrors: {},
      companyDetails: {
        company_type: "Customer",
        vat_applicability: "No",
        be_wise_invoicing: "No",
        is_local: "No",
        is_cross_border: "No",
      },

      compantEditForm: true,
    };
  },

  computed: {
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (!obj) {
        return null;
      } else {
        return obj;
      }
    },
  },

  methods: {
    checkData(obj, key) {
      if (obj) {
        if (key == "country") {
          this.companyDetails.phone_code = obj.phone_code;
          this.getAllCityList({ country: obj.id });
        }
        this.companyDetails[key] = obj.id;
      }
    },

    getAllCountryList() {
      this.$bus.$emit("showLoader", true);
      this.$api.country
        .getCountryList({ limit: "all" })
        .then((res) => {
          this.countryList = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getAllCityList(params = {}) {
      this.$bus.$emit("showLoader", true);
      this.$api.city
        .getCityList({ ...params, limit: "all" })
        .then((res) => {
          this.cityList = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    resetForm() {
      this.companyDetails = {
        company_type: "Customer",
        vat_applicability: "No",
        be_wise_invoicing: "No",
        is_local: "No",
        is_cross_border: "No",
      };
      this.$refs.compantEditForm.resetValidation();
    },

    getCompanyDetails() {
      if (this.currentProfile && this.currentProfile.company) {
        this.$bus.$emit("showLoader", true);
        this.$api.company
          .getCompanyObject(this.currentProfile.company)
          .then((res) => {
            let obj = {
              ...res.data,
              rating: parseFloat(res.data.rating),
            };
            this.companyDetails = obj;

            if (obj.company_contacts && obj.company_contacts.length) {
              this.contactArray = obj.company_contacts;
            }

            this.getAllCityList();
            this.$bus.$emit("showLoader", false);
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
          });
      }
    },

    submitForm() {
      this.$bus.$emit("showLoader", true);

      let payload = {
        id: this.companyDetails.id,
        data: { ...this.companyDetails },
      };

      this.$api.company
        .updateCompany(payload)
        .then(async (res) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Company Updated Successfully!",
            color: "success",
          });
        })
        .catch((err) => {
          this.formErrors = err.data;
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't Update Company!",
            color: "error",
          });
        });
    },
  },

  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("configuration");
      if (!permissions || !permissions.add) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.getAllCountryList();
        this.getCompanyDetails();
      }
    }
  },
};
</script>
