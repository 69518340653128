<template>
  <div>
    <ListComponent title="Location" :totalCount="totalItems">
      <template #list>
        <v-row class="ma-0">
          <v-col cols="4" class="px-0 pt-0 pb-2 d-flex align-center">
            <v-text-field
              label="Search"
              outlined
              dense
              append-icon="mdi-magnify"
              clearable
              hide-details="auto"
              v-model="filters.search"
              @input="searchLocation"
            ></v-text-field>

            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  depressed
                  icon
                  v-on="on"
                  v-bind="attrs"
                  @click="showFilterDialog = true"
                  class="mx-2"
                >
                  <v-badge
                    dot
                    color="primary"
                    :value="Object.keys(filters).length"
                  >
                    <v-icon>mdi-filter</v-icon>
                  </v-badge>
                </v-btn>
              </template>
              <span v-if="Object.keys(filters).length == 0">Filters</span>

              <v-row v-else no-gutters>
                <v-col cols="12" class="font-weight-bold">
                  Applied Filters
                </v-col>
                <v-col cols="12">
                  <span
                    v-for="(value, key) in filters"
                    :key="key"
                    class="text-capitalize"
                    >{{ key.replace("_", " ") }}
                    <br />
                  </span>
                </v-col>
              </v-row>
            </v-tooltip>

            <Columns :headers="headers" :gridColInstance="gridColumnApi" />
          </v-col>

          <v-col
            v-if="totalItems > 0"
            cols="8"
            class="px-0 pt-0 pb-2 d-flex justify-end"
          >
            <Pagination
              :pageNo="pageNo"
              :totalItems="totalItems"
              :pageSize="itemsPerPage"
              @itemsPerPageChange="itemsPerPageChanged"
              @prevPage="prevPage"
              @nextPage="nextPage"
            />
          </v-col>

          <v-col cols="12" class="pa-0">
            <AgGridVue
              style="width: 100%; height: calc(100vh - 172px)"
              class="ag-theme-alpine"
              id="myGrid"
              :columnDefs="headers"
              :context="context"
              @grid-ready="onGridReady"
              @columnMoved="resizeGrid"
              @columnVisible="resizeGrid"
              :defaultColDef="defaultColDef"
              :grid-options="gridOptions"
              :suppressDragLeaveHidesColumns="true"
              :rowData="allLocationList"
            ></AgGridVue>
          </v-col>
        </v-row>
      </template>

      <template #dialogs>
        <AdminLocationFilters
          v-model="showFilterDialog"
          :bindingObject="filters"
          @resetFilters="resetFilters"
          @applyFilters="itemsPerPageChanged(20)"
        />
        <BulkUpload
          v-model="locationBulkUploadDialog"
          :uploadTo="uploadType"
          @refreshList="itemsPerPageChanged(20)"
        />
      </template>

      <template #actions>
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn
              class="mr-2"
              depressed
              color="primary"
              v-on="on"
              v-bind="attrs"
              @click="getLocationList()"
            >
              <v-icon> mdi-refresh </v-icon>
            </v-btn>
          </template>

          <span>Refresh</span>
        </v-tooltip>
        <v-btn
          depressed
          color="primary"
          @click="addLocation"
          v-if="permissions && permissions.add"
        >
          <v-icon>mdi-plus</v-icon>
          <span>Add Location</span>
        </v-btn>
        <v-btn
          class="ml-3"
          v-if="permissions && permissions.add"
          @click="openLocationBulkUpload('location')"
          color="primary"
          depressed
        >
          <v-icon>mdi-plus</v-icon>
          <span>Bulk Upload</span>
        </v-btn>
      </template>
    </ListComponent>

    <DetailsParent :resizable="true" v-model="displayDrawer">
      <template #details>
        <v-card class="fill-height">
          <v-card-title class="heading mb-4">
            <Tooltip
              tooltipTitle="Location Code"
              v-if="selectedLocation.location_code"
              :tooltipText="selectedLocation.location_code"
              right
              tooltipClass="text-h5 font-weight-medium"
            />
          </v-card-title>

          <v-card-subtitle class="subHeading py-2">
            <Tooltip
              tooltipTitle="Location Address"
              v-if="selectedLocation.formatted_address"
              :tooltipText="selectedLocation.formatted_address"
              right
              tooltipClass="text-subtitle-1 font-weight-medium"
            />
          </v-card-subtitle>

          <v-card-text class="pt-2">
            <v-sheet class="Height-Class">
              <v-timeline v-if="logs && logs.length" dense>
                <v-timeline-item
                  v-for="(log, index) in logs"
                  :key="index"
                  color="primary"
                  class="pb-3"
                  small
                >
                  <v-card class="elevation-2">
                    <v-card-text class="py-2">
                      <table class="Full-Width">
                        <tbody>
                          <tr>
                            <th class="text-left">{{ log.updated_by }}</th>
                            <td class="text-caption text-right">
                              {{ log.created | formatDate(true) }}
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">{{ log.message }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
              </v-timeline>
              <div v-else>No Logs to show</div>
            </v-sheet>
          </v-card-text>
        </v-card>
      </template>
    </DetailsParent>
  </div>
</template>

<script>
import Columns from "@/components/General/Columns.vue";
import ListComponent from "@/components/General/ListComponent.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";
import LocationButton from "@/components/AgGridComponents/LocationButton.vue";
import CustomHeader from "@/components/AgGridComponents/General/CustomHeader.vue";
import AdminLocationFilters from "@/components/ModuleBased/dialogs/filters/AdminLocationFilters.vue";
import DetailsParent from "@/components/General/DetailsParent.vue";
import Tooltip from "@/components/General/Tooltip.vue";
import BulkUpload from "@/components/General/BulkUpload.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    Columns,
    ListComponent,
    AgGridVue,
    Pagination,
    LocationButton,
    agColumnHeader: CustomHeader,
    AdminLocationFilters,
    DetailsParent,
    Tooltip,
    BulkUpload,
  },

  data() {
    return {
      sorting: {},
      headers: [
        {
          headerName: "Location Code",
          field: "location_code",
          sortable: true,
          minWidth: 120,
        },
        {
          headerName: "Location Name",
          field: "name",
          sortable: true,
          tooltipField: "formatted_address",
          minWidth: 200,
        },
        {
          headerName: "State",
          field: "location_state",
          sortable: true,
          tooltipField: "location_state",
          minWidth: 140,
        },
        {
          headerName: "Country",
          field: "country_code",
          sortable: true,
          tooltipField: "location_country",
          minWidth: 80,
        },
        { headerName: "Port", field: "is_port", sortable: true, minWidth: 80 },
        {
          headerName: "Free Zone",
          field: "is_free_zone",
          sortable: true,
          minWidth: 100,
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: "LocationButton",
          pinned: "right",
          minWidth: 110,
        },
      ],
      search: "",

      // Pagination Vars
      itemsPerPage: 20,
      pageNo: 1,
      totalItems: 0,
      permissions: null,

      // bulk create
      uploadType: "location",
      locationBulkUploadDialog: false,
      openItemMasterDownloadDialog: false,

      // filter vars
      filters: {},
      showFilterDialog: false,

      // Ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: (params) => {
          this.resizeGrid();
        },

        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },

      allLocationList: [],
      selectedLocation: {},
      displayDrawer: false,
      logs: [],
    };
  },

  computed: {
    context() {
      return { parentComponent: this };
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },

  methods: {
    openLocationBulkUpload(type) {
      this.uploadType = type;
      this.locationBulkUploadDialog = true;
    },
    resetFilters() {
      this.filters = {};
      this.itemsPerPageChanged(20);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },

    resizeGrid() {
      if (this.gridOptions && this.gridOptions.api) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },

    searchLocation() {
      this.pageNo = 1;
      this.getLocationList();
    },

    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getLocationList();
    },

    prevPage() {
      this.pageNo--;
      this.getLocationList();
    },

    nextPage() {
      this.pageNo++;
      this.getLocationList();
    },
    applyGridSort(key, type) {
      if (this.filters && !this.filters.ordering) {
        this.filters.ordering = [];
      }

      if (type == null) {
        delete this.sorting[key];
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(`-${key}`),
          1
        );
      } else if (type == "asc") {
        this.filters.ordering.push(key);
      } else if (type == "desc") {
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(key),
          1,
          `-${key}`
        );
      }
      if (this.filters.ordering.length == 0) {
        delete this.filters.ordering;
      }
      this.itemsPerPageChanged(20);
    },
    getLocationList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        offset: this.offset,
        limit: this.itemsPerPage,
        ...params,
        ...this.filters,
      };

      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }
      this.$api.location
        .getLocationList(params)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.allLocationList = res.data;
          this.totalItems = res.count;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    addLocation() {
      this.$router.push("/app/admin/lane-management/location/add");
    },

    editLocation(id) {
      this.$router.push(`/app/admin/lane-management/location/edit/${id}`);
    },

    locationActivityLogs(data) {
      this.selectedLocation = data;
      const params = {
        location: data.id,
        limit: "50",
        offset: this.logs.length,
      };
      this.$api.activityLogs
        .locationActivityLogs(params)
        .then((res) => {
          this.logs.push(...res.data);
          this.displayDrawer = true;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("lane");
      if (!permissions || !permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.permissions = { ...permissions };
        this.getLocationList();
      }
    }
  },
};
</script>

<style scoped>
.Height-Class {
  height: calc(100vh - 232px);
  overflow-y: auto;
}
</style>
