<template>
  <v-container class="Details-View">
    <v-row class="heading px-2 pt-2">
      <v-col cols="12" md="5">
        <div class="d-flex">
          <Tooltip
            tooltipTitle="Company Code"
            v-if="customerDetails.company_code"
            :tooltipText="customerDetails.company_code"
            top
            tooltipClass="text-h5 font-weight-bold"
          />
        </div>
      </v-col>

      <v-col cols="12" md="6">
        <Tooltip
          tooltipTitle="Company Name"
          v-if="customerDetails.company_name"
          :tooltipText="customerDetails.company_name"
          top
          tooltipClass="text-h5 font-weight-bold"
        />

        <div
          v-if="customerDetails && customerDetails.address_detail"
          class="text-subtitle-1 font-weight-medium d-flex align-baseline"
        >
          <Tooltip
            tooltipTitle="Country"
            v-if="customerDetails.address_detail.Country"
            :tooltipText="customerDetails.address_detail.Country"
            left
            tooltipClass="mr-1"
          />
          |
          <Tooltip
            tooltipTitle="City"
            v-if="customerDetails.address_detail.city"
            :tooltipText="customerDetails.address_detail.city"
            right
            tooltipClass="ml-1"
          />
        </div>
      </v-col>
    </v-row>

    <v-row class="subHeading px-2">
      <v-col cols="12" md="6" lg="5">
        <div class="d-flex align-center mr-4">
          <v-icon>mdi-email</v-icon>
          <span class="mb-0 ml-2 text-Body-1 Word-Break">
            {{ customerDetails.company_email }}
          </span>
        </div>
      </v-col>

      <v-col cols="12" md="6" lg="5">
        <div class="d-flex align-center">
          <v-icon>mdi-phone</v-icon>
          <span class="mb-0 ml-2 text-Body-1">
            {{ customerDetails.full_company_contact_number }}
          </span>
        </div>
      </v-col>
    </v-row>

    <v-tabs fixed-tabs v-model="detailsTab" class="mt-3">
      <v-tabs-slider color="white"></v-tabs-slider>
      <v-tab>Detail View</v-tab>
      <v-tab v-if="!readOnly">User List</v-tab>
      <v-tab
        v-if="
          !readOnly &&
          customerDetails.payment_detail &&
          customerDetails.payment_detail.payment_term == 'Credit'
        "
        >Fuel Surcharge</v-tab
      >
      <v-tab v-if="!readOnly">Address Book</v-tab>
      <v-tab v-if="!readOnly">Documents</v-tab>
      <v-tab v-if="!readOnly">Logs</v-tab>
    </v-tabs>

    <v-tabs-items v-model="detailsTab" class="px-2">
      <v-tab-item class="py-4">
        <v-expansion-panels multiple focusable v-model="companyDetailsView">
          <v-expansion-panel
            class="Card-Details-Expansion"
            v-if="
              customerDetails.contact_details &&
              customerDetails.contact_details.length > 0
            "
          >
            <v-expansion-panel-header class="py-1 px-3 subHeading">
              <span class="text-subtitle-1 font-weight-medium"
                >Contact Details</span
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-3 subHeading">
              <v-list-item
                three-line
                v-for="(contact, i) in customerDetails.contact_details"
                :key="i"
              >
                <v-list-item-content>
                  <v-list-item-title>{{
                    `${contact.contact_person} - ${contact.department_name}`
                  }}</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ contact.contact_email }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ `+${contact.phone_code} ${contact.contact_number}` }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <CardDetails
            v-if="customerDetails.address_detail"
            title="Address Details"
            :displayObject="customerDetails.address_detail"
          />
          <!-- <CardDetails
            v-if="customerDetails.markup_markdown_detail"
            title="Markup Markdown Details"
            :displayObject="customerDetails.markup_markdown_detail"
          /> -->
          <CardDetails
            v-if="customerDetails.payment_detail"
            title="Payment Details"
            :displayObject="customerDetails.payment_detail"
          />
          <CardDetails
            v-if="customerDetails.other_detail"
            title="Other Details"
            :displayObject="customerDetails.other_detail"
          />
        </v-expansion-panels>
      </v-tab-item>
      <v-tab-item v-if="!readOnly">
        <div class="text-right mx-4 my-2">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                icon
                depressed
                @click="addCustomerUser"
                v-bind="attrs"
                v-if="userPermissions && userPermissions.add"
                v-on="on"
                small
                class="Details-Icon"
              >
                <v-icon>mdi-account-plus</v-icon>
              </v-btn>
            </template>
            <span>Add User</span>
          </v-tooltip>
        </div>

        <v-divider></v-divider>

        <v-list>
          <v-list-item v-for="(item, i) in customerCompanyUsers" :key="i">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="text-subtitle-1 font-weight-medium">
                {{ item.first_name }} {{ item.last_name }}
              </v-list-item-title>

              <v-list-item-subtitle>
                <Tooltip
                  tooltipTitle="Username"
                  :tooltipText="item.username"
                  top
                  tooltipClass="text-body-2"
                />
              </v-list-item-subtitle>

              <v-list-item-subtitle>
                <Tooltip
                  tooltipTitle="Email"
                  :tooltipText="item.email"
                  top
                  tooltipClass="text-body-2"
                />

                <Tooltip
                  tooltipTitle="Contact Number"
                  :tooltipText="item.full_contact_number"
                  top
                  tooltipClass="text-body-2 ml-3"
                />
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-icon>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    @click="editCustomerUser(item.id)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-tab-item>
      <v-tab-item
        v-if="
          !readOnly &&
          customerDetails.payment_detail &&
          customerDetails.payment_detail.payment_term == 'Credit'
        "
      >
        <div class="text-right mx-4 my-2">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                icon
                depressed
                @click="openFuelSurchargeForm(false, null)"
                v-bind="attrs"
                v-on="on"
                small
                v-if="permissions && permissions.add"
                class="Details-Icon"
              >
                <v-icon>mdi-gas-station</v-icon>
              </v-btn>
            </template>
            <span>Add Fuel Surcharge</span>
          </v-tooltip>
        </div>
        <v-divider></v-divider>
        <v-list>
          <v-list-item v-for="(item, i) in fuelSurchargeList" :key="i">
            <v-list-item-content>
              <v-list-item-subtitle>
                Start Date - {{ item.start_date }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                End Date - {{ item.end_date }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                Surcharge (%) - {{ item.surcharge }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-icon>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    @click="openFuelSurchargeForm(true, item.id)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-tab-item>
      <v-tab-item v-if="!readOnly">
        <div class="text-right mx-4 my-2">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                icon
                depressed
                @click="openAddressForm(false, null)"
                v-bind="attrs"
                v-on="on"
                small
                v-if="permissions && permissions.add"
                class="Details-Icon"
              >
                <v-icon>mdi-notebook-plus</v-icon>
              </v-btn>
            </template>
            <span>Add Address</span>
          </v-tooltip>
        </div>
        <v-divider></v-divider>
        <v-list>
          <v-list-item v-for="(item, i) in customerAddressList" :key="i">
            <v-list-item-icon>
              <v-icon>mdi-office-building-marker-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="text-subtitle-1 font-weight-medium">
                {{ item.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <Tooltip
                  :tooltipTitle="item.address"
                  :tooltipText="item.address"
                  top
                  tooltipClass="text-body-1"
                />
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-icon>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    @click="openAddressForm(true, item.id)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-tab-item>
      <v-tab-item v-if="!readOnly">
        <DocumentList :documentList="customerDetails.company_documents" />
      </v-tab-item>
      <v-tab-item v-if="!readOnly">
        <v-sheet
          style="
            height: calc(100vh - 380px);
            overflow-y: auto;
            overflow-x: hidden;
          "
        >
          <v-timeline v-if="logs && logs.length" class="px-0" align-top dense>
            <v-timeline-item
              v-for="(log, index) in logs"
              :key="index"
              color="primary"
              icon="mdi-circle"
              small
            >
              <v-row class="pt-1">
                <v-col>
                  <strong>{{ log.created | formatDate(true) }}</strong>
                  <div class="text-body-2">{{ log.message }}</div>
                  <div class="text-caption">- {{ log.updated_by }}</div>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
          <div v-else>No Logs to show</div>
        </v-sheet>
      </v-tab-item>
    </v-tabs-items>

    <UserForm
      v-model="showCustomerUserForm"
      module="customer"
      :editMode="editMode"
      :user="customerDetails"
      :companyUserId="editCompanyId"
      @closeUserForm="closeCustomerUserForm"
      @refreshList="getCustomerCompanyUsers"
    />
    <FuelSurchargeForm
      v-model="showFuelSurchargeForm"
      :fuelSurchargeId="fuelSurchargeId"
      :companyId="customerDetails.company"
      :editMode="isFuelSurchargeEditMode"
      @closeFuelSurchargeForm="closeFuelSurchargeForm"
      @refreshList="getCompanyFuelSurchargeList"
    />
    <CustomerAddressForm
      v-model="showCustomerAddressForm"
      :addressId="editAddressId"
      :customerId="customerDetails.company"
      :editMode="isAddressEditMode"
      module="Customer"
      @closeAddressForm="closeAddressForm"
      @refreshList="getCustomerAddressList"
    />
  </v-container>
</template>

<script>
import UserForm from "../dialogForms/UserForm.vue";
import CardDetails from "@/components/General/CardDetails.vue";
import Tooltip from "@/components/General/Tooltip.vue";
import FuelSurchargeForm from "../dialogForms/FuelSurchargeForm.vue";
import CustomerAddressForm from "../dialogForms/CustomerAddressForm.vue";
import DocumentList from "@/components/General/DocumentList.vue";

export default {
  components: {
    UserForm,
    CardDetails,
    Tooltip,
    FuelSurchargeForm,
    CustomerAddressForm,
    DocumentList,
  },
  props: {
    customerId: {
      type: Number,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
    userPermissions: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    customerId(val) {
      this.logs = [];
      this.getCustomerDetails(val);
      this.getCustomerCompanyUsers();
      this.companyActivityLogs();
      this.getCustomerAddressList();
      this.getCompanyFuelSurchargeList();
    },
  },
  data() {
    return {
      customerDetails: {},
      customerCompanyUsers: [],
      customerAddressList: [],

      // user form vars
      showCustomerUserForm: false,
      editMode: false,
      editCompanyId: null,

      // address form vars
      showCustomerAddressForm: false,
      isAddressEditMode: false,
      editAddressId: null,

      // fuel surcharge form
      showFuelSurchargeForm: false,
      isFuelSurchargeEditMode: false,
      fuelSurchargeId: null,
      fuelSurchargeList: [],

      companyDetailsView: [0, 1, 2, 3, 4],
      detailsTab: null,

      logs: [],
    };
  },
  methods: {
    closeAddressForm() {
      this.isAddressEditMode = false;
      this.editAddressId = null;
      this.showCustomerAddressForm = false;
    },
    closeFuelSurchargeForm() {
      this.isFuelSurchargeEditMode = false;
      this.fuelSurchargeId = null;
      this.showFuelSurchargeForm = false;
    },
    openFuelSurchargeForm(editMode, id) {
      this.isFuelSurchargeEditMode = editMode;
      this.fuelSurchargeId = id;
      this.showFuelSurchargeForm = true;
    },
    openAddressForm(editMode, id) {
      this.isAddressEditMode = editMode;
      this.editAddressId = id;
      this.showCustomerAddressForm = true;
    },
    closeCustomerUserForm() {
      this.editMode = false;
      this.editCompanyId = null;
      this.showCustomerUserForm = false;
    },
    getCustomerDetails(id) {
      this.$api.company
        .getCompanyDetails(id)
        .then((res) => {
          this.customerDetails = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getCustomerCompanyUsers(params = {}) {
      params = {
        ...params,
        company: this.customerId,
        limit: "all",
      };
      this.$api.user
        .getUserList(params)
        .then((res) => {
          this.customerCompanyUsers = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getCompanyFuelSurchargeList(params = {}) {
      params = {
        ...params,
        company: this.customerId,
        limit: "all",
      };
      this.$api.companyFuelSurcharge
        .getCompanyFuelSurchargeList(params)
        .then((res) => {
          this.fuelSurchargeList = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getCustomerAddressList(params = {}) {
      params = {
        ...params,
        customer: this.customerId,
        limit: "all",
      };
      this.$api.customerAddress
        .getCustomerAddressList(params)
        .then((res) => {
          this.customerAddressList = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    companyActivityLogs(params = {}) {
      params = {
        ...params,
        company: this.customerId,
        limit: "50",
        offset: this.logs.length,
      };
      this.$api.activityLogs
        .companyActivityLogs(params)
        .then((res) => {
          this.logs.push(...res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    addCustomerUser() {
      this.editMode = false;
      this.showCustomerUserForm = true;
    },
    editCustomerUser(id) {
      this.editCompanyId = id;
      this.editMode = true;
      this.showCustomerUserForm = true;
    },
  },
  mounted() {
    this.logs = [];
    this.getCustomerDetails(this.customerId);
    this.getCustomerCompanyUsers();
    this.companyActivityLogs();
    this.getCustomerAddressList();
    this.getCompanyFuelSurchargeList();
  },
};
</script>
