<template>
  <v-dialog fullscreen v-model="showAttachmentsDialog">
    <v-card>
      <v-card-title class="secondary white--text mb-4 rounded-0">
        <span> Download PODs </span>
        <v-spacer></v-spacer>
        <v-btn icon @click="showAttachmentsDialog = false">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form
          @submit.prevent="getAttachments"
          ref="chargeForm"
          v-model="isValid"
        >
          <v-row class="mt-0">
            <v-col cols="12" sm="12" md="4" lg="2" class="Form-Columns d-flex">
              <v-btn @click="previousDate" icon depressed color="primary">
                <v-icon> mdi-arrow-left </v-icon>
              </v-btn>
              <DateField
                ref="dateField"
                :inputProps="{
                  dense: true,
                  label: 'Date *',
                  style: 'width: calc(100% - 60px)',
                  'hide-details': 'auto',
                  rules:[(val) => !!val || 'Date is Required!']
                }"
                required
                v-model="filters.logs_date"
              />
              <v-btn @click="nextDate" icon depressed color="primary">
                <v-icon> mdi-arrow-right </v-icon>
              </v-btn>
            </v-col>

            <v-col cols="12" lg="4" class="Form-Columns">
              <v-btn
                type="submit"
                depressed
                color="primary"
                class="mr-2"
                :disabled="!isValid"
              >
                Search
              </v-btn>
            </v-col>
            <v-col cols="12" lg="6" class="Form-Columns d-flex justify-end">
              <v-btn
                @click="createZip()"
                depressed
                color="primary"
                class="mr-2"
                :disabled="!attachmentsList.length"
              >
                Download All
              </v-btn>
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-text>
        <v-row v-if="!selectedFolder">
          <v-col
            cols="1"
            v-for="(folder, index) in attachmentsList"
            :key="index"
          >
            <v-row @dblclick="selectedFolder = folder">
              <v-col cols="12" class="pb-0">
                <img
                  style="width: 100%"
                  :id="`image-${index}`"
                  @onclick="changeImage(`image-${index}`)"
                  src="@/assets/images/close_folder.png"
                />
              </v-col>
              <v-col class="d-flex align-center justify-center py-0" cols="9">
                <span class="text-body-1">
                  {{ folder.folder_name.employee_id }} - {{ folder.folder_name.driver_name }}
                </span>
              </v-col>
              <v-col class="py-2 d-flex justify-center py-0" cols="3">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" @click="createZip(folder)">
                      mdi-content-save
                    </v-icon>
                  </template>
                  <span>Download</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="selectedFolder">
          <v-col cols="12" class="d-flex align-center">
            <span class="pr-4">
              <v-icon @click="selectedFolder = null"> mdi-arrow-left </v-icon>
            </span>
            <span class="text-h6 px-4">
              {{ selectedFolder.folder_name.employee_id }} - {{ selectedFolder.folder_name.driver_name }}
            </span>
            <span>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    @click="createZip(selectedFolder)"
                  >
                    mdi-content-save
                  </v-icon>
                </template>
                <span>Download</span>
              </v-tooltip>
            </span>
          </v-col>
          <v-col cols="12" class="pt-0">
            <v-divider></v-divider>
          </v-col>
          <v-col
            cols="2"
            v-for="(url, urlIndex) in selectedFolder.urls"
            :key="urlIndex"
          >
            <v-row>
              <v-col cols="12">
                <v-img
                  v-if="
                    imageExt.indexOf(getExtension(getFileNameFromUrl(url))) > -1
                  "
                  height="250px"
                  :src="url"
                />

                <v-img
                  v-else-if="getExtension(getFileNameFromUrl(url)) == 'pdf'"
                  height="250px"
                  src="@/assets/images/pdf.png"
                />
                <v-img
                  v-else-if="
                    excelExt.indexOf(getExtension(getFileNameFromUrl(url))) > -1
                  "
                  height="250px"
                  src="@/assets/images/excel.png"
                />

                <div
                  v-else-if="
                    audioExt.indexOf(getExtension(getFileNameFromUrl(url))) > -1
                  "
                >
                  <v-img height="195px" src="@/assets/images/audio.png" />
                  <audio controls :src="url"></audio>
                </div>
                <v-img v-else height="250px" src="@/assets/images/other.png" />
              </v-col>
              <v-col class="pt-0" cols="12">
                {{ getFileNameFromUrl(url) }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import DateField from "@/components/FormBaseComponents/DateField.vue";
import { getFileNameFromUrl } from "@/utils/functions.js";
import JSZip from "jszip";

export default {
  components: {
    DateField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showAttachmentsDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.getAttachments();
      } 
    },
  },
  data() {
    return {
      attachmentsList: [],
      selectedFolder: null,
      isValid: false,
      filters: {
        logs_date: new Date().toISOString().slice(0, 10),
      },
      zip: null,
      imageExt: ["jpg", "png", "jpeg"],
      audioExt: ["mp3", "m4a", "wav"],
      excelExt: ["xlsx", "xlsm", "xlsb", "xls", "csv"],
    };
  },
  methods: {
    nextDate() {
      const field = this.$refs.dateField;
      if (field) {
        field.nextDate();
      }
    },
    previousDate() {
      const field = this.$refs.dateField;
      if (field) {
        field.previousDate();
      }
    },
    getFileNameFromUrl,
    getExtension(name) {
      name = name.split(".");
      return name[name.length - 1]?.toLowerCase();
    },
    changeImage(id) {
      const elem = document.getElementById(id);
      if (elem) {
        if (elem.src.indexOf("close_folder") > -1) {
          elem.src = "/open_folder.png";
        } else {
          elem.src = "/close_folder.png";
        }
      }
    },
    getAttachments() {
      this.selectedFolder = null;
      this.attachmentsList = [];

      this.$bus.$emit("showLoader", true);

      this.$api.trip
        .getAttachments(this.filters)
        .then((res) => {
          this.attachmentsList = res.data.filter(
            (attachment) => attachment.urls && attachment.urls.length
          );
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    async fetchImageData(url) {
      let blob = await fetch(url).then((r) => r.blob());
      return blob;
    },
    async createZip(folder) {
      this.zip = new JSZip();

      this.$bus.$emit("showLoader", true);

      if (folder) {
        await this.createFolder(folder);
      } else {
        for (let i = 0; i < this.attachmentsList.length; i++) {
          await this.createFolder(this.attachmentsList[i]);
        }
      }

      this.$bus.$emit("showLoader", false);

      this.downloadZip(folder);
    },
    async createFolder(details) {
      let folder = this.zip.folder(details.folder_name.employee_id);
      for (let i = 0; i < details.urls.length; i++) {
        await folder.file(
          getFileNameFromUrl(details.urls[i]),
          await this.fetchImageData(details.urls[i]),
          { blob: true }
        );
      }
    },
    downloadZip(folder) {
      let filename = `${this.filters.logs_date}.zip`;

      if (folder) {
        filename = `${folder.folder_name.employee_id}.zip`;
      }

      this.zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          this.download(content, filename);
        });
    },
    download(blob, legWorkBookName) {
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      const fileName = legWorkBookName;

      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    },
  },
};
</script>

