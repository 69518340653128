<template>
  <v-dialog fullscreen v-model="showMarkDAReadyForInvoiceForm">
    <v-card>
      <v-card-title class="secondary white--text mb-4 rounded-0">
        <span>
          Mark DA Ready For Invoice
          {{ this.legDetails ? `for ${this.legDetails.leg_id}` : "" }}
        </span>

        <v-spacer></v-spacer>

        <v-btn
          icon
          @click="resetForm(), (showMarkDAReadyForInvoiceForm = false)"
        >
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form
          ref="chargeForm"
          v-if="showMarkDAReadyForInvoiceForm"
          v-model="isValid"
        >
          <v-row class="mt-0">
            <v-col cols="12" sm="6" md="4" lg="2" class="Form-Columns">
              <InputField
                label="DA No"
                :clearable="true"
                v-model="filters.search"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2" class="Form-Columns">
              <MultiSelect
                :items="allCustomer"
                itemText="company_name"
                itemValue="id"
                label="Select Customer"
                :clearable="true"
                hide-details="auto"
                v-model="filters.customer"
              >
              </MultiSelect>
            </v-col>

            <v-col cols="12" md="6" lg="2" class="Form-Columns">
              <DateField
                :inputProps="{
                  dense: true,
                  label: 'Start Date',
                  clearable: true,
                  'hide-details': 'auto',
                }"
                v-model="filters.start_date"
                @change="delete filters.end_date"
              />
            </v-col>
            <v-col cols="12" md="6" lg="2" class="Form-Columns">
              <DateField
                :inputProps="{
                  dense: true,
                  label: 'End Date',
                  clearable: true,
                  'hide-details': 'auto',
                }"
                :min="filters.start_date"
                v-model="filters.end_date"
              />
            </v-col>
            <v-col cols="auto"  class="Form-Columns">
              <div class="d-flex">
                <v-menu
                  open-on-hover
                  left
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-on="on" v-bind="attrs" class="mx-2">
                      mdi-information
                    </v-icon>
                  </template>
                  <v-card>
                    <v-card-title
                      class="secondary py-1 text-subtitle-1 white--text font-weight-medium"
                    >
                      Filter Information
                    </v-card-title>
                    <v-card-text
                      class="mt-2"
                    >
                      <table class="Normal-Table">
                        <tbody>
                          <tr>
                            <th>Start Date</th>
                            <th class="px-1">:</th>
                            <td>Movement of DA</td>
                          </tr>
                          <tr>
                            <th>End Date</th>
                            <th class="px-1">:</th>
                            <td>Movement of DA</td>
                          </tr>
                        </tbody>
                      </table>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="1" class="Form-Columns">
              <InputField
                label="From DA No"
                :clearable="true"
                v-model="start_da_no"
              />
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="1" class="Form-Columns">
              <InputField
                label="To DA No"
                :clearable="true"
                v-model="end_da_no"
              />
            </v-col>

            <v-col cols="12" md="6" lg="2" class="Form-Columns">
              <v-switch
                dense
                label="Sub Hire"
                hide-details="auto"
                class="mt-1"
                v-model="filters.is_sub_hire"
              ></v-switch>
            </v-col>

            <v-col cols="12" lg="4" class="Form-Columns">
              <v-btn
                depressed
                color="primary"
                class="mr-2"
                @click="searchList()"
              >
                Search
              </v-btn>
              <v-btn
                depressed
                color="primary"
                class="mr-2"
                :disabled="!(selectedRows && selectedRows.length)"
                @click="submitForm()"
              >
                Update Status
              </v-btn>

              <v-btn
                depressed
                color="secondary"
                class="mr-2"
                @click="resetForm()"
              >
                Reset
              </v-btn>
            </v-col>

            <v-col cols="12" class="pa-0">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <span class="text-h6 font-weight-regular">
        Total Selected Delivery Advice -
        <span class="font-weight-bold"> {{ daCount }} </span>
      </span>
      <v-card-text>
        <AgGridVue
          style="width: 100%; height: calc(100vh - 320px)"
          class="ag-theme-alpine"
          id="myGrid"
          :columnDefs="headers"
          :context="context"
          @grid-ready="onGridReady"
          rowSelection="multiple"
          @selection-changed="setSelectedRows"
          :defaultColDef="defaultColDef"
          :grid-options="gridOptions"
          :suppressDragLeaveHidesColumns="true"
          :rowData="allRowList"
        ></AgGridVue>

        <v-col v-if="totalItems > 0" cols="12" class="d-flex justify-end">
          <Pagination
            :pageNo="pageNo"
            :totalItems="totalItems"
            :pageSize="itemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import SelectField from "@/components/FormBaseComponents/SelectField.vue";
import { AgGridVue } from "ag-grid-vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";
import Pagination from "@/components/General/Pagination.vue";
export default {
  components: {
    AgGridVue,
    InputField,
    SelectField,
    DateField,
    MultiSelect,
    Pagination,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    legDetails: {
      default: false,
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.getCustomerList();
        this.getDeliveryAdviceList();
        this.daCount = 0;
      } else {
        this.resetForm();
      }
    },
  },
  computed: {
    showMarkDAReadyForInvoiceForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    context() {
      return { parentComponent: this };
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },
  data() {
    return {
      selectedRows: [],

      formErrors: {},
      isValid: true,
      editMode: false,
      allRowList: [],
      lastEndDate: null,
      allCustomer: [],
      isSearchEnabled: false,
      daCount: 0,
      is_sub_hire: false,
      start_da_no: null,
      end_da_no: null,

      filters: {},
      // pagination vars
      pageNo: 1,
      itemsPerPage: 100,
      totalItems: 0,
      containerCentric: "No",
      headerSelected: [],
      tab: null,
      // ag-grid vars
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        isRowSelectable: (rowNode) => {
          if (
            rowNode.data &&
            rowNode.data.payment_term == "Cash" &&
            !rowNode.data.payment_receipt
          ) {
            return false;
          } else {
            return true;
          }
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },
      headers: [
        {
          headerName: "DA No.",
          field: "da_no",
          minWidth: 60,
        },
        {
          headerName: "Status",
          field: "status",
          minWidth: 60,
        },
        {
          headerName: "Customer",
          field: "customer_name",
          minWidth: 60,
        },
        {
          headerName: "Movement Date/Time",
          field: "movement_date_time",
          minWidth: 150,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value, true);
          },
        },
        {
          headerName: `Rate (${this.$globals.currency})`,
          field: "total_da_rate",
          minWidth: 150,
        },
        {
          headerName: "BOE",
          field: "boe",
          minWidth: 150,
        },
        {
          headerName: "Vessel",
          field: "vessel_no",
          minWidth: 120,
        },
        {
          headerName: "Sub Hire",
          field: "is_sub_hire",
          minWidth: 120,
        },
        {
          headerName: "Ready For Invoice",
          minWidth: 120,
          checkboxSelection: (params) => {
            if (
              params.data &&
              params.data.payment_term == "Cash" &&
              params.data.payment_receipt == null
            ) {
              return false;
            }
            if (params.data.status == "Completed") {
              return true;
            } else {
              return false;
            }
          },
          valueFormatter: (params) => {
            if (
              params.data &&
              params.data.payment_term == "Cash" &&
              params.data.payment_receipt == null
            ) {
              return "Payment receipt need to be attached...";
            }
          },
          tooltipValueGetter: (params) => {
            if (
              params.data &&
              params.data.payment_term == "Cash" &&
              params.data.payment_receipt == null
            ) {
              return "Payment receipt needs to be attached";
            }
          },
          headerCheckboxSelection: true,
        },
      ],
    };
  },
  methods: {
    setSelectedRows() {
      this.selectedRows = this.gridApi.getSelectedRows();
      this.daCount = this.selectedRows.length;
    },
    resizeGrid() {
      if (this.gridOptions && "api" in this.gridOptions) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },
    headersChanged(value) {
      this.headerSelected = value;
      this.resizeGrid();
    },

    searchList() {
      this.pageNo = 1;
      this.getDeliveryAdviceList();
      this.daCount = 0;
    },
    itemsPerPageChanged(e) {
      this.daCount = 0;
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getDeliveryAdviceList();
    },
    prevPage() {
      this.daCount = 0;
      this.pageNo--;
      this.getDeliveryAdviceList();
    },
    nextPage() {
      this.daCount = 0;
      this.pageNo++;
      this.getDeliveryAdviceList();
    },
    resetForm() {
      this.allRowList = null;
      this.selectedRows = null;
      this.filters = {};
      this.headerSelected = [];
      this.start_da_no = null;
      this.end_da_no = null;
      this.daCount = 0;
    },
    getCustomerList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        company_type: "Customer",
        limit: "all",
      };
      this.$api.company
        .getCompanyList(params)
        .then((res) => {
          this.allCustomer = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    submitForm() {
      this.$bus.$emit("showLoader", true);
      this.formErrors = {};

      let params = {
        delivery_advices: this.selectedRows.map((da) => da.id),
      };

      this.$api.deliveryAdvice
        .markDAReadyForInvoice(params)
        .then(() => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Delivery Advices updated successfully!",
            color: "success",
          });
          this.resetForm();
          this.getDeliveryAdviceList();
          this.$emit("refreshList");
          this.daCount = 0;
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't udpate delivery advices!",
            color: "error",
          });
          this.formErrors = err.data;
        });
    },

    getDeliveryAdviceList() {
      this.$bus.$emit("showLoader", true);
      let da_range;
      if (this.start_da_no && this.end_da_no) {
        da_range = this.start_da_no + "," + this.end_da_no;
      }

      let params = {
        offset: this.offset,
        limit: this.itemsPerPage,
        ...params,
        ...this.filters,
        status: "Completed",
        payment_term: "Credit",
      };
      if (da_range) {
        params.da_range = da_range;
      }
      if (params.hasOwnProperty("is_sub_hire")) {
        if (!params.is_sub_hire) {
          delete params.is_sub_hire;
        }
      }
      this.$api.deliveryAdvice
        .getDeliveryAdviceList(params)
        .then((res) => {
          this.allRowList = res.data;
          this.totalItems = res.count;
          this.$bus.$emit("showLoader", false);
          this.selectedRows = null;
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.selectedRows = null;
          console.error(err);
        });
    },
  },
};
</script>
