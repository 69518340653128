<template>
  <v-dialog max-width="90%" v-model="showAddressForm">
    <v-card>
      <v-card-title class="secondary white--text py-2 px-4">
        <span class="text-capitalize">Bulk Update</span>

        <v-spacer></v-spacer>

        <v-btn icon @click="$emit('closeRouteForm')">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-data-table
          dense
          :headers="headers"
          :items="routeList"
          class="elevation-1"
          :hide-default-footer="true"
          :disable-pagination="true"
        >
          <template v-slot:[`item.distance`]="{ index }">
            <div class="Form-Columns">
              <InputField
                label="Distance *"
                :clearable="true"
                :rules="[
                  (v) => !!v || 'Distance is Required',
                  (v) => v > 0 || 'Distance can not be less than 0',
                  (v) =>
                    $globals.containerWeightFormatRegExp.test(v) ||
                    'Distance must have the format 00.00',
                ]"
                hide-details="auto"
                v-model="routeList[index].distance"
              />
            </div>
          </template>
          <template v-slot:[`item.bonus`]="{ index }">
            <div class="Form-Columns">
              <InputField
                hide-details="auto"
                type="number"
                label="Bonus *"
                :rules="[
                  (v) => !!v || 'Bonus is Required',
                  (v) => v > 0 || 'Bonus can not be less than 0',
                  (v) =>
                    $globals.containerWeightFormatRegExp.test(v) ||
                    'Bonus must have the format 00.00',
                ]"
                :clearable="true"
                v-model="routeList[index].bonus"
              />
            </div>
          </template>
        </v-data-table>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          color="primary"
          depressed
          :disabled="!isValid"
          @click="submitForm"
          >Submit</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";

export default {
  components: {
    InputField,
    MultiSelect,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    routeData: {
      type: Array,
      default: [],
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.routeList = [];
        this.routeList = [...this.routeData];
       
      } else {
        this.formErrors = {};
        this.routeList = [];
      }
    },
  },
  computed: {
    showAddressForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    return {
      isValid: true,
      routeList: [],

      formErrors: {},
      sameDataError: null,

      // list vars
      headers: [

        {
          text: "Source",
          value: "source",
          sortable: false,
          width: "200px",
        },
        {
          text: "Mid",
          value: "mid",
          sortable: false,
          width: "200px",
        },
        {
          text: "Destination",
          value: "destination",
          sortable: false,
          width: "200px",
        },
        {
          text: "Route Code",
          value: "title",
          sortable: false,
          width: "200px",
        },
        {
          text: "Service Type",
          value: "service_type",
          sortable: false,
          width: "100px",
        },
        {
          text: "Distance",
          value: "distance",
          sortable: false,
          width: "150px",
        },
        {
          text: "Bonus",
          value: "bonus",
          sortable: false,
          width: "150px",
        },
        {
          text: "Duration (Min)",
          value: "duration",
          sortable: false,
          width: "100px",
        },
        {
          text: "Is Active",
          value: "is_active",
          sortable: false,
          width: "100px",
        },
      ],
    };
  },
  methods: {
    submitForm() {
      this.$bus.$emit("showLoader", true);
      let payload = {
        data: this.routeList,
      };
      this.$api.lane
        .bulkUpdateLane(payload)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Route Updated Successfully!",
            color: "success",
          });
          this.formErrors = {};
          this.$emit("closeRouteForm");
        })
        .catch((err) => {
          this.formErrors = {};
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't update Route!",
            color: "error",
          });
        });
    },
  },
};
</script>
