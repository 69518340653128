<template>
  <v-dialog max-width="65%" v-model="showUserForm">
    <v-card>
      <v-card-title class="secondary white--text">
        <span v-if="editMode" class="text-capitalize">
          Edit {{ module }} User
        </span>

        <span v-else class="text-capitalize">Add {{ module }} User</span>

        <v-spacer></v-spacer>

        <v-btn icon @click="resetForm(), $emit('closeUserForm')" color="white">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form v-if="showUserForm" v-model="isValid" class="py-6">
          <v-row>
            <v-col cols="12" lg="6">
              <InputField
                type="text"
                v-model="userDetails.username"
                :rules="[(val) => !!val || 'Username is required!']"
                label="Username*"
                required
                :error-messages="formErrors.username"
                @input="delete formErrors.username"
              />
            </v-col>

            <v-col cols="12" lg="6">
              <InputField
                :type="isTextInput ? 'text' : 'password'"
                :rules="
                  !editMode ? [(val) => !!val || 'Password is required!'] : []
                "
                :label="!editMode ? 'Password*' : 'Password'"
                :append-icon="!isTextInput ? 'mdi-eye-off' : 'mdi-eye'"
                required
                v-model="userDetails.password"
                @click:append="isTextInput = !isTextInput"
                :error-messages="formErrors.password"
                @input="delete formErrors.password"
              />
            </v-col>

            <v-col cols="12" lg="6">
              <InputField
                type="text"
                :rules="[(val) => !!val || 'First Name is required!']"
                label="First Name*"
                required
                v-model="userDetails.first_name"
                :error-messages="formErrors.first_name"
                @input="delete formErrors.first_name"
              />
            </v-col>

            <v-col cols="12" lg="6">
              <InputField
                type="text"
                :rules="[(val) => !!val || 'Last Name is required!']"
                label="Last Name*"
                required
                v-model="userDetails.last_name"
                :error-messages="formErrors.last_name"
                @input="delete formErrors.last_name"
              />
            </v-col>

            <v-col cols="12" lg="6">
              <InputField
                type="text"
                :rules="[(val) => !!val || 'Designation is required!']"
                label="Designation*"
                required
                v-model="userDetails.designation"
                :error-messages="formErrors.designation"
                @input="delete formErrors.designation"
              />
            </v-col>

            <v-col cols="12" lg="2">
              <MultiSelect
                :items="allPhoneCodeList"
                :rules="[(val) => !!val || 'Phone code is required!']"
                label="Phone code*"
                required
                v-model="userDetails.phone_code"
                :error-messages="formErrors.phone_code"
                @change="delete formErrors.phone_code"
              />
            </v-col>
            <v-col cols="12" lg="4">
              <InputField
                type="number"
                :rules="[(val) => !!val || 'Contact Number is required!']"
                label="Contact Number*"
                required
                v-model="userDetails.contact_number"
                :error-messages="formErrors.contact_number"
                @input="delete formErrors.contact_number"
              />
            </v-col>

            <v-col cols="12" lg="6">
              <InputField
                type="text"
                :rules="[
                  (val) => !!val || 'E-mail is required!',
                  (val) => /.+@.+\..+/.test(val) || 'E-mail must be valid',
                ]"
                label="Email*"
                required
                v-model="userDetails.email"
                :error-messages="formErrors.email"
                @input="delete formErrors.email"
              />
            </v-col>
          </v-row>
        </v-form>
        <v-divider></v-divider>
      </v-card-text>

      <v-card-actions class="d-flex justify-end">
        <v-btn
          color="primary"
          depressed
          :disabled="!isValid"
          @click="submitForm"
        >
          Submit
        </v-btn>
        <v-btn
          class="ml-3"
          color="secondary"
          depressed
          @click="resetForm"
          v-if="!editMode"
        >
          Reset
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import SelectField from "@/components/FormBaseComponents/SelectField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import { encryptText } from "@/utils/functions";

export default {
  components: {
    InputField,
    SelectField,
    MultiSelect,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    module: {
      type: String,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: () => {},
    },
    companyUserId: {
      type: Number,
    },
  },
  computed: {
    showUserForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    return {
      userDetails: {},
      formErrors: {},
      allPhoneCodeList: [],
      isValid: true,
      isTextInput: false,
    };
  },
  watch: {
    value(val) {
      if (val && this.editMode && this.companyUserId != null) {
        this.getUserDetails(this.companyUserId);
      }
      this.getPhoneCodeList();
    },
  },
  methods: {
    getUserDetails(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.user
        .getUserObject(id)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.userDetails = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getPhoneCodeList(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.country
        .getCountryList(params)
        .then((res) => {
          this.allPhoneCodeList = res.data.map((obj) => obj.phone_code);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    submitForm() {
      this.$bus.$emit("showLoader", true);
      let payload;
      if (!this.editMode) {
        payload = {
          ...this.userDetails,
          company: this.user.id,
          is_active: "Yes",
        };
        if (this.userDetails && this.userDetails.password) {
          payload.password = encryptText(this.userDetails.password);
        }
        this.$api.user
          .addUser(payload)

          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "User created successfully!",
              color: "success",
            });
            this.$emit("refreshList");
            this.resetForm();
            this.showUserForm = false;
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't create user!",
              color: "error",
            });
            this.formErrors = err.data;
          });
      } else {
        payload = {
          id: this.userDetails.id,
          data: this.userDetails,
        };
        if (this.userDetails && this.userDetails.password) {
          payload.data.password = encryptText(this.userDetails.password);
        }
        this.$api.user
          .updateUser(payload)
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "User updated successfully!",
              color: "success",
            });
            this.$emit("refreshList");
            this.resetForm();
            this.showUserForm = false;
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't update user!",
              color: "error",
            });
            this.formErrors = err.data;
          });
      }
    },
    resetForm() {
      this.userDetails = {};
    },
  },
};
</script>
