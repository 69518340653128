import Blank from "@/layouts/blank.vue"

import TransporterList from "@/pages/admin/company-management/transporter/TransporterList.vue";
import TransporterAdd from "@/pages/admin/company-management/transporter/TransporterAdd.vue";
import TransporterEdit from "@/pages/admin/company-management/transporter/TransporterEdit.vue";

const transporterRoutes = [
  {
    path: "transporter",
    name: "app-admin-company-management-transporter",
    component: Blank,
    children: [
      {
        path: "/",
        redirect: "/app/admin/company-management/transporter/list",
      },
      {
        path: "list",
        name: "app-admin-company-management-transporter-list",
        component: TransporterList,
      },
      {
        path: "add",
        name: "app-admin-company-management-transporter-add",
        component: TransporterAdd,
      },
      {
        path: "edit",
        name: "app-admin-company-management-transporter-edit",
        component: Blank,
        children: [
          {
            path: "/",
            redirect: "/app/admin/company-management/transporter/list",
          },
          {
            path: ":id",
            name: "app-admin-company-management-transporter-edit-id",
            component: TransporterEdit,
          }
        ]
      },
    ]
  },
]

export default transporterRoutes