import reportList from "@/pages/admin/report/ReportList.vue";

const reportRoutes = [
  {
    path: "report",
    name: "app-admin-report",
    component: reportList,
  },
]

export default reportRoutes