<template>
  <div>
    <v-tooltip
      top
      v-if="this.params.data && this.params.data.da_charge_type === 'Revenue'"
    >
      <template #activator="{ on, attrs }">
        <v-icon
          color="primary"
          class="mx-1"
          v-on="on"
          v-bind="attrs"
          @click="removeItem"
        >
          mdi-close
        </v-icon>
      </template>
      <span>Remove</span>
    </v-tooltip>
    <v-tooltip
      top
      v-if="this.params.data && this.params.data.da_charge_type === 'Cost'"
    >
      <template #activator="{ on, attrs }">
        <v-icon
          color="primary"
          class="mx-1"
          v-on="on"
          v-bind="attrs"
          @click="removeCostItem"
        >
          mdi-close
        </v-icon>
      </template>
      <span>Remove</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  methods: {
    removeItem() {
      this.params.context.parentComponent.removeCharges(this.params.rowIndex);
    },
    removeCostItem() {
      this.params.context.parentComponent.removeCostCharges(
        this.params.rowIndex
      );
    },
  },
};
</script>

