<template>
  <FormComponent
    title="Add Trailer"
    backRoute="/app/admin/asset-management/trailer/list"
    :nonFieldErrors="formErrors.non_field_errors"
  >
    <template #formActions>
      <v-btn
        color="primary"
        depressed
        :disabled="!allFormValidation"
        @click="submitForm"
        class="mr-2"
      >
        Submit
      </v-btn>

      <v-btn color="secondary" depressed @click="resetForm"> Reset </v-btn>
    </template>

    <template #formContent>
      <v-stepper v-model="stepper" non-linear flat>
        <v-stepper-header class="elevation-0">
          <v-stepper-step
            :rules="[() => (Object.keys(formErrors).length ? valid1 : true)]"
            step="1"
            editable
          >
            Basic Details
          </v-stepper-step>
          <v-divider class="ma-0"></v-divider>

          <v-stepper-step
            :rules="[() => (Object.keys(formErrors).length ? valid2 : true)]"
            step="2"
            editable
            >Document Upload
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1" class="pa-0">
            <v-form ref="stepperForm1" v-model="valid1">
              <v-row class="mx-2 my-0">
                <v-col cols="12" class="Form-Columns-Dense">
                  <span class="text-h6 font-weight-regular">Basic Details</span>
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <MultiSelect
                    label="Trailer Type *"
                    :items="trailerTypeList"
                    :rules="[(value) => !!value || 'Trailer Type is Required!']"
                    hide-details="auto"
                    v-model="trailerDetails.trailer_type"
                    :error-messages="formErrors.trailer_type"
                    @input="delete formErrors.trailer_type"
                  ></MultiSelect>
                </v-col>
                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <InputField
                    type="text"
                    label="Trailer Id *"
                    maxlength="30"
                    :rules="[(value) => !!value || 'Trailer Id is Required!']"
                    required
                    hide-details="auto"
                    v-model="trailerDetails.equipment_id"
                    :error-messages="formErrors.equipment_id"
                    @input="delete formErrors.equipment_id"
                  />
                </v-col>
                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <InputField
                    type="text"
                    label="Trailer Number *"
                    maxlength="30"
                    :rules="[
                      (value) => !!value || 'Trailer Number is Required!',
                    ]"
                    required
                    hide-details="auto"
                    v-model="trailerDetails.trailer_number"
                    :error-messages="formErrors.trailer_number"
                    @input="delete formErrors.trailer_number"
                  />
                </v-col>
                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <InputField
                    type="text"
                    label="Fleet Number *"
                    :rules="[
                      (value) => !!value || 'Fleet Number is Required!',
                    ]"
                    required
                    hide-details="auto"
                    v-model="trailerDetails.inventory_number"
                    :error-messages="formErrors.inventory_number"
                    @input="delete formErrors.inventory_number"
                  />
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <InputField
                    type="text"
                    label="Location"
                    maxlength="255"
                    hide-details="auto"
                    v-model="trailerDetails.location"
                    :error-messages="formErrors.location"
                    @input="delete formErrors.location"
                  />
                </v-col>
                <v-col cols="12" class="Form-Columns-Dense">
                  <v-switch
                    :label="`Carry Road Trail : ${
                      trailerDetails.road_trail == 'Yes' ? 'Yes' : 'No'
                    }`"
                    true-value="Yes"
                    false-value="No"
                    class="mt-1 mb-2"
                    hide-details="auto"
                    v-model="trailerDetails.road_trail"
                    :error-messages="formErrors.road_trail"
                    @change="delete formErrors.road_trail"
                  ></v-switch>
                </v-col>

                <v-col cols="12" lg="6">
                  <v-row>
                    <v-col cols="12" class="Form-Columns-Dense">
                      <span class="text-h6 font-weight-regular">
                        Imco License Details
                      </span>
                    </v-col>
                    <v-col cols="12" lg="6" class="Form-Columns-Dense">
                      <InputField
                        type="text"
                        label="Imco License No"
                        maxlength="255"
                        hide-details="auto"
                        v-model="trailerDetails.imco_license_no"
                        :error-messages="formErrors.imco_license_no"
                        @input="delete formErrors.imco_license_no"
                      />
                    </v-col>
                    <v-col cols="12" lg="6" class="Form-Columns-Dense">
                      <DateField
                        :inputProps="{
                          dense: true,
                          label: 'Imco License Expiry',
                          clearable: true,
                          'hide-details': 'auto',
                          'error-messages': formErrors.imco_license_expiry,
                        }"
                        v-model="trailerDetails.imco_license_expiry"
                        @input="delete formErrors.imco_license_expiry"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" lg="6">
                  <v-row>
                    <v-col cols="12" class="Form-Columns-Dense">
                      <span class="text-h6 font-weight-regular">
                        Registration Details
                      </span>
                    </v-col>
                    <v-col cols="12" lg="6" class="Form-Columns-Dense">
                      <DateField
                        :inputProps="{
                          dense: true,
                          label: 'Registration Start Date',
                          clearable: true,
                          'hide-details': 'auto',
                          'error-messages': formErrors.reg_start_date,
                        }"
                        v-model="trailerDetails.reg_start_date"
                        @input="delete formErrors.reg_start_date"
                      />
                    </v-col>
                    <v-col cols="12" lg="6" class="Form-Columns-Dense">
                      <DateField
                        :inputProps="{
                          dense: true,
                          label: 'Registration End Date',
                          clearable: true,
                          'hide-details': 'auto',
                          'error-messages': formErrors.reg_exp_date,
                        }"
                        v-model="trailerDetails.reg_exp_date"
                        @input="delete formErrors.reg_exp_date"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" class="Form-Columns-Dense">
                  <span class="text-h6 font-weight-regular">
                    Dimensions Details
                  </span>
                </v-col>
                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <InputField
                    type="number"
                    label="Payload Capacity (TON) *"
                    :rules="[(val) => !!val || 'Payload Capacity is Required!']"
                    required
                    hide-details="auto"
                    v-model="trailerDetails.payload_capacity"
                    :error-messages="formErrors.payload_capacity"
                    @input="delete formErrors.payload_capacity"
                  />
                </v-col>
                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <InputField
                    type="number"
                    label="Length (Meter)"
                    hide-details="auto"
                    v-model="trailerDetails.length"
                    :error-messages="formErrors.length"
                    @input="delete formErrors.length"
                  />
                </v-col>
                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <InputField
                    type="number"
                    label="Width (Meter)"
                    hide-details="auto"
                    v-model="trailerDetails.width"
                    :error-messages="formErrors.width"
                    @input="delete formErrors.width"
                  />
                </v-col>
                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <InputField
                    type="number"
                    label="Height (Meter)"
                    hide-details="auto"
                    v-model="trailerDetails.height"
                    :error-messages="formErrors.height"
                    @input="delete formErrors.height"
                  />
                </v-col>

                <v-col cols="12" class="Form-Columns-Dense mt-6">
                  <v-btn
                    color="primary"
                    @click="stepper = 2"
                    :disabled="!valid1"
                  >
                    Next
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step="2" class="pa-0">
            <v-form ref="stepperForm2" v-model="valid2">
              <v-row class="mx-2 my-0">
                <v-col cols="12" class="Form-Columns-Dense">
                  <span class="text-h6 font-weight-regular">
                    Upload Documents
                  </span>
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <MultiSelect
                    :items="documentTypes"
                    label="Document Type"
                    hide-details="auto"
                    v-model="document_type"
                    :error-messages="formErrors.document_type"
                    @input="delete formErrors.document_type"
                  />
                </v-col>
                <v-col cols="12" lg="8" class="Form-Columns-Dense">
                  <v-file-input
                    label="Document"
                    accept="image/x-png,image/jpg,image/jpeg,application/pdf"
                    dense
                    outlined
                    :rules="[
                      (file) =>
                        !file ||
                        file.size < 2e6 ||
                        'File size should be less than 2 MB!',
                    ]"
                    hide-details="auto"
                    v-model="document"
                    :error-messages="formErrors.document"
                    @change="delete formErrors.document, getFileData($event)"
                  />
                </v-col>

                <v-col
                  cols="12"
                  lg="1"
                  class="Form-Columns-Dense align-self-center"
                >
                  <v-btn
                    color="primary"
                    depressed
                    :disabled="isDisabled"
                    @click="addDocument"
                  >
                    Add
                  </v-btn>
                </v-col>

                <v-col cols="12" class="Form-Columns-Dense my-6">
                  <v-btn color="secondary" @click="stepper = 1">
                    Previous
                  </v-btn>
                </v-col>

                <v-col
                  cols="12"
                  class="Form-Columns-Dense"
                  v-show="trailerNewDocuments && trailerNewDocuments.length"
                >
                  <span class="text-h6 font-weight-regular">New Upload</span>

                  <v-card elevation="0" outlined>
                    <v-card-text class="Form-Columns-Dense">
                      <v-row
                        class="ma-0"
                        v-for="(data, j) in trailerNewDocuments"
                        :key="j"
                      >
                        <v-col cols="12" lg="3" class="py-1">
                          <a :href="generateUrl(data.document)" target="_black">
                            <span>{{ data.document_type }}</span>
                          </a>
                        </v-col>

                        <v-col cols="12" lg="6" class="py-1">
                          <a :href="generateUrl(data.document)" target="_black">
                            <span>{{ data.document.name }}</span>
                          </a>
                        </v-col>

                        <v-col cols="12" lg="2" class="py-1">
                          <span>{{ getSize(data.document.size) }}</span>
                        </v-col>

                        <v-col cols="12" lg="1" class="text-right py-1">
                          <v-icon small color="primary" @click="removeFile(j)">
                            mdi-close
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>
  </FormComponent>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import SelectField from "@/components/FormBaseComponents/SelectField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import TextareaField from "@/components/FormBaseComponents/TextareaField.vue";
import FormComponent from "@/components/General/FormComponent.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    InputField,
    SelectField,
    MultiSelect,
    TextareaField,
    FormComponent,
    DateField,
  },

  data() {
    return {
      trailerDetails: {
        road_trail: "No",
      },
      formErrors: {},

      stepper: "1",
      valid1: true,
      // valid2: true,
      valid2: true,
      trailerTypeList: [],

      // // fields for docs
      trailerNewDocuments: [],
      document_type: null,
      document: null,
      // Limitation on date input
      today: new Date().toISOString().slice(0, 10),
      documentTypes: [],
    };
  },

  computed: {
    allFormValidation() {
      return this.valid1 && this.valid2;
    },

    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (!obj) {
        return null;
      } else {
        return obj;
      }
    },
    isDisabled() {
      if (this.document_type && this.document) {
        return false;
      } else {
        return true;
      }
    },
  },

  methods: {
    checkFileFormat(event) {
      const name = event.name;
      const lastDot = name.lastIndexOf(".");
      const ext = name.substring(lastDot + 1);
      let allowed = /(\jpg|\jpeg|\pdf|\png)$/i;
      if (!allowed.exec(ext)) {
        this.$nextTick(() => {
          this.document = null;
        });
        return false;
      } else {
        return true;
      }
    },
    getFileData(fileInput) {
      if (fileInput) {
        if (this.checkFileFormat(fileInput)) {
          if (fileInput && !this.document_type) {
            this.$nextTick(() => {
              this.document_type = null;
              this.document = null;
            });
            this.$bus.$emit("showToastMessage", {
              message: "Select Document type first!",
              color: "error",
            });
            return false;
          }
        } else {
          alert("This file format is not Supported!");
        }
      }
    },

    addDocument() {
      this.trailerNewDocuments.push({
        document_type: this.document_type,
        document: this.document,
      });
      this.document = null;
      this.document_type = null;
    },
    removeFile(index) {
      this.trailerNewDocuments.splice(index, 1);
    },
    generateUrl(file) {
      return URL.createObjectURL(file);
    },
    getSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    async uploadTrailerDocuments(id) {
      let i = 0;
      while (i < this.trailerNewDocuments.length) {
        let payload = {
          id: id,
          data: null,
        };
        let newForm = new FormData();
        newForm.append(
          "document_type",
          this.trailerNewDocuments[i].document_type
        );
        newForm.append("document", this.trailerNewDocuments[i].document);
        payload.data = newForm;
        await this.$api.trailer.uploadTrailerDocument(payload);
        i++;
      }
    },

    submitForm() {
      this.$bus.$emit("showLoader", true);
      this.formErrors = {};
      let payload = {
        company: this.currentProfile.company,
        ...this.trailerDetails,
      };
      this.$api.trailer
        .addTrailer(payload)
        .then(async (res) => {
          if (this.trailerNewDocuments.length > 0) {
            await this.uploadTrailerDocuments(res.data.response_data.id);
          }
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Trailer created successfully!",
            color: "success",
          });
          this.$router.push("/app/admin/asset-management/trailer/list");
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't Create Trailer!",
            color: "error",
          });
          this.formErrors = { ...this.formErrors, ...err.data };
        });
    },

    getAllTrailerTypeList() {
      this.$api.trailerType
        .getTrailerTypeSelectList({ limit: "all" })
        .then((res) => {
          this.trailerTypeList = res.data.map((obj) => {
            return {
              text: obj.title,
              value: obj.id,
            };
          });
        })
        .catch((err) => {
          console(err);
        });
    },

    resetForm() {
      this.trailerDetails = {
        road_trail: "No",
      };
      this.$refs.stepperForm1.resetValidation();
      this.$refs.stepperForm2.resetValidation();
      // this.$refs.stepperForm3.resetValidation();
    },
    getTrailerContainerTypes() {
      this.$api.containerType
        .getContainerTypeList({ limit: "all" })
        .then((res) => {
          this.allTrailerContainerTypes = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    getTrailerDocumentOptions() {
      this.$bus.$emit("showLoader", true);
      this.$api.trailer
        .getTrailerOptions()
        .then((res) => {
          this.documentTypes =
            res.data.actions.POST.trailer_documents.child.children.document_type.choices.map(
              (obj) => {
                return {
                  text: obj.display_name,
                  value: obj.value,
                };
              }
            );
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },

  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("trailer");
      if (!permissions || !permissions.add) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.getAllTrailerTypeList();

        this.getTrailerDocumentOptions();
      }
    }
  },
};
</script>
