import { maintenance } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getMaintenanceList(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(maintenance.maintenanceBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getMaintenanceObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${maintenance.maintenanceBase}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getMaintenanceOptions() {
    return new Promise((resolve, reject) => {
      axios
        .options(`${maintenance.maintenanceBase}`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addMaintenance(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(maintenance.maintenanceBase, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updateMaintenance(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${maintenance.maintenanceBase}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  partiallyUpdateMaintenance(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${maintenance.maintenanceBase}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
