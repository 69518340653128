import { department } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
    getDepartmentList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(department.departmentBase, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getDepartmentSelectList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${department.departmentBase}select/`, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getDepartmentObject(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${department.departmentBase}${id}/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    addDepartment(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(department.departmentBase, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    updateDepartment(payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(`${department.departmentBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    partiallyUpdateDepartment(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${department.departmentBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
});