<template>
  <v-dialog v-model="CustomerContractDetails" width="500">
    <v-card v-if="customerContractId">
      <v-card-title class="secondary white--text px-4 py-2">
        Tariff Id -
        {{ customerContractDetails.contract_id }}
        <v-spacer></v-spacer>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="white"
              icon
              @click="CustomerContractDetails = false"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon class="white--text">mdi-close</v-icon>
            </v-btn>
          </template>
          <span>Close</span>
        </v-tooltip>
      </v-card-title>

      <v-card-text>
        <table class="Normal-Table my-2">
          <tbody>
            <tr>
              <td>Customer Name</td>
              <th class="px-2">:</th>
              <th>
                {{ customerContractDetails.customer_name }}
              </th>
            </tr>

            <tr v-if="customerContractDetails.source">
              <td>Source</td>
              <th class="px-2">:</th>
              <th>
                {{ customerContractDetails.source }}
              </th>
            </tr>

            <tr v-if="customerContractDetails.mid_point_name">
              <td>Mid Point</td>
              <th class="px-2">:</th>
              <th>
                {{ customerContractDetails.mid_point_name }}
              </th>
            </tr>

            <tr v-if="customerContractDetails.destination_name">
              <td>Destination</td>
              <th class="px-2">:</th>
              <th>
                {{ customerContractDetails.destination_name }}
              </th>
            </tr>

            <tr>
              <td>Job Type</td>
              <th class="px-2">:</th>
              <th>
                {{ customerContractDetails.job_type }}
              </th>
            </tr>

            <tr>
              <td>Start Date</td>
              <th class="px-2">:</th>
              <th>
                {{ customerContractDetails.start_date | formatDate }}
              </th>
            </tr>

            <tr>
              <td>End Date</td>
              <th class="px-2">:</th>
              <th>
                {{ customerContractDetails.end_date | formatDate }}
              </th>
            </tr>
          </tbody>
        </table>

        <v-divider></v-divider>

        <table class="Full-Width">
          <tbody>
            <tr>
              <td>
                <div class="my-2">
                  <span>Remarks</span>
                  <p class="font-weight-bold mb-0">
                    {{ customerContractDetails.admin_remarks }}
                  </p>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <v-divider></v-divider>
                <table class="Full-Width text-left mt-2">
                  <thead>
                    <tr>
                      <th>Container Type</th>
                      <th class="text-right">Container Count</th>
                      <th class="text-right">
                        Container Rate ({{ $globals.currency }})
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(
                        container, i
                      ) in customerContractDetails.container_types"
                      :key="i"
                    >
                      <td>{{ container.container_type_title }}</td>
                      <td class="text-right">
                        {{ container.container_count }}
                      </td>
                      <td class="text-right">{{ container.rate }}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";

export default {
  components: { InputField, MultiSelect },
  props: {
    value: {
      type: Boolean,
    },
    customerContractId: {
      type: [Object, Number],
      default: () => {},
    },
  },
  data() {
    return {
      customerContractDetails: {},
    };
  },
  computed: {
    CustomerContractDetails: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    value(val) {
      if (val) {
        if (this.customerContractId) {
          this.getCustomerContractObject(this.customerContractId);
        }
      }
    },
  },

  methods: {
    getCustomerContractObject(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.customerContract
        .getCustomerContractObject(this.customerContractId)
        .then((res) => {
          this.customerContractDetails = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
};
</script>