<template>
  <v-dialog width="500" v-model="showLegPODForm">
    <v-card>
      <v-card-title class="secondary white--text">
        <span class="text-capitalize"> Add POD </span>

        <v-spacer></v-spacer>

        <v-btn icon @click="resetForm(), (showLegPODForm = false)">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-2">
        <v-form ref="documentForm" v-model="isValid">
          <v-row class="my-0">
            <v-col cols="12" class="Form-Columns">
              <MultiSelect
                label="Select Leg *"
                :items="daLegList"
                itemText="leg_id"
                itemValue="id"
                hide-details="auto"
                v-model="documentDetails.leg"
                :error-messages="formErrors.leg"
                @change="delete formErrors.leg"
              />
            </v-col>

            <v-col cols="12" class="Form-Columns">
              <v-file-input
                label="POD"
                accept="image/x-png,image/jpg,image/jpeg,application/pdf"
                :rules="[
                  (file) =>
                    !file ||
                    file.size < 2e6 ||
                    'File size should be less than 2 MB!',
                ]"
                outlined
                dense
                hide-details="auto"
                v-model="documentDetails.pod"
                :error-messages="formErrors.pod"
                @change="checkFileFormat($event), delete formErrors.pod"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          depressed
          :disabled="!documentDetails.pod || !documentDetails.leg || !isValid"
          @click="uploadLegPODs"
        >
          Upload
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import SelectField from "@/components/FormBaseComponents/SelectField.vue";

export default {
  components: {
    InputField,
    SelectField,
    MultiSelect,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    daID: {
      required: true,
    },
    daLegList: {
      type: Array,
      required: true,
    },
  },
  computed: {
    showLegPODForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    return {
      documentDetails: {},
      formErrors: {},
      isTextInput: false,
      isValid: true,
    };
  },
  methods: {
    checkFileFormat(event) {
      if (event) {
        const name = event.name;
        const lastDot = name.lastIndexOf(".");
        const ext = name.substring(lastDot + 1);
        let allowed = /(\jpg|\jpeg|\pdf|\png)$/i;
        if (!allowed.exec(ext)) {
          this.$nextTick(() => {
            this.documentDetails.document = null;
            this.$bus.$emit("showToastMessage", {
              message: "This file format is not supported!",
              color: "error",
            });
          });
          return false;
        } else {
          return true;
        }
      }
    },
    uploadLegPODs() {
      let payload = {
        id: this.documentDetails.leg,
        data: null,
      };
      let newForm = new FormData();
      newForm.append("pod", this.documentDetails.pod);
      payload.data = newForm;
      this.$bus.$emit("showLoader", true);
      this.$api.trip
        .uploadLegPOD(payload)
        .then(() => {
          this.documentDetails = {};
          this.$emit("refreshObject");
          this.$bus.$emit("showToastMessage", {
            message: "POD Uploaded Successfully!",
            color: "success",
          });
          this.$bus.$emit("showLoader", false);

          this.formErrors = {};
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.formErrors = err.data;
          this.$bus.$emit("showToastMessage", {
            message: `${
              err.data && err.data.non_field_errors
                ? err.data.non_field_errors.join()
                : "Cannot upload POD"
            }`,
            color: "error",
          });
        });
    },
    resetForm() {
      if (this.$refs.documentForm) {
        this.$refs.documentForm.reset();
      }
      this.documentDetails = {};
    },
  },
};
</script>
