var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
          _vm.params &&
          _vm.params.context &&
          _vm.params.context.parentComponent &&
          _vm.params.context.parentComponent.permissions &&
          _vm.params.context.parentComponent.permissions.change
        )?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-4 ml-1",attrs:{"color":"primary"},on:{"click":_vm.editItem}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")]):_vm._e()]}}])},[_c('span',[_vm._v("Edit")])]),(_vm.params.data.is_active == 'Yes')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
          _vm.params &&
          _vm.params.context &&
          _vm.params.context.parentComponent &&
          _vm.params.context.parentComponent.permissions &&
          _vm.params.context.parentComponent.permissions.add
        )?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 mr-1",attrs:{"color":"primary"},on:{"click":_vm.deactivate}},'v-icon',attrs,false),on),[_vm._v(" mdi-close ")]):_vm._e()]}}],null,false,3861930562)},[_c('span',[_vm._v("DeActivate")])]):_vm._e(),(_vm.params.data.is_active == 'No')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
          _vm.params &&
          _vm.params.context &&
          _vm.params.context.parentComponent &&
          _vm.params.context.parentComponent.permissions &&
          _vm.params.context.parentComponent.permissions.add
        )?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 mr-1",attrs:{"color":"primary"},on:{"click":_vm.activate}},'v-icon',attrs,false),on),[_vm._v(" mdi-check ")]):_vm._e()]}}],null,false,3720404595)},[_c('span',[_vm._v("Activate")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }