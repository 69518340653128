<template>
  <div style="position: relative">
    <div id="gmap" :style="{ width: mapWidth, height: mapHeight }"></div>
    <div class="mapInfo" v-if="hasMapInfo">
      <v-menu bottom offset-y v-model="menu">
        <template v-slot:activator="{ attrs }">
          <v-btn
            color="cyan darken-2"
            dark
            x-small
            v-bind="attrs"
            depressed
            fab
            v-on="{ ...menu }"
            @click="menu = true"
          >
            <v-icon v-if="!menu">mdi-information</v-icon>
            <v-icon v-else>mdi-close</v-icon>
          </v-btn>
        </template>
        <v-card>
          <slot name="mapInfo" />
        </v-card>
      </v-menu>
    </div>
  </div>
</template>

<script>
import {
  sourceMarker,
  destinationMarker,
  midMarker,
  defaultMarker,
  truckMarker,
  blueDot,
} from "@/assets/images/mapIcons.js";

export default {
  props: {
    isDrawable: {
      type: Boolean,
      default: false,
    },
    mapWidth: {
      type: String,
      default: "auto",
    },
    mapHeight: {
      type: String,
      default: "auto",
    },
    hasMapInfo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // mapInfo vars
      fab: false,
      menu: false,

      // instances
      map: null,
      bounds: null,
      directionsRenderer: null,
      directionsService: null,
      drawingManager: null,

      // default val
      lat: 24.4539,
      lng: 54.3773,
      polyOptions: {
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#00FF00",
        fillOpacity: 0.35,
        editable: true,
      },
      selectedShape: null,
      //
      markersArray: [],
    };
  },
  methods: {
    initMap(center = null) {
      let interval = setInterval(() => {
        if (window.google && window.google.maps) {
          clearInterval(interval);
          if (!center) var center = new google.maps.LatLng(this.lat, this.lng);
          this.map = new google.maps.Map(document.getElementById("gmap"), {
            zoom: 10,
            center: center,
            disableDefaultUI: true,
            keepSpiderfied: true,
          });
          this.bounds = new google.maps.LatLngBounds();
          if (this.isDrawable) {
            this.drawingManager = new google.maps.drawing.DrawingManager({
              map: this.map,
              polygonOptions: this.polyOptions,
              rectangleOptions: this.polyOptions,
              drawingControl: true,
              drawingControlOptions: {
                position: google.maps.ControlPosition.RIGHT_TOP,
                editable: true,
                drawingModes: [
                  google.maps.drawing.OverlayType.POLYGON,
                  google.maps.drawing.OverlayType.RECTANGLE,
                ],
              },
            });
            google.maps.event.addListener(
              this.drawingManager,
              "overlaycomplete",
              this.overlayCompleteCallback.bind(this)
            );
          }
        }
      }, 100);
    },
    getDirections(options) {
      return new Promise((resolve, reject) => {
        let interval = setInterval(() => {
          if (window.google && window.google.maps && this.map) {
            clearInterval(interval);

            this.directionsService = new window.google.maps.DirectionsService();
            this.directionsService.route(options, (response, status) => {
              if (status !== "OK") {
                reject(new Error("Could not fetch directions."));
              }

              resolve(response);
            });
          }
        }, 500);
      });
    },
    generateGeoJsonFromLineString(lineString) {
      let geoJson = {
        features: [
          {
            geometry: lineString,
            properties: {},
            type: "Feature",
          },
        ],
        type: "FeatureCollection",
      };

      return geoJson;
    },
    generateLineStringFromRoute(path) {
      return new Promise((resolve, reject) => {
        let lineString = {
          coordinates: [],
          type: "LineString",
        };

        path.overview_path.forEach((p) => {
          lineString.coordinates.push([p.lng(), p.lat()]);
          if (path.overview_path.length == lineString.coordinates.length) {
            resolve(lineString);
          }
        });
      });
    },
    async removeGeoJson() {
      this.map.data.forEach((feature) => {
        this.map.data.remove(feature);
      });
    },
    async removeAllMarkers() {
      while (this.markersArray.length != 0) {
        this.markersArray[0].setMap(null);
        this.markersArray.splice(0, 1);
      }
    },
    overlayCompleteCallback(event) {
      this.setSelection(event);
      if (event.type == "polygon") {
        window.google.maps.event.addListener(
          this.selectedShape.overlay.getPath(),
          "insert_at",
          () => {
            this.setSelection(event);
          }
        );
        window.google.maps.event.addListener(
          this.selectedShape.overlay.getPath(),
          "set_at",
          () => {
            this.setSelection(event);
          }
        );
      } else {
        window.google.maps.event.addListener(
          this.selectedShape.overlay,
          "bounds_changed",
          () => {
            this.setSelection.bind(this);
          }
        );
      }
      this.drawingManager.setDrawingMode(null);
      this.drawingManager.setOptions({
        drawingControl: false,
      });
    },
    setSelection(shape) {
      this.clearSelection();
      this.selectedShape = shape;
      if (shape.overlay.getEditable() == false) {
        shape.overlay.setEditable(true);
      }
      this.constructCordinates(this.selectedShape.type);
    },
    clearSelection() {
      if (this.selectedShape) {
        if (this.selectedShape.overlay) {
          this.selectedShape.overlay.setEditable(false);
        }
        this.selectedShape = null;
      }
    },
    constructCordinates(shapeType) {
      this.coordinates = [];
      if (shapeType == "polygon") {
        this.selectedShape.overlay
          .getPath()
          .getArray()
          .forEach((ele) => this.coordinates.push([ele.lng(), ele.lat()]));
        this.coordinates.push(this.coordinates[0]);
      } else {
        let bounds = this.selectedShape.overlay.getBounds();
        this.coordinates = [
          [bounds.getSouthWest().lng(), bounds.getSouthWest().lat()],
          [bounds.getNorthEast().lng(), bounds.getSouthWest().lat()],
          [bounds.getNorthEast().lng(), bounds.getNorthEast().lat()],
          [bounds.getSouthWest().lng(), bounds.getNorthEast().lat()],
          [bounds.getSouthWest().lng(), bounds.getSouthWest().lat()],
        ];
      }
    },
    async addGeoCollection(collection, reset = true) {
      if (reset) {
        await this.removeGeoJson();
      }
      this.map.data.addGeoJson(collection);
      this.map.data.setStyle(function (feature) {
        let color = feature.getProperty("actual_route") ? "red" : "black";
        return /** @type {!google.maps.Data.StyleOptions} */ ({
          strokeColor: color,
          strokeWeight: 2,
        });
      });
    },
    processPoints(geometry, callback, thisArg) {
      if (geometry instanceof google.maps.LatLng) {
        callback.call(thisArg, geometry);
      } else if (geometry instanceof google.maps.Data.Point) {
        callback.call(thisArg, geometry.get());
      } else {
        geometry.getArray().forEach((g) => {
          this.processPoints(g, callback, thisArg);
        });
      }
    },
    async addMultipleMarkers(coordinateList) {
      await this.removeAllMarkers();
      if (coordinateList.length) {
        let i = 0;
        while (coordinateList.length != this.markersArray.length) {
          this.markersArray.push(this.addMarker(coordinateList[i]));
          i++;
        }
      }
    },
    addMarker(coordinates, draggable = false) {
      let position = {};
      if (Array.isArray(coordinates)) {
        position = {
          lat: coordinates[1],
          lng: coordinates[0],
        };
      } else {
        position = {
          lat: coordinates.latitude,
          lng: coordinates.longitude,
        };
      }

      let markerConfig = {
        position: position,
        map: this.map,
        draggable: draggable,
        animation: google.maps.Animation.DROP,
      };

      if (coordinates.type) {
        markerConfig.icon = {
          url: this.getIcon(coordinates.type),
        };
      }

      let markerObj = new google.maps.Marker(markerConfig);
      this.map.setCenter(position);

      if (coordinates.type == "truck") {
        let infowindow = new google.maps.InfoWindow({ maxWidth: 470 });
        let html = "<ul>";
        if (coordinates.vehicle_id) {
          html =
            html +
            `<li class="d-flex pa-0 ma-0">
                  <span><b>Vehicle Number:</b> ${coordinates.vehicle_id}</span>
                </li>`;
        }
        if (coordinates.device_id) {
          html =
            html +
            `<li class="d-flex pa-0 ma-0">
                  <span><b>Device ID:</b> ${coordinates.device_id}</span>
                </li>`;
        }
        if (typeof coordinates.speed == "number") {
          html =
            html +
            `<li class="d-flex pa-0 ma-0">
                  <span><b>speed:</b> ${coordinates.speed} Km/h</span>
                </li>`;
        }
        html = html + "</ul>";
        markerObj.addListener("mouseover", () => {
          infowindow.setContent(html);
          infowindow.open(this.map, markerObj);
        });
        markerObj.addListener("mouseout", () => {
          infowindow.close();
        });
      }

      return markerObj;
    },
    getIcon(type) {
      switch (type) {
        case "source":
          return sourceMarker;
        case "destination":
          return destinationMarker;
        case "mid":
          return midMarker;
        case "truck":
          return truckMarker;
        case "blue_dot":
          return blueDot;

        default:
          return defaultMarker;
      }
    },
  },
  async mounted() {
    await this.initMap();
    let interval = setInterval(() => {
      if (this.map) {
        clearInterval(interval);
        this.map.data.addListener("addfeature", (e) => {
          this.bounds = new google.maps.LatLngBounds();
          this.processPoints(
            e.feature.getGeometry(),
            this.bounds.extend,
            this.bounds
          );
          this.map.setCenter(this.bounds.getCenter());
          this.map.fitBounds(this.bounds);
        });
      }
    }, 300);
  },
};
</script>

<style scoped>
.mapInfo {
  left: 5px;
  top: 5px;
  position: absolute;
  z-index: 1;
}
</style>