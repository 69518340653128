import CryptoJS from "crypto-js";

export function handleResponse(res) {
  let obj = {};
  if ("data" in res && typeof res.data == typeof {}) {
    if ("results" in res.data) {
      obj.data = res.data.results;
      obj.count = res.data.count;
    } else {
      obj.data = res.data;
    }
  } else {
    obj = res;
  }
  return obj;
}

export function createGoogleMapsDirectionLink(originCoords, destinationCoords, waypoints = "") {
  if (waypoints) {
    return `https://www.google.com/maps/dir/?api=1&origin=${originCoords}&destination=${destinationCoords}&waypoints=${waypoints}`;
  }
  else {
    return `https://www.google.com/maps/dir/?api=1&origin=${originCoords}&destination=${destinationCoords}`;
  }
}

export function handleRawResponse(res) {
  let obj = {};
  if ("data" in res && typeof res.data == typeof {}) {
    if ("results" in res.data) {
      obj.data = res.data.results;
      obj.count = res.data.count;
      obj.additional_info = res.data.additional_info;
    } else {
      obj.data = res.data;
    }
  } else {
    obj = res;
  }
  return obj;
}

export function handleError(err) {
  let obj = {};
  if (err && err.response && "data" in err.response) {
    obj.data = err.response.data;
  }
  if (err && err.response && "status" in err.response) {
    obj.status = err.response.status;
  }

  return obj;
}

export function getFileNameFromUrl(url) {
  let chunk = url.split('/')
  return chunk[chunk.length - 1]
}

/**
 * Raplace char from keys of array of object
 * @param {Array<Object>} array Array of Object to format keys in.
 * @param {string} charToReplace char to replace from keys
 * @param {string} charToReplaceWith char to replace with
 * @param {null | 'uppercase' | 'lowercase'} changeCaseTo change case
 * @returns [{ key: value }]
 */
export async function formatDataKeys(array, charToReplace, charToReplaceWith, changeCaseTo = null) {
  let regex = new RegExp(charToReplace, 'g')
  return array.map((data) => {
    for (let key in data) {
      let __key = key.replace(regex, charToReplaceWith)
      if (changeCaseTo == 'lowercase') {
        __key = __key.toLowerCase()
      }
      if (changeCaseTo == 'uppercase') {
        __key = __key.toUpperCase()
      }
      data[__key] = data[key];
      delete data[key]
    }
    return data
  });
}

export function isPrimitive(val) {
  return !(typeof val == "object" || typeof val == "function");
}

export function isAuthenticated() {
  return !!localStorage.getItem("user");

}

export function isImpersonating() {
  return !!localStorage.getItem("i_user");
}

export function hasRouteAccess(route) {
  let user_type = localStorage.getItem("user_type");
  if (user_type) {
    const r = route.name.split("-");
    if (r && r.length && r[1] == user_type.toLowerCase()) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function getPermissions(module) {
  let permissions = localStorage.getItem("permissions");
  if (permissions) {
    permissions = JSON.parse(permissions);
    return permissions[module] ? permissions[module] : null;
  } else {
    return null;
  }
}

export function getAllUserPreferances() {
  let preferences = localStorage.getItem("userPreferences");
  if (preferences) {
    return JSON.parse(preferences);
  }
  return {}
}

export function getUserPreferances(key) {
  let preferences = getAllUserPreferances()
  // debugger
  if (preferences && preferences[key]) {
    return preferences[key]
  }
  return []
}

export function generateChartOptions(
  chartType,
  chartId = "vue-chart-1",
  labelsArray = []
) {
  let obj = {};
  if (chartType == "stackedBar" || chartType == "bar") {
    obj = {
      chart: {
        type: chartType == "stackedBar" ? "bar" : chartType,
        height: "450",
        stacked: chartType == "stackedBar" ? true : false,
        id: chartId,
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: labelsArray,
        fill: {
          opacity: 1,
        },
      },
    };
  }
  if (chartType == "pie") {
    obj = {
      chart: {
        type: chartType,
      },
      labels: labelsArray,
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    };
  }

  return obj;
}

export function generateChartSeries(seriesNames, dataToPopulate) {
  let arrayToReturn = [];
  if (
    Array.isArray(seriesNames) &&
    Array.isArray(dataToPopulate) &&
    dataToPopulate.every((val) => Array.isArray(val))
  ) {
    seriesNames.map((val, index) => {
      arrayToReturn[index] = {
        name: val,
        data: dataToPopulate[index],
      };
    });
  } else {
    arrayToReturn = [
      {
        name: seriesNames,
        data: dataToPopulate,
      },
    ];
  }
  return arrayToReturn;
}

export function encryptText(text) {
  let key = process.env.VUE_APP_CIPHER_KEY;
  key = CryptoJS.enc.Utf8.parse(key);
  let encrypted = CryptoJS.AES.encrypt(text, key, {
    mode: CryptoJS.mode.ECB,
  });
  encrypted = encrypted.toString();
  return encrypted;
}

export function downloadBlobData(data, filename) {
  const url = window.URL.createObjectURL(
    new Blob([data], { type: "application/octet-stream" })
  );
  var a = document.createElement("a");
  a.href = url;
  a.setAttribute("download", filename);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function setCookie(name, value, domain) {
  document.cookie = name + "=" + (value || "") + "; domain=" + (domain || "") + "; path=/; SameSite=None; Secure";
}

export function getCookie(name) {
  let nameEQ = name + "=";
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function eraseCookie(name) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
