<template>
  <v-dialog fullscreen v-model="showAddCustomerContractDialog">
    <v-card>
      <v-card-title class="secondary white--text">
        <span class="text-capitalize"> Add Tariff </span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="
            resetForm();
            showAddCustomerContractDialog = false;
          "
        >
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-4">
        <v-form
          ref="contractForm"
          v-if="showAddCustomerContractDialog"
          v-model="isValid"
        >
          <FormComponent
            :nonFieldErrors="formErrors.non_field_errors"
            title="Tariff Details"
            :stickyHeader="true"
          >
            <template #formActions>
              <v-btn
                color="primary"
                depressed
                @click="submitForm"
                :disabled="!isValid"
              >
                Add
              </v-btn>
              <v-btn
                class="ml-3"
                color="secondary"
                depressed
                @click="
                  resetForm();
                  showAddCustomerContractDialog = false;
                "
              >
                Cancel
              </v-btn>
            </template>
            <template #formContent>
              <div class="card-inner-container">
                <v-row class="ma-0">
                  <v-col cols="12" sm="6" md="4" lg="3" class="pa-2">
                    <MultiSelect
                      label="Select Customer *"
                      :items="allCustomerList"
                      itemText="company_name"
                      itemValue="id"
                      :rules="[(val) => !!val || 'Customer is Required!']"
                      hide-details="auto"
                      readonly
                      v-model="contractDetails.customer"
                      :error-messages="formErrors.customer"
                    ></MultiSelect>
                  </v-col>

                  <v-col cols="12" sm="6" md="4" lg="3" class="pa-2">
                    <MultiSelect
                      label="Job Type *"
                      :items="allJobType"
                      readonly
                      itemText="display_name"
                      itemValue="value"
                      hide-details="auto"
                      v-model="contractDetails.job_type"
                      :rules="[(v) => !!v || 'Job Type is Required']"
                      :error-messages="formErrors.job_type"
                      @change="delete formErrors.job_type"
                    />
                  </v-col>

                  <v-col cols="12" sm="6" md="4" lg="3" class="pa-2">
                    <DateField
                      :inputProps="{
                        dense: true,
                        label: 'Start Date *',
                        rules: [(val) => !!val || 'Start Date is required!'],
                        'hide-details': 'auto',
                        'error-messages': formErrors.start_date,
                      }"
                      required
                      :min="today"
                      v-model="contractDetails.start_date"
                      @input="delete formErrors.start_date"
                    />
                  </v-col>

                  <v-col cols="12" sm="6" md="4" lg="3" class="pa-2">
                    <DateField
                      :inputProps="{
                        dense: true,
                        label: 'End Date *',
                        rules: [(val) => !!val || 'End Date is required!'],
                        'hide-details': 'auto',
                        'error-messages': formErrors.end_date,
                      }"
                      required
                      :min="contractDetails.start_date"
                      v-model="contractDetails.end_date"
                      @input="delete formErrors.end_date"
                    />
                  </v-col>
                  <v-col cols="12" class="pa-2">
                    <Autocomplete
                      :loading="listLoading"
                      :totalItems="laneCount"
                      :items="allLaneList"
                      @updateList="getLaneList"
                      label="Route *"
                      itemText="title"
                      itemValue="id"
                      hide-details="auto"
                      :rules="[(v) => !!v || 'Route is Required']"
                      v-model="contractDetails.lane"
                      :error-messages="formErrors.lane"
                      @input="
                        delete formErrors.lane;
                        delete formErrors.job_type;
                      "
                    />
                  </v-col>
                </v-row>
                <v-row
                  v-for="(con, i) in contractDetails.customer_contract_rates"
                  :key="i"
                  class="ma-0"
                >
                  <v-col cols="12" sm="6" md="4" lg="3" class="pa-2">
                    <MultiSelect
                      label="Container Type *"
                      :items="allContainersType"
                      itemText="title"
                      itemValue="id"
                      hide-details="auto"
                      :rules="[(v) => !!v || 'Container Type is Required']"
                      v-model="con.container_type"
                      :error-messages="
                        formErrors &&
                        formErrors.customer_contract_rates &&
                        formErrors.customer_contract_rates[i] &&
                        formErrors.customer_contract_rates[i].container_type
                          ? formErrors.customer_contract_rates[i].container_type
                          : []
                      "
                      @change="
                        formErrors &&
                        formErrors.customer_contract_rates &&
                        formErrors.customer_contract_rates[i] &&
                        formErrors.customer_contract_rates[i].container_type
                          ? delete formErrors.customer_contract_rates[i]
                              .container_type
                          : null
                      "
                    />
                  </v-col>

                  <v-col cols="12" sm="6" md="4" lg="3" class="pa-2">
                    <InputField
                      type="number"
                      min="1"
                      max="4"
                      label="Container Count/Truck *"
                      hide-details="auto"
                      :rules="[
                        (v) => !!v || 'Container Count/Truck is Required',
                        (v) =>
                          v > 0 ||
                          'Container Count/Truck can not be less than 1',
                        (v) =>
                          v < 5 ||
                          'Container Count/Truck can not be more than 4',
                      ]"
                      v-model="con.container_count"
                      :error-messages="
                        formErrors &&
                        formErrors.customer_contract_rates &&
                        formErrors.customer_contract_rates[i] &&
                        formErrors.customer_contract_rates[i].container_count
                          ? formErrors.customer_contract_rates[i]
                              .container_count
                          : []
                      "
                      @input="
                        formErrors &&
                        formErrors.customer_contract_rates &&
                        formErrors.customer_contract_rates[i] &&
                        formErrors.customer_contract_rates[i].container_count
                          ? delete formErrors.customer_contract_rates[i]
                              .container_count
                          : null
                      "
                    />
                  </v-col>

                  <v-col cols="12" sm="6" md="4" lg="3" class="pa-2">
                    <InputField
                      type="number"
                      min="0"
                      hide-details="auto"
                      :label="`Rate (${$globals.currency}) *`"
                      :rules="[(v) => !!v || 'Rate is Required']"
                      v-model="con.rate"
                      :error-messages="
                        formErrors &&
                        formErrors.customer_contract_rates &&
                        formErrors.customer_contract_rates[i] &&
                        formErrors.customer_contract_rates[i].rate
                          ? formErrors.customer_contract_rates[i].rate
                          : []
                      "
                      @input="
                        formErrors &&
                        formErrors.customer_contract_rates &&
                        formErrors.customer_contract_rates[i] &&
                        formErrors.customer_contract_rates[i].rate
                          ? delete formErrors.customer_contract_rates[i].rate
                          : null
                      "
                    />
                  </v-col>

                  <v-col cols="12" sm="6" md="4" lg="3" class="pa-2">
                    <v-btn
                      color="primary"
                      depressed
                      @click="addContainerType"
                      class="mr-2"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>

                    <v-btn
                      color="secondary"
                      depressed
                      :disabled="
                        contractDetails.customer_contract_rates.length == 1
                      "
                      @click="removeContainerType(i)"
                    >
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </template>
          </FormComponent>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import Autocomplete from "@/components/FormBaseComponents/Autocomplete.vue";
import SelectField from "@/components/FormBaseComponents/SelectField.vue";
import TextareaField from "@/components/FormBaseComponents/TextareaField.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";
import FormComponent from "@/components/General/FormComponent.vue";

export default {
  components: {
    InputField,
    SelectField,
    TextareaField,
    MultiSelect,
    Autocomplete,
    FormComponent,
    DateField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    jobType: {
      type: String,
      default: "",
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    customerId: {
      type: [Number, Object],
      default: null,
    },
    addressId: {
      type: Number,
    },
  },
  computed: {
    showAddCustomerContractDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    return {
      today: new Date().toISOString().slice(0, 10),
      contractDetails: {
        customer_contract_rates: [{}],
      },
      formErrors: {
        customer_contract_rates: [{}],
      },
      isValid: true,

      // list vars
      allCustomerList: [],
      listLoading: false,
      laneCount: 0,
      allLaneList: [],
      allContainersType: [],
      allJobType: [],
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.getCustomerList();
        this.getCustomerContractOptions();
        this.getContainersType();
        this.getLaneList();
      }
    },
  },
  methods: {
    getContainersType() {
      this.$bus.$emit("showLoader", true);
      this.$api.containerType
        .getContainerTypeList({ limit: "all" })
        .then((res) => {
          this.allContainersType = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    removeContainerType(i) {
      this.contractDetails.customer_contract_rates.splice(i, 1);
      this.formErrors.customer_contract_rates.splice(i, 1);
    },
    addContainerType() {
      this.contractDetails.customer_contract_rates.push({});
      this.formErrors.customer_contract_rates.push({});
    },
    getCustomerList(params = {}) {
      params = {
        company_type: "Customer",
        limit: "all",
        ...params,
      };
      this.$api.company
        .getCompanyList(params)
        .then((res) => {
          this.allCustomerList = res.data;
          this.contractDetails.customer = this.customerId;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getCustomerContractOptions() {
      this.$bus.$emit("showLoader", true);
      this.$api.customerContract
        .getCustomerContractOptions()
        .then((res) => {
          this.allJobType = res.data.actions.POST.job_type.choices;
          this.contractDetails.job_type = this.jobType;

          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getLaneList(params = {}) {
      this.listLoading = true;
      params = {
        offset: 0,
        limit: 10,
        ...params,
      };

      this.$api.lane
        .getLaneList(params)
        .then((res) => {
          this.listLoading = false;
          if ("count" in res) {
            this.laneCount = res.count;
          }

          if (params.offset == 0) {
            this.allLaneList = res.data;
          } else {
            this.allLaneList.push(...res.data);
          }
        })
        .catch((err) => {
          this.listLoading = false;
        });
    },
    submitForm() {
      this.$bus.$emit("showLoader", true);
      let payload = {
        ...this.contractDetails,
        jobType: this.jobType,
        customer: this.customerId,
      };

      payload.customer_contract_rates.forEach((val, index, arr) => {
        delete arr[index].id;
      });

      if (payload.customer && typeof payload.customer != "number") {
        payload.customer = this.customerId.id;
      }

      if (payload.lane && typeof payload.lane == "object") {
        payload.lane = payload.lane.id;
      }

      this.$api.customerContract
        .addCustomerContract(payload)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Customer Tariff added Successfully!",
            color: "success",
          });
          this.$emit("refreshList");
          this.resetForm();
          this.showAddCustomerContractDialog = false;
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: `${
              err.data && err.data.non_field_errors
                ? err.data.non_field_errors.join()
                : "Tariff cannot be created"
            }`,
            color: "error",
          });
          this.formErrors = { ...this.formErrors, ...err.data };
        });
    },

    resetForm() {
      this.contractDetails = {
        customer_contract_rates: [{}],
      };

      this.formErrors = {
        customer_contract_rates: [{}],
      };
      if (this.$refs.contractForm) {
        this.$refs.contractForm.reset();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-inner-container {
  max-height: calc(100vh - 170px);
  overflow: auto;
}
</style>
