<template>
  <v-container class="Details-View">
    <v-row class="heading px-2 pt-2">
      <v-col cols="12" md="10">
        <div class="d-flex">
          <Tooltip
            tooltipTitle="Reference number"
            v-if="ticketDetails.reference_number"
            :tooltipText="ticketDetails.reference_number"
            top
            tooltipTextClass="text-h5 font-weight-medium mr-3"
          />
          <v-chip
            v-if="ticketDetails && ticketDetails.status"
            small
            style="background: rgba(240, 112, 37, 0.12)"
          >
            {{ ticketDetails.status }}
          </v-chip>
        </div>
      </v-col>
    </v-row>

    <v-row class="subHeading px-2 pt-2">
      <v-col cols="12" md="7">
        <div class="d-flex">
          <Tooltip
            tooltipTitle="Module"
            v-if="ticketDetails.module"
            :tooltipText="ticketDetails.module"
            top
            tooltipTextClass="text-subtitle font-weight-medium"
          />
        </div>
      </v-col>
    </v-row>

    <v-row v-if="Object.keys(ticketDetails).length">
      <v-col cols="12" class="pa-0">
        <v-tabs fixed-tabs v-model="detailsTab">
          <v-tabs-slider color="white"></v-tabs-slider>
          <v-tab>Ticket log</v-tab>
          <v-tab>Attachment</v-tab>
        </v-tabs>
      </v-col>

      <v-col cols="12">
        <v-tabs-items v-model="detailsTab">
          <v-tab-item class="Height-Class">
            <v-card elevation="0" class="logs-container overflow-y-auto">
              <v-card-text class="px-0">
                <v-timeline dense clipped>
                  <v-timeline-item
                    v-for="(log, i) in ticketDetails.ticket_logs"
                    :key="i"
                    icon-color="primary"
                    small
                  >
                    <v-card class="elevation-2">
                      <v-card-title class="py-0">
                        <v-row>
                          <v-col cols="6">
                            <span class="text-subtitle-2">
                              {{ log.added_by }}
                            </span>
                          </v-col>

                          <v-col cols="6" class="text-right">
                            <span class="text-caption">
                              {{ log.created | formatDate(true) }}
                            </span>
                          </v-col>
                        </v-row>
                      </v-card-title>
                      <v-card-text> {{ log.message }} </v-card-text>
                    </v-card>
                  </v-timeline-item>
                </v-timeline>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item class="Height-Class">
            <AttachmentList :documentList="ticketDetails.attachment" />
          </v-tab-item>

        </v-tabs-items>
      </v-col>
    </v-row>
</v-container>
</template>

<script>
import CardDetails from "@/components/General/CardDetails.vue";
import AttachmentList from "@/components/AgGridComponents/AdminTicketAttachmentList.vue";
import Tooltip from "@/components/General/Tooltip.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";

export default {
  components: {
    CardDetails,
    Tooltip,
    AttachmentList,
    AgGridVue,
    Pagination,
  },
  props: {
    ticketId: {
      type: Number,
    },
  },
  data() {
    return {
      detailsTab: 0,
      ticketDetails: {},
    };
  },
  computed: {},
  watch: {
    ticketId(val) {
      this.getTicketDetails(val);
    },
  },
  methods: {
    getTicketDetails(id) {
      this.$api.ticket
        .getTicketDetails(id)
        .then((res) => {
          this.ticketDetails = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getTicketDetails(this.ticketId);
  },
};
</script>

<style scoped>
.Height-Class {
  height: calc(100vh - 292px);
  overflow-y: auto;
  overflow-x: hidden;
}

.Maintenance-Height-Class {
  height: calc(100vh - 328px);
  overflow-y: auto;
}

.grid-height {
  height: calc(100vh - 381px);
  overflow-y: auto;
}
</style>
