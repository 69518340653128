var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
          _vm.params &&
          _vm.params.context &&
          _vm.params.context.parentComponent &&
          _vm.params.context.parentComponent.permissions &&
          _vm.params.context.parentComponent.permissions.add
        )?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","disabled":_vm.params.data.trip_id == null ||  _vm.params.data.status == 'Completed' ||
          _vm.params.data.status == 'Ready For Invoice' ||
          _vm.params.data.status == 'Invoice Generated' ||
          _vm.params.data.is_sub_hire == 'Yes'},on:{"click":_vm.uploadEwaybill}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-sign ")]):_vm._e()]}}])},[_c('span',[_vm._v("Upload E-WayBill")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
          _vm.params &&
          _vm.params.context &&
          _vm.params.context.parentComponent &&
          _vm.params.context.parentComponent.permissions &&
          _vm.params.context.parentComponent.permissions.add
        )?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","disabled":_vm.params.data.status == 'Completed' ||
          _vm.params.data.status == 'Ready For Invoice' ||
          _vm.params.data.status == 'Invoice Generated' ||
          _vm.params.data.is_sub_hire == 'Yes'},on:{"click":_vm.assetAllocation}},'v-icon',attrs,false),on),[_vm._v(" mdi-truck-flatbed ")]):_vm._e()]}}])},[_c('span',[_vm._v("Asset Allocation")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
          _vm.params &&
          _vm.params.context &&
          _vm.params.context.parentComponent &&
          _vm.params.context.parentComponent.permissions &&
          _vm.params.context.parentComponent.permissions.add &&
          _vm.params.data.leg_sub_hire &&
          _vm.params.data.status == 'Created'
        )?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":_vm.deAllocateTransporter}},'v-icon',attrs,false),on),[_vm._v(" mdi-replay ")]):_vm._e()]}}])},[_c('span',[_vm._v("Unallocate Transporter")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
          _vm.params &&
          _vm.params.context &&
          _vm.params.context.parentComponent &&
          _vm.params.context.parentComponent.permissions &&
          _vm.params.context.parentComponent.permissions.add &&
          _vm.params.data.is_sub_hire == 'No'
        )?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","disabled":_vm.params.data.status != 'Created' && _vm.params.data.status != 'Assigned'},on:{"click":_vm.combineLegs}},'v-icon',attrs,false),on),[_vm._v(" mdi-sitemap-outline ")]):_vm._e()]}}])},[_c('span',[_vm._v("Combine Legs")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }