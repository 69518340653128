<template>
  <div>
    <v-row class="ma-0">
      <v-col cols="12" md="6" class="d-flex align-center">
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-if="['string', 'object'].indexOf(typeof backRoute) > -1"
              @click="callBackRoute"
              v-bind="attrs"
              v-on="on"
              class="mr-3"
              name="back-button"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </template>
          <span>Back</span>
        </v-tooltip>

        <span class="text-h5">
          {{ title }}
        </span>
        <v-badge
          v-if="daID"
          top
          :content="daID"
          overlap
          class="mb-4 ml-4"
        >
        </v-badge>
      </v-col>


      <v-col cols="12" md="6" class="text-right">
        <slot name="formActions" />
      </v-col>

      <v-col cols="12" v-if="nonFieldErrors.length">
        <v-alert class="ma-0" close-icon="mdi-close" dense type="error">
          <v-list
            class="pa-0"
            dense
            style="background: inherit !important"
            v-for="(error, i) in nonFieldErrors"
            :key="i"
          >
            <v-list-item dense style="min-height: 20px !important">
              <span>{{ i + 1 }} .</span><span>{{ error }}</span>
            </v-list-item>
          </v-list>
        </v-alert>
      </v-col>

      <v-col cols="12" class="pt-0 pb-2">
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <slot name="formContent" />
  </div>
</template>

<script>
export default {
  props: {
    backRoute: {
      type: [String, Object],
    },
    title: {
      type: String,
    },
    stickyHeader: {
      type: Boolean,
      default: false,
    },
    nonFieldErrors: {
      type: Array,
      default: () => [],
    },
    daID: {
      default: null,
    },
  },

  methods: {
    callBackRoute() {
      this.$router.push(this.backRoute);
    },
  },
};
</script>
