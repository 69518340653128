import { handleResponse, handleError } from '@/utils/functions'

export default (axios) => ({
    login(data) {
        return new Promise((resolve, reject) => {
            axios
                .post('api/v2/login/', data)
                .then((res) => {
                    resolve(res)
                })
                .catch((err) => {
                    reject(handleError(err))
                })
        })
    },
    sendOtp(data) {
        return new Promise((resolve, reject) => {
            axios
                .post('api/v2/reset_password_request/', data)
                .then((res) => {
                    resolve(res)
                })
                .catch((err) => {
                    reject(handleError(err))
                })
        })
    },
    restPassword(data) {
        return new Promise((resolve, reject) => {
            axios
                .post('api/v2/reset_password/', data)
                .then((res) => {
                    resolve(res)
                })
                .catch((err) => {
                    reject(handleError(err))
                })
        })
    },
    logout() {
        return new Promise((resolve, reject) => {
            axios
                .post('api/v2/logout/')
                .then((res) => {
                    resolve(res)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    getProfileInfo() {
        return new Promise((resolve, reject) => {
            axios
                .get('api/v2/profile/')
                .then((res) => {
                    resolve(handleResponse(res))
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
})