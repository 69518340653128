import {
  deliveryAdviceCharges
} from "@/utils/constants";
import {
  handleError,
  handleResponse
} from "@/utils/functions";

export default (axios) => ({
  getDeliveryAdviceChargesList(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(deliveryAdviceCharges.deliveryAdviceChargesBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getDeliveryAdviceChargesDetails(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${deliveryAdviceCharges.deliveryAdviceChargesBase}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  addDeliveryAdviceCharge(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(deliveryAdviceCharges.deliveryAdviceChargesBase, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  updateDeliveryAdviceCharge(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${deliveryAdviceCharges.deliveryAdviceChargesBase}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  deleteDeliveryCharge(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${deliveryAdviceCharges.deliveryAdviceChargesBase}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});