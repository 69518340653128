import Dashboard from "@/pages/customer/Index.vue"

import UserManagementRoutes from "@/router/customer/userManagement";
import AddressBookRoutes from "@/router/customer/addressBook"
import ContractRoutes from "@/router/customer/contract"
import RoleManagementRoutes from "@/router/customer/roleManagement"
import RateRoutes from "@/router/customer/rate/index"
import InvoiceRoutes from "@/router/customer/invoice"
import OrderRoutes from "@/router/customer/order"

const customerRoutes = [
  {
    path: "/",
    redirect: "/app/customer/dashboard",
  },
  {
    path: "dashboard",
    name: "app-customer-dashboard",
    component: Dashboard,
  },
  ...AddressBookRoutes,
  ...UserManagementRoutes,
  ...ContractRoutes,
  ...RoleManagementRoutes,
  ...RateRoutes,
  ...InvoiceRoutes,
  ...OrderRoutes,
]

export default customerRoutes