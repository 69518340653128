import {
  reports,
  version
} from "@/utils/constants";
import {
  handleError,
  handleResponse
} from "@/utils/functions";

export default (axios) => ({
  getCustomerDAReport(params = {}, headers = {}, responseType = 'json') {
    return new Promise((resolve, reject) => {
      axios
        .get(`${reports.reportBase}customer_da/`, {
          params: params,
          responseType: responseType,
          headers: headers
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getCustomerInvoiceChargesReport(params = {}, headers = {}, responseType = 'json') {
    return new Promise((resolve, reject) => {
      axios
        .get(`${reports.reportBase}customer_invoice_charges/`, {
          params: params,
          responseType: responseType,
          headers: headers
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getDefectiveCheckListReport(params = {}, headers = {}, responseType = 'json') {
    return new Promise((resolve, reject) => {
      axios
        .get(`${reports.reportBase}defective_checklist/`, {
          params: params,
          responseType: responseType,
          headers: headers
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getVehicleTripReport(params = {}, headers = {}, responseType = 'json') {
    return new Promise((resolve, reject) => {
      axios
        .get(`${reports.reportBase}vehicle_trip/`, {
          params: params,
          responseType: responseType,
          headers: headers
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getCustomerInvoiceReport(params = {}, headers = {}, responseType = 'json') {
    return new Promise((resolve, reject) => {
      axios
        .get(`${reports.reportBase}customer_invoice/`, {
          params: params,
          responseType: responseType,
          headers: headers

        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getDAPendingToInvoiceReport(params = {}, headers = {}, responseType = 'json') {
    return new Promise((resolve, reject) => {
      axios
        .get(`${reports.reportBase}da_pending_to_invoice/`, {
          params: params,
          responseType: responseType,
          headers: headers

        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  
  getDASummaryReport(params = {}, headers = {}, responseType = 'json') {
    return new Promise((resolve, reject) => {
      axios
        .get(`${reports.reportBase}da_summary/`, {
          params: params,
          responseType: responseType,
          headers: headers

        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  
  
  getDaTimeStampReport(params = {}, headers = {}, responseType = 'json') {
    return new Promise((resolve, reject) => {
      axios
        .get(`${reports.reportBase}da_timestamp/`, {
          params: params,
          responseType: responseType,
          headers: headers

        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  
  getDaListReport(params = {}, headers = {}, responseType = 'json') {
    return new Promise((resolve, reject) => {
      axios
        .get(`${reports.reportBase}da_list/`, {
          params: params,
          responseType: responseType,
          headers: headers

        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },


});