<template>
  <div>
    <v-card style="background-color: #ffece0">
      <v-card-title>
        <v-row>
          <v-col cols="6" class="d-flex align-center py-2">
            <v-icon small class="pr-3" color="black">
              mdi-map-marker-path
            </v-icon>
            <h5 class="font-weight-light">{{ tripDetails.trip_id }}</h5>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
    <v-row no-gutters class="py-3 px-4">
      <v-col cols="12">
        <TextareaField
          hide-details="auto"
          type="text"
          label="Remarks *"
          :clearable="true"
          v-model="remark"
        />
      </v-col>
    </v-row>
    <div class="btn-position-fixed-bottom text-center py-1">
      <v-btn
        @click="submitDriverRemarksDialog"
        class="primary text-capitalize font-weight-bold"
        :disabled="!remark"
        style="width: 100px"
        >Submit</v-btn
      >
      <v-btn
        @click="closeDriverRemarksDialog"
        class="secondary text-capitalize font-weight-bold"
        style="width: 100px"
        >Cancel</v-btn
      >
    </div>
  </div>
</template>
  
<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import TextareaField from "@/components/FormBaseComponents/TextareaField.vue";

export default {
  components: {
    InputField,
    TextareaField,
  },
  props: {
    driverRemarksDialog: {
      type: Boolean,
    },
    tripDetails: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      remark: null,
    };
  },

  methods: {
    submitDriverRemarksDialog() {
      this.$emit("submitDriverRemarksDialog", this.remark);
    },
    closeDriverRemarksDialog() {
      this.$emit("closeDriverRemarksDialog");
    },
  },
};
</script>
    
    
    <style scoped lang="scss">
.btn-position-fixed-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.card-outlined {
  border: 1px solid #0000004f !important;
  border-radius: 6px;
}
</style>