import Blank from "@/layouts/blank.vue"

import InBoundIntegrationApi from "@/pages/admin/integration-logs/InBoundIntegrationApi.vue"
import OutBoundIntegrationApi from "@/pages/admin/integration-logs/OutBoundIntegrationApi.vue"


const ConfigurationsRoutes = [
  {
    path: "integration-logs",
    name: "app-admin-integration-logs",
    component: Blank,
    children: [
      {
        path: "/",
        redirect: "/app/admin/dashboard",
      },
      {
        path: "inbound-integration-api",
        name: "app-admin-integration-logs-inbound-integration-api",
        component: InBoundIntegrationApi,
      },
      {
        path: "outbound-integration-api",
        name: "app-admin-integration-logs-outbound-integration-api",
        component: OutBoundIntegrationApi,
      },
    ]
  },
]

export default ConfigurationsRoutes;