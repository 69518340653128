<template>
  <FilterParent module="User" v-model="showAdminUserFilters">
    <template #filterContent>
      <v-row no-gutters>
        <v-col cols="6" class="pa-2">
          <MultiSelect
            :items="allRoleList"
            :disabled="filterObject.primary_user"
            label="Select Role"
            itemText="role_name"
            itemValue="id"
            clearable
            v-model="filterObject.role_id"
          />
        </v-col>
        <v-col cols="6" class="pa-2">
          <MultiSelect
            :disabled="filterObject.role_id"
            :items="userTypeList"
            label="Is Primary User"
            clearable
            v-model="filterObject.primary_user"
          />
        </v-col>
      </v-row>
    </template>
    <template #filterAction>
      <v-btn
        depressed
        @click="$emit('resetFilters'), (showAdminUserFilters = false)"
        >Reset</v-btn
      >
      <v-btn
        color="primary"
        class="ml-4"
        depressed
        @click="$emit('applyFilters'), (showAdminUserFilters = false)"
        >Apply</v-btn
      >
    </template>
  </FilterParent>
</template>

<script>
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import FilterParent from "@/components/General/FilterParent.vue";

export default {
  components: {
    FilterParent,
    MultiSelect,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    bindingObject: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      allRoleList: [],
      userTypeList: [
        {
          text: "Yes",
          value: "true",
        },
        {
          text: "No",
          value: "false",
        },
      ],
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.getRoleList();
      }
    },
  },
  computed: {
    showAdminUserFilters: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    filterObject: {
      get() {
        return this.bindingObject;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (!obj) {
        return null;
      } else {
        return obj;
      }
    },
  },
  methods: {
    getRoleList() {
      this.$api.role
        .getRoleList({ limit: "all", company: this.currentProfile.company })
        .then((res) => {
          this.allRoleList = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style>
</style>