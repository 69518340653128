<template>
  <FormComponent
    title="Add Location"
    :backRoute="backRoute"
    :nonFieldErrors="formErrors.non_field_errors"
  >
    <template #formActions>
      <v-btn color="primary" depressed :disabled="!isValid" @click="submitForm">
        Submit
      </v-btn>

      <v-btn color="secondary" class="ml-3" depressed @click="resetForm">
        Reset
      </v-btn>
    </template>

    <template #formContent>
      <v-form v-model="isValid" ref="locationForm">
        <v-row class="ma-0">
          <v-col cols="12" lg="3" class="Form-Columns-Dense">
            <v-text-field
              ref="autocomplete1"
              id="autocomplete1"
              label="Location Name *"
              dense
              outlined
              required
              :rules="[(v) => !!v || 'Location Name is Required!']"
              hide-details="auto"
              v-model="locationDetails.name"
              :error-messages="formErrors.name"
              @input="delete formErrors.name"
            />
          </v-col>
          <v-col cols="12" lg="2" class="Form-Columns-Dense">
            <v-text-field
              label="State"
              dense
              outlined
              readonly
              hide-details="auto"
              v-model="locationDetails.location_state"
            />
          </v-col>
          <v-col cols="12" lg="5" class="Form-Columns-Dense">
            <v-text-field
              label="Address"
              dense
              outlined
              readonly
              hide-details="auto"
              v-model="locationDetails.formatted_address"
            />
          </v-col>
          <v-col cols="12" lg="2" class="Form-Columns-Dense">
            <v-text-field
              label="Country Code"
              dense
              outlined
              readonly
              hide-details="auto"
              v-model="locationDetails.country_code"
            />
          </v-col>
          <v-col cols="12" lg="3" class="Form-Columns-Dense">
            <v-text-field
              label="Country"
              dense
              outlined
              readonly
              hide-details="auto"
              v-model="locationDetails.location_country"
            />
          </v-col>
          <v-col cols="12" lg="3" class="Form-Columns-Dense">
            <v-switch
              :label="`Port : ${
                locationDetails.is_port == 'Yes' ? 'Yes' : 'No'
              }`"
              hide-details="auto"
              true-value="Yes"
              false-value="No"
              class="mt-1"
              v-model="locationDetails.is_port"
              :error-messages="formErrors.is_port"
              @change="delete formErrors.is_port"
            ></v-switch>
          </v-col>
          <v-col cols="12" lg="3" class="Form-Columns-Dense">
            <v-switch
              :label="`Free Zone : ${
                locationDetails.is_free_zone == 'Yes' ? 'Yes' : 'No'
              }`"
              hide-details="auto"
              true-value="Yes"
              false-value="No"
              class="mt-1"
              v-model="locationDetails.is_free_zone"
              :error-messages="formErrors.is_free_zone"
              @change="delete formErrors.is_free_zone"
            ></v-switch>
          </v-col>
          <v-col cols="12" lg="3" class="Form-Columns-Dense">
            <InputField
              type="text"
              label="Location Code *"
              hide-details="auto"
              required
              :rules="[(val) => !!val || 'Location Code is Required!']"
              v-model="locationDetails.location_code"
              :error-messages="formErrors.location_code"
              @input="delete formErrors.location_code"
            />
          </v-col>
          <v-col cols="12" class="Form-Columns-Dense">
            <GoogleMap
              mapWidth="100%"
              mapHeight="100%"
              ref="locationMap"
              class="Map-Height"
            >
            </GoogleMap>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </FormComponent>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import FormComponent from "@/components/General/FormComponent.vue";
import GoogleMap from "@/components/General/GoogleMap.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: { InputField, FormComponent, GoogleMap },

  data() {
    return {
      isValid: true,
      locationDetails: {
        is_port: "No",
        is_free_zone: "No",
      },
      formErrors: {},

      // autocomplete vars
      autocomplete1: null,
      markerInstance: null,

      locationTypeList: [],
      backRoute: "/app/admin/lane-management/location/list",
    };
  },

  methods: {
    submitForm() {
      this.$bus.$emit("showLoader", true);

      this.$api.location
        .addLocation(this.locationDetails)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Location added Successfully!",
            color: "success",
          });
          this.$router.push(this.backRoute);
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't add Location!",
            color: "error",
          });
          this.formErrors = err.data;
        });
    },

    getAutoComplete() {
      const interval = setInterval(() => {
        if (this.$refs.autocomplete1 && window.google && window.google.maps) {
          clearInterval(interval);

          let input1 = document.getElementById("autocomplete1");

          this.autocomplete1 = new window.google.maps.places.Autocomplete(
            input1
          );

          input1.setAttribute("placeholder", "");

          this.autocomplete1.addListener("place_changed", () => {
            try {
              let obj = {
                ...this.locationDetails,
              };
              let place = this.autocomplete1.getPlace();
              obj.coordinates = {
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng(),
              };

              obj.location_state =
                place.address_components[
                  place.address_components.length - 2
                ].long_name;
              obj.name = place.name;
              obj.formatted_address = place.formatted_address;

              for (var i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];

                if (addressType == "country") {
                  obj.country_code = place.address_components[i].short_name;

                  obj.location_country = place.address_components[i].long_name;
                }
              }
              this.showCoordinates();
              this.locationDetails = obj;
            } catch (error) {
              console.error(error);
            }
          });
        }
      }, 100);
    },

    showCoordinates() {
      let int = setInterval(() => {
        if (
          this.locationDetails.coordinates &&
          window.google &&
          window.google.maps
        ) {
          clearInterval(int);
          if (this.markerInstance) {
            this.markerInstance.setMap(null);
          }
          this.markerInstance = this.$refs.locationMap.addMarker(
            this.locationDetails.coordinates
          );
        }
      }, 300);
    },

    resetForm() {
      this.locationDetails = {
        is_port: "No",
        is_free_zone: "No",
      };
      this.$refs.locationForm.resetValidation();
    },
  },
  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("lane");
      if (!permissions || !permissions.add) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      }
    }
  },
  mounted() {
    this.getAutoComplete();
  },
};
</script>

<style scoped>
.Map-Height {
  height: calc(100vh - 253px);
}
</style>
