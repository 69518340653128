<template>
  <FormComponent
    :title="`Location - ${locationDetails.location_code}`"
    :backRoute="backRoute"
    :nonFieldErrors="formErrors.non_field_errors"
  >
    <template #formContent>
      <v-form v-model="isValid" ref="locationForm">
        <v-row class="ma-0">
          <v-col cols="12" lg="3" class="Form-Columns-Dense">
            <v-text-field
              ref="autocomplete1"
              readonly
              class="grey lighten-3"
              id="autocomplete1"
              label="Location Name *"
              dense
              outlined
              required
              :rules="[(v) => !!v || 'Location Name is Required!']"
              hide-details="auto"
              v-model="locationDetails.name"
              :error-messages="formErrors.name"
              @input="delete formErrors.name"
            />
          </v-col>
          <v-col cols="12" lg="2" class="Form-Columns-Dense">
            <v-text-field
              label="State"
              dense
              outlined
              class="grey lighten-3"
              readonly
              hide-details="auto"
              v-model="locationDetails.location_state"
            />
          </v-col>
          <v-col cols="12" lg="5" class="Form-Columns-Dense">
            <v-text-field
              label="Address"
              dense
              outlined
              readonly
              class="grey lighten-3"
              hide-details="auto"
              v-model="locationDetails.formatted_address"
            />
          </v-col>
          <v-col cols="12" lg="2" class="Form-Columns-Dense">
            <v-text-field
              label="Country Code"
              dense
              outlined
              readonly
              class="grey lighten-3"
              hide-details="auto"
              v-model="locationDetails.country_code"
            />
          </v-col>
          <v-col cols="12" lg="3" class="Form-Columns-Dense">
            <v-text-field
              label="Country"
              dense
              outlined
              readonly
              class="grey lighten-3"
              hide-details="auto"
              v-model="locationDetails.location_country"
            />
          </v-col>
          <v-col cols="12" lg="3" class="Form-Columns-Dense">
            <v-switch
              :label="`Port : ${
                locationDetails.is_port == 'Yes' ? 'Yes' : 'No'
              }`"
              readonly
              true-value="Yes"
              false-value="No"
              class="mt-1"
              hide-details="auto"
              v-model="locationDetails.is_port"
              :error-messages="formErrors.is_port"
              @change="delete formErrors.is_port"
            ></v-switch>
          </v-col>

          <v-col cols="12" lg="3" class="Form-Columns-Dense">
            <v-switch
              :label="`Free Zone : ${
                locationDetails.is_free_zone == 'Yes' ? 'Yes' : 'No'
              }`"
              readonly
              true-value="Yes"
              false-value="No"
              class="mt-1"
              hide-details="auto"
              v-model="locationDetails.is_free_zone"
              :error-messages="formErrors.is_free_zone"
              @change="delete formErrors.is_free_zone"
            ></v-switch>
          </v-col>
          <v-col cols="12" lg="3" class="Form-Columns-Dense">
            <InputField
              type="text"
              readonly
              :clearable="false"
              class="grey lighten-3"
              label="Location Code *"
              :rules="[(val) => !!val || 'Location Code is Required!']"
              required
              hide-details="auto"
              v-model="locationDetails.location_code"
              :error-messages="formErrors.location_code"
              @input="delete formErrors.location_code"
            />
          </v-col>
          <v-col cols="12" class="Form-Columns-Dense">
            <GoogleMap
              mapWidth="100%"
              mapHeight="100%"
              ref="locationMap"
              class="Map-Height"
            >
            </GoogleMap>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </FormComponent>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import FormComponent from "@/components/General/FormComponent.vue";
import GoogleMap from "@/components/General/GoogleMap.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: { InputField, FormComponent, GoogleMap },

  data() {
    return {
      isValid: true,
      locationDetails: {
        is_port: "No",
        is_free_zone: "No",
      },
      formErrors: {},

      // autocomplete vars
      autocomplete1: null,
      markerInstance: null,

      backRoute: "/app/admin/lane-management/location/list",
    };
  },

  methods: {
    getLocationDetails(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.location
        .getLocationObject(id)
        .then((res) => {
          this.locationDetails = res.data;
          this.showCoordinates();
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    showCoordinates() {
      let int = setInterval(() => {
        if (
          this.locationDetails.coordinates &&
          window.google &&
          window.google.maps
        ) {
          clearInterval(int);
          if (this.markerInstance) {
            this.markerInstance.setMap(null);
          }
          this.markerInstance = this.$refs.locationMap.addMarker(
            this.locationDetails.coordinates
          );
        }
      }, 300);
    },
  },
  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("lane");
      if (!permissions || !permissions.change) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.getLocationDetails(this.$route.params.id);
      }
    }
  },
};
</script>

<style scoped>
.Map-Height {
  height: calc(100vh - 253px);
}
</style>
