import Blank from "@/layouts/blank.vue"

import LocationList from "@/pages/admin/lane-management/location/LocationList.vue";
import LocationAdd from "@/pages/admin/lane-management/location/LocationAdd.vue";
import LocationEdit from "@/pages/admin/lane-management/location/LocationEdit.vue";

const locationRoutes = [
  {
    path: "location",
    name: "app-admin-lane-management-location",
    component: Blank,
    children: [
      {
        path: "/",
        redirect: "/app/admin/lane-management/location/list",
      },
      {
        path: "list",
        name: "app-admin-lane-management-location-list",
        component: LocationList,
      },
      {
        path: "add",
        name: "app-admin-lane-management-location-add",
        component: LocationAdd,
      },
      {
        path: "edit",
        name: "app-admin-lane-management-location-edit",
        component: Blank,
        children: [
          {
            path: "/",
            redirect: "/app/admin/lane-management/location/list",
          },
          {
            path: ":id",
            name: "app-admin-lane-management-location-edit-id",
            component: LocationEdit,
          }
        ]
      },
    ]
  },
]

export default locationRoutes