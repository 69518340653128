import Blank from "@/layouts/blank.vue"

import InvoiceList from "@/pages/customer/invoice/InvoiceList.vue";
import InvoiceDetails from "@/pages/customer/invoice/InvoiceDetails.vue";

const invoiceRoutes = [
  {
    path: "invoice",
    name: "app-customer-invoice",
    component: Blank,
    children: [
      {
        path: "/",
        redirect: "/app/customer/invoice/list",
      },
      {
        path: "list",
        name: "app-customer-invoice-list",
        component: InvoiceList,
      },
      {
        path: "details",
        name: "app-customer-invoice-details",
        component: Blank,
        children: [
          {
            path: "/",
            redirect: "/app/customer/invoice/list",
          },
          {
            path: ":id",
            name: "app-customer-invoice-details-id",
            component: InvoiceDetails,
          }
        ]
      },
    ]
  },
]

export default invoiceRoutes