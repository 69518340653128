import Blank from "@/layouts/blank.vue"

import TrailerList from "@/pages/admin/asset-management/trailer/TrailerList.vue";
import TrailerAdd from "@/pages/admin/asset-management/trailer/TrailerAdd.vue";
import TrailerEdit from "@/pages/admin/asset-management/trailer/TrailerEdit.vue";

const trailerRoutes = [
  {
    path: "trailer",
    name: "app-admin-asset-management-trailer",
    component: Blank,
    children: [
      {
        path: "/",
        redirect: "/app/admin/asset-management/trailer/list",
      },
      {
        path: "list",
        name: "app-admin-asset-management-asset-list",
        component: TrailerList,
      },
      {
        path: "add",
        name: "app-admin-asset-management-asset-add",
        component: TrailerAdd,
      },
      {
        path: "edit",
        name: "app-admin-asset-management-asset-edit",
        component: Blank,
        children: [
          {
            path: "/",
            redirect: "/app/admin/asset-management/trailer/list",
          },
          {
            path: ":id",
            name: "app-admin-asset-management-asset-edit-id",
            component: TrailerEdit,
          }
        ]
      },
    ]
  },
]

export default trailerRoutes