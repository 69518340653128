<template>
  <v-card class="fill-height">
    <v-card-title class="heading mb-4">
      <Tooltip
        tooltipTitle="Role"
        v-if="roleDetails.role_name"
        :tooltipText="roleDetails.role_name"
        top
        tooltipClass="text-h5 font-weight-medium"
      />
    </v-card-title>

    <v-card-text>
      <v-tabs fixed-tabs v-model="detailsTab" class="mb-2">
        <v-tabs-slider color="white"></v-tabs-slider>
        <v-tab>Details</v-tab>
        <v-tab>Logs</v-tab>
      </v-tabs>

      <v-tabs-items v-model="detailsTab">
        <v-tab-item class="Height-Class">
          <v-expansion-panels
            multiple
            focusable
            v-if="
              roleDetails.permissions &&
              Object.keys(roleDetails.permissions).length > 0
            "
          >
            <CardDetails
              v-for="(value, key, i) in roleDetails.permissions"
              :key="i"
              :title="key"
              :displayObject="value"
            />
          </v-expansion-panels>
        </v-tab-item>

        <v-tab-item class="Height-Class">
          <v-sheet>
            <v-timeline v-if="logs && logs.length" class="px-0" align-top dense>
              <v-timeline-item
                v-for="(log, index) in logs"
                :key="index"
                color="primary"
                icon="mdi-circle"
                small
              >
                <div>
                  <strong>{{ log.created | formatDate(true) }}</strong>
                  <div class="text-body-2">{{ log.message }}</div>
                  <div class="text-caption">- {{ log.updated_by }}</div>
                </div>
              </v-timeline-item>
            </v-timeline>

            <div v-else class="text-center">
              <v-divider class="mb-4"></v-divider>
              <span>No Logs to show</span>
            </div>
          </v-sheet>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import CardDetails from "@/components/General/CardDetails.vue";
import DocumentList from "@/components/General/DocumentList.vue";
import Tooltip from "@/components/General/Tooltip.vue";
export default {
  components: { CardDetails, Tooltip, DocumentList },
  props: {
    roleId: {
      type: Number,
    },
  },
  watch: {
    roleId(val) {
      this.logs = [];
      this.getRoleDetails(val);
      this.roleActivityLogs();
    },
  },
  data() {
    return {
      roleDetails: {},
      detailsTab: 0,
      logs: [],
    };
  },
  methods: {
    getRoleDetails(id) {
      this.$api.role
        .getRoleObject(id)
        .then((res) => {
          this.roleDetails = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    roleActivityLogs(params = {}) {
      params = {
        ...params,
        role: this.roleDetails.id,
        limit: "50",
        offset: this.logs.length,
      };
      this.$api.activityLogs
        .roleActivityLogs(params)
        .then((res) => {
          this.logs.push(...res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  mounted() {
    this.logs = [];
    this.getRoleDetails(this.roleId);
    this.roleActivityLogs();
  },
};
</script>

<style scoped>
.Height-Class {
  height: calc(100vh - 252px);
  overflow-y: auto;
}
</style>
