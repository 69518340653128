import VehicleTypeList from "@/pages/admin/lane-management/VehicleTypeList.vue";

const vehicleTypeRoutes = [
  {
    path: "vehicle-type",
    name: "app-admin-lane-management-vehicle-type",
    component: VehicleTypeList,
  },
]

export default vehicleTypeRoutes