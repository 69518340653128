<template>
  <div>
    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-icon
          class="ml-1 mr-2"
          color="primary"
          v-on="on"
          v-bind="attrs"
          @click="viewItem"
        >
          mdi-eye
        </v-icon>
      </template>
      <span>View</span>
    </v-tooltip>

    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-icon
          v-if="
            params &&
            params.context &&
            params.context.parentComponent &&
            params.context.parentComponent.permissions &&
            params.context.parentComponent.permissions.change &&
            params.data &&
            ['Created', 'Scheduled', 'Active'].indexOf(params.data.status) > -1
          "
          class="mr-1 ml-1"
          color="primary"
          v-on="on"
          v-bind="attrs"
          @click="editItem"
        >
          mdi-pencil
        </v-icon>
      </template>
      <span>Edit</span>
    </v-tooltip>

    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-icon
          class="ml-2 mr-1"
          color="primary"
          v-on="on"
          v-bind="attrs"
          @click="cancelDeliveryAdvice"
          v-if="
            params &&
            params.context &&
            params.context.parentComponent &&
            params.context.parentComponent.permissions &&
            params.context.parentComponent.permissions.change &&
            params.data &&
            ['Created', 'Scheduled', 'Active'].indexOf(params.data.status) > -1
          "
        >
          mdi-close-circle-outline
        </v-icon>
        <v-icon
          class="ml-2 mr-1"
          color="primary"
          v-on="on"
          v-bind="attrs"
          @click="cancelDeliveryAdvice"
          v-if="
            params &&
            params.context &&
            params.context.parentComponent &&
            params.context.parentComponent.permissions &&
            params.context.parentComponent.permissions.change &&
            params.data &&
            ['Completed'].indexOf(params.data.status) > -1 &&
            params.context.parentComponent.currentProfile.is_primary_user
          "
        >
          mdi-close-circle-outline
        </v-icon>
      </template>
      <span>Cancel {{ params.data.status }}</span>
    </v-tooltip>
    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-icon
          v-if="
            params &&
            params.context &&
            params.context.parentComponent &&
            params.context.parentComponent.permissions &&
            params.context.parentComponent.permissions.change &&
            params.data &&
            params.data.status == 'Invoice Generated' &&
            !params.data.is_send_to_sap
          "
          class="ml-2 mr-2"
          color="primary"
          v-on="on"
          v-bind="attrs"
          @click="sendToSapDACostCharges"
        >
          mdi-send
        </v-icon>
      </template>
      <span>Send To SAP</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  methods: {
    sendToSapDACostCharges() {
      this.params.context.parentComponent.daCostChargesSendToSap(
        this.params.data.id
      );
    },
    viewItem() {
      this.params.context.parentComponent.viewDeliveryAdvicesDetails(
        this.params.data.id
      );
    },
    editItem() {
      this.params.context.parentComponent.editItem(this.params.data);
    },
    cancelDeliveryAdvice() {
      this.params.context.parentComponent.openCancelDaRemarkForm(
        this.params.data.id
      );
    },
  },
};
</script>
