<template>
  <ListComponent title="Outbound Integration Logs">
    <template #list>
      <v-row class="ma-0">
        <v-col cols="4" class="px-0 pt-0 pb-2 d-flex align-center">
          <v-text-field
            label="Search"
            outlined
            dense
            append-icon="mdi-magnify"
            clearable
            hide-details="auto"
            v-model="filters.search"
            @input="searchLogs"
            class="mr-2"
          ></v-text-field>
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                depressed
                icon
                v-on="on"
                v-bind="attrs"
                @click="showFiltersDialog = true"
                class="mx-2"
              >
                <v-badge
                  dot
                  color="primary"
                  :value="Object.keys(filters).length"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-badge>
              </v-btn>
            </template>

            <span v-if="Object.keys(filters).length == 0">Filters</span>

            <v-row v-else no-gutters class="ma-0">
              <v-col cols="12" class="font-weight-bold">
                Applied Filters
              </v-col>

              <v-col cols="12">
                <span
                  v-for="(value, key) in filters"
                  :key="key"
                  class="text-capitalize"
                  >{{ key.replace("_", " ") }}
                  <br />
                </span>
              </v-col>
            </v-row>
          </v-tooltip>
          <Columns :headers="headers" :gridColInstance="gridColumnApi" />
        </v-col>

        <v-col cols="4" class="px-0 pt-0 pb-2">
          <v-tabs height="35" v-model="tab" centered>
            <v-tabs-slider></v-tabs-slider>
            <v-tab> Customer Invoice </v-tab>
            <v-tab> Payment Receipt </v-tab>
            <v-tab> Sub Hire </v-tab>
            <v-tab> Credit Note </v-tab>
          </v-tabs>
        </v-col>

        <v-col
          v-if="totalItems > 0"
          cols="4"
          class="px-0 pt-0 pb-2 d-flex justify-end"
        >
          <Pagination
            :pageNo="pageNo"
            :totalItems="totalItems"
            :pageSize="itemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>
      </v-row>

      <v-col cols="12" class="pa-0">
        <AgGridVue
          style="width: 100%; height: calc(100vh - 172px)"
          class="ag-theme-alpine"
          id="myGrid"
          :columnDefs="headersForTable"
          :context="context"
          @grid-ready="onGridReady"
          @columnMoved="resizeGrid"
          @columnVisible="resizeGrid"
          :defaultColDef="defaultColDef"
          :grid-options="gridOptions"
          :suppressDragLeaveHidesColumns="true"
          :rowData="apiLogsList"
        ></AgGridVue>
      </v-col>
    </template>
    <template #dialogs>
      <OutboundIntegrationLogFilters
        :bindingObject="filters"
        v-model="showFiltersDialog"
        @resetFilters="resetFilters"
        @applyFilters="itemsPerPageChanged(15)"
      />
    </template>
  </ListComponent>
</template>

<script>
import Columns from "@/components/General/Columns.vue";
import ListComponent from "@/components/General/ListComponent.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";
import CustomHeader from "@/components/AgGridComponents/General/CustomHeader.vue";
import DetailsParent from "@/components/General/DetailsParent.vue";
import IntegrationLogButton from "@/components/AgGridComponents/IntegrationLogButton.vue";
import OutboundIntegrationLogFilters from "@/components/ModuleBased/dialogs/filters/OutboundIntegrationLogFilters.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    ListComponent,
    Columns,
    AgGridVue,
    Pagination,
    agColumnHeader: CustomHeader,
    DetailsParent,
    IntegrationLogButton,
    OutboundIntegrationLogFilters,
  },
  watch: {
    tab() {
      this.pageNo = 1;
      this.apiCall();
    },
  },
  data() {
    return {
      sorting: {},
      showFiltersDialog: false,
      headers: [
        {
          headerName: "Created On",
          field: "created",
          tooltipField: "created",
          sortable: true,
          minWidth: 120,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value, true);
          },
        },
        {
          headerName: "End Point",
          field: "endpoint",
          tooltipField: "endpoint",
          sortable: true,
          minWidth: 120,
        },
        {
          headerName: "Response Code",
          field: "response_code",
          tooltipField: "response_code",
          sortable: true,
          minWidth: 120,
        },
        {
          headerName: "Method",
          field: "method",
          tooltipField: "method",
          sortable: true,
          minWidth: 120,
        },
        {
          headerName: "Detail",
          field: "detail",
          tooltipField: "detail",
          sortable: true,
          minWidth: 120,
        },
        {
          headerName: "Status",
          field: "status",
          tooltipField: "last_name",
          minWidth: 120,
          cellRenderer: function (params) {
            const result = /^2/.test(params.data.response_code.toString())
              ? `<span class="success-border px-2 py-1 success--text"> Success </span>`
              : `<span class="error-border px-2 py-1 error--text"> Failed </span>`;
            return result;
          },
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: "IntegrationLogButton",
          pinned: "right",
          width: 120,
        },
      ],
      apiLogsList: [],
      // pagination vars
      itemsPerPage: 20,
      pageNo: 1,
      totalItems: 0,

      // Filter vars
      filters: {},

      tab: null,
      permissions: null,

      // ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },
    };
  },
  computed: {
    headersForTable() {
      let headerForTabGeneral = [...this.headers];

      if (this.tab == 0) {
        return [
          ...headerForTabGeneral,
          {
            headerName: "Invoice No",
            field: "invoice_no",
            tooltipField: "invoice_no",
            sortable: true,
            minWidth: 120,
          },
        ];
      } else if (this.tab == 1) {
        return [
          ...headerForTabGeneral,

          {
            headerName: "Payment Receipt No",
            field: "receipt_id",
            tooltipField: "receipt_id",
            sortable: true,
            minWidth: 120,
          },
        ];
      } else if (this.tab == 2) {
        return [
          ...headerForTabGeneral,

          {
            headerName: "Sub Hire ID",
            field: "sub_hire_id",
            tooltipField: "invoice_no",
            sortable: true,
            minWidth: 120,
          },
        ];
      } else {
        return [
        ...headerForTabGeneral,
          {
            headerName: "Credit Note No",
            field: "credit_note_no",
            tooltipField: "credit_note_no",
            sortable: true,
            minWidth: 120,
          },
        ];
      }
    },
    context() {
      return { parentComponent: this };
    },
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (!obj) {
        return null;
      } else {
        return obj;
      }
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },
  methods: {
    resendSAPData(sapData) {
      const config = {
        status: "secondary",
        title: "Sent To SAP",
        text: "Are you sure you want to sent to SAP?",
      };

      this.$confirm(config).then(() => {
        this.$bus.$emit("showLoader", true);
        let payload = {};
        if (sapData.hasOwnProperty("invoice_no")) {
          payload.invoice_no = sapData.invoice_no;
        } else if (sapData.hasOwnProperty("receipt_id")) {
          payload.receipt_id = sapData.receipt_id;
        } else if (sapData.hasOwnProperty("sub_hire_id")) {
          payload.sub_hire_id = sapData.sub_hire_id;
        } else {
          payload.credit_note_no = sapData.credit_note_no;
        }

        this.$api.integration
          .sentDataToSAP(payload)
          .then((resp) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Send To Sap!",
              color: "success",
            });
            this.apiCall();
          })
          .catch((err) => {
            this.$bus.$emit("showToastMessage", {
              message: `${
                err.data && err.data.non_field_errors
                  ? err.data.non_field_errors.join()
                  : "Cannot Sent To SAP"
              }`,
              color: "error",
            });
            this.$bus.$emit("showLoader", false);
          });
      });
    },
    apiCall() {
      if (this.tab === 0) {
        this.getCustomerInvoiceIntegrationLogs();
      } else if (this.tab === 1) {
        this.getPaymentReceiptIntegrationLogs();
      } else if(this.tab === 2) {
        this.getSubHireIntegrationLogs();
      } else{
        this.getCreditNoteIntegrationLogs()
      }
    },
    resizeGrid() {
      if (this.gridOptions && this.gridOptions.api) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },
    resetFilters() {
      this.filters = {};
      this.itemsPerPageChanged(15);
    },
    searchLogs() {
      this.pageNo = 1;
      this.apiCall();
    },
    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.apiCall();
    },
    prevPage() {
      this.pageNo--;
      this.apiCall();
    },
    nextPage() {
      this.pageNo++;
      this.apiCall();
    },
    applyGridSort(key, type) {
      if (this.filters && !this.filters.ordering) {
        this.filters.ordering = [];
      }

      if (type == null) {
        delete this.sorting[key];
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(`-${key}`),
          1
        );
      } else if (type == "asc") {
        this.filters.ordering.push(key);
      } else if (type == "desc") {
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(key),
          1,
          `-${key}`
        );
      }
      if (this.filters.ordering.length == 0) {
        delete this.filters.ordering;
      }
      this.itemsPerPageChanged(20);
    },

    getCustomerInvoiceIntegrationLogs(params = {}) {
      this.$bus.$emit("showLoader", true);
      if (this.filters.status === "Success") {
        this.filters.status_code = "200, 201";
      } else if (this.filters.status === "Failed") {
        this.filters.status_code = "400, 500, 503";
      }
      params = {
        offset: this.offset,
        limit: this.itemsPerPage,
        ...params,
        ...this.filters,
      };

      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }
      this.$api.integration
        .getCustomerInvoiceIntegrationLogs(params)
        .then((res) => {
          this.apiLogsList = res.data;
          this.totalItems = res.count;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getCreditNoteIntegrationLogs(params = {}) {
      this.$bus.$emit("showLoader", true);
      if (this.filters.status === "Success") {
        this.filters.status_code = "200, 201";
      } else if (this.filters.status === "Failed") {
        this.filters.status_code = "400, 500, 503";
      }
      params = {
        offset: this.offset,
        limit: this.itemsPerPage,
        ...params,
        ...this.filters,
      };

      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }
      this.$api.integration
        .getCreditNoteIntegrationLogs(params)
        .then((res) => {
          this.apiLogsList = res.data;
          this.totalItems = res.count;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },


    getPaymentReceiptIntegrationLogs(params = {}) {
      this.$bus.$emit("showLoader", true);
      if (this.filters.status === "Success") {
        this.filters.status_code = "200, 201";
      } else if (this.filters.status === "Failed") {
        this.filters.status_code = "400, 500, 503";
      }
      params = {
        offset: this.offset,
        limit: this.itemsPerPage,
        ...params,
        ...this.filters,
      };

      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }
      this.$api.integration
        .getPaymentReceiptIntegrationLogs(params)
        .then((res) => {
          this.apiLogsList = res.data;
          this.totalItems = res.count;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getSubHireIntegrationLogs(params = {}) {
      this.$bus.$emit("showLoader", true);
      if (this.filters.status === "Success") {
        this.filters.status_code = "200, 201";
      } else if (this.filters.status === "Failed") {
        this.filters.status_code = "400, 500, 503";
      }
      params = {
        offset: this.offset,
        limit: this.itemsPerPage,
        ...params,
        ...this.filters,
      };

      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }
      this.$api.integration
        .getSubHireIntegrationLogs(params)
        .then((res) => {
          this.apiLogsList = res.data;
          this.totalItems = res.count;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("user");
      if (!permissions || !permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.permissions = { ...permissions };
        this.apiCall();
      }
    }
  },
};
</script>

<style>
.success-border {
  border: 1px solid #4caf50;
  border-radius: 30px;
}

.error-border {
  border: 1px solid #ff5252;
  border-radius: 30px;
}
</style>
red
