import { activityLogs } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  laneActivityLogs(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${activityLogs.logsUrl}lane_activity_log/`, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  companyActivityLogs(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${activityLogs.logsUrl}company_activity_log/`, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  customerContractActivityLogs(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${activityLogs.logsUrl}customer_contract_activity_log/`, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  transporterInvoiceActivityLogs(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${activityLogs.logsUrl}transporter_invoice_activity_log/`, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  customerInvoiceActivityLogs(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${activityLogs.logsUrl}customer_invoice_activity_log/`, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  driverActivityLogs(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${activityLogs.logsUrl}driver_activity_log/`, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  trailerActivityLogs(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${activityLogs.logsUrl}trailer_activity_log/`, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  gtUserActivityLogs(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${activityLogs.logsUrl}gt_user_activity_log/`, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  vehicleActivityLogs(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${activityLogs.logsUrl}vehicle_activity_log/`, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  locationActivityLogs(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${activityLogs.logsUrl}location_activity_log/`, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  roleActivityLogs(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${activityLogs.logsUrl}role_activity_log/`, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  quotationActivityLogs(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${activityLogs.logsUrl}quotation_activity_log/`, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
