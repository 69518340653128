import { branch } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
    getBranchList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(branch.branchBase, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getBranchObject(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${branch.branchBase}${id}/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    addBranch(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(branch.branchBase, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getBranchSelectList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${branch.branchBase}select/`, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    updateBranch(payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(`${branch.branchBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    partiallyUpdateBranch(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${branch.branchBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
});