import TrailerTypeList from "@/pages/admin/lane-management/TrailerTypeList.vue";

const trailerTypeRoutes = [
  {
    path: "trailer-type",
    name: "app-admin-lane-management-trailer-type",
    component: TrailerTypeList,
  },
]

export default trailerTypeRoutes