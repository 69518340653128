<template>
  <div>
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-icon
          v-if="
            params &&
            params.context &&
            params.context.parentComponent &&
            params.context.parentComponent.permissions &&
            params.context.parentComponent.permissions.change
          "
          color="primary"
          class="mr-2 ml-1"
          v-on="on"
          v-bind="attrs"
          @click="editItem"
        >
          mdi-pencil
        </v-icon>
      </template>
      <span>Edit</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-icon color="primary" v-on="on" v-bind="attrs" @click="viewItem" class="ml-2 mr-1">
          mdi-eye
        </v-icon>
      </template>
      <span>View</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  methods: {
    editItem() {
      this.params.context.parentComponent.editTrailer(this.params.data.id);
    },
    viewItem() {
      this.params.context.parentComponent.viewTrailer(this.params.data.id);
    },
  },
};
</script>
