import { imoClass } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
    getIMOClassList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(imoClass.imoClassBase, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getIMOClassObject(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${imoClass.imoClassBase}${id}/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    addIMOClass(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(imoClass.imoClassBase, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    activateDeactivateimoClass(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(
                    `${imoClass.imoClassBase}${payload.id}/deactivate_imoClass/`,
                    payload
                )
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    updateIMOClass(payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(`${imoClass.imoClassBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    partiallyUpdateIMOClass(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${imoClass.imoClassBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
});