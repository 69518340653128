import { driver } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getDriverAppProfile(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${driver.driverAppBase}profile/`, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getDriverAppTripDetails(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${driver.driverAppBase}trip/`, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getDriverAppAssetDetails(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${driver.driverAppBase}trip_asset_details/`, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getDriverAppLegPODs(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${driver.driverAppBase}leg_pod/`, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getDriverAppTripChecklist(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${driver.driverAppBase}trip_check_list/`, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getDriverAppTripMilestone(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${driver.driverAppBase}trip_milestone/`, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  submitTripCheckList(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${driver.driverAppBase}trip_check_list/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  uploadPOD(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${driver.driverAppBase}leg_pod/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updateDriverAppTrip(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${driver.driverAppBase}trip/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addDriverAppTripMilestone(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${driver.driverAppBase}trip_milestone/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

});
