import { render, staticRenderFns } from "./TransporterDriverDetails.vue?vue&type=template&id=2d536893&scoped=true"
import script from "./TransporterDriverDetails.vue?vue&type=script&lang=js"
export * from "./TransporterDriverDetails.vue?vue&type=script&lang=js"
import style0 from "./TransporterDriverDetails.vue?vue&type=style&index=0&id=2d536893&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d536893",
  null
  
)

export default component.exports