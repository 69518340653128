var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"fullscreen":"","max-width":"50%"},model:{value:(_vm.replicaDialog),callback:function ($$v) {_vm.replicaDialog=$$v},expression:"replicaDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Replicate "+_vm._s(_vm.daDetails.da_no)+" "),_c('v-spacer'),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"12","lg":"3"}},[_c('v-file-input',{ref:"replicateDAFileInput",attrs:{"outlined":"","dense":"","hide-details":"auto","label":"Select File"},on:{"change":function($event){return _vm.fileSelected($event)}},model:{value:(_vm.replicateDAFile),callback:function ($$v) {_vm.replicateDAFile=$$v},expression:"replicateDAFile"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"12","lg":"2"}},[_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":_vm.downloadDAReplicateSample}},[_vm._v(" Download Sample ")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.replicaDialog = false;
          _vm.resetForm();}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-alert',{staticClass:"mt-2",attrs:{"dense":"","border":"left","type":"warning"}},[_vm._v(" Container weight must have at most 2 decimals ")]),(_vm.replicaDialog)?_c('v-form',{model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.containerSetList,"hide-default-footer":true,"disable-pagination":true},scopedSlots:_vm._u([{key:"item",fn:function({ item, index }){return [_c('tr',[_c('td',[_vm._v(_vm._s(index + 1))]),_vm._l((Object.keys(item)),function(containerId,containerIndex){return _c('td',{key:`${containerId}${containerIndex}`},[_c('v-row',[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"12","lg":"6"}},[_c('InputField',{attrs:{"rules":[(val) => !!val || 'Container No is Required!'],"placeholder":"ABCD1234567","required":"","persistent-hint":"","hint":_vm.formFieldHints &&
                        _vm.formFieldHints[`${containerId}${index + 1}`],"hide-details":"auto","label":"Container No"},model:{value:(item[containerId].container_no),callback:function ($$v) {_vm.$set(item[containerId], "container_no", $$v)},expression:"item[containerId].container_no"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"12","lg":"6"}},[_c('InputField',{attrs:{"type":"number","label":"Container Weight (Tons) *","aria-placeholder":"","step":".01","rules":[
                        (v) => !!v || 'Container Weight is Required',
                        (v) =>
                          v > 0 || 'Container Weight can not be less than 0',
                        (v) =>
                          _vm.$globals.containerWeightFormatRegExp.test(v) ||
                          'Container Weight must have the format 00.00',
                      ],"hide-details":"auto"},model:{value:(item[containerId].weight),callback:function ($$v) {_vm.$set(item[containerId], "weight", $$v)},expression:"item[containerId].weight"}})],1)],1)],1)}),_c('td',[_c('v-btn',{staticClass:"mr-1",attrs:{"color":"primary","small":"","depressed":""},on:{"click":_vm.addContainerSet}},[_c('v-icon',[_vm._v("mdi-plus")])],1),(_vm.containerSetList.length > 1)?_c('v-btn',{attrs:{"color":"secondary","small":"","depressed":""},on:{"click":function($event){return _vm.removeContainerSet(index)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-minus")])],1):_vm._e()],1)],2)]}}],null,false,1131327003)})],1):_vm._e()],1),_c('v-divider'),_c('v-card-actions',{staticClass:"d-flex justify-center"},[_c('v-btn',{attrs:{"depressed":"","color":"primary","large":"","disabled":!_vm.isValid},on:{"click":_vm.addBulkDeliveryAdvice}},[_c('span',[_vm._v("Submit")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }