var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"fullscreen":""},model:{value:(_vm.showAddCustomerContractDialog),callback:function ($$v) {_vm.showAddCustomerContractDialog=$$v},expression:"showAddCustomerContractDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"secondary white--text"},[_c('span',{staticClass:"text-capitalize"},[_vm._v(" Add Tariff ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.resetForm();
          _vm.showAddCustomerContractDialog = false;}}},[_c('v-icon',{staticClass:"white--text"},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pt-4"},[(_vm.showAddCustomerContractDialog)?_c('v-form',{ref:"contractForm",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('FormComponent',{attrs:{"nonFieldErrors":_vm.formErrors.non_field_errors,"title":"Tariff Details","stickyHeader":true},scopedSlots:_vm._u([{key:"formActions",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","depressed":"","disabled":!_vm.isValid},on:{"click":_vm.submitForm}},[_vm._v(" Add ")]),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"secondary","depressed":""},on:{"click":function($event){_vm.resetForm();
                _vm.showAddCustomerContractDialog = false;}}},[_vm._v(" Cancel ")])]},proxy:true},{key:"formContent",fn:function(){return [_c('div',{staticClass:"card-inner-container"},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('MultiSelect',{attrs:{"label":"Select Customer *","items":_vm.allCustomerList,"itemText":"company_name","itemValue":"id","rules":[(val) => !!val || 'Customer is Required!'],"hide-details":"auto","readonly":"","error-messages":_vm.formErrors.customer},model:{value:(_vm.contractDetails.customer),callback:function ($$v) {_vm.$set(_vm.contractDetails, "customer", $$v)},expression:"contractDetails.customer"}})],1),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('MultiSelect',{attrs:{"label":"Job Type *","items":_vm.allJobType,"readonly":"","itemText":"display_name","itemValue":"value","hide-details":"auto","rules":[(v) => !!v || 'Job Type is Required'],"error-messages":_vm.formErrors.job_type},on:{"change":function($event){delete _vm.formErrors.job_type}},model:{value:(_vm.contractDetails.job_type),callback:function ($$v) {_vm.$set(_vm.contractDetails, "job_type", $$v)},expression:"contractDetails.job_type"}})],1),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('DateField',{attrs:{"inputProps":{
                      dense: true,
                      label: 'Start Date *',
                      rules: [(val) => !!val || 'Start Date is required!'],
                      'hide-details': 'auto',
                      'error-messages': _vm.formErrors.start_date,
                    },"required":"","min":_vm.today},on:{"input":function($event){delete _vm.formErrors.start_date}},model:{value:(_vm.contractDetails.start_date),callback:function ($$v) {_vm.$set(_vm.contractDetails, "start_date", $$v)},expression:"contractDetails.start_date"}})],1),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('DateField',{attrs:{"inputProps":{
                      dense: true,
                      label: 'End Date *',
                      rules: [(val) => !!val || 'End Date is required!'],
                      'hide-details': 'auto',
                      'error-messages': _vm.formErrors.end_date,
                    },"required":"","min":_vm.contractDetails.start_date},on:{"input":function($event){delete _vm.formErrors.end_date}},model:{value:(_vm.contractDetails.end_date),callback:function ($$v) {_vm.$set(_vm.contractDetails, "end_date", $$v)},expression:"contractDetails.end_date"}})],1),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12"}},[_c('Autocomplete',{attrs:{"loading":_vm.listLoading,"totalItems":_vm.laneCount,"items":_vm.allLaneList,"label":"Route *","itemText":"title","itemValue":"id","hide-details":"auto","rules":[(v) => !!v || 'Route is Required'],"error-messages":_vm.formErrors.lane},on:{"updateList":_vm.getLaneList,"input":function($event){delete _vm.formErrors.lane;
                      delete _vm.formErrors.job_type;}},model:{value:(_vm.contractDetails.lane),callback:function ($$v) {_vm.$set(_vm.contractDetails, "lane", $$v)},expression:"contractDetails.lane"}})],1)],1),_vm._l((_vm.contractDetails.customer_contract_rates),function(con,i){return _c('v-row',{key:i,staticClass:"ma-0"},[_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('MultiSelect',{attrs:{"label":"Container Type *","items":_vm.allContainersType,"itemText":"title","itemValue":"id","hide-details":"auto","rules":[(v) => !!v || 'Container Type is Required'],"error-messages":_vm.formErrors &&
                      _vm.formErrors.customer_contract_rates &&
                      _vm.formErrors.customer_contract_rates[i] &&
                      _vm.formErrors.customer_contract_rates[i].container_type
                        ? _vm.formErrors.customer_contract_rates[i].container_type
                        : []},on:{"change":function($event){_vm.formErrors &&
                      _vm.formErrors.customer_contract_rates &&
                      _vm.formErrors.customer_contract_rates[i] &&
                      _vm.formErrors.customer_contract_rates[i].container_type
                        ? delete _vm.formErrors.customer_contract_rates[i]
                            .container_type
                        : null}},model:{value:(con.container_type),callback:function ($$v) {_vm.$set(con, "container_type", $$v)},expression:"con.container_type"}})],1),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('InputField',{attrs:{"type":"number","min":"1","max":"4","label":"Container Count/Truck *","hide-details":"auto","rules":[
                      (v) => !!v || 'Container Count/Truck is Required',
                      (v) =>
                        v > 0 ||
                        'Container Count/Truck can not be less than 1',
                      (v) =>
                        v < 5 ||
                        'Container Count/Truck can not be more than 4',
                    ],"error-messages":_vm.formErrors &&
                      _vm.formErrors.customer_contract_rates &&
                      _vm.formErrors.customer_contract_rates[i] &&
                      _vm.formErrors.customer_contract_rates[i].container_count
                        ? _vm.formErrors.customer_contract_rates[i]
                            .container_count
                        : []},on:{"input":function($event){_vm.formErrors &&
                      _vm.formErrors.customer_contract_rates &&
                      _vm.formErrors.customer_contract_rates[i] &&
                      _vm.formErrors.customer_contract_rates[i].container_count
                        ? delete _vm.formErrors.customer_contract_rates[i]
                            .container_count
                        : null}},model:{value:(con.container_count),callback:function ($$v) {_vm.$set(con, "container_count", $$v)},expression:"con.container_count"}})],1),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('InputField',{attrs:{"type":"number","min":"0","hide-details":"auto","label":`Rate (${_vm.$globals.currency}) *`,"rules":[(v) => !!v || 'Rate is Required'],"error-messages":_vm.formErrors &&
                      _vm.formErrors.customer_contract_rates &&
                      _vm.formErrors.customer_contract_rates[i] &&
                      _vm.formErrors.customer_contract_rates[i].rate
                        ? _vm.formErrors.customer_contract_rates[i].rate
                        : []},on:{"input":function($event){_vm.formErrors &&
                      _vm.formErrors.customer_contract_rates &&
                      _vm.formErrors.customer_contract_rates[i] &&
                      _vm.formErrors.customer_contract_rates[i].rate
                        ? delete _vm.formErrors.customer_contract_rates[i].rate
                        : null}},model:{value:(con.rate),callback:function ($$v) {_vm.$set(con, "rate", $$v)},expression:"con.rate"}})],1),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","depressed":""},on:{"click":_vm.addContainerType}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-btn',{attrs:{"color":"secondary","depressed":"","disabled":_vm.contractDetails.customer_contract_rates.length == 1},on:{"click":function($event){return _vm.removeContainerType(i)}}},[_c('v-icon',[_vm._v("mdi-minus")])],1)],1)],1)})],2)]},proxy:true}],null,false,864234149)})],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }