import AddressBookList from "@/pages/customer/address-book/AddressBook.vue";

const addressBookRoutes = [
  {
    path: "address-book",
    name: "app-customer-address-book",
    component: AddressBookList,
  },
]

export default addressBookRoutes