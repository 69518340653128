<template>
  <div>
    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-icon
          v-if="
            params &&
            params.context &&
            params.context.parentComponent &&
            params.context.parentComponent.permissions &&
            params.context.parentComponent.permissions.change
          "
          class="mr-4 ml-1"
          color="primary"
          v-on="on"
          v-bind="attrs"
          @click="editItem"
        >
          mdi-pencil
        </v-icon>
      </template>
      <span>Edit</span>
    </v-tooltip>
     <v-tooltip bottom v-if="params.data.is_active == 'Yes'">
      <template #activator="{ on, attrs }">
        <v-icon
          class="ml-2 mr-1"
          color="primary"
          v-on="on"
          v-bind="attrs"
          @click="adrClassActivateDeactivate('deactivate')"
          v-if="
            params &&
            params.context &&
            params.context.parentComponent &&
            params.context.parentComponent.permissions &&
            params.context.parentComponent.permissions.change
          "
        >
          mdi-close
        </v-icon>
      </template>
      <span>DeActivate</span>
    </v-tooltip>
    <v-tooltip bottom v-if="params.data.is_active == 'No'">
      <template #activator="{ on, attrs }">
        <v-icon
          class="ml-2 mr-1"
          color="primary"
          v-on="on"
          v-bind="attrs"
          @click="adrClassActivateDeactivate('activate')"
          v-if="
            params &&
            params.context &&
            params.context.parentComponent &&
            params.context.parentComponent.permissions &&
            params.context.parentComponent.permissions.change
          "
        >
          mdi-check
        </v-icon>
      </template>
      <span>Activate</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  methods: {
    editItem() {
      this.params.context.parentComponent.editADRClass(this.params.data.id);
    },
    adrClassActivateDeactivate(status){
      this.params.context.parentComponent.adrClassActivateDeactivate(
        this.params.data.id,
        status
      )
    }
  },
};
</script>

<style></style>
