<template>
  <v-dialog v-model="showRenewDialog" max-width="500">
    <v-card>
      <v-card-title class="secondary white--text">
        <span>Renew Contract</span>

        <v-spacer></v-spacer>

        <v-btn
          icon
          @click="
            showRenewDialog = false;
            delete formData.start_date;
            delete formData.end_date;
          "
        >
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-2">
        <v-form v-model="isValid" v-if="showRenewDialog">
          <v-row class="my-0">
            <v-col cols="12">
              <DateField
                ref="dateField"
                :inputProps="{
                  dense: true,
                  label: 'Start Date *',
                  'hide-details': 'auto',
                  rules: [(val) => !!val || 'Start Date is Required!'],
                  'error-messages': errorObj.start_date,
                }"
                :min="today"
                v-model="formData.start_date"
                @input="$emit('removeFieldError')"
              />
            </v-col>

            <v-col cols="12">
              <DateField
                ref="dateField"
                :inputProps="{
                  dense: true,
                  label: 'End Date *',
                  'hide-details': 'auto',
                  rules: [(val) => !!val || 'End Date is Required!'],
                  'error-messages': errorObj.end_date,
                }"
                :min="formData.start_date"
                v-model="formData.end_date"
                @input="$emit('removeFieldError')"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="d-flex justify-center">
        <v-btn
          depressed
          color="primary"
          large
          :disabled="!isValid"
          @click="$emit('submitRemarks')"
        >
          <span>Submit</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";

export default {
  components: { InputField, DateField },
  emits: ["submitRemarks", "removeFieldError"],
  props: {
    value: {
      type: Boolean,
    },
    formDetails: {
      type: Object,
      default: () => {},
    },
    formErrors: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isValid: true,
      today: new Date().toISOString().slice(0, 10),
    };
  },
  computed: {
    formData: {
      get() {
        return this.formDetails;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    showRenewDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    errorObj: {
      get() {
        return this.formErrors;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {},
};
</script>