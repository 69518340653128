<template>
  <v-dialog v-model="showTripPodDialog" max-width="80%">
    <v-card>
      <v-card-title class="secondary white--text py-2 px-4 rounded-0">
        <span> Add E-WayBill Doc </span>

        <v-spacer></v-spacer>

        <v-btn icon @click="resetForm(), (showTripPodDialog = false)">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form>
          <v-row class="ma-0">
            <v-col cols="12" class="Form-Columns-Dense">
              <span class="text-h6 font-weight-regular">
                Upload Documents
              </span>
            </v-col>

            <v-col cols="12" lg="3" class="Form-Columns-Dense">
              <MultiSelect
                label="Document Type"
                name="document-type"
                :items="documentTypes"
                hide-details="auto"
                v-model="document_type"
                :error-messages="formErrors.document_type"
                @input="delete formErrors.document_type"
              />
            </v-col>

            <v-col cols="12" lg="8" class="Form-Columns-Dense">
              <v-file-input
                label="Document"
                name="document"
                accept="image/x-png,image/jpg,image/jpeg,application/pdf"
                dense
                outlined
                :rules="[
                  (file) =>
                    !file ||
                    file.size < 2e6 ||
                    'File size should be less than 2 MB!',
                ]"
                hide-details="auto"
                v-model="document"
                :error-messages="formErrors.document"
                @change="delete formErrors.document, getFileData($event)"
              />
            </v-col>
            <v-col
              cols="12"
              lg="1"
              class="Form-Columns-Dense align-self-center"
            >
              <v-btn
                color="primary"
                depressed
                name="document-add"
                :disabled="!document_type"
                @click="addDocument"
              >
                Add
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              class="Form-Columns-Dense"
              v-show="uploadedDocs && uploadedDocs.length"
            >
              <span class="text-h6 font-weight-regular">Uploaded</span>

              <v-card elevation="0" outlined>
                <v-card-text class="Form-Columns-Dense">
                  <v-row
                    class="ma-0"
                    v-for="(data, i) in uploadedDocs"
                    :key="i"
                  >
                    <v-col cols="12" lg="5" class="py-1">
                      <a
                        :href="data.pod"
                        target="_black"
                        name="uploaded-document-type"
                      >
                        <span>{{ data.document_type }}</span>
                      </a>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col
              cols="12"
              class="Form-Columns-Dense"
              v-show="trippodNewDocuments && trippodNewDocuments.length"
            >
              <span class="text-h6 font-weight-regular">New Upload</span>

              <v-card elevation="0" outlined>
                <v-card-text class="Form-Columns-Dense">
                  <v-row
                    class="ma-0"
                    v-for="(data, j) in trippodNewDocuments"
                    :key="j"
                  >
                    <v-col cols="12" lg="3" class="py-1">
                      <a
                        :href="generateUrl(data.document)"
                        target="_black"
                        name="document-type"
                      >
                        <span>{{ data.document_type }}</span>
                      </a>
                    </v-col>

                    <v-col cols="12" lg="6" class="py-1">
                      <a
                        :href="generateUrl(data.document)"
                        target="_black"
                        name="document"
                      >
                        <span>{{ data.document.name }}</span>
                      </a>
                    </v-col>

                    <v-col cols="12" lg="2" class="py-1">
                      <span>{{ getSize(data.document.size) }}</span>
                    </v-col>

                    <v-col cols="12" lg="1" class="text-right py-1">
                      <v-icon
                        small
                        color="primary"
                        @click="removeFile(j)"
                        name="document-remove"
                      >
                        mdi-close
                      </v-icon>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col cols="12" class="Form-Columns-Dense">
              <v-btn depressed color="primary" small @click="uploadTripPODs" :disabled="trippodNewDocuments.length == 0">
                <span>Submit</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import SelectField from "@/components/FormBaseComponents/SelectField.vue";
import { AgGridVue } from "ag-grid-vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";
import Pagination from "@/components/General/Pagination.vue";
export default {
  components: {
    AgGridVue,
    InputField,
    SelectField,
    DateField,
    MultiSelect,
    Pagination,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    legId: {
      required: false,
      default: null,
    },
  },

  watch: {
    value(val) {
      if (val) {
        if (this.legId) {
          this.getLegListObject(this.legId);
        }
      } else {
        this.resetForm();
      }
    },
  },
  computed: {
    showTripPodDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    context() {
      return { parentComponent: this };
    },
  },
  data() {
    return {
      selectedRows: [],

      formErrors: {},
      isValid: true,
      editMode: false,
      allRowList: [],
      lastEndDate: null,
      allCustomer: [],
      documentDetails: {},
      documentTypes: ["Truck Appointment", "E Way Bill"],
      document_type: null,
      document: null,
      trippodNewDocuments: [],
      isSearchEnabled: false,
      daCount: 0,
      uploadedDocs: {},

      // pagination vars
    };
  },
  methods: {
    addDocument() {
      this.trippodNewDocuments.push({
        document_type: this.document_type,
        document: this.document,
      });

      this.document = null;
      this.document_type = null;
    },
    resetForm() {
      this.allRowList = null;
      this.selectedRows = null;
      this.trippodNewDocuments = [];
      this.document_type = null;
      this.document = null;
    },

    checkFileFormat(event) {
      const name = event.name;
      const lastDot = name.lastIndexOf(".");
      const ext = name.substring(lastDot + 1);
      let allowed = /(\jpg|\jpeg|\pdf|\png)$/i;
      if (!allowed.exec(ext)) {
        this.$nextTick(() => {
          this.document = null;
        });
        return false;
      } else {
        return true;
      }
    },
    getFileData(fileInput) {
      if (fileInput) {
        if (this.checkFileFormat(fileInput)) {
          if (fileInput && !this.document_type) {
            this.$nextTick(() => {
              this.document_type = null;
              this.document = null;
            });
            this.$bus.$emit("showToastMessage", {
              message: "Select Document type first!",
              color: "error",
            });
            return false;
          }
        } else {
          alert("This file format is not Supported!");
        }
      }
    },

    removeFile(index) {
      this.trippodNewDocuments.splice(index, 1);
    },
    getSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },

    generateUrl(file) {
      return URL.createObjectURL(file);
    },

    uploadTripPODs() {
      this.$bus.$emit("showLoader", true);
      for (let i = 0; i < this.trippodNewDocuments.length; i++) {
        let payload = {
          id: this.leg.trip,
          data: null,
        };

        let newForm = new FormData();
        newForm.append(
          "document_type",
          this.trippodNewDocuments[i].document_type
        );
        newForm.append("document", this.trippodNewDocuments[i].document);
        payload.data = newForm;

        // call the API endpoint with the current payload
        this.$api.trip
          .uploadTripPOD(payload)
          .then(() => {
            this.$bus.$emit("showLoader", true);
            this.$bus.$emit("showToastMessage", {
              message: "POD Uploaded Successfully!",
              color: "success",
            });

            this.$bus.$emit("showLoader", false);
            this.showTripPodDialog = false;
            this.trippodNewDocuments = [];

            this.formErrors = {};
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.formErrors = err.data;
            this.$bus.$emit("showToastMessage", {
              message: `${
                err.data && err.data.non_field_errors
                  ? err.data.non_field_errors.join()
                  : "Cannot upload POD"
              }`,
              color: "error",
            });
          });
      }
    },

    getLegListObject(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.trip
        .getLegListObject(id)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.leg = res.data;
          //   this.trip_id = res.data.trip;
          this.uploadedDocs = this.leg.trip_pod_documents;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },

  beforeMount() {},
};
</script>
