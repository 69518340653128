<template>
  <div>
    <v-row no-gutters class="py-3 px-4">
      <v-col cols="12" v-for="(document, i) in documentList" :key="i">
        <v-card
          elevation="0"
          style="border: 1px solid #0000004f !important"
          class="pa-2"
        >
          <v-row>
            <v-col cols="4 ml-5">
              <img
                class="Brand-Logo"
                alt="Brand Logo"
                :src="`${document.document}`"
              />
            </v-col>
            <v-col class="d-flex align-center">
              <h5 class="text-left">{{ document.created }}</h5>
            </v-col>
            <v-col cols="2" class="d-flex align-center justify-end">
              <a :href="document.document" target="_blank">
                <v-btn elevation="2" small> {{$t('view')}} </v-btn>
              </a>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
     <v-row no-gutters class="py-3 px-4">
      <v-col cols="12" v-for="(document, i) in tripDocumentsList" :key="i">
        <v-card
          elevation="0"
          style="border: 1px solid #0000004f !important"
          class="pa-2"
        >
          <v-row>
            <v-col cols="4">
              <img
                class="Brand-Logo"
                alt="Brand Logo"
                :src="`${document.pod}`"
              />
            </v-col>
            <v-col class="d-flex align-center ml-5">
              <h5 class="text-left">{{ document.document_type }}</h5>
            </v-col>
            <v-col cols="2" class="d-flex align-center justify-end">
              <a :href="document.pod" target="_blank">
                <v-btn elevation="2" small> {{$t('view')}} </v-btn>
              </a>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
  
</template>
  
<script>
export default {
  props: {
    podListDialog: {
      type: Boolean,
    },
    documentList: {
      required: true,
      default: () => [],
    },
    tripDocumentsList: {
      required: false,
      default: () => [],
    },
  },
  data() {
    return {};
  },
};
</script>
    
    
<style scoped lang="scss">
.btn-position-fixed-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.card-outlined {
  border: 1px solid #0000004f !important;
  border-radius: 6px;
}
</style>