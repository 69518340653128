<template>
  <v-dialog max-width="50%" v-model="openDialog">
    <v-card>
      <v-card-title class="text-capitalize">
        {{ title }}
        <v-spacer></v-spacer>
        <v-btn icon @click="openDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <slot name="text" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: false,
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
