<template>
  <ListComponent title="Support Tickets">
    <template #list>
      <v-row class="ma-0">
        <v-col cols="4" class="px-0 pt-0 pb-2 d-flex align-center">
          <v-text-field
            :label="'Search On Enter'"
            outlined
            dense
            append-icon="mdi-magnify"
            clearable
            hide-details="auto"
            v-model="filters.search"
            @click:clear="
              delete filters.search;
              searchCustomers();
              onEnter()
            "
            v-on:keyup.enter="onEnter"
          ></v-text-field>

          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                depressed
                icon
                v-on="on"
                v-bind="attrs"
                @click="showFilterDialog = true"
                class="mx-2"
              >
                <v-badge
                  dot
                  color="primary"
                  :value="Object.keys(filters).length"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-badge>
              </v-btn>
            </template>
            <span v-if="Object.keys(filters).length == 0">Filters</span>

            <v-row v-else no-gutters>
              <v-col cols="12" class="font-weight-bold">Applied Filters</v-col>
              <v-col cols="12">
                <span
                  v-for="(value, key) in filters"
                  :key="key"
                  class="text-capitalize"
                  >{{ key.replace("_", " ") }}
                  <br />
                </span>
              </v-col>
            </v-row>
          </v-tooltip>
          <Columns :headers="headers" :gridColInstance="gridColumnApi" />
        </v-col>

        <v-col
          v-if="totalItems > 0"
          cols="8"
          class="px-0 pt-0 pb-2 d-flex justify-end"
        >
          <Pagination
            :pageNo="pageNo"
            :totalItems="totalItems"
            :pageSize="itemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>

        <v-col cols="12" class="pa-0">
          <AgGridVue
            style="width: 100%; height: calc(100vh - 172px)"
            class="ag-theme-alpine"
            id="myGrid"
            :columnDefs="headers"
            :context="context"
            @grid-ready="onGridReady"
            @columnMoved="resizeGrid"
            @columnVisible="resizeGrid"
            :defaultColDef="defaultColDef"
            :grid-options="gridOptions"
            :suppressDragLeaveHidesColumns="true"
            :rowData="allTicketList"
          ></AgGridVue>
        </v-col>
      </v-row>

      <DetailsParent v-model="detailsDrawer">
        <template #details>
          <AdminTicketDetails :ticketId="selectedTicketId" />
        </template>
      </DetailsParent>
    </template>

    <template #dialogs>
      <AdminTicketUpdateStatusDialog
        v-model="showTicketUpdateStatusForm"
        :formDetails="updatingStatus"
        :formErrors="formErrors"
        @removeFieldError="formErrors = {}"
        :label="`Select status`"
        @getTicketList="getTicketList()"
      />

      <AdminTicketFilters
        v-model="showFilterDialog"
        :bindingObject="filters"
        @resetFilters="resetFilters"
        @applyFilters="itemsPerPageChanged(20)"
      />
    </template>

    <template #actions>
      <v-btn
        v-if="permissions && permissions.add"
        @click="addTicket"
        color="primary"
        depressed
      >
        <v-icon>mdi-plus</v-icon>
        <span>Add ticket</span>
      </v-btn>
    </template>
  </ListComponent>
</template>

<script>
import Columns from "@/components/General/Columns.vue";
import ListComponent from "@/components/General/ListComponent.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";
import DetailsParent from "@/components/General/DetailsParent.vue";
import TicketButton from "@/components/AgGridComponents/AdminTicketButton.vue";
import AdminTicketDetails from "@/components/AgGridComponents/AdminTicketDetails.vue";
import AdminTicketUpdateStatusDialog from "@/components/AgGridComponents/AdminTicketUpdateStatusDialog.vue";
import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";
import CustomHeader from "@/components/AgGridComponents/General/CustomHeader.vue";
import AdminTicketFilters from "@/components/AgGridComponents/AdminTicketFilters.vue";

export default {
  head() {
    return {
      title: "Support Ticket",
    };
  },
  components: {
    ListComponent,
    Columns,
    AdminTicketFilters,
    AdminTicketUpdateStatusDialog,
    AgGridVue,
    Pagination,
    TicketButton,
    DetailsParent,
    agColumnHeader: CustomHeader,
    AdminTicketDetails,
  },
  watch: {
    tab() {
      this.pageNo = 1;
      this.getTicketList();
    },
  },
  data() {
    return {
      sorting: {},
      showTicketUpdateStatusForm: false,
      updatingStatus: {},
      headers: [
        {
          headerName: "Ticket Number",
          sortable: true,
          field: "reference_number",
          tooltipField: "reference_number",
        },
        {
          headerName: "Title",
          field: "title",
          sortable: true,
          tooltipField: "title",
        },
        {
          headerName: "Description",
          field: "description",
          sortable: true,
          tooltipField: "description",
        },
        {
          headerName: "Module",
          field: "module",
          sortable: true,
          tooltipField: "module",
        },
        {
          headerName: "Status",
          field: "status",
          sortable: true,
          tooltipField: "status",
          },
        {
          headerName: "Ticket Date",
          field: "ticket_date",
          sortable: true,
          tooltipField: "ticket_date",
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value);
          },
        },
        
        {
          headerName: "Priority",
          field: "priority",
          sortable: true,
          tooltipField: "priority",
          cellStyle: params => {
            if (params.data.priority === 'Low') {
              
                return {color: 'black', backgroundColor: '#90EE90'};
            }
            else if (params.data.priority === 'Medium') {
               
                return {color: 'black', backgroundColor: '#F6BE00'};
            }
            else if (params.data.priority === 'High') {
              
                return {color: 'black', backgroundColor: '#FF0000'};
            }
            return null;
          },
         
        },
        
        
        {
          headerName: "Actions",
          field: "actions",
          pinned: "right",
          cellRenderer: "TicketButton",
          width: 100,
        },
      ],

      allTicketList: [],
      formErrors: {},
      // pagination vars
      pageNo: 1,
      itemsPerPage: 20,
      totalItems: 0,
      filters: {},
      showFilterDialog: false,

      selectedCustomerId: null,
      permissions: null,

      // bulk vars
      detailsDrawer: false,

      // Filter Vars
      filters: {},

      // ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        width: 150,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },
      tab: null,

      // details vars
      selectedTicketId: null,
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
    currentProfile() {
      let obj = localStorage.getItem("userProfile");
      return !obj ? obj : JSON.parse(obj);
    },
  },

  methods: {
    onEnter() {
       this.searchCustomers()
  
    },
    openTicketStatusDialog(id) {
      this.updatingStatus = {
        id: id,
      };
      this.showTicketUpdateStatusForm = true;
    },
    resizeGrid() {
      if (this.gridOptions && this.gridOptions.api) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },
    resetFilters() {
      this.filters = {};
      this.itemsPerPageChanged(20);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },
    searchCustomers() {
      this.pageNo = 1;
      this.getTicketList();
    },
    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getTicketList();
    },
    prevPage() {
      this.pageNo--;
      this.getTicketList();
    },
    nextPage() {
      this.pageNo++;
      this.getTicketList();
    },
    applyGridSort(key, type) {
      if (this.filters && !this.filters.ordering) {
        this.filters.ordering = [];
      }

      if (type == null) {
        delete this.sorting[key];
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(`-${key}`),
          1
        );
      } else if (type == "asc") {
        this.filters.ordering.push(key);
      } else if (type == "desc") {
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(key),
          1,
          `-${key}`
        );
      }
      if (this.filters.ordering.length == 0) {
        delete this.filters.ordering;
      }
      this.itemsPerPageChanged(20);
    },
    addTicket() {
      this.$router.push({
        path: "/app/admin/ticket/add",
      });
    },
    viewTicketDetails(id) {
      this.$router.push({
        path: `/app/admin/ticket/view/${id}`,
      });
    },
    getTicketList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        offset: this.offset,
        limit: this.itemsPerPage,
        ...this.filters,
      };
      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }
      this.$api.ticket
        .getTicketList(params)
        .then((res) => {
          this.allTicketList = res.data;
          this.totalItems = res.count;
          this.$bus.$emit("showLoader", false);
        })
        .catch(() => {
          this.$bus.$emit("showLoader", false);
        });
    },
    viewTicket(id) {
      this.selectedTicketId = id;
      this.detailsDrawer = true;
    },
  },
  beforeMount() {
    this.getTicketList();
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("support_ticket");

      if (!permissions || !permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.permissions = { ...permissions };
        this.getTicketList();
      }
    }
  },
};
</script>
