var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
          _vm.params &&
          _vm.params.context &&
          _vm.params.context.parentComponent &&
          _vm.params.context.parentComponent.permissions &&
          _vm.params.context.parentComponent.permissions.add &&
          _vm.params.context.parentComponent.tab == 0
        )?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 mr-2",attrs:{"color":"success"},on:{"click":function($event){return _vm.updateStatus(_vm.params.data, 'Accepted')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-check ")]):_vm._e()]}}])},[_c('span',[_vm._v("Accept")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
          _vm.params &&
          _vm.params.context &&
          _vm.params.context.parentComponent &&
          _vm.params.context.parentComponent.permissions &&
          _vm.params.context.parentComponent.permissions.add &&
          _vm.params.context.parentComponent.tab == 0
        )?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 mr-2",attrs:{"color":"error"},on:{"click":function($event){return _vm.updateStatus(_vm.params.data, 'Rejected')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-close ")]):_vm._e()]}}])},[_c('span',[_vm._v("Reject")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 mr-1",attrs:{"color":"primary"},on:{"click":_vm.download}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-download ")])]}}])},[_c('span',[_vm._v("Download")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }