<template>
  <v-menu
    open-on-hover
    :close-on-content-click="false"
    right
    offset-y
    max-width="500"
    min-width="100"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge top overlap :value="badgeValue" :content="badgeValue">
        <v-icon v-on="on" v-bind="attrs" class="mx-2">
          {{ icon }}
        </v-icon>
      </v-badge>
    </template>

    <v-card>
      <v-card-title
        class="text-subtitle-1 font-weight-medium py-1"
        :class="cardHeadingColor"
      >
        {{ cardTitle }}
      </v-card-title>
      <v-card-text class="mt-2 Height-Class">
        <slot name="information" />
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: "mdi-information",
    },
    badgeValue: {
      type: Number,
      default: 0,
    },
    cardHeadingColor: {
      type: String,
      default: "secondary white--text",
    },
    cardTitle: {
      type: String,
      default: "Title",
    },
  },
};
</script>

<style scoped>
.Height-Class {
  min-height: auto;
  max-height: 500px;
  overflow-y: auto;
}
</style>