import Blank from "@/layouts/blank.vue"
import CreditNoteList from "@/pages/admin/invoice/credit-note/CreditNoteList.vue";
import CreditNoteEdit from "@/pages/admin/invoice/credit-note/CreditNoteEdit.vue";
import CreditNoteDetails from "@/pages/admin/invoice/credit-note/CreditNoteDetails.vue";
 
const creditNoteRoutes = [
  {
    path: "credit-note",
    name: "app-admin-invoice-credit-note",
    component: Blank,
    children: [
      {
        path: "/",
        redirect: "/app/admin/invoice/credit-note/list",
      },
      {
        path: "list",
        name: "app-admin-invoice-credit-note-list",
        component: CreditNoteList,
      },


      {
        path: "edit",
        name: "app-admin-invoice-credit-note-edit",
        component: Blank,
        children: [
          {
            path: "/",
            redirect: "/app/admin/invoice/credit-note/list",
          },
          {
            path: ":id",
            name: "app-admin-invoice-credit-note-edit-id",
            component: CreditNoteEdit,
          }
        ]
      },
      {
        path: "details",
        name: "app-admin-invocie-credit-note-details",
        component: Blank,
        children: [
          {
            path: "/",
            redirect: "/app/admin/delivery-advice/list",
          },
          {
            path: ":id",
            name: "app-admin-invocie-credit-note-details-id",
            component: CreditNoteEdit,
          }
        ]
      },
    ]
  },
]

export default creditNoteRoutes