<template>
  <v-tooltip top>
    <template #activator="{ on, attrs }">
      <v-icon
        class="ml-2"
        color="primary"
        v-on="on"
        v-bind="attrs"
        @click="viewItem"
      >
        mdi-eye
      </v-icon>
    </template>
    <span>View</span>
  </v-tooltip>
</template>

<script>
export default {
  methods: {
    viewItem() {
      this.params.context.parentComponent.viewTripInfo(this.params.data);
    },
  },
};
</script>