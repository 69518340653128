<template>
  <v-row class="ma-0">
    <v-col
      cols="12"
      sm="6"
      md="4"
      lg="2"
      class="pb-2 pr-2"
      v-if="dashboardData.companies"
    >
      <v-card class="fill-height" elevation="0" outlined>
        <v-card-title class="grey darken-1 py-2">
          <span class="text-h6 white--text"> Company </span>
        </v-card-title>

        <v-card-text class="px-3 py-2">
          <table class="Primary-Table">
            <tbody>
              <tr v-for="(value, key) in dashboardData.companies" :key="key">
                <td>{{ key.replace(/_+/g, " ") }}</td>
                <th>{{ value }}</th>
              </tr>
            </tbody>
          </table>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" sm="6" md="4" lg="2" class="pb-2 px-2">
      <v-card class="fill-height" elevation="0" outlined>
        <v-card-title class="grey darken-1 py-2">
          <span class="text-h6 white--text"> Locations/Routes </span>
        </v-card-title>

        <v-card-text class="px-3 py-2">
          <table class="Primary-Table">
            <tbody>
              <tr
                v-for="(value, key) in dashboardData.lane_locations"
                :key="key"
              >
                <td>{{ key.replace(/_+/g, " ") }}</td>
                <th>{{ value }}</th>
              </tr>
            </tbody>
          </table>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" sm="6" md="4" lg="2" class="pb-2 px-2">
      <v-card class="fill-height" elevation="0" outlined>
        <v-card-title class="grey darken-1 py-2">
          <span class="text-h6 white--text"> Delivery Advices </span>
        </v-card-title>

        <v-card-text class="px-3 py-2">
          <table class="Primary-Table">
            <tbody>
              <tr
                v-for="(value, key) in dashboardData.delivery_advices"
                :key="key"
              >
                <td>{{ key.replace(/_+/g, " ") }}</td>
                <th>{{ value }}</th>
              </tr>
            </tbody>
          </table>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" sm="6" md="4" lg="2" class="pb-2 px-2">
      <v-card class="fill-height" elevation="0" outlined>
        <v-card-title class="grey darken-1 py-2">
          <span class="text-h6 white--text"> Trips </span>
        </v-card-title>

        <v-card-text class="px-3 py-2">
          <table class="Primary-Table">
            <tbody>
              <tr v-for="(value, key) in dashboardData.trips" :key="key">
                <td>{{ key.replace(/_+/g, " ") }}</td>
                <th>{{ value }}</th>
              </tr>
            </tbody>
          </table>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" sm="6" md="4" lg="2" class="pb-2 px-2">
      <v-card class="fill-height" elevation="0" outlined>
        <v-card-title class="grey darken-1 py-2">
          <span class="text-h6 white--text"> Sub Hire Jobs </span>
        </v-card-title>

        <v-card-text class="px-3 py-2">
          <table class="Primary-Table">
            <tbody>
              <tr
                v-for="(value, key) in dashboardData.sub_hire_jobs"
                :key="key"
              >
                <td>{{ key.replace(/_+/g, " ") }}</td>
                <th>{{ value }}</th>
              </tr>
            </tbody>
          </table>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="4"
      lg="2"
      class="pb-2 pl-2"
      v-if="dashboardData.invoice_payment_receipt"
    >
      <v-card class="fill-height" elevation="0" outlined>
        <v-card-title class="grey darken-1 py-2">
          <span class="text-h6 white--text"> Invoice / Payment Receipt </span>
        </v-card-title>

        <v-card-text class="px-3 py-2">
          <table class="Primary-Table">
            <tbody>
              <tr
                v-for="(value, key) in dashboardData.invoice_payment_receipt"
                :key="key"
              >
                <td>{{ key.replace(/_+/g, " ") }}</td>
                <th>{{ value }}</th>
              </tr>
            </tbody>
          </table>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" class="py-2">
      <v-row class="ma-0 heading rounded">
        <v-col cols="12" lg="4" class="d-flex align-center px-4">
          <span class="text-h6">Charts</span>
        </v-col>
        <v-col cols="12" lg="8" class="d-flex justify-end align-center">
          <DateField
            :inputProps="{
              dense: true,
              label: 'Start Date',
              clearable: true,
              'hide-details': 'auto',
              style: 'max-width: 300px;',
              'background-color': 'white',
            }"
            :max="today"
            v-model="startDate"
          />
          <DateField
            :inputProps="{
              dense: true,
              label: 'End Date',
              clearable: true,
              'hide-details': 'auto',
              style: 'max-width: 300px;',
              class: 'ml-2',
              'background-color': 'white',
            }"
            :min="startDate"
            :max="today"
            :disabled="!startDate"
            v-model="endDate"
          />
          <v-btn depressed class="ml-2" color="primary" @click="datesChanged()">
            Submit
          </v-btn>
        </v-col>
      </v-row>
    </v-col>

    <v-col
      cols="6"
      v-if="
        dashboardData &&
        dashboardData.da &&
        dashboardData.da.da_status_date_wise_data
      "
      class="py-2 pr-2"
    >
      <v-card elevation="0" outlined>
        <v-card-title class="heading py-2">DA Stacked Chart</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <Chart
            :options="chartOptions"
            :labels="
              dashboardData.total_da.total_da_status_date_wise_data.categories
            "
            :datasets="
              dashboardData.total_da.total_da_status_date_wise_data.values
            "
          />
        </v-card-text>
      </v-card>
    </v-col>

    <v-col
      cols="6"
      v-if="
        dashboardData &&
        dashboardData.trip &&
        dashboardData.trip.trip_status_date_wise_data
      "
      class="py-2 pr-2"
    >
      <v-card elevation="0" outlined>
        <v-card-title class="heading py-2">Trip Stacked Chart</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <Chart
            :options="chartOptions"
            :labels="dashboardData.trip.trip_status_date_wise_data.categories"
            :datasets="dashboardData.trip.trip_status_date_wise_data.values"
          />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";
import {
  generateChartOptions,
  generateChartSeries,
} from "@/utils/functions.js";

import Chart from "@/components/General/Chart.vue";

export default {
  name: "AdminIndexPage",

  components: { InputField, Chart, DateField },
  data() {
    return {
      generateChartOptions,
      generateChartSeries,
      dashboardData: {},

      // date vars
      startDate: null,
      endDate: null,
      today: new Date().toISOString().slice(0, 10),

      labels: ["January", "February", "March"],
      datasets: [{ data: [40, 20, 12] }],

      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        height: "500",
        datasets: {
          bar: {
            categoryPercentage: 0.5,
            barPercentage: 1,
          },
        },
        scales: {
          xAxes: [
            {
              stacked: true,
            },
          ],
          yAxes: [
            {
              stacked: true,
            },
          ],
        },
      },
    };
  },
  methods: {
    datesChanged() {
      this.getDashboardData({
        start_date: this.startDate,
        end_date: this.endDate,
      });
    },

    getDashboardData(params = {}) {
      this.$bus.$emit("showLoader", true);
      this.$api.dashboard
        .getAdminDashboardData(params)
        .then((res) => {
          this.dashboardData = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch(() => {
          this.$bus.$emit("showLoader", false);
        });
    },
  },
  mounted() {
    this.getDashboardData();
  },
};
</script>
