<template>
  <v-card class="fill-height">
    <v-card-title class="heading mb-4">
      <Tooltip
        tooltipTitle="Username"
        v-if="userDetails.username"
        :tooltipText="userDetails.username"
        right
        tooltipClass="text-h5 font-weight-medium"
      />
    </v-card-title>

    <v-card-text>
      <v-sheet class="Height-Class">
        <v-timeline v-if="logs && logs.length" align-top dense>
          <v-timeline-item
            v-for="(log, index) in logs"
            :key="index"
            color="primary"
            icon="mdi-circle"
            small
          >
            <div>
              <strong>{{ log.created | formatDate(true) }}</strong>
              <div class="text-body-2">{{ log.message }}</div>
              <div class="text-caption">- {{ log.updated_by }}</div>
            </div>
          </v-timeline-item>
        </v-timeline>

        <div v-else class="text-center">
          <v-divider class="mb-4"></v-divider>
          <span>No Logs to show</span>
        </div>
      </v-sheet>
    </v-card-text>
  </v-card>
</template>

<script>
import CardDetails from "@/components/General/CardDetails.vue";
import Tooltip from "@/components/General/Tooltip.vue";

export default {
  components: { CardDetails, Tooltip },
  props: {
    userId: {
      type: Number,
    },
  },
  watch: {
    userId(val) {
      this.logs = [];
      this.getUserObject(val);
      this.gtUserActivityLogs();
    },
  },
  data() {
    return {
      userDetails: {},
      logs: [],
      detailsTab: 0,
    };
  },
  methods: {
    getUserObject(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.user
        .getUserObject(id)
        .then((res) => {
          this.userDetails = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    gtUserActivityLogs(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        ...params,
        gt_user: this.userId,
        limit: "50",
        offset: this.logs.length,
      };
      this.$api.activityLogs
        .gtUserActivityLogs(params)
        .then((res) => {
          this.logs.push(...res.data);
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
  mounted() {
    this.contractDetailView = [];
    this.logs = [];
    this.getUserObject(this.userId);
    this.gtUserActivityLogs();
  },
  computed: {
    userType() {
      return localStorage.getItem("user_type");
    },
  },
};
</script>

<style scoped>
.Height-Class {
  height: calc(100vh - 196px);
  overflow-y: auto;
}
</style>