import {
  reports,
  version
} from "@/utils/constants";
import {
  handleError,
  handleResponse
} from "@/utils/functions";

export default (axios) => ({
  getReportList(params = {}, headers = {}, responseType = 'json') {
    return new Promise((resolve, reject) => {
      axios
        .get(`${reports.reportBase}`, {
          params: params,
          responseType: responseType,
          headers: headers
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getReportData(data = {}) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${reports.reportBase}`, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getReportBlobObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${reports.reportBase}${id}/download/`, {
          responseType: 'blob',
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getReportDetails(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${reports.reportBase}${id}/view/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getReportOptions(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .options(reports.reportBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});