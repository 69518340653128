<template>
  <div>
    <v-icon color="error" v-if="params.value"> mdi-close-circle </v-icon>
    <v-icon color="success" v-else> mdi-check-circle </v-icon>
  </div>
</template>

<script>
export default {
  methods: {},
};
</script>

<style></style>
