import { adrClass } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
    getADRClassList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(adrClass.adrClassBase, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getADRClassObject(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${adrClass.adrClassBase}${id}/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    addADRClass(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(adrClass.adrClassBase, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    activateDeactivateadrClass(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(
                    `${adrClass.adrClassBase}${payload.id}/deactivate_adrClass/`,
                    payload
                )
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    updateADRClass(payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(`${adrClass.adrClassBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    partiallyUpdateADRClass(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${adrClass.adrClassBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
});