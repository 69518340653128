<template>
  <v-dialog max-width="65%" v-model="showAddressForm">
    <v-card>
      <v-card-title class="secondary white--text py-2 px-4">
        <span v-if="editMode" class="text-capitalize">
          Edit {{ module }} Address
        </span>

        <span v-else class="text-capitalize">Add {{ module }} Address</span>

        <v-spacer></v-spacer>

        <v-btn icon @click="resetForm(), $emit('closeAddressForm')">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pa-2">
        <v-form ref="addressForm" v-if="showAddressForm" v-model="isValid">
          <v-row class="ma-0">
            <v-col cols="12" lg="4" class="pa-2">
              <InputField
                hide-details="auto"
                type="text"
                label="Address Title *"
                :rules="[(val) => !!val || 'Address Title is Required!']"
                required
                v-model="addressDetails.name"
                :error-messages="formErrors.name"
                @input="delete formErrors.name"
              />
            </v-col>

            <v-col cols="12" lg="8" class="pa-2">
              <v-text-field
                hide-details="auto"
                type="text"
                label="Landmark *"
                id="autocomplete1"
                ref="autocomplete1"
                dense
                outlined
                :rules="[(val) => !!val || 'Location is Required!']"
                required
                v-model="addressDetails.location_string"
                :error-messages="formErrors.location_string"
                @input="delete formErrors.location_string"
              />
            </v-col>

            <!-- <v-col cols="12" md="6" lg="4" class="pa-2">
              <MultiSelect
                hide-details="auto"
                label="Country *"
                :items="allCountryList"
                itemText="name"
                itemValue="id"
                :returnObject="true"
                :rules="[(val) => !!val || 'Country is Required!']"
                v-model="addressDetails.country"
                :error-messages="formErrors.country"
                @change="
                  delete formErrors.country;
                  checkData($event, 'country');
                "
              />
            </v-col> -->

            <!-- <v-col cols="12" md="6" lg="4" class="pa-2">
              <MultiSelect
                hide-details="auto"
                label="City *"
                :items="allCityList"
                itemText="city_name"
                itemValue="id"
                :rules="[(val) => !!val || 'City is Required!']"
                required
                v-model="addressDetails.city"
                :error-messages="formErrors.city"
                @change="delete formErrors.city"
              />
            </v-col> -->


            <v-col cols="12" class="pa-2">
              <TextareaField
                hide-details="auto"
                label="Address *"
                counter
                :rules="[
                  (val) => !!val || 'Address is Required!',
                  (val) =>
                    (val && val.length < 255) ||
                    'Address can not be more than 255 characters!',
                ]"
                required
                v-model="addressDetails.address"
                :error-messages="formErrors.address"
                @input="delete formErrors.address"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          color="primary"
          depressed
          :disabled="!isValid"
          @click="submitForm"
        >
          Submit
        </v-btn>
        <v-btn
          class="ml-3"
          color="secondary"
          depressed
          @click="resetForm"
          v-if="!editMode"
        >
          Reset
        </v-btn>
      </v-card-actions>
    </v-card>

    <CoordinatesForm
      v-model="showCoordinateMap"
      :coordinates="addressDetails.coordinates"
      @confirmLocation="addressDetails.coordinates = $event"
    />
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import SelectField from "@/components/FormBaseComponents/SelectField.vue";
import TextareaField from "@/components/FormBaseComponents/TextareaField.vue";
import CoordinatesForm from "../dialogs/CoordinatesForm.vue";

export default {
  components: {
    InputField,
    SelectField,
    CoordinatesForm,
    TextareaField,
    MultiSelect,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    module: {
      type: String,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    customerId: {
      type: [Number, Object],
      default: null,
    },
    addressId: {
      type: Number,
    },
  },
  computed: {
    showAddressForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    return {
      addressDetails: {},
      formErrors: {},
      isValid: true,

      // coordinate map vars
      showCoordinateMap: false,
    };
  },
  watch: {
    value(val) {
      if (val) {
        if (this.editMode && this.addressId != null) {
          this.getAddressDetails(this.addressId);
        }
        this.getAutoComplete();
      }
    },
  },
  methods: {
    checkData(obj, key) {
      if (obj) {

        this.addressDetails[key] = obj.id;
      }
    },

    getAutoComplete() {
      const interval = setInterval(async () => {
        let input1 = document.getElementById("autocomplete1");

        if (window.google && window.google.maps && input1) {
          clearInterval(interval);

          this.autocomplete1 = new window.google.maps.places.Autocomplete(
            input1
          );

          input1.setAttribute("placeholder", "");
          this.autocomplete1.addListener("place_changed", () => {
            let place = this.autocomplete1.getPlace();
            this.addressDetails.coordinates = {
              latitude: place.geometry.location.lat(),
              longitude: place.geometry.location.lng(),
            };
            this.addressDetails.location_string = place.formatted_address;
            this.addressDetails.address = place.formatted_address;
            this.showCoordinateMap = true;
          });
        }
      }, 100);
    },

    getAddressDetails(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.customerAddress
        .getCustomerAddressObject(id)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.addressDetails = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },


    submitForm() {
      this.$bus.$emit("showLoader", true);
      let payload;

      if (!this.editMode) {
        payload = {
          ...this.addressDetails,
          customer: this.customerId,
        };
        if (payload.customer && typeof payload.customer != "number") {
          payload.customer = this.customerId.id;
        }

        this.$api.customerAddress
          .addCustomerAddress(payload)
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Address created successfully!",
              color: "success",
            });
            this.$emit("refreshList");
            this.resetForm();
            this.showAddressForm = false;
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't create customer address!",
              color: "error",
            });
            this.formErrors = err.data;
          });
      } else {
        payload = {
          id: this.addressDetails.id,
          data: this.addressDetails,
        };
        this.$api.customerAddress
          .updateCustomerAddress(payload)
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Address updated successfully!",
              color: "success",
            });
            this.$emit("refreshList");
            this.resetForm();
            this.showAddressForm = false;
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't update customer address!",
              color: "error",
            });
            console.error(err);
            this.formErrors = err.data;
          });
      }
    },
    resetForm() {
      this.addressDetails = {};
      if (this.$refs.addressForm) {
        this.$refs.addressForm.reset();
      }
    },
  },
};
</script>
