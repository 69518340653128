<template>
  <v-dialog fullscreen v-model="replicaDialog" max-width="50%">
    <v-card>
      <v-card-title>
        Replicate {{ daDetails.da_no }}

        <v-spacer></v-spacer>
        <v-col cols="12" md="12" lg="3" class="text-right">
          <v-file-input
            ref="replicateDAFileInput"
            outlined
            dense
            hide-details="auto"
            label="Select File"
            v-model="replicateDAFile"
            @change="fileSelected($event)"
          />
        </v-col>
        <v-col cols="12" md="12" lg="2" class="text-right">
          <v-btn depressed color="primary" @click="downloadDAReplicateSample">
            Download Sample
          </v-btn>
        </v-col>

        <v-btn
          icon
          @click="
            replicaDialog = false;
            resetForm();
          "
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-alert dense border="left" type="warning" class="mt-2">
          Container weight must have at most 2 decimals
        </v-alert>

        <v-form v-model="isValid" v-if="replicaDialog">
          <v-data-table
            :headers="headers"
            :items="containerSetList"
            :hide-default-footer="true"
            :disable-pagination="true"
          >
            <template v-slot:item="{ item, index }">
              <tr>
                <td>{{ index + 1 }}</td>
                <td
                  v-for="(containerId, containerIndex) in Object.keys(item)"
                  :key="`${containerId}${containerIndex}`"
                >
                  <v-row>
                    <v-col cols="12" md="12" lg="6" class="text-right">
                      <InputField
                        :rules="[(val) => !!val || 'Container No is Required!']"
                        placeholder="ABCD1234567"
                        required
                        persistent-hint
                        :hint="
                          formFieldHints &&
                          formFieldHints[`${containerId}${index + 1}`]
                        "
                        hide-details="auto"
                        label="Container No"
                        v-model="item[containerId].container_no"
                      />
                    </v-col>

                    <v-col cols="12" md="12" lg="6" class="text-right">
                      <InputField
                        type="number"
                        label="Container Weight (Tons) *"
                        aria-placeholder=""
                        step=".01"
                        :rules="[
                          (v) => !!v || 'Container Weight is Required',
                          (v) =>
                            v > 0 || 'Container Weight can not be less than 0',
                          (v) =>
                            $globals.containerWeightFormatRegExp.test(v) ||
                            'Container Weight must have the format 00.00',
                        ]"
                        hide-details="auto"
                        v-model="item[containerId].weight"
                      />
                    </v-col>
                  </v-row>
                </td>
                <td>
                  <v-btn
                    color="primary"
                    small
                    depressed
                    @click="addContainerSet"
                    class="mr-1"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                  <v-btn
                    color="secondary"
                    small
                    v-if="containerSetList.length > 1"
                    depressed
                    @click="removeContainerSet(index)"
                  >
                    <v-icon color="white">mdi-minus</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="d-flex justify-center">
        <v-btn
          depressed
          color="primary"
          large
          :disabled="!isValid"
          @click="addBulkDeliveryAdvice"
        >
          <span>Submit</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import { formatDataKeys } from "@/utils/functions";
import { utils, read, write } from "xlsx";
import FileField from "@/components/FormBaseComponents/FileField.vue";
import { DAReplicateSampleDownloadHeaders } from "@/utils/choices";

export default {
  components: { InputField, FileField },
  props: {
    value: {
      type: Boolean,
    },
    daDetails: {
      type: Object,
      required: true,
    },
    daPayload: {
      type: Object,
      required: false,
    },
    replicateNewDA: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    replicaDialog(val) {
      if (val) {
        this.setHeaders();
        this.addContainerSet();
      }
    },
    containerSetList: {
      handler(val) {
        if (val && val.length > 0 && !this.replicateDAFile) {
          val.forEach((item, index) => {
            Object.keys(item).forEach((containerId, containerIndex) => {
              let formFieldHintsKey = `${containerId}${index + 1}`;
              if (item[containerId] && item[containerId].container_no) {
                let container_no = item[containerId].container_no;
                if (
                  container_no &&
                  !this.$globals.containerNumberRegExp.test(container_no)
                ) {
                  this.formFieldHints[formFieldHintsKey] =
                    "Invalid Container No!";
                } else {
                  delete this.formFieldHints[formFieldHintsKey];
                }
              }
            });
          });
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      formFieldHints: {},

      isValid: true,
      replicateDAFile: null,
      containerSetList: [],
      rawData: [],
      headers: [
        { text: "#", value: "index", sortable: false },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    replicaDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    daContainerSet() {
      return this.daDetails.delivery_advice_containers;
    },
    cols() {
      return this.daContainerSet.length > 0
        ? 12 / this.daContainerSet.length
        : 12;
    },
  },

  methods: {
    downloadDAReplicateSample() {
      const workBookName = `REPLICATE_DA_SAMPLE_${new Date()
        .toISOString()
        .slice(0, 10)}`;

      var wb = utils.book_new();
      wb.Props = {
        Title: workBookName,
        CreatedDate: new Date(),
      };

      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, DAReplicateSampleDownloadHeaders);

      utils.sheet_add_json(ws, [], { origin: "A2", skipHeader: true });

      utils.book_append_sheet(wb, ws, "Sheet1");

      const wbout = write(wb, { bookType: "xlsx", type: "binary" });
      const blob = new Blob([this.s2ab(wbout)], {
        type: "application/octet-stream",
      });
      this.download(blob, workBookName);

      this.$bus.$emit("showLoader", false);
    },
    download(blob, workBookName) {
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      const fileName = ` ${workBookName}.xlsx`;

      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    },
    s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    },
    fileSelected(file) {
      if (file) {
        const name = file.name;
        const lastDot = name.lastIndexOf(".");
        const ext = name.substring(lastDot + 1);
        let allowed = /(\xlsx|\xls)$/i;
        if (!allowed.exec(ext)) {
          this.$nextTick(() => {
            this.$refs.replicateDAFileInput.reset();
            this.$bus.$emit("showToastMessage", {
              message: "This file format is not supported!",
              color: "error",
            });
          });
          return false;
        }
      }

      if (file && file.size > 2e6) {
        this.$bus.$emit("showToastMessage", {
          message: "File size must be less than 2 MB",
          color: "error",
        });
        return;
      }
      if (file) {
        this.$bus.$emit("showLoader", true);
        let reader = new FileReader();
        reader.onload = async () => {
          let fileData = reader.result;

          let wb = read(fileData, {
            type: "binary",
          });
          const sheetData = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
          if (sheetData && sheetData.length == 0) {
            this.$bus.$emit("showToastMessage", {
              message: "Empty Sheet",
              color: "error",
            });
            this.$bus.$emit("showLoader", false);

            return false;
          }

          const rawData = [
            ...(await formatDataKeys(sheetData, " ", "_", "lowercase")),
          ];
          if (rawData.length > 500) {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Cannot add more than 500 replica at a time",
              color: "error",
            });
            this.$refs.replicateDAFileInput.reset();
            return;
          }
          try {
            let _distinctContainerIds = [];
            if (this.containerSetList.length) {
              _distinctContainerIds = Object.keys(this.containerSetList[0]);
            }

            this.containerSetList = rawData.map((containerSet) => {
              const obj = {};
              let index = 0;
              while (Object.keys(obj).length != _distinctContainerIds.length) {
                obj[_distinctContainerIds[index]] = {};

                obj[_distinctContainerIds[index]]["container_no"] =
                  containerSet[`container_no${!!index ? "_" + index : ""}`];

                obj[_distinctContainerIds[index]]["weight"] =
                  containerSet[`container_weight${!!index ? "_" + index : ""}`];

                index++;
              }
              return obj;
            });
            this.$bus.$emit("showLoader", false);
          } catch (err) {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Some issue with the excel sheet data",
              color: "error",
            });
            this.replicateDAFile = null;
          }
        };

        reader.readAsBinaryString(file);
      } else {
        this.errors = [];
        this.rawData = [];
      }
    },
    setHeaders() {
      this.daContainerSet.forEach((container) => {
        this.headers.splice(this.headers.length - 1, 0, {
          text: container.container_type_title,
          align: "center",
          value: container.id,
          sortable: false,
        });
      });
    },
    addContainerSet() {
      if (this.containerSetList.length != 500) {
        let obj = {};
        this.daContainerSet.forEach((c, index) => {
          obj[c.id] = {};
          if (this.daContainerSet.length == index + 1) {
            let formFieldHintsKey = `${c.id}${this.containerSetList.length}`;
            this.containerSetList.push(obj);
            this.formFieldHints[formFieldHintsKey] = "";
          }
        });
      } else {
        this.$bus.$emit("showToastMessage", {
          message: "Cannot add more than 500 replica at a time",
          color: "error",
        });
      }
    },
    removeContainerSet(index) {
      if (this.containerSetList.length > 1) {
        this.containerSetList.splice(index, 1);
      }
    },
    async formatData() {
      return this.containerSetList.map((containerSet) => {
        return Object.keys(containerSet).map((containerId) => {
          return {
            ...containerSet[containerId],
            order_container_id: containerId,
          };
        });
      });
    },
    async addBulkDeliveryAdvice() {
      if (this.replicateNewDA) {
        const payload = this.daPayload;
        payload.replicate_da = await this.formatData();
        
        this.$emit("replicateDA", payload);
        this.resetForm();
        return;
      }
      
      const data = {
        id: this.daDetails.id,
        data: await this.formatData(),
      };
      this.$bus.$emit("showLoader", true);

      this.$api.deliveryAdvice
        .addBulkDeliveryAdvice(data)
        .then((res) => {
          this.$emit("refreshList");
          this.replicaDialog = false;
          this.resetForm();
          this.$bus.$emit("showToastMessage", {
            message: "Delivery Advices created successfully",
            color: "success",
          });
          this.$router.push("/app/admin/trip/list");
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);

          console.error(err);

          this.$bus.$emit("showToastMessage", {
            message: `${
              err.data && err.data.non_field_errors
                ? err.data.non_field_errors.join()
                : "Cannot replicate DA"
            }`,
            color: "error",
          });
        });
    },
    resetForm() {
      this.formFieldHints = {};
      this.containerSetList = [];
      this.headers.splice(1, this.headers.length - 2);
      this.$refs.replicateDAFileInput.reset();
    },
  },
};
</script>