<template>
  <div>
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn
          v-if="
            params &&
            params.data &&
            params.data.active_trip &&
            params.data.active_trip.trip_id
          "
          depressed
          small
          color="primary"
          v-on="on"
          v-bind="attrs"
          @click="goToTrip"
        >
          {{ params.data.active_trip.trip_id }}
        </v-btn>
      </template>
      <span>View</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  methods: {
    goToTrip() {
      this.params.context.parentComponent.goToTrip(
        this.params.data.active_trip.id
      );
    },
  },
};
</script>

<style>
</style>