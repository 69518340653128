export const ReportType = [{
        text: "Customer Delivery Advice Summary Report",
        value: "Customer Delivery Advice Summary Report",
    },
    {
        text: "Delivery Advice Summary Report",
        value: "Delivery Advice Summary Report",
    },
    {
        text: "Delivery Advice Time Stamp Report",
        value: "Delivery Advice Time Stamp Report",
    },
    {
        text: "Delivery Advice List Report",
        value: "Delivery Advice List Report",
    },
    {
        text: "Customer Invoice Detailed Report",
        value: "Customer Invoice Detailed Report",
    },
    {
        text: "Invoice List (Consolidated with remarks)",
        value: "Invoice List",
    },
    {
        text: "DA Pending To Invoice Report",
        value: "DA Pending To Invoice Report",
    },
    {
        text: "Defective CheckList Report",
        value: "Defective CheckList Report",
    },
    {
        text: "Trip CheckList Log",
        value: "Trip CheckList Log",
    },
];
export const DAReplicateSampleDownloadHeaders = [
    [
        "Container No",
        "Container Weight",
        "Container No",
        "Container Weight",
        "Container No",
        "Container Weight",
        "Container No",
        "Container Weight",
    ],
];
export const ReportCategory = [{
        display_name: "Finance Reports",
        value: "Finance Reports",
    },
    {
        display_name: "Delivery Advice Reports",
        value: "Delivery Advice Reports",
    },
    {
        display_name: "Vehicle Reports",
        value: "Vehicle Reports",
    },
    {
        display_name: "Driver Reports",
        value: "Driver Reports",
    },
    {
        display_name: "Sub Hire Report",
        value: "Sub Hire Reports",
    },
    {
        display_name: "Other Reports",
        value: "Other Reports",
    },
];