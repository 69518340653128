import authHeader from "@/utils/authHeader";

const wsObject = {
  // Chat Socket Variables
  messageList: [],
  selectedTrip: null,
  initialLoad: true,
  newMessageAlert: false,
  chatHistoryTimeout: null,

  // Notification Variables
  offset: 0,
  limit: 5,
  userCompanyId: null,
  userType: null,
  unreadCount: 0,
  companyNotifications: [],
  notificationsTimeout: null,
  clearAllNotifications: false,

  createInstance(url, protocols = []) {
    this.instance = new WebSocket(
      `${process.env.VUE_APP_BASE_WS_URL}${url}/?token=${!!authHeader() ? authHeader().replace("token ", "") : ""
      }`,
      protocols
    );
    return;
  },

  removeInstance() {
    if (this.instance) {
      this.instance.close();
      this.instance = null;
    }
  },
};

export default wsObject;