<template>
  <FormComponent
    title="Edit Cash DA "
    :backRoute="backRoute"
    :nonFieldErrors="formErrors.non_field_errors"
    :daID="directDaDetails.da_no"
  >
    <template #formActions>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-on="on"
            v-bind="attrs"
            class="mx-2"
            @click="getEditDaDetails('previous')"
          >
            mdi-arrow-left-drop-circle
          </v-icon>
        </template>
        <span>Previous</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-on="on"
            v-bind="attrs"
            class="mx-2"
            @click="getEditDaDetails('next')"
          >
            mdi-arrow-right-drop-circle
          </v-icon>
        </template>
        <span>Next</span>
      </v-tooltip>
      <v-btn
        color="primary"
        depressed
        :disabled="!allFormValidation"
        @click="submitForm"
      >
        Update
      </v-btn>
      <!-- <v-btn color="secondary" class="ml-2" depressed @click="resetForm">
        Reset
      </v-btn> -->
    </template>

    <template #formContent>
      <v-stepper v-model="stepper" non-linear flat>
        <v-stepper-header class="elevation-0">
          <v-stepper-step
            :rules="[() => (Object.keys(formErrors).length ? valid1 : true)]"
            step="1"
            editable
          >
            Basic Details
          </v-stepper-step>
          <v-divider class="ma-0"></v-divider>
          <v-stepper-step
            :rules="[() => (Object.keys(formErrors).length ? valid3 : true)]"
            step="2"
            editable
          >
            Document Details
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1" class="pa-0">
            <v-form ref="stepperForm1" v-model="valid1">
              <v-row class="mx-2 my-0">
                <v-col cols="12" lg="4" class="Form-Columns-Dense">
                  <span class="text-h6 font-weight-regular">Basic Details</span>
                </v-col>

                <v-col cols="12" lg="8" class="Form-Columns-Dense text-right">
                  <!-- <v-btn
                    v-if="perm && perm.change"
                    small
                    @click="showPaymentReceiptForm = true"
                    color="primary"
                    class="mr-2"
                    depressed
                  >
                    Add Payment Receipt
                  </v-btn> -->
                  <v-btn
                    v-if="directDaDetails.customer"
                    small
                    @click="showCustomerAddressForm = true"
                    color="primary"
                    depressed
                  >
                    Add Address
                  </v-btn>
                </v-col>
                <!-- 
                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <InputField
                    type="text"
                    label="BOE Number/ Reference Number *"
                    :rules="[(val) => !!val || 'BOE Number is Required!']"
                    required
                    hide-details="auto"
                    v-model="directDaDetails.boe"
                    :error-messages="formErrors.boe"
                    @input="delete formErrors.boe"
                  />
                </v-col> -->

                <v-col cols="12" lg="4" class="Form-Columns-Dense">
                  <MultiSelect
                    label="Select Customer *"
                    readonly
                    :items="allCustomerList"
                    itemText="company_name"
                    itemValue="id"
                    :returnObject="true"
                    :rules="[(val) => !!val || 'Customer is Required!']"
                    hide-details="auto"
                    v-model="directDaDetails.customer"
                    :error-messages="formErrors.customer"
                    @change="
                      delete formErrors.customer;
                      customerPaymentReceipt();
                      directDaDetails.payment_receipt = null;
                    "
                  />
                </v-col>

                <v-col cols="12" lg="4" class="Form-Columns-Dense d-flex">
                  <MultiSelect
                    label="Payment Receipt"
                    :items="paymentReceiptList"
                    readonly
                    itemText="title"
                    itemValue="id"
                    :rules="[(val) => !!val || 'Payment Receipt is Required!']"
                    hide-details="auto"
                    returnObject
                    v-model="directDaDetails.payment_receipt"
                    :error-messages="formErrors.payment_receipt"
                    @change="
                      delete formErrors.payment_receipt;
                      setDaDetails();
                    "
                  />
                </v-col>

                <v-col cols="12" lg="4" class="Form-Columns-Dense">
                  <InputField
                    label="Job Type *"
                    readonly
                    :clearable="false"
                    class="grey lighten-3"
                    hide-details="auto"
                    v-model="directDaDetails.job_type"
                  />
                </v-col>

                <v-col cols="12" lg="4" class="Form-Columns-Dense">
                  <MultiSelect
                    label="Route *"
                    class="grey lighten-3"
                    :items="allLaneList"
                    itemText="title"
                    readonly
                    itemValue="id"
                    :rules="[(v) => !!v || 'Route is Required']"
                    hide-details="auto"
                    v-model="directDaDetails.lane"
                    :error-messages="formErrors.lane"
                    @input="delete formErrors.lane"
                  />
                </v-col>

                <v-col cols="12" lg="4" class="Form-Columns-Dense">
                  <InputField
                    type="text"
                    readonly
                    label="Boe/Reference Number *"
                    class="grey lighten-3"
                    :clearable="false"
                    hide-details="auto"
                    v-model="directDaDetails.boe"
                  />
                </v-col>

                <v-col cols="12" lg="4" class="Form-Columns-Dense">
                  <InputField
                    type="text"
                    readonly
                    class="grey lighten-3"
                    :clearable="false"
                    label="Rate *"
                    hide-details="auto"
                    v-model="paymentReceiptRate"
                  />
                </v-col>

                <v-col cols="12">
                  <v-row>
                    <v-col
                      v-if="
                        directDaDetails.payment_receipt &&
                        directDaDetails.payment_receipt.need_addresses_for &&
                        directDaDetails.payment_receipt.need_addresses_for
                          .source
                      "
                      cols="12"
                      lg="3"
                      class="Form-Columns-Dense"
                    >
                      <div class="d-flex">
                        <MultiSelect
                          :class="
                            (directDaDetails &&
                              directDaDetails.status &&
                              directDaDetails.status == 'Active') ||
                            directDaDetails.status == 'Completed'
                              ? 'grey lighten-3'
                              : ''
                          "
                          ref="`assigned_source_address"
                          label="Source Address *"
                          :items="allCustomerAddressList"
                          itemText="name"
                          itemValue="id"
                          :returnObject="true"
                          :rules="[
                            (val) => !!val || 'Source Address is Required!',
                          ]"
                          :item-disabled="
                            (item) => {
                              return item.id == midId;
                            }
                          "
                          :readonly="
                            (directDaDetails &&
                              directDaDetails.status &&
                              directDaDetails.status == 'Active') ||
                            directDaDetails.status == 'Completed'
                          "
                          hide-details="auto"
                          v-model="directDaDetails.assigned_source_address"
                          :error-messages="formErrors.assigned_source_address"
                          @change="
                            delete formErrors.assigned_source_address;
                            checkData();
                          "
                        />

                        <v-menu
                          open-on-hover
                          left
                          offset-y
                          v-if="directDaDetails.assigned_source_address"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-on="on" v-bind="attrs" class="mx-2">
                              mdi-information
                            </v-icon>
                          </template>
                          <v-card>
                            <v-card-title
                              class="secondary py-1 text-subtitle-1 white--text font-weight-medium"
                            >
                              Source Address Information
                            </v-card-title>
                            <v-card-text class="mt-2">
                              <table class="Normal-Table">
                                <tbody>
                                  <tr
                                    v-if="
                                      directDaDetails.assigned_source_address
                                        .address
                                    "
                                  >
                                    <th>Address</th>
                                    <th class="px-2">:</th>
                                    <td>
                                      {{
                                        directDaDetails.assigned_source_address
                                          .address
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </div>
                    </v-col>
                    <v-col
                      v-if="
                        directDaDetails.payment_receipt &&
                        directDaDetails.payment_receipt.need_addresses_for &&
                        directDaDetails.payment_receipt.need_addresses_for.mid
                      "
                      cols="12"
                      lg="3"
                      class="Form-Columns-Dense"
                    >
                      <div class="d-flex">
                        <MultiSelect
                          :class="
                            (directDaDetails &&
                              directDaDetails.status &&
                              directDaDetails.status == 'Active') ||
                            directDaDetails.status == 'Completed'
                              ? 'grey lighten-3'
                              : ''
                          "
                          label="Mid Point Address *"
                          :items="allCustomerAddressList"
                          itemText="name"
                          itemValue="id"
                          :returnObject="true"
                          :rules="[
                            (val) => !!val || 'Mid Point Address is Required!',
                          ]"
                          :item-disabled="
                            (item) => {
                              return (
                                item.id == sourceId || item.id == destinationId
                              );
                            }
                          "
                          :readonly="
                            (directDaDetails &&
                              directDaDetails.status &&
                              directDaDetails.status == 'Active') ||
                            directDaDetails.status == 'Completed'
                          "
                          hide-details="auto"
                          v-model="directDaDetails.assigned_mid_address"
                          :error-messages="formErrors.assigned_mid_address"
                          @change="
                            delete formErrors.assigned_mid_address;
                            checkData();
                          "
                        />

                        <v-menu
                          open-on-hover
                          left
                          offset-y
                          v-if="directDaDetails.assigned_mid_address"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-on="on" v-bind="attrs" class="mx-2">
                              mdi-information
                            </v-icon>
                          </template>
                          <v-card>
                            <v-card-title
                              class="secondary py-1 text-subtitle-1 white--text font-weight-medium"
                            >
                              Mid Point Address Information
                            </v-card-title>
                            <v-card-text class="mt-2">
                              <table class="Normal-Table">
                                <tbody>
                                  <tr
                                    v-if="
                                      directDaDetails.assigned_mid_address
                                        .address
                                    "
                                  >
                                    <th>Address</th>
                                    <th class="px-2">:</th>
                                    <td>
                                      {{
                                        directDaDetails.assigned_mid_address
                                          .address
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </div>
                    </v-col>

                    <v-col
                      v-if="
                        directDaDetails.payment_receipt &&
                        directDaDetails.payment_receipt.need_addresses_for &&
                        directDaDetails.payment_receipt.need_addresses_for
                          .destination
                      "
                      cols="12"
                      lg="3"
                      class="Form-Columns-Dense"
                    >
                      <div class="d-flex">
                        <MultiSelect
                          :class="
                            (directDaDetails &&
                              directDaDetails.status &&
                              directDaDetails.status == 'Active') ||
                            directDaDetails.status == 'Completed'
                              ? 'grey lighten-3'
                              : ''
                          "
                          label="Destination Address *"
                          :items="allCustomerAddressList"
                          itemText="name"
                          itemValue="id"
                          :returnObject="true"
                          :rules="[
                            (val) =>
                              !!val || 'Destination Address is Required!',
                          ]"
                          :item-disabled="
                            (item) => {
                              return item.id == midId;
                            }
                          "
                          hide-details="auto"
                          :readonly="
                            (directDaDetails &&
                              directDaDetails.status &&
                              directDaDetails.status == 'Active') ||
                            directDaDetails.status == 'Completed'
                          "
                          v-model="directDaDetails.assigned_destination_address"
                          :error-messages="
                            formErrors.assigned_destination_address
                          "
                          @change="
                            formErrors.assigned_destination_address = null;
                            checkData();
                          "
                        />

                        <v-menu
                          open-on-hover
                          left
                          offset-y
                          v-if="directDaDetails.assigned_destination_address"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-on="on" v-bind="attrs" class="mx-2">
                              mdi-information
                            </v-icon>
                          </template>
                          <v-card>
                            <v-card-title
                              class="secondary py-1 text-subtitle-1 white--text font-weight-medium"
                            >
                              Destination Address Information
                            </v-card-title>
                            <v-card-text class="mt-2">
                              <table class="Normal-Table">
                                <tbody>
                                  <tr
                                    v-if="
                                      directDaDetails
                                        .assigned_destination_address.address
                                    "
                                  >
                                    <th>Address</th>
                                    <th class="px-2">:</th>
                                    <td>
                                      {{
                                        directDaDetails
                                          .assigned_destination_address.address
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </div>
                    </v-col>
                    <v-col cols="12" lg="3" class="Form-Columns-Dense">
                      <MultiSelect
                        :class="
                          (directDaDetails &&
                            directDaDetails.status &&
                            directDaDetails.status == 'Active') ||
                          directDaDetails.status == 'Completed'
                            ? 'grey lighten-3'
                            : ''
                        "
                        label="Select Milestones"
                        :items="milestoneList"
                        required
                        itemText="milestone_name"
                        itemValue="id"
                        :multiple="true"
                        :chips="true"
                        hide-details="auto"
                        :readonly="
                          (directDaDetails &&
                            directDaDetails.status &&
                            directDaDetails.status == 'Active') ||
                          directDaDetails.status == 'Completed'
                        "
                        v-model="directDaDetails.milestones"
                        :error-messages="formErrors.milestones"
                        @change="delete formErrors.milestones"
                      ></MultiSelect>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <v-switch
                    :label="`Genset : ${directDaDetails.has_genset}`"
                    true-value="Yes"
                    false-value="No"
                    hide-details="auto"
                    :readonly="
                      (directDaDetails &&
                        directDaDetails.status &&
                        directDaDetails.status == 'Active') ||
                      directDaDetails.status == 'Completed'
                    "
                    v-model="directDaDetails.has_genset"
                    :error-messages="formErrors.has_genset"
                    @input="delete formErrors.has_genset"
                    class="mt-1"
                  />
                </v-col>

                <v-col cols="12" lg="2">
                  <v-row>
                    <v-col cols="12" lg="9" class="Form-Columns-Dense">
                      <v-switch
                        :label="`Hazmat : ${directDaDetails.is_hazmat}`"
                        true-value="Yes"
                        false-value="No"
                        hide-details="auto"
                        :readonly="
                          (directDaDetails &&
                            directDaDetails.status &&
                            directDaDetails.status == 'Active') ||
                          directDaDetails.status == 'Completed'
                        "
                        v-model="directDaDetails.is_hazmat"
                        :error-messages="formErrors.is_hazmat"
                        @input="delete formErrors.is_hazmat"
                        class="mt-1"
                      />
                    </v-col>
                    <v-col cols="12" lg="3" class="Form-Columns-Dense">
                      <v-btn
                        icon
                        class="red--text"
                        v-if="directDaDetails.is_hazmat == 'Yes'"
                        @click="showHazardousDialog = true"
                      >
                        <v-icon>mdi-alert</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <MultiSelect
                    :class="
                      (directDaDetails &&
                        directDaDetails.status &&
                        directDaDetails.status == 'Active') ||
                      directDaDetails.status == 'Completed'
                        ? 'grey lighten-3'
                        : ''
                    "
                    label="Currency *"
                    :items="currencyList"
                    itemText="currency_code"
                    itemValue="currency_code"
                    :rules="[(val) => !!val || 'Currency is Required!']"
                    hide-details="auto"
                    :readonly="
                      (directDaDetails &&
                        directDaDetails.status &&
                        directDaDetails.status == 'Active') ||
                      directDaDetails.status == 'Completed'
                    "
                    v-model="directDaDetails.currency"
                    :error-messages="formErrors.currency"
                    @change="delete formErrors.currency"
                  ></MultiSelect>
                </v-col>

                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <DateField
                    :inputProps="{
                      dense: true,
                      label: 'Movement Date *',
                      rules: [(val) => !!val || 'Movement Date is Required!'],
                      'hide-details': 'auto',
                      'error-messages': formErrors.movement_date,
                      class:
                        (directDaDetails &&
                          directDaDetails.status &&
                          directDaDetails.status == 'Active') ||
                        directDaDetails.status == 'Completed'
                          ? 'grey lighten-3'
                          : '',
                    }"
                    required
                    :readonly="
                      (directDaDetails &&
                        directDaDetails.status &&
                        directDaDetails.status == 'Active') ||
                      directDaDetails.status == 'Completed'
                    "
                    v-model="directDaDetails.movement_date"
                    @input="delete formErrors.movement_date"
                  />
                </v-col>

                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <InputField
                    :class="
                      (directDaDetails &&
                        directDaDetails.status &&
                        directDaDetails.status == 'Active') ||
                      directDaDetails.status == 'Completed'
                        ? 'grey lighten-3'
                        : ''
                    "
                    type="time"
                    label="Movement Time *"
                    :rules="[(val) => !!val || 'Movement Time is Required!']"
                    required
                    hide-details="auto"
                    :readonly="
                      (directDaDetails &&
                        directDaDetails.status &&
                        directDaDetails.status == 'Active') ||
                      directDaDetails.status == 'Completed'
                    "
                    v-model="directDaDetails.movement_time"
                    :error-messages="formErrors.movement_time"
                    @input="delete formErrors.movement_time"
                  />
                </v-col>

                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <MultiSelect
                    label="Select Branch *"
                    :items="allBranchList"
                    itemText="branch_title"
                    itemValue="id"
                    :rules="[(val) => !!val || 'Branch is Required!']"
                    hide-details="auto"
                    v-model="directDaDetails.branch"
                    :error-messages="formErrors.branch"
                    @input="delete formErrors.branch"
                  ></MultiSelect>
                </v-col>

                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <MultiSelect
                    label="Select Depot *"
                    :items="allDepotList"
                    itemText="depot_name"
                    itemValue="id"
                    :rules="[(val) => !!val || 'Depot is Required!']"
                    hide-details="auto"
                    v-model="directDaDetails.depot"
                    :error-messages="formErrors.depot"
                    @input="delete formErrors.depot"
                  ></MultiSelect>
                </v-col>

                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <InputField
                    :class="
                      (directDaDetails &&
                        directDaDetails.status &&
                        directDaDetails.status == 'Active') ||
                      directDaDetails.status == 'Completed'
                        ? 'grey lighten-3'
                        : ''
                    "
                    type="text"
                    label="Purchase Order"
                    hide-details="auto"
                    :readonly="
                      (directDaDetails &&
                        directDaDetails.status &&
                        directDaDetails.status == 'Active') ||
                      directDaDetails.status == 'Completed'
                    "
                    v-model="directDaDetails.po_no"
                    :error-messages="formErrors.po_no"
                    @input="delete formErrors.po_no"
                  />
                </v-col>

                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <InputField
                    :class="
                      (directDaDetails &&
                        directDaDetails.status &&
                        directDaDetails.status == 'Active') ||
                      directDaDetails.status == 'Completed'
                        ? 'grey lighten-3'
                        : ''
                    "
                    type="text"
                    label="Shipment Number"
                    hide-details="auto"
                    :readonly="
                      (directDaDetails &&
                        directDaDetails.status &&
                        directDaDetails.status == 'Active') ||
                      directDaDetails.status == 'Completed'
                    "
                    v-model="directDaDetails.shipment_no"
                    :error-messages="formErrors.shipment_no"
                    @input="delete formErrors.shipment_no"
                  />
                </v-col>

                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <InputField
                    :class="
                      (directDaDetails &&
                        directDaDetails.status &&
                        directDaDetails.status == 'Active') ||
                      directDaDetails.status == 'Completed'
                        ? 'grey lighten-3'
                        : ''
                    "
                    type="text"
                    label="Vessel Number"
                    hide-details="auto"
                    :readonly="
                      (directDaDetails &&
                        directDaDetails.status &&
                        directDaDetails.status == 'Active') ||
                      directDaDetails.status == 'Completed'
                    "
                    v-model="directDaDetails.vessel_no"
                    :error-messages="formErrors.vessel_no"
                    @input="delete formErrors.vessel_no"
                  />
                </v-col>

                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <v-switch
                    hide-details="auto"
                    label="Sub Hire"
                    true-value="Yes"
                    false-value="No"
                    class="mt-1"
                    :readonly="
                      (directDaDetails &&
                        directDaDetails.status &&
                        directDaDetails.status == 'Active') ||
                      directDaDetails.status == 'Completed'
                    "
                    v-model="directDaDetails.is_sub_hire"
                    :error-messages="formErrors.is_sub_hire"
                    @change="delete formErrors.is_sub_hire"
                  ></v-switch>
                </v-col>
              </v-row>

              <v-col cols="12">
                <div class="d-flex">
                  <span class="text-h6 font-weight-regular">
                    Container Details
                  </span>
                  <v-spacer />
                  <!-- <v-btn
                    color="primary"
                    depressed
                    small
                    @click="
                      directDaDetails.delivery_advice_containers.push({});
                      formFieldHints.push({});
                    "
                    :disabled="
                      directDaDetails.delivery_advice_containers.length >= 4
                    "
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn> -->
                </div>
                <v-divider class="mt-1" />
              </v-col>

              <v-col cols="12">
                <v-row
                  v-for="(con, i) in directDaDetails.delivery_advice_containers"
                  :key="i"
                >
                  <v-col cols="1" class="d-flex align-center">
                    <span class="text-h6 font-weight-regular">
                      # {{ i + 1 }}
                    </span>
                  </v-col>

                  <v-col cols="10">
                    <v-row>
                      <v-col cols="12" lg="4" class="Form-Columns-Dense">
                        <InputField
                          type="text"
                          placeholder="ABCD1234567"
                          readonly
                          class="grey lighten-3"
                          :clearable="false"
                          label="Container Number *"
                          :rules="[
                            (v) => !!v || 'Container Number is Required',
                          ]"
                          hide-details="auto"
                          persistent-hint
                          :hint="formFieldHints[i].container_no"
                          v-model="
                            directDaDetails.delivery_advice_containers[i]
                              .container_no
                          "
                        />
                      </v-col>
                      <v-col cols="12" lg="4" class="Form-Columns-Dense">
                        <MultiSelect
                          label="Container Type *"
                          readonly
                          class="grey lighten-3"
                          :clearable="false"
                          :items="allContainersType"
                          itemText="title"
                          itemValue="id"
                          :rules="[(v) => !!v || 'Container Type is Required']"
                          hide-details="auto"
                          v-model="
                            directDaDetails.delivery_advice_containers[i][
                              'container_type'
                            ]
                          "
                        />
                      </v-col>

                      <v-col cols="12" lg="4" class="Form-Columns-Dense">
                        <InputField
                          type="number"
                          label="Container Weight (Tons) *"
                          readonly
                          class="grey lighten-3"
                          :clearable="false"
                          aria-placeholder=""
                          step=".01"
                          :rules="[
                            (v) => !!v || 'Container Weight is Required',
                            (v) =>
                              v > 0 ||
                              'Container Weight can not be less than 0',
                            (v) =>
                              $globals.containerWeightFormatRegExp.test(v) ||
                              'Container Weight must have the format 00.00',
                          ]"
                          hide-details="auto"
                          v-model="
                            directDaDetails.delivery_advice_containers[i].weight
                          "
                        />
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col
                    cols="12"
                    lg="1"
                    class="d-flex align-center justify-end"
                  >
                    <v-btn
                      color="secondary"
                      depressed
                      small
                      disabled
                      @click="removeContainer(i)"
                    >
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" class="mt-6">
                <v-btn color="primary" @click="stepper = 2" :disabled="!valid1">
                  Next
                </v-btn>
              </v-col>
              <v-card>
                <v-card-text class="pa-2 mt-1">
                  <AgGridVue
                    style="width: 100%; height: 300px"
                    class="ag-theme-alpine"
                    id="myChargeGrid"
                    :columnDefs="chargeHeaders"
                    :grid-options="chargeGridOptions"
                    :suppressDragLeaveHidesColumns="true"
                    :rowData="allChargeList"
                  ></AgGridVue>
                </v-card-text>
              </v-card>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step="2" class="pa-0">
            <v-form ref="stepperForm3" v-model="valid3">
              <v-row class="mx-2 my-0">
                <v-col cols="12" class="Form-Columns-Dense">
                  <span class="text-h6 font-weight-regular">
                    Upload Documents
                  </span>
                </v-col>

                <v-col cols="12" sm="6" md="4" class="Form-Columns-Dense">
                  <InputField
                    type="text"
                    label="Document Type"
                    hide-details="auto"
                    v-model="document_type"
                    :error-messages="formErrors.document_type"
                    @input="delete formErrors.document_type"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  lg="7"
                  class="Form-Columns-Dense"
                >
                  <v-file-input
                    label="Document"
                    outlined
                    clearable
                    show-size
                    dense
                    :rules="[
                      (file) =>
                        !file ||
                        file.size < 2e6 ||
                        'File size should be less than 2 MB!',
                    ]"
                    accept="image/x-png,image/jpg,image/jpeg,application/pdf"
                    hide-details="auto"
                    v-model="document"
                    :error-messages="formErrors.document"
                    @input="delete formErrors.document"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  lg="1"
                  class="Form-Columns-Dense"
                >
                  <v-btn
                    color="primary"
                    depressed
                    :disabled="!document || !document_type"
                    @click="addDocument"
                  >
                    Add
                  </v-btn>
                </v-col>

                <v-col cols="12" class="Form-Columns-Dense my-6">
                  <v-btn color="secondary" depressed @click="stepper = 1">
                    Previous
                  </v-btn>
                </v-col>

                <v-col
                  v-show="
                    directDaDetails.da_documents &&
                    directDaDetails.da_documents.length
                  "
                  cols="12"
                  class="px-2 pt-2 pb-0"
                >
                  <span class="text-h6 font-weight-regular"> Uploaded </span>
                </v-col>

                <v-col
                  v-show="
                    directDaDetails.da_documents &&
                    directDaDetails.da_documents.length
                  "
                  cols="12"
                  class="pa-2"
                >
                  <v-card elevation="0" outlined>
                    <v-card-text class="py-1 px-0">
                      <v-row
                        class="ma-0"
                        v-for="(data, i) in directDaDetails.da_documents"
                        :key="i"
                      >
                        <v-col cols="12" lg="5" class="py-2">
                          <a :href="data.document" target="_black">
                            <span>{{ data.document_type }}</span>
                          </a>
                        </v-col>

                        <v-col cols="12" lg="3" class="py-2">
                          Created on : {{ data.created | formatDate(true) }}
                        </v-col>

                        <v-col cols="12" lg="3" class="py-2">
                          Added By : {{ data.added_by }}
                        </v-col>

                        <v-col cols="12" lg="1" class="text-right py-2">
                          <v-icon
                            small
                            color="red"
                            @click="deleteDocument(data, i)"
                          >
                            mdi-close
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col
                  v-show="daDocuments && daDocuments.length"
                  cols="12"
                  class="px-2 pt-2 pb-0"
                >
                  <span class="text-h6 font-weight-regular"> New Upload </span>
                </v-col>

                <v-col
                  v-show="daDocuments && daDocuments.length"
                  cols="12"
                  class="pa-2"
                >
                  <v-card elevation="0" outlined>
                    <v-card-text class="py-1 px-0">
                      <v-row
                        class="ma-0"
                        v-for="(data, i) in daDocuments"
                        :key="i"
                      >
                        <v-col cols="12" lg="5" class="py-2">
                          <a :href="generateUrl(data.document)" target="_black">
                            <span>{{ data.document_type }}</span>
                          </a>
                        </v-col>

                        <v-col cols="12" lg="5" class="py-2">
                          <a :href="generateUrl(data.document)" target="_black">
                            <span>{{ data.document.name }}</span>
                          </a>
                        </v-col>

                        <v-col cols="6" lg="1" class="py-2">
                          <span>{{ getSize(data.document.size) }}</span>
                        </v-col>

                        <v-col cols="6" lg="1" class="text-right py-2">
                          <v-icon small color="primary" @click="removeFile(i)">
                            mdi-close
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <v-dialog v-model="CustomerPaymentReceiptDetails" width="800">
        <v-card v-if="directDaDetails.payment_receipt">
          <v-card-title class="secondary white--text px-4 py-2">
            Payment Receipt -
            {{ paymentReceiptDetailView.receipt_id }}

            <v-spacer></v-spacer>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="white"
                  icon
                  @click="CustomerPaymentReceiptDetails = false"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon class="white--text">mdi-close</v-icon>
                </v-btn>
              </template>
              <span>Close</span>
            </v-tooltip>
          </v-card-title>

          <v-card-text class="heading pa-2">
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    lg="auto"
                    v-if="paymentReceiptDetailView.reference_number"
                  >
                    <span class="text-caption">Reference Number</span>
                    <p class="text-subtitle-1 font-weight-medium mb-0">
                      {{ paymentReceiptDetailView.reference_number }}
                    </p>
                  </v-col>
                  <v-divider vertical class="mx-3"></v-divider>

                  <v-col
                    cols="12"
                    lg="auto"
                    v-if="paymentReceiptDetailView.company_name"
                  >
                    <span class="text-caption">Customer Name</span>
                    <p class="text-subtitle-1 font-weight-medium mb-0">
                      {{ paymentReceiptDetailView.company_name }}
                    </p>
                  </v-col>
                  <v-divider vertical class="mx-3"></v-divider>

                  <v-col
                    cols="12"
                    lg="auto"
                    v-if="paymentReceiptDetailView.lane_title"
                  >
                    <span class="text-caption">Lane</span>
                    <p class="text-subtitle-1 font-weight-medium mb-0">
                      {{ paymentReceiptDetailView.lane_title }}
                    </p>
                  </v-col>
                  <v-divider vertical class="mx-3"></v-divider>

                  <v-col
                    cols="12"
                    lg="auto"
                    v-if="paymentReceiptDetailView.job_type"
                  >
                    <span class="text-caption">Job Type</span>
                    <p class="text-subtitle-1 font-weight-medium mb-0">
                      {{ paymentReceiptDetailView.job_type }}
                    </p>
                  </v-col>
                  <v-divider vertical class="mx-3"></v-divider>

                  <v-col
                    cols="12"
                    lg="auto"
                    v-if="paymentReceiptDetailView.movement_date"
                  >
                    <span class="text-caption">Movement Date</span>
                    <p class="text-subtitle-1 font-weight-medium mb-0">
                      {{ paymentReceiptDetailView.movement_date }}
                    </p>
                  </v-col>
                  <v-divider vertical class="mx-3"></v-divider>

                  <v-col
                    cols="12"
                    lg="auto"
                    v-if="paymentReceiptDetailView.rate"
                  >
                    <span class="text-caption">Rate</span>
                    <p class="text-subtitle-1 font-weight-medium mb-0">
                      {{ paymentReceiptDetailView.rate }}
                    </p>
                  </v-col>
                  <v-divider vertical class="mx-3"></v-divider>

                  <v-col
                    cols="12"
                    lg="auto"
                    v-if="paymentReceiptDetailView.tax_percentage"
                  >
                    <span class="text-caption">Tax Percentage</span>
                    <p class="text-subtitle-1 font-weight-medium mb-0">
                      {{ paymentReceiptDetailView.tax_percentage }}
                    </p>
                  </v-col>
                  <v-divider vertical class="mx-3"></v-divider>

                  <v-col
                    cols="12"
                    lg="auto"
                    v-if="paymentReceiptDetailView.payment_mode"
                  >
                    <span class="text-caption">Payment Mode</span>
                    <p class="text-subtitle-1 font-weight-medium mb-0">
                      {{ paymentReceiptDetailView.payment_mode }}
                    </p>
                  </v-col>
                  <v-divider vertical class="mx-3"></v-divider>

                  <v-col
                    cols="12"
                    lg="auto"
                    v-if="paymentReceiptDetailView.collected_by"
                  >
                    <span class="text-caption">Collected By</span>
                    <p class="text-subtitle-1 font-weight-medium mb-0">
                      {{ paymentReceiptDetailView.collected_by }}
                    </p>
                  </v-col>
                  <v-divider
                    vertical
                    class="mx-3"
                    v-if="paymentReceiptDetailView.collected_by"
                  ></v-divider>

                  <v-col
                    cols="12"
                    lg="auto"
                    v-if="paymentReceiptDetailView.container_details"
                  >
                    <span class="text-caption">Container Details</span>
                    <p class="text-subtitle-1 font-weight-medium mb-0">
                      {{ paymentReceiptDetailView.container_details }}
                    </p>
                  </v-col>
                  <v-divider vertical class="mx-3"></v-divider>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card-text>

          <!-- <v-card-text class="pa-2 mt-1">
            <AgGridVue
              style="width: 100%; height: 300px"
              class="ag-theme-alpine"
              id="myChargeGrid"
              :columnDefs="chargeHeaders"
              :grid-options="chargeGridOptions"
              :suppressDragLeaveHidesColumns="true"
              :rowData="allChargeList"
            ></AgGridVue>
          </v-card-text> -->
        </v-card>
      </v-dialog>
      <CustomerAddressForm
        v-model="showCustomerAddressForm"
        :customerId="directDaDetails.customer"
        module="Customer"
        @closeAddressForm="showCustomerAddressForm = false"
        @refreshList="getCustomerAddressList"
      />
      <!-- <PaymentReceiptForm
        v-model="showPaymentReceiptForm"
        :companyId="directDaDetails.customer"
        @refreshList="getPaymentReceiptList"
      /> -->
      <AdminDAHazardousForm
        v-model="showHazardousDialog"
        dialogTitle="DA Hazardous Cargo"
        :daHazardousFormDetails="daHazardousFormDetails"
        @saveDAHazardousForm="saveDAHazardousForm"
      />
    </template>
  </FormComponent>
</template>

<script>
import FormComponent from "@/components/General/FormComponent.vue";
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import Tooltip from "@/components/General/Tooltip.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";
import CustomerAddressForm from "@/components/ModuleBased/dialogForms/CustomerAddressForm.vue";
import AdminDAHazardousForm from "@/components/ModuleBased/dialogForms/AdminDAHazardousForm.vue";
import { AgGridVue } from "ag-grid-vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    FormComponent,
    InputField,
    MultiSelect,
    Tooltip,
    DateField,
    AdminDAHazardousForm,
    CustomerAddressForm,
    AgGridVue,
  },

  data() {
    return {
      showHazardousDialog: false,
      CustomerPaymentReceiptDetails: false,
      hazardousId: null,
      daHazardousFormDetails: {},
      getListCalled: false,

      formFieldHints: [{}],
      showPaymentReceiptForm: false,
      showCustomerAddressForm: false,
      deliveryAdviceChargeDetails: {},

      directDaDetails: {
        has_genset: "No",
        is_hazmat: "No",
        is_sub_hire: "No",
        payment_term: "Cash",
        delivery_advice_containers: [{}],
        milestones: [],
      },

      formErrors: {},

      allDepotList: [],
      allBranchList: [],
      allCustomerAddressList: [],
      allContainersType: [],
      allCustomerList: [],
      allJobType: [],
      allLaneList: [],

      milestoneList: [],
      paymentTerms: [],
      currencyList: [],
      paymentReceiptList: [],
      paymentReceiptDetailView: [],

      paymentReceiptRefferenceNumber: null,
      paymentReceiptRate: null,

      document_type: null,
      document: null,
      daDocuments: [],

      stepper: "1",
      valid1: true,
      valid2: true,
      valid3: true,
      jobType: null,
      perm: null,

      backRoute: { path: "/app/admin/delivery-advice/list" },
      today: new Date().toISOString().slice(0, 10),
      currentDateTime: new Date(),

      customerAddressListTimeout: null,
      customerContractListTimeout: null,
      jobTypeListTimeout: null,
      validateDaContainer: null,
      daContainerUpdate: false,

      gridOptions: {
        defaultColDef: {
          resizable: true,
          flex: 1,
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },
      // charge ag-grid vars
      allChargeList: [],

      chargeHeaders: [
        {
          headerName: "Charge Title",
          field: "charge_title",
          minWidth: 150,
        },
        {
          headerName: "Revenue GL No.",
          field: "gl_no",
          minWidth: 150,
        },
        {
          headerName: `Rate (${this.$globals.currency})`,
          field: "rate",
          minWidth: 100,
        },
        {
          headerName: "TAX(%)",
          field: "tax_percentage",
          minWidth: 100,
        },
        {
          headerName: "Description.",
          field: "description",
          minWidth: 150,
        },
      ],
      chargeGridApi: null,
      chargeGridColumnApi: null,

      chargeGridOptions: {
        defaultColDef: {
          resizable: true,
          flex: 1,
        },

        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
      },
      sourceId: null,
      midId: null,
      destinationId: null,
    };
  },

  computed: {
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (!obj) {
        return null;
      } else {
        return obj;
      }
    },

    allFormValidation() {
      return this.valid1 && this.valid2 && this.valid3;
    },
  },
  watch: {
    directDaDetails: {
      handler() {},
      deep: true,
    },
  },
  methods: {
    getEditDaDetails(changeKey) {
      this.$bus.$emit("showLoader", true);
      let payload = {
        id: this.directDaDetails.id,
        da_change: changeKey,
      };
      this.$api.deliveryAdvice
        .prevNextEditDa(payload)
        .then((res) => {
          this.editviewDeliveryAdvicesDetails(res.data);
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "DA not found!",
            color: "error",
          });
        });
    },

    getLists(apis) {
      this.$bus.$emit("showLoader", true);

      return new Promise((resolve, reject) => {
        Promise.all(apis)
          .then((val) => {
            this.getListCalled = false;
            this.$bus.$emit("showLoader", false);
            resolve();
          })
          .catch((err) => {
            this.getListCalled = false;
            this.$bus.$emit("showLoader", false);
            reject();
          });
      });
    },
    editviewDeliveryAdvicesDetails(data) {
      if (data && data.payment_term == "Cash") {

        this.$router.push({
          path: `/app/admin/delivery-advice/cash-da-edit/${data.da_id}`,
          query: {
            backRoute: `/app/admin/delivery-advice/list`,
          },
        });
      } else {
        this.$router.push({
          path: `/app/admin/delivery-advice/direct-da-edit/${data.order_id}`,
          query: {
            backRoute: `/app/admin/delivery-advice/list`,
          },
        });
      }

      setTimeout(() => {
        this.permissions = getPermissions("delivery_advice");
        this.getDepotList();
        this.getCurrencyList();
        this.getCustomerAddressList();

        this.getCustomerList();
        this.getContainersType();
        this.getBranchList();
        this.getMilestoneList();
        this.getJobTypeOptions();
        if (data.payment_term == "Cash") {
          this.getDirectCashDeliveryAdviceObject(this.$route.params.id);
        }
      }, 20);
    },
    checkData() {
      if (this.directDaDetails.assigned_source_address) {
        if (typeof this.directDaDetails.assigned_source_address == typeof {}) {
          this.sourceId = this.directDaDetails.assigned_source_address.id;
        }
      }
      if (
        this.directDaDetails.assigned_mid_address &&
        typeof this.directDaDetails.assigned_mid_address == typeof {}
      ) {
        this.midId = this.directDaDetails.assigned_mid_address.id;
      }
      if (this.directDaDetails.assigned_destination_address) {
        if (
          typeof this.directDaDetails.assigned_destination_address == typeof {}
        ) {
          this.destinationId =
            this.directDaDetails.assigned_destination_address.id;
        }
      }
    },
    paymentReceiptDetail() {
      let paymentReceiptId = null;
      if (
        this.directDaDetails.payment_receipt &&
        typeof this.directDaDetails.payment_receipt == typeof {}
      ) {
        paymentReceiptId = this.directDaDetails.payment_receipt.id;
      } else {
        paymentReceiptId = this.directDaDetails.payment_receipt;
      }
      this.getPaymentReceiptDetail(paymentReceiptId);
    },
    checkDaContainerUpdate() {
      this.daContainerUpdate = true;
    },
    customerPaymentReceipt() {
      this.getPaymentReceiptList();
    },
    setDaDetails() {
      let daPaymentReceiptLane = {
        id:
          this.directDaDetails.payment_receipt &&
          this.directDaDetails.payment_receipt.lane,
        title:
          this.directDaDetails.payment_receipt &&
          this.directDaDetails.payment_receipt.lane_title,
      };

      this.allLaneList.push(daPaymentReceiptLane);

      this.directDaDetails.job_type =
        this.directDaDetails.payment_receipt &&
        this.directDaDetails.payment_receipt.job_type;
      this.directDaDetails.lane =
        this.directDaDetails.payment_receipt &&
        this.directDaDetails.payment_receipt.lane;

      this.getCustomerAddressList();
    },
    getPaymentReceiptList(params = {}) {
      params = {
        ...params,
        da: this.$route.params.id,
      };

      if (typeof this.directDaDetails.customer == typeof {}) {
        params.company = this.directDaDetails.customer.id;
      } else {
        params.company = this.directDaDetails.customer;
      }

      this.$api.deliveryAdvice
        .paymentReceiptsList(params)
        .then((res) => {
          this.paymentReceiptList = res.data;
          this.getMilestoneList();

          const paymentReceiptObj = this.paymentReceiptList.find((payment) => {
            return payment.id == this.directDaDetails.payment_receipt;
          });
          this.directDaDetails.payment_receipt = paymentReceiptObj;
          this.paymentReceiptRefferenceNumber =
            this.directDaDetails.payment_receipt.reference_number;

          this.paymentReceiptRate = this.directDaDetails.payment_receipt.rate;

          this.allChargeList =
            this.directDaDetails.payment_receipt.payment_receipt_charges;
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't Fetch payment receipt list!",
            color: "error",
          });
        });
    },
    getPaymentReceiptDetail(id) {
      return this.$api.paymentReceipt
        .getPaymentReceiptDetails(id)
        .then((res) => {
          this.paymentReceiptDetailView = res.data;
          this.allChargeList = res.data.payment_receipt_charges;
          this.CustomerPaymentReceiptDetails = true;
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't Fetch payment receipt detail!",
            color: "error",
          });
        });
    },

    addDAHazardousCargo(daID) {
      let obj = this.daHazardousFormDetails;
      function isEmptyObject(obj) {
        return JSON.stringify(obj) === "{}";
      }
      if (isEmptyObject(obj)) {
        return;
      }
      let payload = { ...this.daHazardousFormDetails, delivery_advice: daID };
      this.$api.daHazardousCargo
        .addDAHazardousCargo(payload)
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    },

    saveDAHazardousForm(data) {
      this.daHazardousFormDetails = { ...data };
    },

    resetForm() {
      this.directDaDetails = {
        has_genset: "No",
        is_hazmat: "No",
        delivery_advice_containers: [{}],
        milestones: [],
        customer: {},
      };
      this.formErrors = {
        delivery_advice_containers: [{}],
        milestones: [],
        customer: [],
      };
      this.$refs.stepperForm1.resetValidation();
      this.$refs.stepperForm2.resetValidation();
    },

    checkCustomer() {
      delete this.directDaDetails.payment_receipt;
      if (this.directDaDetails && this.directDaDetails.customer) {
        let customer = {
          ...this.directDaDetails.customer,
        };
        this.directDaDetails = {
          ...this.directDaDetails,
          currency: customer.currency,
        };
      }
    },

    checkFormErrors() {
      if (
        this.formErrors &&
        this.formErrors.delivery_advice_containers &&
        (this.formErrors.delivery_advice_containers.length == 0 ||
          this.formErrors.delivery_advice_containers.every(
            (obj) => !obj || Object.keys(obj).length == 0
          ))
      ) {
        delete this.formErrors.delivery_advice_containers;
      }
    },

    addDocument() {
      this.daDocuments.push({
        document_type: this.document_type,
        document: this.document,
      });
      this.document = null;
      this.document_type = null;
    },

    removeFile(index) {
      this.daDocuments.splice(index, 1);
    },

    generateUrl(file) {
      return URL.createObjectURL(file);
    },

    getSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },

    async uploadDADocuments(id) {
      let i = 0;
      while (i < this.daDocuments.length) {
        let payload = {
          id: id,
          data: null,
        };
        let newForm = new FormData();
        newForm.append("document_type", this.daDocuments[i].document_type);
        newForm.append("document", this.daDocuments[i].document);
        payload.data = newForm;
        await this.$api.deliveryAdvice.uploadDeliveryAdviceDocument(payload);
        i++;
      }
    },

    deleteDocument(document, index) {
      if (
        confirm(`Are you sure you want to delete ${document.document_type} ?`)
      ) {
        this.$bus.$emit("showLoader", true);
        this.$api.deliveryAdvice
          .deleteDeliveryAdviceDocument(document.id)
          .then((res) => {
            this.$bus.$emit("showToastMessage", {
              message: "Document deleted successfully!",
              color: "success",
            });
            this.directDaDetails.da_documents.splice(index, 1);
            this.$bus.$emit("showLoader", false);
          })
          .catch((err) => {
            console.error(err);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't delete document!",
              color: "error",
            });
            this.$bus.$emit("showLoader", false);
          });
      }
    },

    updateDAHazardousCargo() {
      this.$api.daHazardousCargo
        .updateDAHazardousCargo({
          id: this.hazardousId,
          data: this.daHazardousFormDetails,
        })
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    },

    submitForm() {
      const payload = {
        ...this.directDaDetails,
      };

      let oldData = this.validateDaContainer;

      let newData = payload.delivery_advice_containers;

      let oldDataIdCount = oldData.length;
      let newDataIdCount = 0;
      if (oldData.length == newData.length) {
        for (let i = 0; i < newData.length; i++) {
          if (newData[i].hasOwnProperty("id")) {
            newDataIdCount += 1;
          }
        }
        if (oldDataIdCount != newDataIdCount) {
          this.daContainerUpdate = true;
        } else {
          this.daContainerUpdate = false;
        }
      } else {
        this.daContainerUpdate = true;
      }

      payload.are_containers_update = this.daContainerUpdate;

      if (
        payload.assigned_destination_address &&
        typeof payload.assigned_destination_address == typeof {}
      ) {
        payload.assigned_destination_address =
          payload.assigned_destination_address.id;
      } else {
        payload.assigned_destination_address =
          payload.assigned_destination_address;
      }

      if (
        payload.assigned_source_address &&
        typeof payload.assigned_source_address == typeof {}
      ) {
        payload.assigned_source_address = payload.assigned_source_address.id;
      } else {
        payload.assigned_source_address = payload.assigned_source_address;
      }

      if (
        payload.assigned_mid_address &&
        typeof payload.assigned_mid_address == typeof {}
      ) {
        payload.assigned_mid_address = payload.assigned_mid_address.id;
      } else {
        payload.assigned_mid_address = payload.assigned_mid_address;
      }

      if (
        payload.payment_receipt &&
        typeof payload.payment_receipt == typeof {}
      ) {
        payload.payment_receipt = payload.payment_receipt.id;
      }

      if (payload.customer && typeof payload.customer == typeof {}) {
        payload.customer = payload.customer.id;
      } else {
        payload.customer = payload.customer;
      }

      payload.delivery_advice_containers.forEach((element, i) => {
        element.lane = payload.lane;
        element.assigned_source_address = payload.assigned_source_address;
        element.assigned_destination_address =
          payload.assigned_destination_address;
        element.assigned_mid_address = payload.assigned_mid_address;
        element.movement_date = payload.movement_date;
        element.movement_time = payload.movement_time;
        element.job_type = payload.job_type;
      });

      this.$bus.$emit("showLoader", true);

      this.$api.deliveryAdvice
        .updateDirectCashDeliveryAdvice(payload)
        .then((res) => {
          localStorage.removeItem("addDirectDaDetails");

          if (this.daDocuments.length > 0) {
            this.uploadDADocuments(res.data.id);
          }

          if (
            payload &&
            payload.is_hazmat == "Yes" &&
            this.daHazardousFormDetails
          ) {
            if (
              this.directDaDetails &&
              this.directDaDetails.da_hazardous_cargo_id
            ) {
              this.updateDAHazardousCargo();
            } else {
              this.addDAHazardousCargo(this.directDaDetails.id);
            }
          }

          this.$bus.$emit("showLoader", false);

          this.$bus.$emit("showToastMessage", {
            message: "Delivery Advice added successfully!",
            color: "success",
          });

          this.$router.push(`/app/admin/trip/list`);
        })
        .catch((err) => {
          if (err.data) {
            this.formErrors = err.data;
          }
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't Add Delivery Advice!",
            color: "error",
          });
        });
    },

    getDeliveryAdviceOptions() {
      this.$api.deliveryAdvice
        .getDeliveryAdviceOptions()
        .then((res) => {
          this.paymentTerms = res.data.actions.POST.payment_term.choices;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    getContainersType() {
      return this.$api.containerType
        .getContainerTypeSelectList({ limit: "all" })
        .then((res) => {
          this.allContainersType = res.data;
        })
        .catch(() => {
          this.$bus.$emit("showLoader", false);
        });
    },

    getCurrencyList() {
      return this.$api.currency
        .getCurrencySelectList({ limit: "all" })
        .then((res) => {
          this.currencyList = res.data;

          const currency = this.currencyList.find((c) => {
            return c.currency_code == this.$globals.currency;
          });

          if (currency) {
            this.directDaDetails.currency = currency.currency_code;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    setCustomerAddresses() {
      this.directDaDetails.assigned_source_address =
        this.allCustomerAddressList.find(
          (obj) => obj.id == this.directDaDetails.assigned_source_address
        );
      this.directDaDetails.assigned_mid_address =
        this.allCustomerAddressList.find(
          (obj) => obj.id == this.directDaDetails.assigned_mid_address
        );
      this.directDaDetails.assigned_destination_address =
        this.allCustomerAddressList.find(
          (obj) => obj.id == this.directDaDetails.assigned_destination_address
        );
    },

    getCustomerAddressList(params = {}) {
      if (this.customerAddressListTimeout) {
        clearTimeout(this.customerAddressListTimeout);
      }

      this.customerAddressListTimeout = setTimeout(() => {
        params = {
          limit: "all",
          ...params,
        };

        if (
          this.directDaDetails.customer &&
          typeof this.directDaDetails.customer == typeof {}
        ) {
          params.customer = this.directDaDetails.customer.id;
        } else {
          params.customer = this.directDaDetails.customer;
        }

        return this.$api.customerAddress
          .getCustomerAddressSelectList(params)
          .then((res) => {
            this.allCustomerAddressList = res.data;
            this.setCustomerAddresses();
          })
          .catch((err) => {
            console.error(err);
            this.$bus.$emit("showLoader", false);
          });
      }, 200);
    },

    getCustomerList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        company_type: "Customer",
        limit: "all",
        ...params,
      };
      this.$api.company
        .getCompanySelectList(params)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.allCustomerList = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getMilestoneList(params = {}) {
      if (this.jobTypeListTimeout) {
        clearTimeout(this.jobTypeListTimeout);
      }
      this.jobTypeListTimeout = setTimeout(() => {
        if (this.directDaDetails && this.directDaDetails.job_type) {
          params = {
            job_type: this.directDaDetails.job_type,
          };
        }
        this.$api.milestone
          .getMilestoneList(params)
          .then((res) => {
            this.milestoneList = res.data;
          })
          .catch((err) => {
            console.error(err);
            this.$bus.$emit("showLoader", false);
          });
      }, 200);
    },

    getDepotList(params = {}) {
      params = {
        limit: "all",
        ...params,
      };
      this.$api.depot
        .getDepotSelectList(params)
        .then((res) => {
          this.allDepotList = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getBranchList(params = {}) {
      params = {
        limit: "all",
        ...params,
      };
      return this.$api.branch
        .getBranchSelectList(params)
        .then((res) => {
          this.allBranchList = res.data;
          if (res && res.data && res.data.length) {
            this.directDaDetails.branch = res.data[0].id;
          }
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    setBackRoute() {
      if ("backRoute" in this.$route.query) {
        this.backRoute.path = this.$route.query.backRoute;
      }
      if ("active_tab" in this.$route.query) {
        if (!this.backRoute.query) {
          this.backRoute.query = {};
        }
        this.backRoute.query.active_tab = this.$route.query.active_tab;
      }
    },

    getJobTypeOptions() {
      return this.$api.bmr
        .getBMROptions()
        .then((res) => {
          this.allJobType = res.data.actions.POST.job_type.choices;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getdaHazardousCargoDetails() {
      if (this.hazardousId) {
        this.editMode = true;
        return this.$api.daHazardousCargo
          .getdaHazardousCargoDetails(this.hazardousId)
          .then((res) => {
            this.daHazardousFormDetails = res.data;
          })
          .catch((err) => {
            console.error(err);
            this.$bus.$emit("showLoader", false);
          });
      }
    },
    async getDirectCashDeliveryAdviceObject(id) {
      await this.$api.deliveryAdvice
        .getDirectCashDeliveryAdvice(id)
        .then(async (res) => {
          this.directDaDetails = res.data;
          this.directDaDetails.delivery_advice_containers =
            res.data.order_containers;
          this.validateDaContainer = [
            ...res.data.order_containers.map((c) => {
              return { ...c };
            }),
          ];

          if (this.directDaDetails.da_hazardous_cargo_id) {
            this.hazardousId = this.directDaDetails.da_hazardous_cargo_id;
            this.getdaHazardousCargoDetails();
          }

          let daPaymentReceiptLane = {
            id: this.directDaDetails && this.directDaDetails.lane,
            title: this.directDaDetails && this.directDaDetails.lane_title,
          };
          this.allLaneList.push(daPaymentReceiptLane);

          this.jobType = this.directDaDetails.job_type;
          this.formFieldHints =
            this.directDaDetails.delivery_advice_containers.map(() => {
              return {};
            });

          //for same source mid and destination validation
          this.sourceId =
            this.directDaDetails &&
            this.directDaDetails.assigned_source_address;
          this.midId =
            this.directDaDetails && this.directDaDetails.assigned_mid_address;
          this.destinationId =
            this.directDaDetails &&
            this.directDaDetails.assigned_destination_address;

          await this.getPaymentReceiptList();
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
  async beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("delivery_advice");
      const paymentRecPerm = getPermissions("payment_receipt");
      this.perm = paymentRecPerm;
      if (!permissions || !permissions.add) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        if (!this.getListCalled) {
          this.getListCalled = true;
          this.getLists([
            await this.getDirectCashDeliveryAdviceObject(this.$route.params.id),
            this.getDepotList(),
            this.getBranchList(),
            this.getJobTypeOptions(),
            this.getDeliveryAdviceOptions(),
            this.getCurrencyList(),
            this.getCustomerList(),
            this.getContainersType(),

            this.getCustomerAddressList(),
          ]);
        }
      }
    }
  },
};
</script>
