<template>
  <v-dialog width="500" v-model="showDepotForm">
    <v-card>
      <v-card-title class="secondary white--text">
        <span> {{ editMode ? "Update" : "Add" }} Depot </span>

        <v-spacer></v-spacer>

        <v-btn icon @click="resetForm(), (showDepotForm = false)">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-2">
        <v-form ref="depotForm" v-if="showDepotForm" v-model="isValid">
          <v-row class="my-0">
            <v-col cols="12" class="Form-Columns">
              <InputField
                label="Depot *"
                required
                :rules="[
                  (val) => !!val || 'Depot is Required!',
                  (val) =>
                    !$globals.numbersOnlyRegExp.test(val) ||
                    'Invalid Depot Code!',
                ]"
                hide-details="auto"
                :clearable="true"
                v-model="depotDetails.depot_name"
                :error-messages="formErrors.depot_name"
                @input="delete formErrors.depot_name"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          depressed
          :disabled="!isValid"
          @click="submitForm"
        >
          Submit
        </v-btn>

        <v-btn
          class="ml-3"
          color="secondary"
          v-if="!editMode"
          depressed
          @click="resetForm"
        >
          Reset
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";

export default {
  components: {
    InputField,
    MultiSelect,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    editDepotID: {
      type: [Number, null],
      default: null,
    },
  },
  watch: {
    value(val) {
      if (val) {
        if (this.editMode) {
          this.getDepotObject(this.editDepotID);
        }
      } else {
        this.resetForm();
      }
    },
  },
  computed: {
    showDepotForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    return {
      isValid: true,
      isTextInput: false,
      formErrors: {},
      depotDetails: {},
    };
  },
  methods: {
    submitForm() {
      this.$bus.$emit("showLoader", true);
      if (!this.editMode) {
        this.depotDetails = {
          ...this.depotDetails,
        };
        this.$api.depot
          .addDepot(this.depotDetails)
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Depot added successfully!",
              color: "success",
            });
            this.resetForm();
            this.showDepotForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: `${
                err.data && err.data.non_field_errors
                  ? err.data.non_field_errors.join()
                  : "Couldn't add Depot"
              }`,
              color: "error",
            });
            this.formErrors = err.data;
          });
      } else {
        this.$api.depot
          .updateDepot({
            id: this.depotDetails.id,
            data: this.depotDetails,
          })
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Depot updated successfully!",
              color: "success",
            });
            this.resetForm();
            this.showDepotForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: `${
                err.data && err.data.non_field_errors
                  ? err.data.non_field_errors.join()
                  : "Couldn't update Depot"
              }`,
              color: "error",
            });

            this.formErrors = err.data;
          });
      }
    },

    resetForm() {
      if (this.$refs.depotForm) {
        this.$refs.depotForm.reset();
      }
      this.depotDetails = {};
    },

    getDepotObject(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.depot
        .getDepotObject(id)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.depotDetails = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
};
</script>
