import Blank from "@/layouts/blank.vue"

import OrderList from "@/pages/admin/order/OrderList.vue";
import OrderDetails from "@/pages/admin/order/OrderDetails.vue";
import OrderCreateDA from "@/pages/admin/order/OrderCreateDA.vue";

const orderRoutes = [
  {
    path: "order",
    name: "app-admin-order",
    component: Blank,
    children: [
      {
        path: "/",
        redirect: "/app/admin/order/list",
      },
      {
        path: "list",
        name: "app-admin-order-list",
        component: OrderList,
      },
      {
        path: "details",
        name: "app-admin-order-details",
        component: Blank,
        children: [
          {
            path: "/",
            redirect: "/app/admin/order/list",
          },
          {
            path: ":id",
            name: "app-admin-order-details-id",
            component: OrderDetails,
          }
        ]
      },
      {
        path: "create-da",
        name: "app-admin-order-create-da",
        component: Blank,
        children: [
          {
            path: "/",
            redirect: "/app/admin/order/list",
          },
          {
            path: ":id",
            name: "app-admin-order-create-da-id",
            component: OrderCreateDA,
          }
        ]
      },
    ]
  },
]

export default orderRoutes