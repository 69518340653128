import { country } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
    getCountryList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(country.countryBase, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getCountrySelectList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${country.countryBase}select/`, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getCountryDetails(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${country.countryBase}${id}/view/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getCountryObject(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${country.countryBase}${id}/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    addCountry(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(country.countryBase, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    updateCountry(payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(`${country.countryBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    partiallyUpdateCountry(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${country.countryBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
});