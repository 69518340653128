import { vehicleType } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
    getVehicleTypeList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(vehicleType.vehicleTypeBase, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getVehicleTypeSelectList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${vehicleType.vehicleTypeBase}select/`, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    addVehicleType(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(vehicleType.vehicleTypeBase, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getVehicleTypeObject(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${vehicleType.vehicleTypeBase}${id}/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    updateVehicleType(payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(`${vehicleType.vehicleTypeBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    partiallyUpdateVehicleType(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${vehicleType.vehicleTypeBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
});