import Blank from "@/layouts/blank.vue"

import LocationRoutes from "@/router/admin/laneManagement/location"
import LaneRoutes from "@/router/admin/laneManagement/lane"
import containerTypeRoutes from "@/router/admin/laneManagement/containerType"
import trailerTypeRoutes from "@/router/admin/laneManagement/trailerType"
import vehicleTypeRoutes from "@/router/admin/laneManagement/vehicleType"

const laneManagementRoutes = [
  {
    path: "lane-management",
    name: "app-admin-lane-management",
    component: Blank,
    children: [
      ...LocationRoutes,
      ...LaneRoutes,
      ...containerTypeRoutes,
      ...trailerTypeRoutes,
      ...vehicleTypeRoutes,
    ]
  },
]

export default laneManagementRoutes