<template>
  <v-dialog v-model="showTicketUpdateStatusDialog" max-width="500">
    <v-card>
      <v-card-title class="secondary white--text">
        <span>Update Ticket Status </span>

        <v-spacer></v-spacer>

        <v-btn icon @click="showTicketUpdateStatusDialog = false">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-2">
        <v-form
          ref="statusForm"
          v-model="isValid"
          v-if="showTicketUpdateStatusDialog"
        >
          <v-row class="ma-0">
            <v-col cols="12" class="pa-2">
              <MultiSelect
                hide-details="auto"
                showAll
                :label="`Select Status`"
                :placeholder="`Select Status`"
                :items="ticketStatusList"
                itemText="display_name"
                itemValue="value"
                v-model="status"
              ></MultiSelect>
            </v-col>
            <v-col cols="12" class="pa-2">
              <InputField
                label="Remarks *"
                v-if="status == 'Invalid'"
                required
                :rules="[(val) => !!val || 'Remarks is Required!']"
                :clearable="true"
                hide-details="auto"
                v-model="remarks"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="d-flex justify-center">
        <v-btn
          depressed
          color="primary"
          large
          :disabled="!isTicketStatus || !isValid"
          @click="updateTicketStatus"
        >
          <span>Submit</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import InputField from "@/components/FormBaseComponents/InputField.vue";

export default {
  components: { MultiSelect, InputField },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    formDetails: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      ticketStatusList: [],
      formErrors: {},
      status: null,
      isValid: true,
      remarks: null,
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.getTicketOptions();
      }
    },
  },
  methods: {
    updateTicketStatus() {
      this.$bus.$emit("showLoader", true);
      let payload = {
        ...this.formDetails,
        data: { status: this.status, invalid_remarks: this.remarks },
      };
      this.$api.ticket
        .updateTicketStatus(payload)
        .then((res) => {
          this.$bus.$emit("showToastMessage", {
            message: "Ticket status updated successfully!",
            color: "success",
          });
          this.status = null;
          this.showTicketUpdateStatusDialog = false;
          this.$emit("getTicketList");
          this.resetForm()
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          this.formErrors = err.data;
          console.error(err);
          if (err && err.data && err.data.non_field_errors) {
            this.$bus.$emit("showToastMessage", {
              message: err.data.non_field_errors[0],
              color: "error",
              hasActions: true,
            });
          }
          this.$bus.$emit("showLoader", false);
        });
    },

    getTicketOptions() {
      this.$bus.$emit("showLoader", true);
      this.$api.ticket
        .getTicketOptions()
        .then((res) => {
          this.ticketStatusList = res.data.actions.POST.status.choices;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    resetForm() {
      if (this.$refs.statusForm) {
        this.$refs.statusForm.reset();
      }
      this.remarks = null;
    },
  },
  computed: {
    isTicketStatus() {
      return this.status ? true : false;
    },
    showTicketUpdateStatusDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
