<template>
  <v-footer fixed app elevation="4">

    <v-spacer></v-spacer>
    <span>&copy; {{ new Date().getFullYear() }}</span>
  </v-footer>
</template>

<script>
export default {

  data() {
    return {
      items: [
        {
          disabled: false,
          exact: true,
          link: false,
          href: "/customers/add",
          text: "Add Customer",
        },
        {
          disabled: false,
          exact: true,
          link: false,
          href: "/customers/list",
          text: "Customers",
        },
      ],
    };
  },
};
</script>