import { milestone } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getMilestoneList(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(milestone.milestoneBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getMilestoneObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${milestone.milestoneBase}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addMilestone(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(milestone.milestoneBase, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getMilestoneOptions(data) {
    return new Promise((resolve, reject) => {
      axios
        .options(milestone.milestoneBase, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updateMilestone(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${milestone.milestoneBase}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  partiallyUpdateMilestone(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${milestone.milestoneBase}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
