import Blank from "@/layouts/blank.vue"

import CustomerList from "@/pages/admin/company-management/customer/CustomerList.vue";
import CustomerAdd from "@/pages/admin/company-management/customer/CustomerAdd.vue";
import CustomerEdit from "@/pages/admin/company-management/customer/CustomerEdit.vue";

const customerRoutes = [
  {
    path: "customer",
    name: "app-admin-company-management-customer",
    component: Blank,
    children: [
      {
        path: "/",
        redirect: "/app/admin/company-management/customer/list",
      },
      {
        path: "list",
        name: "app-admin-company-management-customer-list",
        component: CustomerList,
      },
      {
        path: "add",
        name: "app-admin-company-management-customer-add",
        component: CustomerAdd,
      },
      {
        path: "edit",
        name: "app-admin-company-management-customer-edit",
        component: Blank,
        children: [
          {
            path: "/",
            redirect: "/app/admin/company-management/customer/list",
          },
          {
            path: ":id",
            name: "app-admin-company-management-customer-edit-id",
            component: CustomerEdit,
          }
        ]
      },
    ]
  },
]

export default customerRoutes