<template>
  <v-row class="ma-0">
    <v-col cols="12" sm="6" md="4" lg="3" v-if="dashboardData.contracts">
      <v-card class="fill-height">
        <v-card-title class="grey darken-1 py-3">
          <span class="text-h6 white--text"> Tariff </span>
        </v-card-title>

        <v-card-text class="mt-4">
          <table class="Primary-Table">
            <tbody>
              <tr v-for="(value, key) in dashboardData.contracts" :key="key">
                <td>{{ key.replace(/_+/g, " ") }}</td>
                <th>{{ value }}</th>
              </tr>
            </tbody>
          </table>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" sm="6" md="4" lg="3" v-if="dashboardData.totals">
      <v-card class="fill-height">
        <v-card-title class="grey darken-1 py-3">
          <span class="text-h6 white--text"> Others </span>
        </v-card-title>

        <v-card-text class="mt-4">
          <table class="Primary-Table">
            <tbody>
              <tr v-for="(value, key) in dashboardData.totals" :key="key">
                <td>{{ key.replace(/_+/g, " ") }}</td>
                <th>{{ value }}</th>
              </tr>
            </tbody>
          </table>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" class="mb-4">
      <v-divider></v-divider>
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <p class="text-h6">Charts</p>
      <v-spacer></v-spacer>
    </v-col>

    <v-col cols="12" sm="6" md="4" lg="3">
      <DateField
        :inputProps="{
          dense: true,
          label: 'Start Date',
          'hide-details': 'auto',
        }"
        v-model="startDate"
        :max="today"
      />
    </v-col>

    <v-col cols="12" sm="6" md="4" lg="3">
      <DateField
        :inputProps="{
          dense: true,
          label: 'End Date',
          'hide-details': 'auto',
        }"
        :min="startDate"
        :max="today"
        :disabled="!startDate"
        v-model="endDate"
      />
    </v-col>

    <v-col cols>
      <v-btn depressed color="primary" @click="datesChanged()">Submit</v-btn>
    </v-col>

    <v-col
      cols="6"
      v-if="
        dashboardData &&
        dashboardData.order &&
        dashboardData.order.order_status_date_wise_data
      "
    >
      <v-card>
        <v-card-title class="heading">Order Stacked Chart</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <Chart
            :options="chartOptions"
            :labels="dashboardData.order.order_status_date_wise_data.categories"
            :datasets="dashboardData.order.order_status_date_wise_data.values"
          />
        </v-card-text>
      </v-card>
    </v-col>

    <v-col
      cols="6"
      v-if="
        dashboardData &&
        dashboardData.da &&
        dashboardData.da.da_status_date_wise_data
      "
    >
      <v-card>
        <v-card-title class="heading">DA Stacked Chart</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <Chart
            :options="chartOptions"
            :labels="dashboardData.da.da_status_date_wise_data.categories"
            :datasets="dashboardData.da.da_status_date_wise_data.values"
          />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";

import {
  generateChartOptions,
  generateChartSeries,
} from "@/utils/functions.js";

import Chart from "@/components/General/Chart.vue";

export default {
  name: "AdminIndexPage",
  components: { InputField, Chart, DateField },
  data() {
    return {
      generateChartOptions,
      generateChartSeries,
      dashboardData: {},

      // date vars
      startDate: null,
      endDate: null,
      today: new Date().toISOString().slice(0, 10),

      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        height: "500",
        datasets: {
          bar: {
            categoryPercentage: 0.5,
            barPercentage: 1,
          },
        },
        scales: {
          xAxes: [
            {
              stacked: true,
            },
          ],
          yAxes: [
            {
              stacked: true,
            },
          ],
        },
      },
    };
  },
  methods: {
    datesChanged() {
      this.getDashboardData({
        start_date: this.startDate,
        end_date: this.endDate,
      });
    },
    getDashboardData(params = {}) {
      this.$bus.$emit("showLoader", true);
      this.$api.dashboard
        .getCustomerDashboardData(params)
        .then((res) => {
          this.dashboardData = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch(() => {
          this.$bus.$emit("showLoader", false);
        });
    },
  },
  mounted() {
    this.getDashboardData();
  },
};
</script>

<style scoped>
</style>
