<template>
  <div>
    <v-dialog fullscreen v-model="showBulkCancelDaForm">
      <v-card>
        <v-card-title class="secondary white--text mb-4 rounded-0">
          <span> Bulk Cancel Da </span>

          <v-spacer></v-spacer>

          <v-btn icon @click="resetForm(), (showBulkCancelDaForm = false)">
            <v-icon class="white--text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form v-if="showBulkCancelDaForm" v-model="isValid">
            <v-row class="mt-0">
              <v-col cols="12" sm="6" md="4" lg="1" class="Form-Columns">
                <InputField
                  label="DA No"
                  :clearable="true"
                  v-model="filters.search"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="1" class="Form-Columns">
                <MultiSelect
                  :items="allCustomer"
                  itemText="company_name"
                  itemValue="id"
                  label="Select Customer"
                  :clearable="true"
                  hide-details="auto"
                  v-model="filters.customer"
                >
                </MultiSelect>
              </v-col>

              <v-col cols="12" md="4" lg="2" class="Form-Columns">
                <DateField
                  :inputProps="{
                    dense: true,
                    label: 'Start Date',
                    clearable: true,
                    'hide-details': 'auto',
                  }"
                  v-model="filters.start_date"
                  @change="delete filters.end_date"
                />
              </v-col>
              <v-col cols="12" md="4" lg="2" class="Form-Columns">
                <DateField
                  :inputProps="{
                    dense: true,
                    label: 'End Date',
                    clearable: true,
                    'hide-details': 'auto',
                  }"
                  :min="filters.start_date"
                  v-model="filters.end_date"
                />
              </v-col>
              <v-col cols="auto"  class="Form-Columns">
                <div class="d-flex">
                  <v-menu
                    open-on-hover
                    left
                    offset-y
                   
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-on="on" v-bind="attrs" class="mx-2">
                        mdi-information
                      </v-icon>
                    </template>
                    <v-card>
                      <v-card-title
                        class="secondary py-1 text-subtitle-1 white--text font-weight-medium"
                      >
                        Filter Information
                      </v-card-title>
                      <v-card-text
                        class="mt-2"
                        
                      >
                        <table class="Normal-Table">
                          <tbody>
                            <tr>
                              <th>Start Date</th>
                              <th class="px-1">:</th>
                              <td>Movement date of DA</td>
                            </tr>
                            <tr>
                              <th>End Date</th>
                              <th class="px-1">:</th>
                              <td>Movement date of DA</td>
                            </tr>
                          </tbody>
                        </table>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="2" lg="1" class="Form-Columns">
                <MultiSelect
                  :items="statusList"
                  label="Status"
                  :clearable="true"
                  hide-details="auto"
                  v-model="filters.status"
                >
                </MultiSelect>
              </v-col>

              <v-col cols="12" lg="4" class="Form-Columns">
                <v-btn
                  depressed
                  color="primary"
                  class="mr-2"
                  @click="searchList()"
                >
                  Search
                </v-btn>
                <v-btn
                  depressed
                  color="primary"
                  class="mr-2"
                  :disabled="!(selectedRows && selectedRows.length)"
                  @click="showBulkRemarks = true"
                >
                  Bulk Cancel
                </v-btn>

                <v-btn
                  depressed
                  color="secondary"
                  class="mr-2"
                  @click="resetForm()"
                >
                  Reset
                </v-btn>
              </v-col>

              <v-col cols="12" class="pa-0">
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <span class="text-h6 font-weight-regular">
          Total Selected Delivery Advice -
          <span class="font-weight-bold"> {{ countDa }} </span>
        </span>
        <v-card-text>
          <AgGridVue
            style="width: 100%; height: calc(100vh - 320px)"
            class="ag-theme-alpine"
            id="myGrid"
            :columnDefs="headers"
            :context="context"
            @grid-ready="onGridReady"
            rowSelection="multiple"
            @selection-changed="setSelectedRows"
            :defaultColDef="defaultColDef"
            :grid-options="gridOptions"
            :suppressDragLeaveHidesColumns="true"
            :rowData="allRowList"
          ></AgGridVue>

          <v-col v-if="totalItems > 0" cols="12" class="d-flex justify-end">
            <Pagination
              :pageNo="pageNo"
              :totalItems="totalItems"
              :pageSize="itemsPerPage"
              @itemsPerPageChange="itemsPerPageChanged"
              @prevPage="prevPage"
              @nextPage="nextPage"
            />
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>

    <BulkRejectionDialog
      v-model="showBulkRemarks"
      :isRequiredRemarks="true"
      :label="`${!isAccepted ? 'Cancellation' : 'Acceptance'} Remarks `"
      :formDetails="daCancellationRemark"
      fieldKey="admin_remarks"
      @submitRemarks="bulkDeliveryAdvicecancel()"
    />
  </div>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import SelectField from "@/components/FormBaseComponents/SelectField.vue";
import { AgGridVue } from "ag-grid-vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";
import Pagination from "@/components/General/Pagination.vue";
import BulkRejectionDialog from "@/components/ModuleBased/dialogs/BulkRejectionDialog.vue";

export default {
  components: {
    AgGridVue,
    InputField,
    SelectField,
    DateField,
    MultiSelect,
    Pagination,
    BulkRejectionDialog,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.getCustomerList();
        this.getDeliveryAdviceList();
        this.countDa = 0;
      } else {
        this.resetForm();
      }
    },
  },
  computed: {
    showBulkCancelDaForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    context() {
      return { parentComponent: this };
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },
  data() {
    return {
      selectedRows: [],
      showBulkRemarks: false,
      isAccepted: false,
      daCancellationRemark: {},
      countDa: 0,

      formErrors: {},
      isValid: true,
      editMode: false,
      allRowList: [],
      allCustomer: [],
      statusList: [
        {
          text: "Scheduled",
          value: "Scheduled",
        },

        {
          text: "Active",
          value: "Active",
        },
        {
          text: "Completed",
          value: "Completed",
        },
      ],
      isSearchEnabled: false,

      filters: {
        status: [],
      },
      // pagination vars
      pageNo: 1,
      itemsPerPage: 20,
      totalItems: 0,
      headerSelected: [],
      tab: null,
      // ag-grid vars
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },

        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },
      headers: [
        {
          headerName: "DA No.",
          field: "da_no",
          minWidth: 60,
        },
        {
          headerName: "Status",
          field: "status",
          minWidth: 60,
        },
        {
          headerName: "Customer",
          field: "customer_name",
          minWidth: 60,
        },
        {
          headerName: "Movement Date/Time",
          field: "movement_date_time",
          minWidth: 150,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value, true);
          },
        },
        {
          headerName: "Payment Term",
          field: "payment_term",
          sortable: true,
          minWidth: 90,
        },
        {
          headerName: `Rate (${this.$globals.currency})`,
          field: "total_da_rate",
          minWidth: 150,
        },
        {
          headerName: "BOE",
          field: "boe",
          minWidth: 150,
        },
        {
          headerName: "Sub Hire",
          field: "is_sub_hire",
          sortable: true,
          minWidth: 80,
        },
        {
          headerName: "Vessel",
          field: "vessel_no",
          minWidth: 120,
        },
        {
          headerName: "Select Da for Cancel",
          minWidth: 120,
          checkboxSelection: true,
          headerCheckboxSelection: true,
        },
      ],
    };
  },
  methods: {
    setSelectedRows() {
      this.selectedRows = this.gridApi.getSelectedRows();
      this.countDa = this.selectedRows.length;
    },
    resizeGrid() {
      if (this.gridOptions && "api" in this.gridOptions) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },
    headersChanged(value) {
      this.headerSelected = value;
      this.resizeGrid();
    },

    searchList() {
      this.countDa = 0;
      this.pageNo = 1;
      this.getDeliveryAdviceList();
      this.selectedRows = null;
    },
    itemsPerPageChanged(e) {
      this.countDa = 0;
      this.selectedRows = null;
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getDeliveryAdviceList();
    },
    prevPage() {
      this.countDa = 0;
      this.pageNo--;
      this.getDeliveryAdviceList();
    },
    nextPage() {
      this.countDa = 0;
      this.pageNo++;
      this.getDeliveryAdviceList();
    },
    resetForm() {
      this.allRowList = null;
      this.selectedRows = null;
      this.filters = {};
      this.headerSelected = [];
    },
    getCustomerList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        company_type: "Customer",
        limit: "all",
      };
      this.$api.company
        .getCompanyList(params)
        .then((res) => {
          this.allCustomer = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    bulkDeliveryAdvicecancel() {
      let payload = {
        delivery_advices_id: this.selectedRows.map((da) => da.id),
        cancel_remarks: this.daCancellationRemark.admin_remarks,
      };
      this.$api.deliveryAdvice
        .bulkcancelDeliveryAdvice(payload)
        .then((res) => {
          this.$bus.$emit("showToastMessage", {
            message: "DA Cancelled Successfully!",
            color: "success",
          });
          this.daCancellationRemark.admin_remarks = null;
          this.getDeliveryAdviceList();
          this.showBulkRemarks = false;
          this.selectedRows = null;
          this.countDa = 0;
          this.$emit("refreshList");
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't cancel DA!",
            color: "error",
          });
        });
    },

    getDeliveryAdviceList() {
      this.$bus.$emit("showLoader", true);
      let params = {
        offset: this.offset,
        limit: this.itemsPerPage,
        ...params,
        ...this.filters,
        statuses: ["Active", "Scheduled", "Completed"].join(","),
      };

      this.$api.deliveryAdvice
        .getDeliveryAdviceList(params)
        .then((res) => {
          this.allRowList = res.data;
          this.totalItems = res.count;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);

          console.error(err);
        });
    },
  },
};
</script>
