var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-4 ml-1",attrs:{"color":"primary"},on:{"click":_vm.viewItem}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])]}}])},[_c('span',[_vm._v("View")])])],1),(
      this.params.data.status == 'Pending' ||
      this.params.data.status == 'Approved'
    )?_c('v-col',{staticClass:"pa-0",attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
            _vm.params &&
            _vm.params.context &&
            _vm.params.context.parentComponent &&
            _vm.params.context.parentComponent.permissions &&
            _vm.params.context.parentComponent.permissions.change
          )?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-4 ml-1",attrs:{"color":"primary","disabled":_vm.params.data.is_used},on:{"click":_vm.editItem}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")]):_vm._e()]}}],null,false,3464077457)},[_c('span',[_vm._v("Edit")])])],1):_vm._e(),(this.params.data.status == 'Invoice Updated')?_c('v-col',{staticClass:"pa-0",attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
            _vm.params &&
            _vm.params.context &&
            _vm.params.context.parentComponent &&
            _vm.params.context.parentComponent.permissions &&
            _vm.params.context.parentComponent.permissions.change
          )?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-4 ml-1",attrs:{"color":"primary","disabled":_vm.params.data.is_used},on:{"click":_vm.editInvoiceDetails}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")]):_vm._e()]}}],null,false,2268965463)},[_c('span',[_vm._v("Edit")])])],1):_vm._e(),(this.params.data.status == 'Pending')?_c('v-col',{staticClass:"pa-0",attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
            _vm.params &&
            _vm.params.context &&
            _vm.params.context.parentComponent &&
            _vm.params.context.parentComponent.permissions &&
            _vm.params.context.parentComponent.permissions.add &&
            _vm.params.data.is_all_legs_completed
          )?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 mr-2",attrs:{"color":"success"},on:{"click":function($event){return _vm.updateStatus(_vm.params.data.id, 'Approved')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-check ")]):_vm._e()]}}],null,false,2644460177)},[_c('span',[_vm._v("Approved")])])],1):_vm._e(),(this.params.data.status == 'Approved')?_c('v-col',{staticClass:"pa-0",attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
            _vm.params &&
            _vm.params.context &&
            _vm.params.context.parentComponent &&
            _vm.params.context.parentComponent.permissions &&
            _vm.params.context.parentComponent.permissions.add
          )?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 mr-2",attrs:{"color":"success"},on:{"click":function($event){return _vm.updateStatus(_vm.params.data.id, 'Invoice Updated')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-check ")]):_vm._e()]}}],null,false,2230968748)},[_c('span',[_vm._v("Update the invoice details")])])],1):_vm._e(),(this.params.data.status == 'Invoice Updated')?_c('v-col',{staticClass:"pa-0",attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
            _vm.params &&
            _vm.params.context &&
            _vm.params.context.parentComponent &&
            _vm.params.context.parentComponent.permissions &&
            _vm.params.context.parentComponent.permissions.add
          )?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 mr-2",attrs:{"color":"success"},on:{"click":function($event){return _vm.updateStatus(_vm.params.data.id, 'Completed')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-check ")]):_vm._e()]}}],null,false,374051429)},[_c('span',[_vm._v("Complete the sub hire job")])])],1):_vm._e(),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 mr-1",attrs:{"color":"primary"},on:{"click":_vm.viewLogs}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v("Sub Hire Job Logs")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }