import { transporterInvoice } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getTransporterInvoiceList(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(transporterInvoice.transporterInvoiceBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addTransporterInvoice(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(transporterInvoice.transporterInvoiceBase, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getTransporterInvoiceObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${transporterInvoice.transporterInvoiceBase}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getTransporterInvoiceBlopObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${transporterInvoice.transporterInvoiceBase}${id}/download`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getTransporterInvoiceDetails(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${transporterInvoice.transporterInvoiceBase}${id}/view/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updateTransporterInvoice(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${transporterInvoice.transporterInvoiceBase}${payload.id}/`,
          payload.data
        )
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  partiallyUpdateTransporterInvoice(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${transporterInvoice.transporterInvoiceBase}${payload.id}/`,
          payload.data
        )
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getTransporterInvoiceOptions() {
    return new Promise((resolve, reject) => {
      axios
        .options(transporterInvoice.transporterInvoiceBase)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addTransporterInvoiceDoc(invoiceId, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${transporterInvoice.transporterInvoiceBase}${invoiceId}/upload/`, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  deleteTransporterInvoiceDoc(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${transporterInvoice.transporterInvoiceDoc}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  uploadTransporterInvoiceDocuments(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${transporterInvoice.transporterInvoiceBase}${payload.id}/upload/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getLegListForInvoice(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(transporterInvoice.legListForInvoice, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getLegDetailsForInvoice(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(transporterInvoice.legDetailsForInvoice, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  createTransporterInvoice(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(transporterInvoice.transporterInvoiceBase, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getTranspoterInvoiceBlopObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${transporterInvoice.transporterInvoiceBase}${id}/download`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getTransporterInvoicePOD(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${transporterInvoice.transporterInvoiceBase}${id}/pods`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

});
