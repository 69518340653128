var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.showLegMovementDateForm),callback:function ($$v) {_vm.showLegMovementDateForm=$$v},expression:"showLegMovementDateForm"}},[_c('v-card',[_c('v-card-title',{staticClass:"secondary white--text"},[_c('span',[_vm._v(" Update Movement Date ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.showLegMovementDateForm = false}}},[_c('v-icon',{staticClass:"white--text"},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pa-2"},[(_vm.showLegMovementDateForm)?_c('v-form',{model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"Form-Columns",attrs:{"cols":"12"}},[_c('DateField',{ref:"movementDate",attrs:{"inputProps":{
                dense: true,
                label: 'Movement Date *',
                rules: [(val) => !!val || 'Date is Required!'],
                'hide-details': 'auto',
                'error-messages': _vm.formErrors.movement_date,
              },"required":""},on:{"input":function($event){delete _vm.formErrors.movement_date}},model:{value:(_vm.formDetails.movement_date),callback:function ($$v) {_vm.$set(_vm.formDetails, "movement_date", $$v)},expression:"formDetails.movement_date"}})],1),_c('v-col',{staticClass:"Form-Columns",attrs:{"cols":"12"}},[_c('InputField',{attrs:{"type":"time","label":"Movement Time *","required":"","rules":[(val) => !!val || 'Movement Time is Required!'],"hide-details":"auto","error-messages":_vm.formErrors.movement_time},on:{"input":function($event){delete _vm.formErrors.movement_time}},model:{value:(_vm.formDetails.movement_time),callback:function ($$v) {_vm.$set(_vm.formDetails, "movement_time", $$v)},expression:"formDetails.movement_time"}})],1)],1)],1):_vm._e()],1),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"primary","depressed":"","disabled":!_vm.isValid},on:{"click":_vm.submitForm}},[_vm._v(" Submit ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }