<template>
  <v-dialog max-width="50%" v-model="showlegContainerSealNoDialog">
    <v-card>
      <v-card-title class="secondary white--text">
        <span> {{ dialogTitle }}</span>

        <v-spacer></v-spacer>

        <v-btn icon @click="showlegContainerSealNoDialog = false">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-2">
        <v-form
          ref="daListForm"
          v-if="showlegContainerSealNoDialog"
          v-model="isValid"
        >
          <v-row
            class="my-0"
            v-for="(con, i) in legDetails.order_containers"
            :key="i"
          >
            <v-col cols="12" sm="6" class="Form-Columns">
              <InputField
                type="text"
                label="Container Number"
                readonly
                :clearable="false"
                hide-details="auto"
                v-model="con['container_no']"
              />
            </v-col>
            <v-col cols="12" sm="6" class="Form-Columns">
              <InputField
                type="text"
                label="Seal No *"
                hide-details="auto"
                v-model="con['seal_no']"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn color="primary" depressed @click="submitForm"> Save </v-btn>

        <v-btn class="ml-3" color="secondary" depressed @click="resetForm">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import SelectField from "@/components/FormBaseComponents/SelectField.vue";
import { AgGridVue } from "ag-grid-vue";
import TextareaField from "@/components/FormBaseComponents/TextareaField.vue";

export default {
  components: {
    AgGridVue,
    InputField,
    SelectField,
    MultiSelect,
    TextareaField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    dialogTitle: {
      type: String,
      default: "",
    },
    objID: {
      type: [String, Number],
      default: "",
    },
  },
  watch: {
    value(val) {
      if (val) {
        if (this.objID) {
          this.getLegListObject(this.objID);
        }
      } else {
        this.resetForm();
      }
    },
  },
  computed: {
    showlegContainerSealNoDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    context() {
      return { parentComponent: this };
    },
  },

  data() {
    return {
      formErrors: {},
      isValid: true,
      editMode: false,
      legDetails: {},

      // ag-grid vars
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },
    };
  },
  methods: {
    getLegListObject(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.trip
        .getLegListObject(id)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.legDetails = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    resizeGrid() {
      if (this.gridOptions && "api" in this.gridOptions) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },

    submitForm() {
      this.$bus.$emit("showLoader", true);
      this.formErrors = {};
      let payload = this.legDetails.order_containers;
      let params = {
        id: this.objID,
        data: payload,
      };
      this.$api.trip
        .updateContainerSealNo(params)
        .then(() => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Seal No successfully updated!",
            color: "success",
          });
          this.resetForm();
          this.showlegContainerSealNoDialog = false;
          this.$emit("refreshList");
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't udpate seal no!",
            color: "error",
          });
          this.formErrors = err.data;
        });
    },

    resetForm() {
      this.showlegContainerSealNoDialog = false;
    },
  },
};
</script>
