<template>
  <div>
    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-icon
          class="ml-2"
          color="primary"
          v-on="on"
          v-bind="attrs"
          :disabled="
            params.data.status == 'Pending' ||
            params.data.status == 'Failed' ||
            params.data.total_records == 0
          "
          @click="viewItem"
        >
          mdi-eye
        </v-icon>
      </template>
      <span>View</span>
    </v-tooltip>
    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-icon
          :disabled="
            params.data.status == 'Pending' ||
            params.data.status == 'Failed' ||
            params.data.total_records == 0
          "
          class="ml-2"
          color="primary"
          v-on="on"
          v-bind="attrs"
          @click="downloadReports"
        >
          mdi-download
        </v-icon>
      </template>
      <span>Download</span>
    </v-tooltip>

    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-icon
          class="ml-2"
          color="primary"
          v-on="on"
          v-bind="attrs"
          @click="viewReportsView"
        >
          mdi-information
        </v-icon>
      </template>
      <span>View Filters</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  methods: {
    viewItem() {
      this.params.context.parentComponent.viewReports(
        this.params.data.id,
        this.params.data.report_type
      );
    },
    downloadReports() {
      this.params.context.parentComponent.downloadReport(this.params.data);
    },
    viewReportsView() {
      this.params.context.parentComponent.viewReportsInfo(this.params.data);
    },
  },
};
</script>
