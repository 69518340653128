import {
  tripMilestones
} from "@/utils/constants";
import {
  handleError,
  handleResponse
} from "@/utils/functions";

export default (axios) => ({
  getTripMilestonesList(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(tripMilestones.tripMilestonesBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getTripMilestonesDetails(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${tripMilestones.tripMilestonesBase}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  partiallyUpdateTripMilestone(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${tripMilestones.tripMilestonesBase}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});