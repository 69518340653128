import Blank from "@/layouts/blank.vue"

import OrderList from "@/pages/customer/order/OrderList.vue";
import OrderAdd from "@/pages/customer/order/OrderAdd.vue";
import OrderEdit from "@/pages/customer/order/OrderEdit.vue";
import OrderDetails from "@/pages/customer/order/OrderDetails.vue";

const orderRoutes = [
  {
    path: "order",
    name: "app-customer-order",
    component: Blank,
    children: [
      {
        path: "/",
        redirect: "/app/customer/order/list",
      },
      {
        path: "list",
        name: "app-customer-order-list",
        component: OrderList,
      },
      {
        path: "add",
        name: "app-customer-order-add",
        component: OrderAdd,
      },
      {
        path: "edit",
        name: "app-customer-order-edit",
        component: Blank,
        children: [
          {
            path: "/",
            redirect: "/app/customer/order/list",
          },
          {
            path: ":id",
            name: "app-customer-order-edit-id",
            component: OrderEdit,
          }
        ]
      },
      {
        path: "details",
        name: "app-customer-order-details",
        component: Blank,
        children: [
          {
            path: "/",
            redirect: "/app/customer/order/list",
          },
          {
            path: ":id",
            name: "app-customer-order-details-id",
            component: OrderDetails,
          }
        ]
      },
    ]
  },
]

export default orderRoutes