<template>
  <FilterParent module="Ticket" v-model="showAdminTicketFilters">
    <template #filterContent>
      <v-row no-gutters>
        <v-col cols="12" lg="6" class="pa-2">
          <MultiSelect
            hide-details="auto"
            :items="ticketModuleList"
            itemText="display_name"
            itemValue="value"
            label="Select module"
            clearable
            v-model="filterObject.module"
          />
        </v-col>
        <v-col cols="12" lg="6" class="pa-2">
          <MultiSelect
            hide-details="auto"
            :items="ticketPriorityList"
            itemText="display_name"
            itemValue="value"
            label="Select priority"
            clearable
            v-model="filterObject.priority"
          />
        </v-col>
        <v-col cols="12" lg="6" class="pa-2">
          <MultiSelect
            hide-details="auto"
            :items="ticketStatusList"
            itemText="display_name"
            itemValue="value"
            label="Select status"
            clearable
            v-model="filterObject.status"
          />
        </v-col>
      </v-row>
    </template>

    <template #filterAction>
      <v-btn
        depressed
        color="secondary"
        @click="$emit('resetFilters'), (showAdminTicketFilters = false)"
      >
        Reset & Close
      </v-btn>

      <v-btn
        color="primary"
        class="ml-4"
        depressed
        @click="$emit('applyFilters'), (showAdminTicketFilters = false)"
      >
        Apply
      </v-btn>
    </template>
  </FilterParent>
</template>

<script>
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import FilterParent from "@/components/General/FilterParent.vue";

export default {
  components: {
    FilterParent,
    MultiSelect,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    bindingObject: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      ticketModuleList: [],
      ticketPriorityList: [],
      ticketStatusList: [],
      booleanValues: [
        {
          text: "Yes",
          value: "true",
        },
        {
          text: "No",
          value: "false",
        },
      ],
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.getTicketOptions();
      }
    },
  },
  computed: {
    showAdminTicketFilters: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    filterObject: {
      get() {
        return this.bindingObject;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    getTicketOptions() {
      this.$bus.$emit("showLoader", true);
      this.$api.ticket
        .getTicketOptions()
        .then((res) => {
          this.ticketModuleList = res.data.actions.POST.module.choices;
          this.ticketPriorityList = res.data.actions.POST.priority.choices;
          this.ticketStatusList = res.data.actions.POST.status.choices;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
};
</script>

<style>
</style>