<template>
  <v-card class="fill-height">
    <v-card-title class="heading mb-4">
      <Tooltip
        tooltipTitle="Vehicle ID"
        v-if="vehicleDetails.equipment_id"
        :tooltipText="vehicleDetails.equipment_id"
        right
        tooltipClass="text-h5 font-weight-medium"
      />
    </v-card-title>

    <v-card-subtitle class="subHeading py-2">
      <div class="d-flex justify-space-between">
        <Tooltip
          tooltipTitle="Vehicle Type"
          v-if="vehicleDetails.vehicle_type_title"
          :tooltipText="vehicleDetails.vehicle_type_title"
          right
          tooltipClass="text-subtitle font-weight-medium"
        />

        <Tooltip
          tooltipTitle="Vehicle Number"
          v-if="vehicleDetails.vehicle_number"
          :tooltipText="vehicleDetails.vehicle_number"
          left
          tooltipClass="text-subtitle font-weight-medium"
        />
      </div>
    </v-card-subtitle>

    <v-card-text class="pt-2" v-if="Object.keys(vehicleDetails).length">
      <v-tabs grow v-model="detailsTab" class="mb-2">
        <v-tabs-slider color="white"></v-tabs-slider>
        <v-tab name="vehicle-details-view">Detail View</v-tab>
        <v-tab name="vehicle-details-maintenance">Vehicle Maintenance</v-tab>
        <v-tab name="vehicle-details-documents">Documents</v-tab>
        <v-tab name="vehicle-details-logs">Logs</v-tab>
      </v-tabs>

      <v-tabs-items v-model="detailsTab">
        <v-tab-item class="Height-Class">
          <v-expansion-panels focusable multiple v-model="vehicleDetailView">
            <CardDetails
              title="Vehicle Info"
              :displayObject="vehicleDetails.extra_info"
            />
            <CardDetails
              title="Company Info"
              :displayObject="vehicleDetails.company_detail"
            />
          </v-expansion-panels>
        </v-tab-item>

        <v-tab-item>
          <div class="text-right">
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-if="permissions && permissions.add"
                  color="primary"
                  name="vehicle-details-add-maintenance"
                  depressed
                  v-on="on"
                  v-bind="attrs"
                  small
                  class="mb-2"
                  @click="addMaintenance"
                >
                  <v-icon>mdi-tools</v-icon>
                </v-btn>
              </template>
              <span>Add Maintenance</span>
            </v-tooltip>
          </div>

          <div
            class="Maintenance-Height-Class"
            v-if="vehicleMaintenance && vehicleMaintenance.length"
          >
            <v-card
              color="subHeading"
              v-for="(item, i) in vehicleMaintenance"
              :key="i"
            >
              <v-card-title class="pb-1 pt-3">
                <span class="text-subtitle-1 font-weight-medium">
                  {{ `${i + 1}. ${item.maintenance_type}` }}
                </span>

                <v-spacer></v-spacer>

                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      :name="`vehicle-details-edit-maintenance-${i}`"
                      color="primary"
                      @click="editMaintenance(item.id)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
              </v-card-title>

              <v-divider></v-divider>
              <v-card-text class="pt-2 pb-3">
                <table class="Normal-Table">
                  <tbody>
                    <tr>
                      <th>Priority</th>
                      <th class="px-1">:</th>
                      <td>{{ item.priority }}</td>
                    </tr>
                    <tr>
                      <th>Duration</th>
                      <th class="px-1">:</th>
                      <td>
                        {{ item.start_date | formatDate }}
                        <strong class="mx-1">to</strong>
                        {{ item.end_date | formatDate }}
                      </td>
                    </tr>
                    <tr>
                      <th>Description</th>
                      <th class="px-1">:</th>
                      <td>{{ item.description }}</td>
                    </tr>
                  </tbody>
                </table>
              </v-card-text>
            </v-card>
          </div>

          <div v-else class="text-center">
            <v-divider class="mb-4"></v-divider>
            <span>No Vehicle Maintenance to show</span>
          </div>
        </v-tab-item>

        <v-tab-item class="Height-Class">
          <DocumentList :documentList="vehicleDetails.vehicle_documents" />
        </v-tab-item>

        <v-tab-item>
          <v-sheet class="Height-Class">
            <v-timeline v-if="logs && logs.length" align-top dense>
              <v-timeline-item
                v-for="(log, index) in logs"
                :key="index"
                color="primary"
                icon="mdi-circle"
                small
              >
                <div>
                  <strong>{{ log.created | formatDate(true) }}</strong>
                  <div class="text-body-2">{{ log.message }}</div>
                  <div class="text-caption">- {{ log.updated_by }}</div>
                </div>
              </v-timeline-item>
            </v-timeline>

            <div v-else class="text-center">
              <v-divider class="mb-4"></v-divider>
              <span>No Logs to show</span>
            </div>
          </v-sheet>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>

    <MaintenanceForm
      v-model="showMaintenanceForm"
      :asset="vehicleDetails"
      :editMode="editMode"
      :maintenanceId="selectedMaintenanceId"
      module="Vehicle"
      @closeMaintenanceForm="showMaintenanceForm = false"
      @refreshList="getVehicleMaintenanceList"
    />
  </v-card>
</template>

<script>
import CardDetails from "@/components/General/CardDetails.vue";
import DocumentList from "@/components/General/DocumentList.vue";
import Tooltip from "@/components/General/Tooltip.vue";
import MaintenanceForm from "../dialogForms/MaintenanceForm.vue";

export default {
  components: { CardDetails, Tooltip, MaintenanceForm, DocumentList },
  props: {
    vehicleId: {
      type: Number,
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    vehicleId(val) {
      this.logs = [];
      this.getVehicleDetails(val);
      this.vehicleActivityLogs();
      this.getVehicleMaintenanceList();
    },
  },
  data() {
    return {
      detailsTab: 0,
      vehicleDetails: {},
      vehicleMaintenance: [],
      logs: [],

      // Maintenance vars
      showMaintenanceForm: false,
      editMode: false,
      selectedMaintenanceId: null,

      vehicleDetailView: [0, 1],
    };
  },
  methods: {
    getVehicleDetails(id) {
      this.$api.vehicle
        .getVehicleDetails(id)
        .then((res) => {
          this.vehicleDetails = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getVehicleMaintenanceList() {
      this.$api.maintenance
        .getMaintenanceList({ vehicle: this.vehicleId, limit: "all" })
        .then((res) => {
          this.vehicleMaintenance = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    addMaintenance() {
      this.editMode = false;
      this.selectedMaintenanceId = null;
      this.showMaintenanceForm = true;
    },
    editMaintenance(id) {
      this.editMode = true;
      this.selectedMaintenanceId = id;
      this.showMaintenanceForm = true;
    },
    vehicleActivityLogs(params = {}) {
      params = {
        ...params,
        vehicle: this.vehicleId,
        limit: "50",
        offset: this.logs.length,
      };
      this.$api.activityLogs
        .vehicleActivityLogs(params)
        .then((res) => {
          this.logs.push(...res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    },

    getListData() {
      this.$bus.$emit("showLoader", true);
      Promise.all([
        this.getVehicleDetails(this.vehicleId),
        this.vehicleActivityLogs(),
        this.getVehicleMaintenanceList(),
      ])
        .then((res) => {
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
        });
    },
  },
  mounted() {
    this.logs = [];
    this.getListData();
  },
};
</script>

<style scoped>
.Height-Class {
  height: calc(100vh - 282px);
  overflow-y: auto;
}

.Maintenance-Height-Class {
  height: calc(100vh - 322px);
  overflow-y: auto;
}
</style>