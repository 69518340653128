<template>
  <v-text-field
    :value="value"
    :class="designClass"
    outlined
    :clearable="clearable"
    autocomplete="new-password"
    v-on="$listeners"
    v-bind="$attrs"
    :dense="dense"
  ></v-text-field>
</template>

<script>
export default {
  name: "InputTag",
  props: {
    dense: {
      default: true,
    },
    value: {
      type: [String, Array, Date, Number],
      default: "",
    },
    designClass: {
      type: String,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
