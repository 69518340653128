<template>
  <v-app>
    <h1>{{ pageNotFound }}</h1>
  </v-app>
</template>

<script>
export default {
  name: "EmptyLayout",
  layout: "empty",
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      pageNotFound: "404 Not Found",
    };
  },
};
</script>

<style scoped>
h1 {
  font-size: 20px;
}
</style>
