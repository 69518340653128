<template>
  <ListComponent
    title="Customer Tariff"
    :totalCount="totalTariffStatistics.total_tariff"
  >
    <template #list>
      <v-row class="ma-0">
        <v-col cols="4" class="d-flex align-center px-0 pt-2">
          <v-text-field
            label="Search"
            outlined
            dense
            append-icon="mdi-magnify"
            clearable
            hide-details="auto"
            v-model="filters.search"
            @input="searchCustomerContracts"
          ></v-text-field>

          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                depressed
                icon
                v-on="on"
                v-bind="attrs"
                @click="showFilterDialog = true"
                class="mx-2"
              >
                <v-badge
                  dot
                  color="primary"
                  :value="Object.keys(filters).length"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-badge>
              </v-btn>
            </template>

            <span v-if="Object.keys(filters).length == 0">Filters</span>

            <v-row v-else no-gutters>
              <v-col cols="12" class="font-weight-bold">Applied Filters</v-col>
              <v-col cols="12">
                <span
                  v-for="(value, key) in filters"
                  :key="key"
                  class="text-capitalize"
                  >{{ key.replace("_", " ") }}
                  <br />
                </span>
              </v-col>
            </v-row>
          </v-tooltip>
          <Columns
            :headers="headers"
            :gridColInstance="gridColumnApi"
            localStorageKey="customerContractList"
          />
        </v-col>

        <v-col cols="4" class="px-0">
          <v-tabs height="35" v-model="tab">
            <v-tabs-slider></v-tabs-slider>
            <v-tab>
              Pending
              <v-badge
                v-if="totalTariffStatistics.pending_tariff"
                top
                :content="totalTariffStatistics.pending_tariff"
                overlap
                class="mb-4 ml-3"
              >
              </v-badge>
            </v-tab>
            <v-tab>
              Active
              <v-badge
                v-if="totalTariffStatistics.active_tariff"
                top
                :content="totalTariffStatistics.active_tariff"
                overlap
                class="mb-4 ml-3"
              >
              </v-badge>
            </v-tab>
            <v-tab>
              Rejected
              <v-badge
                v-if="totalTariffStatistics.rejected_tariff"
                top
                :content="totalTariffStatistics.rejected_tariff"
                overlap
                class="mb-4 ml-3"
              >
              </v-badge>
            </v-tab>
            <v-tab>
              Closed
              <v-badge
                v-if="totalTariffStatistics.closed_tariff"
                top
                :content="totalTariffStatistics.closed_tariff"
                overlap
                class="mb-4 ml-3"
              >
              </v-badge>
            </v-tab>
          </v-tabs>
        </v-col>

        <v-col
          v-if="totalItems > 0"
          cols="4"
          class="d-flex justify-end align-center px-0 pt-2"
        >
          <Pagination
            :pageNo="pageNo"
            :totalItems="totalItems"
            :pageSize="itemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>

        <v-col cols="12" class="pa-0">
          <AgGridVue
            style="width: 100%; height: calc(100vh - 192px)"
            class="ag-theme-alpine"
            id="customerContractGrid"
            :columnDefs="headers"
            :context="context"
            @grid-ready="onGridReady"
            @columnMoved="$bus.$emit('syncPreferences')"
            :defaultColDef="defaultColDef"
            :grid-options="gridOptions"
            :suppressDragLeaveHidesColumns="true"
            :rowData="customerContractList"
          />
        </v-col>
      </v-row>

      <RemarksDialog
        v-model="showRemarksForm"
        :formDetails="updatingContract"
        fieldKey="admin_remarks"
        :formErrors="formErrors"
        :isContractForm="true"
        @removeFieldError="formErrors = {}"
        :label="updatingContract.status + ' Remarks *'"
        @submitRemarks="updateContract"
      />

      <DetailsParent :resizable="true" v-model="displayDrawer">
        <template #details>
          <CustomerContractDetails
            :permissions="permissions"
            @refreshList="getCustomerContractList"
            @submitResp="showConfirmationDialog"
            :contractId="selectedContractId"
          />
        </template>
      </DetailsParent>

      <AdminCustomerContractFilters
        v-model="showFilterDialog"
        :bindingObject="filters"
        @resetFilters="resetFilters"
        @applyFilters="itemsPerPageChanged(20)"
      />
      <RejectionRemarksDialog
        v-model="showCloseRemarksForm"
        :formDetails="contractDetails"
        :isRequiredRemarks="true"
        fieldKey="close_remarks"
        label="Close Remarks"
        @submitRemarks="closeRemarksSubmit"
      />
    </template>

    <template #dialogs>
      <RenewContractDialog
        v-model="renewDialog"
        :formDetails="renewObject"
        :formErrors="renewObjectErrors"
        @submitRemarks="renewContract"
        @removeFieldError="renewObjectErrors = {}"
      />
      <!-- <BulkUploadDialog v-model="showBulkUploadDialog" /> -->
      <BulkUpload
        v-model="locationBulkUploadDialog"
        :uploadTo="uploadType"
        @refreshList="itemsPerPageChanged(20)"
      />
      <BulkDownload
        v-model="tariffBulkDownloadDialog"
        :downloadTo="downloadType"
      />
    </template>

    <template #actions>
      <v-btn
        v-if="permissions && permissions.add"
        @click="addContract()"
        color="primary"
        depressed
      >
        <v-icon>mdi-plus</v-icon>
        <span>Add Tariff</span>
      </v-btn>
      <v-btn
        class="ml-1"
        v-if="permissions && permissions.add"
        @click="openLocationBulkUpload('customer_tariff')"
        color="primary"
        depressed
      >
        <v-icon>mdi-plus</v-icon>
        <span>Bulk Upload</span>
      </v-btn>
      <v-btn
        class="ml-1"
        v-if="permissions && permissions.add"
        @click="openBulkDownload('customer_tariff')"
        color="primary"
        depressed
      >
        <v-icon>mdi-plus</v-icon>
        <span>Bulk Download</span>
      </v-btn>
    </template>
  </ListComponent>
</template>

<script>
import Columns from "@/components/General/Columns.vue";
import ListComponent from "@/components/General/ListComponent.vue";
import RemarksDialog from "@/components/ModuleBased/dialogs/RemarksDialog.vue";
import RenewContractDialog from "@/components/ModuleBased/dialogs/RenewContractDialog.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";
import AdminCustomerContractButton from "@/components/AgGridComponents/AdminCustomerContractButton.vue";
import CustomHeader from "@/components/AgGridComponents/General/CustomHeader.vue";
import DetailsParent from "@/components/General/DetailsParent.vue";
import CustomerContractDetails from "@/components/ModuleBased/details/CustomerContractDetails.vue";
import AdminCustomerContractFilters from "@/components/ModuleBased/dialogs/filters/AdminCustomerContractFilters.vue";
import RejectionRemarksDialog from "@/components/ModuleBased/dialogs/RejectionRemarksDialog.vue";
import BulkUploadDialog from "@/components/ModuleBased/dialogs/BulkUploadDialog.vue";
import BulkUpload from "@/components/General/BulkUpload.vue";
import BulkDownload from "@/components/General/BulkDownload.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    ListComponent,
    RemarksDialog,
    AgGridVue,
    Pagination,
    AdminCustomerContractButton,
    agColumnHeader: CustomHeader,
    DetailsParent,
    CustomerContractDetails,
    AdminCustomerContractFilters,
    RenewContractDialog,
    RejectionRemarksDialog,
    BulkUploadDialog,
    BulkUpload,
    BulkDownload,
    Columns,
  },
  data() {
    return {
      renewDialog: false,
      showCloseRemarksForm: false,
      showBulkUploadDialog: false,
      renewObject: {},
      renewObjectErrors: {},

      sorting: {},
      headers: [
        {
          headerName: "Tariff ID",
          field: "contract_id",
          sortable: true,
          minWidth: 100,
        },
        {
          headerName: "Customer Code",
          field: "customer_code",
          tooltipField: "customer_code",
          sortable: true,
          minWidth: 110,
        },
        {
          headerName: "Customer",
          field: "customer_name",
          tooltipField: "customer_name",
          sortable: true,
          minWidth: 300,
        },
        {
          headerName: "Route",
          field: "customer_contract_lane_title",
          tooltipField: "customer_contract_lane_full_title",
          sortable: true,
          minWidth: 150,
        },
        {
          headerName: "Start Date",
          field: "start_date",
          sortable: true,
          minWidth: 120,
          tooltipValueGetter: (params) => {
            return this.$globals.formatDate(params.value);
          },
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value);
          },
        },
        {
          headerName: "End Date",
          field: "end_date",
          sortable: true,
          minWidth: 120,
          tooltipValueGetter: (params) => {
            return this.$globals.formatDate(params.value);
          },
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value);
          },
        },
        {
          headerName: "Job Type",
          field: "job_type",
          sortable: true,
          minWidth: 100,
        },
        {
          headerName: "Actions",
          field: "actions",
          pinned: "right",
          minWidth: 230,
          cellRendererFramework: "AdminCustomerContractButton",
        },
      ],
      customerContractList: [],

      // details vars
      selectedContractId: null,
      displayDrawer: false,
      // Show Error Message
      formErrors: {},

      // pagination vars
      pageNo: 1,
      itemsPerPage: 20,
      totalItems: 0,

      // filter vars
      filters: {},
      showFilterDialog: false,

      tab: null,

      // update Contract Details
      updatingContract: {},
      showRemarksForm: false,
      permissions: null,
      contractDetails: {},

      // bulk create
      uploadType: "location",
      downloadType: "tariff",
      locationBulkUploadDialog: false,
      tariffBulkDownloadDialog: false,
      openItemMasterDownloadDialog: false,

      //daStatistics
      totalTariffStatistics: [],

      // ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },
  watch: {
    tab() {
      this.getCustomerContractList({ offset: 0, limit: this.itemsPerPage });
      this.displayDrawer = false;
    },
  },
  methods: {
    openLocationBulkUpload(type) {
      this.uploadType = type;
      this.locationBulkUploadDialog = true;
    },
    openBulkDownload(type) {
      this.downloadType = type;
      this.tariffBulkDownloadDialog = true;
    },
    showConfirmationDialog() {
      this.showCloseRemarksForm = true;
    },
    closeRemarksSubmit() {
      this.$bus.$emit("showLoader", true);
      let payload = {
        id: this.selectedContractId,
        close_remarks: this.contractDetails.close_remarks,
      };

      this.$api.customerContract
        .closeCustomerContract(payload)
        .then((res) => {
          this.getCustomerContractList();
          this.$bus.$emit("showToastMessage", {
            message: "Tariff closed successfully!",
            color: "success",
          });
          this.showRemarksForm = false;
          this.$bus.$emit("showLoader", false);
          this.showCloseRemarksForm = false;
          this.selectedContractId = false;
          this.displayDrawer = false;
          delete this.contractDetails.close_remarks;
        })
        .catch((err) => {
          this.formErrors = err.data;
          console.error(err);
          this.$bus.$emit("showToastMessage", {
            message: `${
              err.data && err.data.non_field_errors
                ? err.data.non_field_errors.join()
                : "Couldn't update Tariff status!"
            }`,
            color: "error",
          });
          this.$bus.$emit("showLoader", false);
        });
    },
    resizeGrid() {
      if (this.gridApi) {
        setTimeout(() => {
          this.gridApi.sizeColumnsToFit();
        }, 100);
      }
    },
    resetFilters() {
      this.filters = {};
      this.itemsPerPageChanged(20);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },
    searchCustomerContracts() {
      this.pageNo = 1;
      this.getCustomerContractList();
    },
    openRemarksForm(contract, status) {
      this.updatingContract = {
        id: contract.id,
        status: status,
        source_location: contract.source_location,
        mid_location: contract.mid_location,
        destination_location: contract.destination_location,
        start_date: contract.start_date,
      };
      if (status == "Active") {
        this.updatingContract.end_date = contract.end_date;
      }
      this.showRemarksForm = true;
    },
    updateContract() {
      this.$bus.$emit("showLoader", true);
      let payload = {
        id: this.updatingContract.id,
        data: this.updatingContract,
      };
      this.$api.customerContract
        .partiallyUpdateCustomerContract(payload)
        .then((res) => {
          this.getCustomerContractList();
          this.$bus.$emit("showToastMessage", {
            message: "Tariff status updated successfully!",
            color: "success",
          });
          this.showRemarksForm = false;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          this.formErrors = err.data;
          console.error(err);
          this.$bus.$emit("showToastMessage", {
            message: `${
              err.data && err.data.non_field_errors
                ? err.data.non_field_errors.join()
                : "Cant update tariff"
            }`,
            color: "error",
          });
          this.$bus.$emit("showLoader", false);
        });
    },
    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getCustomerContractList();
    },
    prevPage() {
      this.pageNo--;
      this.getCustomerContractList();
    },
    nextPage() {
      this.pageNo++;
      this.getCustomerContractList();
    },
    applyGridSort(key, type) {
      if (this.filters && !this.filters.ordering) {
        this.filters.ordering = [];
      }

      if (type == null) {
        delete this.sorting[key];
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(`-${key}`),
          1
        );
      } else if (type == "asc") {
        this.filters.ordering.push(key);
      } else if (type == "desc") {
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(key),
          1,
          `-${key}`
        );
      }
      if (this.filters.ordering.length == 0) {
        delete this.filters.ordering;
      }
      this.itemsPerPageChanged(20);
    },
    getCustomerContractList(params = {}) {
      this.$bus.$emit("showLoader", true);
      let laneId;
      const laneObject = Object.assign({}, this.filters.lane);
      if (this.filters && this.filters.lane) {
        laneId = this.filters.lane.id;
        delete this.filters.lane;
        this.filters.lane = laneId
      }
      params = {
        offset: this.offset,
        limit: this.itemsPerPage,
        status:
          this.tab == 0
            ? "Pending"
            : this.tab == 1
            ? "Active"
            : this.tab == 2
            ? "Rejected"
            : "Closed",
        ...params,
        ...this.filters,
      };
      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }
      this.$api.customerContract
        .getCustomerContractList(params)
        .then((res) => {
          this.customerContractList = res.data;
          this.totalItems = res.count;

          delete params.limit;
          delete params.offset;
          delete params.status;

          this.getTotalTariffCount(params);
          this.filters.lane = laneObject
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    addContract(id) {
      const path = {
        path: "/app/admin/customer-contract/add",
        query: {
          active_tab: this.tab,
        },
      };
      if (id) {
        path.query.id = id;
      }
      this.$router.push(path);
    },
    editContract(id) {
      const path = {
        path: `/app/admin/customer-contract/edit/${id}`,
        query: {
          active_tab: this.tab,
        },
      };
      this.$router.push(path);
    },
    viewContract(id) {
      this.selectedContractId = id;
      this.displayDrawer = true;
    },
    deleteSpotRate() {
      this.$bus.$emit("showToastMessage", {
        message: "This is a test",
      });
    },
    checkQuery() {
      if ("active_tab" in this.$route.query) {
        this.tab = parseInt(this.$route.query.active_tab);
      }
    },
    showRenewDialog(id) {
      this.selectedContractId = id;
      this.renewDialog = true;
    },
    renewContract() {
      const params = {
        id: this.selectedContractId,
        data: this.renewObject,
      };
      this.$api.customerContract
        .renewContract(params)
        .then((res) => {
          this.renewDialog = false;
          this.getCustomerContractList();
          this.$bus.$emit("showToastMessage", {
            message: "Tariff Renewed Successfully!",
            color: "success",
          });
          this.$bus.$emit("showLoader", false);
          this.renewObject.start_date = null;
          this.renewObject.end_date = null;
        })
        .catch((err) => {
          console.error(err);
          this.renewObjectErrors = err.data;
          this.$bus.$emit("showToastMessage", {
            message: `${
              err.data && err.data.non_field_errors
                ? err.data.non_field_errors.join()
                : "Something went wrong!"
            }`,
            color: "error",
          });
          this.$bus.$emit("showLoader", false);
        });
    },
    getTotalTariffCount(params) {
      this.$api.customerContract
        .getTariffStatistics(params)
        .then((res) => {
          this.totalTariffStatistics = res.data;
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't  DA!",
            color: "error",
          });
        });
    },
  },
  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("contract");
      if (!permissions || !permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.permissions = { ...permissions };
        this.checkQuery();
      }
    }
  },
  mounted() {
    this.getTotalTariffCount();
  },
};
</script>
