import { daHazardousCargo } from "@/utils/constants";
import {
  handleError,
  handleResponse
} from "@/utils/functions";

export default (axios) => ({
  getdaHazardousCargoList(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(daHazardousCargo.daHazardousCargoBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },


  getdaHazardousCargoDetails(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${daHazardousCargo.daHazardousCargoBase}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  addDAHazardousCargo(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(daHazardousCargo.daHazardousCargoBase, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  updateDAHazardousCargo(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${daHazardousCargo.daHazardousCargoBase}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  deleteDAHazardousCargo(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${daHazardousCargo.daHazardousCargoBase}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});