<template>
  <FormComponent
    title="Edit Role"
    backRoute="/app/admin/role-management/list"
    :stickyHeader="true"
    :nonFieldErrors="errors.non_field_errors"
  >
    <template #formActions>
      <v-btn color="primary" depressed :disabled="!isValid" @click="submitForm">
        Update
      </v-btn>
    </template>

    <template #formContent>
      <v-form v-model="isValid">
        <v-row class="ma-0">
          <v-col cols="12" sm="6" lg="4" class="pa-2">
            <InputField
              hide-details="auto"
              type="text"
              label="Role Name*"
              :rules="[(val) => !!val || 'Role Name is required!']"
              required
              v-model="roleDetails.role_name"
              :error-messages="errors.role_name"
            />
          </v-col>

          <v-col cols="12" class="px-2 pt-2 pb-0">
            <span class="text-h6">Role Permission</span>
          </v-col>

          <v-col cols="12" class="px-2 pb-0 pt-2">
            <v-row class="ma-0">
              <v-col cols="5" class="white--text secondary">
                <span>Permissions</span>
              </v-col>

              <v-col class="white--text secondary d-flex justify-center">
                <span>View</span>
              </v-col>

              <v-col class="white--text secondary d-flex justify-center">
                <span>Create</span>
              </v-col>

              <v-col class="white--text secondary d-flex justify-center">
                <span>Update</span>
              </v-col>

              <v-col class="white--text secondary d-flex justify-center">
                <span>Delete</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="py-0 px-2 role-scroll">
            <v-row
              class="ma-0"
              v-for="(value, key, i) in roleDetails.updated_permissions"
              :key="i"
            >
              <v-col
                cols="5"
                class="
                  d-flex
                  align-end
                  border-bottom-grey border-left-grey
                  py-1
                "
                v-if="key != 'lane' && key != 'contract'"
              >
                <span class="text-capitalize">
                  {{ key.replace(/_/g, " ") }}
                </span>
              </v-col>
              <v-col
                cols="5"
                class="
                  d-flex
                  align-center
                  border-bottom-grey border-left-grey
                  py-1
                "
                v-else-if="key == 'lane'"
              >
                <span class="text-capitalize"> Route </span>
              </v-col>
              <v-col
                cols="5"
                class="
                  d-flex
                  align-center
                  border-bottom-grey border-left-grey
                  py-1
                "
                v-else-if="key == 'contract'"
              >
                <span class="text-capitalize">Customer Tariff </span>
              </v-col>

              <v-col class="d-flex justify-center border-bottom-grey py-1">
                <v-checkbox
                  v-if="typeof value == typeof {} && 'view' in value"
                  class="ma-0"
                  hide-details="auto"
                  v-model="value.view"
                  @change="handleView($event, value)"
                ></v-checkbox>
              </v-col>

              <v-col class="d-flex justify-center border-bottom-grey py-1">
                <v-checkbox
                  v-if="typeof value == typeof {} && 'add' in value"
                  class="ma-0"
                  hide-details="auto"
                  v-model="value.add"
                  @change="handleAdd($event, value)"
                ></v-checkbox>
              </v-col>

              <v-col class="d-flex justify-center border-bottom-grey py-1">
                <v-checkbox
                  v-if="typeof value == typeof {} && 'change' in value"
                  class="ma-0"
                  hide-details="auto"
                  v-model="value.change"
                  @change="handleChange($event, value)"
                ></v-checkbox>
              </v-col>

              <v-col
                class="
                  d-flex
                  justify-center
                  border-bottom-grey border-right-grey
                  py-1
                "
              >
                <v-checkbox
                  v-if="typeof value == typeof {} && 'delete' in value"
                  class="ma-0"
                  hide-details="auto"
                  v-model="value.delete"
                  @change="handleDelete($event, value)"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </FormComponent>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import FormComponent from "@/components/General/FormComponent.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: { FormComponent, InputField },
  data() {
    return {
      isValid: true,
      checkbox: true,
      isTextInput: false,
      roleDetails: {},
      errors: {},
    };
  },
  methods: {
    handleView(val, obj) {
      if (!val) {
        obj.add = val;
        obj.change = val;
        obj.delete = val;
      }
    },
    handleAdd(val, obj) {
      if (val) {
        obj.change = val;
        obj.delete = val;
        obj.view = val;
      }
      if (!val) {
        obj.change = val;
        obj.add = val;
        obj.delete = val;
      }
    },
    handleChange(val, obj) {
      if (val) {
        obj.view = val;
        obj.add = val;
        obj.delete = val;
      }
      if (!val) {
        obj.delete = val;
        obj.add = val;
      }
    },
    handleDelete(val, obj) {
      if (val) {
        obj.view = val;
        obj.add = val;
        obj.change = val;
      }
      if (!val) {
        obj.change = val;
        obj.add = val;
      }
    },
    getRoleDetails(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.role
        .getRoleObject(id)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          let tempObj = res.data;
          tempObj["updated_permissions"] = tempObj.permissions;
          delete tempObj.permissions;
          this.roleDetails = tempObj;
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          console.error(err);
        });
    },
    submitForm() {
      this.errors = {};

      let payload = {
        id: this.roleDetails.id,
        data: this.roleDetails,
      };
      this.$bus.$emit("showLoader", true);
      this.$api.role
        .updateRole(payload)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            color: "success",
            message: "Role updated successfully!",
          });
          this.$router.push("/app/admin/role-management/list");
        })
        .catch((err) => {
          this.errors = err.data;
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            color: "error",
            message: "Couldn't update role!",
          });
        });
    },
  },
  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("role");
      if (!permissions || !permissions.change) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.getRoleDetails(this.$route.params.id);
      }
    }
  },
};
</script>

<style scoped>
.role-scroll {
  height: calc(100vh - 330px);
  overflow: auto;
}
</style>