<template>
  <ListComponent title="Quotations">
    <template #list>
      <v-row class="ma-0">
        <v-col cols="4" class="pl-0 pr-2 pt-0 pb-2 d-flex align-center">
          <v-text-field
            label="Search"
            outlined
            dense
            append-icon="mdi-magnify"
            clearable
            hide-details="auto"
            v-model="filters.search"
            @input="searchRole"
            class="mr-2"
          ></v-text-field>

          <Columns :headers="headers" :gridColInstance="gridColumnApi" />
        </v-col>

        <v-col cols="4" class="px-0 pt-0 pb-2">
          <v-tabs height="35" v-model="tab" centered>
            <v-tabs-slider></v-tabs-slider>
            <v-tab> Pending </v-tab>
            <v-tab> Sent </v-tab>
            <v-tab> Approved </v-tab>
            <v-tab> Rejected </v-tab>
            <v-tab> Cancelled </v-tab>
          </v-tabs>
        </v-col>

        <v-col
          v-if="totalItems > 0"
          cols="4"
          class="px-0 pt-0 pb-2 d-flex justify-end"
        >
          <Pagination
            :pageNo="pageNo"
            :totalItems="totalItems"
            :pageSize="itemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>

        <v-col cols="12" class="pa-0">
          <AgGridVue
            style="width: 100%; height: calc(100vh - 172px)"
            class="ag-theme-alpine"
            :columnDefs="headers"
            :context="context"
            @grid-ready="onGridReady"
            @columnMoved="resizeGrid"
            @columnVisible="resizeGrid"
            :defaultColDef="defaultColDef"
            :grid-options="gridOptions"
            :suppressDragLeaveHidesColumns="true"
            :rowData="quotationList"
          ></AgGridVue>
        </v-col>
      </v-row>

      <DetailsParent :resizable="true" v-model="displayDrawer">
        <template #details>
          <QuotationDetails :hideActions="true" :q_id="selectedQuotationId" />
        </template>
      </DetailsParent>
    </template>
    <template #actions>
      <v-btn
        v-if="permissions && permissions.add"
        color="primary"
        depressed
        @click="requestQuotation"
      >
        <v-icon>mdi-plus</v-icon>
        <span>Sent Quotation</span>
      </v-btn>
    </template>
  </ListComponent>
</template>

<script>
import Columns from "@/components/General/Columns.vue";
import ListComponent from "@/components/General/ListComponent.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";
import QuotationButton from "@/components/AgGridComponents/AdminQuotationEditButton.vue";
import DetailsParent from "@/components/General/DetailsParent.vue";
import QuotationDetails from "@/components/ModuleBased/details/QuotationDetails.vue";
import CustomHeader from "@/components/AgGridComponents/General/CustomHeader.vue";
import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    Columns,
    ListComponent,
    AgGridVue,
    Pagination,
    QuotationButton,
    agColumnHeader: CustomHeader,
    DetailsParent,
    QuotationDetails,
  },
  data() {
    return {
      sorting: {},
      statuses: ["Pending", "Sent", "Approved", "Rejected", "Cancelled"],
      headers: [
        {
          headerName: "Quotation ID",
          field: "quotation_id",
          sortable: true,
          minWidth: 100,
        },
        {
          headerName: "Customer",
          field: "customer_name",
          tooltipField: "customer_name",
          sortable: true,
        },
        {
          headerName: "Source",
          field: "source_name",
          tooltipField: "source_name",
          sortable: true,
        },
        {
          headerName: "Source Country",
          field: "source_country",
          tooltipField: "source_country",
          sortable: true,
          minWidth: 180,
        },
        {
          headerName: "Destination",
          field: "destination_name",
          tooltipField: "destination_name",
          sortable: true,
        },
        {
          headerName: "Destination Country",
          field: "destination_country",
          sortable: true,
          minWidth: 180,
        },
        {
          headerName: "Start Date",
          field: "start_date",
          sortable: true,
          tooltipValueGetter: (params) => {
            return this.$globals.formatDate(params.value);
          },
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value);
          },
        },
        {
          headerName: "End Date",
          field: "end_date",
          tooltipValueGetter: (params) => {
            return this.$globals.formatDate(params.value);
          },
          sortable: true,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value);
          },
        },
        {
          headerName: "Distance",
          field: "distance",
          sortable: true,
        },
        {
          headerName: "Service Type",
          field: "service_type",
          sortable: true,
        },
        {
          headerName: "Job Type",
          field: "job_type",
          sortable: true,
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: "QuotationButton",
          pinned: "right",
          minWidth: 110,
        },
      ],
      quotationList: [],

      pageNo: 1,
      itemsPerPage: 20,
      totalItems: 0,
      filters: {},

      tab: 0,

      // ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },

      permissions: null,
      searchTimeout: null,

      selectedQuotationId: null,
      displayDrawer: false,
    };
  },
  watch: {
    tab() {
      this.getQuotationList();
      this.displayDrawer = false;
    },
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (obj) {
        return obj;
      } else {
        return null;
      }
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },
  methods: {
    requestQuotation() {
      this.$router.push("/app/admin/quotations/sent");
    },
    applyGridSort(key, type) {
      if (this.filters && !this.filters.ordering) {
        this.filters.ordering = [];
      }

      if (type == null) {
        delete this.sorting[key];
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(`-${key}`),
          1
        );
      } else if (type == "asc") {
        this.filters.ordering.push(key);
      } else if (type == "desc") {
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(key),
          1,
          `-${key}`
        );
      }
      if (this.filters.ordering.length == 0) {
        delete this.filters.ordering;
      }
      this.itemsPerPageChanged(20);
    },
    resizeGrid() {
      if (this.gridOptions && this.gridOptions.api) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },
    searchRole() {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(() => {
        this.pageNo = 1;
        this.getQuotationList();
      }, 1000);
    },
    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getQuotationList();
    },
    prevPage() {
      this.pageNo--;
      this.getQuotationList();
    },
    nextPage() {
      this.pageNo++;
      this.getQuotationList();
    },
    getQuotationList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        status: this.statuses[this.tab],
        offset: this.offset,
        limit: this.itemsPerPage,
        ...this.filters,
      };
      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }
      this.$api.quotation
        .getQuotationList(params)
        .then((res) => {
          this.quotationList = res.data;
          this.totalItems = res.count;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    viewDetails(id) {
      this.selectedQuotationId = id;
      this.displayDrawer = true;
    },
    editQuotation(id) {
      this.$router.push(`/app/admin/quotations/edit/${id}`);
    },
    editadminQuotation(id) {
      this.$router.push(`/app/admin/quotations/sent-edit/${id}`);
    },
  },
  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("quotation");
      if (!permissions || !permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.permissions = { ...permissions };
        this.getQuotationList();
      }
    }
  },
};
</script>