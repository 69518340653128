<template>
  <v-overlay :value="showOverlay" color="blue-grey darken-2" z-index="1000">
    <v-row>
      <v-col cols="12">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-col>
      <v-col cols="12">
        <b class="pt-4 text-h6">{{ loadingMessage }}</b>
      </v-col>
    </v-row>
  </v-overlay>
</template>

<script>
export default {
  props: {
    showOverlay: {
      type: Boolean,
      default: false,
    },
    loadingMessage: {
      type: String,
      default: "Loading..",
    },
  },
};
</script>

<style></style>
