<template>
  <v-dialog v-model="showRenewDialog" max-width="500">
    <v-card>
      <v-card-title class="secondary white--text">
        <span>Invoice Detail</span>

        <v-spacer></v-spacer>

        <v-btn
          icon
          @click="
            showRenewDialog = false;
            delete invoiceDetail.invoice_date;
          "
        >
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-2">
        <v-form v-model="isValid" v-if="showRenewDialog">
          <v-row class="my-0">
            <v-col cols="12">
              <InputField
                type="text"
                label="Invoice Number *"
                hide-details="auto"
                :rules="[(v) => !!v || 'Invoice Number is Required']"
                v-model="invoiceDetail.invoice_no"
              />
            </v-col>
            <v-col cols="12">
              <DateField
                ref="dateField"
                :inputProps="{
                  dense: true,
                  label: 'Invoice Date *',
                  'hide-details': 'auto',
                  rules: [(val) => !!val || 'Invoice Date is Required!'],
                }"
                v-model="invoiceDetail.invoice_date"
                @input="$emit('removeFieldError')"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="d-flex justify-center">
        <v-btn
          depressed
          color="primary"
          large
          :disabled="!isValid"
          @click="invoiceDetailSubHire(id, subHireStatus)"
        >
          <span>Submit</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";

export default {
  components: { InputField, DateField },
  emits: ["submitRemarks", "removeFieldError"],
  props: {
    value: {
      type: Boolean,
    },
    formDetails: {
      type: Object,
      default: () => {},
    },
    formErrors: {
      type: Object,
      default: () => {},
    },
    subHireId: {
      required: true,
    },
    status: {
      required: true,
    },
    invoiceDetailNumber: {
      default: null,
    },
    invoiceDetailDate: {
      default: null,
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.id = this.subHireId;
        this.subHireStatus = this.status;
        if (this.subHireStatus == "Invoice Updated") {
          this.invoiceDetail.invoice_no = this.invoiceDetailNumber;
          this.invoiceDetail.invoice_date = this.invoiceDetailDate;
        }
      } else {
        this.id = null;
        this.subHireStatus = null;
        this.invoiceDetail.invoice_no = null;
        this.invoiceDetail.invoice_no = null;
      }
    },
  },
  data() {
    return {
      isValid: true,
      invoiceDetail: {
        invoice_no: null,
        invoice_date: null, 
        invoice_detail_update: true,
      },
      today: new Date().toISOString().slice(0, 10),
      subHireStatus: null,
    };
  },
  computed: {
    showRenewDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    errorObj: {
      get() {
        return this.formErrors;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    invoiceDetailSubHire(id, status) {
      this.$bus.$emit("showLoader", true);
      let payload = this.invoiceDetail;
      payload.status = status;
      this.$api.subHire
        .subhireStatusUpdate(id, payload)
        .then((res) => {
          this.$emit("refreshList");
          this.$bus.$emit("showToastMessage", {
            message: "Sub Hire Approved Successfully!",
            color: "success",
          });
          this.showRenewDialog = false;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          this.$bus.$emit("showToastMessage", {
            message: `${
              err.data && err.data.non_field_errors
                ? err.data.non_field_errors.join()
                : "Sub Hire can't be approved"
            }`,
            color: "error",
          });
          this.$bus.$emit("showLoader", false);
        });
    },
  },
};
</script>