var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
          _vm.params &&
          _vm.params.context &&
          _vm.params.context.parentComponent &&
          _vm.params.context.parentComponent.permissions &&
          _vm.params.context.parentComponent.permissions.add
        )?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":_vm.editItem}},'v-icon',attrs,false),on),[_vm._v("mdi-pencil")]):_vm._e()]}}])},[_c('span',[_vm._v("Edit")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }