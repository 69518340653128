<template>
  <v-dialog v-model="openLanguageDialog" max-width="400px">
    <v-card>
      <v-card-title class="background-primary text-white py-2">
        <v-row>
          <v-col cols="10">
            <span class="text-subtitle-1"> Change Language </span>
          </v-col>
          <v-col cols="2" class="d-flex justify-end">
            <v-btn small icon @click="openLanguageDialog = false">
              <v-icon class="text-white">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row no-gutters class="pt-6 pb-1">
          <v-select
            dense
            :items="language"
            outlined
            class="notranslate"
            label="Select Language"
            item-text="label"
            item-value="value"
            v-model="selectedLanguage"
            :menu-props="{ offsetY: true }"
            hide-details
          />
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="d-flex justify-end">
        <v-btn @click="submitForm" class="primary">Change</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getCookie } from "@/utils/functions";
export default {
  props: {
    value: Boolean,
  },
  data() {
    return {
      language: [
        {
          label: "English",
          value: "en",
        },
        {
          label: "Arabic",
          value: "ar",
        },
        {
          label: "Hindi",
          value: "hi",
        },
      ],
      selectedLanguage: null,
    };
  },
  computed: {
    openLanguageDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    openLanguageDialog(val) {
      if(val) {
        this.checkLang()
      }
    },
  },
  methods: {
    submitForm() {
      this.$emit("changeLanguage", this.selectedLanguage, true);
      this.openLanguageDialog = false;
    },
    checkLang() {
      if (getCookie("currentLanguage")) {
        this.selectedLanguage = getCookie("currentLanguage");
      }
    },
  },
};
</script>
