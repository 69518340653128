import { company, version, version2 } from "@/utils/constants";
import { handleError, handleResponse, handleRawResponse } from "@/utils/functions";

export default (axios) => ({
    getCompanyList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(company.companyBase, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getCompanySelectList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${company.companyBase}select/`, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getCompanyOptions(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .options(company.companyBase, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getCompanyDetails(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${company.companyBase}${id}/view/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getCompanyObject(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${company.companyBase}${id}/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    addCompany(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(company.companyBase, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    updateCompany(payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(`${company.companyBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    partiallyUpdateCompany(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${company.companyBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    uploadCompanyDocuments(payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${company.companyBase}${payload.id}/upload/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    deleteCompanyDocument(id) {
        return new Promise((resolve, reject) => {
            axios
                .delete(`${company.companyDocumentDeleteBase}${id}`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getCompanyDocumentOptions() {
        return new Promise((resolve, reject) => {
            axios
                .options(`${company.companyDocumentDeleteBase}`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getCompanyNotifications(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${version2}company_notification/`, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleRawResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    companyNotificationMarkAsRead(payload = {}) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${version2}company_notification/${payload.id}/mark_as_read/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    clearAllCompanyNotification(payload = {}) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${version2}company_notification/clear_all/`, payload)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    companyNotificationMarkAsCleared(payload = {}) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${version2}company_notification/${payload.id}/mark_as_cleared/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getMasterConfiguration(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${version2}master_configurations/`, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleRawResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    updateMasterConfiguration(payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${version2}master_configurations/`, payload)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
});