<template>
  <v-menu
    v-model="menuDialog"
    min-width="fit-content"
    :close-on-content-click="false"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">

      <v-text-field
        outlined
        readonly
        autocomplete="new-password"
        :value="formattedDate"
        v-bind="{ ...attrs, ...inputProps }"
        v-on="on"
        @click:clear="selectedDate = null"
      ></v-text-field>
    </template>

    <v-card>
      <div class="d-flex">
        <div>
          <v-date-picker
            v-bind="$attrs"
            v-model="modelDate"
            @input="fieldType != 'datetime' ? (menuDialog = false) : null"
          ></v-date-picker>
        </div>
        <div v-if="fieldType == 'datetime'" id="time-picker-container">
          <v-row no-gutters>
            <v-col cols="12" style="height: 88px; width: 243px" class="primary">
              <div
                class="
                  pa-2
                  fill-height
                  d-flex
                  align-end
                  custom-time-text
                  white--text
                "
              >
                <span>
                  {{ time }}
                </span>
              </div>
            </v-col>
            <v-col class="border-left-grey" cols="6">
              <v-list
                style="height: 290px; overflow-x: auto; min-width: 69px"
                nav
                dense
              >
                <v-list-item-group v-model="selectedHour" color="primary">
                  <v-list-item v-for="(hour, i) in hours" :key="i">
                    <v-list-item-content>
                      <v-list-item-title v-text="hour"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
            <v-col cols="6">
              <v-list
                style="height: 290px; overflow-x: auto; min-width: 69px"
                nav
                dense
              >
                <v-list-item-group v-model="selectedMin" color="primary">
                  <v-list-item v-for="(minute, i) in minutes" :key="i">
                    <v-list-item-content>
                      <v-list-item-title v-text="minute"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import moment from "moment";
export default {
  name: "InputTag",
  props: {
    value: {
      type: [String, Date, Number],
    },
    inputProps: {
      default: () => {},
    },
    fieldType: {
      default: "date",
    },
    hourStep: {
      default: 1,
    },
    minStep: {
      default: 1,
    },
  },
  computed: {
    selectedDate: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
        this.$emit("change", value);
      },
    },
    hours() {
      return [...Array(24).keys()]
        .filter((h) => parseInt(h) % parseInt(this.hourStep) == 0)
        .map((h) => (h < 10 ? `0${h}` : `${h}`));
    },
    minutes() {
      return [...Array(60).keys()]
        .filter((m) => parseInt(m) % parseInt(this.minStep) == 0)
        .map((m) => (m < 10 ? `0${m}` : `${m}`));
    },
    time() {
      if (this.selectedMin != null && this.selectedHour != null) {
        return `${
          parseInt(this.hours[this.selectedHour]) < 10
            ? "0" + parseInt(this.hours[this.selectedHour])
            : parseInt(this.hours[this.selectedHour])
        } : ${
          parseInt(this.minutes[this.selectedMin]) < 10
            ? "0" + parseInt(this.minutes[this.selectedMin])
            : parseInt(this.minutes[this.selectedMin])
        }`;
      } else {
        return "";
      }
    },
    formattedDate() {
      return this.fieldType == "datetime"
        ? this.modelDate
          ? this.$globals.formatDate(
              `${this.modelDate} ${this.time.replaceAll(" ", "")}`,
              true
            )
          : ""
        : this.$globals.formatDate(this.modelDate);
    },
  },
  data() {
    return {
      modelDate: null,
      selectedHour: 0,
      selectedMin: 0,
      menuDialog: false,
    };
  },
  watch: {
    selectedHour() {
      if (this.selectedMin == null) {
        this.selectedMin = 0;
      }
      this.setDateTime();
    },
    selectedMin() {
      this.setDateTime();
    },
    modelDate() {
      this.setDateTime();
    },
    selectedDate() {
      if (!this.modelDate) {
        this.setDefaultDateTime();
      } else {
        if (this.modelDate != null && !this.selectedDate) {
          this.modelDate = null;
          this.selectedHour = 0;
          this.selectedMin = 0;
        }
      }
    },
  },
  methods: {
    setDateTime() {
      if (this.fieldType == "datetime") {
        if (
          this.modelDate != null &&
          this.selectedMin != null &&
          this.selectedHour != null
        ) {
          this.selectedDate = `${this.modelDate}T${this.time.replaceAll(
            " ",
            ""
          )}`;
        }
      } else {
        if (this.modelDate) {
          this.selectedDate = `${this.modelDate}`;
        }
      }
    },
    setDefaultDateTime() {
      if (this.selectedDate) {
        if (this.fieldType == "datetime") {
          const data = this.selectedDate.split(" ");
          this.modelDate = data[0];
          if (data[1]) {
            const time = data[1].split(":");
            this.selectedHour = this.hours.indexOf(
              this.hours.find((h) => parseInt(h) == parseInt(time[0]))
            );
            this.selectedMin = this.minutes.indexOf(
              this.minutes.find((m) => parseInt(m) == parseInt(time[1]))
            );
          }
        } else {
          this.modelDate = this.selectedDate;
        }
      }
    },
    nextDate() {
      let date = moment(this.modelDate);
      date.add(1, "day");
      this.modelDate = date.format("yyyy-MM-DD");
    },
    previousDate() {
      let date = moment(this.modelDate);
      date.subtract(1, "day");
      this.modelDate = date.format("yyyy-MM-DD");
    },
  },
  mounted() {
    this.setDefaultDateTime();
  },
};
</script>

<style scoped>
#time-picker-container {
  width: 140px;
}
.custom-time-text {
  font-size: 34px;
  font-weight: 500;
}
</style>