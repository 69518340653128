import { unClass } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
    getUNClassList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(unClass.unClassBase, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getUNClassObject(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${unClass.unClassBase}${id}/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    addUNClass(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(unClass.unClassBase, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    activateDeactivateunClass(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(
                    `${unClass.unClassBase}${payload.id}/deactivate_unClass/`,
                    payload
                )
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    updateUNClass(payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(`${unClass.unClassBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    partiallyUpdateUNClass(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${unClass.unClassBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
});