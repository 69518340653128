<template>
  <FormComponent
    title="Quotation"
    backRoute="/app/admin/quotations/list"
    :nonFieldErrors="formErrors.non_field_errors"
  >
    <template #formActions>
      <v-btn
        color="primary"
        depressed
        class="mr-2"
        :disabled="!isValid"
        @click="submitForm(false)"
      >
        Submit
      </v-btn>

      <v-btn color="secondary" depressed @click="showRemarksForm = true">
        Cancel
      </v-btn>
    </template>

    <template #formContent>
      <v-row class="ma-0">
        <v-col cols="12" md="12" class="Height-Class pa-0">
          <v-form @submit.prevent v-model="isValid" ref="locationForm">
            <v-row class="ma-0">
              <v-col
                cols="12"
                class="text-subtitle-1 font-weight-bold font-italic pa-2"
                v-if="quotationDetails.is_contract_created"
              >
                Tariff ID : {{ quotationDetails.contract_id }}
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="6" class="pa-2">
                <InputField
                  hide-details="auto"
                  type="date"
                  label="Start Date"
                  readonly
                  :clearable="false"
                  v-model="quotationDetails.start_date"
                />
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="6" class="pa-2">
                <DateField
                  :inputProps="{
                    dense: true,
                    label: 'End Date *',
                    'hide-details': 'auto',
                    'error-messages': formErrors.end_date,
                    rules: [(v) => !!v || 'End Date is Required!'],
                  }"
                  :min="quotationDetails.start_date"
                  required
                  v-model="quotationDetails.end_date"
                  @input="delete formErrors.end_date"
                />
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="6" class="pa-2">
                <v-text-field
                  hide-details="auto"
                  label="Source Location Name"
                  id="sourceLocation"
                  ref="sourceLocation"
                  dense
                  outlined
                  readonly
                  :clearable="false"
                  v-model="quotationDetails.source_name"
                />
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="6" class="pa-2">
                <v-text-field
                  hide-details="auto"
                  label="Destination Location Name"
                  ref="destinationLocation"
                  id="destinationLocation"
                  dense
                  outlined
                  readonly
                  :clearable="false"
                  v-model="quotationDetails.destination_name"
                />
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="6" class="pa-2">
                <v-text-field
                  hide-details="auto"
                  label="Source State"
                  dense
                  outlined
                  readonly
                  :clearable="false"
                  v-model="quotationDetails.source_state"
                />
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="6" class="pa-2">
                <v-text-field
                  hide-details="auto"
                  label="Destination State"
                  dense
                  outlined
                  readonly
                  :clearable="false"
                  v-model="quotationDetails.destination_state"
                />
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="6" class="pa-2">
                <v-text-field
                  hide-details="auto"
                  label="Source Country Code"
                  dense
                  outlined
                  readonly
                  :clearable="false"
                  v-model="quotationDetails.source_country_code"
                />
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="6" class="pa-2">
                <v-text-field
                  hide-details="auto"
                  label="Destination Country Code"
                  dense
                  outlined
                  readonly
                  :clearable="false"
                  v-model="quotationDetails.destination_country_code"
                />
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="6" class="pa-2">
                <v-text-field
                  hide-details="auto"
                  label="Source Country"
                  dense
                  outlined
                  readonly
                  :clearable="false"
                  v-model="quotationDetails.source_country"
                />
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="6" class="pa-2">
                <v-text-field
                  hide-details="auto"
                  label="Destination Country"
                  dense
                  outlined
                  readonly
                  :clearable="false"
                  v-model="quotationDetails.destination_country"
                />
              </v-col>

              <v-col cols="12" sm="6" md="6" class="pa-2">
                <div class="d-flex justify-left">
                  <v-switch
                    hide-details="auto"
                    :label="`Is Source Port : ${
                      quotationDetails.is_source_port == 'Yes' ? 'Yes' : 'No'
                    }`"
                    true-value="Yes"
                    false-value="No"
                    class="mt-1"
                    tabindex="2"
                    :disabled="
                      quotationDetails.is_contract_created"
                    v-model="quotationDetails.is_source_port"
                  ></v-switch>
                </div>
              </v-col>

              <v-col cols="12" sm="6" md="6" class="pa-2">
                <div class="d-flex justify-left">
                  <v-switch
                    hide-details="auto"
                    :label="`Is Destination Port : ${
                      quotationDetails.is_destination_port == 'Yes'
                        ? 'Yes'
                        : 'No'
                    }`"
                    true-value="Yes"
                    false-value="No"
                    class="mt-1"
                    :disabled="
                      quotationDetails.is_contract_created"
                    tabindex="2"
                    v-model="quotationDetails.is_destination_port"
                  ></v-switch>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="pa-2">
                <v-text-field
                  hide-details="auto"
                  label="Distance *"
                  dense
                  outlined
                  :clearable="false"
                  :rules="[(v) => !!v || 'Distance is Required']"
                  v-model="quotationDetails.distance"
                />
              </v-col>

              <v-col cols="12" sm="6" md="4" class="pa-2">
                <v-text-field
                  hide-details="auto"
                  label="Service Type"
                  dense
                  outlined
                  readonly
                  :clearable="false"
                  v-model="quotationDetails.service_type"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" class="pa-2">
                <MultiSelect
                  hide-details="auto"
                  label="Job Type *"
                  :items="allJobType"
                  itemText="display_name"
                  disabled
                  itemValue="value"
                  v-model="quotationDetails.job_type"
                  :rules="[(v) => !!v || 'Job Type is Required']"
                  :error-messages="formErrors.job_type"
                  @change="delete formErrors.job_type"
                />
              </v-col>

              <v-col cols="12" class="px-2">
                <v-row
                  v-for="(rate, i) in quotationDetails.quotation_rates"
                  :key="i"
                  class="ma-0 heading rounded-lg mb-3"
                >
                  <v-col cols="12">
                    <span class="text-h6">
                      Container Details {{ i + 1 }}
                    </span>
                  </v-col>

                  <v-col cols="12" class="py-0">
                    <v-divider></v-divider>
                  </v-col>

                  <v-col cols="12" sm="6" md="4" class="pa-2">
                    <MultiSelect
                      hide-details="auto"
                      label="Container Type"
                      :items="allContainersType"
                      itemText="title"
                      itemValue="id"
                      readonly
                      v-model="rate.container_type"
                      :error-messages="
                        formErrors &&
                        formErrors.quotation_rates &&
                        formErrors.quotation_rates[i] &&
                        formErrors.quotation_rates[i].container_type
                          ? formErrors.quotation_rates[i].container_type
                          : null
                      "
                      @input="
                        formErrors &&
                        formErrors.quotation_rates &&
                        formErrors.quotation_rates[i] &&
                        formErrors.quotation_rates[i].container_type
                          ? (delete formErrors.quotation_rates[i]
                              .container_type,
                            checkErrors())
                          : null
                      "
                    />
                  </v-col>

                  <v-col cols="12" sm="6" md="4" class="pa-2">
                    <InputField
                      hide-details="auto"
                      type="text"
                      label="Container Count"
                      readonly
                      :clearable="false"
                      v-model="rate.container_count"
                    />
                  </v-col>

                  <v-col cols="12" sm="6" md="4" class="pa-2">
                    <InputField
                      hide-details="auto"
                      type="number"
                      :label="`Rate (${$globals.currency}) *`"
                      :rules="[(v) => !!v || 'Rate is Required!']"
                      required
                      v-model="rate.rate"
                      :error-messages="
                        formErrors &&
                        formErrors.quotation_rates &&
                        formErrors.quotation_rates[i] &&
                        formErrors.quotation_rates[i].rate
                          ? formErrors.quotation_rates[i].rate
                          : null
                      "
                      @input="
                        formErrors &&
                        formErrors.quotation_rates &&
                        formErrors.quotation_rates[i] &&
                        formErrors.quotation_rates[i].rate
                          ? (delete formErrors.quotation_rates[i].rate,
                            checkErrors())
                          : null
                      "
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>

      <RejectionRemarksDialog
        v-model="showRemarksForm"
        :formDetails="quotationDetails"
        fieldKey="admin_remarks"
        label="Cancellation Remarks"
        @submitRemarks="submitForm(true)"
      />
    </template>
  </FormComponent>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import FormComponent from "@/components/General/FormComponent.vue";
import GoogleMap from "@/components/General/GoogleMap.vue";
import RejectionRemarksDialog from "@/components/ModuleBased/dialogs/RejectionRemarksDialog.vue";
import { sourceMarker, destinationMarker } from "@/assets/images/mapIcons.js";
import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";
import DateField from "@/components/FormBaseComponents/DateField.vue";

export default {
  components: {
    InputField,
    MultiSelect,
    FormComponent,
    GoogleMap,
    RejectionRemarksDialog,
    DateField,
  },

  data() {
    return {
      sourceMarker,
      destinationMarker,
      isValid: true,
      quotationDetails: {
        quotation_rates: [{}],
      },
      formErrors: {},

      // autocomplete vars
      sourceLocationAutocomplete: null,
      markerInstance: null,

      locationTypeList: [],
      allContainersType: [],
      allJobType: [],
      showRemarksForm: false,

      directions: [],
      directionsTimeout: null,
      selectedRoute: {},
      markerTimeout: null,
    };
  },

  computed: {
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (obj) {
        return obj;
      } else {
        return null;
      }
    },
  },

  methods: {
    getQuotationOptions() {
      this.$api.quotation
        .getQuotationOptions()
        .then((res) => {
          this.allJobType = res.data.actions.POST.job_type.choices.map(
            (obj) => obj.value
          );
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getContainersType() {
      this.$api.containerType
        .getContainerTypeList({ limit: "all" })
        .then((res) => {
          this.allContainersType = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    checkErrors() {
      if (
        this.formErrors &&
        this.formErrors.quotation_rates &&
        (this.formErrors.quotation_rates.length == 0 ||
          this.formErrors.quotation_rates.every(
            (obj) => Object.keys(obj).length == 0
          ))
      ) {
        delete this.formErrors.quotation_rates;
      }
    },
    submitForm(isRejected = false) {
      this.$bus.$emit("showLoader", true);

      let payload = { id: this.$route.params.id };
      if (isRejected) {
        payload.data = {
          admin_remarks: this.quotationDetails.admin_remarks,
          status: "Cancelled",
        };
      } else {
        payload.data = {
          quotation_rates: this.quotationDetails.quotation_rates,
          status: "Sent",
          end_date: this.quotationDetails.end_date,
          job_type: this.quotationDetails.job_type,
          is_source_port: this.quotationDetails.is_source_port,
          is_destination_port: this.quotationDetails.is_destination_port,
          distance: this.quotationDetails.distance,
        };
      }

      this.$api.quotation
        .partiallyUpdateQuotation(payload)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Quotation requested!",
            color: "success",
          });
          this.$router.push("/app/admin/quotations/list");
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't request quotation!",
            color: "error",
          });
          this.formErrors = err.data;
        });
    },

    async getRoute() {
      if (
        this.quotationDetails.source_coordinates &&
        this.quotationDetails.destination_coordinates
      ) {
        if (this.directionsTimeout) {
          clearTimeout(this.directionsTimeout);
        }

        this.directionsTimeout = setTimeout(() => {
          this.$bus.$emit("showLoader", true);

          const directionConfig = {
            origin: {
              lat: parseFloat(
                this.quotationDetails.source_coordinates.latitude
              ),
              lng: parseFloat(
                this.quotationDetails.source_coordinates.longitude
              ),
            },
            destination: {
              lat: parseFloat(
                this.quotationDetails.destination_coordinates.latitude
              ),
              lng: parseFloat(
                this.quotationDetails.destination_coordinates.longitude
              ),
            },
            travelMode: "DRIVING",
            provideRouteAlternatives: true,
          };

          this.$refs.quotationMap
            .getDirections(directionConfig)
            .then(async (resp) => {
              const directions = resp.routes;
              this.$bus.$emit("showLoader", false);
              this.directions = [];

              let index = 0;
              while (directions.length > this.directions.length) {
                this.directions.push({
                  name: `Route ${index + 1}`,
                  distance: directions[index].legs[0].distance.text,
                  route: this.$refs.quotationMap.generateGeoJsonFromLineString(
                    await this.$refs.quotationMap.generateLineStringFromRoute(
                      directions[index]
                    )
                  ),
                });
                index++;
              }

              this.selectedRoute = this.directions[0];

              this.loadRoute();
              this.loadMarkers();
            })
            .catch((err) => {
              console.error(err);
              this.$bus.$emit("showLoader", false);
            });
        }, 200);
      }
    },

    loadRoute() {
      if (this.markerTimeout) {
        clearTimeout(this.markerTimeout);
      }
      this.markerTimeout = setTimeout(() => {
        this.quotationDetails = {
          ...this.quotationDetails,
          route: this.selectedRoute.route,
          distance: this.selectedRoute.distance,
        };

        this.$refs.quotationMap.addGeoCollection(this.quotationDetails.route);
      }, 500);
    },

    loadMarkers() {
      this.$refs.quotationMap.addMultipleMarkers([
        { ...this.quotationDetails.source_coordinates, type: "source" },
        {
          ...this.quotationDetails.destination_coordinates,
          type: "destination",
        },
      ]);
    },

    getQuotationDetails(id) {
      this.$api.quotation
        .getQuotationObject(id)
        .then((res) => {
          if (res.data.status != "Pending") {
            this.$router.push("/app/admin/quotations/list");
            return null;
          }
          this.quotationDetails = res.data;
          if (
            this.quotationDetails &&
            this.quotationDetails.job_type &&
            (this.quotationDetails.job_type == "Export" ||
              this.quotationDetails.job_type == "Import")
          ) {
            this.quotationDetails.is_source_port = "Yes";
          }

          if (this.quotationDetails.is_contract_created) {
            this.quotationDetails.start_date = res.data.contract_start_date;
            this.quotationDetails.end_date = res.data.contract_end_date;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("quotation");
      if (!permissions || !permissions.change) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.getQuotationDetails(this.$route.params.id);
        this.getQuotationOptions();
      }
    }
  },
  mounted() {
    this.getContainersType();
  },
};
</script>

<style scoped>
.Map-Height {
  height: calc(100vh - 221px);
}

.Height-Class {
  height: calc(100vh - 160px);
  overflow-y: auto;
}
</style>
