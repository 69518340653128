import { ticket } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
    getTicketList(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(ticket.ticketBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getTicketOptions(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .options(ticket.ticketBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addTicket(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(ticket.ticketBase, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  uploadTicketDocument(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${ticket.ticketBase}${payload.id}/upload/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getTicketDetails(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ticket.ticketBase}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updateTicketStatus(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${ticket.ticketBase}${payload.id}/update_status/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addTicketComment(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${ticket.ticketBase}${payload.id}/comment/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

});
