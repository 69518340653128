<template>
  <v-dialog max-width="500" v-model="showAdminReportInfoDialog">
    <v-card>
      <v-card-title class="secondary white--text">
        <span> Reports Filter Info </span>

        <v-spacer></v-spacer>

        <v-btn depressed icon @click="showAdminReportInfoDialog = false">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-2" v-if="showReportDialogInfo.payload">
        <v-row class="my-0">
          <v-col cols="12" v-if="showReportDialogInfo.payload.start_date">
            <span class="text-subtitle-2 font-weight-bold">Start Date</span>
            <p class="text-subtitle-1 mb-0">
              {{ showReportDialogInfo.payload.start_date | formatDate }}
            </p>
          </v-col>
          <v-col cols="12" v-if="showReportDialogInfo.payload.end_date">
            <span class="text-subtitle-2 font-weight-bold">End Date</span>
            <p class="text-subtitle-1 mb-0">
              {{ showReportDialogInfo.payload.end_date | formatDate }}
            </p>
          </v-col>
          <v-col cols="12" v-if="showReportDialogInfo.payload.customer_name">
            <span class="text-subtitle-2 font-weight-bold">Customer Name</span>
            <p class="text-subtitle-1 mb-0">
              {{ showReportDialogInfo.payload.customer_name }}
            </p>
          </v-col>
          <v-col cols="12" v-if="showReportDialogInfo.payload.transporter_name">
            <span class="text-subtitle-2 font-weight-bold"
              >Transporter Name</span
            >
            <p class="text-subtitle-1 mb-0">
              {{ showReportDialogInfo.payload.transporter_name }}
            </p>
          </v-col>
          <v-col cols="12" v-if="showReportDialogInfo.payload.sub_hire_status">
            <span class="text-subtitle-2 font-weight-bold"
              >Sub Hire Status</span
            >
            <p class="text-subtitle-1 mb-0">
              {{ showReportDialogInfo.payload.sub_hire_status }}
            </p>
          </v-col>
          <v-col cols="12" v-if="showReportDialogInfo.payload.status_start_date">
            <span class="text-subtitle-2 font-weight-bold"
              >Status Start Date</span
            >
            <p class="text-subtitle-1 mb-0">
              {{ showReportDialogInfo.payload.status_start_date }}
            </p>
          </v-col>
          <v-col cols="12" v-if="showReportDialogInfo.payload.status_end_date">
            <span class="text-subtitle-2 font-weight-bold"
              >Status End Date</span
            >
            <p class="text-subtitle-1 mb-0">
              {{ showReportDialogInfo.payload.status_end_date }}
            </p>
          </v-col>
          <v-col cols="12" v-if="showReportDialogInfo.payload.vehicle_name">
            <span class="text-subtitle-2 font-weight-bold">Vehicle Name</span>
            <p class="text-subtitle-1 mb-0">
              {{ showReportDialogInfo.payload.vehicle_name }}
            </p>
          </v-col>
          <v-col cols="12" v-if="showReportDialogInfo.payload.driver_name">
            <span class="text-subtitle-2 font-weight-bold">Driver Name</span>
            <p class="text-subtitle-1 mb-0">
              {{ showReportDialogInfo.payload.driver_name }}
            </p>
          </v-col>
          <v-col cols="12" v-if="showReportDialogInfo.payload.payment_mode">
            <span class="text-subtitle-2 font-weight-bold">Payment Mode</span>
            <p class="text-subtitle-1 mb-0">
              {{ showReportDialogInfo.payload.payment_mode }}
            </p>
          </v-col>
          <v-col cols="12" v-if="showReportDialogInfo.payload.da_status">
            <span class="text-subtitle-2 font-weight-bold">DA Status</span>
            <p class="text-subtitle-1 mb-0">
              {{ showReportDialogInfo.payload.da_status }}
            </p>
          </v-col>
          <v-col cols="12" v-if="showReportDialogInfo.payload.payment_term">
            <span class="text-subtitle-2 font-weight-bold">Payment Term</span>
            <p class="text-subtitle-1 mb-0">
              {{ showReportDialogInfo.payload.payment_term }}
            </p>
          </v-col>
          <v-col
            cols="12"
            v-if="showReportDialogInfo.payload.source_location_name"
          >
            <span class="text-subtitle-2 font-weight-bold"
              >Source Location Name</span
            >
            <p class="text-subtitle-1 mb-0">
              {{ showReportDialogInfo.payload.source_location_name }}
            </p>
          </v-col>
          <v-col
            cols="12"
            v-if="showReportDialogInfo.payload.mid_location_name"
          >
            <span class="text-subtitle-2 font-weight-bold"
              >Mid Location Name</span
            >
            <p class="text-subtitle-1 mb-0">
              {{ showReportDialogInfo.payload.mid_location_name }}
            </p>
          </v-col>
          <v-col
            cols="12"
            v-if="showReportDialogInfo.payload.destination_location_name"
          >
            <span class="text-subtitle-2 font-weight-bold"
              >Destination Location Name</span
            >
            <p class="text-subtitle-1 mb-0">
              {{ showReportDialogInfo.payload.destination_location_name }}
            </p>
          </v-col>
          <v-col cols="12" v-if="showReportDialogInfo.payload.job_type">
            <span class="text-subtitle-2 font-weight-bold">Job Type</span>
            <p class="text-subtitle-1 mb-0">
              {{ showReportDialogInfo.payload.job_type }}
            </p>
          </v-col>
          <v-col cols="12" v-if="showReportDialogInfo.payload.depot_name">
            <span class="text-subtitle-2 font-weight-bold">Depot Name</span>
            <p class="text-subtitle-1 mb-0">
              {{ showReportDialogInfo.payload.depot_name }}
            </p>
          </v-col>
          <v-col cols="12" v-if="showReportDialogInfo.payload.branch_name">
            <span class="text-subtitle-2 font-weight-bold">Branch Name</span>
            <p class="text-subtitle-1 mb-0">
              {{ showReportDialogInfo.payload.branch_name }}
            </p>
          </v-col>
          <v-col cols="12" v-if="showReportDialogInfo.payload.leg_start_date">
            <span class="text-subtitle-2 font-weight-bold">Leg Start Date</span>
            <p class="text-subtitle-1 mb-0">
              {{ showReportDialogInfo.payload.leg_start_date }}
            </p>
          </v-col>
          <v-col cols="12" v-if="showReportDialogInfo.payload.leg_end_date">
            <span class="text-subtitle-2 font-weight-bold">Leg End Date</span>
            <p class="text-subtitle-1 mb-0">
              {{ showReportDialogInfo.payload.leg_end_date }}
            </p>
          </v-col>
          <v-col cols="12" v-if="showReportDialogInfo.payload.boe">
            <span class="text-subtitle-2 font-weight-bold">BOE</span>
            <p class="text-subtitle-1 mb-0">
              {{ showReportDialogInfo.payload.boe }}
            </p>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    showReportDialogInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      filtersObject: {},
      allReports: [],
      showCustomers: false,
      today: new Date().toISOString().slice(0, 16),
    };
  },

  computed: {
    showAdminReportInfoDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {},
};
</script>
