import { currency } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
    getCurrencyList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(currency.currencyBase, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getCurrencySelectList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${currency.currencyBase}select/`, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getCurrencyDetails(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${currency.currencyBase}${id}/view/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getCurrencyObject(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${currency.currencyBase}${id}/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    addCurrency(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(currency.currencyBase, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    updateCurrency(payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(`${currency.currencyBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    partiallyUpdateCurrency(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${currency.currencyBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
});