import VueRouter from "vue-router";

import Main from "@/layouts/main.vue"
import Error from "@/layouts/error.vue"
import Admin from "@/layouts/admin.vue"
import Shipper from "@/layouts/shipper.vue"
import Driver from "@/layouts/driver.vue"

import Login from "@/pages/Login.vue"
import ForgotPassword from "@/pages/ForgotPassword.vue"

import AdminRoutes from "@/router/admin/index"
import ShipperRoutes from "@/router/customer/index"
import DriverRoutes from "@/router/driver/index"

const router = new VueRouter({
  mode: "hash",
  routes: [
    {
      path: "/",
      redirect: "/app",
    },
    {
      path: "/app",
      name: "app",
      component: Main,
      children: [
        {
          path: "login",
          name: "app-login",
          component: Login,
        },
        {
          path: "forgot-password",
          name: "app-forgot-password",
          component: ForgotPassword,
        },
        {
          path: "admin",
          name: "app-admin",
          component: Admin,
          children: AdminRoutes
        },
        {
          path: "customer",
          name: "app-customer",
          component: Shipper,
          children: ShipperRoutes
        },
        {
          path: "driver",
          name: "app-driver",
          component: Driver,
          children: DriverRoutes
        },
      ],
    },
    {
      path: "**",
      component: Error,
    }
  ],
});

export default router;