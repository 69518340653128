import Blank from "@/layouts/blank.vue"

import CustomerContractList from "@/pages/admin/customer-contract/CustomerContractList.vue";
import CustomerContractAdd from "@/pages/admin/customer-contract/CustomerContractAdd.vue";
import CustomerContractEdit from "@/pages/admin/customer-contract/CustomerContractEdit.vue";

const customerContractRoutes = [
  {
    path: "customer-contract",
    name: "app-admin-customer-contract",
    component: Blank,
    children: [
      {
        path: "/",
        redirect: "/app/admin/customer-contract/list",
      },
      {
        path: "list",
        name: "app-admin-customer-contract-list",
        component: CustomerContractList,
      },
      {
        path: "add",
        name: "app-admin-customer-contract-add",
        component: CustomerContractAdd,
      },
      {
        path: "edit",
        name: "app-admin-customer-contract-edit",
        component: Blank,
        children: [
          {
            path: "/",
            redirect: "/app/admin/customer-contract/list",
          },
          {
            path: ":id",
            name: "app-admin-customer-contract-edit-id",
            component: CustomerContractEdit,
          }
        ]
      },
    ]
  },
]

export default customerContractRoutes