<template>
  <v-select
    :items="items"
    :class="designClass"
    v-bind="$attrs"
    outlined
    :menu-props="{ bottom: true, offsetY: true }"
  ></v-select>
</template>

<script>
export default {
  name: 'SelectTag',
  props: {
    items: {
      type: Array,
      default: () => [],
    },

    designClass: {
      type: String,
    },
  },
}
</script>
