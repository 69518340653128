<template>
  <v-row>
    <v-col cols="12">
      <div id="invoiceAttachment" style="break-after: auto">
        <div class="black--text">
          <table class="Full-Width">
            <tbody>
              <tr>
                <td>
                  <table class="Full-Width">
                    <tbody>
                      <tr>
                        <td class="text-center" width="40%">
                          <img
                            alt="Brand Logo"
                            class="Brand-Logo"
                            src="@/assets/images/brand-logo.png"
                          />
                        </td>
                        <td width="30%">
                          <p class="text-h5 font-weight-bold mb-0">
                            Momentum Company Limited Saudi Arabia
                          </p>
                          <p class="text-h5 font-weight-bold mb-0">
                            شركة مومينتم السعودية المحدودة شركة شخص واحد
                          </p>
                          <p class="text-h6 font-weight-medium mb-0">
                            Khalid Bin Al Walid St Building 7953 Sharafiyah Dist
                          </p>
                          <p class="text-h6 font-weight-medium mb-0">
                            Jeddah 22234-4959 kingdom of Saudi Arabia
                          </p>
                          <p class="text-h6 font-weight-medium mb-0">
                            لشارع خالد بن الوليد المبنى رقم 7953 حي الشرفية
                          </p>
                          <p class="text-h6 font-weight-medium mb-0">
                            جدة 22234-4959 المملكة العربية السعودية
                          </p>
                          <p class="text-subtitle-1 mb-0">
                            Tel : +966591257663
                          </p>
                          <p class="text-subtitle-1 mb-0">
                            E-mail : info@momentumlogistics.com
                          </p>
                          <p class="text-h5 font-weight-bold mb-0">
                            C.R No: 4030457682 VAT No: 310562221900003
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <tr>
                <td>
                  <v-divider></v-divider>
                </td>
              </tr>

              <tr>
                <td class="text-center">
                  <p class="text-h5 font-weight-bold my-4">Tax Invoice</p>
                </td>
              </tr>

              <tr>
                <td>
                  <table class="Full-Width">
                    <tbody>
                      <tr>
                        <td colspan="2">
                          <v-row>
                            <v-col cols="12" lg="7">
                              <table>
                                <tbody>
                                  <tr>
                                    <th class="text-left Vertical-Align">
                                      Bill To
                                    </th>
                                    <th class="px-4 Vertical-Align">:</th>
                                    <td
                                      class="text-left"
                                      v-if="
                                        'company_details' in invoiceDetails &&
                                        invoiceDetails.company_details
                                      "
                                    >
                                      <p class="pa-0 ma-0">
                                        {{
                                          invoiceDetails.company_details
                                            .company_code
                                        }}
                                      </p>
                                      <p class="pa-0 ma-0">
                                        {{
                                          invoiceDetails.company_details
                                            .company_name
                                        }}
                                      </p>
                                      <p class="pa-0 ma-0">
                                        {{
                                          invoiceDetails.company_details
                                            .address_detail.address
                                        }}
                                      </p>
                                      <p class="pa-0 ma-0">
                                        {{
                                          invoiceDetails.company_details
                                            .full_company_contact_number
                                        }}
                                      </p>
                                    </td>

                                    <td v-else>
                                      <p
                                        class="pa-0 ma-0"
                                        v-if="invoiceDetails.company_code"
                                      >
                                        {{ invoiceDetails.company_code }}
                                      </p>
                                      <p
                                        class="pa-0 ma-0"
                                        v-if="invoiceDetails.company_name"
                                      >
                                        {{ invoiceDetails.company_name }}
                                      </p>
                                      <p
                                        class="pa-0 ma-0"
                                        v-if="invoiceDetails.company_address"
                                      >
                                        {{ invoiceDetails.company_address }}
                                      </p>
                                      <p
                                        class="pa-0 ma-0"
                                        v-if="
                                          invoiceDetails.company_contact_number
                                        "
                                      >
                                        {{
                                          invoiceDetails.company_contact_number
                                        }}
                                      </p>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td colspan="3">
                                      <br />
                                    </td>
                                  </tr>

                                  <tr>
                                    <th class="text-left">VAT NO.</th>
                                    <th class="px-4">:</th>
                                    <td
                                      class="text-left"
                                      v-if="invoiceDetails.company_vat_number"
                                    >
                                      {{ invoiceDetails.company_vat_number }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th class="text-left">CR NO.</th>
                                    <th class="px-4">:</th>
                                    <td
                                      class="text-left"
                                      v-if="invoiceDetails.company_cr_number"
                                    >
                                      {{ invoiceDetails.company_cr_number }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </v-col>

                            <v-col cols="12" lg="5">
                              <table>
                                <tbody>
                                  <tr v-if="invoiceDetails.invoice_no">
                                    <th class="text-left">Invoice No</th>
                                    <th class="px-4">:</th>
                                    <td>{{ invoiceDetails.invoice_no }}</td>
                                  </tr>

                                  <tr v-if="invoiceDetails.invoice_date">
                                    <th class="text-left">Invoice Date</th>
                                    <th class="px-4">:</th>
                                    <td>
                                      {{
                                        invoiceDetails.invoice_date
                                          | formatDate(false)
                                      }}
                                    </td>
                                  </tr>
                                  <tr v-if="invoiceDetails.invoice_date">
                                    <th class="text-left">Due Date</th>
                                    <th class="px-4">:</th>
                                    <td>
                                      {{
                                        invoiceDetails.invoice_date
                                          | formatDate(false)
                                      }}
                                    </td>
                                  </tr>
                                  <tr v-if="invoiceDetails.invoice_type">
                                    <th class="text-left">Invoice Type</th>
                                    <th class="px-4">:</th>
                                    <td>
                                      {{ invoiceDetails.invoice_type }}
                                    </td>
                                  </tr>

                                  <tr>
                                    <th class="text-left">UOM</th>
                                    <th class="px-4">:</th>

                                    <td>
                                      {{
                                        invoiceDetails.invoice_uom
                                          ? invoiceDetails.invoice_uom
                                          : "NIL"
                                      }}
                                    </td>
                                  </tr>

                                  <tr>
                                    <th class="text-left">Est Arrival</th>
                                    <th class="px-4">:</th>

                                    <td>
                                      {{
                                        invoiceDetails.est_arrival
                                          ? invoiceDetails.est_arrival
                                          : "NIL"
                                      }}
                                    </td>
                                  </tr>

                                  <tr>
                                    <th class="text-left">Type</th>
                                    <th class="px-4">:</th>

                                    <td>ROA - Road Freight</td>
                                  </tr>

                                  <tr>
                                    <th class="text-left">Ref No.</th>
                                    <th class="px-4">:</th>

                                    <td>
                                      {{
                                        invoiceDetails.bill_of_lading
                                          ? invoiceDetails.bill_of_lading
                                          : "NIL"
                                      }}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td colspan="3">
                                      <br />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </v-col>
                          </v-row>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr v-if="invoiceDetails.port_of_loading">
                        <th class="text-left">Port of Loading</th>
                        <th class="px-4">:</th>
                        <td>
                          {{ invoiceDetails.port_of_loading }}
                        </td>
                      </tr>

                      <tr v-if="invoiceDetails.port_of_discharge">
                        <th class="text-left">Port of Discharge</th>
                        <th class="px-4">:</th>
                        <td>
                          {{ invoiceDetails.port_of_discharge }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <tr>
                <td>
                  <table class="Full-Width Invoice-Table">
                    <thead>
                      <tr>
                        <th class="text-left">SR No.</th>
                        <th class="text-left">Description</th>
                        <th class="text-right">QTY</th>
                        <th class="text-right">Currency</th>
                        <th class="text-right">Unit Price</th>
                        <th class="text-right">Taxable Amount</th>
                        <th class="text-right">TAX %</th>
                        <th class="text-right">
                          VAT Amount ({{ $globals.currency }})
                        </th>
                        <th class="text-right">
                          Gross Amount ({{ $globals.currency }})
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(charge, i) in invoiceDetails.invoice_charges"
                        :key="i"
                      >
                        <td>{{ i + 1 }}</td>
                        <td>{{ charge.charge_title }}</td>

                        <td class="text-right">
                          {{ charge.quantity }}
                        </td>

                        <td class="text-right">SAR</td>

                        <td class="text-right">
                          {{ charge.da_rate | formatNumber(true) }}
                        </td>
                        <td class="text-right">
                          {{ charge.taxable_amount | formatNumber(true) }}
                        </td>

                        <td class="text-right">
                          {{ `${charge.tax_percentage}` }}
                        </td>
                        <td class="text-right">
                          {{ charge.tax_amount | formatNumber(true) }}
                        </td>
                        <td class="text-right">
                          {{ charge.gross_amount | formatNumber(true) }}
                        </td>
                      </tr>

                      <tr>
                        <td rowspan="5"></td>
                        <td rowspan="5"></td>
                        <td rowspan="5"></td>
                        <td rowspan="5"></td>
                        <td colspan="4" class="text-right">
                          <p>
                            AMOUNT BEFORE 15% TAX المضافة القيمة ضريبة قبل
                            المبلغ
                          </p>
                        </td>
                        <td colspan="4" class="text-right">
                          {{ invoiceDetails.total_amount | formatNumber(true) }}
                        </td>
                      </tr>

                      <tr>
                        <td colspan="4" class="text-right">
                          <p>VALUE ADDED TAX 15% المضافة القيمة ض</p>
                        </td>
                        <td colspan="4" class="text-right">
                          {{
                            invoiceDetails.total_tax_amount | formatNumber(true)
                          }}
                        </td>
                      </tr>

                      <tr>
                        <td colspan="4" class="text-right">
                          <p>TOTAL INVOICE AMOUNT للفاتورة الإجمالي المبلغ</p>
                        </td>
                        <td class="text-right">
                          {{
                            invoiceDetails.total_gross_amount
                              | formatNumber(true)
                          }}
                        </td>
                      </tr>

                      <tr>
                        <td colspan="8">
                          <span class="text-subtitle-1 font-weight-bold">
                            {{ $globals.currency }}
                          </span>
                          <span class="text-subtitle-1">
                            {{ invoiceDetails.total_gross_amount_words }}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <th>Remarks :</th>
                        <td>{{ invoiceDetails.remarks }}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <tr>
                <td>
                  <v-row class="my-10 px-3">
                    <v-col cols="12">
                      <span class="text-subtitle-1">
                        Please Remit Payment To :
                      </span>
                    </v-col>
                    <v-col
                      cols="6"
                      class="Border-Top-Class Border-Left-Class Border-Right-Class"
                    >
                      <table>
                        <tbody>
                          <tr>
                            <th class="text-left">Beneficiary Name :</th>
                          </tr>
                          <tr>
                            <td>Momentum Company Limited Saudi Arabia (LLC)</td>
                          </tr>
                        </tbody>
                      </table>
                    </v-col>

                    <v-col cols="6" class="Border-Top-Class Border-Right-Class">
                      <table>
                        <tbody>
                          <tr>
                            <th class="text-left">Swift Code :</th>
                            <td>BSFRSARIXXX</td>
                          </tr>
                        </tbody>
                      </table>
                    </v-col>

                    <v-col
                      cols="6"
                      class="Border-Bottom-Class Border-Top-Class Border-Left-Class Border-Right-Class"
                    >
                      <table>
                        <tbody>
                          <tr>
                            <th class="text-left">Bank Name & Address :</th>
                          </tr>
                          <tr>
                            <td>
                              BANQUE SAUDI FRANSI - KING ABDUL AZIZ STREET
                              BRANCH, AL KHOBAR, 31952, KSA
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </v-col>

                    <v-col
                      cols="6"
                      class="Border-Bottom-Class Border-Top-Class Border-Right-Class"
                    >
                      <table>
                        <tbody>
                          <tr>
                            <th class="text-left">Account Number :</th>
                            <td>988037-002-56</td>
                          </tr>
                          <tr>
                            <th class="text-left">Account Currency :</th>
                            <td>Saudi Riyal</td>
                          </tr>
                          <tr>
                            <th class="text-left">IBAN Number :</th>
                            <td>SA5655000000098803700256</td>
                          </tr>
                        </tbody>
                      </table>
                    </v-col>
                  </v-row>
                </td>
              </tr>

              <tr>
                <td>
                  <table class="Full-Width">
                    <tbody>
                      <tr>
                        <td>
                          <span class="text-subtitle-1 font-weight-bold">
                            Terms and conditions:
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <tr>
                <td>
                  <span class="text-subtitle-1">
                    Verify the invoice and report discrepancy, if any, within 7
                    days from the date of receipt of the invoice, otherwise the
                    invoice will be be assumed as accurate and accepted by
                    customer.
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  <table class="Full-Width">
                    <tbody>
                      <tr>
                        <td>
                          <span class="text-subtitle-1 font-weight-bold">
                            الشروط و الحكام
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <span class="text-subtitle-1">
                    تحقق من الفاتورة و الفوارق، إن وجدت، على أن يتم التبليغ عنها
                    في غضون 7 أيام من تاريخ استلم الفاتورة ، وإل سيتم افتراض
                    الفاتورة على أنها دقيقة وقبله العميل
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    invoiceDetails: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style scoped>
#invoiceAttachment {
  font-family: "Roboto", sans-serif !important;
}

.Invoice-Table,
.Invoice-Table th,
.Invoice-Table td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 5px;
  margin-top: 12px;
}

.Border-Top-Class {
  border-top: 1px solid black;
}

.Border-Bottom-Class {
  border-bottom: 1px solid black;
}

.Border-Left-Class {
  border-left: 1px solid black;
}

.Border-Right-Class {
  border-right: 1px solid black;
}

.Brand-Logo {
  width: 200px;
}

.d-flex {
  display: flex !important;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.Vertical-Align {
  vertical-align: top;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.px-3 {
  padding-right: 12px !important;
  padding-left: 12px !important;
}

.px-4 {
  padding-right: 16px !important;
  padding-left: 16px !important;
}

.my-4 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.my-10 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.text-h5 {
  font-size: 1.5rem !important;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: normal !important;
  font-family: "Roboto", sans-serif !important;
}

.text-h6 {
  font-size: 1.25rem !important;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em !important;
  font-family: "Roboto", sans-serif !important;
}

.text-subtitle-1 {
  font-size: 1rem !important;
  font-weight: normal;
  line-height: 1.75rem;
  letter-spacing: 0.009375em !important;
  font-family: "Roboto", sans-serif !important;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.Check-Box {
  margin-top: 0px;
  padding-top: 0px;
}

.Check-Box
  .v-input__control
  .v-input__slot
  .v-input--selection-controls__input
  .v-input--selection-controls__ripple {
  height: 25px;
  width: 25;
  left: 0px;
  top: calc(50% - 13px);
  margin: 0px;
}
</style>
