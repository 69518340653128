<template>
  <ListComponent title="Order">
    <template #list>
      <v-row class="ma-0">
        <v-col cols="4" class="px-0 pt-0 pb-2 d-flex align-center">
          <v-text-field
            v-model="filters.search"
            append-icon="mdi-magnify"
            label="Search"
            hide-details
            outlined
            dense
            clearable
            @input="searchOrder"
            class="mr-2"
          ></v-text-field>

          <Columns :headers="headers" :gridColInstance="gridColumnApi" />
        </v-col>

        <v-col cols="4" class="px-0 pt-0 pb-2">
          <v-tabs height="35" v-model="tab" centered>
            <v-tabs-slider></v-tabs-slider>
            <v-tab> Pending </v-tab>
            <v-tab> Active </v-tab>
            <v-tab> Completed </v-tab>
            <v-tab> Cancelled </v-tab>
          </v-tabs>
        </v-col>

        <v-col
          v-if="totalItems > 0"
          cols="4"
          class="px-0 pt-0 pb-2 d-flex justify-end"
        >
          <Pagination
            :pageNo="pageNo"
            :totalItems="totalItems"
            :pageSize="itemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>

        <v-col cols="12" class="pa-0">
          <AgGridVue
            style="width: 100%; height: calc(100vh - 172px)"
            class="ag-theme-alpine"
            id="myGrid"
            :columnDefs="headers"
            :context="context"
            @grid-ready="onGridReady"
            @columnMoved="resizeGrid"
            @columnVisible="resizeGrid"
            :defaultColDef="defaultColDef"
            :grid-options="gridOptions"
            :suppressDragLeaveHidesColumns="true"
            :rowData="allCustomerOrderList"
          ></AgGridVue>
        </v-col>
      </v-row>
    </template>

    <template #actions>
      <v-btn
        v-if="permissions && permissions.add"
        @click="addOrder"
        color="primary"
        depressed
      >
        <v-icon>mdi-plus</v-icon>
        <span>Add Order</span>
      </v-btn>
    </template>
  </ListComponent>
</template>

<script>
import Columns from "@/components/General/Columns.vue";
import ListComponent from "@/components/General/ListComponent.vue";
import { AgGridVue } from "ag-grid-vue";
import CustomerOrderButton from "@/components/AgGridComponents/CustomerOrderButton.vue";
import Pagination from "@/components/General/Pagination.vue";
import CustomHeader from "@/components/AgGridComponents/General/CustomHeader.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    Columns,
    ListComponent,
    AgGridVue,
    CustomerOrderButton,
    agColumnHeader: CustomHeader,
    Pagination,
  },

  watch: {
    tab() {
      this.getCustomerOrderList();
    },
  },

  data() {
    return {
      sorting: {},
      headers: [
        {
          headerName: "Order No",
          field: "order_no",
          sortable: true,
        },
        { headerName: "BOE/Refernce No", field: "boe", sortable: true },
        { headerName: "Payment Term", field: "payment_term" },
        {
          headerName: "Order Date",
          field: "created",
          sortable: true,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value, true);
          },
        },
        {
          headerName: "Containers/Utilized",
          field: "total_and_utilized_container",
        },
        { headerName: "Total DA(s)", field: "order_da_count" },
        { headerName: "Direct DA|Order", field: "is_direct_order_da" },
        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: "CustomerOrderButton",
          pinned: "right",
          minWidth: 110,
        },
      ],

      // Pagination Vars
      tab: null,
      itemsPerPage: 20,
      pageNo: 1,
      totalItems: 0,
      filters: {},

      // Ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      permissions: null,
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },

      allCustomerOrderList: [],
    };
  },

  computed: {
    context() {
      return { parentComponent: this };
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
    currentProfile() {
      if (JSON.parse(localStorage.getItem("userProfile"))) {
        return JSON.parse(localStorage.getItem("userProfile"));
      } else {
        return null;
      }
    },
  },

  methods: {
    applyGridSort(key, type) {
      if (this.filters && !this.filters.ordering) {
        this.filters.ordering = [];
      }

      if (type == null) {
        delete this.sorting[key];
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(`-${key}`),
          1
        );
      } else if (type == "asc") {
        this.filters.ordering.push(key);
      } else if (type == "desc") {
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(key),
          1,
          `-${key}`
        );
      }
      if (this.filters.ordering.length == 0) {
        delete this.filters.ordering;
      }
      this.itemsPerPageChanged(20);
    },
    resizeGrid() {
      if (this.gridOptions && "api" in this.gridOptions) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },

    searchOrder() {
      this.pageNo = 1;
      this.getCustomerOrderList();
    },

    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getCustomerOrderList();
    },

    prevPage() {
      this.pageNo--;
      this.getCustomerOrderList();
    },

    nextPage() {
      this.pageNo++;
      this.getCustomerOrderList();
    },

    getCustomerOrderList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        offset: this.offset,
        limit: this.itemsPerPage,
        ...params,
        ...this.filters,
        customer: this.currentProfile.company,
        status:
          this.tab == 0
            ? "Pending"
            : this.tab == 1
            ? "Active"
            : this.tab == 2
            ? "Completed"
            : "Cancelled",
      };
      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }
      this.$api.customerOrder
        .getCustomerOrderList(params)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.allCustomerOrderList = res.data;
          this.totalItems = res.count;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    addOrder() {
      this.$router.push({
        path: "/app/customer/order/add",
        query: {
          active_tab: this.tab,
        },
      });
    },

    viewDeliveryAdvice(id) {
      this.$router.push({
        path: `/app/customer/order/details/${id}`,
        query: {
          active_tab: this.tab,
        },
      });
    },

    editOrder(id) {
      this.$router.push(`/app/customer/order/edit/${id}`);
    },

    checkQuery() {
      if ("active_tab" in this.$route.query) {
        this.tab = parseInt(this.$route.query.active_tab);
      }
    },
  },

  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("order");
      if (!permissions || !permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.permissions = { ...permissions };
      }
    }
  },
  mounted() {
    this.checkQuery();
  },
};
</script>
