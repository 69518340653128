<template>
  <v-dialog width="500" v-model="showOdometerDialog">
    <v-card>
      <v-card-title class="secondary white--text">
        <span> Change Odometer Reading </span>

        <v-spacer></v-spacer>

        <v-btn icon @click="showOdometerDialog = false">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-2">
        <v-form ref="daListForm" v-if="showOdometerDialog" v-model="isValid">
          <v-row class="my-0">
            <v-col cols="12" class="Form-Columns">
              <InputField
                type="number"
                :label="`Start Odometer Reading *`"
                required
                :rules="[
                  (val) => !!val || 'Start Odometer Reading is Required!',
                ]"
                hide-details="auto"
                :disabled="
                  newStatus == 'Completed' && !!tripDetails.trip_start_km
                "
                v-model="formDetails.trip_start_km"
                :error-messages="formErrors.trip_start_km"
                @input="delete formErrors.trip_start_km"
              />
            </v-col>
            <v-col
              cols="12"
              class="Form-Columns"
              v-if="newStatus == 'Completed'"
            >
              <InputField
                type="number"
                :label="`End Odometer Reading *`"
                required
                :rules="[(val) => !!val || 'End Odometer Reading is Required!']"
                hide-details="auto"
                v-model="formDetails.trip_end_km"
                :error-messages="formErrors.trip_end_km"
                @input="delete formErrors.trip_end_km"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          depressed
          @click="submitForm"
          :disabled="!isValid"
        >
          Save
        </v-btn>

        <v-btn
          class="ml-3"
          color="secondary"
          depressed
          @click="showOdometerDialog = false"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import SelectField from "@/components/FormBaseComponents/SelectField.vue";
import { AgGridVue } from "ag-grid-vue";

export default {
  components: {
    AgGridVue,
    InputField,
    SelectField,
    MultiSelect,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    newStatus: {
      type: String,
      default: "",
    },
    tripDetails: {
      type: Object,
      default: Object,
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.getGPSOdometerReading();
      } else {
        this.resetForm();
      }
    },
  },
  computed: {
    showOdometerDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    context() {
      return { parentComponent: this };
    },
  },
  data() {
    return {
      formErrors: {},
      formDetails: {},
      isValid: true,
      gpsOdometerReading: null,
    };
  },
  methods: {
    submitForm(payload = {}) {
      this.$bus.$emit("showLoader", true);
      this.formErrors = {};
      payload = {
        ...this.formDetails,
        new_trip_status: this.newStatus,
      };
      let params = {
        id: this.tripDetails.id,
        data: payload,
      };
      this.$api.trip
        .updateOdometerReading(params)
        .then(() => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Odometer Reading successfully updated!",
            color: "success",
          });
          this.resetForm();
          this.showOdometerDialog = false;
          this.fieldValue = null;
          this.$emit("refreshList");
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          if (err && err.data && err.data.non_field_errors) {
            this.$bus.$emit("showToastMessage", {
              message: err.data.non_field_errors[0],
              color: "error",
              hasActions: true,
            });
          } else {
            this.formErrors = err.data;
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't udpate Odometer Reading!",
              color: "error",
            });
          }
        });
    },
    getGPSOdometerReading() {
      this.$bus.$emit("showLoader", true);
      this.$api.trip
        .getGPSOdometerReading(this.tripDetails.id)
        .then((res) => {
          this.gpsOdometerReading = res.data;

          if (
            (!this.gpsOdometerReading &&
              this.tripDetails &&
              this.tripDetails.trip_start_km) ||
            (this.tripDetails && this.tripDetails.trip_start_km)
          ) {
            this.formDetails.trip_start_km = this.tripDetails.trip_start_km;
          } else if (
            this.gpsOdometerReading &&
            this.gpsOdometerReading.odometer_reading
          ) {
            this.formDetails = {
              trip_start_km: Math.round(
                this.gpsOdometerReading.odometer_reading
              ),
            };
          }
          if (this.newStatus == "Completed") {
            this.formDetails = {
              ...this.formDetails,
              trip_end_km: Math.round(this.gpsOdometerReading.odometer_reading),
            };
          }
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    resetForm() {
      this.showOdometerDialog = false;
      this.formDetails = {};
      this.formErrors = {};
      this.$emit("revertTripStatus");
    },
  },
};
</script>
