<template>
  <div>
    <p>
      <span><v-icon>mdi-web</v-icon></span>
      <b class="ml-2">{{ $t("change language") }}</b>
    </p>

    <v-radio-group  class="ml-5" v-model="$i18n.locale">
      <v-radio
        label="English"
        value="en"
        @click="getChangeLanguage()"
      ></v-radio>
      <v-radio label="Arabic" value="ar" @click="getChangeLanguage()"></v-radio>
    </v-radio-group>

    
  </div>
</template>

<script>
export default {
  name: "LocaleSwitcher",
  data() {
    return {
      locales: ["en", "ar"],
      driverDetails: {},
      selectedLanguage: null,
    };
  },
  methods: {

    getChangeLanguage(payload = {}) {
      this.$bus.$emit("showLoader", true);
      payload = {
        language_change: true,
        driver_language: this.$i18n.locale,
      };

      this.$api.driverApp
        .updateDriverAppTrip(payload)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Langauge Changed successfully!",
            color: "success",
          });
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
  beforeCreate() {
    this.$api.authentication
      .getProfileInfo()
      .then((res) => {
        this.userType = res.data.user_type.toLowerCase();
        this.driverDetails = res.data;
        this.$bus.$emit("showLoader", false);
        localStorage.setItem("userProfile", JSON.stringify(res.data));
        localStorage.setItem("user_type", this.userType);
        const preference = res?.data?.user_preferences || {};
        const selectedLanguage = res?.data?.language_preference;
        this.$i18n.locale = selectedLanguage
        localStorage.setItem("userPreferences", JSON.stringify(preference));
        localStorage.setItem(
          "permissions",
          JSON.stringify(res.data.permissions)
        );
        if (res.data && this.userType) {
          this.setupWS(this.userType);
        }
      })
      .catch((err) => {
        this.$bus.$emit("showLoader", false);
      });
  },
};
</script>
