import Blank from "@/layouts/blank.vue"

import ADRClass from "@/pages/admin/configuration/ADRClass.vue"
import Bank from "@/pages/admin/configuration/Bank.vue"
import Branch from "@/pages/admin/configuration/Branch.vue"
import Charge from "@/pages/admin/configuration/Charge.vue"
import CheckList from "@/pages/admin/configuration/CheckList.vue"
import CompanyEdit from "@/pages/admin/configuration/CompanyEdit.vue"
import Country from "@/pages/admin/configuration/Country.vue"
import Currency from "@/pages/admin/configuration/Currency.vue"
import Department from "@/pages/admin/configuration/Department.vue"
import Depot from "@/pages/admin/configuration/Depot.vue"
import EmailSubscriber from "@/pages/admin/configuration/EmailSubscriber.vue"
import IMOClass from "@/pages/admin/configuration/IMOClass.vue"
import Master from "@/pages/admin/configuration/Master.vue"
import Milestone from "@/pages/admin/configuration/Milestone.vue"
import Nationality from "@/pages/admin/configuration/Nationality.vue"
import UNClass from "@/pages/admin/configuration/UNClass.vue"

const ConfigurationsRoutes = [
  {
    path: "configuration",
    name: "app-admin-configuration",
    component: Blank,
    children: [
      {
        path: "/",
        redirect: "/app/admin/dashboard",
      },
      {
        path: "adr-class",
        name: "app-admin-configuration-adr-class",
        component: ADRClass,
      },
      {
        path: "bank",
        name: "app-admin-configuration-bank",
        component: Bank,
      },
      {
        path: "branch",
        name: "app-admin-configuration-branch",
        component: Branch,
      },
      {
        path: "charge",
        name: "app-admin-configuration-charge",
        component: Charge,
      },
      {
        path: "check-list",
        name: "app-admin-configuration-check-list",
        component: CheckList,
      },
      {
        path: "company-edit",
        name: "app-admin-configuration-company-edit",
        component: CompanyEdit,
      },
      {
        path: "country",
        name: "app-admin-configuration-country",
        component: Country,
      },
      {
        path: "currency",
        name: "app-admin-configuration-currency",
        component: Currency,
      },
      {
        path: "department",
        name: "app-admin-configuration-department",
        component: Department,
      },
      {
        path: "depot",
        name: "app-admin-configuration-depot",
        component: Depot,
      },
      {
        path: "email-subscriber",
        name: "app-admin-configuration-email-subscriber",
        component: EmailSubscriber,
      },
      {
        path: "imo-class",
        name: "app-admin-configuration-imo-class",
        component: IMOClass,
      },
      {
        path: "master",
        name: "app-admin-configuration-master",
        component: Master,
      },
      {
        path: "milestone",
        name: "app-admin-configuration-milestone",
        component: Milestone,
      },
      {
        path: "nationality",
        name: "app-admin-configuration-nationality",
        component: Nationality,
      },
      {
        path: "un-class",
        name: "app-admin-configuration-un-class",
        component: UNClass,
      },
    ]
  },
]

export default ConfigurationsRoutes;