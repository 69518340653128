import { location } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
    getLocationList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(location.locationBase, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getLocationSelectList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${location.locationBase}select/`, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    getLocationTypeOptions(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .options(location.locationBase, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    getLocationObject(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${location.locationBase}${id}/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    addLocation(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(location.locationBase, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    updateLocation(payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(`${location.locationBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
})