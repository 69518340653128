<template>
  <div>
    <v-card style="background-color: #ffece0">
      <v-card-title>
        <v-row>
          <v-col cols="6" class="d-flex align-center py-2">
            <v-icon small class="pr-3" color="black">
              mdi-map-marker-path
            </v-icon>
            <h5 class="font-weight-light">{{ tripDetails.trip_id }}</h5>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
    <v-row no-gutters class="py-3 px-4">
      <!-- <v-col cols="12" class="py-3">
        <v-card elevation="0">
          <v-card-text v-if="tripDetails.trip_start_km">
            <table class="Primary-Table" >
              <tbody>
                <tr >
                  <td>Trip Start Kms</td>
                  <th class="py-1">{{ tripDetails.trip_start_km }}</th>
                </tr>
              </tbody>
            </table>
          </v-card-text>
        </v-card>
      </v-col> -->
      <v-col cols="12" class="py-3">
          <h4 class="font-weight-light">{{$t('odometer reading(kms)')}}: 
            <span class="float-right">{{ tripDetails.previous_odometer_reading }}</span>
          </h4>
          <h4 class="font-weight-light">{{$t('fleet No.')}}
            <span class="float-right">{{ tripDetails.inventory_number }}</span>
          </h4>
      </v-col>
      <v-col cols="12">
        <InputField
          hide-details="auto"
          type="number"
          label="Odometer Reading (Kms) *"
          :clearable="true"
          v-model="odometerReading"
        />
      </v-col>
    </v-row>
    <div class="btn-position-fixed-bottom text-center py-1">
      <v-btn
        @click="updateDriverAppTrip"
        class="primary text-capitalize font-weight-bold"
        :disabled="!odometerReading"
        style="width: 100px"
        >Update</v-btn
      >
    </div>
  </div>
</template>
  
<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";

export default {
  props: {
    odometerReadingDialog: {
      type: Boolean,
    },
    odometerReadingType: {
      type: String,
      required: true,
    },
    tripDetails: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      odometerReading: null,
    };
  },
  components: {
    InputField,
  },

  methods: {
    updateDriverAppTrip() {
      this.$bus.$emit("showLoader", true);
      let payload = {
        request_type: "update_km",
        odometer_reading_type: this.odometerReadingType,
      };
      if (this.odometerReadingType && this.odometerReadingType == "start") {
        payload.trip_start_km = parseInt(this.odometerReading);
      } else if (
        this.odometerReadingType &&
        this.odometerReadingType == "end"
      ) {
        payload.trip_end_km = parseInt(this.odometerReading);
      }
      this.$api.driverApp
        .updateDriverAppTrip(payload)
        .then((res) => {
          if (this.odometerReadingType == "start") {
            this.$emit("closeOdometerReadingDialog");
            this.$emit("refreshTripDetails");
          } else if (this.odometerReadingType == "end") {
            this.$emit("completeTrip");
          }
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Odometer reading updated successfully!",
            color: "success",
            hasActions: true,
          });
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
          if (err.data && err.data.error && err.data.error.non_field_errors) {
            this.$bus.$emit("showToastMessage", {
              message: `${
                err.data && err.data.error && err.data.error.non_field_errors
                  ? err.data.error.non_field_errors.join()
                  : "Couldn't update odometer reading!"
              }`,
              color: "error",
              hasActions: true,
            });
          } else {
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't update odometer reading!",
              color: "error",
              hasActions: true,
            });
          }
        });
    },
  },
};
</script>
    
    
<style scoped lang="scss">
.btn-position-fixed-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.card-outlined {
  border: 1px solid #0000004f !important;
  border-radius: 6px;
}
</style>