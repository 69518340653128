import { customerAddress } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
    getCustomerAddressList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(customerAddress.customerAddressBase, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getCustomerAddressSelectList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${customerAddress.customerAddressBase}select/`, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    addCustomerAddress(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(customerAddress.customerAddressBase, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getCustomerAddressObject(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${customerAddress.customerAddressBase}${id}/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    updateCustomerAddress(payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(
                    `${customerAddress.customerAddressBase}${payload.id}/`,
                    payload.data
                )
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    partiallyUpdateCustomerAddress(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(
                    `${customerAddress.customerAddressBase}${payload.id}/`,
                    payload.data
                )
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
});