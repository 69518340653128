var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"ma-0"},[(_vm.dashboardData.contracts)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-card',{staticClass:"fill-height"},[_c('v-card-title',{staticClass:"grey darken-1 py-3"},[_c('span',{staticClass:"text-h6 white--text"},[_vm._v(" Tariff ")])]),_c('v-card-text',{staticClass:"mt-4"},[_c('table',{staticClass:"Primary-Table"},[_c('tbody',_vm._l((_vm.dashboardData.contracts),function(value,key){return _c('tr',{key:key},[_c('td',[_vm._v(_vm._s(key.replace(/_+/g, " ")))]),_c('th',[_vm._v(_vm._s(value))])])}),0)])])],1)],1):_vm._e(),(_vm.dashboardData.totals)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-card',{staticClass:"fill-height"},[_c('v-card-title',{staticClass:"grey darken-1 py-3"},[_c('span',{staticClass:"text-h6 white--text"},[_vm._v(" Others ")])]),_c('v-card-text',{staticClass:"mt-4"},[_c('table',{staticClass:"Primary-Table"},[_c('tbody',_vm._l((_vm.dashboardData.totals),function(value,key){return _c('tr',{key:key},[_c('td',[_vm._v(_vm._s(key.replace(/_+/g, " ")))]),_c('th',[_vm._v(_vm._s(value))])])}),0)])])],1)],1):_vm._e(),_c('v-col',{staticClass:"mb-4",attrs:{"cols":"12"}},[_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('p',{staticClass:"text-h6"},[_vm._v("Charts")]),_c('v-spacer')],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('DateField',{attrs:{"inputProps":{
        dense: true,
        label: 'Start Date',
        'hide-details': 'auto',
      },"max":_vm.today},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('DateField',{attrs:{"inputProps":{
        dense: true,
        label: 'End Date',
        'hide-details': 'auto',
      },"min":_vm.startDate,"max":_vm.today,"disabled":!_vm.startDate},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1),_c('v-col',{attrs:{"cols":""}},[_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":function($event){return _vm.datesChanged()}}},[_vm._v("Submit")])],1),(
      _vm.dashboardData &&
      _vm.dashboardData.order &&
      _vm.dashboardData.order.order_status_date_wise_data
    )?_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',[_c('v-card-title',{staticClass:"heading"},[_vm._v("Order Stacked Chart")]),_c('v-divider'),_c('v-card-text',[_c('Chart',{attrs:{"options":_vm.chartOptions,"labels":_vm.dashboardData.order.order_status_date_wise_data.categories,"datasets":_vm.dashboardData.order.order_status_date_wise_data.values}})],1)],1)],1):_vm._e(),(
      _vm.dashboardData &&
      _vm.dashboardData.da &&
      _vm.dashboardData.da.da_status_date_wise_data
    )?_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',[_c('v-card-title',{staticClass:"heading"},[_vm._v("DA Stacked Chart")]),_c('v-divider'),_c('v-card-text',[_c('Chart',{attrs:{"options":_vm.chartOptions,"labels":_vm.dashboardData.da.da_status_date_wise_data.categories,"datasets":_vm.dashboardData.da.da_status_date_wise_data.values}})],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }