<template>
  <v-dialog v-model="showBulkUploadDialog" max-width="900">
    <v-card>
      <v-card-title class="secondary white--text">
        <span>Bulk Upload </span>

        <v-spacer></v-spacer>

        <v-btn
          icon
          @click="
            showBulkUploadDialog = false;
            resetForm();
          "
        >
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-2">
        <v-form ref="bulkUploadForm" v-model="isValid">
          <v-row class="my-0">
            <v-col
              cols="12"
              class="py-0"
              v-if="errors && errors.non_field_errors"
            >
              <v-alert
                close-icon="mdi-close"
                v-if="errors.non_field_errors.length"
                dense
                type="error"
              >
                <v-list
                  class="pa-0"
                  dense
                  style="background: inherit !important"
                  v-for="(error, i) in errors.non_field_errors"
                  :key="i"
                >
                  <v-list-item dense style="min-height: 20px !important">
                    <span>{{ i + 1 }} .</span><span>{{ error }}</span>
                  </v-list-item>
                </v-list>
              </v-alert>
            </v-col>

            <!-- <v-col cols="12" sm="6" lg="4" class="Form-Columns">
              <MultiSelect
                label="Select Customer *"
                :items="allCustomerList"
                itemText="company_name"
                itemValue="id"
                :rules="[(val) => !!val || 'Customer is Required!']"
                hide-details="auto"
                v-model="formDetails.customer"
                :error-messages="formErrors.customer"
                @change="delete formErrors.customer"
              ></MultiSelect>
            </v-col> -->
<!-- 
            <v-col cols="12" sm="6" lg="4" class="Form-Columns">
              <DateField
                :inputProps="{
                  dense: true,
                  label: 'Start Date *',
                  'hide-details': 'auto',
                  rules: [(v) => !!v || 'Start Date is Required!'],
                  'error-messages': formErrors.start_date,
                }"
                :min="today"
                v-model="formDetails.start_date"
                @input="delete formErrors.start_date"
              />
            </v-col> -->

            <!-- <v-col cols="12" sm="6" md="4" lg="4" class="Form-Columns">
              <DateField
                :inputProps="{
                  dense: true,
                  label: 'End Date *',
                  'hide-details': 'auto',
                  rules: [(v) => !!v || 'End Date is Required!'],
                  'error-messages': formErrors.end_date,
                }"
                :min="today"
                v-model="formDetails.end_date"
                @input="delete formErrors.end_date"
              />
            </v-col> -->

            <v-col cols="12" sm="6" class="Form-Columns">
              <v-file-input
                label="Select File *"
                dense
                outlined
                :rules="[
                  (v) => !!v || 'File is required',
                  (v) => (v && v.size > 0) || 'File is required',
                ]"
                accept=".xlsx, .xls"
                hide-details="auto"
                v-model="formDetails.selectedFile"
                @change="fileSelected($event)"
              ></v-file-input>
            </v-col>

            <v-col cols="12" sm="6" md="4" class="Form-Columns">
              <v-btn
                depressed
                color="primary"
                @click="downloadSampleExceleFile"
              >
                <span>Download Demo Sheet</span>
              </v-btn>
            </v-col>
            <v-col cols="12" class="d-flex justify-space-between pt-0">
              <span class="text-capitalize">
                total records : {{ totalRecordCount }}</span
              >
              <span> Records with error: {{ rowData.length }}</span>
            </v-col>
            <v-col cols="12">
              <AgGridVue
                @grid-ready="gridReady"
                :grid-options="gridOptions"
                :column-defs="columnDefs"
                :default-col-def="defaultColDef"
                :context="context"
                :row-data="rowData"
                style="width: 100%; height: 400px"
                class="ag-theme-material"
              >
              </AgGridVue>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="d-flex justify-center">
        <v-btn
          depressed
          color="primary"
          large
          :disabled="!isValid"
          @click="submitForm"
        >
          <span>Submit</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FileField from "@/components/FormBaseComponents/FileField.vue";
import { formatDataKeys } from "@/utils/functions";
import { AgGridVue } from "ag-grid-vue";
import { utils, read } from "xlsx";
import DateField from "@/components/FormBaseComponents/DateField.vue";
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import BulkUploadErrorTooltip from "@/components/General/BulkUploadErrorTooltip.vue";

export default {
  components: {
    InputField,
    MultiSelect,
    DateField,
    FileField,
    AgGridVue,
    BulkUploadErrorTooltip,
  },
  props: {
    value: {
      type: Boolean,
    },
  },
  data() {
    return {
      rawData: [],
      rowData: [],
      columnDefs: [],
      errors: [],
      selectedFile: null,
      allCustomerList: [],
      isValid: true,
      formDetails: {},
      formErrors: {},
      dataObject: [],
      gridOptions: {
        headerHeight: 40,
        rowHeight: 40,
        rowSelection: "multiple",
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
      },
      defaultColDef: {
        lockPosition: true,
      },
      serverErrors: [],

      today: new Date().toISOString().slice(0, 10),
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.getCustomerList();
      } else {
        this.resetForm();
      }
    },
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    totalRecordCount() {
      return this.dataObject.filter((item) => Object.keys(item).length > 0)
        .length;
    },
    showBulkUploadDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    distinctErrors() {
      let err = [];

      this.serverErrors
        .filter((e) => {
          return Object.keys(e).length > 0;
        })
        .map((m) => {
          return Object.keys(m).map((k) => {
            return m[k];
          });
        })
        .forEach((r) => {
          r.forEach((s) => {
            err = [...new Set([...err, ...s])];
          });
        });
      return err;
    },
  },
  methods: {
    gridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    removeErrorRow(index, oldIndex) {
      setTimeout(() => {
        this.serverErrors.splice(index, 1);
        this.rowData.splice(index, 1);

        this.dataObject.splice(
          this.dataObject.findIndex((item) => item.oldIndex == oldIndex),
          1
        );
      }, 200);
    },
    dataChange(param) {
      let field = param.colDef.field;
      let localError = this.serverErrors[param.node.rowIndex];
      if (param && param.newValue != param.oldValue) {
        delete localError[field];
      }
      let newIndex = this.dataObject.findIndex(
        (item) => item.oldIndex == param.data.oldIndex
      );
      this.dataObject[newIndex] = param.data;

      if (
        localError.non_field_errors ||
        (Object.keys(localError) && Object.keys(localError).length == 0)
      ) {
        setTimeout(() => {
          this.serverErrors.splice(param.node.rowIndex, 1);
          this.rowData.splice(param.node.rowIndex, 1);
        }, 200);
      }

      return param.newValue;
    },
    cellStyle(param) {
      if (
        param &&
        param.colDef &&
        param.colDef.field &&
        this.serverErrors[param.rowIndex].hasOwnProperty(param.colDef.field)
      ) {
        return {
          backgroundColor: "red",
        };
      } else {
        return {
          backgroundColor: "white",
        };
      }
    },
    downloadSampleExceleFile() {
      let filename = "CustomerContract.xlsx";
      const params = {
        excel_type: "bulk_customer_contract",
      };
      this.$bus.$emit("showLoader", true);
      this.$api.customerContract
        .customerContractBulkExcelFileDownload(params)
        .then((res) => {
          var blob = res.data;

          var downloadLink = window.document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(
            new Blob([blob], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          downloadLink.download = filename;
          downloadLink.click();

          this.showBulkUploadForm = false;
          this.$bus.$emit("showToastMessage", {
            message: "Download successfully!",
            color: "success",
          });

          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          this.formErrors = err.data;
          console.error(err);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't update Tariff status!",
            color: "error",
          });
          this.$bus.$emit("showLoader", false);
        });
    },
    fileSelected(file) {
      if (file) {
        let reader = new FileReader();
        reader.onload = async () => {
          this.excelData = [];
          let fileData = reader.result;
          let wb = read(fileData, {
            type: "binary",
          });
          let rowData = utils.sheet_to_row_object_array(
            wb.Sheets[wb.SheetNames[0]],
            {
              header: 0,
              defval: "",
            }
          );
          this.dataObject = rowData.map((row, index) => {
            let newObject = {};
            newObject.oldIndex = index;
            for (const [key, value] of Object.entries(row)) {
              let newKey = key
                .toString()
                .toLowerCase()
                .trim()
                .replaceAll(" ", "_");
              newObject[newKey] = value;
            }

            return newObject;
          });
        };

        reader.readAsBinaryString(file);
      } else {
        this.clearDialogData();
      }
    },
    submitForm() {
      this.$bus.$emit("showLoader", true);
      this.formDetails.contracts = this.dataObject;
      let payload;
      payload = {
        ...this.formDetails,
      };
      this.$api.customerContract
        .uploadCustomerContract(payload)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.$emit("refreshList");
          this.resetForm();
          this.showBulkUploadDialog = false;
          if (res && res.data && res.data.message) {
            this.$bus.$emit("showToastMessage", {
              message: res.data.message,
              color: "success",
            });
          }
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          if (typeof err == "string") {
            this.$bus.$emit("showToastMessage", {
              message: err,
              color: "error",
            });
          } else if (
            (err && typeof err == "object") ||
            typeof err == "object"
          ) {
            this.formErrors = err.data;
            this.serverErrors = err.data;
            this.generateErrorRow(err.data);
            this.$bus.$emit("showToastMessage", {
              message: "Error uploading sheet!",
              color: "error",
            });
          }
          this.loading = false;
        });
    },
    generateErrorRow(errorRow) {
      const result = Array.isArray(errorRow);
      if (result) {
        let errorIndexRow = errorRow.map((item, index) => {
          return Object.keys(item).length > 0 ? true : false;
        });
        this.columnDefs = Object.keys(this.dataObject[0]).map((item, index) => {
          return {
            headerName: `${item.replaceAll("_", " ")}`
              .toLowerCase()
              .toUpperCase(),
            field: item,
            editable: "oldIndex" != item ? true : false,
            hide: "oldIndex" == item || "formError" == item ? true : false,
            valueParser: this.dataChange,
            cellStyle: this.cellStyle,
          };
        });

        this.columnDefs.unshift({
          headerName: "errors",
          pinned: "left",
          width: 90,
          cellRendererFramework: "BulkUploadErrorTooltip",
        });

        this.columnDefs.push({
          headerName: "actions",
          pinned: "right",
          width: 90,
        });
        this.serverErrors = this.serverErrors.filter((item, index) => {
          if (errorIndexRow[index]) {
            return item;
          }
        });

        this.rowData = this.dataObject.filter((item, index) => {
          if (errorIndexRow[index]) {
            return item;
          }
        });
        this.rowData = this.rowData.map((row, index) => {
          let error = this.serverErrors[index];
          return { ...row, formError: error };
        });
      }
    },
    resetForm() {
      if (this.$refs.bulkUploadForm) {
        this.$refs.bulkUploadForm.reset();
      }
      this.formDetails = {};
      this.formErrors = {};
    },
    getCustomerList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        company_type: "Customer",
        limit: "all",
        ...params,
      };
      this.$api.company
        .getCompanyList(params)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.allCustomerList = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    clearDialogData() {
      this.file = null;
      this.fulldata = [];
      this.rowData = [];
      this.columnDefs = [];
      this.dataObject = [];
      this._reqFields = [];
      this.serverErrors = [];
    },
  },

  mount() {
    this.getCustomerList();
  },
};
</script>