<template>
  <div>
    <v-row class="ma-0">
      <v-col cols="12" lg="6" class="align-self-center">
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              @click="callBackRoute"
              v-bind="attrs"
              v-on="on"
              class="mr-3"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </template>
          <span>Back</span>
        </v-tooltip>

        <span class="text-h5"> {{ invoiceDetails.invoice_no }} Details </span>
      </v-col>

      <v-col cols="12" lg="6" class="text-right align-self-center">
        <v-btn @click="displayDrawer = true" depressed small color="primary">
          Show Logs
        </v-btn>
      </v-col>

      <v-col cols="12" class="py-0">
        <v-divider></v-divider>
      </v-col>

      <!-- <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
        class="Form-Columns"
        v-if="invoiceDetails.amount_info"
      >
        <span class="text-caption"> Total VAT Amount </span>
        <p class="text-subtitle-1 font-weight-medium mb-0">
          {{
            `${invoiceDetails.amount_info.total_vat_amount} ${$globals.currency}`
          }}
        </p>
      </v-col> -->
      <v-col cols="12" class="pb-1">
        <v-card>
          <v-card-title class="heading py-1">
            <span class="text-h6 font-weight-regular">Invoice Details</span>
          </v-card-title>
          <v-card-text class="pa-2">
            <v-row class="ma-0">
              <v-col cols="12" lg="9">
                <v-row>
                  <v-col
                    cols="12"
                    lg="auto"
                    class="Form-Columns-Dense"
                    v-if="invoiceDetails.invoice_date"
                  >
                    <span class="text-caption">Invoice Date</span>
                    <p class="text-subtitle-1 font-weight-medium mb-0">
                      {{ invoiceDetails.invoice_date | formatDate }}
                    </p>
                  </v-col>
                  <v-divider
                    vertical
                    class="mx-3"
                    v-if="invoiceDetails.invoice_from_date"
                  ></v-divider>
                  <v-col
                    cols="12"
                    lg="auto"
                    class="Form-Columns-Dense"
                    v-if="invoiceDetails.invoice_from_date"
                  >
                    <span class="text-caption">Invoice From Date</span>
                    <p class="text-subtitle-1 font-weight-medium mb-0">
                      {{ invoiceDetails.invoice_from_date | formatDate }}
                    </p>
                  </v-col>
                  <v-divider
                    vertical
                    class="mx-3"
                    v-if="invoiceDetails.invoice_to_date"
                  ></v-divider>
                  <v-col
                    cols="12"
                    lg="auto"
                    class="Form-Columns-Dense"
                    v-if="invoiceDetails.invoice_to_date"
                  >
                    <span class="text-caption">Invoice To Date</span>
                    <p class="text-subtitle-1 font-weight-medium mb-0">
                      {{ invoiceDetails.invoice_to_date | formatDate }}
                    </p>
                  </v-col>
                  <v-divider
                    vertical
                    class="mx-3"
                    v-if="invoiceDetails.invoice_type"
                  ></v-divider>
                  <v-col
                    cols="12"
                    lg="auto"
                    class="Form-Columns-Dense"
                    v-if="invoiceDetails.invoice_type"
                  >
                    <span class="text-caption">Invoice Type</span>
                    <p class="text-subtitle-1 font-weight-medium mb-0">
                      {{ invoiceDetails.invoice_type }}
                    </p>
                  </v-col>
                  <v-divider
                    vertical
                    class="mx-3"
                    v-if="invoiceDetails.departure_country"
                  ></v-divider>
                  <v-col
                    cols="12"
                    lg="auto"
                    class="Form-Columns-Dense"
                    v-if="invoiceDetails.departure_country"
                  >
                    <span class="text-caption">Departure Country</span>
                    <p class="text-subtitle-1 font-weight-medium mb-0">
                      {{ invoiceDetails.departure_country }}
                    </p>
                  </v-col>
                  <v-divider
                    vertical
                    class="mx-3"
                    v-if="invoiceDetails.destination_country"
                  ></v-divider>
                  <v-col
                    cols="12"
                    lg="auto"
                    class="Form-Columns-Dense"
                    v-if="invoiceDetails.destination_country"
                  >
                    <span class="text-caption">Destination Country</span>
                    <p class="text-subtitle-1 font-weight-medium mb-0">
                      {{ invoiceDetails.destination_country }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" lg="3">
                <v-row justify="end">
                  <v-col
                    cols="12"
                    lg="auto"
                    class="Form-Columns-Dense"
                    v-if="invoiceDetails.amount_info"
                  >
                    <span class="text-caption"> Total Amount </span>
                    <p class="text-subtitle-1 font-weight-medium mb-0">
                      {{
                        `${invoiceDetails.amount_info.total_amount} ${$globals.currency}`
                      }}
                    </p>
                  </v-col>
                  <v-divider
                    vertical
                    class="mx-3"
                    v-if="
                      invoiceDetails.amount_info &&
                      invoiceDetails.amount_info.total_gross_amount
                    "
                  ></v-divider>
                  <v-col
                    cols="12"
                    lg="auto"
                    class="Form-Columns-Dense"
                    v-if="invoiceDetails.amount_info"
                  >
                    <span class="text-caption"> Total Gross Amount </span>
                    <p class="text-subtitle-1 font-weight-medium mb-0">
                      {{
                        `${invoiceDetails.amount_info.total_gross_amount} ${$globals.currency}`
                      }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-divider></v-divider>
            <v-row>
              <v-col
                cols="12"
                lg="4"
                v-if="
                  invoiceDetails.remarks_info &&
                  invoiceDetails.remarks_info.admin_remarks
                "
                class="mx-1"
              >
                <span class="text-caption">Admin Remarks </span>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  {{ invoiceDetails.remarks_info.admin_remarks }}
                </p>
              </v-col>
              <v-col
                cols="12"
                class="mx-1"
                lg="4"
                v-if="
                  invoiceDetails.remarks_info &&
                  invoiceDetails.remarks_info.customer_remarks
                "
              >
                <span class="text-caption">Customer Remarks </span>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  {{ invoiceDetails.remarks_info.customer_remarks }}
                </p>
              </v-col>
              <v-col
                cols="12"
                lg="4"
                class="mx-1"
                v-if="
                  invoiceDetails.remarks_info &&
                  invoiceDetails.remarks_info.remarks
                "
              >
                <span class="text-caption">Remarks </span>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  {{ invoiceDetails.remarks_info.remarks }}
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-title class="heading py-1">
            <span class="text-h6 font-weight-regular">Company Details</span>
          </v-card-title>
          <v-card-text class="pa-2">
            <div class="d-flex">
              <div
                class="d-flex"
                v-for="(value, key, i) in invoiceDetails.company_details"
                :key="i"
              >
                <div class="Form-Columns-Dense">
                  <span class="text-caption">
                    {{ key.replace(/_/g, " ") }}
                  </span>
                  <p class="text-subtitle-1 font-weight-medium mb-0">
                    {{ value }}
                  </p>
                </div>
                <v-divider vertical class="mx-3"></v-divider>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" class="py-1">
        <span class="text-h6 font-weight-regular">Delivery Advices</span>
      </v-col>
      <v-col cols="12" class="pt-0">
        <AgGridVue
          class="ag-theme-alpine Table-Height"
          id="myGrid"
          :columnDefs="headers"
          @grid-ready="onGridReady"
          :defaultColDef="defaultColDef"
          :grid-options="gridOptions"
          :suppressDragLeaveHidesColumns="true"
          :rowData="invoiceDetails.delivery_advice_details"
        ></AgGridVue>
      </v-col>

      <v-col cols="12" class="py-1">
        <span class="text-h6 font-weight-regular">Charges</span>
      </v-col>
      <v-col cols="12" class="pt-0">
        <AgGridVue
          class="ag-theme-alpine Table-Height"
          id="myChargeGrid"
          :columnDefs="chargeHeaders"
          @grid-ready="chargeOnGridReady"
          :defaultColDef="chargeDefaultColDef"
          :grid-options="chargeGridOptions"
          :suppressDragLeaveHidesColumns="true"
          :rowData="invoiceDetails.invoice_charges"
        ></AgGridVue>
      </v-col>
    </v-row>

    <DetailsParent :resizable="true" v-model="displayDrawer">
      <template #details>
        <v-card class="fill-height">
          <v-card-title class="heading mb-4">
            <Tooltip
              tooltipTitle="Invoice ID"
              v-if="invoiceDetails.invoice_no"
              :tooltipText="invoiceDetails.invoice_no"
              right
              tooltipClass="text-h5 font-weight-medium"
            />
          </v-card-title>

          <v-card-text>
            <v-sheet class="Height-Class">
              <v-timeline
                v-if="logs && logs.length"
                class="px-0"
                align-top
                dense
              >
                <v-timeline-item
                  v-for="(log, index) in logs[0]"
                  :key="index"
                  color="primary"
                  icon="mdi-circle"
                  small
                >
                  <div>
                    <strong>{{ log.created | formatDate(true) }}</strong>
                    <div class="text-body-2">{{ log.message }}</div>
                    <div class="text-caption">- {{ log.updated_by }}</div>
                  </div>
                </v-timeline-item>
              </v-timeline>

              <div v-else class="text-center">
                <v-divider class="mb-4"></v-divider>
                <span>No Logs to show</span>
              </div>
            </v-sheet>
          </v-card-text>
        </v-card>
      </template>
    </DetailsParent>
  </div>
</template>

<script>
import DetailsParent from "@/components/General/DetailsParent.vue";
import { AgGridVue } from "ag-grid-vue";
import Tooltip from "@/components/General/Tooltip.vue";

export default {
  components: {
    DetailsParent,
    AgGridVue,
    Tooltip,
  },
  data() {
    return {
      invoiceDetails: {},
      backRoute: { path: "/app/admin/invoice/customer/list" },

      // ag-grid vars
      headers: [
        {
          headerName: "Delivery Advice No.",
          field: "da_no",
        },
        {
          headerName: "BOE",
          field: "boe",
        },
        {
          headerName: "Completed On",
          field: "completed_on",
        },
        {
          headerName: "DA Additional Charges",
          field: "da_additional_charges",
        },
        {
          headerName: "DA date",
          field: "created",
        },
        {
          headerName: "Has Genset",
          field: "has_genset",
        },
        {
          headerName: "Is Hazmat",
          field: "is_hazmat",
        },
        {
          headerName: "Source",
          field: "source_location_code",
          tooltipField: "source_address",
        },
        {
          headerName: "Mid Point",
          field: "mid_location_code",
          tooltipField: "mid_address",
        },
        {
          headerName: "Destination",
          field: "destination_location_code",
          tooltipField: "destination_address",
        },
        {
          headerName: `Rate (${this.$globals.currency})`,
          field: "rate",
        },
      ],
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },

      // charge ag-grid vars
      chargeHeaders: [
        {
          headerName: "Charge Title",
          field: "charge_title",
          minWidth: 300,
        },
        {
          headerName: "Charge Type",
          field: "charge_type",
        },
        {
          headerName: `Rate (${this.$globals.currency})`,
          field: "rate",
        },
        {
          headerName: "TAX(%)",
          field: "tax_percentage",
        },
        {
          headerName: `TAX Amount (${this.$globals.currency})`,
          field: "tax_amount",
        },
        {
          headerName: `Gross Amount (${this.$globals.currency})`,
          field: "gross_amount",
        },
      ],
      chargeGridApi: null,
      chargeGridColumnApi: null,
      chargeDefaultColDef: {
        resizable: true,
      },
      chargeGridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
      },

      logs: [],
      displayDrawer: false,
    };
  },
  methods: {
    resizeGrid() {
      if (this.gridOptions && "api" in this.gridOptions) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }

      if (this.chargeGridOptions && "api" in this.chargeGridOptions) {
        setTimeout(() => {
          this.chargeGridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },
    callBackRoute() {
      this.$router.push(this.backRoute);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },
    chargeOnGridReady(params) {
      this.chargeGridApi = params.api;
      this.chargeGridColumnApi = params.columnApi;
      this.resizeGrid();
    },
    getCustomerInvoiceObject(id) {
      this.$api.customerInvoice
        .getCustomerInvoiceDetails(id)
        .then((res) => {
          this.invoiceDetails = res.data;
          if (
            this.invoiceDetails &&
            this.invoiceDetails.invoice_activity_logs
          ) {
            this.logs.push(this.invoiceDetails.invoice_activity_logs);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    setBackRoute() {
      if ("backRoute" in this.$route.query) {
        this.backRoute.path = this.$route.query.backRoute;
      }
      if ("active_tab" in this.$route.query) {
        if (!this.backRoute.query) {
          this.backRoute.query = {};
        }
        this.backRoute.query.active_tab = this.$route.query.active_tab;
      }
    },

    customerInvoiceActivityLogs(params = {}) {
      params = {
        ...params,
        quotation: this.$route.params.id,
        limit: "50",
        offset: this.logs.length,
      };
      this.$api.activityLogs
        .customerInvoiceActivityLogs(params)
        .then((res) => {
          this.logs.push(...res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  mounted() {
    if ("id" in this.$route.params) {
      this.logs = [];
      this.getCustomerInvoiceObject(this.$route.params.id);
    }
    this.setBackRoute();
  },
};
</script>

<style scoped>
.Height-Class {
  height: calc(100vh - 196px);
  overflow-y: auto;
}

.Table-Height {
  width: 100%;
  height: 250px;
  overflow-y: auto;
}
</style>

