<template>
  <div>
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-icon
          color="primary"
          class="mr-2"
          v-on="on"
          v-bind="attrs"
          @click="updatestatus"
          v-if="
            params &&
            params.context &&
            params.context.parentComponent &&
            params.context.parentComponent.permissions && 
            params.context.parentComponent.permissions.change &&
            params.context.parentComponent.currentProfile.is_primary_user
          "
          >mdi-pencil</v-icon
        >
      </template>
      <span>Update status</span>
    </v-tooltip>
    <!-- <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon color="primary" v-on="on" v-bind="attrs" @click="viewItem"
            >mdi-eye</v-icon
          >
        </template>
        <span>View</span>
      </v-tooltip> -->
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          color="primary"
          class="mr-2"
          v-bind="attrs"
          v-on="on"
          @click="viewSupportDetails()"
          >mdi-eye</v-icon
        >
      </template>
      <span>View details</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  methods: {
    updatestatus() {
      this.params.context.parentComponent.openTicketStatusDialog(
        this.params.data.id
      );
    },
    viewSupportDetails() {
      this.params.context.parentComponent.viewTicketDetails(
        this.params.data.id
      );
    },
  },
};
</script>

<style></style>
