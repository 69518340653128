import { bankDetails } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
    getbankDetailsList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(bankDetails.bankDetailsBase, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getbankDetailsSelectList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${bankDetails.bankDetailsBase}select/`, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getbankDetailsObject(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${bankDetails.bankDetailsBase}${id}/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    addbankDetails(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(bankDetails.bankDetailsBase, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    updatebankDetails(payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(`${bankDetails.bankDetailsBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    activateDeactivateBankDetails(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(
                    `${bankDetails.bankDetailsBase}${payload.id}/deactivate_bank_details/`,
                    payload
                )
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    partiallyUpdatebankDetails(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${bankDetails.bankDetailsBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
});