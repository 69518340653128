<template>
  <v-expansion-panel class="Card-Details-Expansion">
    <v-expansion-panel-header class="py-1 px-3 subHeading">
      <span
        class="text-subtitle-1 text-capitalize font-weight-medium"
        v-if="title != 'lane' && title != 'contract'"
      >
        {{ title }}
      </span>
      <span
        class="text-subtitle-1 text-capitalize font-weight-medium"
        v-else-if="title == 'lane'"
      >
        Route
      </span>
      <span
        class="text-subtitle-1 text-capitalize font-weight-medium"
        v-else-if="title == 'contract'"
      >
        Customer Tariff
      </span>
    </v-expansion-panel-header>

    <v-expansion-panel-content class="pt-3 subHeading Space-Class">
      <table
        class="Primary-Table"
        v-if="
          Array.isArray(displayObject) &&
          displayObject.every((val) => isPrimitive(val))
        "
      >
        <tr v-for="(value, i) in displayObject" :key="i">
          <td class="text-subtitle-1">
            {{ `${i + 1}.` }}
          </td>
          <td class="text-subtitle-1 font-weight-medium">
            {{ value }}
          </td>
        </tr>
      </table>
      <table class="Primary-Table" v-else>
        <tr v-for="(value, key) in displayObject" :key="key">
          <td
            class="text-subtitle-1"
            v-if="
              restrictedKeys.indexOf(key) == -1 &&
              value != null &&
              value != undefined &&
              isPrimitive(value)
            "
          >
            {{ key.replace(/_/g, " ") }}
          </td>
          <th
            class="text-subtitle-1 font-weight-medium"
            v-if="
              restrictedKeys.indexOf(key) == -1 &&
              value != null &&
              value != undefined &&
              isPrimitive(value)
            "
          >
            {{ typeof value != typeof true ? value : value ? "Yes" : "No" }}
          </th>
        </tr>
      </table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { isPrimitive } from "@/utils/functions.js";

export default {
  props: {
    title: {
      type: String,
      default: "Title",
    },

    displayObject: {
      type: [Object, Array],
      required: true,
    },

    restrictedKeys: {
      type: Array,
      default: () => ["id", "is_active"],
    },
  },
  computed: {},
  data() {
    return {
      isPrimitive,
    };
  },
  methods: {
    returnHeaderKeys(array) {
      return [...new Set(array.map((val) => Object.keys(val)))];
    },
  },
};
</script>

<style>
.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 45px;
}

.Space-Class .v-expansion-panel-content__wrap {
  padding: 0 12px 16px;
}
</style>
