<template>
  <div>
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn
          depressed
          icon
          v-on="on"
          v-bind="attrs"
          :disabled="params.fullIvoicecredit"
          class="ml-1 mr-2"
          @click="editCharges"
        >
          <v-icon color="primary">mdi-pencil</v-icon>
        </v-btn>
      </template>
      <span>Edit Charge</span>
    </v-tooltip>

    <!--  v-if="params && params.data && !params.data.fullInvoiceCreidit" -->
  </div>
</template>

<script>
export default {
 

  methods: {
    editCharges() {
      this.params.context.parentComponent.updateAdditionalCharges(
        this.params.data
      );
    },
  },
};
</script>

<style></style>



