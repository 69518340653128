<template>
  <FilterParent
    module="Delivery Advice"
    v-model="showAdminDeliveryAdviceFilters"
  >
    <template #filterContent>
      <v-row no-gutters>
        <v-col cols="12" class="pa-2">
          <MultiSelect
            :items="allCustomerList"
            itemText="company_name"
            itemValue="id"
            label="Select Customer Company"
            v-model="filterObject.customer"
          />
        </v-col>
       
        <v-col cols="12" class="Form-Columns">
          <Autocomplete
            :loading="listLoading"
            :totalItems="laneCount"
            :items="allLaneList"
            itemText="title"
            itemValue="id"
            @updateList="getLaneList"
            label="Route"
            v-model="filterObject.lane"
          />
        </v-col>
        <v-col cols="12" class="Form-Columns">
          <MultiSelect
            label="Payment Terms"
            :items="paymentTerms"
            v-model="filterObject.payment_term"
          />
        </v-col>
        <v-col cols="12" class="Form-Columns">
          <MultiSelect
            label="Branch"
            :items="allBranch"
            itemText="branch_code"
            itemValue="id"
            v-model="filterObject.branch"
          />
        </v-col>
        <v-col cols="12" class="Form-Columns">
          <MultiSelect
            label="Depot"
            :items="allDepot"
            itemText="depot_name"
            itemValue="id"
            v-model="filterObject.depot"
          />
        </v-col>
        <v-col cols="6" class="pa-2" v-if="currentTab == 1">
          <InputField
            type="date"
            label="Select Start Date"
            v-model="filterObject.completed_start_date"
          />
        </v-col>
        <v-col cols="6" class="pa-2" v-if="currentTab == 1">
          <InputField
            type="date"
            label="Select End Date"
            v-model="filterObject.completed_end_date"
          />
        </v-col>
        <v-col cols="6" class="pa-2">
          <DateField
            :inputProps="{
              dense: true,
              label: 'Start Date ',
              'hide-details': 'auto',
            }"
            v-model="filterObject.start_date"
          />
        </v-col>

        <v-col cols="6" class="pa-2">
          <DateField
            :inputProps="{
              dense: true,
              label: 'End Date ',
              'hide-details': 'auto',
              
            }"
            v-model="filterObject.end_date"
          />
        </v-col>
      </v-row>
    </template>
    <template #filterAction>
      <v-btn
        depressed
        @click="
          $emit('resetFilters');
          showAdminDeliveryAdviceFilters = false;
        "
      >
        Reset
      </v-btn>
      <v-btn
        color="primary"
        class="ml-4"
        depressed
        @click="
          $emit('applyFilters');
          showAdminDeliveryAdviceFilters = false;
        "
      >
        Apply
      </v-btn>
    </template>
  </FilterParent>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import Autocomplete from "@/components/FormBaseComponents/Autocomplete.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import FilterParent from "@/components/General/FilterParent.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";

export default {
  components: {
    FilterParent,
    MultiSelect,
    InputField,
    Autocomplete,
    DateField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    bindingObject: {
      type: Object,
      default: () => ({}),
    },
    currentTab: {
      required: true,
    },
  },
  data() {
    return {
      allCustomerList: [],
      allTransporterList: [],
      allLaneList: [],
      paymentTerms: [],
      allBranch: [],
      allDepot: [],
      listLoading: false,
      laneCount: 0,
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.getCustomerList();
        this.getLaneList();
        this.getCompanyOptions();
        this.getBranchList();
        this.getDepotList();
      }
    },
  },
  computed: {
    showAdminDeliveryAdviceFilters: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    filterObject: {
      get() {
        return this.bindingObject;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    getCustomerList() {
      this.$api.company
        .getCompanyList({
          limit: "all",
          company_type: "Customer",
        })
        .then((res) => {
          this.allCustomerList = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getTransporterList() {
      this.$api.company
        .getCompanyList({
          limit: "all",
          company_type: "Transporter",
        })
        .then((res) => {
          this.allTransporterList = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    getLaneList(params = {}) {
      this.listLoading = true;
      params = {
        is_active: true,
        limited: true,
        offset: 0,
        limit: 10,
        ...params,
      };

      this.$api.lane
        .getLaneList(params)
        .then((res) => {
          this.listLoading = false;
          if ("count" in res) {
            this.laneCount = res.count;
          }

          if (params.offset == 0) {
            this.allLaneList = res.data;
          } else {
            this.allLaneList.push(...res.data);
          }
        })
        .catch((err) => {
          this.listLoading = false;
        });
    },
    getCompanyOptions() {
      this.$bus.$emit("showLoader", true);
      this.$api.company
        .getCompanyOptions()
        .then((res) => {
          this.paymentTerms = res.data.actions.POST.payment_term.choices.map(
            (obj) => {
              return { text: obj.display_name, value: obj.value };
            }
          );
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getBranchList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        limit: "all",
        ...params,
      };
      this.$api.branch
        .getBranchList(params)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.allBranch = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getDepotList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        limit: "all",
      };
      this.$api.depot
        .getDepotList(params)
        .then((res) => {
          this.allDepot = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
};
</script>

<style></style>
