<template>
  <FormComponent
    title="Create Credit DA"
    :backRoute="backRoute"
    :nonFieldErrors="formErrors.non_field_errors"
  >
    <template #formActions>
      <v-btn
        color="primary"
        depressed
        :disabled="!allFormValidation"
        @click="submitForm"
      >
        Submit
      </v-btn>
      <v-btn color="secondary" class="ml-2" depressed @click="resetForm">
        Reset
      </v-btn>
    </template>

    <template #formContent>
      <v-stepper v-model="stepper" non-linear flat>
        <v-stepper-header class="elevation-0">
          <v-stepper-step
            :rules="[() => (Object.keys(formErrors).length ? valid1 : true)]"
            step="1"
            editable
          >
            Basic Details
          </v-stepper-step>
          <v-divider class="ma-0"></v-divider>
          <v-stepper-step
            :rules="[() => (Object.keys(formErrors).length ? valid2 : true)]"
            step="2"
            editable
            @click="getChargeList({ is_revenue: true })"
          >
            Charges
          </v-stepper-step>
          <v-divider class="ma-0"></v-divider>
          <v-stepper-step
            :rules="[() => (Object.keys(formErrors).length ? valid3 : true)]"
            step="3"
            editable
            @click="
              getChargeList({ is_cost: true });
              getTransporterList();
            "
          >
            Cost Charges
          </v-stepper-step>
          <v-divider class="ma-0"></v-divider>
          <v-stepper-step
            :rules="[() => (Object.keys(formErrors).length ? valid4 : true)]"
            step="4"
            editable
          >
            Document Details
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1" class="pa-0">
            <v-form ref="stepperForm1" v-model="valid1">
              <v-row class="mx-2 my-0">
                <v-col cols="12" lg="4" class="Form-Columns-Dense">
                  <span class="text-h6 font-weight-regular">Basic Details</span>
                </v-col>

                <v-col cols="12" lg="8" class="Form-Columns-Dense text-right">
                  <v-btn
                    v-if="directDaDetails.customer"
                    small
                    @click="showCustomerAddressForm = true"
                    color="primary"
                    depressed
                  >
                    Add Address
                  </v-btn>
                </v-col>

                <v-col cols="12" lg="4" class="Form-Columns-Dense">
                  <InputField
                    type="text"
                    label="BOE Number/ Reference Number *"
                    :rules="[(val) => !!val || 'BOE Number is Required!']"
                    required
                    hide-details="auto"
                    v-model="directDaDetails.boe"
                    :error-messages="formErrors.boe"
                    @input="delete formErrors.boe"
                  />
                </v-col>

                <v-col cols="12" lg="4" class="Form-Columns-Dense">
                  <MultiSelect
                    label="Select Customer *"
                    :items="allCustomerList"
                    itemText="company_name"
                    itemValue="id"
                    :returnObject="true"
                    :rules="[(val) => !!val || 'Customer is Required!']"
                    hide-details="auto"
                    v-model="directDaDetails.customer"
                    :error-messages="formErrors.customer"
                    @change="
                      delete formErrors.customer;
                      getCustomerAddressList();
                      getContractList();
                      checkCustomer();
                      setSalesRepresentativeValue();
                    "
                  />
                </v-col>

                <v-col cols="12" lg="4" class="Form-Columns-Dense">
                  <MultiSelect
                    label="Job Type *"
                    :items="allJobType"
                    itemText="display_name"
                    itemValue="value"
                    hide-details="auto"
                    v-model="directDaDetails.job_type"
                    :rules="[(v) => !!v || 'Job Type is Required']"
                    :error-messages="formErrors.job_type"
                    @change="
                      delete formErrors.job_type;
                      setJobType();
                    "
                  />
                </v-col>

                <v-col cols="12" lg="8" class="Form-Columns-Dense d-flex">
                  <MultiSelect
                    label="Source -> Mid Point -> Destination *"
                    :class="
                      directDaDetails.customer_contract
                        ? 'contract-field-width float-left'
                        : ''
                    "
                    :items="allCustomerContractList"
                    itemText="customer_contract_lane_title"
                    :disabled="
                      !directDaDetails.customer || !directDaDetails.job_type
                    "
                    itemValue="id"
                    :returnObject="true"
                    :rules="[(v) => !!v || 'Location is Required']"
                    hide-details="auto"
                    v-model="directDaDetails.customer_contract"
                    :error-messages="formErrors.customer_contract"
                    @change="
                      delete formErrors.customer_contract;
                      setContainersType();
                      getCustomerContractCharges();
                      updateselectedContract();
                    "
                  />

                  <div v-if="directDaDetails.customer_contract">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          depressed
                          color="primary text-white"
                          v-bind="attrs"
                          v-on="on"
                          @click="CustomerContractDetails = true"
                          class="ml-3"
                        >
                          {{ directDaDetails.customer_contract.contract_id }}
                        </v-btn>
                      </template>
                      <span>Tariff Details</span>
                    </v-tooltip>
                  </div>
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <v-btn
                    color="secondary"
                    depressed
                    @click="showAddCustomerContractDialog = true"
                    class="ml-auto"
                    v-if="
                      directDaDetails.job_type &&
                      directDaDetails.customer &&
                      contrPermissions &&
                      contrPermissions.add
                    "
                  >
                    Add Tariff
                  </v-btn>
                </v-col>

                <v-col cols="12" v-if="directDaDetails.customer_contract">
                  <v-row>
                    <v-col
                      v-if="
                        directDaDetails.customer_contract &&
                        directDaDetails.customer_contract.need_addresses_for &&
                        directDaDetails.customer_contract.need_addresses_for
                          .source
                      "
                      cols="12"
                      lg="3"
                      class="Form-Columns-Dense"
                    >
                      <div class="d-flex">
                        <MultiSelect
                          ref="`assigned_source_address"
                          label="Source Address *"
                          :items="allCustomerAddressList"
                          itemText="name"
                          itemValue="id"
                          :returnObject="true"
                          :rules="[
                            (val) => !!val || 'Source Address is Required!',
                          ]"
                          :item-disabled="
                            (item) => {
                              return (
                                item == directDaDetails.assigned_mid_address
                              );
                            }
                          "
                          hide-details="auto"
                          v-model="directDaDetails.assigned_source_address"
                          :error-messages="formErrors.assigned_source_address"
                          @input="delete formErrors.assigned_source_address"
                        />

                        <v-menu
                          open-on-hover
                          left
                          offset-y
                          v-if="directDaDetails.assigned_source_address"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-on="on" v-bind="attrs" class="mx-2">
                              mdi-information
                            </v-icon>
                          </template>
                          <v-card>
                            <v-card-title
                              class="secondary py-1 text-subtitle-1 white--text font-weight-medium"
                            >
                              Source Address Information
                            </v-card-title>
                            <v-card-text class="mt-2">
                              <table class="Normal-Table">
                                <tbody>
                                  <tr
                                    v-if="
                                      directDaDetails.assigned_source_address
                                        .address
                                    "
                                  >
                                    <th>Address</th>
                                    <th class="px-2">:</th>
                                    <td>
                                      {{
                                        directDaDetails.assigned_source_address
                                          .address
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </div>
                    </v-col>
                    <v-col
                      v-if="
                        directDaDetails.customer_contract &&
                        directDaDetails.customer_contract.need_addresses_for &&
                        directDaDetails.customer_contract.need_addresses_for.mid
                      "
                      cols="12"
                      lg="3"
                      class="Form-Columns-Dense"
                    >
                      <div class="d-flex">
                        <MultiSelect
                          label="Mid Point Address *"
                          :items="allCustomerAddressList"
                          itemText="name"
                          itemValue="id"
                          :returnObject="true"
                          :rules="[
                            (val) => !!val || 'Mid Point Address is Required!',
                          ]"
                          :item-disabled="
                            (item) => {
                              return (
                                item ==
                                  directDaDetails.assigned_source_address ||
                                item ==
                                  directDaDetails.assigned_destination_address
                              );
                            }
                          "
                          hide-details="auto"
                          v-model="directDaDetails.assigned_mid_address"
                          :error-messages="formErrors.assigned_mid_address"
                          @input="delete formErrors.assigned_mid_address"
                        />

                        <v-menu
                          open-on-hover
                          left
                          offset-y
                          v-if="directDaDetails.assigned_mid_address"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-on="on" v-bind="attrs" class="mx-2">
                              mdi-information
                            </v-icon>
                          </template>
                          <v-card>
                            <v-card-title
                              class="secondary py-1 text-subtitle-1 white--text font-weight-medium"
                            >
                              Mid Point Address Information
                            </v-card-title>
                            <v-card-text class="mt-2">
                              <table class="Normal-Table">
                                <tbody>
                                  <tr
                                    v-if="
                                      directDaDetails.assigned_mid_address
                                        .address
                                    "
                                  >
                                    <th>Address</th>
                                    <th class="px-2">:</th>
                                    <td>
                                      {{
                                        directDaDetails.assigned_mid_address
                                          .address
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </div>
                    </v-col>

                    <v-col
                      v-if="
                        directDaDetails.customer_contract &&
                        directDaDetails.customer_contract.need_addresses_for &&
                        directDaDetails.customer_contract.need_addresses_for
                          .destination
                      "
                      cols="12"
                      lg="3"
                      class="Form-Columns-Dense"
                    >
                      <div class="d-flex">
                        <MultiSelect
                          label="Destination Address *"
                          :items="allCustomerAddressList"
                          itemText="name"
                          itemValue="id"
                          :returnObject="true"
                          :rules="[
                            (val) =>
                              !!val || 'Destination Address is Required!',
                          ]"
                          :item-disabled="
                            (item) => {
                              return (
                                item == directDaDetails.assigned_mid_address
                              );
                            }
                          "
                          hide-details="auto"
                          v-model="directDaDetails.assigned_destination_address"
                          :error-messages="
                            formErrors.assigned_destination_address
                          "
                          @input="
                            formErrors.assigned_destination_address = null
                          "
                        />

                        <v-menu
                          open-on-hover
                          left
                          offset-y
                          v-if="directDaDetails.assigned_destination_address"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-on="on" v-bind="attrs" class="mx-2">
                              mdi-information
                            </v-icon>
                          </template>
                          <v-card>
                            <v-card-title
                              class="secondary py-1 text-subtitle-1 white--text font-weight-medium"
                            >
                              Destination Address Information
                            </v-card-title>
                            <v-card-text class="mt-2">
                              <table class="Normal-Table">
                                <tbody>
                                  <tr
                                    v-if="
                                      directDaDetails
                                        .assigned_destination_address.address
                                    "
                                  >
                                    <th>Address</th>
                                    <th class="px-2">:</th>
                                    <td>
                                      {{
                                        directDaDetails
                                          .assigned_destination_address.address
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </div>
                    </v-col>
                    <v-col cols="12" lg="3" class="Form-Columns-Dense">
                      <MultiSelect
                        label="Select Milestones"
                        :items="milestoneList"
                        itemText="milestone_name"
                        itemValue="id"
                        :multiple="true"
                        :chips="true"
                        hide-details="auto"
                        v-model="directDaDetails.milestones"
                        :error-messages="formErrors.milestones"
                        @change="delete formErrors.milestones"
                      ></MultiSelect>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <v-switch
                    :label="`Genset : ${directDaDetails.has_genset}`"
                    true-value="Yes"
                    false-value="No"
                    hide-details="auto"
                    v-model="directDaDetails.has_genset"
                    :error-messages="formErrors.has_genset"
                    @input="delete formErrors.has_genset"
                    class="mt-1"
                  />
                </v-col>

                <v-col cols="12" lg="2">
                  <v-row>
                    <v-col cols="12" lg="9" class="Form-Columns-Dense">
                      <v-switch
                        :label="`Hazmat : ${directDaDetails.is_hazmat}`"
                        true-value="Yes"
                        false-value="No"
                        hide-details="auto"
                        v-model="directDaDetails.is_hazmat"
                        :error-messages="formErrors.is_hazmat"
                        @input="delete formErrors.is_hazmat"
                        class="mt-1"
                      />
                    </v-col>
                    <v-col cols="12" lg="3" class="Form-Columns-Dense">
                      <v-btn
                        icon
                        class="red--text"
                        v-if="directDaDetails.is_hazmat == 'Yes'"
                        @click="showHazardousDialog = true"
                      >
                        <v-icon>mdi-alert</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <MultiSelect
                    label="Currency *"
                    :items="currencyList"
                    itemText="currency_code"
                    itemValue="currency_code"
                    :rules="[(val) => !!val || 'Currency is Required!']"
                    hide-details="auto"
                    v-model="directDaDetails.currency"
                    :error-messages="formErrors.currency"
                    @change="delete formErrors.currency"
                  ></MultiSelect>
                </v-col>

                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <DateField
                    :inputProps="{
                      dense: true,
                      label: 'Movement Date *',
                      rules: [(val) => !!val || 'Movement Date is Required!'],
                      'hide-details': 'auto',
                      'error-messages': formErrors.movement_date,
                    }"
                    required
                    v-model="directDaDetails.movement_date"
                    @input="delete formErrors.movement_date"
                  />
                </v-col>

                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <InputField
                    type="time"
                    label="Movement Time *"
                    :rules="[(val) => !!val || 'Movement Time is Required!']"
                    required
                    hide-details="auto"
                    v-model="directDaDetails.movement_time"
                    :error-messages="formErrors.movement_time"
                    @input="delete formErrors.movement_time"
                  />
                </v-col>

                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <MultiSelect
                    label="Select Branch *"
                    :items="allBranchList"
                    itemText="branch_title"
                    itemValue="id"
                    :rules="[(val) => !!val || 'Branch is Required!']"
                    hide-details="auto"
                    v-model="directDaDetails.branch"
                    :error-messages="formErrors.branch"
                    @input="delete formErrors.branch"
                  ></MultiSelect>
                </v-col>
                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <MultiSelect
                    label="Select Depot *"
                    :items="allDepotList"
                    itemText="depot_name"
                    itemValue="id"
                    :rules="[(val) => !!val || 'Depot is Required!']"
                    hide-details="auto"
                    v-model="directDaDetails.depot"
                    :error-messages="formErrors.depot"
                    @input="delete formErrors.depot"
                  ></MultiSelect>
                </v-col>

                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <InputField
                    type="text"
                    label="Purchase Order"
                    hide-details="auto"
                    v-model="directDaDetails.po_no"
                    :error-messages="formErrors.po_no"
                    @input="delete formErrors.po_no"
                  />
                </v-col>

                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <InputField
                    type="text"
                    label="Shipment Number"
                    hide-details="auto"
                    v-model="directDaDetails.shipment_no"
                    :error-messages="formErrors.shipment_no"
                    @input="delete formErrors.shipment_no"
                  />
                </v-col>

                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <InputField
                    type="text"
                    label="Vessel Number"
                    hide-details="auto"
                    v-model="directDaDetails.vessel_no"
                    :error-messages="formErrors.vessel_no"
                    @input="delete formErrors.vessel_no"
                  />
                </v-col>

                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <v-switch
                    hide-details="auto"
                    label="Sub Hire"
                    true-value="Yes"
                    false-value="No"
                    class="mt-1"
                    v-model="directDaDetails.is_sub_hire"
                    :error-messages="formErrors.is_sub_hire"
                    @change="delete formErrors.is_sub_hire"
                  ></v-switch>
                </v-col>

                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <v-switch
                    hide-details="auto"
                    label="Replicate DA"
                    true-value="Yes"
                    false-value="No"
                    class="mt-1"
                    v-model="is_replicate_da"
                  ></v-switch>
                </v-col>

                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <InputField
                    type="number"
                    label="Cost Rate"
                    hide-details="auto"
                    v-model="directDaDetails.cost_rate"
                    :error-messages="formErrors.cost_rate"
                    @input="delete formErrors.cost_rate"
                  />
                </v-col>

                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <InputField
                    type="text"
                    label="Sales Representative *"
                    readonly
                    :clearable="false"
                    required
                    hide-details="auto"
                    v-model="directDaDetails.sales_representative"
                    :error-messages="formErrors.sales_representative"
                    @input="delete formErrors.sales_representative"
                  />
                </v-col>
                <v-col cols="12" lg="6" class="Form-Columns-Dense">
                  <TextareaField
                    hide-details="auto"
                    v-model="directDaDetails.da_remarks"
                    variant="outlined"
                    rows="3"
                    :label="`DA Remarks`"
                    required
                    :error-messages="formErrors.da_remarks"
                    @input="delete formErrors.da_remarks"
                  >
                  </TextareaField>
                </v-col>
              </v-row>

              <v-col cols="12">
                <div class="d-flex">
                  <span class="text-h6 font-weight-regular">
                    Container Details
                  </span>
                  <v-spacer />
                  <v-btn
                    color="primary"
                    depressed
                    small
                    @click="
                      directDaDetails.order_containers.push({});
                      formFieldHints.push({});
                    "
                    :disabled="directDaDetails.order_containers.length >= 4"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </div>
                <v-divider class="mt-1" />
              </v-col>

              <v-col cols="12">
                <v-row
                  v-for="(con, i) in directDaDetails.order_containers"
                  :key="i"
                >
                  <v-col cols="1" class="d-flex align-center">
                    <span class="text-h6 font-weight-regular">
                      # {{ i + 1 }}
                    </span>
                  </v-col>

                  <v-col cols="10">
                    <v-row>
                      <v-col cols="12" lg="4" class="Form-Columns-Dense">
                        <InputField
                          type="text"
                          placeholder="ABCD1234567"
                          label="Container Number *"
                          :rules="[
                            (v) => !!v || 'Container Number is Required',
                          ]"
                          hide-details="auto"
                          persistent-hint
                          :hint="formFieldHints[i].container_no"
                          v-model="
                            directDaDetails.order_containers[i].container_no
                          "
                          :error-messages="
                            formErrors &&
                            formErrors.order_containers &&
                            formErrors.order_containers[i] &&
                            formErrors.order_containers[i].container_no
                              ? formErrors.order_containers[i].container_no
                              : []
                          "
                          @input="
                            formErrors &&
                            formErrors.order_containers &&
                            formErrors.order_containers[i] &&
                            formErrors.order_containers[i].container_no
                              ? (delete formErrors.order_containers[i]
                                  .container_no,
                                checkFormErrors())
                              : []
                          "
                        />
                      </v-col>
                      <v-col cols="12" lg="4" class="Form-Columns-Dense">
                        <MultiSelect
                          label="Container Type *"
                          :items="allContainersType"
                          itemText="container_type_title"
                          itemValue="container_type"
                          :rules="[(v) => !!v || 'Container Type is Required']"
                          hide-details="auto"
                          v-model="
                            directDaDetails.order_containers[i][
                              'container_type'
                            ]
                          "
                          :error-messages="
                            formErrors &&
                            formErrors.order_containers &&
                            formErrors.order_containers[i] &&
                            formErrors.order_containers[i].container_type
                              ? formErrors.order_containers[i].container_type
                              : []
                          "
                          @change="
                            formErrors &&
                            formErrors.order_containers &&
                            formErrors.order_containers[i] &&
                            formErrors.order_containers[i].container_type
                              ? (delete formErrors.order_containers[i]
                                  .container_type,
                                checkFormErrors())
                              : []
                          "
                        />
                      </v-col>

                      <v-col cols="12" lg="4" class="Form-Columns-Dense">
                        <InputField
                          type="number"
                          label="Container Weight (Tons) *"
                          aria-placeholder=""
                          step=".01"
                          :rules="[
                            (v) => !!v || 'Container Weight is Required',
                            (v) =>
                              v > 0 ||
                              'Container Weight can not be less than 0',
                            (v) =>
                              $globals.containerWeightFormatRegExp.test(v) ||
                              'Container Weight must have the format 00.00',
                          ]"
                          hide-details="auto"
                          v-model="directDaDetails.order_containers[i].weight"
                          :error-messages="
                            formErrors &&
                            formErrors.order_containers &&
                            formErrors.order_containers[i] &&
                            formErrors.order_containers[i].weight
                              ? formErrors.order_containers[i].weight
                              : []
                          "
                          @input="
                            formErrors &&
                            formErrors.order_containers &&
                            formErrors.order_containers[i] &&
                            formErrors.order_containers[i].weight
                              ? (delete formErrors.order_containers[i].weight,
                                checkFormErrors())
                              : []
                          "
                        />
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col
                    cols="12"
                    lg="1"
                    class="d-flex align-center justify-end"
                  >
                    <v-btn
                      color="secondary"
                      depressed
                      small
                      :disabled="i == 0"
                      @click="removeContainer(i)"
                    >
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="mt-6">
                <v-btn color="primary" @click="stepper = 2" :disabled="!valid1">
                  Next
                </v-btn>
              </v-col>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step="2" class="pa-0">
            <v-form ref="stepperForm2" v-model="valid2">
              <v-row class="mx-2 my-0">
                <v-col cols="12" class="Form-Columns-Dense">
                  <div class="d-flex">
                    <span class="text-h6 font-weight-regular">
                      Add Charges
                    </span>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      small
                      depressed
                      :disabled="!deliveryAdviceChargeDetails.rate || !valid2"
                      @click="
                        addDACharge();
                        delete formErrors.rate;
                        delete formErrors.charge;
                        delete formErrors.tax_type;
                      "
                      class="mb-1"
                    >
                      Add
                    </v-btn>
                  </div>
                  <v-divider />
                </v-col>

                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <MultiSelect
                    outlined
                    :items="allChargeList"
                    v-model="deliveryAdviceChargeDetails.charge"
                    itemText="charge_title"
                    itemValue="id"
                    label="Select Charge"
                    :item-disabled="
                      (item) => {
                        return selectedDACharges.includes(item.id);
                      }
                    "
                    hide-details="auto"
                    returnObject
                    :error-messages="formErrors.charge"
                    @change="
                      delete formErrors.charge;
                      setChargeDetails();
                    "
                  />
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <MultiSelect
                    outlined
                    readonly
                    hide-details="auto"
                    label="Tax Type *"
                    :items="taxTypeList"
                    v-model="deliveryAdviceChargeDetails.tax_type"
                    :error-messages="formErrors.tax_type"
                  />
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <InputField
                     
                    hide-details="auto"
                    v-model="deliveryAdviceChargeDetails.description"
                    label="Description"
                    :clearable="false"
                    :error-messages="formErrors.description"
                    @input="delete formErrors.description"
                  />
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <InputField
                    readonly
                    type="text"
                    hide-details="auto"
                    :clearable="false"
                    v-model="deliveryAdviceChargeDetails.gl_no"
                    label="Revenue GL No."
                    :disabled="!deliveryAdviceChargeDetails.gl_no"
                    :error-messages="formErrors.gl_no"
                    @input="delete formErrors.gl_no"
                  />
                </v-col>

                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <InputField
                    type="number"
                    hide-details="auto"
                    v-model="deliveryAdviceChargeDetails.rate"
                    :label="`Rate (${$globals.currency}) `"
                    :disabled="!deliveryAdviceChargeDetails.charge"
                    :rules="[
                      (v) =>
                        $globals.fourDecimalPlacesRegExp.test(v) ||
                        'Rate must have 4 decimal places and positive value only',
                    ]"
                    :error-messages="formErrors.rate"
                    @input="
                      delete formErrors.rate;
                      calculateRate();
                    "
                  />
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <InputField
                    readonly
                    type="text"
                    hide-details="auto"
                    :clearable="false"
                    v-model="deliveryAdviceChargeDetails.tax_percentage"
                    label="Tax (%)"
                    :disabled="!deliveryAdviceChargeDetails.tax_percentage"
                    :error-messages="formErrors.tax_percentage"
                    @input="delete formErrors.tax_percentage"
                  />
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <InputField
                    hide-details="auto"
                    type="number"
                    :label="`Tax Amount (${$globals.currency})`"
                    :clearable="false"
                    readonly
                    :value="deliveryAdviceChargeDetails.tax"
                  />
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <InputField
                    hide-details="auto"
                    type="number"
                    :label="`Amount (${$globals.currency})`"
                    :clearable="false"
                    readonly
                    :value="deliveryAdviceChargeDetails.amount_with_tax"
                  />
                </v-col>

                <v-col
                  v-show="daCharges && daCharges.length"
                  cols="12"
                  class="Form-Columns-Dense"
                >
                  <span class="text-h6 font-weight-regular"> New Charges </span>

                  <AgGridVue
                    style="width: 100%; height: calc(100vh - 445px)"
                    class="ag-theme-alpine"
                    id="myGrid"
                    :columnDefs="headers"
                    :context="context"
                    :grid-options="gridOptions"
                    :suppressDragLeaveHidesColumns="true"
                    :rowData="daCharges"
                  ></AgGridVue>
                </v-col>

                <v-col cols="12" class="Form-Columns-Dense mt-6">
                  <v-btn
                    depressed
                    color="primary"
                    @click="stepper = 3"
                    :disabled="!valid2"
                    class="mr-1"
                  >
                    Next
                  </v-btn>
                  <v-btn color="secondary" depressed @click="stepper = 1">
                    Previous
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>

          <!-- cost charges -->
          <v-stepper-content step="3" class="pa-0">
            <v-form ref="stepperForm3" v-model="valid3">
              <v-row class="mx-2 my-0">
                <v-col cols="12" class="Form-Columns-Dense">
                  <div class="d-flex">
                    <span class="text-h6 font-weight-regular">
                      Add Cost Charges
                    </span>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      small
                      depressed
                      :disabled="!deliveryAdviceChargeDetails.rate || !valid3"
                      @click="
                        addCostDACharge();
                        delete formErrors.rate;
                        delete formErrors.charge;
                      "
                      class="mb-1"
                    >
                      Add
                    </v-btn>
                  </div>
                  <v-divider />
                </v-col>
                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <MultiSelect
                    outlined
                    :items="allChargeList"
                    v-model="deliveryAdviceChargeDetails.charge"
                    itemText="charge_title"
                    itemValue="id"
                    label="Select Charge"
                    :item-disabled="
                      (item) => {
                        return selectedCostDACharges.includes(item.id);
                      }
                    "
                    hide-details="auto"
                    returnObject
                    :error-messages="formErrors.charge"
                    @change="
                      delete formErrors.charge;
                      setChargeDetails();
                    "
                  />
                </v-col>
                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <MultiSelect
                    readonly
                    outlined
                    hide-details="auto"
                    label="Tax Type *"
                    :rules="[(val) => !!val || 'Tax Type is required']"
                    :items="taxTypeList"
                    v-model="deliveryAdviceChargeDetails.tax_type"
                    :error-messages="formErrors.tax_type"
                    @change="
                      setChargeDetails();
                      delete formErrors.tax_type;
                    "
                  />
                </v-col>
                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <InputField
                    
                    hide-details="auto"
                    v-model="deliveryAdviceChargeDetails.description"
                    label="Description"
                    :clearable="false"
                    :error-messages="formErrors.description"
                    @input="delete formErrors.description"
                  />
                </v-col>

                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <InputField
                    readonly
                    type="text"
                    hide-details="auto"
                    :clearable="false"
                    v-model="deliveryAdviceChargeDetails.cost_gl_no"
                    label="Cost GL No."
                    :disabled="!deliveryAdviceChargeDetails.cost_gl_no"
                    :error-messages="formErrors.cost_gl_no"
                    @change="delete formErrors.cost_gl_no"
                  />
                </v-col>
                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <MultiSelect
                    label="Select Vendor *"
                    :items="transporterList"
                    itemText="company_name"
                    itemValue="company_name"
                    hide-details="auto"
                    :rules="[(val) => !!val || 'Transporter is required']"
                    v-model="deliveryAdviceChargeDetails.vendor"
                    :error-messages="formErrors.transporter"
                    @change="delete formErrors.transporter"
                  />
                </v-col>

                <v-col cols="12" class="pa-0">
                  <v-row class="ma-0">
                    <v-col cols="12" lg="2" class="Form-Columns-Dense">
                      <InputField
                        type="number"
                        hide-details="auto"
                        v-model="deliveryAdviceChargeDetails.rate"
                        :label="`Rate (${$globals.currency}) `"
                        :disabled="!deliveryAdviceChargeDetails.charge"
                        :rules="[
                          (v) =>
                            $globals.fourDecimalPlacesRegExp.test(v) ||
                            'Rate must have 4 decimal places and positive value only',
                        ]"
                        :error-messages="formErrors.rate"
                        @input="
                          delete formErrors.rate;
                          calculateRate();
                        "
                      />
                    </v-col>
                    <v-col cols="12" lg="2" class="Form-Columns-Dense">
                      <InputField
                        readonly
                        type="text"
                        hide-details="auto"
                        :clearable="false"
                        v-model="deliveryAdviceChargeDetails.tax_percentage"
                        label="Tax (%)"
                        :disabled="!deliveryAdviceChargeDetails.tax_percentage"
                        :error-messages="formErrors.tax_percentage"
                        @input="delete formErrors.tax_percentage"
                      />
                    </v-col>
                    <v-col cols="12" lg="2" class="Form-Columns-Dense">
                      <InputField
                        hide-details="auto"
                        type="number"
                        :label="`Tax Amount (${$globals.currency})`"
                        :clearable="false"
                        readonly
                        :value="deliveryAdviceChargeDetails.tax"
                      />
                    </v-col>
                    <v-col cols="12" lg="2" class="Form-Columns-Dense">
                      <InputField
                        hide-details="auto"
                        type="number"
                        :label="`Amount (${$globals.currency})`"
                        :clearable="false"
                        readonly
                        :value="deliveryAdviceChargeDetails.amount_with_tax"
                      />
                    </v-col>
                    <v-col cols="12" lg="2" class="Form-Columns-Dense">
                      <InputField
                        type="text"
                        label="Invoice Number *"
                        hide-details="auto"
                        :rules="[(v) => !!v || 'Invoice Number is Required']"
                        v-model="deliveryAdviceChargeDetails.invoice_no"
                      />
                    </v-col>
                    <v-col cols="12" lg="2" class="Form-Columns-Dense">
                      <DateField
                        ref="dateField"
                        :inputProps="{
                          dense: true,
                          label: 'Invoice Date *',
                          'hide-details': 'auto',
                          rules: [
                            (val) => !!val || 'Invoice Date is Required!',
                          ],
                        }"
                        v-model="deliveryAdviceChargeDetails.invoice_date"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col
                  v-show="daCostCharges && daCostCharges.length"
                  cols="12"
                  class="Form-Columns-Dense"
                >
                  <span class="text-h6 font-weight-regular"> New Charges </span>

                  <AgGridVue
                    style="width: 100%; height: calc(100vh - 445px)"
                    class="ag-theme-alpine"
                    id="costChargeType"
                    :columnDefs="headers"
                    :context="context"
                    :grid-options="chargeGridOptions"
                    :suppressDragLeaveHidesColumns="true"
                    :rowData="daCostCharges"
                  ></AgGridVue>
                </v-col>

                <v-col cols="12" class="Form-Columns-Dense mt-6">
                  <v-btn
                    depressed
                    color="primary"
                    @click="stepper = 4"
                    :disabled="!valid2"
                    class="mr-1"
                  >
                    Next
                  </v-btn>
                  <v-btn color="secondary" depressed @click="stepper = 1">
                    Previous
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step="4" class="pa-0">
            <v-form ref="stepperForm4" v-model="valid4">
              <v-row class="mx-2 my-0">
                <v-col cols="12" class="Form-Columns-Dense">
                  <span class="text-h6 font-weight-regular">
                    Upload Documents
                  </span>
                </v-col>

                <v-col cols="12" sm="6" md="4" class="Form-Columns-Dense">
                  <InputField
                    type="text"
                    label="Document Type"
                    hide-details="auto"
                    v-model="document_type"
                    :error-messages="formErrors.document_type"
                    @input="delete formErrors.document_type"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  lg="7"
                  class="Form-Columns-Dense"
                >
                  <v-file-input
                    label="Document"
                    outlined
                    clearable
                    show-size
                    dense
                    :rules="[
                      (file) =>
                        !file ||
                        file.size < 2e6 ||
                        'File size should be less than 2 MB!',
                    ]"
                    accept="image/x-png,image/jpg,image/jpeg,application/pdf"
                    hide-details="auto"
                    v-model="document"
                    :error-messages="formErrors.document"
                    @input="delete formErrors.document"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  lg="1"
                  class="Form-Columns-Dense"
                >
                  <v-btn
                    color="primary"
                    depressed
                    :disabled="!document || !document_type || !valid4"
                    @click="addDocument"
                  >
                    Add
                  </v-btn>
                </v-col>

                <v-col cols="12" class="Form-Columns-Dense my-6">
                  <v-btn color="secondary" depressed @click="stepper = 1">
                    Previous
                  </v-btn>
                </v-col>

                <v-col
                  v-show="daDocuments && daDocuments.length"
                  cols="12"
                  class="px-2 pt-2 pb-0"
                >
                  <span class="text-h6 font-weight-regular"> New Upload </span>
                </v-col>

                <v-col
                  v-show="daDocuments && daDocuments.length"
                  cols="12"
                  class="pa-2"
                >
                  <v-card elevation="0" outlined>
                    <v-card-text class="py-1 px-0">
                      <v-row
                        class="ma-0"
                        v-for="(data, i) in daDocuments"
                        :key="i"
                      >
                        <v-col cols="12" lg="5" class="py-2">
                          <a :href="generateUrl(data.document)" target="_black">
                            <span>{{ data.document_type }}</span>
                          </a>
                        </v-col>

                        <v-col cols="12" lg="5" class="py-2">
                          <a :href="generateUrl(data.document)" target="_black">
                            <span>{{ data.document.name }}</span>
                          </a>
                        </v-col>

                        <v-col cols="6" lg="1" class="py-2">
                          <span>{{ getSize(data.document.size) }}</span>
                        </v-col>

                        <v-col cols="6" lg="1" class="text-right py-2">
                          <v-icon small color="primary" @click="removeFile(i)">
                            mdi-close
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>

      <CustomerContractDetailsDialog
        v-model="CustomerContractDetails"
        :customerContractId="selectedContractId"
      />
      <CustomerContractDialog
        v-model="showAddCustomerContractDialog"
        :customerId="directDaDetails.customer"
        :jobType="directDaDetails.job_type"
        @closeAddressForm="showAddCustomerContractDialog = false"
        @refreshList="getContractList"
      />
      <CustomerAddressForm
        v-model="showCustomerAddressForm"
        :customerId="directDaDetails.customer"
        module="Customer"
        @closeAddressForm="showCustomerAddressForm = false"
        @refreshList="getCustomerAddressList"
      />
      <AdminDAHazardousForm
        v-model="showHazardousDialog"
        dialogTitle="DA Hazardous Cargo"
        :daHazardousFormDetails="daHazardousFormDetails"
        @saveDAHazardousForm="saveDAHazardousForm"
      />
      <DaReplicaDialog
        v-model="replicaDialog"
        :daDetails="selectedDaDetails"
        :daPayload="replicateDaPayload"
        :replicateNewDA="newDA"
        @replicateDA="sequencingDaCreation"
      />
    </template>
  </FormComponent>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import TextareaField from "@/components/FormBaseComponents/TextareaField.vue";
import CustomHeader from "@/components/AgGridComponents/General/CustomHeader.vue";
import FormComponent from "@/components/General/FormComponent.vue";
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import Tooltip from "@/components/General/Tooltip.vue";
import CustomerContractDialog from "@/components/ModuleBased/dialogForms/CustomerContractDialog.vue";
import CustomerAddressForm from "@/components/ModuleBased/dialogForms/CustomerAddressForm.vue";
import CustomerContractDetailsDialog from "@/components/ModuleBased/dialogs/CustomerContractDetailsDialog.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";
import AdminDAHazardousForm from "@/components/ModuleBased/dialogForms/AdminDAHazardousForm.vue";
import chargesRemoveButton from "@/components/AgGridComponents/chargesRemoveButton.vue";
import DaReplicaDialog from "@/components/ModuleBased/dialogForms/DaReplicaDialog.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    TextareaField,
    AgGridVue,
    agColumnHeader: CustomHeader,
    FormComponent,
    CustomerContractDialog,
    InputField,
    MultiSelect,
    Tooltip,
    CustomerAddressForm,
    CustomerContractDetailsDialog,
    DateField,
    AdminDAHazardousForm,
    chargesRemoveButton,
    DaReplicaDialog,
  },

  data() {
    return {
      showHazardousDialog: false,
      hazardousId: null,
      daHazardousFormDetails: {},
      getListCalled: false,

      formFieldHints: [{}],
      showAddCustomerContractDialog: false,
      CustomerContractDetails: false,
      selectedContractId: null,
      showCustomerAddressForm: false,
      deliveryAdviceChargeDetails: {},
      daCharges: [],
      daCostCharges: [],
      selectedDACharges: [],
      selectedCostDACharges: [],
      allChargeList: [],
      allCustomerContractCharges: [],
      taxTypeList: [
        "Standard Rated",
        "Zero Rated",
        "Exempt Rated",
        "Exclude Rated",
      ],

      directDaDetails: {
        has_genset: "No",
        is_hazmat: "No",
        is_sub_hire: "No",
        order_containers: [{}],
        milestones: [],
      },

      replicaDialog: false,
      selectedDaDetails: {},

      formErrors: {},
      contrPermissions: null,

      allCustomerContractList: [],
      allDepotList: [],
      allBranchList: [],
      allCustomerAddressList: [],
      allContainersType: [],
      allCustomerList: [],
      allJobType: [],
      transporterList: [],

      milestoneList: [],
      currencyList: [],

      document_type: null,
      document: null,
      daDocuments: [],

      stepper: "1",
      valid1: true,
      valid2: true,
      valid3: true,
      valid4: true,
      jobType: null,
      perm: null,
      is_replicate_da: "No",
      newDA: true,
      replicateDaPayload: {},

      backRoute: { path: "/app/admin/delivery-advice/list" },
      today: new Date().toISOString().slice(0, 10),
      currentDateTime: new Date(),

      customerAddressListTimeout: null,
      customerContractListTimeout: null,
      jobTypeListTimeout: null,

      headers: [
        {
          headerName: "Charge Title",
          field: "charge_title",
          tooltipField: "charge_title",
          minWidth: 150,
        },
        {
          headerName: "Tax Type",
          field: "tax_type",
          tooltipField: "tax_type",
          minWidth: 150,
        },
        {
          headerName: "Description",
          field: "description",
          tooltipField: "description",
          minWidth: 350,
        },
        {
          headerName: "DA Charge Type",
          field: "da_charge_type",
          tooltipField: "da_charge_type",
          minWidth: 150,
        },
        {
          headerName: "Revenue GL No.",
          field: "gl_no",
          tooltipField: "gl_no",
          minWidth: 140,
        },
         {
          headerName: "Cost GL No.",
          field: "cost_gl_no",
          tooltipField: "cost_gl_no",
          minWidth: 140,
        },

        {
          headerName: `Rate (${this.$globals.currency})`,
          field: "rate",
          tooltipField: "rate",
          minWidth: 120,
        },
        {
          headerName: "Tax (%)",
          field: "tax_percentage",
          tooltipField: "tax_percentage",
          minWidth: 80,
        },
        {
          headerName: `Tax Amount (${this.$globals.currency})`,
          field: "tax",
          tooltipField: "tax",
          minWidth: 150,
        },
        {
          headerName: `Amount (${this.$globals.currency})`,
          field: "amount_with_tax",
          tooltipField: "amount_with_tax",
          minWidth: 140,
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: "chargesRemoveButton",
          pinned: "right",
          width: 90,
        },
      ],
      // ag-grid vars
      gridOptions: {
        defaultColDef: {
          resizable: true,
          flex: 1,
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },
      chargeGridOptions: {
        defaultColDef: {
          resizable: true,
          flex: 1,
        },

        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
      },
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (!obj) {
        return null;
      } else {
        return obj;
      }
    },
    allFormValidation() {
      return this.valid1 && this.valid2 && this.valid4;
    },
  },
  watch: {
    directDaDetails: {
      handler(val) {
        if (val.order_containers && val.order_containers.length > 0) {
          val.order_containers.forEach((item, index) => {
            if (
              item.container_no &&
              !this.$globals.containerNumberRegExp.test(item.container_no)
            ) {
              this.formFieldHints[index]["container_no"] =
                "Invalid Container No!";
            } else {
              delete this.formFieldHints[index]["container_no"];
            }
          });
        }
      },
      deep: true,
    },
  },
  methods: {
    getTransporterList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        company_type: "Transporter",
        limit: "all",
      };
      this.$api.company
        .getCompanySelectList(params)
        .then((res) => {
          this.transporterList = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    calculateRate() {
      const rate = this.deliveryAdviceChargeDetails.rate;
      const tax = this.deliveryAdviceChargeDetails.tax_percentage;
      const quantity = 1;
      if (rate && quantity) {
        const totalGrossRate = parseFloat(rate) * parseFloat(quantity);
        if (totalGrossRate && tax) {
          const totalTaxAmount = totalGrossRate * (parseFloat(tax) / 100);

          this.deliveryAdviceChargeDetails.tax = totalTaxAmount.toFixed(4);
          this.deliveryAdviceChargeDetails.amount_with_tax = (
            totalGrossRate + totalTaxAmount
          ).toFixed(4);
        }
      }
    },

    updateselectedContract() {
      if (
        this.directDaDetails.customer_contract &&
        typeof this.directDaDetails.customer_contract == "object"
      ) {
        this.selectedContractId = this.directDaDetails.customer_contract.id;
      }
    },
    addDAHazardousCargo(daID) {
      let obj = this.daHazardousFormDetails;
      function isEmptyObject(obj) {
        return JSON.stringify(obj) === "{}";
      }
      if (isEmptyObject(obj)) {
        return;
      }

      let payload = { ...this.daHazardousFormDetails, delivery_advice: daID };
      this.$api.daHazardousCargo
        .addDAHazardousCargo(payload)
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    },

    saveDAHazardousForm(data) {
      this.daHazardousFormDetails = { ...data };
    },

    resetForm() {
      this.directDaDetails = {
        has_genset: "No",
        is_hazmat: "No",
        order_containers: [{}],
        milestones: [],
        customer: {},
      };
      this.formErrors = {
        order_containers: [{}],
        milestones: [],
        customer: [],
      };
      this.$refs.stepperForm1.resetValidation();
      this.$refs.stepperForm2.resetValidation();
    },

    checkCustomer() {
      if (this.directDaDetails && this.directDaDetails.customer) {
        let customer = {
          ...this.directDaDetails.customer,
        };
        this.directDaDetails = {
          ...this.directDaDetails,
          currency: customer.currency,
        };
      }
    },

    addContractPageRedirect() {
      const path = {
        path: "/app/admin/customer-contract/add",
      };
      path.query = {
        backRoute: this.$route.path,
        da_id: this.$route.query.da_id,
      };
      this.$router.push(path);
    },

    getChargeList(params = {}) {
      params = {
        ...params,
        limit: "all",
        is_active: "true",
        is_system_generated: "false",
      };

      this.$api.charge
        .getChargeSelectList(params)
        .then((res) => {
          this.allChargeList = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    setChargeDetails() {
      if (this.deliveryAdviceChargeDetails.charge) {
        const c = { ...this.deliveryAdviceChargeDetails.charge };
        delete c.id;

        this.deliveryAdviceChargeDetails = {
          ...this.deliveryAdviceChargeDetails,
          ...c,
        };

        this.calculateRate();
      }
    },

    addDACharge() {
      this.selectedDACharges.push(this.deliveryAdviceChargeDetails.charge.id);
      this.daCharges.push({
        charge_title: this.deliveryAdviceChargeDetails.charge.charge_title,
        charge: this.deliveryAdviceChargeDetails.charge.id,
        rate: this.deliveryAdviceChargeDetails.rate,
        gl_no: this.deliveryAdviceChargeDetails.gl_no,
        tax_percentage: this.deliveryAdviceChargeDetails.tax_percentage,
        description: this.deliveryAdviceChargeDetails.description,
        arabic_description: this.deliveryAdviceChargeDetails.arabic_description,
        tax: this.deliveryAdviceChargeDetails.tax,
        amount_with_tax: this.deliveryAdviceChargeDetails.amount_with_tax,
        da_charge_type: "Revenue",
        tax_type: this.deliveryAdviceChargeDetails.tax_type,
      });
      this.deliveryAdviceChargeDetails = {};
    },

    addCostDACharge() {
      this.selectedCostDACharges.push(
        this.deliveryAdviceChargeDetails.charge.id
      );
      this.daCostCharges.push({
        charge_title: this.deliveryAdviceChargeDetails.charge.charge_title,
        charge: this.deliveryAdviceChargeDetails.charge.id,
        rate: this.deliveryAdviceChargeDetails.rate,
        cost_gl_no: this.deliveryAdviceChargeDetails.cost_gl_no,
        tax_percentage: this.deliveryAdviceChargeDetails.tax_percentage,
        description: this.deliveryAdviceChargeDetails.description,
        arabic_description: this.deliveryAdviceChargeDetails.arabic_description,
        tax: this.deliveryAdviceChargeDetails.tax,
        amount_with_tax: this.deliveryAdviceChargeDetails.amount_with_tax,
        da_charge_type: "Cost",
        vendor: this.deliveryAdviceChargeDetails.vendor,
        tax_type: this.deliveryAdviceChargeDetails.tax_type,
        invoice_no: this.deliveryAdviceChargeDetails.invoice_no,
        invoice_date: this.deliveryAdviceChargeDetails.invoice_date,
      });
      this.deliveryAdviceChargeDetails = {};
    },

    removeCharges(index) {
      this.daCharges.splice(index, 1);
      this.selectedDACharges.splice(index, 1);
    },
    removeCostCharges(index) {
      this.daCostCharges.splice(index, 1);
      this.selectedCostDACharges.splice(index, 1);
    },

    async createBulkDACharges(id) {
      let payload = {
        id: id,
        data: this.daCharges,
      };
      await this.$api.deliveryAdvice.createBulkDACharges(payload);
    },

    checkFormErrors() {
      if (
        this.formErrors &&
        this.formErrors.order_containers &&
        (this.formErrors.order_containers.length == 0 ||
          this.formErrors.order_containers.every(
            (obj) => !obj || Object.keys(obj).length == 0
          ))
      ) {
        delete this.formErrors.order_containers;
      }
    },

    addDocument() {
      this.daDocuments.push({
        document_type: this.document_type,
        document: this.document,
      });
      this.document = null;
      this.document_type = null;
    },

    removeFile(index) {
      this.daDocuments.splice(index, 1);
    },

    generateUrl(file) {
      return URL.createObjectURL(file);
    },

    getSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },

    async uploadDADocuments(id) {
      let i = 0;
      while (i < this.daDocuments.length) {
        let payload = {
          id: id,
          data: null,
        };
        let newForm = new FormData();
        newForm.append("document_type", this.daDocuments[i].document_type);
        newForm.append("document", this.daDocuments[i].document);
        payload.data = newForm;
        await this.$api.deliveryAdvice.uploadDeliveryAdviceDocument(payload);
        i++;
      }
    },

    removeContainer(index) {
      this.directDaDetails.order_containers.splice(index, 1);
      this.formFieldHints.splice(index, 1);
    },

    sequencingDaCreation(payload) {
      payload.da_charges = this.daCharges;
      this.$bus.$emit("showLoader", true);
      this.$api.deliveryAdvice
        .addDirectDeliveryAdvice(payload)
        .then((res) => {
          localStorage.removeItem("addDirectDaDetails");

          if (this.daDocuments.length > 0) {
            this.uploadDADocuments(res.data.da_id);
          }

          if (
            payload &&
            payload.is_hazmat == "Yes" &&
            this.daHazardousFormDetails
          ) {
            this.addDAHazardousCargo(res.data.da_id);
          }
          this.$bus.$emit("showLoader", false);
          this.replicaDialog = false;
          this.resetForm();

          this.$bus.$emit("showToastMessage", {
            message: "Delivery Advice added successfully!",
            color: "success",
          });

          this.$router.push(`/app/admin/trip/list`);
        })
        .catch((err) => {
          this.replicaDialog = false;
          this.formErrors = err.data;
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't Add Delivery Advice!",
            color: "error",
          });
        });
    },

    submitForm() {
      const payload = {
        ...this.directDaDetails,
        payment_term: "Credit",
      };

      if (payload.assigned_destination_address) {
        payload.assigned_destination_address =
          payload.assigned_destination_address.id;
      }

      if (payload.assigned_source_address) {
        payload.assigned_source_address = payload.assigned_source_address.id;
      }

      if (payload.assigned_mid_address) {
        payload.assigned_mid_address = payload.assigned_mid_address.id;
      }

      if (payload.customer_contract) {
        payload.customer_contract = payload.customer_contract.id;
      }

      if (payload.customer) {
        payload.customer = payload.customer.id;
      }

      if (this.daCharges) {
        payload.da_charges = this.daCharges;
      }
      if (this.daCostCharges) {
        payload.da_charges = this.daCharges.concat(this.daCostCharges);
      }

      if (this.is_replicate_da == "Yes") {
        this.selectedDaDetails = {};
        this.replicaDialog = true;
        let count = 1;

        let order_container = this.directDaDetails.order_containers.map((a) => {
          return { ...a };
        });

        order_container.forEach((container) => {
          let name;
          for (let i = 0; i < this.allCustomerContractList.length; i++) {
            this.allCustomerContractList[i].container_types.find((type) => {
              if (type.container_type == container.container_type) {
                name = type.container_type_title;
                return;
              }
            });
          }

          container.id = count + "oc";
          container.container_type_title = name;
          count++;
        });
        this.selectedDaDetails.delivery_advice_containers = order_container;
        this.replicateDaPayload = payload;
        return;
      }

      this.$bus.$emit("showLoader", true);

      this.$api.deliveryAdvice
        .addDirectDeliveryAdvice(payload)
        .then((res) => {
          localStorage.removeItem("addDirectDaDetails");

          if (this.daDocuments.length > 0) {
            this.uploadDADocuments(res.data.da_id);
          }

          // if (this.daCharges.length > 0) {
          //   this.createBulkDACharges(res.data.da_id);
          // }

          if (
            payload &&
            payload.is_hazmat == "Yes" &&
            this.daHazardousFormDetails
          ) {
            this.addDAHazardousCargo(res.data.da_id);
          }

          this.$bus.$emit("showLoader", false);

          this.$bus.$emit("showToastMessage", {
            message: "Delivery Advice added successfully!",
            color: "success",
          });
          this.$router.push(`/app/admin/trip/list`);
        })
        .catch((err) => {
          this.formErrors = err.data;
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't Add Delivery Advice!",
            color: "error",
          });
        });
    },

    setCustomerContractCharges() {
      this.allCustomerContractCharges.forEach((item, index) => {
        this.selectedDACharges.push(item.charge);

        this.daCharges.push({
          charge_title: item.charge_title,
          charge: item.charge,
          rate: item.rate,
          gl_no: item.gl_no,
          tax_percentage: item.tax_percentage,
          description: item.description,
          arabic_description: item.arabic_description,
          da_charge_type: item.da_charge_type,
          tax: item.tax,
          amount_with_tax: item.amount_with_tax,
          tax_type: item.tax_type,
        });
      });
    },

    getCustomerContractCharges(params = {}) {
      this.selectedDACharges = [];
      this.daCharges = [];

      if (this.directDaDetails && this.directDaDetails.customer_contract) {
        this.$bus.$emit("showLoader", true);
        params = {
          customer_contract: this.directDaDetails.customer_contract.id,
        };

        this.$api.customerContract
          .getCustomerContractCharges(params)
          .then((res) => {
            this.allCustomerContractCharges = res.data;
            this.setCustomerContractCharges();
            this.$bus.$emit("showLoader", false);
          })
          .catch((err) => {
            console.error(err);
            this.$bus.$emit("showLoader", false);
          });
      }
    },

    setContainersType() {
      if (
        this.directDaDetails &&
        this.directDaDetails.customer_contract &&
        this.directDaDetails.customer_contract.container_types
      ) {
        this.allContainersType =
          this.directDaDetails.customer_contract.container_types;
      } else {
        this.allContainersType = [];
      }

      this.getMilestoneList();
    },

    getCurrencyList() {
      this.$api.currency
        .getCurrencySelectList({ limit: "all" })
        .then((res) => {
          this.currencyList = res.data;

          const currency = this.currencyList.find((c) => {
            return c.currency_code == this.$globals.currency;
          });

          if (currency) {
            this.directDaDetails.currency = currency.currency_code;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    setSalesRepresentativeValue() {
      if (
        this.directDaDetails.customer &&
        typeof this.directDaDetails.customer !== "number"
      ) {
        const customer = this.allCustomerList.find(
          (customer) => customer.id === this.directDaDetails.customer?.id
        );
        this.directDaDetails.sales_representative =
          customer?.sales_representative;
      } else if (this.directDaDetails.customer) {
        const customer = this.allCustomerList.find(
          (customer) => customer.id === this.directDaDetails.customer
        );
        this.directDaDetails.sales_representative =
          customer?.sales_representative;
      }
    },

    getCustomerAddressList(params = {}) {
      if (this.customerAddressListTimeout) {
        clearTimeout(this.customerAddressListTimeout);
      }

      this.customerAddressListTimeout = setTimeout(() => {
        this.$bus.$emit("showLoader", true);
        params = {
          limit: "all",
          ...params,
        };
        if (this.directDaDetails.customer) {
          params.customer = this.directDaDetails.customer.id;
        }

        this.$api.customerAddress
          .getCustomerAddressSelectList(params)
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.allCustomerAddressList = res.data;
          })
          .catch((err) => {
            console.error(err);
            this.$bus.$emit("showLoader", false);
          });
      }, 200);
    },

    getContractList(params = {}) {
      if (this.customerContractListTimeout) {
        clearTimeout(this.customerContractListTimeout);
      }
      this.customerContractListTimeout = setTimeout(() => {
        if (this.directDaDetails && this.directDaDetails.job_type) {
          this.$bus.$emit("showLoader", true);
          params = {
            status: "Active",
            limit: "all",
            job_type: this.directDaDetails.job_type,

            ...params,
          };
          if (this.directDaDetails.customer) {
            params.customer = this.directDaDetails.customer.id;
          }
          this.$api.customerContract
            .getCustomerContractSelectList(params)
            .then((res) => {
              this.$bus.$emit("showLoader", false);
              this.allCustomerContractList = res.data;
              this.getCustomerContractCharges();
            })
            .catch((err) => {
              console.error(err);
              this.$bus.$emit("showLoader", false);
            });
        }
      }, 200);
    },

    getCustomerList(params = {}) {
      params = {
        company_type: "Customer",
        limit: "all",
        ...params,
        payment_term: "Credit",
      };
      this.$api.company
        .getCompanySelectList(params)
        .then((res) => {
          this.allCustomerList = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getMilestoneList(params = {}) {
      if (this.jobTypeListTimeout) {
        clearTimeout(this.jobTypeListTimeout);
      }
      this.jobTypeListTimeout = setTimeout(() => {
        if (this.directDaDetails && this.directDaDetails.job_type) {
          params = {
            job_type: this.directDaDetails.job_type,
          };
        }
        this.$api.milestone
          .getMilestoneList(params)
          .then((res) => {
            this.milestoneList = res.data;
            // this.directDaDetails.milestones = this.milestoneList.map(
            //   (m) => m.id
            // );
          })
          .catch((err) => {
            console.error(err);
            this.$bus.$emit("showLoader", false);
          });
      }, 200);
    },

    getDepotList(params = {}) {
      params = {
        limit: "all",
        ...params,
      };
      this.$api.depot
        .getDepotSelectList(params)
        .then((res) => {
          this.allDepotList = res.data;
          if (res && res.data && res.data.length) {
            let user_data = JSON.parse(localStorage.getItem("userProfile"));
            if (user_data && user_data.depot) {
              this.directDaDetails.depot = user_data.depot;
            } else {
              this.directDaDetails.depot = res.data[0].id;
            }
          }
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getBranchList(params = {}) {
      params = {
        limit: "all",
        ...params,
      };
      this.$api.branch
        .getBranchSelectList(params)
        .then((res) => {
          this.allBranchList = res.data;
          if (res && res.data && res.data.length) {
            let user_data = JSON.parse(localStorage.getItem("userProfile"));
            if (user_data && user_data.branch) {
              this.directDaDetails.branch = user_data.branch;
            } else {
              this.directDaDetails.branch = res.data[0].id;
            }
          }
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    setBackRoute() {
      if ("backRoute" in this.$route.query) {
        this.backRoute.path = this.$route.query.backRoute;
      }
      if ("active_tab" in this.$route.query) {
        if (!this.backRoute.query) {
          this.backRoute.query = {};
        }
        this.backRoute.query.active_tab = this.$route.query.active_tab;
      }
    },

    setJobType() {
      this.getContractList();
    },
    getLists(apis) {
      this.$bus.$emit("showLoader", true);

      return new Promise((resolve, reject) => {
        Promise.all(apis)
          .then((val) => {
            this.getListCalled = false;
            this.$bus.$emit("showLoader", false);
            resolve();
          })
          .catch((err) => {
            this.getListCalled = false;
            this.$bus.$emit("showLoader", false);
            reject();
          });
      });
    },
    getJobTypeOptions() {
      this.$api.bmr
        .getBMROptions()
        .then((res) => {
          this.allJobType = res.data.actions.POST.job_type.choices;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },

  mounted() {
    let movementTime = null;
    let movementTimeMinutes = this.currentDateTime.getMinutes();
    if (movementTimeMinutes.toString().length == 1) {
      movementTime =
        this.currentDateTime.getHours() + ":" + `0${movementTimeMinutes}`;
    } else {
      movementTime =
        this.currentDateTime.getHours() + ":" + movementTimeMinutes;
    }
    this.directDaDetails = {
      ...this.directDaDetails,
      movement_date: this.today,
      movement_time: movementTime,
    };
  },

  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("delivery_advice");
      const contractPermissions = getPermissions("contract");
      if (!permissions || !permissions.add) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.contrPermissions = { ...contractPermissions };
        if (!this.getListCalled) {
          this.getListCalled = true;
          this.getLists([
            // this.getChargeList(),
            this.getDepotList(),
            this.getBranchList(),
            this.getJobTypeOptions(),
            this.getCurrencyList(),
            this.setBackRoute(),
            this.getCustomerList(),
          ]);
        }
      }
    }
  },
};
</script>

<style scoped>
.Container-Height-Class {
  height: calc(100vh - 297px);
  overflow-y: auto;
}
.DA-Form-Height-Class {
  height: calc(100vh - 297px);
  overflow-y: auto;
}
.DropZone {
  height: 250px;
}
.contract-field-width {
  width: calc(100% - 116px);
}
</style>
