import { driver } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
    getDriverList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(driver.driverBase, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getDriverSelectList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${driver.driverBase}select/`, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    getDriverOptions(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .options(driver.driverBase, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    getDriverDetails(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${driver.driverBase}${id}/view/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    getDriverObject(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${driver.driverBase}${id}/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    addDriver(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(driver.driverBase, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    updateDriver(payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(`${driver.driverBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    changeLanguageDriver(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(
                    `${driver.driverBase}${payload.driver_id}/change_langauge_driver/`,
                    payload
                )
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    partiallyUpdateDriver(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${driver.driverBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    uploadDriverDocument(payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${driver.driverBase}${payload.id}/upload/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    getDriverDocumentOptions() {
        return new Promise((resolve, reject) => {
            axios
                .options(`${driver.driverDocBase}`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    deleteDriverDocument(id) {
        return new Promise((resolve, reject) => {
            axios
                .delete(`${driver.driverDocBase}${id}/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
});