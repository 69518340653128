<template>
  <v-dialog v-model="tariffBulkDownloadDialog" max-width="500">
    <v-card>
      <v-card-title class="secondary white--text">
        <span>Download Customer Tariff </span>

        <v-spacer></v-spacer>

        <v-btn icon @click="tariffBulkDownloadDialog = false">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-2">
        <v-form v-model="isValid" v-if="tariffBulkDownloadDialog">
          <v-row class="my-0">
            <v-col cols="12">
              <MultiSelect
                :items="allCustomerList"
                label="Select Customer *"
                itemText="company_name"
                itemValue="id"
                :rules="[(val) => !!val || 'Customer is Required!']"
                v-model="customer"
              />
            </v-col>

            <v-col cols="12">
              <MultiSelect
                :items="allJobType"
                label="Select Job Type"
                itemText="job_type"
                itemValue="id"
                v-model="job_type"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="d-flex justify-center">
        <v-btn
          depressed
          color="primary"
          :disabled="!isValid"
          @click="downloadCustomerTariffDataExcel(downloadModule)"
        >
          <span>Submit</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";

export default {
  components: { InputField, MultiSelect },
  props: {
    value: {
      type: Boolean,
    },
    downloadTo: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      allJobType: [],
      allCustomerList: [],
      isValid: true,
      customer: null,
      job_type: null,
      downloadModule: null
    };
  },
  computed: {
    tariffBulkDownloadDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.getCustomerContractOptions();
        this.getCustomerList();
        this.downloadModule = this.downloadTo
      } else {
        this.customer = null;
        this.job_type = null;
      }
    },
  },

  methods: {
    getCustomerContractOptions() {
      this.$bus.$emit("showLoader", true);
      this.$api.customerContract
        .getCustomerContractOptions()
        .then((res) => {
          this.allJobType = res.data.actions.POST.job_type.choices.map(
            (obj) => obj.value
          );
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getCustomerList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        company_type: "Customer",
        limit: "all",
        ...params,
        limited: true,
      };
      this.$api.company
        .getCompanyList(params)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.allCustomerList = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    async downloadCustomerTariffDataExcel(downloadModule) {
      let params = {
        download_type: downloadModule,
        customer: this.customer,
        job_type: this.job_type,
      };
      this.$bus.$emit("showLoader", true);
      await this.$api.customerContract
        .downloadCustomerTariffExcel(params)
        .then(async (response) => {
          this.$bus.$emit("showLoader", false);
          var blob = response.data;

          var downloadLink = window.document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(
            new Blob([blob], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          downloadLink.download = downloadModule + ".xlsx";
          downloadLink.click();
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showToastMessage", {
            message: `${
              err.data && err.data.non_field_errors
                ? err.data.non_field_errors.join()
                : "Data not found"
            }`,
            color: "error",
          });

          this.$bus.$emit("showLoader", false);
        });
    },
  },
};
</script>