<template>
  <v-row class="ma-0">
    <v-col cols="12">
      <v-tabs grow v-model="detailsTab">
        <v-tabs-slider></v-tabs-slider>
        <v-tab>{{ $t("vehicle") }}</v-tab>
        <v-tab>{{ $t("trailer") }}</v-tab>
        <v-tab>{{ $t("driver") }}</v-tab>
      </v-tabs>

      <v-row>
        <v-col cols="7"></v-col>
        <v-col cols="5">
          <b class="mr-7">{{ $t("yes") }}</b>
          <b>{{ $t("no") }}</b>
        </v-col>
      </v-row>
      <v-form ref="driverRemarkForm" v-model="isValid">
        <v-tabs-items v-model="detailsTab">
          <!-- for vehcile -->
          <v-tab-item>
  
            <v-row class="ma-0" v-for="(check, i) in checkList" :key="i">
              <v-col cols="7" v-if="check.asset_type == 'Vehicle'">
                <template>
                  <span v-if="check.is_mandatory">
                    {{ `${check.name} *` }}
                  </span>
                  <span v-else>
                    {{ `${check.name}` }}
                  </span>
                  <v-col cols="7" v-if="check.asset_type == 'Vehicle' && check.checklist_image">
                    <img
                      class="Brand-Logo"
                      alt="Checklist image"
                      :src="`${check.checklist_image}`"
                    />
                  </v-col>
                </template>
              </v-col>

              <v-col
                cols="5"
                v-if="check.asset_type == 'Vehicle'"
                class="pr-0 align-self-center"
              >
                <v-radio-group
                  row
                  class="m-0"
                  v-model="check.is_checked"
                  :disabled="checkListSubmitted"
                >
                  <v-radio
                    dense
                    color="error"
                    :value="true"
                    hide-details="auto"
                  ></v-radio>
                  <v-radio
                    dense
                    color="error"
                    :value="false"
                    hide-details="auto"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col
                cols="12"
                v-if="
                  check.asset_type == 'Vehicle' && check.is_checked == false
                "
              >
                <InputField
                  type="text"
                  v-model="check.driver_remark"
                  label="Driver Remarks*"
                  :disabled="checkListSubmitted"
                  :required="!check.is_checked"
                  :rules="[(val) => !!val || 'Driver Remarks is Required!']"
                />
              </v-col>
            </v-row>
          </v-tab-item>
          <!-- for trailer -->
          <!-- <v-row>
            <v-col cols="7"></v-col>
            <v-col cols="5">
              <b class="mr-7">YES</b>
              <b>NO</b>
            </v-col>
          </v-row> -->

          <v-tab-item >
            <v-row class="ma-0" v-for="(check, i) in checkList" :key="i">
              <v-col cols="7" v-if="check.asset_type == 'Trailer'">
                <template>
                  <span v-if="check.is_mandatory">
                    {{ `${check.name} *` }}
                  </span>
                  <span v-else>
                    {{ `${check.name}` }}
                  </span>
                </template>
              </v-col>
              <v-col cols="7" v-if="check.asset_type == 'Trailer' && check.checklist_image">
                <img
                  class="Brand-Logo"
                  alt="Brand Logo"
                  :src="`${check.checklist_image}`"
                />
              </v-col>
              <v-col cols="5" v-if="check.asset_type == 'Trailer'" class="pr-0">
                <v-radio-group
                  row
                  class="mt-0"
                  v-model="check.is_checked"
                  :disabled="checkListSubmitted"
                >
                  <v-radio
                    dense
                    color="error"
                    :value="true"
                    hide-details="auto"
                  ></v-radio>
                  <v-radio
                    dense
                    color="error"
                    :value="false"
                    hide-details="auto"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col
                cols="12"
                v-if="
                  check.asset_type == 'Trailer' && check.is_checked == false
                "
              >
                <InputField
                  type="text"
                  v-model="check.driver_remark"
                  :disabled="checkListSubmitted"
                  label="Driver Remarks*"
                  :required="!check.is_checked"
                  :rules="[(val) => !!val || 'Driver Remarks is required']"
                />
              </v-col>
            </v-row>
          </v-tab-item>

          <!-- for driver -->
          <v-tab-item>
            <v-row class="ma-0" v-for="(check, i) in checkList" :key="i">
              <v-col cols="7" v-if="check.asset_type == 'Driver'">
                <template>
                  <span v-if="check.is_mandatory">
                    {{ `${check.name} *` }}
                  </span>
                  <span v-else>
                    {{ `${check.name}` }}
                  </span>
                </template>
              </v-col>
              <v-col cols="7" v-if="check.asset_type == 'Driver' && check.checklist_image">
                <img
                  class="Brand-Logo"
                  alt="Brand Logo"
                  :src="`${check.checklist_image}`"
                />
              </v-col>

              <v-col cols="5" v-if="check.asset_type == 'Driver'" class="pr-0">
                <v-radio-group
                  row
                  class="mt-0"
                  v-model="check.is_checked"
                  :disabled="checkListSubmitted"
                >
                  <v-radio
                    dense
                    color="error"
                    :value="true"
                    hide-details="auto"
                  ></v-radio>
                  <v-radio
                    dense
                    color="error"
                    :value="false"
                    hide-details="auto"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col
                cols="12"
                v-if="check.asset_type == 'Driver' && check.is_checked == false"
              >
                <InputField
                  type="text"
                  v-model="check.driver_remark"
                  :disabled="checkListSubmitted"
                  label="Driver Remarks*"
                  :required="!check.is_checked"
                  :rules="[(val) => !!val || 'Driver Remarks is required']"
                />
              </v-col>
            </v-row>
          </v-tab-item>
          <v-row class="ma-0">
            <v-btn
              @click="submitTripCheckList"
              class="primary text-capitalize font-weight-bold"
              :disabled="
                !checkList || checkListSubmitted || !isValid || !isAllSelected
              "
              style="width: 100px"
            >
              {{ $t("submit") }}
            </v-btn>
          </v-row>
        </v-tabs-items>
      </v-form>

      <!-- <v-row>
        <v-btn
          @click="submitTripCheckList"
          class="primary text-capitalize font-weight-bold"
          :disabled="!checkList || checkListSubmitted"
          style="width: 100px"
        >
          Submit
        </v-btn>
      </v-row> -->
    </v-col>
  </v-row>
</template>

<script>
import BaseDriverDialog from "@/components/General/BaseDriverDialog.vue";
import DriverAppDriverRemarksDialog from "@/components/ModuleBased/details/driverApp/DriverAppDriverRemarksDialog.vue";
import InputField from "@/components/FormBaseComponents/InputField.vue";

export default {
  components: {
    InputField,
    BaseDriverDialog,
    DriverAppDriverRemarksDialog,
  },
  props: {
    checkListDialog: {
      type: Boolean,
    },
    tripDetails: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      checkList: [],
      selectedCheckList: {},
      formattedCheckList: [],
      driverRemarksDialog: false,
      asset_type: null,
      driver_checklist_remarks: [],
      detailsTab: 0,
      tripCheckListData: [],
      checkListSubmitted: false,
      formErrors: {},
      isValid: true,
      imageUrl: null,
    };
  },
  computed: {
    isAllSelected() {
      return this.checkList.every((item) => item.hasOwnProperty("is_checked"));
    },
  },
  watch: {
    checkListDialog(val) {
      if (val) {
        this.getDriverAppTripChecklist();
      }
    },
  },
  methods: {
    submitTripCheckList(payload = {}) {
      this.checkList.forEach((checkList) => {
        payload = {};
        payload.is_checked = checkList.is_checked ? true : false;
        payload.name = checkList.name;
        payload.asset_type = checkList.asset_type;
        payload.driver_checklist_remarks = checkList.driver_remark;
        this.tripCheckListData.push(payload);
      });
      this.$api.driverApp
        .submitTripCheckList(this.tripCheckListData)
        .then((res) => {
          this.$emit("refreshTripDetails");
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Checklist submitted successfully!",
            color: "success",
            hasActions: true,
          });
        })
        .catch((err) => {
          console.error(err.data);
          this.$bus.$emit("showLoader", false);
          if (
            err &&
            err.data &&
            err.data.error &&
            err.data.error.non_field_errors
          ) {
            this.$bus.$emit("showToastMessage", {
              message: `${
                err.data && err.data.error.non_field_errors
                  ? err.data.error.non_field_errors.join()
                  : "Cant submit checklists"
              }`,
              color: "error",
              hasActions: true,
            });
          } else {
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't update trip checklist!",
              color: "error",
              hasActions: true,
            });
          }
          this.$emit("refreshTripDetails");
        });
    },
    getDriverAppTripChecklist(params = {}) {
      this.$bus.$emit("showLoader", true);
      this.$api.driverApp
        .getDriverAppTripChecklist(params)
        .then((res) => {
          this.checkList = res.data.check_list;

          if (this.tripDetails && this.tripDetails.is_checklist_submitted) {
            this.checkListSubmitted = true;
          }
          this.$bus.$emit("showLoader", false);
        })
        .catch(() => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't get checklists!",
            color: "error",
            hasActions: true,
          });
        });
    },
  },
  mounted() {
    this.getDriverAppTripChecklist();
  },
};
</script>

<style scoped lang="scss">
.btn-position-fixed-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.card-outlined {
  border: 1px solid #0000004f !important;
  border-radius: 6px;
}
</style>
