var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
        _vm.params &&
        _vm.params.context &&
        _vm.params.context.parentComponent &&
        _vm.params.context.parentComponent.permissions &&
        _vm.params.context.parentComponent.permissions.change
      )?_c('v-icon',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":"primary"},on:{"click":_vm.editItem}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")]):_vm._e()]}}])},[_c('span',[_vm._v("Edit")])])
}
var staticRenderFns = []

export { render, staticRenderFns }