import Blank from "@/layouts/blank.vue"

import LaneList from "@/pages/admin/lane-management/lane/LaneList.vue";
import LaneAdd from "@/pages/admin/lane-management/lane/LaneAdd.vue";
import LaneEdit from "@/pages/admin/lane-management/lane/LaneEdit.vue";

const laneRoutes = [
  {
    path: "lane",
    name: "app-admin-lane-management-lane",
    component: Blank,
    children: [
      {
        path: "/",
        redirect: "/app/admin/lane-management/lane/list",
      },
      {
        path: "list",
        name: "app-admin-lane-management-lane-list",
        component: LaneList,
      },
      {
        path: "add",
        name: "app-admin-lane-management-lane-add",
        component: LaneAdd,
      },
      {
        path: "edit",
        name: "app-admin-lane-management-lane-edit",
        component: Blank,
        children: [
          {
            path: "/",
            redirect: "/app/admin/lane-management/lane/list",
          },
          {
            path: ":id",
            name: "app-admin-lane-management-lane-edit-id",
            component: LaneEdit,
          }
        ]
      },
    ]
  },
]

export default laneRoutes