import { nationality } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
    getNationalityList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(nationality.nationalityBase, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getNationalitySelectList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${nationality.nationalityBase}select/`, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getNationalityObject(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${nationality.nationalityBase}${id}/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    addNationality(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(nationality.nationalityBase, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getNationalityOptions(data) {
        return new Promise((resolve, reject) => {
            axios
                .options(nationality.nationalityBase, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    updateNationality(payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(`${nationality.nationalityBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    partiallyUpdateNationality(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${nationality.nationalityBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
});