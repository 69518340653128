<template>
  <v-snackbar
    v-bind="$attrs"
    v-on="$listeners"
    :color="color"
    :timeout="timeout"
  >
    {{ textMessage }}
    <template v-if="hasActions" v-slot:action="{ attrs }">
      <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "CommonSnackBar",
  props: {
    color: {
      type: String,
    },
    timeout: {
      type: Number,
      default: 1500,
    },
    textMessage: {
      type: String,
      required: true,
    },
    hasActions: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
