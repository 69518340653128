<template>
  <v-dialog width="500" v-model="showMilestoneForm">
    <v-card>
      <v-card-title class="secondary white--text">
        <span> {{ editMode ? "Update" : "Add" }} Milestone </span>

        <v-spacer></v-spacer>

        <v-btn icon @click="resetForm(), (showMilestoneForm = false)">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-2">
        <v-form ref="milestoneForm" v-if="showMilestoneForm" v-model="isValid">
          <v-row class="my-0">
            <v-col cols="12" class="Form-Columns">
              <MultiSelect
                label="Job Type *"
                :items="allJobTypeList"
                itemText="display_name"
                itemValue="value"
                hide-details="auto"
                :rules="[(val) => !!val || 'Job Type is required!']"
                v-model="milestoneDetails.job_type"
                :error-messages="formErrors.job_type"
                @input="delete formErrors.job_type"
              />
            </v-col>

            <v-col cols="12" class="Form-Columns">
              <InputField
                type="text"
                label="Milestone Name *"
                required
                :rules="[(val) => !!val || 'Milestone Name is required!']"
                hide-details="auto"
                v-model="milestoneDetails.milestone_name"
                :error-messages="formErrors.milestone_name"
                @input="delete formErrors.milestone_name"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          depressed
          :disabled="!isValid"
          @click="submitForm"
        >
          Submit
        </v-btn>

        <v-btn
          class="ml-3"
          color="secondary"
          v-if="!editMode"
          depressed
          @click="resetForm"
        >
          Reset
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import SelectField from "@/components/FormBaseComponents/SelectField.vue";

export default {
  components: {
    InputField,
    SelectField,
    MultiSelect,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    editMilestoneID: {
      type: [Number, null],
      default: null,
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.getMilestoneOptions();
        if (this.editMode) {
          this.getMilestoneObject(this.editMilestoneID);
        }
      } else {
        this.resetForm();
      }
    },
  },
  computed: {
    showMilestoneForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    return {
      formErrors: {},
      isValid: true,
      isTextInput: false,
      allJobTypeList: [],
      milestoneDetails: {},
    };
  },
  methods: {
    submitForm() {
      this.$bus.$emit("showLoader", true);
      if (!this.editMode) {
        this.milestoneDetails = {
          ...this.milestoneDetails,
        };
        this.$api.milestone
          .addMilestone(this.milestoneDetails)
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Milestone added successfully!",
              color: "success",
            });
            this.resetForm();
            this.showMilestoneForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't add milestone!",
              color: "error",
            });
            this.formErrors = err.data;
          });
      } else {
        this.$api.milestone
          .updateMilestone({
            id: this.milestoneDetails.id,
            data: this.milestoneDetails,
          })
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Milestone updated successfully!",
              color: "success",
            });
            this.resetForm();
            this.showMilestoneForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't update milestone!",
              color: "error",
            });
            this.formErrors = err.data;
          });
      }
    },

    getMilestoneOptions() {
      this.$bus.$emit("showLoader", true);
      this.$api.milestone
        .getMilestoneOptions()
        .then((res) => {
          this.allJobTypeList = res.data.actions.POST.job_type.choices;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    resetForm() {
      if (this.$refs.milestoneForm) {
        this.$refs.milestoneForm.reset();
      }
      this.milestoneDetails = {};
    },

    getMilestoneObject(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.milestone
        .getMilestoneObject(id)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.milestoneDetails = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
};
</script>
