var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-expansion-panel',{staticClass:"Card-Details-Expansion"},[_c('v-expansion-panel-header',{staticClass:"py-1 px-3 subHeading"},[(_vm.title != 'lane' && _vm.title != 'contract')?_c('span',{staticClass:"text-subtitle-1 text-capitalize font-weight-medium"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):(_vm.title == 'lane')?_c('span',{staticClass:"text-subtitle-1 text-capitalize font-weight-medium"},[_vm._v(" Route ")]):(_vm.title == 'contract')?_c('span',{staticClass:"text-subtitle-1 text-capitalize font-weight-medium"},[_vm._v(" Customer Tariff ")]):_vm._e()]),_c('v-expansion-panel-content',{staticClass:"pt-3 subHeading Space-Class"},[(
        Array.isArray(_vm.displayObject) &&
        _vm.displayObject.every((val) => _vm.isPrimitive(val))
      )?_c('table',{staticClass:"Primary-Table"},_vm._l((_vm.displayObject),function(value,i){return _c('tr',{key:i},[_c('td',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(`${i + 1}.`)+" ")]),_c('td',{staticClass:"text-subtitle-1 font-weight-medium"},[_vm._v(" "+_vm._s(value)+" ")])])}),0):_c('table',{staticClass:"Primary-Table"},_vm._l((_vm.displayObject),function(value,key){return _c('tr',{key:key},[(
            _vm.restrictedKeys.indexOf(key) == -1 &&
            value != null &&
            value != undefined &&
            _vm.isPrimitive(value)
          )?_c('td',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(key.replace(/_/g, " "))+" ")]):_vm._e(),(
            _vm.restrictedKeys.indexOf(key) == -1 &&
            value != null &&
            value != undefined &&
            _vm.isPrimitive(value)
          )?_c('th',{staticClass:"text-subtitle-1 font-weight-medium"},[_vm._v(" "+_vm._s(typeof value != typeof true ? value : value ? "Yes" : "No")+" ")]):_vm._e()])}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }