import Blank from "@/layouts/blank.vue"

import QuotationList from "@/pages/admin/quotations/QuotationList.vue";
import QuotationSent from "@/pages/admin/quotations/QuotationSent.vue";
import QuotationEdit from "@/pages/admin/quotations/QuotationEdit.vue";
import QuotationSentEdit from "@/pages/admin/quotations/QuotationSentEdit.vue";

const quotationRoutes = [
  {
    path: "quotations",
    name: "app-admin-quotation",
    component: Blank,
    children: [
      {
        path: "/",
        redirect: "/app/admin/quotations/list",
      },
      {
        path: "list",
        name: "app-admin-quotation-list",
        component: QuotationList,
      },
      {
        path: "sent",
        name: "app-admin-quotation-sent",
        component: QuotationSent,
      },
      {
        path: "edit",
        name: "app-admin-quotation-edit",
        component: Blank,
        children: [
          {
            path: "/",
            redirect: "/app/admin/quotations/list",
          },
          {
            path: ":id",
            name: "app-admin-quotation-edit-id",
            component: QuotationEdit,
          }
        ]
      },
      {
        path: "sent-edit",
        name: "app-admin-quotation-sent-edit",
        component: Blank,
        children: [
          {
            path: "/",
            redirect: "/app/admin/quotations/list",
          },
          {
            path: ":id",
            name: "app-admin-quotation-sent-edit-id",
            component: QuotationSentEdit,
          }
        ]
      },
    ]
  },
]

export default quotationRoutes