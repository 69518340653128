<template>
  <div>
    <div class="d-flex align-baseline">
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-btn
            icon
            @click="callBackRoute"
            v-bind="attrs"
            v-on="on"
            class="mr-3"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </template>
        <span>Back</span>
      </v-tooltip>

      <span class="text-h4"> {{ invoiceDetails.invoice_no }} Details </span>
    </div>

    <v-divider class="mt-2"></v-divider>

    <v-card elevation="0">
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
            class="Form-Columns"
            v-if="invoiceDetails.amount_info"
          >
            <span class="text-caption"> Total Amount </span>
            <p class="text-subtitle-1 font-weight-medium mb-0">
              {{
                `${invoiceDetails.amount_info.total_amount} ${$globals.currency}`
              }}
            </p>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
            class="Form-Columns"
            v-if="invoiceDetails.amount_info"
          >
            <span class="text-caption"> Total Gross Amount </span>
            <p class="text-subtitle-1 font-weight-medium mb-0">
              {{
                `${invoiceDetails.amount_info.total_gross_amount} ${$globals.currency}`
              }}
            </p>
          </v-col>

          <v-col cols="12" class="pb-0">
            <span class="text-h6 font-weight-bold">Invoice Details</span>
            <v-divider></v-divider>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" class="Form-Columns">
            <span class="text-caption">Invoice Date</span>
            <p class="text-subtitle-1 font-weight-medium mb-0">
              {{ invoiceDetails.invoice_date }}
            </p>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" class="Form-Columns">
            <span class="text-caption">Invoice From Date </span>
            <p class="text-subtitle-1 font-weight-medium mb-0">
              {{ invoiceDetails.invoice_from_date }}
            </p>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" class="Form-Columns">
            <span class="text-caption">Invoice To Date </span>
            <p class="text-subtitle-1 font-weight-medium mb-0">
              {{ invoiceDetails.invoice_to_date }}
            </p>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" class="Form-Columns">
            <span class="text-caption">Invoice Type </span>
            <p class="text-subtitle-1 font-weight-medium mb-0">
              {{ invoiceDetails.invoice_type }}
            </p>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" class="Form-Columns">
            <span class="text-caption">Departure Country </span>
            <p class="text-subtitle-1 font-weight-medium mb-0">
              {{ invoiceDetails.departure_country }}
            </p>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" class="Form-Columns">
            <span class="text-caption">Destination Country </span>
            <p class="text-subtitle-1 font-weight-medium mb-0">
              {{ invoiceDetails.destination_country }}
            </p>
          </v-col>
        </v-row>

        <v-row class="mt-3">
          <v-col
            cols="12"
            md="6"
            lg="4"
            v-if="
              invoiceDetails.remarks_info &&
              invoiceDetails.remarks_info.admin_remarks
            "
          >
            <span class="text-caption">Admin Remarks </span>
            <p class="text-subtitle-1 font-weight-medium mb-0">
              {{ invoiceDetails.remarks_info.admin_remarks }}
            </p>
          </v-col>

          <v-col
            cols="12"
            md="6"
            lg="4"
            v-if="
              invoiceDetails.remarks_info &&
              invoiceDetails.remarks_info.customer_remarks
            "
          >
            <span class="text-caption">Customer Remarks </span>
            <p class="text-subtitle-1 font-weight-medium mb-0">
              {{ invoiceDetails.remarks_info.customer_remarks }}
            </p>
          </v-col>

          <v-col
            cols="12"
            md="6"
            lg="4"
            v-if="
              invoiceDetails.remarks_info && invoiceDetails.remarks_info.remarks
            "
          >
            <span class="text-caption">Remarks </span>
            <p class="text-subtitle-1 font-weight-medium mb-0">
              {{ invoiceDetails.remarks_info.remarks }}
            </p>
          </v-col>
        </v-row>

        <v-row class="mt-5">
          <v-col cols="12" class="pb-0">
            <span class="text-h6 font-weight-bold">Company Details</span>
            <v-divider></v-divider>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
            class="Form-Columns"
            v-for="(value, key, i) in invoiceDetails.company_details"
            :key="i"
          >
            <span class="text-caption text-capitalize">
              {{ key.replace(/_/g, " ") }}
            </span>
            <p class="text-subtitle-1 font-weight-medium mb-0">
              {{ value }}
            </p>
          </v-col>

          <v-col cols="12" class="pb-0">
            <span class="text-h6 font-weight-bold">Delivery Advices</span>
            <v-divider></v-divider>
          </v-col>

          <v-col cols="12">
            <AgGridVue
              class="ag-theme-alpine Table-Height"
              id="myGrid"
              :columnDefs="headers"
              @grid-ready="onGridReady"
              :defaultColDef="defaultColDef"
              :grid-options="gridOptions"
              :suppressDragLeaveHidesColumns="true"
              :rowData="invoiceDetails.delivery_advice_details"
            ></AgGridVue>
          </v-col>

          <v-col cols="12" class="pb-0">
            <span class="text-h6 font-weight-bold">Charges</span>
            <v-divider></v-divider>
          </v-col>

          <v-col cols="12">
            <AgGridVue
              class="ag-theme-alpine Table-Height"
              id="myChargeGrid"
              :columnDefs="chargeHeaders"
              @grid-ready="chargeOnGridReady"
              :defaultColDef="chargeDefaultColDef"
              :grid-options="chargeGridOptions"
              :suppressDragLeaveHidesColumns="true"
              :rowData="invoiceDetails.invoice_charges"
            ></AgGridVue>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import DetailsParent from "@/components/General/DetailsParent.vue";
import { AgGridVue } from "ag-grid-vue";

export default {
  components: {
    DetailsParent,
    AgGridVue,
  },
  data() {
    return {
      invoiceDetails: {},
      backRoute: { path: "/app/customer/invoice/list" },

      // ag-grid vars
      headers: [
        {
          headerName: "Delivery Advice No.",
          field: "da_no",
        },
        {
          headerName: "BOE",
          field: "boe",
        },
        {
          headerName: "Completed On",
          field: "completed_on",
        },
        {
          headerName: "DA Additional Charges",
          field: "da_additional_charges",
        },
        {
          headerName: "DA date",
          field: "created",
        },
        {
          headerName: "Has Genset",
          field: "has_genset",
        },
        {
          headerName: "Is Hazmat",
          field: "is_hazmat",
        },
        {
          headerName: "Source",
          field: "source_name",
          tooltipField: "source_address",
        },
        {
          headerName: "Mid Point",
          field: "mid_point_name",
          tooltipField: "mid_address",
        },
        {
          headerName: "Destination",
          field: "destination_name",
          tooltipField: "destination_address",
        },
        {
          headerName: `Rate (${this.$globals.currency})`,
          field: "rate",
        },
      ],
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },

      // charge ag-grid vars
      chargeHeaders: [
        {
          headerName: "Charge Title",
          field: "charge_title",
        },
        {
          headerName: "Charge Type",
          field: "charge_type",
        },
        {
          headerName: `Rate (${this.$globals.currency})`,
          field: "rate",
        },
        {
          headerName: "TAX(%)",
          field: "tax_percentage",
        },
        {
          headerName: "TAX Amount",
          field: "tax_amount",
        },
        {
          headerName: "Gross Amount",
          field: "gross_amount",
        },
      ],
      chargeGridApi: null,
      chargeGridColumnApi: null,
      chargeDefaultColDef: {
        resizable: true,
      },
      chargeGridOptions: {
        onGridSizeChanged: () => {
          this.gridOptions.api.sizeColumnsToFit();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
      },
    };
  },
  methods: {
    resizeGrid() {
      if (this.gridOptions && "api" in this.gridOptions) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }

      if (this.chargeGridOptions && "api" in this.chargeGridOptions) {
        setTimeout(() => {
          this.chargeGridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },
    callBackRoute() {
      this.$router.push(this.backRoute);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    chargeOnGridReady(params) {
      this.chargeGridApi = params.api;
      this.chargeGridColumnApi = params.columnApi;
    },
    getCustomerInvoiceObject(id) {
      this.$api.customerInvoice
        .getCustomerInvoiceDetails(id)
        .then((res) => {
          this.invoiceDetails = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    setBackRoute() {
      if ("backRoute" in this.$route.query) {
        this.backRoute.path = this.$route.query.backRoute;
      }
      if ("active_tab" in this.$route.query) {
        if (!this.backRoute.query) {
          this.backRoute.query = {};
        }
        this.backRoute.query.active_tab = this.$route.query.active_tab;
      }
    },
  },
  mounted() {
    if ("id" in this.$route.params) {
      this.getCustomerInvoiceObject(this.$route.params.id);
    }
    this.setBackRoute();
  },
};
</script>

<style>
.Table-Height {
  width: 100%;
  height: 250px;
  overflow-y: auto;
}
</style>