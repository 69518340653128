<template>
  <v-file-input
    :class="designClass"
    outlined
    clearable
    show-size
    truncate-length="32"
    v-bind="$attrs"
    v-on="$listeners"
    dense
  ></v-file-input>
</template>

<script>
export default {
  name: "FileTag",
  props: {
    designClass: {
      type: String,
    },
  },
};
</script>
