<template>
  <div>
    <v-card
      elevation="0"
      color="subHeading"
      class="mb-2"
      v-for="(data, i) in documentList"
      :key="i"
    >
      <v-card-text class="py-3">
        <div class="mb-1 d-flex justify-space-between">
          <a :href="data.pod" target="_black">
            <span class="text-subtitle-1">{{ data.pod_title }}</span>
          </a>

          <div class="text-right" v-if="isDeletable">
            <v-icon color="red" @click="$emit('deleteDocument', data.id, i)">
              mdi-delete
            </v-icon>
          </div>
        </div>

        <table class="Normal-Table">
          <tbody>
            <tr v-show="data.leg_id">
              <th>Leg</th>
              <th class="px-1">:</th>
              <td>{{ data.leg_id }}</td>
            </tr>
            <tr>
              <th>Created on</th>
              <th class="px-1">:</th>
              <td>{{ data.created | formatDate(true) }}</td>
            </tr>

            <tr>
              <th>Added By</th>
              <th class="px-1">:</th>
              <td>{{ data.created_by }}</td>
            </tr>
          </tbody>
        </table>
      </v-card-text>
    </v-card>

    <div class="text-center" v-if="documentList.length == 0">
      <v-divider class="mb-4"></v-divider>
      <span>No PODs to show!</span>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["deleteDocument"],
  props: {
    documentList: {
      type: Array,
      required: true,
    },
    isDeletable: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
