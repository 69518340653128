<template>
  <ListComponent title="Vehicle" :totalCount="totalItems">
    <template #list>
      <v-row class="ma-0">
        <v-col cols="4" class="px-0 pt-0 pb-2 d-flex align-center">
          <v-text-field
            label="Search"
            name="vehicle-list-search"
            outlined
            dense
            append-icon="mdi-magnify"
            clearable
            hide-details="auto"
            v-model="filters.search"
            @input="searchAdminVehicles"
          ></v-text-field>

          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                depressed
                icon
                v-on="on"
                v-bind="attrs"
                @click="showFilterDialog = true"
                class="mx-2"
              >
                <v-badge
                  dot
                  color="primary"
                  :value="Object.keys(filters).length"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-badge>
              </v-btn>
            </template>

            <span v-if="Object.keys(filters).length == 0">Filters</span>

            <v-row v-else no-gutters>
              <v-col cols="12" class="font-weight-bold">Applied Filters</v-col>
              <v-col cols="12">
                <span
                  v-for="(value, key) in filters"
                  :key="key"
                  class="text-capitalize"
                  >{{ key.replace("_", " ") }}
                  <br />
                </span>
              </v-col>
            </v-row>
          </v-tooltip>

          <Columns :headers="headers" :gridColInstance="gridColumnApi" />
        </v-col>

        <v-col
          v-if="totalItems > 0"
          cols="8"
          class="px-0 pt-0 pb-2 d-flex justify-end"
        >
          <Pagination
            :pageNo="pageNo"
            :totalItems="totalItems"
            :pageSize="itemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>

        <v-col cols="12" class="pa-0">
          <AgGridVue
            style="width: 100%; height: calc(100vh - 172px)"
            class="ag-theme-alpine"
            id="adminVehicleList"
            :columnDefs="headers"
            :context="context"
            @grid-ready="onGridReady"
            @columnMoved="resizeGrid"
            @columnVisible="resizeGrid"
            :defaultColDef="defaultColDef"
            :grid-options="gridOptions"
            :suppressDragLeaveHidesColumns="true"
            :rowData="allVehicleList"
          />
        </v-col>
      </v-row>

      <AdminVehicleFilters
        v-model="showFilterDialog"
        :bindingObject="filters"
        @resetFilters="resetFilters"
        @applyFilters="itemsPerPageChanged(20)"
      />

      <DetailsParent v-model="detailsDrawer" :resizable="true">
        <template #details>
          <AdminVehicleDetails
            :vehicleId="selectedVehicleId"
            :permissions="permissions"
          />
        </template>
      </DetailsParent>
    </template>

    <template #dialogs>
      <BulkUpload
        v-model="vehicleBulkUploadDialog"
        :uploadTo="uploadType"
        @refreshList="itemsPerPageChanged(10)"
      />
    </template>

    <template #actions>
      <v-btn
        v-if="permissions && permissions.add"
        color="primary"
        depressed
        name="vehicle-list-add-vehicle"
        @click="addVehicle"
      >
        <v-icon>mdi-plus</v-icon>
        <span>Add Vehicle</span>
      </v-btn>
      <v-btn
        v-if="permissions && permissions.add"
        @click="openVehicleBulkUpload('vehicle')"
        name="vehicle-list-bulk-upload"
        class="ml-3"
        color="primary"
        depressed
      >
        <v-icon>mdi-plus</v-icon>
        <span>Bulk Upload</span>
      </v-btn>
    </template>
  </ListComponent>
</template>

<script>
import ListComponent from "@/components/General/ListComponent.vue";
import Columns from "@/components/General/Columns.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";
import VehicleButton from "@/components/AgGridComponents/VehicleButton.vue";
import TripGoToButton from "@/components/AgGridComponents/TripGoToButton.vue";
import CustomHeader from "@/components/AgGridComponents/General/CustomHeader.vue";
import AdminVehicleFilters from "@/components/ModuleBased/dialogs/filters/AdminVehicleFilters.vue";
import DetailsParent from "@/components/General/DetailsParent.vue";
import AdminVehicleDetails from "@/components/ModuleBased/details/AdminVehicleDetails.vue";
import BulkUpload from "@/components/General/BulkUpload.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    ListComponent,
    Columns,
    AgGridVue,
    Pagination,
    VehicleButton,
    agColumnHeader: CustomHeader,
    TripGoToButton,
    AdminVehicleFilters,
    DetailsParent,
    AdminVehicleDetails,
    BulkUpload,
  },

  data() {
    return {
      sorting: {},
      headers: [
        {
          headerName: "Fleet Number",
          field: "inventory_number",
          sortable: true,
          minWidth: 130,
        },
        {
          headerName: "Vehicle ID",
          field: "equipment_id",
          sortable: true,
          minWidth: 120,
        },
        {
          headerName: "Vehicle Number",
          field: "vehicle_number",
          sortable: true,
          minWidth: 120,
        },
        {
          headerName: "Vehicle type",
          field: "vehicle_type_title",
          sortable: true,
          tooltipField: "vehicle_type_title",
          minWidth: 300,
        },
        {
          headerName: "Payload Capacity(TON)",
          field: "payload_capacity",
          sortable: true,
          minWidth: 140,
        },
        {
          headerName: "Odometer Reading",
          field: "odometer_reading",
          sortable: true,
          minWidth: 85,
        },
        {
          headerName: "GPS ID",
          field: "gps_id",
          sortable: true,
          minWidth: 70,
        },
        {
          headerName: "Active Trip",
          field: "active_trip.trip_id",
          cellRendererFramework: "TripGoToButton",
          minWidth: 120,
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: "VehicleButton",
          pinned: "right",
          minWidth: 120,
        },
      ],

      // details vars
      selectedVehicleId: null,
      detailsDrawer: false,

      allVehicleList: [],

      // pagination vars
      pageNo: 1,
      itemsPerPage: 20,
      totalItems: 0,

      // bulk create
      uploadType: "driver",
      vehicleBulkUploadDialog: false,

      permissions: null,

      // Filter vars
      filters: {},
      showFilterDialog: false,

      // bulk vars
      showBulkDialog: false,

      // ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },
    };
  },

  computed: {
    context() {
      return { parentComponent: this };
    },
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (!obj) {
        return null;
      } else {
        return obj;
      }
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },

  methods: {
    openVehicleBulkUpload(type) {
      this.uploadType = type;
      this.vehicleBulkUploadDialog = true;
    },
    resizeGrid() {
      if (this.gridOptions && this.gridOptions.api) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },
    resetFilters() {
      this.filters = {};
      this.itemsPerPageChanged(20);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },
    searchAdminVehicles() {
      this.pageNo = 1;
      this.getVehicleList();
    },
    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getVehicleList();
    },
    prevPage() {
      this.pageNo--;
      this.getVehicleList();
    },
    nextPage() {
      this.pageNo++;
      this.getVehicleList();
    },
    applyGridSort(key, type) {
      if (this.filters && !this.filters.ordering) {
        this.filters.ordering = [];
      }

      if (type == null) {
        delete this.sorting[key];
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(`-${key}`),
          1
        );
      } else if (type == "asc") {
        this.filters.ordering.push(key);
      } else if (type == "desc") {
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(key),
          1,
          `-${key}`
        );
      }
      if (this.filters.ordering.length == 0) {
        delete this.filters.ordering;
      }
      this.itemsPerPageChanged(20);
    },
    getVehicleList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        offset: this.offset,
        limit: this.itemsPerPage,
        company: this.currentProfile.company,
        ...params,
        ...this.filters,
      };
      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }
      this.$api.vehicle
        .getVehicleList(params)
        .then((res) => {
          this.allVehicleList = res.data;
          this.totalItems = res.count;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    addVehicle() {
      this.$router.push("/app/admin/asset-management/vehicle/add");
    },

    editVehicle(id) {
      this.$router.push(`/app/admin/asset-management/vehicle/edit/${id}`);
    },

    goToTrip(trip_id) {
      this.$router.push({
        path: `/app/admin/trip/details/${trip_id}`,
        query: {
          backRoute: "/app/admin/asset-management/vehicle/list",
        },
      });
    },

    viewVehicle(id) {
      this.selectedVehicleId = id;
      this.detailsDrawer = true;
    },
  },

  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("vehicle");
      if (!permissions || !permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.permissions = { ...permissions };
        this.getVehicleList();
      }
    }
  },
};
</script>
