<template>
  <router-view />
</template>
<script>
import { hasRouteAccess } from "@/utils/functions";

export default {
  name: "mainPage",
  methods: {
    async checkifLoggedIn() {
      let user = localStorage.getItem("user");
      if (!user) {
        if (this.$route.path !== "/app/login") {
          this.$router.push({ path: "/app/login" });
        }
      } else {
        let user_type = localStorage.getItem("user_type");
        if (user_type) {
          user_type = user_type.toLowerCase();
          if (this.$route.fullPath == "/app" || !hasRouteAccess(this.$route)) {
            const url = `/app/${user_type}`;
            this.$router.push(url);
          }
        }
      }
    },
  },
  created() {
    this.checkifLoggedIn();
  },
};
</script>
