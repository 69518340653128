import SpotRate from "@/pages/customer/rate/spot/Spot.vue";

const spotRateRoutes = [
  {
    path: "spot",
    name: "app-customer-rate-spot",
    component: SpotRate,
  },
]

export default spotRateRoutes