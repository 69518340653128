import { vehicle } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
    getVehicleList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(vehicle.vehicleBase, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getVehicleSelectList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${vehicle.vehicleBase}select/`, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    getVehicleOptions(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .options(vehicle.vehicleBase, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    getVehicleDetails(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${vehicle.vehicleBase}${id}/view/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    getVehicleObject(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${vehicle.vehicleBase}${id}/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    addVehicle(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(vehicle.vehicleBase, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    updateVehicle(payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(`${vehicle.vehicleBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    partiallyUpdateVehicle(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${vehicle.vehicleBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    getVehicleDocumentOptions() {
        return new Promise((resolve, reject) => {
            axios
                .options(`${vehicle.vehicleBase}`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    uploadVehicleDocument(payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${vehicle.vehicleBase}${payload.id}/upload/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    deleteVehicleDocument(id) {
        return new Promise((resolve, reject) => {
            axios
                .delete(`${vehicle.vehicleDocBase}${id}/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
});