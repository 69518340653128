<template>
  <v-dialog max-width="65%" v-model="showMaintenanceForm">
    <v-card>
      <v-card-title class="secondary white--text py-2 px-4">
        <span v-if="editMode" class="text-capitalize">
          Edit {{ module }} Maintenance
        </span>

        <span v-else class="text-capitalize">Add {{ module }} Maintenance</span>

        <v-spacer></v-spacer>

        <v-btn
          icon
          @click="resetForm(), $emit('closeMaintenanceForm')"
          name="vehicle-maintenance-close"
        >
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-2">
        <v-form
          ref="maintenanceForm"
          v-if="showMaintenanceForm"
          v-model="isValid"
        >
          <v-row v-if="formErrors.non_field_errors" class="mt-0">
            <v-col cols="12">
              <v-alert close-icon="mdi-close" dense type="error">
                <v-list
                  class="pa-0"
                  dense
                  style="background: inherit !important"
                  v-for="(error, i) in formErrors.non_field_errors"
                  :key="i"
                >
                  <v-list-item dense style="min-height: 20px !important">
                    <span>{{ i + 1 }} .</span><span>{{ error }}</span>
                  </v-list-item>
                </v-list>
              </v-alert>
            </v-col>
          </v-row>

          <v-row class="my-0">
            <v-col cols="12" lg="6" class="Form-Columns">
              <MultiSelect
                label="Maintenance Type *"
                name="vehicle-maintenance-type"
                :items="allMaintenanceType"
                :rules="[(val) => !!val || 'Maintenance Type is Required!']"
                hide-details="auto"
                v-model="maintenanceDetails.maintenance_type"
                :error-messages="formErrors.maintenance_type"
                @input="delete formErrors.maintenance_type"
              />
            </v-col>

            <v-col cols="12" lg="6" class="Form-Columns">
              <MultiSelect
                label="Priority *"
                name="vehicle-maintenance-priority"
                :items="allPriority"
                :rules="[(val) => !!val || 'Priority is Required!']"
                hide-details="auto"
                v-model="maintenanceDetails.priority"
                :error-messages="formErrors.priority"
                @input="delete formErrors.priority"
              />
            </v-col>

            <v-col cols="12" lg="6" class="Form-Columns">
              <DateField
                :inputProps="{
                  dense: true,
                  label: 'Start Date *',
                  name: 'vehicle-maintenance-start-date',
                  clearable: true,
                  rules: [(val) => !!val || 'Start Date is Required!'],
                  'hide-details': 'auto',
                  'error-messages': formErrors.start_date,
                }"
                required
                :min="today"
                v-model="maintenanceDetails.start_date"
                @input="delete formErrors.start_date"
              />
            </v-col>

            <v-col cols="12" lg="6" class="Form-Columns">
              <DateField
                :inputProps="{
                  dense: true,
                  label: 'End Date *',
                  name: 'vehicle-maintenance-end-date',
                  clearable: true,
                  rules: [(val) => !!val || 'End Date is Required!'],
                  'hide-details': 'auto',
                  'error-messages': formErrors.end_date,
                }"
                required
                :min="maintenanceDetails.start_date"
                v-model="maintenanceDetails.end_date"
                @input="delete formErrors.end_date"
              />
            </v-col>

            <v-col cols="12" class="Form-Columns">
              <TextareaField
                label="Description *"
                name="vehicle-maintenance-description"
                required
                :rules="[(val) => !!val || 'Description is Required!']"
                hide-details="auto"
                v-model="maintenanceDetails.description"
                :error-messages="formErrors.description"
                @input="delete formErrors.description"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn
          v-if="!editMode"
          name="vehicle-maintenance-reset"
          class="mr-2"
          color="secondary"
          depressed
          @click="resetForm"
        >
          Reset
        </v-btn>
        <v-btn
          color="primary"
          name="vehicle-maintenance-submit"
          depressed
          :disabled="!isValid"
          @click="submitForm"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DateField from "@/components/FormBaseComponents/DateField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import TextareaField from "@/components/FormBaseComponents/TextareaField.vue";

export default {
  components: {
    DateField,
    MultiSelect,
    TextareaField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    module: {
      type: String,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    asset: {
      type: Object,
      default: () => {},
    },
    maintenanceId: {
      type: Number,
    },
  },
  computed: {
    showMaintenanceForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    return {
      maintenanceDetails: {
        asset_type: this.module,
      },
      formErrors: {},
      allMaintenanceType: [],
      allPriority: [],
      isValid: true,
      isTextInput: false,

      today: new Date().toISOString().slice(0, 10),
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.formErrors = {};
        if (this.editMode && this.maintenanceId != null) {
          this.getMaintenanceDetails(this.maintenanceId);
        }
        this.getMaintenanceOptions();
      }
    },
  },
  methods: {
    getMaintenanceDetails(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.maintenance
        .getMaintenanceObject(id)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.maintenanceDetails = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    submitForm() {
      this.$bus.$emit("showLoader", true);
      let payload;
      if (!this.editMode) {
        payload = {
          ...this.maintenanceDetails,
        };
        if (this.module == "Vehicle") {
          payload.vehicle = this.asset.id;
        } else {
          payload.trailer = this.asset.id;
        }
        this.$api.maintenance
          .addMaintenance(payload)
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Maintenance created successfully!",
              color: "success",
            });
            this.$emit("refreshList");
            this.resetForm();
            this.showMaintenanceForm = false;
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't create maintenance!",
              color: "error",
            });
            this.formErrors = err.data;
          });
      } else {
        payload = {
          id: this.maintenanceDetails.id,
          data: this.maintenanceDetails,
        };
        this.$api.maintenance
          .updateMaintenance(payload)
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Maintenance updated successfully!",
              color: "success",
            });
            this.$emit("refreshList");
            this.resetForm();
            this.showMaintenanceForm = false;
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't update maintenance!",
              color: "error",
            });
            console.error(err);
            this.formErrors = err.data;
          });
      }
    },
    resetForm() {
      if (this.$refs.maintenanceForm) {
        this.$refs.maintenanceForm.reset();
      }
      this.maintenanceDetails = {
        asset_type: this.module,
      };
    },
    getMaintenanceOptions() {
      this.$api.maintenance
        .getMaintenanceOptions()
        .then((res) => {
          this.allMaintenanceType =
            res.data.actions.POST.maintenance_type.choices.map(
              (obj) => obj.value
            );
          this.allPriority = res.data.actions.POST.priority.choices.map(
            (obj) => obj.value
          );
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>
