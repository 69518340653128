<template>
  <v-dialog width="500" v-model="showChangePasswordDialog">
    <v-card>
      <v-card-title class="secondary white--text">
        <span>Change Password</span>

        <v-spacer></v-spacer>

        <v-btn icon @click="showChangePasswordDialog = false">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="mt-2">
        <v-form
          ref="changePasswordForm"
          v-if="showChangePasswordDialog"
          v-model="isValid"
        >
          <v-row class="my-0">
            <v-col cols="12" class="Form-Columns">
              <InputField
                :type="isOldPasswordTextInput ? 'text' : 'password'"
                :rules="[(val) => !!val || 'Old Password is required!']"
                label="Old Password *"
                :append-icon="
                  !isOldPasswordTextInput ? 'mdi-eye-off' : 'mdi-eye'
                "
                required
                hide-details="auto"
                v-model="formDetails.old_password"
                @click:append="isOldPasswordTextInput = !isOldPasswordTextInput"
                :error-messages="formErrors.old_password"
                @input="delete formErrors.old_password"
              />
            </v-col>
            <v-col cols="12" class="Form-Columns">
              <InputField
                :type="isNewPasswordTextInput ? 'text' : 'password'"
                :rules="[(val) => !!val || 'New Password is required!']"
                label="New Password *"
                :append-icon="
                  !isNewPasswordTextInput ? 'mdi-eye-off' : 'mdi-eye'
                "
                required
                hide-details="auto"
                v-model="formDetails.new_password"
                @click:append="isNewPasswordTextInput = !isNewPasswordTextInput"
                :error-messages="formErrors.new_password"
                @input="delete formErrors.new_password"
              />
            </v-col>
            <v-col cols="12" class="Form-Columns">
              <InputField
                :type="isConfirmNewPasswordTextInput ? 'text' : 'password'"
                label="Conifrm New Password *"
                :append-icon="
                  !isConfirmNewPasswordTextInput ? 'mdi-eye-off' : 'mdi-eye'
                "
                :rules="[
                  (val) => !!val || 'Confirm New Password is required!',
                  (val) =>
                    (val && val == formDetails.new_password) ||
                    'New Password and Confirm new password is not matching!',
                ]"
                required
                hide-details="auto"
                v-model="formDetails.confirm_new_password"
                @click:append="
                  isConfirmNewPasswordTextInput = !isConfirmNewPasswordTextInput
                "
                :error-messages="formErrors.confirm_new_password"
                @input="delete formErrors.confirm_new_password"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          depressed
          @click="submitForm"
          :disabled="!isValid"
        >
          Save
        </v-btn>

        <v-btn
          class="ml-3"
          color="secondary"
          depressed
          @click="
            showChangePasswordDialog = false;
            resetForm();
          "
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import { encryptText } from "@/utils/functions";

export default {
  components: {
    InputField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(val) {
      if (val) {
      }
    },
  },
  computed: {
    showChangePasswordDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    context() {
      return { parentComponent: this };
    },
  },
  data() {
    return {
      formDetails: {},
      formErrors: {},
      isValid: true,
      isOldPasswordTextInput: false,
      isNewPasswordTextInput: false,
      isConfirmNewPasswordTextInput: false,
    };
  },
  methods: {
    submitForm(payload = {}) {
      this.$bus.$emit("showLoader", true);
      this.formErrors = {};
      payload = {
        ...this.formDetails,
      };
      if (this.formDetails && this.formDetails.old_password) {
        payload.old_password = encryptText(this.formDetails.old_password);
      }
      if (this.formDetails && this.formDetails.new_password) {
        payload.new_password = encryptText(this.formDetails.new_password);
      }
      if (payload && payload.confirm_new_password) {
        delete payload.confirm_new_password;
      }

      this.$api.user
        .changePassword(payload)
        .then(() => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Password changed successfully!",
            color: "success",
          });
          this.resetForm();
          this.showChangePasswordDialog = false;
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't change password!",
            color: "error",
          });
          this.formErrors = err.data;
          if (err.data) {
            this.formDetails = {};
            this.resetFormValidation();
          } else {
            this.resetFormValidation();
          }
        });
    },
    resetForm() {
      this.formDetails = {};
      this.formErrors = {};
      this.resetFormValidation();
    },
    resetFormValidation() {
      let form = this.$refs.changePasswordForm;
      if (form) {
        form.resetValidation();
      }
    },
  },
};
</script>
