import Vue from 'vue'

function openConfirmationDialog(config) {
  Vue.prototype.$bus.$emit("showConfirmationDialog", config)
  return new Promise((resolve, reject) => {
    Vue.prototype.$bus.$on("confirmed", resolve)
    Vue.prototype.$bus.$on("rejected", reject)
  })
}

export default openConfirmationDialog;