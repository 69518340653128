<template>
  <v-app>
    <router-view />
    <CommonSnackBar
      v-model="showToastMessage"
      :color="snackBarColor"
      :timeout="timeOut"
      :textMessage="snackbarMessage"
      :position="position"
    />
    <CommonLoader :loadingMessage="loaderMessage" :showOverlay="showLoader" />
    <ConfirmationDialogue
      v-model="showConfirmationDialogue"
      :status="status"
      :title="title"
      :text="text"
    />
  </v-app>
</template>

<script>
import CommonSnackBar from "@/components/BaseComponents/SnackBar.vue";
import CommonLoader from "@/components/BaseComponents/Loader.vue";
import ConfirmationDialogue from "@/components/General/ConfirmationDialogue.vue";

export default {
  components: {
    CommonSnackBar,
    CommonLoader,
    ConfirmationDialogue,
  },
  data: () => ({
    showToastMessage: false,
    snackBarColor: "primary",
    snackbarMessage: "",
    timeOut: 1500,
    showLoader: false,
    loaderMessage: "Loading..",
    position: null,

    // Confirmation
    showConfirmationDialogue: false,
    status: "secondery",
    title: "Confirmation",
    text: "Are you sure?",
  }),
  created() {
    this.$bus.$on("showConfirmationDialog", (config) => {
      if (config) {
        this.status = config.status;
        this.title = config.title;
        this.text = config.text;
      }
      this.showConfirmationDialogue = true;
    });
    this.$bus.$on(
      "showToastMessage",
      ({ color, message, timeout, position }) => {
        this.timeOut = timeout;
        this.position = position;
        this.snackBarColor = color;
        this.snackbarMessage = message;
        this.showToastMessage = true;
      }
    );
    this.$bus.$on("showLoader", (value, message) => {
      this.loaderMessage = message;
      this.showLoader = value;
    });
  },
  beforeDestroy() {
    this.$bus.$off("showToastMessage");
    this.$bus.$off("showLoader");
    this.$bus.$off("showConfirmationDialog");
  },
};
</script>

<style lang="scss">
@import "./assets/scss/base.scss";
</style>
