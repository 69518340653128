<template>
  <v-card>
    <v-card-title class="heading mb-4">
      <span class="text-h5 font-weight-medium">City List</span>
    </v-card-title>

    <v-card-subtitle class="subHeading py-2">
      <div class="d-flex justify-space-between">
        <div v-if="Object.keys(countryObj).length">
          <span
            v-if="countryObj.country_code"
            class="text-subtitle-1 font-weight-medium"
          >
            Country Code : {{ countryObj.country_code }}
          </span>
        </div>

        <v-btn
          depressed
          color="primary"
          small
          @click="addCity"
          v-show="permissions && permissions.add"
        >
          <v-icon class="mr-2">mdi-plus</v-icon>
          <span>City</span>
        </v-btn>
      </div>
    </v-card-subtitle>

    <v-card-text class="mt-4">
      <AgGridVue
        style="width: 100%; height: calc(100vh - 240px)"
        :class="
          $vuetify.theme.dark ? 'ag-theme-alpine-dark' : 'ag-theme-alpine'
        "
        id="myGrid"
        :columnDefs="headers"
        :context="context"
        @grid-ready="onGridReady"
        :defaultColDef="defaultColDef"
        :grid-options="gridOptions"
        :suppressDragLeaveHidesColumns="true"
        :rowData="allCityList"
      ></AgGridVue>
    </v-card-text>

    <CityForm
      v-model="showCityForm"
      :editMode="isEditing"
      :editCityID="editCityID"
      :countryId="countryObj.id"
      @refreshList="itemsPerPageChanged(10)"
    />
  </v-card>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";
import CityButton from "@/components/AgGridComponents/CityButton.vue";
import CityForm from "../dialogForms/CityForm.vue";

export default {
  components: {
    AgGridVue,
    Pagination,
    CityButton,
    CityForm,
  },
  props: {
    countryObj: {
      type: Object,
      default: () => ({}),
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      // BMR list vars
      allCityList: [],
      headers: [
        {
          headerName: "City Name",
          field: "city_name",
          tooltipField: "city_name",
        },
        {
          headerName: "City Code",
          field: "city_code",
          width: 60,
        },

        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: "CityButton",
          pinned: "right",
          width: 50,
        },
      ],

      // bmr edit vars

      // form Vars
      showCityForm: false,
      isEditing: false,
      editCityID: null,

      // pagination vars
      itemsPerPage: 10,
      pageNo: 1,
      totalItems: 0,
      filters: {},

      // ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.gridOptions.api.sizeColumnsToFit();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },
  watch: {
    countryObj: {
      handler() {
        this.getCityList();
      },
    },
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    searchCity() {
      this.pageNo = 1;
      this.getCityList();
    },
    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getCityList();
    },
    prevPage() {
      this.pageNo--;
      this.getCityList();
    },
    nextPage() {
      this.pageNo++;
      this.getCityList();
    },
    getCityList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        country: this.countryObj.id,
        offset: this.offset,
        limit: this.itemsPerPage,
        ...params,
        ...this.filters,
      };
      this.$api.city
        .getCityList(params)
        .then((res) => {
          this.allCityList = res.data;
          this.totalItems = res.count;

          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    addCity() {
      this.editCityID = null;
      this.isEditing = false;
      this.showCityForm = true;
    },
    editCity(id) {
      this.editCityID = id;
      this.isEditing = true;
      this.showCityForm = true;
    },
  },
  mounted() {
    this.getCityList(this.countryObj.id);
  },
};
</script>

<style></style>
