<template>
  <FormComponent
    title="Create New Order"
    :backRoute="backRoute"
    :nonFieldErrors="formErrors.non_field_errors"
  >
    <template #formActions>
      <v-btn color="primary" depressed :disabled="!isValid" @click="submitForm">
        Submit
      </v-btn>
    </template>

    <template #formContent>
      <v-form v-model="isValid" ref="customerOrderForm">
        <v-row class="mx-2 my-0">
          <v-col cols="12" lg="6" class="Form-Columns-Dense">
            <span class="text-h6 font-weight-regular">Basic Details</span>
          </v-col>
          <v-col cols="6" class="Form-Columns-Dense text-right">
            <v-btn
              small
              @click="showCustomerAddressForm = true"
              color="primary"
              depressed
            >
              Add Address
            </v-btn>
          </v-col>
          <v-col cols="12" lg="3" class="Form-Columns-Dense">
            <InputField
              type="text"
              label="BOE/Reference Number *"
              :rules="[(val) => !!val || 'BOE Number is Required!']"
              required
              hide-details="auto"
              v-model="orderDetails.boe"
              :error-messages="formErrors.boe"
              @input="delete formErrors.boe"
            />
          </v-col>

          <v-col cols="12" lg="3" class="Form-Columns-Dense">
            <MultiSelect
              hide-details="auto"
              label="Job Type *"
              :items="allJobType"
              itemText="display_name"
              itemValue="value"
              v-model="orderDetails.job_type"
              :rules="[(v) => !!v || 'Job Type is Required']"
              :error-messages="formErrors.job_type"
              @change="
                delete formErrors.job_type;
                jobTypeChanged();
              "
            />
          </v-col>
          <v-col cols="12" lg="3" class="Form-Columns-Dense">
            <MultiSelect
              hide-details="auto"
              :items="paymentTerms"
              v-model="orderDetails.payment_term"
              :rules="[(val) => !!val || 'Payment Terms is Required!']"
              label="Payment Terms *"
              :readonly="currentProfile.company_payment_term == 'Cash'"
              required
              :error-messages="formErrors.payment_term"
              @input="delete formErrors.payment_term"
            ></MultiSelect>
          </v-col>
          <v-col cols="12" lg="3" class="Form-Columns-Dense">
            <v-checkbox
              v-model="sameAddressForAll"
              label="Same address for All"
              color="black"
              hide-details="auto"
              class="ma-1"
              @change="jobTypeChanged()"
            ></v-checkbox>
          </v-col>

          <v-col cols="12" v-if="sameAddressForAll">
            <v-row>
              <v-col cols="12" lg="10" class="Form-Columns-Dense">
                <MultiSelect
                  label="Source -> Mid Point -> Destination *"
                  :items="allLaneList"
                  itemText="title"
                  itemValue="id"
                  hide-details="auto"
                  :returnObject="true"
                  :rules="[(v) => !!v || 'Location is Required']"
                  v-model="addressDetails.lane"
                  :error-messages="formErrors.lane"
                  @change="
                    delete formErrors.lane;
                    addressChanged();
                  "
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="12"
            lg="4"
            v-if="
              sameAddressForAll &&
              displayDetails &&
              displayDetails &&
              displayDetails[0].lane &&
              displayDetails[0].lane.need_addresses_for &&
              displayDetails[0].lane.need_addresses_for.source
            "
            class="Form-Columns-Dense"
          >
            <div class="d-flex">
              <MultiSelect
                hide-details="auto"
                :ref="`assigned_source_address`"
                label="Source Address *"
                :items="allCustomerAddressList"
                itemText="name"
                itemValue="id"
                :returnObject="true"
                :rules="[(v) => !!v || 'Source Address is Required']"
                v-model="addressDetails.assigned_source_address"
                @change="addressChanged()"
              />

              <div
                v-if="addressDetails.assigned_source_address"
                class="align-self-center"
              >
                <ViewInformationOnHover cardTitle="Source Address Information">
                  <template #information>
                    <table class="Normal-Table">
                      <tbody>
                        <tr
                          v-if="addressDetails.assigned_source_address.address"
                        >
                          <th>Address</th>
                          <th class="px-2">:</th>
                          <td>
                            {{ addressDetails.assigned_source_address.address }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </template>
                </ViewInformationOnHover>
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            lg="4"
            v-if="
              sameAddressForAll &&
              displayDetails &&
              displayDetails &&
              displayDetails[0].lane &&
              displayDetails[0].lane.need_addresses_for &&
              displayDetails[0].lane.need_addresses_for.mid
            "
            class="Form-Columns-Dense"
          >
            <div class="d-flex">
              <MultiSelect
                hide-details="auto"
                label="Mid Point Address *"
                :items="allCustomerAddressList"
                itemText="name"
                itemValue="id"
                :returnObject="true"
                :rules="[(v) => !!v || 'Mid Point Address is Required']"
                v-model="addressDetails.assigned_mid_address"
                @change="addressChanged()"
              />

              <div
                v-if="addressDetails.assigned_mid_address"
                class="align-self-center"
              >
                <ViewInformationOnHover
                  cardTitle="Mid Point Address Information"
                >
                  <template #information>
                    <table class="Normal-Table">
                      <tbody>
                        <tr v-if="addressDetails.assigned_mid_address.address">
                          <th>Address</th>
                          <th class="px-2">:</th>
                          <td>
                            {{ addressDetails.assigned_mid_address.address }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </template>
                </ViewInformationOnHover>
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            lg="4"
            v-if="
              sameAddressForAll &&
              displayDetails &&
              displayDetails &&
              displayDetails[0].lane &&
              displayDetails[0].lane.need_addresses_for &&
              displayDetails[0].lane.need_addresses_for.destination
            "
            class="Form-Columns-Dense"
          >
            <div class="d-flex">
              <MultiSelect
                hide-details="auto"
                label="Destination Address *"
                :items="allCustomerAddressList"
                itemText="name"
                itemValue="id"
                :returnObject="true"
                :rules="[(v) => !!v || 'Destination Address is Required']"
                v-model="addressDetails.assigned_destination_address"
                @change="addressChanged()"
              />

              <div
                v-if="addressDetails.assigned_destination_address"
                class="align-self-center"
              >
                <ViewInformationOnHover
                  cardTitle="Destination Address Information"
                >
                  <template #information>
                    <table class="Normal-Table">
                      <tbody>
                        <tr
                          v-if="
                            addressDetails.assigned_destination_address.address
                          "
                        >
                          <th>Address</th>
                          <th class="px-2">:</th>
                          <td>
                            {{
                              addressDetails.assigned_destination_address
                                .address
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </template>
                </ViewInformationOnHover>
              </div>
            </div>
          </v-col>

          <v-col
            cols="12"
            v-for="(con, i) in orderDetails.order_containers"
            :key="i"
            class="Form-Columns-Dense"
          >
            <v-card elevation="1" outlined>
              <v-card-title class="heading py-2">
                <span class="text-h6"> Container Details {{ i + 1 }} </span>

                <v-spacer></v-spacer>

                <v-btn
                  color="primary"
                  depressed
                  @click="addContainerType()"
                  class="mr-2"
                  small
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>

                <v-btn
                  color="secondary"
                  depressed
                  :disabled="i == 0"
                  @click="removeContainerType(i)"
                  small
                >
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
              </v-card-title>

              <v-card-text class="pa-2">
                <v-row class="ma-0">
                  <v-col cols="12" v-if="!sameAddressForAll">
                    <v-row>
                      <v-col cols="12" lg="10" class="Form-Columns-Dense">
                        <MultiSelect
                          hide-details="auto"
                          label="Source -> Mid Point -> Destination *"
                          :items="allLaneList"
                          itemText="title"
                          itemValue="id"
                          :returnObject="true"
                          :rules="[(v) => !!v || 'Location is Required']"
                          v-model="con.lane"
                          :error-messages="
                            formErrors &&
                            formErrors.order_containers &&
                            formErrors.order_containers[i] &&
                            formErrors.order_containers[i].lane
                              ? formErrors.order_containers[i].lane
                              : []
                          "
                          @change="
                            formErrors &&
                            formErrors.order_containers &&
                            formErrors.order_containers[i] &&
                            formErrors.order_containers[i].lane
                              ? delete formErrors.order_containers[i].lane
                              : null;
                            checkLocationData($event, 'lane', i);
                          "
                        />
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" lg="2" class="Form-Columns-Dense">
                    <InputField
                      hide-details="auto"
                      type="text"
                      placeholder="ABCD1234567"
                      persistent-hint
                      :hint="formFieldHints[i].container_no"
                      label="Container Number *"
                      :rules="[(v) => !!v || 'Container Number is Required']"
                      v-model="con.container_no"
                      :error-messages="
                        formErrors &&
                        formErrors.order_containers &&
                        formErrors.order_containers[i] &&
                        formErrors.order_containers[i].container_no
                          ? formErrors.order_containers[i].container_no
                          : []
                      "
                      @input="
                        formErrors &&
                        formErrors.order_containers &&
                        formErrors.order_containers[i] &&
                        formErrors.order_containers[i].container_no
                          ? delete formErrors.order_containers[i].container_no
                          : null
                      "
                    />
                  </v-col>

                  <v-col cols="12" lg="4" class="Form-Columns-Dense">
                    <MultiSelect
                      hide-details="auto"
                      label="Container Type *"
                      :items="allContainersType"
                      itemText="title"
                      itemValue="id"
                      :rules="[(v) => !!v || 'Container Type is Required']"
                      v-model="con.container_type"
                      :error-messages="
                        formErrors &&
                        formErrors.order_containers &&
                        formErrors.order_containers[i] &&
                        formErrors.order_containers[i].container_type
                          ? formErrors.order_containers[i].container_type
                          : []
                      "
                      @change="
                        formErrors &&
                        formErrors.order_containers &&
                        formErrors.order_containers[i] &&
                        formErrors.order_containers[i].container_type
                          ? delete formErrors.order_containers[i].container_type
                          : null
                      "
                    />
                  </v-col>

                  <v-col cols="12" lg="2" class="Form-Columns-Dense">
                    <InputField
                      hide-details="auto"
                      type="number"
                      label="Container Weight (Tons) *"
                      :rules="[
                        (v) => !!v || 'Container Weight is Required',
                        (v) =>
                          v > 0 || 'Container Weight can not be less than 0',
                        (v) =>
                          $globals.containerWeightFormatRegExp.test(v) ||
                          'Container Weight must have the format 00.00',
                      ]"
                      v-model="con.weight"
                      step=".01"
                      :error-messages="
                        formErrors &&
                        formErrors.order_containers &&
                        formErrors.order_containers[i] &&
                        formErrors.order_containers[i].weight
                          ? formErrors.order_containers[i].weight
                          : []
                      "
                      @input="
                        formErrors &&
                        formErrors.order_containers &&
                        formErrors.order_containers[i] &&
                        formErrors.order_containers[i].weight
                          ? delete formErrors.order_containers[i].weight
                          : null
                      "
                    />
                  </v-col>

                  <v-col cols="12" lg="2" class="Form-Columns-Dense">
                    <DateField
                      :inputProps="{
                        dense: true,
                        label: 'Movement Date *',
                        rules: [(val) => !!val || 'Movement Date is Required!'],
                        'hide-details': 'auto',
                        'error-messages':
                          formErrors &&
                          formErrors.order_containers &&
                          formErrors.order_containers[i] &&
                          formErrors.order_containers[i].movement_date
                            ? formErrors.order_containers[i].movement_date
                            : [],
                      }"
                      required
                      :min="today"
                      v-model="con.movement_date"
                      @input="
                        formErrors &&
                        formErrors.order_containers &&
                        formErrors.order_containers[i] &&
                        formErrors.order_containers[i].movement_date
                          ? delete formErrors.order_containers[i].movement_date
                          : null
                      "
                    />
                  </v-col>

                  <v-col cols="12" lg="2" class="Form-Columns-Dense">
                    <InputField
                      hide-details="auto"
                      type="time"
                      label="Movement Time"
                      required
                      v-model="con.movement_time"
                      :error-messages="
                        formErrors &&
                        formErrors.order_containers &&
                        formErrors.order_containers[i] &&
                        formErrors.order_containers[i].movement_time
                          ? formErrors.order_containers[i].movement_time
                          : []
                      "
                      @input="
                        formErrors &&
                        formErrors.order_containers &&
                        formErrors.order_containers[i] &&
                        formErrors.order_containers[i].movement_time
                          ? delete formErrors.order_containers[i].movement_time
                          : null
                      "
                    />
                  </v-col>
                </v-row>
                <v-row class="ma-0" v-if="!sameAddressForAll">
                  <v-col
                    cols="12"
                    lg="4"
                    v-if="
                      displayDetails &&
                      displayDetails[i] &&
                      displayDetails[i].lane &&
                      displayDetails[i].lane.need_addresses_for &&
                      displayDetails[i].lane.need_addresses_for.source
                    "
                    class="Form-Columns-Dense"
                  >
                    <div class="d-flex">
                      <MultiSelect
                        hide-details="auto"
                        :ref="`assigned_source_address_${i}`"
                        label="Source Address *"
                        :items="allCustomerAddressList"
                        itemText="name"
                        itemValue="id"
                        :returnObject="true"
                        :rules="[(v) => !!v || 'Source Address is Required']"
                        v-model="con.assigned_source_address"
                        :error-messages="
                          formErrors &&
                          formErrors.order_containers &&
                          formErrors.order_containers[i] &&
                          formErrors.order_containers[i].assigned_source_address
                            ? formErrors.order_containers[i]
                                .assigned_source_address
                            : []
                        "
                        @change="
                          formErrors &&
                          formErrors.order_containers &&
                          formErrors.order_containers[i] &&
                          formErrors.order_containers[i].assigned_source_address
                            ? delete formErrors.order_containers[i]
                                .assigned_source_address
                            : null;
                          checkLocationData(
                            $event,
                            'assigned_source_address',
                            i
                          );
                        "
                      />

                      <div
                        v-if="displayDetails[i].assigned_source_address"
                        class="align-self-center"
                      >
                        <ViewInformationOnHover
                          cardTitle="Source Address Information"
                        >
                          <template #information>
                            <table class="Normal-Table">
                              <tbody>
                                <tr
                                  v-if="
                                    displayDetails[i].assigned_source_address
                                      .address
                                  "
                                >
                                  <th>Address</th>
                                  <th class="px-2">:</th>
                                  <td>
                                    {{
                                      displayDetails[i].assigned_source_address
                                        .address
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </template>
                        </ViewInformationOnHover>
                      </div>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    lg="4"
                    v-if="
                      displayDetails &&
                      displayDetails[i] &&
                      displayDetails[i].lane &&
                      displayDetails[i].lane.need_addresses_for &&
                      displayDetails[i].lane.need_addresses_for.mid
                    "
                    class="Form-Columns-Dense"
                  >
                    <div class="d-flex">
                      <MultiSelect
                        hide-details="auto"
                        label="Mid Point Address *"
                        :items="allCustomerAddressList"
                        itemText="name"
                        itemValue="id"
                        :returnObject="true"
                        :rules="[(v) => !!v || 'Mid Point Address is Required']"
                        v-model="con.assigned_mid_address"
                        :error-messages="
                          formErrors &&
                          formErrors.order_containers &&
                          formErrors.order_containers[i] &&
                          formErrors.order_containers[i].assigned_mid_address
                            ? formErrors.order_containers[i]
                                .assigned_mid_address
                            : []
                        "
                        @change="
                          formErrors &&
                          formErrors.order_containers &&
                          formErrors.order_containers[i] &&
                          formErrors.order_containers[i].assigned_mid_address
                            ? delete formErrors.order_containers[i]
                                .assigned_mid_address
                            : null;
                          checkLocationData($event, 'assigned_mid_address', i);
                        "
                      />

                      <div
                        v-if="displayDetails[i].assigned_mid_address"
                        class="align-self-center"
                      >
                        <ViewInformationOnHover
                          cardTitle="Mid Point Address Information"
                        >
                          <template #information>
                            <table class="Normal-Table">
                              <tbody>
                                <tr
                                  v-if="
                                    displayDetails[i].assigned_mid_address
                                      .address
                                  "
                                >
                                  <th>Address</th>
                                  <th class="px-2">:</th>
                                  <td>
                                    {{
                                      displayDetails[i].assigned_mid_address
                                        .address
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </template>
                        </ViewInformationOnHover>
                      </div>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    lg="4"
                    v-if="
                      displayDetails &&
                      displayDetails[i] &&
                      displayDetails[i].lane &&
                      displayDetails[i].lane.need_addresses_for &&
                      displayDetails[i].lane.need_addresses_for.destination
                    "
                    class="Form-Columns-Dense"
                  >
                    <div class="d-flex">
                      <MultiSelect
                        hide-details="auto"
                        label="Destination Address *"
                        :items="allCustomerAddressList"
                        itemText="name"
                        itemValue="id"
                        :returnObject="true"
                        :rules="[
                          (v) => !!v || 'Destination Address is Required',
                        ]"
                        v-model="con.assigned_destination_address"
                        :error-messages="
                          formErrors &&
                          formErrors.order_containers &&
                          formErrors.order_containers[i] &&
                          formErrors.order_containers[i]
                            .assigned_destination_address
                            ? formErrors.order_containers[i]
                                .assigned_destination_address
                            : []
                        "
                        @change="
                          formErrors &&
                          formErrors.order_containers &&
                          formErrors.order_containers[i] &&
                          formErrors.order_containers[i]
                            .assigned_destination_address
                            ? delete formErrors.order_containers[i]
                                .assigned_destination_address
                            : null;
                          checkLocationData(
                            $event,
                            'assigned_destination_address',
                            i
                          );
                        "
                      />

                      <div
                        v-if="displayDetails[i].assigned_destination_address"
                        class="align-self-center"
                      >
                        <ViewInformationOnHover
                          cardTitle="Destination Address Information"
                        >
                          <template #information>
                            <table class="Normal-Table">
                              <tbody>
                                <tr
                                  v-if="
                                    displayDetails[i]
                                      .assigned_destination_address.address
                                  "
                                >
                                  <th>Address</th>
                                  <th class="px-2">:</th>
                                  <td>
                                    {{
                                      displayDetails[i]
                                        .assigned_destination_address.address
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </template>
                        </ViewInformationOnHover>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-dialog v-model="contractDetailsDialog" width="500">
            <v-card v-if="Object.keys(selectedContractDetails).length > 0">
              <v-card-title class="secondary white--text px-4 py-2">
                Tariff Id -
                {{ selectedContractDetails.contract_id }}

                <v-spacer></v-spacer>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="white"
                      icon
                      @click="contractDetailsDialog = false"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <span>Close</span>
                </v-tooltip>
              </v-card-title>

              <v-card-text>
                <table class="Normal-Table my-2">
                  <tbody>
                    <tr>
                      <td>Customer Name</td>
                      <th class="px-2">:</th>
                      <th>
                        {{ selectedContractDetails.customer_name }}
                      </th>
                    </tr>

                    <tr v-if="selectedContractDetails.source_name">
                      <td>Source</td>
                      <th class="px-2">:</th>
                      <th>
                        {{ selectedContractDetails.source_name }}
                      </th>
                    </tr>

                    <tr v-if="selectedContractDetails.mid_point_name">
                      <td>Mid Point</td>
                      <th class="px-2">:</th>
                      <th>
                        {{ selectedContractDetails.mid_point_name }}
                      </th>
                    </tr>

                    <tr v-if="selectedContractDetails.destination_name">
                      <td>Destination</td>
                      <th class="px-2">:</th>
                      <th>
                        {{ selectedContractDetails.destination_name }}
                      </th>
                    </tr>

                    <tr>
                      <td>Job Type</td>
                      <th class="px-2">:</th>
                      <th>
                        {{ selectedContractDetails.job_type }}
                      </th>
                    </tr>

                    <tr>
                      <td>Start Date</td>
                      <th class="px-2">:</th>
                      <th>
                        {{ selectedContractDetails.start_date | formatDate }}
                      </th>
                    </tr>

                    <tr>
                      <td>End Date</td>
                      <th class="px-2">:</th>
                      <th>
                        {{ selectedContractDetails.end_date | formatDate }}
                      </th>
                    </tr>
                  </tbody>
                </table>

                <v-divider></v-divider>

                <table class="Full-Width">
                  <tbody>
                    <tr>
                      <td>
                        <div class="my-2">
                          <span>Remarks</span>
                          <p class="font-weight-bold mb-0">
                            {{ selectedContractDetails.admin_remarks }}
                          </p>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <v-divider></v-divider>
                        <table class="Full-Width text-left mt-2">
                          <thead>
                            <tr>
                              <th>Container Type</th>
                              <th class="text-right">Container Count</th>
                              <th class="text-right">
                                Container Rate ({{ $globals.currency }})
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(container, i) in selectedContractDetails[
                                'container_types'
                              ]"
                              :key="i"
                            >
                              <td>{{ container.container_type_title }}</td>
                              <td class="text-right">
                                {{ container.container_count }}
                              </td>
                              <td class="text-right">{{ container.rate }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-row>
      </v-form>
      <CustomerAddressForm
        v-model="showCustomerAddressForm"
        :customerId="currentProfile ? currentProfile.company : 0"
        module="Customer"
        @closeAddressForm="showCustomerAddressForm = false"
        @refreshList="getCustomerAddressList"
      />
    </template>
  </FormComponent>
</template>

<script>
import FormComponent from "@/components/General/FormComponent.vue";
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import Tooltip from "@/components/General/Tooltip.vue";
import CustomerAddressForm from "@/components/ModuleBased/dialogForms/CustomerAddressForm.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";
import ViewInformationOnHover from "@/components/General/ViewInformationOnHover.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    FormComponent,
    InputField,
    MultiSelect,
    DateField,
    Tooltip,
    CustomerAddressForm,
    ViewInformationOnHover,
  },

  data() {
    return {
      isValid: true,
      showCustomerAddressForm: false,

      paymentTerms: [],
      allCustomerAddressList: [],
      allCustomerContractList: [],
      allContainersType: [],
      allLaneList: [],
      allJobType: [],
      jobType: null,

      // Customer Address
      displayDetails: [{}],

      orderDetails: {
        order_containers: [{}],
      },

      formFieldHints: [{}],
      formErrors: {
        order_containers: [{}],
      },
      contractDetailsDialog: false,
      selectedContractDetails: {},
      today: new Date().toISOString().slice(0, 10),

      backRoute: { path: "/app/customer/order/list" },

      sameAddressForAll: false,
      addressDetails: {},
    };
  },

  computed: {
    currentProfile() {
      if (JSON.parse(localStorage.getItem("userProfile"))) {
        return JSON.parse(localStorage.getItem("userProfile"));
      } else {
        return null;
      }
    },
  },
  watch: {
    orderDetails: {
      handler(val) {
        if (val.order_containers && val.order_containers.length > 0) {
          val.order_containers.forEach((item, index) => {
            if (
              item.container_no &&
              !this.$globals.containerNumberRegExp.test(item.container_no)
            ) {
              this.formFieldHints[index].container_no = "Invalid Container No!";
            } else {
              delete this.formFieldHints[index].container_no;
            }
          });
        }
      },
      deep: true,
    },
  },

  methods: {
    jobTypeChanged() {
      this.displayDetails = [{}];
      this.orderDetails.order_containers = [{}];
      this.addressDetails = {};
      if (this.$refs.customerOrderForm) {
        this.$refs.customerOrderForm.resetValidation();
      }
    },
    getLaneList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        limit: "all",
      };

      this.$api.lane
        .getLaneList(params)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.allLaneList = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    addressChanged() {
      this.displayDetails.forEach((obj, index) => {
        Object.keys(this.addressDetails).forEach((key) => {
          this.checkLocationData(this.addressDetails[key], key, index);
        });
      });
    },

    checkLocationData(obj, key, i) {
      this.displayDetails[i][key] = obj;
      this.orderDetails.order_containers[i][key] = obj?.id;
    },

    addContainerType() {
      this.orderDetails.order_containers.push({});
      this.displayDetails.push({});
      this.formFieldHints.push({});

      if (this.sameAddressForAll) {
        this.addressChanged();
      }
    },

    removeContainerType(i) {
      this.orderDetails.order_containers.splice(i, 1);
      this.formFieldHints.splice(i, 1);
      this.displayDetails.splice(i, 1);
    },

    submitForm(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        customer: this.currentProfile.company,
        ...this.orderDetails,
      };

      this.$api.customerOrder
        .addCustomerOrder(params)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Order Added Successfully!",
            color: "success",
          });
          this.$router.push("/app/customer/order/list");
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't add Order!",
            color: "error",
          });
          this.formErrors = { ...this.formErrors, ...err.data };
          this.$forceUpdate();
        });
    },

    viewContractDetails(contract) {
      this.selectedContractDetails = contract;
      this.contractDetailsDialog = true;
    },

    getContractList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        status: "Active",
        limit: "all",
        job_type: this.orderDetails.job_type,
        customer: this.currentProfile.company,
        ...params,
      };
      this.$api.customerContract
        .getCustomerContractSelectList(params)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.allCustomerContractList = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getCustomerAddressList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        limit: "all",
        customer: this.currentProfile.company,
        ...params,
      };
      this.$api.customerAddress
        .getCustomerAddressSelectList(params)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.allCustomerAddressList = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    setBackRoute() {
      if ("backRoute" in this.$route.query) {
        this.backRoute.path = this.$route.query.backRoute;
      }
      if ("active_tab" in this.$route.query) {
        if (!this.backRoute.query) {
          this.backRoute.query = {};
        }
        this.backRoute.query.active_tab = this.$route.query.active_tab;
      }
    },
    setJobType() {
      this.getContractList();
    },
    getOrderOptions() {
      this.$bus.$emit("showLoader", true);
      this.$api.customerOrder
        .getOrderOptions()
        .then((res) => {
          this.paymentTerms = res.data.actions.POST.payment_term.choices.map(
            (obj) => {
              return { text: obj.display_name, value: obj.value };
            }
          );
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getContainerTypeList() {
      this.$bus.$emit("showLoader", true);
      this.$api.containerType
        .getContainerTypeList({ limit: "all" })
        .then((res) => {
          this.allContainersType = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getJobTypeOptions() {
      this.$bus.$emit("showLoader", true);
      this.$api.customerOrder
        .getOrderOptions()
        .then((res) => {
          this.allJobType = res.data.actions.POST.job_type.choices;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },

  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("order");
      if (!permissions || !permissions.add) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.permissions = { ...permissions };
        this.getJobTypeOptions();
        this.getLaneList();
        this.getContainerTypeList();
        this.getOrderOptions();
        this.setBackRoute();
        this.getCustomerAddressList();
        if (
          this.currentProfile &&
          this.currentProfile.company_payment_term == "Cash"
        ) {
          this.orderDetails.payment_term = "Cash";
        }
      }
    }
  },
};
</script>

