import { trip, version, version2 } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
    getTripList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(trip.tripBase, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    getLegList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(trip.legList, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    getLegContainerList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(trip.legContainerList, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },


    getTripAssetList(id, params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${trip.tripBase}assets/`, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    getTripMatrix(id, params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${trip.tripBase}${id}/metrics/`, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    getTripOptions(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .options(trip.tripBase, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    getTripDetails(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${trip.tripBase}${id}/view/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    prevNextTrip(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(
                    `${trip.tripBase}${payload.id}/previous_next_trip/`,
                    payload
                )
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    getTripObject(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${trip.tripBase}${id}/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    getTripCheckList(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${trip.tripBase}${id}/checklist/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    getTripDefectsBlob(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${trip.tripBase}${id}/trip_checklist_download/`, {
                    responseType: "blob",
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    getDefectiveTripCheckList(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${trip.tripBase}${id}/defects_trip_checklist/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    updateDefectiveTripCheckList(id, data) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${trip.tripBase}${id}/defects_trip_checklist/`, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    updateTripCheckList(id, data) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${trip.tripBase}${id}/checklist/`, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },



    AddMilestoneToTrip(id, data) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${trip.tripBase}${id}/add_milestone/`, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    TripMilestoneReached(id, data) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${trip.tripBase}${id}/trip_milestone_reached/`, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    addTrip(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(trip.tripBase, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    updateTrip(payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(`${trip.tripBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    updateTripLogs(data) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${trip.tripLogs}${data.data.tripLogId}/`, data.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    changeTripStatus(id, status) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${trip.tripBase}${id}/change_trip_status/`, {
                    status: status
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getTransporterInvoicePreview(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${trip.legList}invoices/`, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    getLegs(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(trip.legBase, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    addLeg(payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${trip.legBase}`, payload)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    deleteLeg(id) {
        return new Promise((resolve, reject) => {
            axios
                .delete(`${trip.legBase}${id}/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    updateLeg(payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(`${trip.legBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    updateContainerSealNo(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${trip.legBase}${payload.id}/update_container_seal_no/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    deAllocateTransporter(id) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${trip.legBase}${id}/de_allocate_transporter/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getGPSOdometerReading(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${trip.tripBase}${id}/gps_odometer_reading/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    updateOdometerReading(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${trip.tripBase}${payload.id}/update_vehicle_odometer_reading/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    updateLegETADateTime(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${trip.legBase}${payload.id}/leg_eta_time/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    deleteLegTrip(id) {
        return new Promise((resolve, reject) => {
            axios
                .delete(`${trip.legBase}${id}/delete_leg_trip/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    updateManualSequence(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${trip.legBase}${payload.id}/leg_manual_sequence/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    partiallyUpdateLeg(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${trip.legBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    bulkUpdateLegMovementDate(payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${version2}bulk_update_leg_movement_date/`, payload)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    partiallyUpdateTrip(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${trip.tripBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    getAllocatedAssets(params) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${version2}asset_allocation/`, {
                    params: params
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    updateAllocatedAssets(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${version2}asset_allocation/`, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    deleteLegPOD(id) {
        return new Promise((resolve, reject) => {
            axios
                .delete(`${trip.legPODBase}${id}/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    uploadLegPOD(payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${trip.legList}${payload.id}/upload/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    uploadTripPOD(payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${trip.tripBase}${payload.id}/upload/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getLegListObject(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${trip.legList}${id}/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getSimillarLegs(params) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${version2}combine_legs/`, {
                    params: params
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    combineLegs(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${version2}combine_legs/`, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    getAttachments(params) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${version2}export_chat_attachment/`, {
                    params: params
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getTripChatDetails(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${trip.tripBase}${id}/trip_chat_detail/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

});