<template>
  <v-dialog width="500" v-model="showEmailSubscriberForm">
    <v-card>
      <v-card-title class="secondary white--text">
        <span> {{ editMode ? "Update" : "Add" }} Email Subscriber </span>

        <v-spacer></v-spacer>

        <v-btn icon @click="resetForm(), (showEmailSubscriberForm = false)">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-2">
        <v-form
          ref="EmailSubscriberForm"
          v-if="showEmailSubscriberForm"
          v-model="isValid"
        >
          <v-row class="my-0">
            <v-col cols="12" class="Form-Columns">
              <MultiSelect
                label="Modules *"
                :items="allModuleList"
                itemText="text"
                itemValue="value"
                hide-details="auto"
                :rules="[(val) => !!val || 'Modules is required!']"
                v-model="emailSubscriberDetails.module"
                :error-messages="formErrors.module"
                @change="delete formErrors.module"
              />
            </v-col>

            <v-col cols="12" class="Form-Columns">
              <MultiSelect
                label="Users *"
                :items="allUserList"
                itemText="username"
                itemValue="id"
                :multiple="true"
                :chips="true"
                :showAll="true"
                hide-details="auto"
                :rules="[(val) => !!val || 'User is required!']"
                v-model="emailSubscriberDetails.gt_users"
                :error-messages="formErrors.gt_users"
                @change="delete formErrors.gt_users"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          depressed
          :disabled="!isValid"
          @click="submitForm"
        >
          Submit
        </v-btn>

        <v-btn
          class="ml-3"
          color="secondary"
          v-if="!editMode"
          depressed
          @click="resetForm"
        >
          Reset
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";

export default {
  components: {
    InputField,
    MultiSelect,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    editEmailSubscriberId: {
      type: [Number, null],
      default: null,
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.getUserList();
        this.getEmailSubscribersOptions();
        this.getCompanyList();
        if (this.editMode) {
          this.getEmailSubscriberObject(this.editEmailSubscriberId);
        }
      } else {
        this.resetForm();
      }
    },
  },
  computed: {
    showEmailSubscriberForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (!obj) {
        return {};
      } else {
        return obj;
      }
    },
  },
  data() {
    return {
      isValid: true,
      isTextInput: false,
      formErrors: {},
      emailSubscriberDetails: {
        gt_users: [],
      },
      allUserList: [],
      allModuleList: [],
      EmailSubscriberUserList: [],
    };
  },
  methods: {
    submitForm() {
      this.$bus.$emit("showLoader", true);
      if (!this.editMode) {
        this.emailSubscriberDetails = {
          company: this.currentProfile.company,
          ...this.emailSubscriberDetails,
        };
        this.$api.emailSubscriber
          .addEmailSubscribers(this.emailSubscriberDetails)
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Email Subscribers added successfully!",
              color: "success",
            });
            this.resetForm();
            this.showEmailSubscriberForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: `${
                err.data && err.data.non_field_errors
                  ? err.data.non_field_errors.join()
                  : "Couldn't add Email Subscribers"
              }`,
              color: "error",
            });
            this.formErrors = err.data;
          });
      } else {
        this.$api.emailSubscriber
          .updateGetEmailSubscribers({
            id: this.emailSubscriberDetails.id,
            data: this.emailSubscriberDetails,
          })
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Email Subscriber Map updated successfully!",
              color: "success",
            });
            this.resetForm();
            this.showEmailSubscriberForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: `${
                err.data && err.data.non_field_errors
                  ? err.data.non_field_errors.join()
                  : "Couldn't update Email Subscriber"
              }`,
              color: "error",
            });

            this.formErrors = err.data;
          });
      }
    },

    resetForm() {
      if (this.$refs.EmailSubscriberForm) {
        this.$refs.EmailSubscriberForm.reset();
      }
      this.emailSubscriberDetails = {
        gt_users: [],
      };
    },

    getEmailSubscriberObject(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.emailSubscriber
        .getEmailSubscriberObject(id)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.emailSubscriberDetails = res.data;
          this.getEmailSubscriberCompanyUser();
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getEmailSubscribersOptions() {
      this.$bus.$emit("showLoader", true);
      this.$api.emailSubscriber
        .getEmailSubscribersOptions()
        .then((res) => {
          this.allModuleList = res.data.actions.POST.module.choices.map(
            (obj) => {
              return { text: obj.display_name, value: obj.value };
            }
          );
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getCompanyList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        limit: "all",
        company_type: "Admin",
        ...params,
      };

      this.$api.company
        .getCompanyList(params)
        .then((res) => {
          this.EmailSubscriberUserList = res.data;
          this.totalItems = res.count;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getUserList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        company: this.currentProfile.company,
        limit: "all",
        user_type: "Admin",
        ...params,
      };

      this.$api.user
        .getUserList(params)
        .then((res) => {
          this.allUserList = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getEmailSubscriberCompanyUser(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        ...params,
        company: this.emailSubscriberDetails.company,
        user_type: "Admin",
        limit: "all",
      };
      this.$api.user
        .getUserList(params)
        .then((res) => {
          this.EmailSubscriberUserList = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
};
</script>
