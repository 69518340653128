<template>
  <div>
    <v-btn
      depressed
      color="primary"
      small
      v-if="
        params &&
        params.context &&
        params.context.parentComponent &&
        params.context.parentComponent.da_permissions &&
        params.context.parentComponent.da_permissions.add &&
        (params.context.parentComponent.tab == 0 ||
          params.context.parentComponent.tab == 1)
      "
      @click="createDeliveryAdvice"
      :disabled="params.data.all_container_utilized"
    >
      Create DA
    </v-btn>

    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-icon
          class="ml-2"
          color="primary"
          v-on="on"
          v-bind="attrs"
          @click="viewItem"
        >
          mdi-eye
        </v-icon>
      </template>
      <span>View</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  methods: {
    viewItem() {
      this.params.context.parentComponent.viewOrder(this.params.data.id);
    },
    createDeliveryAdvice() {
      this.params.context.parentComponent.createDeliveryAdvice(
        this.params.data.id
      );
    },
  },
};
</script>

<style></style>
