<template>
  <v-dialog max-width="65%" v-model="showDriverLeaveForm">
    <v-card>
      <v-card-title class="secondary white--text">
        <span class="text-capitalize">
          {{ editMode ? "Update" : "Add" }} Driver Leave
        </span>

        <v-spacer></v-spacer>

        <v-btn icon @click="resetForm(), (showDriverLeaveForm = false)">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-2">
        <v-form
          ref="driverLeaveForm"
          v-if="showDriverLeaveForm"
          v-model="isValid"
        >
          <v-row class="my-0">
            <v-col cols="12" lg="4" class="Form-Columns">
              <MultiSelect
                label="Leave Type *"
                :items="allLeaveTypeList"
                hide-details="auto"
                :rules="[(val) => !!val || 'Leave Type is Required!']"
                v-model="driverLeaveDetails.leave_type"
                :error-messages="formErrors.leave_type"
                @change="delete formErrors.leave_type"
              />
            </v-col>

            <v-col cols="12" lg="4" class="Form-Columns">
              <DateField
                :inputProps="{
                  dense: true,
                  label: 'Start Date *',
                  rules: [(val) => !!val || 'Start Date is Required!'],
                  'hide-details': 'auto',
                  'error-messages': formErrors.start_date,
                }"
                required
                :min="today"
                v-model="start_date"
                @input="delete formErrors.start_date"
              />
            </v-col>

            <v-col cols="12" lg="4" class="Form-Columns">
              <DateField
                :inputProps="{
                  dense: true,
                  label: 'End Date *',
                  'hide-details': 'auto',
                  rules: [(val) => !!val || 'End Date is Required!'],
                  'error-messages': formErrors.end_date,
                }"
                required
                :min="start_date ? start_date : today"
                v-model="end_date"
                @input="delete formErrors.end_date"
              />
            </v-col>

            <v-col cols="12" lg="4" class="Form-Columns">
              <InputField
                type="number"
                label="Total days"
                readonly
                :clearable="false"
                hide-details="auto"
                v-model="driverLeaveDetails.total_days"
                :error-messages="formErrors.total_days"
                @input="delete formErrors.total_days"
              />
            </v-col>

            <v-col cols="12" lg="8" class="Form-Columns">
              <TextareaField
                label="Description *"
                required
                :rules="[(val) => !!val || 'Description is Required!']"
                hide-details="auto"
                v-model="driverLeaveDetails.description"
                :error-messages="formErrors.description"
                @input="delete formErrors.description"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          depressed
          :disabled="!isValid"
          @click="submitForm"
        >
          Submit
        </v-btn>

        <v-btn
          class="ml-3"
          color="secondary"
          v-if="!editMode"
          depressed
          @click="resetForm"
        >
          Reset
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DateField from "@/components/FormBaseComponents/DateField.vue";
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import SelectField from "@/components/FormBaseComponents/SelectField.vue";
import TextareaField from "@/components/FormBaseComponents/TextareaField.vue";
import moment from "moment";

export default {
  components: {
    DateField,
    InputField,
    SelectField,
    MultiSelect,
    TextareaField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    editDriverLeaveID: {
      type: [Number, null],
      default: null,
    },
    driverId: {
      type: [Number, null],
      default: null,
    },
  },
  computed: {
    showDriverLeaveForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    value(val) {
      if (val) {
        if (this.editMode && this.editDriverLeaveID) {
          this.getDriverLeaveObject(this.editDriverLeaveID);
        }
        this.getDriverLeaveOptions();
      } else {
        this.resetForm();
      }
    },
    start_date(value) {
      this.checkStartEndDateValidity("start_date", value);
    },
    end_date(value) {
      this.checkStartEndDateValidity("end_date", value);
    },
  },
  data() {
    return {
      formErrors: {},
      isValid: true,
      isTextInput: false,

      // form vars
      start_date: null,
      end_date: null,
      driverLeaveDetails: {},

      allLeaveTypeList: [],

      today: new Date().toISOString().slice(0, 10),
    };
  },
  methods: {
    checkStartEndDateValidity(input, value) {
      this.driverLeaveDetails[input] = value;
      if (this.start_date && this.end_date) {
        let d1 = moment(this.start_date);
        let d2 = moment(this.end_date);
        if (d1.isAfter(d2) || d2.isBefore(d1)) {
          this.formErrors[input] =
            input == "start_date"
              ? "Start date can not be after End Date"
              : "End Date can not be before Start Date";
        } else {
          this.formErrors.start_date = "";
          this.formErrors.end_date = "";
          this.driverLeaveDetails.total_days = d2.diff(d1, "days") + 1;
          return true;
        }
      }
    },
    submitForm() {
      this.$bus.$emit("showLoader", true);
      if (!this.editMode) {
        this.driverLeaveDetails = {
          ...this.driverLeaveDetails,
          driver: this.driverId,
        };
        this.$api.driverLeave
          .addDriverLeave(this.driverLeaveDetails)
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Driver Leave added successfully!",
              color: "success",
            });
            this.resetForm();
            this.showDriverLeaveForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message:
                err.data && err.data.non_field_errors
                  ? err.data.non_field_errors[0]
                  : "Couldn't add driver leave!",
              color: "error",
            });
            this.formErrors = err.data;
          });
      } else {
        this.$api.driverLeave
          .updateDriverLeave({
            id: this.driverLeaveDetails.id,
            data: this.driverLeaveDetails,
          })
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Driver Leave updated successfully!",
              color: "success",
            });
            this.resetForm();
            this.showDriverLeaveForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message:
                err.data && err.data.non_field_errors
                  ? err.data.non_field_errors[0]
                  : "Couldn't update driver leave!",
              color: "error",
            });
            this.formErrors = err.data;
          });
      }
    },

    resetForm() {
      if (this.$refs.driverLeaveForm) {
        this.$refs.driverLeaveForm.reset();
      }
      this.start_date = null;
      this.end_date = null;
      this.driverLeaveDetails = {};
    },

    getDriverLeaveObject(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.driverLeave
        .getDriverLeaveObject(id)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.driverLeaveDetails = res.data;
          this.start_date = res.data.start_date;
          this.end_date = res.data.end_date;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getDriverLeaveOptions() {
      this.$bus.$emit("showLoader", true);
      this.$api.driverLeave
        .getDriverLeaveOptions()
        .then((res) => {
          this.allLeaveTypeList = res.data.actions.POST.leave_type.choices.map(
            (obj) => obj.value
          );
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
};
</script>
