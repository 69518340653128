<template>
  <v-dialog width="500" v-model="showCheckListForm">
    <v-card>
      <v-card-title class="secondary white--text">
        <span> {{ editMode ? "Update" : "Add" }} Check List </span>

        <v-spacer></v-spacer>

        <v-btn icon @click="resetForm(), (showCheckListForm = false)">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-2">
        <v-form ref="checkListForm" v-if="showCheckListForm" v-model="isValid">
          <v-row class="my-0">
            <v-col cols="12" class="Form-Columns">
              <InputField
                type="text"
                label="Check List Name *"
                required
                :rules="[
                  (val) => !!val || 'Check List Name is Required!',
                  (val) =>
                    !$globals.numbersOnlyRegExp.test(val) ||
                    'Invalid Check List Name!',
                ]"
                hide-details="auto"
                v-model="checkListDetails.name"
                :error-messages="formErrors.name"
                @input="delete formErrors.name"
              />
            </v-col>
            <v-col cols="12" class="Form-Columns">
              <InputField
                type="text"
                label="Arabic Check List Name *"
                required
                :rules="[
                  (val) => !!val || 'Arabic Check List Name is Required!',
                  (val) =>
                    !$globals.numbersOnlyRegExp.test(val) ||
                    'Invalid Check List Name!',
                ]"
                hide-details="auto"
                v-model="checkListDetails.arabic_checklist_name"
                :error-messages="formErrors.arabic_checklist_name"
                @input="delete formErrors.arabic_checklist_name"
              />
            </v-col>

            <v-col cols="12" class="Form-Columns">
              <MultiSelect
                label="Asset Type *"
                :items="allAssetType"
                :rules="[(val) => !!val || 'Asset Type is Required!']"
                hide-details="auto"
                v-model="checkListDetails.asset_type"
                :error-messages="formErrors.asset_type"
                @change="delete formErrors.asset_type"
              />
            </v-col>
            <v-col cols="12" class="Form-Columns"  >
              <v-file-input
                label="Document"
                name="document"
                accept="image/x-png,image/jpg,image/jpeg,application/pdf"
                dense
                outlined
                :rules="[
                  (file) =>
                    !file ||
                    file.size < 2e6 ||
                    'File size should be less than 2 MB!',
                ]"
                hide-details="auto"
                v-model="checklist_image"
                :error-messages="formErrors.checklist_image"
                @change="delete formErrors.checklist_image, getFileData($event)"
              />
            </v-col>
            <v-col cols="12" lg="5" class="py-1" v-if="editMode && checkListDetails.checklist_image">
              <a
                :href="checkListDetails.checklist_image"
                target="_black"
                name="uploaded-document-type"
              >
                <span>Document Image </span>
              </a>
            </v-col>

            <v-col cols="12" class="Form-Columns">
              <v-switch
                :label="`Mandatory : ${checkListDetails.is_mandatory}`"
                true-value="Yes"
                false-value="No"
                hide-details="auto"
                class="mt-1"
                v-model="checkListDetails.is_mandatory"
                :error-messages="formErrors.is_mandatory"
                @change="delete formErrors.is_mandatory"
              ></v-switch>
            </v-col>
            <v-col cols="12" class="Form-Columns">
              <v-switch
                :label="`Is Active : ${checkListDetails.is_active}`"
                true-value="Yes"
                false-value="No"
                hide-details="auto"
                class="mt-1"
                v-model="checkListDetails.is_active"
                :error-messages="formErrors.is_active"
                @change="delete formErrors.is_active"
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          depressed
          :disabled="!isValid"
          @click="submitForm"
        >
          Submit
        </v-btn>

        <v-btn
          class="ml-3"
          color="secondary"
          v-if="!editMode"
          depressed
          @click="resetForm"
        >
          Reset
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import SelectField from "@/components/FormBaseComponents/SelectField.vue";

export default {
  components: {
    InputField,
    SelectField,
    MultiSelect,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    editCheckListID: {
      type: [Number, null],
      default: null,
    },
  },
  watch: {
    value(val) {
      if (val) {
        if (this.editMode) {
          this.getCheckListObject(this.editCheckListID);
        }
        this.getCheckListOptions();
      } else {
        this.resetForm();
      }
    },
  },
  computed: {
    showCheckListForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    return {
      formErrors: {},
      isValid: true,
      isTextInput: false,
      checklist_image: null,

      checkListDetails: {
        is_mandatory: "No",
        is_active: "Yes",
      },

      allAssetType: [],
    };
  },
  methods: {
    getFileData(fileInput) {
      if (fileInput) {
        if (this.checkFileFormat(fileInput)) {
          if (fileInput && !this.checklist_image) {
            this.$nextTick(() => {
              this.checklist_image = null;
            });
            this.$bus.$emit("showToastMessage", {
              message: "Select Document type first!",
              color: "error",
            });
            return false;
          }
        } else {
          alert("This file format is not Supported!");
        }
      }
    },
    checkFileFormat(event) {
      const name = event.name;
      const lastDot = name.lastIndexOf(".");
      const ext = name.substring(lastDot + 1);
      let allowed = /(\jpg|\jpeg|\pdf|\png)$/i;
      if (!allowed.exec(ext)) {
        this.$nextTick(() => {
          this.checklist_image = null;
        });
        return false;
      } else {
        return true;
      }
    },
    async uploadChecklistDocuments(resdata) {
      let payload = {
        id: resdata.id,
        data: null,
      };

      let newForm = new FormData();
      newForm.append("checklist_image", this.checklist_image);
      payload.data = newForm;
      await this.$api.checkList.uploadCheckListDocument(payload);
    },

    submitForm() {
      this.$bus.$emit("showLoader", true);
      if (!this.editMode) {
        this.checkListDetails = {
          ...this.checkListDetails,
        };

        this.$api.checkList
          .addCheckList(this.checkListDetails)
          .then(async (res) => {
            if (this.checklist_image) {
              await this.uploadChecklistDocuments(res.data.response_data);
            }
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "CheckList added successfully!",
              color: "success",
            });
            this.resetForm();
            this.showCheckListForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't add checkList!",
              color: "error",
            });
            this.formErrors = err.data;
          });
      } else {
        // const imageUrl =
        //   this.checkListDetails.checklist_image;

        // fetch(imageUrl)
        //   .then((response) => response.blob()) // download the file content as a blob
        //   .then((blob) => {
        //     this.checklist_image = new File([blob], "filename.png", { type: blob.type });
        //   })
        //   .catch((error) => console.error(error));
        
        this.checkListDetails.checklist_image =null
        this.$api.checkList
          .updateCheckList({
            id: this.checkListDetails.id,
            data: this.checkListDetails,
          })
          .then(async (res) => {
            if (this.checklist_image) {
              await this.uploadChecklistDocuments(res.data.response_data);
            }
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "CheckList updated successfully!",
              color: "success",
            });
            this.resetForm();
            this.showCheckListForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't update checkList!",
              color: "error",
            });
            this.formErrors = err.data;
          });
      }
    },

    resetForm() {
      if (this.$refs.checkListForm) {
        this.$refs.checkListForm.reset();
      }
      this.checkListDetails = {
        is_mandatory: "No",
        is_active: "Yes",
      };
    },

    getCheckListObject(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.checkList
        .getCheckListObject(id)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.checkListDetails = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getCheckListOptions() {
      this.$bus.$emit("showLoader", true);
      this.$api.checkList
        .getCheckListOptions()
        .then((res) => {
          this.allAssetType = res.data.actions.POST.asset_type.choices.map(
            (obj) => obj.value
          );
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
};
</script>
