import { depot } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
    getDepotList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(depot.depotBase, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getDepotObject(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${depot.depotBase}${id}/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    addDepot(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(depot.depotBase, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getDepotSelectList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${depot.depotBase}select/`, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    updateDepot(payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(`${depot.depotBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    partiallyUpdateDepot(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${depot.depotBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
});