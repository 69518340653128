<template>
  <v-dialog max-width="65%" width="500" v-model="showEmailForm" persistant>
    <v-card>
      <v-card-title class="secondary white--text">
        <span>Send Email</span>

        <v-spacer></v-spacer>

        <v-btn icon @click="resetForm(), (showEmailForm = false)">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pa-2">
        <v-form ref="emailForm" v-if="showEmailForm" v-model="isValid">
          <v-row class="ma-0">
            <v-col cols="12">
              <InputField
                hide-details="auto"
                type="text"
                label="Email Id *"
                required
                :rules="[
                  (val) => !!val || 'Email is Required!',
                  (val) => /.+@.+\..+/.test(val) || 'Email must be valid',
                ]"
                v-model="emailDetails.email_detail"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          color="primary"
          depressed
          :disabled="!isValid"
          @click="submitForm"
        >
          Submit
        </v-btn>

        <v-btn class="ml-3" color="secondary" depressed @click="resetForm">
          Reset
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import SelectField from "@/components/FormBaseComponents/SelectField.vue";

export default {
  components: {
    InputField,
    SelectField,
    MultiSelect,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    invoiceId: {
      type: [Number, null],
      default: null,
    },
    companyEmail: {
      type: [String, null],
      default: null,
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.emailDetails.email_detail = this.companyEmail;
      }
    },
  },
  computed: {
    showEmailForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    return {
      isValid: true,
      isTextInput: false,
      emailDetails: {},
    };
  },
  methods: {
    async submitForm() {
      let payload = {
        id: this.invoiceId,
        emailId: this.emailDetails,
      };
      await this.$api.customerInvoice
        .sendPDFEmail(payload)
        .then(async (response) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Email Send successfully!",
            color: "success",
          });
          this.resetForm();
          this.showEmailForm = false;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    resetForm() {
      if (this.$refs.emailForm) {
        this.$refs.emailForm.reset();
      }
      this.emailDetails = {};
    },
  },
};
</script>