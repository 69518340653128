<template>
  <v-dialog v-model="showRemarksDialog" max-width="500">
    <v-card>
      <v-card-title class="secondary white--text">
        <span>Remarks </span>

        <v-spacer></v-spacer>

        <v-btn icon @click="showRemarksDialog = false">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-2">
        <v-form v-model="isValid" v-if="showRemarksDialog">
          <v-row class="my-0">
            <v-col v-if="formData.start_date" cols="12">
              <div class="d-flex">
                <span class="text-subtitle-1">Start Date :&nbsp;</span>
                <span class="text-subtitle-1 font-weight-medium">
                  {{ formData.start_date | formatDate }}
                </span>
              </div>
            </v-col>

            <v-col
              cols="12"
              class="Form-Columns"
              v-if="formData.status == 'Active'"
            >
              <DateField
                :inputProps="{
                  dense: true,
                  label: 'End Date *',
                  rules:[(v) => !!v || 'End Date is Required!'],
                  'hide-details': 'auto',
                  'error-messages': errorObj.end_date,
                }"
                required
                :min="today"
                v-model="formData.end_date"
                @input="errorObj = {}"
              />
            </v-col>

            <v-col cols="12" class="Form-Columns">
              <InputField
                type="text"
                :label="label"
                required
                :rules="[(val) => !!val || 'Remarks is Required!']"
                hide-details="auto"
                placeholder="Remarks"
                v-model="formData[fieldKey]"
                :error-messages="errorObj[fieldKey]"
                @input="errorObj = {}"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="justify-end">
        <v-btn
          depressed
          color="primary"
          :disabled="!isValid"
          @click="$emit('submitRemarks')"
        >
          <span>Submit</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DateField from "@/components/FormBaseComponents/DateField.vue";
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";

export default {
  components: { InputField, MultiSelect, DateField },
  props: {
    value: {
      type: Boolean,
    },
    formDetails: {
      type: Object,
      default: () => {},
    },
    fieldKey: {
      type: String,
    },
    isContractForm: {
      type: Boolean,
    },
    formErrors: {
      type: Object,
      default: () => {},
    },
    label: {
      type: String,
    },
  },
  data() {
    return {
      allJobTypeList: [],
      isValid: true,
      today: new Date().toISOString().slice(0, 10),
      errorObj: {},
    };
  },
  computed: {
    formData: {
      get() {
        return this.formDetails;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    showRemarksDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    value(val) {
      if (val && this.isContractForm) {
      }
    },
    formErrors(val) {
      this.errorObj = { ...val };
    },
  },

  methods: {
    getJobTypes() {
      let payload = {
        source_location: this.formData.source_location,
        mid_location: this.formData.mid_location,
        destination_location: this.formData.destination_location,
      };
      if (payload) {
        this.$bus.$emit("showLoader", true);
        this.$api.customerContract
          .getJobTypes(payload)
          .then((res) => {
            this.allJobTypeList = res.data;
            this.$bus.$emit("showLoader", false);
          })
          .catch((err) => {
            console.error(err);
            this.$bus.$emit("showLoader", false);
          });
      }
    },
  },
};
</script>