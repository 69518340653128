<template>
  <div>
    <v-row class="ma-0">
      <v-col cols="12" sm="6" class="d-flex align-center">
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              @click="callBackRoute"
              v-bind="attrs"
              v-on="on"
              class="mr-3"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </template>
          <span>Back</span>
        </v-tooltip>
        <span class="text-h5">Order Details</span>
      </v-col>

      <v-col cols="12" sm="6" class="text-right align-self-center">

        <v-btn depressed @click="viewOrderLogs" color="primary" small>
          Order Logs
        </v-btn>

        <v-btn
          depressed
          color="primary"
          small
          @click="createDeliveryAdvice(orderDetails.id)"
          class="ml-2"
          v-if="
            orderDetails.status == 'Pending' ||
            (orderDetails.status == 'Active' &&
              !orderDetails.all_container_utilized)
          "
          v-show="permissions && permissions.add"
        >
          Create DA
        </v-btn>
      </v-col>

      <v-col cols="12" class="heading">
        <v-row class="ma-0" justify="space-between">
          <v-col cols="12" lg="auto" class="Form-Columns-Dense">
            <div class="d-flex">
              <div>
                <span class="text-caption">Customer Name</span>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  {{ orderDetails.customer_name }}
                </p>
              </div>
              <v-divider vertical class="mx-3"></v-divider>
              <div>
                <span class="text-caption">Order No</span>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  {{ orderDetails.order_no }}
                </p>
              </div>
              <v-divider vertical class="mx-3"></v-divider>
              <div>
                <span class="text-caption">BOE/Reference No</span>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  {{ orderDetails.boe }}
                </p>
              </div>
            </div>
          </v-col>

          <v-col
            cols="12"
            lg="auto"
            class="Form-Columns-Dense align-self-center"
          >
            <p class="mb-0">
              <ViewInformationOnHover
                icon="mdi-train-car-container"
                :badgeValue="orderDetails.order_container_count"
                cardTitle="Order Container List"
              >
                <template #information>
                  <ContainerCard
                    v-for="(container, i) in orderDetails.order_containers"
                    :key="i"
                    :container="container"
                  />
                </template>
              </ViewInformationOnHover>
            </p>
          </v-col>

          <v-col cols="12" lg="auto" class="Form-Columns-Dense">
            <div>
              <span class="text-caption">Status</span>
              <p class="text-subtitle-1 font-weight-medium mb-0">
                {{ orderDetails.status }}
              </p>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12">
        <AgGridVue
          style="width: 100%; height: calc(100vh - 226px)"
          class="ag-theme-alpine"
          id="daGrid"
          :columnDefs="daHeaders"
          :context="context"
          @grid-ready="onGridReady"
          :defaultColDef="defaultColDef"
          :grid-options="gridOptions"
          :suppressDragLeaveHidesColumns="true"
          :rowData="orderDeliveryList"
        ></AgGridVue
      ></v-col>
    </v-row>

    <DetailsParent v-model="daDetailsDrawer" :resizable="true">
      <template #details>
        <v-card class="fill-height">
          <v-card-title class="heading mb-4">
            <span class="text-h6">Delivery Advice Details</span>
          </v-card-title>

          <v-card-subtitle class="subHeading py-2">
            <Tooltip
              tooltipTitle="Delivery Advice No"
              v-if="fetchedDeliveryAdvice && fetchedDeliveryAdvice.da_no"
              :tooltipText="fetchedDeliveryAdvice.da_no"
              right
              tooltipClass="text-subtitle-1 font-weight-medium"
            />
          </v-card-subtitle>

          <v-card-text>
            <v-tabs v-model="drawerTabs" fixed-tabs class="mt-3">
              <v-tabs-slider color="white"></v-tabs-slider>
              <v-tab>Containers</v-tab>
              <v-tab>Logs</v-tab>
            </v-tabs>

            <v-tabs-items v-model="drawerTabs" class="mt-3">
              <v-tab-item>
                <v-divider></v-divider>

                <div
                  v-if="fetchedDeliveryAdvice"
                  class="Delivery-Advice-Scrolling-Class mt-3"
                >
                  <ContainerCard
                    v-for="(
                      container, i
                    ) in fetchedDeliveryAdvice.delivery_advice_containers"
                    :key="i"
                    :container="container"
                  />
                </div>
              </v-tab-item>

              <v-tab-item>
                <v-divider class="mb-3"></v-divider>

                <p v-if="!fetchedDeliveryAdvice" class="py-12">
                  Select Delivery Advice to see it's logs!
                </p>

                <span
                  class="py-12"
                  v-else-if="
                    fetchedDeliveryAdvice.delivery_advice_logs &&
                    fetchedDeliveryAdvice.delivery_advice_logs.length == 0
                  "
                >
                  No logs to show!
                </span>

                <div class="Delivery-Advice-Scrolling-Class" v-else>
                  <v-timeline dense clipped>
                    <v-timeline-item
                      v-for="(
                        log, i
                      ) in fetchedDeliveryAdvice.delivery_advice_logs"
                      :key="i"
                      icon-color="primary"
                      small
                    >
                      <v-card class="elevation-2">
                        <v-card-text>
                          <table class="Full-Width">
                            <tbody>
                              <tr>
                                <th class="text-left">{{ log.added_by }}</th>
                                <td class="text-caption text-right">
                                  {{ log.created | formatDate(true) }}
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2">{{ log.message }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-timeline-item>
                  </v-timeline>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </template>
    </DetailsParent>

    <DetailsParent v-model="orderLogDetailsDrawer" :resizable="true">
      <template #details>
        <v-card elevation="0">
          <v-card-title class="heading">
            <span class="text-h6">Order Log</span>
          </v-card-title>

          <v-card-text>
            <v-row class="Order-Log-Scrolling-Class mt-3">
              <v-col cols="12">
                <span
                  class="py-12"
                  v-if="
                    orderDetails.order_logs &&
                    orderDetails.order_logs.length == 0
                  "
                >
                  No logs to show!
                </span>

                <v-timeline dense clipped v-else>
                  <v-timeline-item
                    v-for="(log, i) in orderDetails.order_logs"
                    :key="i"
                    icon-color="primary"
                    small
                  >
                    <v-card class="elevation-2">
                      <v-card-text>
                        <table class="Full-Width">
                          <tbody>
                            <tr>
                              <th class="text-left">{{ log.added_by }}</th>
                              <td class="text-caption text-right">
                                {{ log.created | formatDate(true) }}
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2">{{ log.message }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </v-card-text>
                    </v-card>
                  </v-timeline-item>
                </v-timeline>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </DetailsParent>


  </div>
</template>

<script>
import ViewInformationOnHover from "@/components/General/ViewInformationOnHover.vue";
import ContainerCard from "@/components/General/ContainerCard.vue";
import { AgGridVue } from "ag-grid-vue";
import DetailsParent from "@/components/General/DetailsParent.vue";
import Tooltip from "@/components/General/Tooltip.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    ViewInformationOnHover,
    AgGridVue,
    ContainerCard,
    DetailsParent,
    Tooltip,
  },
  data() {
    return {
      trip_permissions: null,
      da_permissions: null,
      permissions: null,
      orderDetails: {},
      orderDeliveryList: [],
      sorting: {},
      daHeaders: [
        {
          headerName: "DA No.",
          field: "da_no",
          minWidth: 110,
          cellStyle: (params) => {
            return {
              textDecoration: "underline",
            };
          },

          onCellClicked: (params) => {
            const path = {
              path: `/app/admin/delivery-advice/details/${params.data.id}`,
              query: {
                backRoute: `/app/admin/order/details/${this.$route.params.id}`,
                active_tab: this.$route.query.active_tab,
              },
            };
            this.$router.push(path);
          },
        },
        {
          headerName: "BOE No",
          field: "boe",
          sortable: true,
          minWidth: 120,
        },
        {
          headerName: "Route",
          field: "lane_title",
          sortable: false,
          minWidth: 145,
        },
        {
          headerName: "Job Type",
          field: "job_type",
          sortable: true,
          minWidth: 85,
        },
        {
          headerName: "Genset",
          field: "has_genset",
          sortable: true,
          minWidth: 65,
        },
        {
          headerName: "Hazmat",
          field: "is_hazmat",
          sortable: true,
          minWidth: 65,
        },
        {
          headerName: "Payment Term",
          field: "payment_term",
          sortable: true,
          minWidth: 70,
        },
        {
          headerName: "Currency",
          field: "currency",
          sortable: true,
          minWidth: 70,
        },
        {
          headerName: "Movement Date",
          field: "movement_date_time",
          sortable: false,
          minWidth: 160,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value, true);
          },
        },
        {
          headerName: "Container(s)",
          field: "da_container_details.da_container_count",
          minWidth: 100,
        },
        {
          headerName: "Leg(s)",
          field: "total_da_legs",
          minWidth: 100,
        },
        {
          headerName: "Created At",
          field: "created",
          minWidth: 160,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value, true);
          },
        },
        {
          headerName: "Completed On",
          field: "completed_on",
          minWidth: 160,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value, true);
          },
        },
        {
          headerName: "Branch",
          field: "branch_name",
          sortable: true,
          minWidth: 100,
        },
        {
          headerName: "Depot",
          field: "depot_name",
          sortable: true,
          minWidth: 100,
        },
        {
          headerName: `Tariff`,
          field: "customer_contract_id",
          minWidth: 100,
        },
        {
          headerName: `Rate (${this.$globals.currency})`,
          field: "rate",
          minWidth: 90,
        },
        {
          headerName: "Status",
          field: "status",
          sortable: true,
          minWidth: 130,
          tooltipField: "status",
        },
      ],

      tabs: 0,
      selectedOrderContainer: null,
      fetchedDeliveryAdvice: null,

      drawerTabs: 0,
      orderLogDetailsDrawer: false,
      daDetailsDrawer: false,

      showDaChargeDialog: false,
      selectedDa: null,

      backRoute: { path: "/app/admin/order/list" },
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        resizable: true,
        flex: 1,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },
    };
  },
  watch: {
    tabs() {
      this.orderLogDetailsDrawer = false;
      this.daDetailsDrawer = false;
    },
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    resizeGrid() {
      if (this.gridOptions && "api" in this.gridOptions) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },
    callBackRoute() {
      this.$router.push(this.backRoute);
    },
    viewDeliveryAdviceCharges(da) {
      this.showDaChargeDialog = true;
      this.selectedDa = da;
    },

    viewOrderLogs() {
      this.orderLogDetailsDrawer = true;
    },
    viewDeliveryAdviceDetails(da) {
      this.getDeliveryAdviceObject(da.id);
      this.daDetailsDrawer = true;
    },

    createDeliveryAdvice(id) {
      this.$router.push(`/app/admin/order/create-da/${id}`);
    },
    selectContainer(container) {
      if (container == this.selectedOrderContainer) {
        this.selectedOrderContainer = null;
      } else {
        this.selectedOrderContainer = container;
      }
    },
    viewDeliveryAdvice(id) {
      this.$router.push({
        path: `/app/admin/delivery-advice/list`,
        query: {
          da_id: id,
        },
      });
    },
    getDeliveryAdviceObject(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.deliveryAdvice
        .getDeliveryAdviceObject(id)
        .then((res) => {
          this.fetchedDeliveryAdvice = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    deleteDeliveryAdvice(da) {
      if (confirm(`Are you sure you want to delete ${da.da_no}`)) {
        this.$api.deliveryAdvice
          .deleteDeliveryAdvice(da.id)
          .then((res) => {
            this.$bus.$emit("showToastMessage", {
              message: `Delivery Advice ${da.da_no} deleted successfully!`,
              color: "success",
            });
            this.getOrderDeliveryAdviceList({ order: this.$route.params.id });
            this.getOrderObject(this.$route.params.id);
          })
          .catch((err) => {
            this.$bus.$emit("showToastMessage", {
              message: `Couldn't delete delivery advice!`,
              color: "error",
            });
          });
      }
    },
    getOrderObject(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.customerOrder
        .getCustomerOrderDetails(id)
        .then((res) => {
          this.orderDetails = res.data;
          this.$bus.$emit("showLoader", false);
          this.setBackRoute();
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getOrderDeliveryAdviceList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        ...params,
        limit: "all",
      };
      this.$api.deliveryAdvice
        .getDeliveryAdviceList(params)
        .then((res) => {
          this.orderDeliveryList = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },

    createTrip(id) {
      this.$router.push(`/app/admin/trip/add/${id}`);
    },
    editTrip(id) {
      this.$router.push(`/app/admin/trip/edit/${id}`);
    },
    setBackRoute() {
      if ("backRoute" in this.$route.query) {
        this.backRoute.path = this.$route.query.backRoute;
      }
      if ("active_tab" in this.$route.query) {
        if (!this.backRoute.query) {
          this.backRoute.query = {};
        }
        this.backRoute.query.active_tab = this.$route.query.active_tab;
      }
    },
  },
  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      this.permissions = getPermissions("order");
      this.trip_permissions = getPermissions("leg_trip");
      this.da_permissions = getPermissions("delivery_advice");
      if (!this.permissions || !this.permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.getOrderDeliveryAdviceList({ order: this.$route.params.id });
        this.getOrderObject(this.$route.params.id);
        this.setBackRoute();
      }
    }
  },
};
</script>

<style>
.Order-Log-Scrolling-Class {
  height: calc(100vh - 180px);
  overflow-y: auto;
}

.Delivery-Advice-Scrolling-Class {
  height: calc(100vh - 309px);
  overflow-y: auto;
}
</style>
