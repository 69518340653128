import Blank from "@/layouts/blank.vue"

import TicketList from "@/pages/admin/ticket/TicketList.vue";
import TicketAdd from "@/pages/admin/ticket/TicketAdd.vue";
import TicketEdit from "@/pages/admin/ticket/TicketView.vue";

const ticketRoutes = [
  {
    path: "ticket",
    name: "app-admin-ticket",
    component: Blank,
    children: [
      {
        path: "/",
        redirect: "/app/admin/ticket/list",
      },
      {
        path: "list",
        name: "app-admin-ticket-list",
        component: TicketList,
      },
      {
        path: "add",
        name: "app-admin-ticket-add",
        component: TicketAdd,
      },
      {
        path: "view",
        name: "app-admin-ticket-view",
        component: Blank,
        children: [
          {
            path: "/",
            redirect: "/app/admin/ticket/list",
          },
          {
            path: ":id",
            name: "app-admin-ticket-view-id",
            component: TicketEdit,
          }
        ]
      },
    ]
  },
]

export default ticketRoutes