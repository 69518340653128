<template>
  <v-dialog width="500" v-model="showCountryForm">
    <v-card>
      <v-card-title class="secondary white--text">
        <span> {{ editMode ? "Update" : "Add" }} Country </span>

        <v-spacer></v-spacer>

        <v-btn icon @click="resetForm(), (showCountryForm = false)">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-2">
        <v-form ref="countryForm" v-if="showCountryForm" v-model="isValid">
          <v-row class="my-0">
            <v-col cols="12" class="Form-Columns">
              <InputField
                type="text"
                label="Country Name *"
                required
                :rules="[
                  (val) => !!val || 'Country Name is Required!',
                  (val) =>
                    $globals.lettersOnlyRegExp.test(val) ||
                    'Invalid Country Name!',
                ]"
                hide-details="auto"
                v-model="countryDetails.name"
                :error-messages="formErrors.name"
                @input="delete formErrors.name"
              />
            </v-col>

            <v-col cols="12" class="Form-Columns">
              <InputField
                type="text"
                label="Country Code *"
                required
                :rules="[(val) => !!val || 'Country Code is Required!']"
                hide-details="auto"
                v-model="countryDetails.country_code"
                :error-messages="formErrors.country_code"
                @input="delete formErrors.country_code"
              />
            </v-col>

            <v-col cols="12" class="Form-Columns">
              <InputField
                type="number"
                label="Phone Code *"
                :rules="[(val) => !!val || 'Phone Code is Required!']"
                required
                hide-details="auto"
                v-model="countryDetails.phone_code"
                :error-messages="formErrors.phone_code"
                @input="delete formErrors.phone_code"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          depressed
          :disabled="!isValid"
          @click="submitForm"
        >
          Submit
        </v-btn>

        <v-btn
          class="ml-3"
          color="secondary"
          v-if="!editMode"
          depressed
          @click="resetForm"
        >
          Reset
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import SelectField from "@/components/FormBaseComponents/SelectField.vue";

export default {
  components: {
    InputField,
    SelectField,
    MultiSelect,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    editCountryID: {
      type: [Number, null],
      default: null,
    },
  },
  watch: {
    value(val) {
      if (val) {
        if (this.editMode) {
          this.getCountryObject(this.editCountryID);
        }
      } else {
        this.resetForm();
      }
    },
  },
  computed: {
    showCountryForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    return {
      formErrors: {},
      isValid: true,
      isTextInput: false,

      countryDetails: {},
    };
  },
  methods: {
    submitForm() {
      this.$bus.$emit("showLoader", true);
      if (!this.editMode) {
        this.$api.country
          .addCountry(this.countryDetails)
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Country Added Successfully!",
              color: "success",
            });
            this.resetForm();
            this.showCountryForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't Add Country!",
              color: "error",
            });
            this.formErrors = err.data;
          });
      } else {
        this.$api.country
          .updateCountry({
            id: this.countryDetails.id,
            data: this.countryDetails,
          })
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Country Updated Successfully!",
              color: "success",
            });

            this.resetForm();
            this.showCountryForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't Update Country!",
              color: "error",
            });
            this.formErrors = err.data;
          });
      }
    },

    resetForm() {
      if (this.$refs.countryForm) {
        this.$refs.countryForm.reset();
      }
      this.countryDetails = {};
    },

    getCountryObject(id) {
      this.$bus.$emit("showLoader", true);

      this.$api.country
        .getCountryObject(id)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.countryDetails = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
};
</script>
