<template>
  <v-dialog persistent max-width="92%" v-model="customerInvoiceFormDialog">
    <v-card>
      <v-card-title class="secondary white--text px-4 py-2">
        <span>{{ isEditing ? "Edit" : "Add" }} Charge</span>
        <div class="d-flex">
          <v-menu open-on-hover left offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-on="on" v-bind="attrs" class="mx-2">
                mdi-information
              </v-icon>
            </template>
            <v-card>
              <v-card-title
                class="secondary py-1 text-subtitle-1 white--text font-weight-medium"
              >
                Information
              </v-card-title>
              <v-card-text class="mt-2">
                <table class="Normal-Table">
                  <tbody>
                    <tr>
                      <th>Check Tax Type and Tax Percentage</th>
                    </tr>
                  </tbody>
                </table>
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
        <v-spacer></v-spacer>
        <v-btn icon @click="customerInvoiceFormDialog = false">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-2">
        <v-form ref="customerInvoiceChargeForm" v-model="chargesValid">
          <v-row class="ma-0">
            <v-col cols="3" class="Form-Columns-Dense">
              <MultiSelect
                hide-details="auto"
                :items="allChargeList"
                itemText="charge_title"
                itemValue="charge_title"
                label="Charge Item *"
                :rules="[(val) => !!val || 'Charge Item is Required!']"
                :returnObject="true"
                v-model="daInvoiceCharge.charge_title"
                :readonly="isReadOnly"
                :clearable="false"
                :error-messages="formErrors.charge_title"
                @change="
                  delete formErrors.charge_title;
                  chargeUpdated($event);
                "
              />
            </v-col>

            <v-col cols="3" class="Form-Columns-Dense">
              <MultiSelect
                outlined
                readonly
                label="Tax Type *"
                :items="taxTypeList"
                v-model="daInvoiceCharge.tax_type"
                :error-messages="formErrors.tax_type"
              />
            </v-col>

            <v-col cols="3" class="Form-Columns-Dense">
              <InputField
                hide-details="auto"
                type="text"
                label="Description *"
                :readonly="
                  daInvoiceCharge.charge_type == 'Freight' || isReadOnly
                "
                :clearable="false"
                required
                :rules="[(val) => !!val || 'Charge Description is Required!']"
                v-model="daInvoiceCharge.description"
                :error-messages="formErrors.description"
                @input="delete formErrors.description"
              />
            </v-col>
            <v-col cols="3" class="Form-Columns-Dense">
              <InputField
                hide-details="auto"
                type="text"
                label="Revenue GL No."
                readonly
                :clearable="false"
                required
                :rules="[(val) => !!val || 'Revenue GL No is Required!']"
                v-model="daInvoiceCharge.gl_no"
                :error-messages="formErrors.gl_no"
                @input="delete formErrors.gl_no"
              />
            </v-col>
            <v-col cols="3" class="Form-Columns-Dense">
              <InputField
                hide-details="auto"
                type="number"
                step=".01"
                :label="`Rate (${$globals.currency}) *`"
                required
                :rules="[
                  (value) =>
                    (value != null && value != undefined) || 'Rate is required',
                  (val) => !!val || 'Rate is Required!',
                ]"
                :clearable="false"
                v-model="daInvoiceCharge.rate"
                :error-messages="formErrors.rate"
                @input="
                  delete formErrors.rate;
                  chargeDataChanged();
                "
              />
            </v-col>
            <v-col cols="3" class="Form-Columns-Dense">
              <InputField
                hide-details="auto"
                type="number"
                step=".01"
                :label="`Tax (%)`"
                :clearable="false"
                readonly
                required
                :rules="[
                  (value) => value >= 0 || 'Tax can not be less than 0',
                  (value) => !!value || 'Tax% is Required!',
                ]"
                v-model="daInvoiceCharge.tax_percentage"
                :error-messages="formErrors.tax_percentage"
                @input="delete formErrors.tax_percentage"
              />
            </v-col>
            <v-col cols="3" class="Form-Columns-Dense">
              <InputField
                hide-details="auto"
                type="number"
                :label="`Tax Amount (${$globals.currency})`"
                :clearable="false"
                readonly
                :value="daInvoiceCharge.tax_amount"
              />
            </v-col>
            <v-col cols="3" class="Form-Columns-Dense">
              <InputField
                hide-details="auto"
                type="number"
                :label="`Amount (${$globals.currency})`"
                :clearable="false"
                readonly
                :value="daInvoiceCharge.amount_with_tax"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions class="d-flex justify-end">
        <v-btn
          v-if="!isEditing"
          text
          depressed
          color="primary"
          @click="resetForm()"
        >
          {{ isEditing ? "Cancel" : "Reset" }}
        </v-btn>
        <v-btn
          :disabled="!chargesValid"
          depressed
          color="primary"
          @click="submit"
        >
          {{ isEditing ? "Update" : "Add" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
export default {
  components: {
    InputField,
    MultiSelect,
  },
  props: {
    value: {
      type: Boolean,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    chargeId: {
      required: false,
    },
    daId: {
      required: false,
    },
    invoiceId: {
      required: false,
    },
    isReadOnly: {
      default: false,
    },
    serviceType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      allChargeList: [],
      taxTypeList: [
        "Standard Rated",
        "Exempt Rated",
        "Zero Rated",
        "Exclude Rated",
      ],
      formErrors: {},
      daInvoiceCharge: {},
      chargesValid: false,
      newchargeId: null,
    };
  },
  computed: {
    customerInvoiceFormDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.getChargeList();
        if (this.isEditing) {
          this.chargrObject(this.chargeId);
        }
      } else {
        this.resetForm();
      }
    },
  },
  methods: {
    chargeDataChanged() {
      this.calculateRate();
    },
    chargeUpdated(charge) {
      let charge_obj = { ...charge };
      this.newchargeId = charge_obj.id;
      if (charge) {
        delete charge.id;

        this.daInvoiceCharge = {
          ...this.daInvoiceCharge,
          ...charge,
          charge_title: charge.charge_title,
        };

        this.calculateRate();
      } else {
        this.daInvoiceCharge = {};
      }
    },
    calculateRate() {
      const rate = this.daInvoiceCharge.rate;
      const tax = this.daInvoiceCharge.tax_percentage;
      const quantity = this.daInvoiceCharge.quantity;

      if (rate && quantity) {
        const totalGrossRate = parseFloat(rate) * parseFloat(quantity);
        if (totalGrossRate && tax) {
          const totalTaxAmount = totalGrossRate * (parseFloat(tax) / 100);

          this.daInvoiceCharge.tax_amount = totalTaxAmount.toFixed(4);
          this.daInvoiceCharge.amount_with_tax = (
            totalGrossRate + totalTaxAmount
          ).toFixed(4);
        }
      } else {
        this.daInvoiceCharge = {
          ...this.daInvoiceCharge,
          amount_with_tax: 0.0,
          tax_amount: 0.0,
        };
      }
    },
    getChargeList(params = {}) {
      params = {
        limit: "all",
        is_active: "true",
        is_revenue: "true",
      };
      this.$api.charge
        .getChargeList(params)
        .then((res) => {
          this.allChargeList = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    chargrObject(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.customerInvoice
        .getChargeObject(id)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.daInvoiceCharge = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    resetForm() {
      if (this.$refs.customerInvoiceChargeForm) {
        this.daInvoiceCharge = {};
        this.$refs.customerInvoiceChargeForm.reset();
      }
      this.formErrors = {};
    },

    submit() {
      this.$bus.$emit("showLoader", true);

      let payload = {
        ...this.daInvoiceCharge,
      };
      if (this.newchargeId) {
        payload.charge = this.newchargeId;
      }
      payload.da_charge_type = "Revenue";

      if (this.isEditing) {
        this.$api.customerInvoice
          .updateSingleCharge(payload.id, payload)
          .then((res) => {
            if (res && res.data) {
              this.$emit("refreshList", res.data);
            }
            this.customerInvoiceFormDialog = false;
            this.$bus.$emit("showLoader", false);
          })
          .catch((err) => {
            console.error(err);
            if (err && err.data) {
              this.formErrors = { ...err.data };
            }
            this.$bus.$emit("showLoader", false);
          });
      } else {
        payload.delivery_advice = this.daId;
        payload.charge_type = "Other";
        payload.customer_invoice = this.invoiceId;
        this.$api.customerInvoice
          .addSingleCharge(payload)
          .then((res) => {
            if (res && res.data) {
              this.$emit("refreshList", res.data);
            }
            this.customerInvoiceFormDialog = false;
            this.$bus.$emit("showLoader", false);
          })
          .catch((err) => {
            console.error(err);
            if (err && err.data) {
              this.formErrors = { ...err.data };
            }
            this.$bus.$emit("showLoader", false);
          });
      }
    },
  },
};
</script>

<style>
</style>