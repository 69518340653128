import { containerType } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
    getContainerTypeList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(containerType.containerTypeBase, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getContainerTypeSelectList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${containerType.containerTypeBase}select/`, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getContainerTypeOptions() {
        return new Promise((resolve, reject) => {
            axios
                .options(containerType.containerTypeBase)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    addContainerType(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(containerType.containerTypeBase, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getContainerTypeObject(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${containerType.containerTypeBase}${id}/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    updateContainerType(payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(`${containerType.containerTypeBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    partiallyUpdateContainerType(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${containerType.containerTypeBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
});