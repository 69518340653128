var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.showRemarksDialog),callback:function ($$v) {_vm.showRemarksDialog=$$v},expression:"showRemarksDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"secondary white--text"},[_c('span',[_vm._v("Remarks ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.showRemarksDialog = false}}},[_c('v-icon',{staticClass:"white--text"},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pt-2"},[(_vm.showRemarksDialog)?_c('v-form',{model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',{staticClass:"my-0"},[(_vm.formData.start_date)?_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"text-subtitle-1"},[_vm._v("Start Date : ")]),_c('span',{staticClass:"text-subtitle-1 font-weight-medium"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.formData.start_date))+" ")])])]):_vm._e(),(_vm.formData.status == 'Active')?_c('v-col',{staticClass:"Form-Columns",attrs:{"cols":"12"}},[_c('DateField',{attrs:{"inputProps":{
                dense: true,
                label: 'End Date *',
                rules:[(v) => !!v || 'End Date is Required!'],
                'hide-details': 'auto',
                'error-messages': _vm.errorObj.end_date,
              },"required":"","min":_vm.today},on:{"input":function($event){_vm.errorObj = {}}},model:{value:(_vm.formData.end_date),callback:function ($$v) {_vm.$set(_vm.formData, "end_date", $$v)},expression:"formData.end_date"}})],1):_vm._e(),_c('v-col',{staticClass:"Form-Columns",attrs:{"cols":"12"}},[_c('InputField',{attrs:{"type":"text","label":_vm.label,"required":"","rules":[(val) => !!val || 'Remarks is Required!'],"hide-details":"auto","placeholder":"Remarks","error-messages":_vm.errorObj[_vm.fieldKey]},on:{"input":function($event){_vm.errorObj = {}}},model:{value:(_vm.formData[_vm.fieldKey]),callback:function ($$v) {_vm.$set(_vm.formData, _vm.fieldKey, $$v)},expression:"formData[fieldKey]"}})],1)],1)],1):_vm._e()],1),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"depressed":"","color":"primary","disabled":!_vm.isValid},on:{"click":function($event){return _vm.$emit('submitRemarks')}}},[_c('span',[_vm._v("Submit")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }