import Blank from "@/layouts/blank.vue"
import CashReceipt from "@/pages/admin/invoice/CashReceipt.vue"

import CustomerInvoiceRoutes from "@/router/admin/invoice/customer"
import creditNoteRoutes from "@/router/admin/invoice/creditNote"

const invoiceRoutes = [
  {
    path: "invoice",
    name: "app-admin-invoice",
    component: Blank,
    children: [
      {
        path: "cash-receipt",
        name: "app-admin-invoice-cash-receipt",
        component: CashReceipt,
      },
      ...CustomerInvoiceRoutes,
      ...creditNoteRoutes
    ]
  },
]

export default invoiceRoutes