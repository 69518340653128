<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: {
    labels: Array,
    datasets: Array,
    options: Object,
  },
  watch: {
    labels() {
      this.loadChart();
    },
    datasets() {
      this.loadChart();
    },
    options() {
      this.loadChart();
    },
  },
  methods: {
    loadChart() {
      this.renderChart(
        {
          labels: this.labels,
          datasets: this.datasets,
        },
        this.options
      );
    },
  },
  mounted() {
    this.loadChart();
  },
};
</script>