<template>
  <ListComponent title="Master Configuration">
    <template #list>
      <v-form ref="masterConfigurationForm" v-model="masterConfigurationForm">
        <v-row class="ma-0">
          <v-col cols="12" sm="6" md="4" lg="3" class="px-1">
            <InputField
              hide-details="auto"
              type="text"
              v-model="formDetails.smtp_email"
              :rules="[
                (val) => !!val || 'Email is Required!',
                (val) => /.+@.+\..+/.test(val) || 'Email must be valid',
              ]"
              label="Company Email *"
              required
              :error-messages="formErrors.smtp_email"
              @input="delete formErrors.smtp_email"
            />
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" class="px-1">
            <InputField
              :type="isTextInput ? 'text' : 'password'"
              :rules="
                !editMode ? [(val) => !!val || 'Password is required!'] : []
              "
              :label="!editMode ? 'Password*' : 'Password'"
              required
              hide-details="auto"
              v-model="formDetails.smtp_password"
              @click:append="isTextInput = !isTextInput"
              :error-messages="formErrors.smtp_password"
              @input="delete formErrors.smtp_password"
            />
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" class="px-1">
            <InputField
              type="number"
              label="Port"
              hide-details="auto"
              :rules="[(val) => !!val || 'Port is Required!']"
              v-model="formDetails.smtp_port"
              :error-messages="formErrors.smtp_port"
              @input="delete formErrors.smtp_port"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" class="px-1">
            <InputField
              type="text"
              label="Host"
              hide-details="auto"
              :rules="[(val) => !!val || 'Host is Required!']"
              v-model="formDetails.smtp_host"
              :error-messages="formErrors.smtp_host"
              @input="delete formErrors.smtp_host"
            />
          </v-col>

          <v-col cols="3"  class="Form-Columns">
            <v-switch
              hide-details="auto"
              :label="`Email notifications : ${
                formDetails.email_notifications == 'Yes' ? 'Yes' : 'No'
              }`"
              true-value="Yes"
              false-value="No"
              class="mt-1"
              v-model="formDetails.email_notifications"
              :error-messages="formErrors.email_notifications"
              @change="delete formErrors.email_notifications"
            ></v-switch>
          </v-col>

          <v-col cols="3"  class="Form-Columns">
            <v-switch
              hide-details="auto"
              :label="`Whatsapp notifications : ${
                formDetails.whatsapp_notifications == 'Yes' ? 'Yes' : 'No'
              }`"
              true-value="Yes"
              false-value="No"
              class="mt-1"
              v-model="formDetails.whatsapp_notifications"
              :error-messages="formErrors.whatsapp_notifications"
              @change="delete formErrors.whatsapp_notifications"
            ></v-switch>
          </v-col>

          <v-col cols="12" class="px-1">
            <v-btn
              color="primary"
              @click="submitForm"
              :disabled="!masterConfigurationForm"
            >
              Save Changes
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </ListComponent>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import ListComponent from "@/components/General/ListComponent.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
  encryptText,
} from "@/utils/functions";

export default {
  components: {
    InputField,
    MultiSelect,
    ListComponent
  },

  data() {
    return {
      formDetails: {},
      formErrors: {},
      isTextInput: false,
      editMode: false,
      masterConfigurationForm: true,
    };
  },

  computed: {
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (!obj) {
        return null;
      } else {
        return obj;
      }
    },
  },

  methods: {
    resetForm() {
      this.formDetails = {};
      this.formErrors = {};
      this.isTextInput = false;
      this.$refs.masterConfigurationForm.resetValidation();
    },

    getMasterConfiguration() {
      this.$bus.$emit("showLoader", true);
      this.$api.company
        .getMasterConfiguration()
        .then((res) => {
          this.formDetails = {
            ...res.data,
          };
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
        });
    },

    submitForm(payload = {}) {
      this.$bus.$emit("showLoader", true);
      payload = { ...this.formDetails };
      if (payload && payload.smtp_password) {
        payload.smtp_password = encryptText(payload.smtp_password);
      }
      this.$api.company
        .updateMasterConfiguration(payload)
        .then(async (res) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Configuration Updated Successfully!",
            color: "success",
          });
        })
        .catch((err) => {
          this.formErrors = err.data;
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't Update Configuration!",
            color: "error",
          });
        });
    },
  },

  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("configuration");
      if (!permissions || !permissions.add) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.getMasterConfiguration();
      }
    }
  },
};
</script>
