<template>
  <div>
    <v-form v-model="isValid" ref="podForm">
      <v-row no-gutters class="py-3 px-4">
        <v-col cols="12" class="py-5">
          <v-row>
            <v-col class="d-flex align-center">
              <h4 class="text-capitalize">{{$t('uploaded pods')}}</h4>
            </v-col>
            <v-col class="align-right">
              <v-file-input
                ref="podFileInput"
                :clearable="true"
                hide-details
                type="file"
                prepend-icon=""
                prepend-inner-icon="mdi-attachment"
                v-model="legPodFile"
                accept="image/*"
                outlined
                dense
                @change="getFileData($event)"
              ></v-file-input>
              <v-btn
                @click="uploadPod"
                block
                class="primary text-capitalize my-1"
                :disabled="!isValid || !legPodFile"
                style="width: 100px"
                >{{$t('upload')}}</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" v-for="(legPod, i) in legPodData" :key="i">
          <v-card
            elevation="0"
            style="border: 1px solid #0000004f !important"
            class="pa-2"
          >
            <v-row>
              <v-col cols="4">
                <img
                  class="Brand-Logo"
                  alt="Brand Logo"
                  style="height: 100px; width: 100px"
                  :src="`${legPod.pod}`"
                />
              </v-col>
              <v-col class="d-flex align-center">
                <h5 class="text-left">{{ legPod.created }}</h5>
              </v-col>
              <v-col cols="2" class="d-flex align-center justify-end">
                <a :href="legPod.pod" target="_blank">
                  <v-btn elevation="2" small> {{$t('view')}} </v-btn>
                </a>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  props: {
    podListDialog: {
      type: Boolean,
    },
    selectedLegId: {
      required: true,
      default: null,
    },
  },
  data() {
    return {
      legPodData: [],
      legPodFile: null,
      isValid: true,
    };
  },
  watch: {
    podListDialog(val) {
      if (val) {
        this.getDriverAppLegPODs();
      }
    },
  },
  methods: {
    checkFileFormat(event) {
      const name = event.name;
      const lastDot = name.lastIndexOf(".");
      const ext = name.substring(lastDot + 1);
      let allowed = /(\jpg|\jpeg|\pdf|\png)$/i;
      if (!allowed.exec(ext)) {
        this.$nextTick(() => {
          this.uploadFiles = null;
        });
        return false;
      } else {
        return true;
      }
    },
    getFileData(fileInput) {
      if (fileInput) {
        if (!this.checkFileFormat(fileInput)) {
          alert("This file format is not supported!");
        }
      }
    },

    uploadPod() {
      this.$bus.$emit("showLoader", true);

      let newForm = new FormData();
      newForm.append("pod", this.legPodFile);
      newForm.append("leg", this.selectedLegId);
      this.$api.driverApp
        .uploadPOD(newForm)
        .then((response) => {
          this.$bus.$emit("showLoader", false);
          this.$refs.podFileInput.reset();
          this.getDriverAppLegPODs();
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't upload POD!",
            color: "error",
            hasActions: true,
          });
        });
    },
    getDriverAppLegPODs(params = {}) {
      if (this.selectedLegId) {
        this.$bus.$emit("showLoader", true);
        params.leg = this.selectedLegId;
        this.$api.driverApp
          .getDriverAppLegPODs(params)
          .then((res) => {
            this.legPodData = res.data;
            this.$bus.$emit("showLoader", false);
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't get driver's leg pod details!",
              color: "error",
              hasActions: true,
            });
          });
      }
    },
  },
  mounted() {
    this.getDriverAppLegPODs();
  },
};
</script>
  
  
<style scoped lang="scss">
.card-outlined {
  border: 1px solid #0000004f !important;
  border-radius: 6px;
}
</style>