<template>
  <ListComponent title="Tariff">
    <template #list>
      <v-row class="ma-0">
        <v-col cols="4" class="px-0 pt-0 pb-2 d-flex align-center">
          <v-text-field
            label="Search"
            outlined
            dense
            append-icon="mdi-magnify"
            clearable
            hide-details="auto"
            v-model="filters.search"
            @input="searchContractList"
          ></v-text-field>

          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                depressed
                icon
                v-on="on"
                v-bind="attrs"
                @click="showFilterDialog = true"
              >
                <v-badge
                  dot
                  color="primary"
                  :value="Object.keys(filters).length"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-badge>
              </v-btn>
            </template>

            <span v-if="Object.keys(filters).length == 0">Filters</span>

            <v-row v-else no-gutters>
              <v-col cols="12" class="font-weight-bold">Applied Filters</v-col>

              <v-col cols="12">
                <span
                  v-for="(value, key) in filters"
                  :key="key"
                  class="text-capitalize"
                >
                  {{ key.replace("_", " ") }}
                  <br />
                </span>
              </v-col>
            </v-row>
          </v-tooltip>

          <Columns :headers="headers" :gridColInstance="gridColumnApi" />
        </v-col>

        <v-col cols="4" class="px-0 pt-0 pb-2">
          <v-tabs height="35" v-model="tab" centered>
            <v-tabs-slider></v-tabs-slider>
            <v-tab> Pending </v-tab>
            <v-tab> Active </v-tab>
            <v-tab> Rejected </v-tab>
            <v-tab> Closed </v-tab>
          </v-tabs>
        </v-col>

        <v-col
          v-if="totalItems > 0"
          cols="4"
          class="px-0 pt-0 pb-2 d-flex justify-end"
        >
          <Pagination
            :pageNo="pageNo"
            :totalItems="totalItems"
            :pageSize="itemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>

        <v-col cols="12" class="pa-0">
          <AgGridVue
            style="width: 100%; height: calc(100vh - 172px)"
            class="ag-theme-alpine"
            id="myGrid"
            :columnDefs="headers"
            :context="context"
            @grid-ready="onGridReady"
            @columnMoved="resizeGrid"
            @columnVisible="resizeGrid"
            :defaultColDef="defaultColDef"
            :grid-options="gridOptions"
            :suppressDragLeaveHidesColumns="true"
            :rowData="allContractList"
          ></AgGridVue>
        </v-col>
      </v-row>

      <DetailsParent :resizable="true" v-model="displayDrawer">
        <template #details>
          <CustomerContractDetails :contractId="selectedContractId" />
        </template>
      </DetailsParent>

      <CustomerContractFilter
        v-model="showFilterDialog"
        :bindingObject="filters"
        @resetFilters="resetFilters"
        @applyFilters="itemsPerPageChanged(20)"
      />
    </template>
  </ListComponent>
</template>

<script>
import Columns from "@/components/General/Columns.vue";
import ListComponent from "@/components/General/ListComponent.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";
import CustomerContractDetails from "@/components/ModuleBased/details/CustomerContractDetails.vue";
import DetailsParent from "@/components/General/DetailsParent.vue";
import UserContractButton from "@/components/AgGridComponents/UserContractButton.vue";
import CustomerContractFilter from "@/components/ModuleBased/dialogs/filters/CustomerContractFilter.vue";
import CustomHeader from "@/components/AgGridComponents/General/CustomHeader.vue";
import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    ListComponent,
    Columns,
    AgGridVue,
    Pagination,
    CustomerContractDetails,
    agColumnHeader: CustomHeader,
    DetailsParent,
    UserContractButton,
    CustomerContractFilter,
  },
  data() {
    return {
      sorting: {},
      headers: [
        {
          headerName: "Tariff ID",
          field: "contract_id",
          sortable: true,
          minWidth: 100,
        },
        {
          headerName: "Lane",
          field: "customer_contract_lane_title",
          tooltipField: "customer_contract_lane_title",
          sortable: true,
          minWidth: 400,
        },
        {
          headerName: "Start Date",
          field: "start_date",
          sortable: true,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value);
          },
          minWidth: 120,
        },
        {
          headerName: "End Date",
          field: "end_date",
          sortable: true,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value);
          },
          minWidth: 120,
        },
        {
          headerName: "Job Type",
          field: "job_type",
          sortable: true,
          minWidth: 100,
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: "UserContractButton",
          pinned: "right",
          width: 100,
        },
      ],
      allContractList: [],

      // details vars
      selectedContractId: null,
      displayDrawer: false,

      // pagination vars
      pageNo: 1,
      itemsPerPage: 20,
      totalItems: 0,

      // Filter vars
      filters: {},
      showFilterDialog: false,

      headerSelected: [],
      tab: null,

      // ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (!obj) {
        return null;
      } else {
        return obj;
      }
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },
  watch: {
    tab() {
      this.getContractList({ offset: 0, limit: this.itemsPerPage });
      this.displayDrawer = false;
    },
  },
  methods: {
    applyGridSort(key, type) {
      if (this.filters && !this.filters.ordering) {
        this.filters.ordering = [];
      }

      if (type == null) {
        delete this.sorting[key];
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(`-${key}`),
          1
        );
      } else if (type == "asc") {
        this.filters.ordering.push(key);
      } else if (type == "desc") {
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(key),
          1,
          `-${key}`
        );
      }
      if (this.filters.ordering.length == 0) {
        delete this.filters.ordering;
      }
      this.itemsPerPageChanged(20);
    },
    resizeGrid() {
      if (this.gridOptions && "api" in this.gridOptions) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },
    resetFilters() {
      this.filters = {};
      this.itemsPerPageChanged(20);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },
    searchContractList() {
      this.pageNo = 1;
      this.getContractList();
    },
    headersChanged(value) {
      this.headerSelected = value;
      this.resizeGrid();
    },
    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getContractList();
    },
    prevPage() {
      this.pageNo--;
      this.getContractList();
    },
    nextPage() {
      this.pageNo++;
      this.getContractList();
    },
    getContractList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        customer: this.currentProfile.company,
        offset: this.offset,
        limit: this.itemsPerPage,
        status:
          this.tab == 0
            ? "Pending"
            : this.tab == 1
            ? "Active"
            : this.tab == 2
            ? "Rejected"
            : "Closed",
        ...params,
        ...this.filters,
      };
      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }
      this.$api.customerContract
        .getCustomerContractList(params)
        .then((res) => {
          this.allContractList = res.data;
          this.totalItems = res.count;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    viewContract(id) {
      this.selectedContractId = id;
      this.displayDrawer = true;
    },
  },
  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("contract");
      if (!permissions || !permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.getContractList();
      }
    }
  },
};
</script>
