var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-col',{staticClass:"px-0 pb-0",attrs:{"cols":"12"}},[_c('v-card',{attrs:{"height":"100%"}},[_c('v-card-text',{staticClass:"chatDialog Chat-Height-Class",attrs:{"id":"chatCard"},on:{"scroll":_vm.getOldChats}},[_vm._l((_vm.messageList),function(msg,i){return _c('div',{key:i,staticClass:"message-group"},[(msg.message_format == 'Text')?_c('v-row',{staticClass:"msg-container",attrs:{"justify":`${msg.sender_type == _vm.userType ? 'end' : 'start'}`,"no-gutters":""}},[_c('div',{staticClass:"message rounded-lg pa-4",class:`${
                msg.sender_type == _vm.userType
                  ? 'rounded-br-0 grey lighten-2 send'
                  : 'rounded-bl-0 primary lighten-4 recieved'
              }`},[_vm._v(" "+_vm._s(msg.message)+" ")])]):_vm._e(),(msg.message_format == 'Image')?_c('v-row',{staticClass:"msg-container",attrs:{"justify":`${msg.sender_type == _vm.userType ? 'end' : 'start'}`,"no-gutters":""}},[_c('div',{staticClass:"message rounded-lg pa-4",class:`${
                msg.sender_type == _vm.userType
                  ? 'rounded-br-0 grey lighten-2 send'
                  : 'rounded-bl-0 primary lighten-4 recieved'
              }`},[_c('img',{attrs:{"width":"100%","src":msg.attachment_url,"alt":"File"}})])]):_vm._e(),(msg.message_format == 'Document')?_c('v-row',{staticClass:"msg-container",attrs:{"justify":`${msg.sender_type == _vm.userType ? 'end' : 'start'}`,"no-gutters":""}},[_c('div',{staticClass:"message rounded-lg pa-4",class:`${
                msg.sender_type == _vm.userType
                  ? 'rounded-br-0 grey lighten-2 send'
                  : 'rounded-bl-0 primary lighten-4 recieved'
              }`},[_c('v-list',{staticClass:"pa-0",attrs:{"dense":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-list-item',_vm._g(_vm._b({staticClass:"pa-0",class:`${
                        msg.sender_type == _vm.userType
                          ? 'grey lighten-2'
                          : 'primary lighten-4'
                      }`,attrs:{"dense":""},on:{"click":function($event){return _vm.downloadFile(msg.attachment_url)}}},'v-list-item',attrs,false),on),[_c('v-list-item-avatar',[_c('v-icon',{staticClass:"grey lighten-1",attrs:{"size":"20","dark":""}},[_vm._v(" mdi-file ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.getFileName(msg.attachment_url))}})],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getFileName(msg.attachment_url)))])])],1)],1)]):_vm._e(),(msg.message_format == 'Audio')?_c('v-row',{staticClass:"msg-container",attrs:{"justify":`${msg.sender_type == _vm.userType ? 'end' : 'start'}`,"no-gutters":""}},[_c('div',{staticClass:"message rounded-lg pa-4",class:`${
                msg.sender_type == _vm.userType
                  ? 'rounded-br-0 grey lighten-2 send'
                  : 'rounded-bl-0 primary lighten-4 recieved'
              }`},[_c('audio',{attrs:{"controls":"","src":msg.attachment_url}},[_vm._v(" Your browser does not support the "),_c('code',[_vm._v("audio")]),_vm._v(" element. ")])])]):_vm._e(),_c('div',{staticClass:"text-caption",class:`${msg.sender_type == _vm.userType ? 'text-right' : ''}`},[_vm._v(" "+_vm._s(msg.sender_type)+" | "+_vm._s(msg.sender_name)+" | "+_vm._s(_vm._f("formatDate")(msg.created,true))+" ")])],1)}),_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"fileSelector",attrs:{"type":"file","accept":"audio/mp3,audio/aac,audio/wav,image/png,image/jpg,image/jpeg,.doc,.docx,.pdf,.xlsx,.xls"},on:{"change":_vm.selectionChanged}})],2),_c('v-card-actions',[_c('v-btn',{staticClass:"mx-2",attrs:{"icon":"","depressed":"","disabled":_vm.tripDetails.status == 'Completed' ||
            Object.keys(_vm.tripDetails).length === 0},on:{"click":_vm.toggleRecording}},[_c('v-icon',[_vm._v("mdi-microphone")])],1),(_vm.recording)?_c('span',[_vm._v(" Recording ... ")]):_vm._e(),(_vm.audio)?_c('audio',{attrs:{"controls":"","src":_vm.audioPreview}},[_vm._v(" Your browser does not support the "),_c('code',[_vm._v("audio")]),_vm._v(" element. ")]):_vm._e(),(!_vm.recording && !_vm.audio)?_c('v-text-field',{attrs:{"placeholder":"Type Here...","prepend-inner-icon":"mdi-attachment","filled":"","rounded":"","hide-details":"","disabled":_vm.tripDetails.status == 'Completed' ||
            Object.keys(_vm.tripDetails).length === 0,"readonly":!!_vm.selectedFile,"loading":_vm.uploadLoading},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.sendMessage.apply(null, arguments)},"click:prepend-inner":function($event){return _vm.triggerFileSelect()}},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}):_vm._e(),(_vm.selectedFile || _vm.audio || _vm.message)?_c('v-btn',{staticClass:"mx-2",attrs:{"icon":"","depressed":""},on:{"click":function($event){return _vm.resetMessage()}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e(),_c('v-btn',{staticClass:"mx-2",attrs:{"disabled":_vm.recording ||
            _vm.tripDetails.status == 'Completed' ||
            Object.keys(_vm.tripDetails).length === 0,"icon":"","depressed":""},on:{"click":function($event){return _vm.sendMessage()}}},[_c('v-icon',[_vm._v("mdi-send")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }