var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.showRenewDialog),callback:function ($$v) {_vm.showRenewDialog=$$v},expression:"showRenewDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"secondary white--text"},[_c('span',[_vm._v("Renew Contract")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.showRenewDialog = false;
          delete _vm.formData.start_date;
          delete _vm.formData.end_date;}}},[_c('v-icon',{staticClass:"white--text"},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pt-2"},[(_vm.showRenewDialog)?_c('v-form',{model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',{staticClass:"my-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('DateField',{ref:"dateField",attrs:{"inputProps":{
                dense: true,
                label: 'Start Date *',
                'hide-details': 'auto',
                rules: [(val) => !!val || 'Start Date is Required!'],
                'error-messages': _vm.errorObj.start_date,
              },"min":_vm.today},on:{"input":function($event){return _vm.$emit('removeFieldError')}},model:{value:(_vm.formData.start_date),callback:function ($$v) {_vm.$set(_vm.formData, "start_date", $$v)},expression:"formData.start_date"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('DateField',{ref:"dateField",attrs:{"inputProps":{
                dense: true,
                label: 'End Date *',
                'hide-details': 'auto',
                rules: [(val) => !!val || 'End Date is Required!'],
                'error-messages': _vm.errorObj.end_date,
              },"min":_vm.formData.start_date},on:{"input":function($event){return _vm.$emit('removeFieldError')}},model:{value:(_vm.formData.end_date),callback:function ($$v) {_vm.$set(_vm.formData, "end_date", $$v)},expression:"formData.end_date"}})],1)],1)],1):_vm._e()],1),_c('v-divider'),_c('v-card-actions',{staticClass:"d-flex justify-center"},[_c('v-btn',{attrs:{"depressed":"","color":"primary","large":"","disabled":!_vm.isValid},on:{"click":function($event){return _vm.$emit('submitRemarks')}}},[_c('span',[_vm._v("Submit")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }