import Blank from "@/layouts/blank.vue"

import AssetMaintenance from "@/pages/admin/integration/AssetMaintenance.vue"
import Company from "@/pages/admin/integration/Company.vue"
import Driver from "@/pages/admin/integration/Driver.vue"
import DriverLeave from "@/pages/admin/integration/DriverLeave.vue"

const ConfigurationsRoutes = [
  {
    path: "integration",
    name: "app-admin-integration",
    component: Blank,
    children: [
      {
        path: "/",
        redirect: "/app/admin/dashboard",
      },
      {
        path: "asset-maintenance",
        name: "app-admin-integration-asset-maintenance",
        component: AssetMaintenance,
      },
      {
        path: "company",
        name: "app-admin-integration-company",
        component: Company,
      },
      {
        path: "driver",
        name: "app-admin-integration-driver",
        component: Driver,
      },
      {
        path: "driver-leave",
        name: "app-admin-integration-driver-leave",
        component: DriverLeave,
      },
    ]
  },
]

export default ConfigurationsRoutes;