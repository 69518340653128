<template>
  <v-dialog max-width="65%" v-model="showFuelSurchargeForm">
    <v-card>
      <v-card-title class="secondary white--text py-2 px-4">
        <span v-if="editMode" class="text-capitalize">
          Edit Fuel Surcharge
        </span>
        <span v-else class="text-capitalize">Add Fuel Surcharge</span>
        <v-spacer></v-spacer>

        <v-btn
          icon
          @click="
            resetForm();
            $emit('closeFuelSurchargeForm');
          "
        >
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pa-2">
        <v-form
          ref="fuelSurchargeForm"
          v-if="showFuelSurchargeForm"
          v-model="isValid"
        >
          <v-row class="ma-0">
            <v-col cols="12" lg="4" class="Form-Columns">
              <DateField
                :inputProps="{
                  dense: true,
                  label: 'Start Date *',
                  'hide-details': 'auto',
                  rules: [(val) => !!val || 'Start Date is Required!'],
                  'error-messages': formErrors.start_date,
                }"
                :min="today"
                required
                v-model="formDetails.start_date"
                @input="
                  delete formErrors.start_date;
                  delete formDetails.end_date;
                  checkDates();
                "
              />
            </v-col>
            <v-col cols="12" lg="4" class="Form-Columns">
              <DateField
                :inputProps="{
                  dense: true,
                  label: 'End Date *',
                  'hide-details': 'auto',
                  rules: [(val) => !!val || 'End Date is Required!'],
                  'error-messages': formErrors.end_date,
                }"
                ref="endDateField"
                required
                disabled
                v-model="formDetails.end_date"
                @input="delete formErrors.end_date"
              />
            </v-col>
            <v-col cols="12" lg="4" class="Form-Columns">
              <InputField
                type="number"
                label="Surcharge (%)"
                hide-details="auto"
                v-model="formDetails.surcharge"
                :error-messages="formErrors.surcharge"
                @input="delete formErrors.surcharge"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          color="primary"
          depressed
          :disabled="!isValid"
          @click="submitForm"
        >
          Submit
        </v-btn>
        <v-btn
          class="ml-3"
          color="secondary"
          depressed
          @click="resetForm"
          v-if="!editMode"
        >
          Reset
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";
import moment from "moment";

export default {
  components: {
    InputField,
    DateField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    companyId: {
      type: [Number, Object],
      default: null,
    },
    fuelSurchargeId: {
      type: Number,
    },
  },
  computed: {
    showFuelSurchargeForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    return {
      formDetails: {},
      formErrors: {},
      today: new Date().toISOString().slice(0, 10),
      isValid: true,
    };
  },
  watch: {
    value(val) {
      if (val) {
        if (this.editMode && this.fuelSurchargeId != null) {
          this.getCompanyFuelSurchargeObject(this.fuelSurchargeId);
        }
      }
    },
  },
  methods: {
    checkDates() {
      if (this.formDetails.start_date) {
        let startDate = moment(this.formDetails.start_date, "YYYY-MM-DD");
        let lastDayOfMonth = moment(startDate)
          .endOf("month")
          .format("YYYY-MM-DD");
        this.formDetails.end_date = lastDayOfMonth;
        setTimeout(() => {
          const field = this.$refs.endDateField;
          if (field) {
            field.setDefaultDateTime();
          }
        }, 20);
      }
    },
    getCompanyFuelSurchargeObject(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.companyFuelSurcharge
        .getCompanyFuelSurchargeObject(id)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.formDetails = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    submitForm() {
      this.$bus.$emit("showLoader", true);
      let payload;

      if (!this.editMode) {
        payload = {
          ...this.formDetails,
          company: this.companyId,
        };
        if (payload.company && typeof payload.company != "number") {
          payload.company = this.companyId.id;
        }

        this.$api.companyFuelSurcharge
          .addCompanyFuelSurcharge(payload)
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Fuel surcharge created successfully!",
              color: "success",
            });
            this.$emit("refreshList");
            this.resetForm();
            this.showFuelSurchargeForm = false;
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            if (
              err.data &&
              err.data.non_field_errors &&
              err.data.non_field_errors.length
            ) {
              this.$bus.$emit("showToastMessage", {
                message: `${
                  err.data && err.data.non_field_errors
                    ? err.data.non_field_errors.join()
                    : "Could not add fuel surcharge"
                }`,
                color: "error",
              });
            } else {
              this.formErrors = err.data;
            }
          });
      } else {
        payload = {
          id: this.formDetails.id,
          data: this.formDetails,
        };
        this.$api.companyFuelSurcharge
          .updateCompanyFuelSurcharge(payload)
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Fuel surcharge updated successfully!",
              color: "success",
            });
            this.$emit("refreshList");
            this.resetForm();
            this.showFuelSurchargeForm = false;
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            if (
              err.data &&
              err.data.non_field_errors &&
              err.data.non_field_errors.length
            ) {
              this.$bus.$emit("showToastMessage", {
                message: `${
                  err.data && err.data.non_field_errors
                    ? err.data.non_field_errors.join()
                    : "Could not update fuel surcharge"
                }`,
                color: "error",
              });
            } else {
              this.formErrors = err.data;
            }
          });
      }
    },
    resetForm() {
      this.formDetails = {};
      if (this.$refs.fuelSurchargeForm) {
        this.$refs.fuelSurchargeForm.reset();
      }
    },
  },
};
</script>
