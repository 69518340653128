import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from "vuetify/es5/util/colors";
import ar from "vuetify/lib/locale/ar.js";
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

export default new Vuetify({
  customVariables: ["@/assets/variables.scss"],
  lang: {
    locales: { ar },
  },
  theme: {
    dark: false,
    themes: {
      dark: {
        primary: "#f07025",
        accent: colors.grey.darken3,
        secondary: colors.amber.darken3,
        info: colors.teal.lighten1,
        warning: colors.amber.base,
        error: colors.deepOrange.accent4,
        success: colors.green.accent3,
        heading: colors.grey.darken1,
        subHeading: colors.grey.darken3,
      },
      light: {
        primary: "#f07025",
        heading: "#ebedef",
        subHeading: "#f5f6f7",
      },
    },
  },
});
