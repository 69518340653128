<template>
  <v-dialog fullscreen v-model="showLegExportDialog">
    <v-card>
      <v-card-title class="secondary white--text rounded-0">
        <span> Advance Search / Export </span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="
            resetLegExportDialog(),
              (showLegExportDialog = false),
              (isSearchEnabled = true)
          "
        >
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="px-3 pb-3">
        <v-form ref="chargeForm" v-if="showLegExportDialog" v-model="isValid">
          <v-row class="mt-0 pa-1">
            <v-col cols="12" sm="6" md="4" lg="2" class="pa-1">
              <InputField
                label="DA No"
                :clearable="true"
                v-model="filters.da_no"
                hide-details="auto"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2" class="pa-1">
              <InputField
                label="Trip ID"
                :clearable="true"
                v-model="filters.trip_id"
                hide-details="auto"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2" class="pa-1">
              <InputField
                label="BOE"
                :clearable="true"
                v-model="filters.boe"
                hide-details="auto"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2" class="pa-1">
              <InputField
                label="Vessel No."
                :clearable="true"
                v-model="filters.da_vessel_no"
                hide-details="auto"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2" class="pa-1">
              <MultiSelect
                :showAll="false"
                :items="allCustomer"
                itemText="company_name"
                itemValue="id"
                label="Select Customer(s)"
                hide-details="auto"
                :multiple="true"
                :chips="true"
                chipWidthStyle="max-width: 3.7vw;"
                v-model="filters.customers"
              >
              </MultiSelect>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2" class="pa-1">
              <MultiSelect
                :showAll="false"
                :items="allTransporter"
                itemText="company_name"
                itemValue="id"
                label="Select Transporter(s)"
                hide-details="auto"
                :multiple="true"
                :chips="true"
                chipWidthStyle="max-width: 3.7vw;"
                v-model="filters.transporters"
              >
              </MultiSelect>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2" class="pa-1">
              <InputField
                label="Source Location"
                :clearable="true"
                v-model="filters.source_location_name"
                hide-details="auto"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2" class="pa-1">
              <InputField
                label="Destination Location"
                :clearable="true"
                v-model="filters.destination_location_name"
                hide-details="auto"
              />
            </v-col>
            <v-col cols="12" md="6" lg="2" class="pa-1">
              <DateField
                :inputProps="{
                  dense: true,
                  label: 'Start Date *',
                  clearable: true,
                  'hide-details': 'auto',
                }"
                v-model="filters.start_date"
                @input="
                  checkSearchButton();
                  checkDates();
                "
              />
            </v-col>
            <v-col cols="12" md="6" lg="2" class="pa-1">
              <DateField
                :inputProps="{
                  dense: true,
                  label: 'End Date *',
                  clearable: true,
                  'hide-details': 'auto',
                }"
                :min="filters.start_date"
                :max="lastEndDate ? lastEndDate : null"
                v-model="filters.end_date"
                @input="checkSearchButton()"
              />
            </v-col>
            <v-col cols="auto" class="pa-1">
              <div class="d-flex">
                <v-menu
                  open-on-hover
                  left
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-on="on" v-bind="attrs" class="mx-2">
                      mdi-information
                    </v-icon>
                  </template>
                  <v-card>
                    <v-card-title
                      class="secondary py-1 text-subtitle-1 white--text font-weight-medium"
                    >
                      Filter Information
                    </v-card-title>
                    <v-card-text
                      class="mt-2"
                    >
                      <table class="Normal-Table">
                        <tbody>
                          <tr>
                            <th>Start Date</th>
                            <th class="px-1">:</th>
                            <td>Movement Date of Leg</td>
                          </tr>
                          <tr>
                            <th>End Date</th>
                            <th class="px-1">:</th>
                            <td>Movement Date of Leg</td>
                          </tr>
                        </tbody>
                      </table>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="1" class="pa-1">
              <InputField
                label="Vehicle"
                :clearable="true"
                v-model="filters.trip_vehicle"
                hide-details="auto"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2" class="pa-1">
              <InputField
                label="Driver"
                :clearable="true"
                v-model="filters.trip_driver"
                hide-details="auto"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2" class="pa-1">
              <InputField
                label="Trailer 1"
                :clearable="true"
                v-model="filters.trip_trailer_1"
                hide-details="auto"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2" class="pa-1">
              <InputField
                label="Trailer 2"
                :clearable="true"
                v-model="filters.trip_trailer_2"
                hide-details="auto"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2" class="pa-1">
              <MultiSelect
                :showAll="false"
                :items="allJobType"
                itemText="display_name"
                itemValue="value"
                label="Select Job Type"
                :clearable="true"
                hide-details="auto"
                v-model="filters.job_type"
              >
              </MultiSelect>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="2" class="pa-1">
              <InputField
                label="Container No"
                :clearable="true"
                v-model="filters.container_no"
                hide-details="auto"
              />
            </v-col>
            <v-col cols="12" lg="4" class="pa-1">
              <v-btn
                depressed
                color="primary"
                class="mr-2"
                @click="searchTrips"
              >
                Search
              </v-btn>
              <v-btn
                depressed
                color="primary"
                class="mr-2"
                :disabled="
                  !(
                    allLegList &&
                    allLegList.length &&
                    filters.start_date &&
                    filters.end_date
                  )
                "
                @click="exportLegData"
              >
                Export
              </v-btn>
              <v-btn
                depressed
                color="secondary"
                class="mr-2"
                @click="resetLegExportDialog()"
              >
                Reset
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-text class="pt-0 px-0 pb-2">
        <v-divider></v-divider>
      </v-card-text>
      <v-card-text class="px-3 pb-3">
        <v-row>
          <v-col cols="12" class="pt-3 pb-1">
            <AgGridVue
              style="width: 100%; height: calc(100vh - 270px)"
              class="small-table ag-theme-alpine"
              id="advancedSearchGrid"
              :columnDefs="headers"
              :context="context"
              @grid-ready="onGridReady"
              :defaultColDef="defaultColDef"
              :grid-options="gridOptions"
              :suppressDragLeaveHidesColumns="true"
              :rowData="allLegList"
            ></AgGridVue>
          </v-col>

          <v-col v-if="totalLegs > 0" cols="12" class="d-flex justify-end py-0">
            <Pagination
              :pageNo="pageNo"
              :totalItems="totalLegs"
              :pageSize="itemsPerPage"
              @itemsPerPageChange="itemsPerPageChanged"
              @prevPage="prevPage"
              @nextPage="nextPage"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";
import { formatDataKeys } from "@/utils/functions";
import { utils, write } from "xlsx";

export default {
  components: {
    DateField,
    AgGridVue,
    InputField,
    Pagination,
    MultiSelect,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    legDetails: {
      default: false,
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.getCustomerList();
        this.getTransporterList();
      } else {
        this.resetLegExportDialog();
      }
    },
    filters(val) {
      if (val.start_date && val.end_date) {
        this.isSearchEnabled = true;
      }
    },
  },
  computed: {
    showLegExportDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    context() {
      return { parentComponent: this };
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },
  data() {
    return {
      sorting: {},
      formErrors: {},
      isValid: true,
      lastEndDate: null,
      editMode: false,
      isSearchEnabled: false,
      allCustomer: [],
      allJobType: ["Import", "Export", "FTL", "LTL", "Empty"],
      allTransporter: [],
      allLegList: [],
      filters: {
        transporters: [],
        customers: [],
      },
      defaultFilters: {},
      // pagination vars
      pageNo: 1,
      itemsPerPage: 100,
      totalLegs: 100,
      containerCentric: "No",
      tab: null,

      // ag-grid vars
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        rowStyle: {
          "font-size": "10px",
        },
        rowHeight: 35,
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
      },
      headers: [
        {
          headerName: "Depot",
          field: "depot",
          tooltipField: "depot",
          sortable: true,
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "DA",
          field: "da_no",
          sortable: true,
          minWidth: 65,
          tooltipField: "da_status",
          cellStyle: (params) => {
            let style = {
              backgroundColor: params.data.status_color,
            };

            if (this.daPermissions && this.daPermissions.view) {
              style.textDecoration = "underline";
            }
            return style;
          },
        },

        {
          headerName: "Customer",
          field: "customer_name",
          sortable: true,
          minWidth: 65,
          tooltipField: "customer_name",
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },

        {
          headerName: "Haz",
          field: "is_hazmat",
          sortable: true,
          tooltipField: "is_hazmat",
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },

        {
          headerName: "Plan/Sequence",
          field: "manual_sequence",
          sortable: true,
          minWidth: 30,
          tooltipField: "manual_sequence",
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Movement Date",
          field: "movement_date",
          sortable: true,
          minWidth: 70,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value);
          },
          tooltipValueGetter: (params) => {
            return this.$globals.formatDate(params.value);
          },
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Movement Date",
          field: "movement_time",
          sortable: true,
          tooltipField: "da_movement_time",
          minWidth: 40,
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },

        {
          headerName: "ETA Time",
          field: "leg_eta_time",
          tooltipField: "leg_eta_time",
          sortable: true,
          minWidth: 50,
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Source",
          field: "source_location_code",
          sortable: true,
          minWidth: 80,
          tooltipField: "source_address",
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Mid",
          field: "mid_location_code",
          sortable: true,
          minWidth: 80,
          tooltipField: "mid_address",
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Destination",
          field: "destination_location_code",
          sortable: true,
          minWidth: 80,
          tooltipField: "destination_address",
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Vehicle",
          field: "vehicle_number",
          tooltipField: "vehicle_number",
          minWidth: 50,
          sortable: true,
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Driver",
          field: "driver_name",
          tooltipField: "driver_name",
          minWidth: 45,
          sortable: true,
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Trailer 1",
          field: "trailer_1_number",
          tooltipField: "trailer_1_number",
          sortable: true,
          minWidth: 52,
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Trailer 2",
          field: "trailer_2_number",
          tooltipField: "trailer_2_number",
          minWidth: 52,
          sortable: true,
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Trip/Leg Status",
          field: "status",
          minWidth: 65,
          tooltipField: "status",
          sortable: true,
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Container Type(s)",
          field: "leg_container_details.types",
          tooltipField: "leg_container_details.types",
          minWidth: 100,
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Container Count",
          field: "leg_container_count",
          tooltipField: "leg_container_count",
          minWidth: 100,
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Container No(s)",
          field: "leg_container_details.numbers",
          tooltipField: "leg_container_details.numbers",
          minWidth: 100,
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },

        {
          headerName: "Container Weight(s)",
          field: "leg_container_details.weights",
          tooltipField: "leg_container_details.weights",
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Seal No(s)",
          field: "leg_container_details.seal_numbers",
          tooltipField: "leg_container_details.seal_numbers",
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: `Customer Rate (${this.$globals.currency})`,
          field: "customer_rate",
          minWidth: 45,
          tooltipField: "customer_contract_id",
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },

        {
          headerName: "DA Remarks",
          field: "da_remarks",
          tooltipField: "da_remarks",

          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Company",
          field: "company_name",
          sortable: true,
          minWidth: 80,
          tooltipField: "company_name",
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Sub Hire",
          field: "is_sub_hire",
          sortable: true,
          minWidth: 60,

          cellStyle: (params) => {
            if (params.data && params.data.sub_hire) {
              return {
                fontWeight: "bold",
                backgroundColor: params.data.status_color,
              };
            }
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Sub Hire ID",
          field: "sub_hire_no",
          sortable: true,
          minWidth: 80,

          cellStyle: (params) => {
            if (params.data && params.data.sub_hire) {
              return {
                fontWeight: "bold",
                backgroundColor: params.data.status_color,
              };
            }
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Job Type",
          field: "job_type",
          tooltipField: "job_type",
          minWidth: 60,
          sortable: true,
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },

        {
          headerName: "Start Time",
          field: "leg_start_date_time",
          tooltipValueGetter: (params) => {
            return this.$globals.formatDate(params.value, true);
          },
          sortable: true,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value, true);
          },
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Finish Time",
          field: "leg_end_date_time",
          tooltipValueGetter: (params) => {
            return this.$globals.formatDate(params.value, true);
          },
          sortable: true,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value, true);
          },
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },

        {
          headerName: `Transporter Rate (${this.$globals.currency})`,
          field: "rate",
          tooltipField: "transporter_contract_id",
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "BOE",
          field: "boe",
          sortable: true,
          tooltipField: "boe",
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },

        {
          headerName: "Leg",
          field: "leg_id",
          minWidth: 75,
          tooltipField: "leg_id",
          sortable: true,
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },

        {
          headerName: "Trip",
          field: "trip_id",
          tooltipField: "trip_status",
          minWidth: 70,
          sortable: true,
          cellStyle: (params) => {
            let style = {
              backgroundColor: params.data.status_color,
            };

            if (this.permissions && this.permissions.change) {
              style.textDecoration = "underline";
            }
            return style;
          },
        },

        {
          headerName: "Branch",
          field: "branch",
          sortable: true,
          minWidth: 50,
          tooltipField: "branch",
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Slot Start",
          field: "slot_start",
          sortable: true,
          tooltipValueGetter: (params) => {
            return this.$globals.formatDate(params.value, true);
          },
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value, true);
          },
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Slot End",
          sortable: true,
          field: "slot_end",
          tooltipValueGetter: (params) => {
            return this.$globals.formatDate(params.value, true);
          },
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value, true);
          },
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },

        {
          headerName: "Gen",
          field: "has_genset",
          tooltipField: "has_genset",
          sortable: true,
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
      ],
    };
  },
  methods: {
    checkSearchButton() {
      if (this.filters.start_date && this.filters.end_date) {
        this.isSearchEnabled = true;
      } else {
        this.isSearchEnabled = false;
      }
    },
    checkDates() {
      if (this.filters.start_date) {
        let currentDate = new Date(this.filters.start_date);
        let endDate = currentDate;
        endDate.setDate(currentDate.getDate() + 5);
        this.lastEndDate = endDate.toISOString().slice(0, 10);
        this.isSearchEnabled = true;
      } else {
        this.isSearchEnabled = false;
        delete this.filters.end_date;
      }
    },
    resizeGrid() {
      if (this.gridOptions && "api" in this.gridOptions) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },

    getLegsList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        offset: this.offset,
        limit: this.itemsPerPage,
        ...params,
        ...this.filters,
        ...this.defaultFilters,
      };
      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }

      if ("transporters" in params && params.transporters.length > 0) {
        params = { ...params, transporters: params.transporters.join(",") };
      }
      if ("customers" in params && params.customers.length > 0) {
        params = { ...params, customers: params.customers.join(",") };
      }

      let function_name = "getLegList";

      if (this.containerCentric === "Yes") {
        function_name = "getLegContainerList";
      }

      this.$api.trip[function_name](params)
        .then((res) => {
          this.allLegList = res.data;
          this.totalLegs = res.count;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    exportLegData(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        export: true,
        limit: "all",
        ...params,
        ...this.filters,
      };
      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }

      if ("transporters" in params && params.transporters.length > 0) {
        params = { ...params, transporters: params.transporters.join(",") };
      }
      if ("customers" in params && params.customers.length > 0) {
        params = { ...params, customers: params.customers.join(",") };
      }

      let function_name = "getLegList";

      if (this.containerCentric === "Yes") {
        function_name = "getLegContainerList";
      }

      this.$api.trip[function_name](params)
        .then(async (res) => {
          this.exportLegListData = res.data;
          const data = await formatDataKeys(
            this.exportLegListData,
            "_",
            " ",
            "uppercase"
          );
          const legWorkBookName = `LEG_DATA_${new Date()
            .toISOString()
            .slice(0, 10)}`;

          var wb = utils.book_new();
          wb.Props = {
            Title: legWorkBookName,
            CreatedDate: new Date(),
          };

          wb.SheetNames.push("Sheet 1");
          const ws = utils.json_to_sheet(data);

          wb.Sheets["Sheet 1"] = ws;
          const wbout = write(wb, { bookType: "xlsx", type: "binary" });
          const blob = new Blob([this.s2ab(wbout)], {
            type: "application/octet-stream",
          });
          this.download(blob, legWorkBookName);
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },
    searchTrips() {
      this.pageNo = 1;
      this.getLegsList();
    },
    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getLegsList();
    },
    prevPage() {
      this.pageNo--;
      this.getLegsList();
    },
    nextPage() {
      this.pageNo++;
      this.getLegsList();
    },
    applyGridSort(key, type) {
      if (this.filters && !this.filters.ordering) {
        this.filters.ordering = [];
      }

      if (type == null) {
        delete this.sorting[key];
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(`-${key}`),
          1
        );
      } else if (type == "asc") {
        this.filters.ordering.push(key);
      } else if (type == "desc") {
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(key),
          1,
          `-${key}`
        );
      }
      if (this.filters.ordering.length == 0) {
        delete this.filters.ordering;
      }
      this.itemsPerPageChanged(50);
    },
    getCustomerList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        company_type: "Customer",
        limit: "all",
      };
      this.$api.company
        .getCompanyList(params)
        .then((res) => {
          this.allCustomer = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getTransporterList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        company_type: "Transporter",
        limit: "all",
      };
      this.$api.company
        .getCompanyList(params)
        .then((res) => {
          this.allTransporter = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    },
    download(blob, legWorkBookName) {
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      const fileName = ` ${legWorkBookName}.xlsx`;

      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    },
    resetLegExportDialog() {
      this.filters = {
        transporters: [],
        customers: [],
      };
      this.isSearchEnabled = false;
      this.allLegList = null;
    },
  },
};
</script>
