var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.showContractForm),callback:function ($$v) {_vm.showContractForm=$$v},expression:"showContractForm"}},[_c('v-card',[_c('v-card-title',{staticClass:"secondary white--text px-4 py-2"},[_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.editMode ? "Update" : "Add")+" Tariffs ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.resetForm(), (_vm.showContractForm = false)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pt-2"},[(_vm.showContractForm)?_c('v-form',{ref:"CustomerContractCreationForm",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"Form-Columns",attrs:{"cols":"12"}},[_c('DateField',{attrs:{"inputProps":{
                dense: true,
                label: 'Start Date *',
                'hide-details': 'auto',
                rules: [(val) => !!val || 'Start Date is Required!'],
                'error-messages': _vm.formErrors.start_date,
              },"min":_vm.today,"required":""},on:{"input":function($event){delete _vm.formErrors.start_date}},model:{value:(_vm.childFormDetails.start_date),callback:function ($$v) {_vm.$set(_vm.childFormDetails, "start_date", $$v)},expression:"childFormDetails.start_date"}})],1),_c('v-col',{staticClass:"Form-Columns",attrs:{"cols":"12"}},[_c('InputField',{attrs:{"type":"text","label":"Customer Remarks *","required":"","rules":[(val) => !!val || 'Remarks is Required!'],"hide-details":"auto","error-messages":_vm.formErrors.customer_remarks},on:{"input":function($event){delete _vm.formErrors.customer_remarks}},model:{value:(_vm.childFormDetails.customer_remarks),callback:function ($$v) {_vm.$set(_vm.childFormDetails, "customer_remarks", $$v)},expression:"childFormDetails.customer_remarks"}})],1)],1)],1):_vm._e()],1),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"primary","depressed":"","disabled":!_vm.isValid},on:{"click":_vm.submitForm}},[_vm._v("Submit")]),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"secondary","depressed":""},on:{"click":_vm.resetForm}},[_vm._v(" Reset ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }