import { bmr } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getBMRList(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(bmr.bmrBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  
  getBMROptions(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .options(bmr.bmrBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getBMRDetails(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${bmr.bmrBase}${id}/view/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getRoute(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${bmr.bmrRoute}`, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getBMRObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${bmr.bmrBase}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  addBMR(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(bmr.bmrBase, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  updateBMR(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${bmr.bmrBase}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  partiallyUpdateBMR(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${bmr.bmrBase}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
