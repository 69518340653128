<template>
  <FilterParent
    module="Tariff"
    maxWidth="500"
    v-model="showCustomerContractFilters"
  >
    <template #filterContent>
      <v-row class="mt-0">
        <v-col cols="12" class="Form-Columns">
          <MultiSelect
            label="Select Job Type"
            :items="allJobType"
            v-model="filterObject.job_type"
          />
        </v-col>

        <v-col cols="12" class="Form-Columns">
          <InputField
            type="date"
            label="Start Date"
            v-model="filterObject.start_date"
          />
        </v-col>

        <v-col cols="12" class="Form-Columns">
          <InputField
            type="date"
            label="End Date"
            v-model="filterObject.end_date"
          />
        </v-col>
      </v-row>
    </template>

    <template #filterAction>
      <v-btn
        depressed
        @click="$emit('resetFilters'), (showCustomerContractFilters = false)"
      >
        Reset
      </v-btn>

      <v-btn
        color="primary"
        class="ml-4"
        depressed
        @click="$emit('applyFilters'), (showCustomerContractFilters = false)"
      >
        Apply
      </v-btn>
    </template>
  </FilterParent>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import FilterParent from "@/components/General/FilterParent.vue";

export default {
  components: {
    FilterParent,
    MultiSelect,
    InputField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    bindingObject: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      allJobType: [],
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.getCustomerContractOptions();
      }
    },
  },
  computed: {
    showCustomerContractFilters: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    filterObject: {
      get() {
        return this.bindingObject;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    getCustomerContractOptions() {
      this.$api.customerContract
        .getCustomerContractOptions()
        .then((res) => {
          this.allJobType = res.data.actions.POST.job_type.choices.map(
            (obj) => obj.value
          );
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style>
</style>