import Blank from "@/layouts/blank.vue"

import QuotationList from "@/pages/customer/rate/quotation/QuotationList.vue";
import QuotationRequest from "@/pages/customer/rate/quotation/QuotationRequest.vue";
import QuotationEdit from "@/pages/customer/rate/quotation/QuotationEdit.vue";

const quotationRoutes = [
  {
    path: "quotation",
    name: "app-customer-rate-quotation",
    component: Blank,
    children: [
      {
        path: "/",
        redirect: "/app/customer/rate/quotation/list",
      },
      {
        path: "list",
        name: "app-customer-rate-quotation-list",
        component: QuotationList,
      },
      {
        path: "request",
        name: "app-customer-rate-quotation-request",
        component: QuotationRequest,
      },
      {
        path: "edit",
        name: "app-customer-rate-quotation-edit",
        component: Blank,
        children: [
          {
            path: "/",
            redirect: "/app/customer/rate/quotation/list",
          },
          {
            path: ":id",
            name: "app-customer-rate-quotation-edit-id",
            component: QuotationEdit,
          }
        ]
      },
    ]
  },
]

export default quotationRoutes