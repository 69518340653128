

<template>
  <v-dialog
    v-model="openFuelSurchargeBulkUpload"
    persistent
    scrollable
    width="90%"
    max-width="1200px"
  >
    <v-card class="pa-4">
      <v-card-title class="d-flex justify-space-between">
        <span
          class="
            text-lg-subtitle-1 text-xl-h6 text-uppercase
            font-weight-black
            primary--text
          "
        >
          Fuel Surcharge Bulk Upload
        </span>
        <v-btn depressed fab class="mt-n3" small @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row class="pt-8">
          <v-col cols="4" class="d-flex">
            <DateField
              ref="dateField"
              :inputProps="{
                dense: true,
                label: 'Start Date *',
                style: 'width: calc(100% - 60px)',
                'hide-details': 'auto',
                rules: [(val) => !!val || 'Start Date is Required!'],
                
              }"
              :min="today"
              required
              v-model="start_date"
              @input="checkDates"
            />
          </v-col>
          <v-col cols="4" class="d-flex">
            <DateField
              :inputProps="{
                dense: true,
                label: 'End Date *',
                style: 'width: calc(100% - 60px)',
                'hide-details': 'auto',
                rules: [(val) => !!val || 'End Date is Required!'],
              }"
              required
              ref="endDateField"
              disabled
              v-model="end_date"
            />
          </v-col>
          <v-col cols="4" class="d-flex">
            <v-file-input
              v-model="file"
              hide-details
              dense
              :label="`${'upload document'}`"
              prepend-inner-icon="mdi-attachment mdi-rotate-90"
              class="mr-4"
              prepend-icon=""
              outlined
              @change="getFileData($event)"
              accept=".xlsx, .xls, .csv"
            />
            <v-icon color="primary" @click="openInstructionDialog(uploadTo)">
              mdi-information
            </v-icon>
          </v-col>
          <v-col cols="7" class="text-capitalize">
            <v-btn class="primary" depressed @click="downloadSampleExcel()">
              {{ "download sample excel file" }}
            </v-btn>
          </v-col>
          <v-col cols="12" class="d-flex justify-space-between pt-0">
            <span class="text-capitalize">
              {{ "total records" }} : {{ totalRecordCount }}</span
            >
            <span> {{ "records with error" }} : {{ rowData.length }}</span>
          </v-col>
          <v-col v-if="distinctErrors.length > 0" class="py-0">
            <v-alert border="left" colored-border color="red">
              <v-row no-gutters>
                <v-col class="grow"> </v-col>
                <v-col class="shrink">
                  <v-btn
                    class="error"
                    depressed
                    @click="removeAllRecordWithError"
                  >
                    {{ "remove all with error" }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
          <v-col cols="12" v-if="rowData.length > 0">
            <AgGridVue
              @grid-ready="gridReady"
              :grid-options="gridOptions"
              :column-defs="columnDefs"
              :default-col-def="defaultColDef"
              :context="context"
              :row-data="rowData"
              style="width: 100%; height: 400px"
              class="ag-theme-material"
            >
            </AgGridVue>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pa-4 d-flex justify-end">
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="!file || rowData.length != 0 || !start_date || !end_date"
          @click="submitData()"
        >
          {{ "submit" }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <Instruction
      v-model="instructionDialog"
      :instructionList="instructionList"
    />
  </v-dialog>
</template>

<script>
import { read, utils } from "xlsx";
import { fuelSurchargeInstruction } from "@/utils/instruction.js";
import { AgGridVue } from "ag-grid-vue";
import Instruction from "@/components/General/Instruction.vue";
import RemoveRowButtonBulkUpload from "@/components/General/RemoveRowButtonBulkUpload.vue";
import { downloadBlobData } from "@/utils/functions.js";
import BulkUploadErrorTooltip from "@/components/General/BulkUploadErrorTooltip.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";
import moment from "moment";

export default {
  components: {
    DateField,
    AgGridVue,
    Instruction,
    RemoveRowButtonBulkUpload,
    BulkUploadErrorTooltip,
  },
  props: {
    value: Boolean,
  },
  data() {
    return {
      today: new Date().toISOString().slice(0, 10),
      loading: false,
      start_date: null,
      end_date: null,
      lastEndDate: null,
      file: null,
      fulldata: [],
      rowData: [],
      columnDefs: [],
      _reqFields: [],
      _allFields: null,
      gridApi: null,
      columnApi: null,
      gridOptions: {
        headerHeight: 40,
        rowHeight: 40,
        rowSelection: "multiple",
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
      },
      defaultColDef: {
        lockPosition: true,
      },
      requiredHeaders: [],
      serverErrors: [],

      // For Instructions Dialog
      instructionList: [],
      instructionDialog: false,
      dataObject: [],
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    totalRecordCount() {
      return this.dataObject.filter((item) => Object.keys(item).length > 0)
        .length;
    },
    openFuelSurchargeBulkUpload: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    distinctErrors() {
      let err = [];

      this.serverErrors
        .filter((e) => {
          return Object.keys(e).length > 0;
        })
        .map((m) => {
          return Object.keys(m).map((k) => {
            return m[k];
          });
        })
        .forEach((r) => {
          r.forEach((s) => {
            err = [...new Set([...err, ...s])];
          });
        });
      return err;
    },
  },
  methods: {
    checkDates() {
      if (this.start_date) {
        let startDate = moment(this.start_date, "YYYY-MM-DD");
        let lastDayOfMonth = moment(startDate)
          .endOf("month")
          .format("YYYY-MM-DD");
        this.end_date = lastDayOfMonth;
        setTimeout(() => {
          const field = this.$refs.endDateField;
          if (field) {
            field.setDefaultDateTime();
          }
        }, 20);
      }
    },
    openInstructionDialog() {
      this.instructionList = fuelSurchargeInstruction;
      this.instructionDialog = true;
    },
    gridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    getFileData(file) {
      if (file) {
        let reader = new FileReader();
        reader.onload = async () => {
          this.excelData = [];
          let fileData = reader.result;
          let wb = read(fileData, {
            type: "binary",
            cellText: false,
            cellDates: true,
          });
          let rowData = utils.sheet_to_row_object_array(
            wb.Sheets[wb.SheetNames[0]],
            {
              header: 0,
              defval: "",
              raw: false,
              dateNF: "yyyy-mm-dd",
            }
          );

          this.dataObject = rowData.map((row, index) => {
            let newObject = {};
            newObject.oldIndex = index;
            for (const [key, value] of Object.entries(row)) {
              let newKey = key
                .toString()
                .toLowerCase()
                .trim()
                .replaceAll(" ", "_");
              newObject[newKey] = value;
            }

            return newObject;
          });
        };

        reader.readAsBinaryString(file);
      } else {
        this.clearDialogData();
      }
    },
    removeErrorRow(index, oldIndex) {
      setTimeout(() => {
        this.serverErrors.splice(index, 1);
        this.rowData.splice(index, 1);

        this.dataObject.splice(
          this.dataObject.findIndex((item) => item.oldIndex == oldIndex),
          1
        );
      }, 200);
    },
    dataChange(param) {
      let field = param.colDef.field;
      let localError = this.serverErrors[param.node.rowIndex];
      if (param && param.newValue != param.oldValue) {
        delete localError[field];
      }
      let newIndex = this.dataObject.findIndex(
        (item) => item.oldIndex == param.data.oldIndex
      );
      this.dataObject[newIndex] = param.data;

      if (
        (localError && localError.non_field_errors) ||
        (localError &&
          Object.keys(localError) &&
          Object.keys(localError).length == 0)
      ) {
        setTimeout(() => {
          this.serverErrors.splice(param.node.rowIndex, 1);
          this.rowData.splice(param.node.rowIndex, 1);
        }, 200);
      }

      return param.newValue;
    },
    cellStyle(param) {
      if (
        param &&
        param.colDef &&
        param.colDef.field &&
        this.serverErrors &&
        this.serverErrors[param.rowIndex] &&
        this.serverErrors[param.rowIndex][param.colDef.field]
      ) {
        return {
          backgroundColor: "red",
        };
      } else {
        return {
          backgroundColor: "white",
        };
      }
    },
    removeAllRecordWithError() {
      while (this.rowData && this.rowData.length > 0) {
        this.removeDataFromRow(this.rowData[0].oldIndex, 0);
      }
    },
    removeDataFromRow(oldIndex, currentIndex) {
      this.dataObject.splice(oldIndex, 1, {});
      this.rowData.splice(currentIndex, 1);

      let data = this.dataObject.filter((obj) => Object.keys(obj).length !== 0);

      if (data.length == 0) {
        this.clearDialogData();
      }

      if (this.rowData.length == 0) {
        this.serverErrors = [];
      }
    },
    downloadSampleExcel() {
      const params = {
        excel_type: "bulk_fuel_surcharge",
      };
      this.$api.bulkUpload
        .downloadSampleFile(params)
        .then((res) => {
          downloadBlobData(res, `Sample fuel surcharge excel file.xls`);
          this.$bus.$emit("showToastMessage", {
            message: "Download file successfully",
            color: "success",
          });
        })
        .catch((err) => {
          this.$bus.$emit("showToastMessage", {
            message: "Can't download file!",
            color: "error",
          });
        });
    },
    submitData(payload = {}) {
      this.serverErrors = [];
      payload.start_date = this.start_date;
      payload.end_date = this.end_date;
      payload.upload_type = "bulk_fuel_surcharge";
      if (this.dataObject && this.dataObject.length > 0) {
        payload.bulk_data = this.dataObject.filter(
          (item) => Object.keys(item).length > 0
        );
        this.$bus.$emit("showLoader", true);
        this.$api.bulkUpload
          .bulkUpload(payload)
          .then((resp) => {
            this.$bus.$emit("showLoader", false);
            this.loading = false;
            this.$bus.$emit("showToastMessage", {
              message: "Successfully uploaded file",
              color: "success",
            });
            this.serverErrors = [];
            this.closeDialog();
            this.$emit("refreshList");
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            if (typeof err == "string") {
              this.$bus.$emit("showToastMessage", {
                message: err,
                color: "error",
              });
            } else if (
              (err && typeof err == "object") ||
              typeof err == "object"
            ) {
              this.serverErrors = err;
              this.generateErrorRow(err);
              this.$bus.$emit("showToastMessage", {
                message: "Error uploading sheet!",
                color: "error",
              });
            }

            this.loading = false;
          });
      } else {
        this.$bus.$emit("showToastMessage", {
          message: "Data not found!",
          color: "error",
        });
      }
    },
    generateErrorRow(errorRow) {
      let errorIndexRow = errorRow.map((item, index) => {
        return Object.keys(item).length > 0 ? true : false;
      });
      this.columnDefs = Object.keys(this.dataObject[0]).map((item, index) => {
        return {
          headerName: `${item.replaceAll("_", " ")}`
            .toLowerCase()
            .toUpperCase(),
          field: item,
          editable: "oldIndex" != item ? true : false,
          hide: "oldIndex" == item || "formError" == item ? true : false,
          valueParser: this.dataChange,
          cellStyle: this.cellStyle,
        };
      });

      this.columnDefs.unshift({
        headerName: "errors",
        pinned: "left",
        width: 90,
        cellRendererFramework: "BulkUploadErrorTooltip",
      });

      this.columnDefs.push({
        headerName: "actions",
        pinned: "right",
        width: 90,
        cellRendererFramework: "RemoveRowButtonBulkUpload",
      });
      this.serverErrors = this.serverErrors.filter((item, index) => {
        if (errorIndexRow[index]) {
          return item;
        }
      });

      this.rowData = this.dataObject.filter((item, index) => {
        if (errorIndexRow[index]) {
          return item;
        }
      });
      this.rowData = this.rowData.map((row, index) => {
        let error = this.serverErrors[index];
        return { ...row, formError: error };
      });
    },
    closeDialog() {
      this.openFuelSurchargeBulkUpload = false;
      this.clearDialogData();
    },
    clearDialogData() {
      this.file = null;
      this.fulldata = [];
      this.rowData = [];
      this.columnDefs = [];
      this.dataObject = [];
      this._reqFields = [];
      this.serverErrors = [];
      this.start_date = null;
      this.end_date = null;
    },
  },
};
</script>

