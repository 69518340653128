import {
    paymentReceipt
} from "@/utils/constants";
import {
    handleError,
    handleResponse
} from "@/utils/functions";

export default (axios) => ({
    getPaymentReceiptList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(paymentReceipt.base, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getPaymentReceiptSelectList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${paymentReceipt.base}select/`, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getPaymentReceiptOptions() {
        return new Promise((resolve, reject) => {
            axios
                .options(paymentReceipt.base)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getPaymentReceiptBlob(id, params = {}) {

        return new Promise((resolve, reject) => {
            axios
                .get(`${paymentReceipt.base}${id}/download/`, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    updateSapDetail(id) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${paymentReceipt.base}${id}/send_to_sap/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    bulkUpdateSapDetail(payment_receipt_ids) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${paymentReceipt.base}bulk_send_to_sap/`, {
                    payment_receipt_ids
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    getPaymentReceiptDetails(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${paymentReceipt.base}${id}/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    addReceiptDetails(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(paymentReceipt.base, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    updateReceiptDetails(payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(`${paymentReceipt.base}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    deleteDeliveryCharge(id) {
        return new Promise((resolve, reject) => {
            axios
                .delete(`${paymentReceipt.base}${id}/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getPaymentReceiptLogs(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${paymentReceipt.base}${id}/payment_receipt_logs/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
});