<template>
  <v-menu
    :disabled="$attrs.disabled"
    v-model="menuDialog"
    :close-on-content-click="false"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <v-textarea
          :loading="loading"
          rows="1"
          outlined
          auto-grow
          readonly
          dense
          hide-details="auto"
          append-icon="mdi-chevron-down"
          autocomplete="new-password"
          v-model="autocompleteValue"
          v-bind="$attrs"
          v-on="$listeners"
        />
      </span>
    </template>

    <div>
      <v-list>
        <v-list-item-group color="primary">
          <v-list-item inactive :ripple="false">
            <v-list-item-content>
              <v-text-field
                placeholder="Search..."
                hide-details="auto"
                v-model="search"
                dense
                outlined
                clearable
                autocomplete="new-password"
              />
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="multiple && showSelectAll"
            @click="toggleSelectAll"
          >
            <v-list-item-icon>
              <v-icon v-if="isAllSelected" color="primary">
                mdi-checkbox-marked
              </v-icon>
              <v-icon v-else> mdi-checkbox-blank-outline </v-icon>
            </v-list-item-icon>
            <v-list-item-content>Select All</v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-divider class="grey"></v-divider>
      <div
        id="custom-item-list-container"
        @scroll="handleScroll"
        class="autocomplete-items-list-container"
      >
        <v-list>
          <v-list-item
            :selectable="false"
            v-for="(item, i) in items"
            :key="i"
            @click="toggleSelection(item)"
          >
            <v-list-item-icon v-if="multiple">
              <v-icon v-if="getSelectedItemIndex(item) != -1" color="primary">
                mdi-checkbox-marked
              </v-icon>
              <v-icon v-else> mdi-checkbox-blank-outline </v-icon>
            </v-list-item-icon>
            <v-list-item-icon v-else>
              <v-icon
                v-if="isItemSelected(selectedValue, item)"
                color="primary"
              >
                mdi-radiobox-marked
              </v-icon>
              <v-icon v-else> mdi-radiobox-blank </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="typeof item != 'object' ? item : item[itemText]"
              />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </div>
  </v-menu>
</template>

<script>
export default {
  name: "AutocompleteInput",
  props: {
    value: {
      required: true,
    },
    itemText: {
      type: String,
      default: "text",
    },
    itemValue: {
      type: String,
      default: "value",
    },
    searchDelay: {
      type: Number,
      default: 800,
    },
    multiple: {
      default: false,
    },
    showSelectAll: {
      default: false,
    },
    loading: {
      default: false,
    },
    items: {
      required: true,
      default: () => [],
    },
    totalItems: {
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      search: "",
      menuDialog: false,
    };
  },
  computed: {
    selectedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    autocompleteValue: {
      get() {
        if (this.multiple) {
          if (Array.isArray(this.selectedValue)) {
            return this.selectedValue
              .map((val) => {
                if (val && typeof val == "string") {
                  return val;
                } else if (val && typeof val == "object") {
                  return val[this.itemText];
                } else {
                  return "";
                }
              })
              .join(", ");
          }
        } else {
          if (this.selectedValue && typeof this.selectedValue == "string") {
            return this.selectedValue;
          } else if (
            this.selectedValue &&
            typeof this.selectedValue == "object"
          ) {
            return this.selectedValue[this.itemText];
          } else {
            return "";
          }
        }
      },
      set() {},
    },
    isAllSelected() {
      if (Array.isArray(this.selectedValue)) {
        return this.selectedValue.length == this.totalItems;
      } else {
        return false;
      }
    },
  },
  watch: {
    selectedValue(val) {
      if (this.multiple && !Array.isArray(val)) {
        this.selectedValue = [];
      }
    },
    search() {
      this.getSearchResults();
    },
    menuDialog(value) {
      if (!value) {
        this.resetList();
      }
    },
  },
  methods: {
    handleScroll(ev) {
      if (
        !this.loading &&
        this.totalItems != this.items.length &&
        ev.target.scrollHeight - 60 <
          Math.floor(ev.target.scrollTop + ev.target.offsetHeight)
      ) {
        this.getItemList();
      }
    },
    getSearchResults() {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }

      this.searchTimeout = setTimeout(() => {
        this.getItemList(true);
      }, this.searchDelay);
    },
    getSelectedItemIndex(item) {
      if (this.selectedValue) {
        return this.selectedValue.findIndex((value) => {
          return this.isItemSelected(value, item);
        });
      } else {
        return -1;
      }
    },
    isItemSelected(value, item) {
      if (!value) {
        return false;
      }
      if (typeof item == "string") {
        return value == item;
      } else {
        return value[this.itemValue] == item[this.itemValue];
      }
    },
    toggleSelection(item) {
      if (this.multiple) {
        if (!Array.isArray(this.selectedValue)) {
          this.selectedValue = [];
        }
        let arr = [...this.selectedValue];
        const selectedIndex = this.getSelectedItemIndex(item);

        if (selectedIndex == -1) {
          arr.push(item);
        } else {
          arr.splice(selectedIndex, 1);
        }

        this.selectedValue = [...arr];
      } else {
        if (
          !this.selectedValue ||
          !this.isItemSelected(this.selectedValue, item)
        ) {
          if (typeof item == "object") {
            this.selectedValue = {
              ...item,
            };
          } else {
            this.selectedValue = item;
          }
        } else {
          this.selectedValue = null;
        }
        this.menuDialog = false;
      }
    },
    async toggleSelectAll() {
      if (this.isAllSelected) {
        this.selectedValue = [];
      } else {
        if (this.items.length == this.totalItems) {
          this.selectAllValues();
        } else {
          this.$bus.$emit("showLoader", true);
          this.getItemList(true, { limit: "all" })
            .then(() => {
              setTimeout(() => {
                this.$bus.$emit("showLoader", false);
                this.selectAllValues();
              }, 500);
            })
            .catch(() => {
              this.$bus.$emit("showLoader", false);
            });
        }
      }
    },
    selectAllValues() {
      this.selectedValue = this.items.map((val) => ({ ...val }));
    },
    getItemList(reset = false, params = {}) {
      params = {
        limit: 10,
        offset: 0,
        ...params,
      };

      if (!reset) {
        params.offset = this.items.length;
      }

      if (this.search && this.search.trim()) {
        params.search = this.search.trim();
      }

      this.$emit("updateList", params);
    },
    resetList() {
      const elem = document.getElementById("custom-item-list-container");
      elem.scrollTop = 0;
      this.search = null;
      this.getItemList(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.autocomplete-items-list-container {
  background: white;
  max-height: 260px;
  overflow-y: auto;
  width: 100%;
}
</style>