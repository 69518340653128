<template>
  <v-container class="Details-View">
    <v-row class="heading">
      <v-col cols="12">
        <Tooltip
          tooltipTitle="Trailer ID"
          v-if="trailerDetails.equipment_id"
          :tooltipText="trailerDetails.equipment_id"
          top
          tooltipClass="text-h5 font-weight-bold"
        />
      </v-col>
    </v-row>

    <v-row class="subHeading">
      <v-col cols="12" md="7">
        <Tooltip
          tooltipTitle="Trailer Type"
          v-if="trailerDetails.trailer_type_title"
          :tooltipText="trailerDetails.trailer_type_title"
          top
          tooltipClass="text-subtitle font-weight-bold"
        />
      </v-col>

      <v-col cols="12" md="5" class="text-right">
        <Tooltip
          tooltipTitle="Trailer Number"
          v-if="trailerDetails.trailer_number"
          :tooltipText="trailerDetails.trailer_number"
          top
          tooltipClass="text-subtitle font-weight-bold"
        />
      </v-col>
    </v-row>

    <v-row v-if="Object.keys(trailerDetails).length">
      <v-col cols="12" class="px-0">
        <v-tabs fixed-tabs v-model="detailsTab">
          <v-tabs-slider color="white"></v-tabs-slider>
          <v-tab>Detail View</v-tab>
          <v-tab>Trailer Maintenance</v-tab>
          <v-tab>Documents</v-tab>
          <v-tab>Logs</v-tab>
        </v-tabs>
      </v-col>

      <v-col cols="12">
        <v-tabs-items v-model="detailsTab">
          <v-tab-item class="Height-Class">
            <v-expansion-panels focusable multiple v-model="trailerDetailView">
              <CardDetails
                title="Trailer Info"
                :displayObject="trailerDetails.extra_info"
              />
              <CardDetails
                title="Company Info"
                :displayObject="trailerDetails.company_detail"
              />
            </v-expansion-panels>

            <v-expansion-panels
              v-if="
                trailerDetails.trailer_tags &&
                trailerDetails.trailer_tags.length > 0
              "
              class="mt-4"
              focusable
              multiple
              v-model="trailerTags"
            >
              <CardDetails
                title="Trailer Tags"
                :displayObject="trailerDetails.trailer_tags"
              />
            </v-expansion-panels>

            <v-expansion-panels
              class="mt-4"
              focusable
              multiple
              v-for="(trailer, i) in trailerDetails.container_type_mapping"
              :key="i"
              v-model="containerTypeMap"
            >
              <CardDetails
                :title="`${i + 1}.Container Type Mapping`"
                :displayObject="trailer"
              />
            </v-expansion-panels>
          </v-tab-item>

          <v-tab-item>
            <div class="text-right">
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    depressed
                    v-on="on"
                    v-bind="attrs"
                    small
                    @click="addMaintenance"
                    class="mb-2"
                    v-if="permissions && permissions.add"
                  >
                    <v-icon>mdi-tools</v-icon>
                  </v-btn>
                </template>
                <span>Add Maintenance</span>
              </v-tooltip>
            </div>

            <v-list class="Maintenance-Height-Class py-0">
              <v-list-item
                class="subHeading rounded-lg mb-2"
                v-for="(item, i) in trailerMaintenance"
                :key="i"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-subtitle-1 font-weight-medium">
                    {{ `${i + 1}. ${item.maintenance_type}` }}
                  </v-list-item-title>

                  <v-list-item-subtitle class="pt-2">
                    <p class="mb-2">
                      Priority : <strong>{{ item.priority }}</strong>
                    </p>

                    <p class="mb-2">
                      From
                      <strong>{{ item.start_date | formatDate }}</strong> to
                      <strong>{{ item.end_date | formatDate }}</strong>
                    </p>

                    <p class="mb-0">Description : {{ item.description }}</p>
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-icon>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        @click="editMaintenance(item.id)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-tab-item>

          <v-tab-item class="Height-Class">
            <DocumentList :documentList="trailerDetails.trailer_documents" />
          </v-tab-item>
          <v-tab-item>
            <v-sheet
              style="
                height: calc(100vh - 300px);
                overflow-y: auto;
                overflow-x: hidden;
              "
            >
              <v-timeline
                v-if="logs && logs.length"
                class="px-0"
                align-top
                dense
              >
                <v-timeline-item
                  v-for="(log, index) in logs"
                  :key="index"
                  color="primary"
                  icon="mdi-circle"
                  small
                >
                  <v-row class="pt-1">
                    <v-col>
                      <strong>{{ log.created | formatDate(true) }}</strong>
                      <div class="text-body-2">{{ log.message }}</div>
                      <div class="text-caption">- {{ log.updated_by }}</div>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
              <div v-else>No Logs to show</div>
            </v-sheet>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <MaintenanceForm
      v-model="showMaintenanceForm"
      :asset="trailerDetails"
      :editMode="editMode"
      :maintenanceId="selectedMaintenanceId"
      module="Trailer"
      @closeMaintenanceForm="showMaintenanceForm = false"
      @refreshList="getTrailerMaintenanceList"
    />
  </v-container>
</template>

<script>
import CardDetails from "@/components/General/CardDetails.vue";
import DocumentList from "@/components/General/DocumentList.vue";
import Tooltip from "@/components/General/Tooltip.vue";
import MaintenanceForm from "../dialogForms/MaintenanceForm.vue";

export default {
  components: { CardDetails, Tooltip, MaintenanceForm, DocumentList },
  props: {
    trailerId: {
      type: Number,
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    trailerId(val) {
      this.logs = [];
      this.getTrailerDetails(val);
      this.getTrailerMaintenanceList();
      this.trailerActivityLogs();
    },
  },
  data() {
    return {
      detailsTab: 0,
      trailerDetails: {},
      trailerMaintenance: [],
      trailerTags: [],
      containerTypeMap: [],
      logs: [],

      // Maintenance vars
      showMaintenanceForm: false,
      editMode: false,
      selectedMaintenanceId: null,

      trailerDetailView: [0, 1],
    };
  },
  methods: {
    getTrailerDetails(id) {
      this.$api.trailer
        .getTrailerDetails(id)
        .then((res) => {
          if (res.data && res.data.container_type_mapping) {
            this.containerTypeMap = res.data.container_type_mapping.map(
              (v, i) => i
            );
          }
          this.trailerDetails = res.data;
          if (res.data && res.data.trailer_tags) {
            res.data.trailer_tags.forEach((element, i) => {
              this.trailerTags.push(i);
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getTrailerMaintenanceList() {
      this.$api.maintenance
        .getMaintenanceList({ trailer: this.trailerId, limit: "all" })
        .then((res) => {
          this.trailerMaintenance = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    addMaintenance() {
      this.editMode = false;
      this.selectedMaintenanceId = null;
      this.showMaintenanceForm = true;
    },
    editMaintenance(id) {
      this.editMode = true;
      this.selectedMaintenanceId = id;
      this.showMaintenanceForm = true;
    },
    trailerActivityLogs(params = {}) {
      params = {
        ...params,
        trailer: this.trailerDetails.id,
        limit: "50",
        offset: this.logs.length,
      };
      this.$api.activityLogs
        .trailerActivityLogs(params)
        .then((res) => {
          this.logs.push(...res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  mounted() {
    this.logs = [];
    this.getTrailerDetails(this.trailerId);
    this.getTrailerMaintenanceList();
    this.trailerActivityLogs();
  },
};
</script>

<style scoped>
.Height-Class {
  height: calc(100vh - 300px);
  overflow-y: auto;
  overflow-x: hidden;
}

.Maintenance-Height-Class {
  height: calc(100vh - 336px);
  overflow-y: auto;
}
</style>
