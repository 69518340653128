<template>
  <div>
    <v-card
      elevation="0"
      color="subHeading"
      class="mb-2"
      outlined
      v-for="(data, i) in documentList"
      :key="i"
    >
      <v-card-text class="pa-2">
        <v-row>
          <v-col cols="6" class="pb-0">
            <a :href="data.attachment" target="_black">
              <span>{{ data.attachment_name }}</span>
            </a>
          </v-col>

          <v-col cols="6" class="text-right pb-0" v-if="isDeletable">
            <v-icon color="red" @click="$emit('deleteDocument', data.id, i)">
              mdi-delete
            </v-icon>
          </v-col>

          <v-col cols="12">
            <p class="mb-0">Created On : {{ data.created }}</p>
            <p class="mb-0">Added By : {{ data.added_by }}</p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card elevation="0" v-if="documentList.length == 0" class="fill-height">
      <v-card-text class="d-flex justify-center align-center fill-height">
        No documents to show!
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    documentList: {
      type: Array,
      required: true,
    },
    isDeletable: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
