import Blank from "@/layouts/blank.vue"

import SpotRoutes from "@/router/customer/rate/spot"
import QuotationRoutes from "@/router/customer/rate/quotation"

const rateRoutes = [
  {
    path: "rate",
    name: "app-customer-rate",
    component: Blank,
    children: [
      ...SpotRoutes,
      ...QuotationRoutes
    ]
  },
]

export default rateRoutes