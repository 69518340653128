<template>
  <FilterParent module="Location" maxWidth="500" v-model="showAdminUserFilters">
    <template #filterContent>
      <v-row class="ma-0">
        <v-col cols="12" class="Form-Columns">
          <MultiSelect
            hide-details="auto"
            :items="allCountryList"
            label="Select Country"
            itemText="name"
            itemValue="id"
            v-model="filterObject.country"
          />
        </v-col>

        <v-col cols="12" class="Form-Columns">
          <v-switch
            hide-details="auto"
            :label="`Is Port : ${filterObject.is_port ? 'Yes' : 'No'}`"
            class="mt-1"
            v-model="filterObject.is_port"
          ></v-switch>
        </v-col>

        <v-col cols="12" class="Form-Columns">
          <v-switch
            hide-details="auto"
            :label="`Is Free Zone : ${
              filterObject.is_free_zone ? 'Yes' : 'No'
            }`"
            class="mt-1"
            v-model="filterObject.is_free_zone"
          ></v-switch>
        </v-col>
      </v-row>
    </template>

    <template #filterAction>
      <v-btn
        color="secondary"
        depressed
        @click="$emit('resetFilters'), (showAdminUserFilters = false)"
        >Reset</v-btn
      >
      <v-btn
        color="primary"
        class="ml-4"
        depressed
        @click="$emit('applyFilters'), (showAdminUserFilters = false)"
        >Apply</v-btn
      >
    </template>
  </FilterParent>
</template>

<script>
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import FilterParent from "@/components/General/FilterParent.vue";

export default {
  components: {
    FilterParent,
    MultiSelect,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    bindingObject: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      allCountryList: [],
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.getCountryList();
      }
    },
  },
  computed: {
    showAdminUserFilters: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    filterObject: {
      get() {
        return this.bindingObject;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (!obj) {
        return null;
      } else {
        return obj;
      }
    },
  },
  methods: {
    getCountryList() {
      this.$bus.$emit("showLoader", true);
      this.$api.country
        .getCountryList({ limit: "all" })
        .then((res) => {
          this.allCountryList = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
};
</script>
