<template>
  <ListComponent title="Company Logs">
    <template #list>
      <v-row class="ma-0">
        <v-col cols="12" md="6" lg="4" class="px-0">
          <v-text-field
            label="Search"
            outlined
            dense
            append-icon="mdi-magnify"
            clearable
            hide-details="auto"
            v-model="filters.search"
            @input="searchLogs"
          ></v-text-field>
        </v-col>

        <v-col cols="12" class="pa-0">
          <AgGridVue
            style="width: 100%; height: calc(100vh - 266px)"
            class="ag-theme-alpine"
            id="myGrid"
            :columnDefs="headers"
            :context="context"
            @grid-ready="onGridReady"
            :defaultColDef="defaultColDef"
            :grid-options="gridOptions"
            :suppressDragLeaveHidesColumns="true"
            :rowData="logsList"
          ></AgGridVue>
        </v-col>
        
        <v-col v-if="totalItems > 0" cols="12" class="d-flex justify-end px-0">
          <Pagination
            :pageNo="pageNo"
            :totalItems="totalItems"
            :pageSize="itemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>
      </v-row>

      <DetailsParent v-model="detailsDrawer" :resizable="true">
        <template #details>
          <TransporterDetails
            :readOnly="true"
            v-if="selectedRecord.company_type == 'Transporter'"
            :transporterId="selectedRecord.id"
          />
          <CustomerDetails
            :readOnly="true"
            v-if="selectedRecord.company_type == 'Customer'"
            :customerId="selectedRecord.id"
          />
        </template>
      </DetailsParent>
    </template>

    <template #dialogs>
      <BasicAlert v-model="showAlert" title="Error">
        <template #text>
          <div v-for="(errors, key, index) in selectedError" :key="index">
            <div v-if="key !== 'non_field_errors'">
              <div class="text-capitalize text-subtitle-1 font-weight-bold">
                {{ key.replace(/_/g, " ") }}:
              </div>
              <div v-for="(error, i) in errors" :key="i">
                &nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;{{ error }}
              </div>
            </div>
          </div>
          <div v-if="'non_field_errors' in selectedError">
            <span
              v-for="(error, index) in selectedError.non_field_errors"
              :key="index"
            >
              {{ error }}
            </span>
          </div>
        </template>
      </BasicAlert>
    </template>
  </ListComponent>
</template>

<script>
import IntegrationButton from "@/components/AgGridComponents/IntegrationButton.vue";
import BasicAlert from "@/components/General/BasicAlert.vue";
import ListComponent from "@/components/General/ListComponent.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";
import CustomHeader from "@/components/AgGridComponents/General/CustomHeader.vue";
import DetailsParent from "@/components/General/DetailsParent.vue";
import TransporterDetails from "@/components/ModuleBased/details/TransporterDetails.vue";
import CustomerDetails from "@/components/ModuleBased/details/CustomerDetails.vue";
import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    ListComponent,
    IntegrationButton,
    AgGridVue,
    Pagination,
    BasicAlert,
    DetailsParent,
    TransporterDetails,
    CustomerDetails,
    agColumnHeader: CustomHeader,
  },
  data() {
    return {
      sorting: {},
      headers: [
        {
          headerName: "Response Code",
          field: "response_code",
          sortable: true,
        },
        {
          headerName: "Company Code",
          field: "request_body.company_code",
          sortable: true,
        },
        {
          headerName: "Company Name",
          field: "request_body.company_name",
          sortable: true,
        },
        {
          headerName: "Contact Number",
          field: "request_body.company_contact_number",
          sortable: true,
        },
        {
          headerName: "Company Type",
          field: "request_body.company_type",
          sortable: true,
        },
        {
          headerName: "Company Country",
          field: "request_body.country",
          sortable: true,
        },
        {
          headerName: "Company City",
          field: "request_body.city",
          sortable: true,
        },
        {
          headerName: "Payment Terms",
          field: "request_body.payment_terms",
          sortable: true,
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: "IntegrationButton",
        },
      ],
      logsList: [],

      // pagination vars
      itemsPerPage: 10,
      pageNo: 1,
      totalItems: 0,
      filters: {},

      headerSelected: [],
      tab: null,

      permissions: null,
      transporterPermissions: null,
      customerPermissions: null,

      // ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        width: 150,
        resizable: true
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.gridOptions.api.sizeColumnsToFit();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },

      showAlert: false,
      selectedError: {},

      selectedRecord: null,
      detailsDrawer: false,
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (!obj) {
        return null;
      } else {
        return obj;
      }
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    searchLogs() {
      this.pageNo = 1;
      this.getCompanyIntegrationLogs();
    },
    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getCompanyIntegrationLogs();
    },
    prevPage() {
      this.pageNo--;
      this.getCompanyIntegrationLogs();
    },
    nextPage() {
      this.pageNo++;
      this.getCompanyIntegrationLogs();
    },
    applyGridSort(key, type) {
      if (this.filters && !this.filters.ordering) {
        this.filters.ordering = [];
      }

      if (type == null) {
        delete this.sorting[key];
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(`-${key}`),
          1
        );
      } else if (type == "asc") {
        this.filters.ordering.push(key);
      } else if (type == "desc") {
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(key),
          1,
          `-${key}`
        );
      }
      if (this.filters.ordering.length == 0) {
        delete this.filters.ordering;
      }
      this.getCompanyIntegrationLogs();
    },
    getCompanyIntegrationLogs(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        offset: this.offset,
        limit: this.itemsPerPage,
        ...params,
        ...this.filters,
      };

      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }
      this.$api.integration
        .getCompanyIntegrationLogs(params)
        .then((res) => {
          this.logsList = res.data;
          this.totalItems = res.count;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    viewDetails(data) {
      this.selectedRecord = data;
      this.detailsDrawer = true;
    },
    viewError(data) {
      this.selectedError = data.response_body;
      this.showAlert = true;
    },
    addManually(data) {
      const route = {
        path: `/app/admin/company-management/${data.request_body.company_type.toLowerCase()}/add`,
        query: { intId: data.id },
      };
      this.$router.push(route);
    },
  },

  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("integration");
      this.transporterPermissions = getPermissions("transporter");
      this.customerPermissions = getPermissions("customer");

      if (!permissions || !permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.permissions = { ...permissions };
        this.getCompanyIntegrationLogs();
      }
    }
  },
};
</script>