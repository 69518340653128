import {
  quotation
} from "@/utils/constants";
import {
  handleError,
  handleResponse
} from "@/utils/functions";

export default (axios) => ({
  getQuotationList(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(quotation.quotationBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getQuotationOptions(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .options(quotation.quotationBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getQuotationObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${quotation.quotationBase}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getQuotationDetails(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${quotation.quotationBase}${id}/view/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addQuotation(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(quotation.quotationBase, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addQuotationFromLocation(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${quotation.quotationBase}add_contract_location_quotation/`, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updateQuotation(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${quotation.quotationBase}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  partiallyUpdateQuotation(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${quotation.quotationBase}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});