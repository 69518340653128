<template>
  <div>
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-icon
          class="mr-4 ml-1"
          color="primary"
          v-on="on"
          v-bind="attrs"
          @click="viewItem"
        >
          mdi-eye
        </v-icon>
      </template>
      <span>View</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-icon
          v-if="
            params &&
            params.context &&
            params.context.parentComponent &&
            params.context.parentComponent.permissions &&
            params.context.parentComponent.permissions.change
          "
          color="primary"
          v-on="on"
          v-bind="attrs"
          @click="editItem"
        >
          mdi-pencil
        </v-icon>
      </template>
      <span>Edit</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  methods: {
    viewItem() {
      this.params.context.parentComponent.viewCountry(this.params.data);
    },
    editItem() {
      this.params.context.parentComponent.editCountry(this.params.data.id);
    },
  },
};
</script>

<style>
</style>