<template>
  <v-dialog width="500" v-model="showDocumentForm">
    <v-card>
      <v-card-title class="secondary white--text">
        <span class="text-capitalize"> Add Document </span>

        <v-spacer></v-spacer>

        <v-btn icon @click="resetForm(), (showDocumentForm = false)">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-2">
        <v-form ref="documentForm" v-model="isValid">
          <v-row class="my-0">
            <v-col cols="12" class="Form-Columns">
              <InputField
                type="text"
                label="Document Type"
                hide-details="auto"
                v-model="documentDetails.document_type"
                :error-messages="formErrors.document_type"
                @input="delete formErrors.document_type"
              />
            </v-col>

            <v-col cols="12" class="Form-Columns">
              <v-file-input
                label="Document"
                accept="image/x-png,image/jpg,image/jpeg,application/pdf"
                :rules="[
                  (file) =>
                    !file ||
                    file.size < 2e6 ||
                    'File size should be less than 2 MB!',
                ]"
                outlined
                dense
                hide-details="auto"
                v-model="documentDetails.document"
                :error-messages="formErrors.document"
                @change="checkFileFormat($event), delete formErrors.document"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          depressed
          :disabled="
            !documentDetails.document ||
            !documentDetails.document_type ||
            !isValid
          "
          @click="uploadDADocuments"
          >Upload</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import SelectField from "@/components/FormBaseComponents/SelectField.vue";

export default {
  components: {
    InputField,
    SelectField,
    MultiSelect,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    daID: {
      // type: Number,
      required: true,
    },
  },
  computed: {
    showDocumentForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    return {
      documentDetails: {},
      formErrors: {},
      isTextInput: false,
      isValid: true,
    };
  },
  methods: {
    checkFileFormat(event) {
      if (event) {
        const name = event.name;
        const lastDot = name.lastIndexOf(".");
        const ext = name.substring(lastDot + 1);
        let allowed = /(\jpg|\jpeg|\pdf|\png)$/i;
        if (!allowed.exec(ext)) {
          this.$nextTick(() => {
            this.documentDetails.document = null;
            this.$bus.$emit("showToastMessage", {
              message: "This file format is not supported!",
              color: "error",
            });
          });
          return false;
        } else {
          return true;
        }
      }
    },
    uploadDADocuments() {
      let payload = {
        id: this.daID,
        data: null,
      };
      this.$bus.$emit("showLoader", true);

      let newForm = new FormData();
      newForm.append("document_type", this.documentDetails.document_type);
      newForm.append("document", this.documentDetails.document);
      payload.data = newForm;
      this.$api.deliveryAdvice
        .uploadDeliveryAdviceDocument(payload)
        .then(() => {
          this.documentDetails = {};
          this.$emit("refreshObject");
          this.$bus.$emit("showToastMessage", {
            message: "Document Uploaded Successfully!",
            color: "success",
          });
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);

          this.formErrors = err.data;
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't upload document!",
            color: "error",
          });
        });
    },
    resetForm() {
      if (this.$refs.documentForm) {
        this.$refs.documentForm.reset();
      }
      this.documentDetails = {};
    },
  },
};
</script>
