<template>
  <ListComponent title="Payment Receipt" :totalCount="totalItems">
    <template #list>
      <v-row class="ma-0">
        <v-col cols="4" class="px-0 pt-0 pb-2 d-flex align-center">
          <v-text-field
            label="Search"
            outlined
            dense
            append-icon="mdi-magnify"
            clearable
            hide-details="auto"
            v-model="filters.search"
            @input="searchPaymentReceipt"
            class="mr-2"
          ></v-text-field>

          <Columns :headers="headers" :gridColInstance="gridColumnApi" />
          <v-spacer></v-spacer>
           <span class="text-h6 font-weight-regular  ml-5">
              Total Receipt-
            <span class="font-weight-bold"> {{ PaymentReceiptCount }} </span>
       </span>
        </v-col>

        <v-col
          v-if="totalItems > 0"
          cols="8"
          class="px-0 pt-0 pb-2 d-flex justify-end"
        >
          <Pagination
            :pageNo="pageNo"
            :totalItems="totalItems"
            :pageSize="itemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>

        <v-col cols="12" class="pa-0">
          <AgGridVue
            style="width: 100%; height: calc(100vh - 172px)"
            class="ag-theme-alpine"
            id="cashrecieptGrid"
            :columnDefs="headers"
            :context="context"
            @grid-ready="onGridReady"
            @columnMoved="resizeGrid"
            @columnVisible="resizeGrid"
            :defaultColDef="defaultColDef"
            :grid-options="gridOptions"
            :isRowSelectable="isRowSelectable"
            :suppressDragLeaveHidesColumns="true"
            :rowData="allPaymentReceiptList"
            rowSelection="multiple"
            @selection-changed="onSelectionChanged"
          />
        </v-col>
      </v-row>
      <DetailsParent v-model="paymentReceiptLogDetailsDrawer" :resizable="true">
        <template #details>
          <v-card elevation="0">
            <v-card-title class="heading">
              <span class="text-h6">Payment Receipt Logs</span>
            </v-card-title>

            <v-card-text>
              <v-row class="Order-Log-Scrolling-Class mt-3">
                <v-col cols="12">
                  <span
                    class="py-12"
                    v-if="paymentReceiptLogs && paymentReceiptLogs.length == 0"
                  >
                    No logs to show!
                  </span>

                  <v-timeline dense clipped v-else>
                    <v-timeline-item
                      v-for="(log, i) in paymentReceiptLogs"
                      :key="i"
                      icon-color="primary"
                      small
                    >
                      <v-card class="elevation-2">
                        <v-card-text>
                          <table class="Full-Width">
                            <tbody>
                              <tr>
                                <th class="text-left">{{ log.updated_by }}</th>
                                <td class="text-caption text-right">
                                  {{ log.created | formatDate(true) }}
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2">{{ log.message }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-timeline-item>
                  </v-timeline>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </DetailsParent>
    </template>

    <template #dialogs>
      <PaymentReceiptForm
        v-model="showPaymentReceiptForm"
        :isCopyOperation="isCopyOperation"
        :paymentReceiptID="editPaymentReceiptID"
        @refreshList="itemsPerPageChanged(20)"
        @updateCopyOperation="updateCopyOperation"
      />
    </template>

    <template #actions>
      <v-btn
        v-if="selectedPaymentReceipt && selectedPaymentReceipt.length"
        depressed
        color="primary"
        @click="sentToSAP()"
        class="mr-1"
      >
        Send To SAP
      </v-btn>
      <v-btn
        v-if="permissions && permissions.add"
        color="primary"
        depressed
        @click="addPaymentReceipt"
      >
        <v-icon>mdi-plus</v-icon>
        <span>Add Payment Receipt</span>
      </v-btn>
    </template>
  </ListComponent>
</template>

<script>
import Columns from "@/components/General/Columns.vue";
import ListComponent from "@/components/General/ListComponent.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";
import PaymentReceiptForm from "@/components/ModuleBased/dialogForms/PaymentReceiptForm.vue";
import PaymentReceiptButton from "@/components/AgGridComponents/PaymentReceiptButton.vue";
import PaymentReceiptIsUsedField from "@/components/AgGridComponents/PaymentReceiptIsUsedField.vue";
import CustomHeader from "@/components/AgGridComponents/General/CustomHeader.vue";
import DetailsParent from "@/components/General/DetailsParent.vue";
import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    Columns,
    ListComponent,
    agColumnHeader: CustomHeader,
    AgGridVue,
    Pagination,
    PaymentReceiptForm,
    PaymentReceiptButton,
    PaymentReceiptIsUsedField,
    DetailsParent,
  },
  watch: {
    tab() {
      this.paymentReceiptLogDetailsDrawer = false;
    },
  },

  data() {
    return {
      sorting: {},
      headers: [
        {
          headerName: "Receipt Id",
          field: "receipt_id",
          tooltipField: "receipt_id",
          sortable: true,
          minWidth: 140,
          headerCheckboxSelection: true,
          checkboxSelection: true,
        },
        {
          headerName: "Creation Date",
          field: "created",
          tooltipField: "created",
          sortable: true,
          minWidth: 120,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value);
          },
        },
        {
          headerName: "Customer",
          field: "company_name",
          tooltipField: "company_name",
          sortable: true,
          minWidth: 150,
          tooltipField: "company_name",
        },
        {
          headerName: "Route",
          field: "lane_title",
          tooltipField: "lane_title",
          sortable: false,
          minWidth: 150,
        },
        {
          headerName: "Payment Mode",
          field: "payment_mode",
          sortable: true,
          minWidth: 120,
        },
        {
          headerName: "Transport Rate",
          field: "rate",
          sortable: true,
          minWidth: 100,
        },
        {
          headerName: "Tax Amount",
          field: "total_tax_amount",
          sortable: true,
          minWidth: 80,
        },
        {
          headerName: "Gross Amount",
          field: "total_amount",
          sortable: false,
          minWidth: 100,
        },
        {
          headerName: "Delivery Advice",
          field: "delivery_advice_number",
          tooltipField: "delivery_advice_number",
          sortable: false,
          minWidth: 120,
        },
        {
          headerName: "Available",
          field: "is_used",
          sortable: false,
          cellRendererFramework: "PaymentReceiptIsUsedField",
          minWidth: 100,
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: "PaymentReceiptButton",
          pinned: "right",
          minWidth: 270,
        },
      ],

      allPaymentReceiptList: [],
      paymentReceiptLogs: [],
      paymentReceiptLogDetailsDrawer: false,

      // pagination vars
      pageNo: 1,
      itemsPerPage: 20,
      totalItems: 0,
      PaymentReceiptCount: 0,

      filters: {},

      selectedrowData: [],
      selectedPaymentReceipt: [],

      // ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },

      isCopyOperation: false,
      showPaymentReceiptForm: false,
      editPaymentReceiptID: null,

      permissions: null,
    };
  },

  computed: {
    context() {
      return { parentComponent: this };
    },
    currentProfile() {
      if (JSON.parse(localStorage.getItem("userProfile"))) {
        return JSON.parse(localStorage.getItem("userProfile"));
      } else {
        return null;
      }
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },

  methods: {
    sentToSAP() {
      const config = {
        status: "secondary",
        title: "Sent To SAP",
        text: "Are you sure you want to sent to SAP?",
      };

      this.$confirm(config).then(() => {
        this.$bus.$emit("showLoader", true);
        let payment_receipt_ids = [...this.selectedPaymentReceipt];
        this.$api.paymentReceipt
          .bulkUpdateSapDetail(payment_receipt_ids)
          .then((resp) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Send To Sap!",
              color: "success",
            });
            this.getPaymentReceiptList();
            this.resetForm();
          })
          .catch((err) => {
            console.error(err);
            this.$bus.$emit("showLoader", false);
          });
      });
    },
    isRowSelectable(params) {
      return (
        params &&
        params.data &&
        params.data.delivery_advice_number &&
        !parseInt(params.data.total_amount) == 0 &&
        !params.data.is_send_to_sap
      );
    },
    onSelectionChanged() {
      this.selectedrowData = this.gridApi.getSelectedRows();
      this.PaymentReceiptCount =  this.selectedrowData.length;

      this.selectedPaymentReceipt = [];
      for (let i = 0; i < this.selectedrowData.length; i++) {
        this.selectedPaymentReceipt.push(this.selectedrowData[i].id);
      }
    },
    resetForm() {
      this.selectedrowData = [];
      this.selectedPaymentReceipt = [];
    },
    updateCopyOperation(value) {
      this.isCopyOperation = value;
    },
    copyPaymentReceipt(id) {
      this.editPaymentReceiptID = id;
      this.showPaymentReceiptForm = true;
      this.isCopyOperation = true;
    },
    viewPaymentReceiptLogs(paymentReceiptId) {
      this.paymentReceiptLogDetailsDrawer = true;
      this.$bus.$emit("showLoader", false);
      this.$api.paymentReceipt
        .getPaymentReceiptLogs(paymentReceiptId)
        .then((res) => {
          this.paymentReceiptLogs = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't fetch payment receipt logs!",
            color: "error",
          });
          this.formErrors = err.data;
        });
    },
    resizeGrid() {
      if (this.gridOptions && this.gridOptions.api) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },
    searchPaymentReceipt() {
      this.PaymentReceiptCount = 0;
      this.pageNo = 1;
      this.getPaymentReceiptList();
    },
    itemsPerPageChanged(e) {
       this.PaymentReceiptCount = 0;
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getPaymentReceiptList();
    },
    prevPage() {
       this.PaymentReceiptCount = 0;
      this.pageNo--;
      this.getPaymentReceiptList();
    },
    nextPage() {
       this.PaymentReceiptCount = 0;
      this.pageNo++;
      this.getPaymentReceiptList();
    },
    applyGridSort(key, type) {
      if (this.filters && !this.filters.ordering) {
        this.filters.ordering = [];
      }

      if (type == null) {
        delete this.sorting[key];
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(`-${key}`),
          1
        );
      } else if (type == "asc") {
        this.filters.ordering.push(key);
      } else if (type == "desc") {
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(key),
          1,
          `-${key}`
        );
      }
      if (this.filters.ordering.length == 0) {
        delete this.filters.ordering;
      }
      this.itemsPerPageChanged(20);
    },
    getPaymentReceiptList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        offset: this.offset,
        limit: this.itemsPerPage,
        ...params,
        ...this.filters,
      };
      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }
      this.$api.paymentReceipt
        .getPaymentReceiptList(params)
        .then((res) => {
          this.allPaymentReceiptList = res.data;
          this.totalItems = res.count;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getPaymentReceiptBlob(id, receipt_id, invoiceDownload = null) {
      let name;
      if (invoiceDownload) {
        name = "Invoice-";
      } else {
        name = "Receipt-";
      }
      this.$bus.$emit("showLoader", true);
      let params = {
        invoice_download: invoiceDownload,
      };
      this.$api.paymentReceipt
        .getPaymentReceiptBlob(id, params)
        .then((resp) => {
          let blob = new Blob([resp.data], {
            type: resp.headers["content-type"],
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = name + receipt_id + ".pdf";
          link.click();
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    paymentReceiptSendToSap(id) {
      this.selectedPaymentReceipt = []
      this.selectedPaymentReceipt.push(id)
      this.sentToSAP()
    },

    addPaymentReceipt() {
      this.editPaymentReceiptID = null;
      this.showPaymentReceiptForm = true;
    },

    editPaymentReceipt(id) {
      this.editPaymentReceiptID = id;
      this.showPaymentReceiptForm = true;
    },
  },
  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("payment_receipt");
      if (!permissions || !permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.permissions = { ...permissions };
        this.getPaymentReceiptList();
      }
    }
  },
};
</script>
