<template>
  <FormComponent
    :backRoute="backRoute"
    title="Ticket Details"
    :nonFieldError="nonFieldError"
  >
    <template #formContent>
      <v-row>
        <v-col cols="9">
          <v-card elevation="2" class="mb-4" color="heading">
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6" md="4" lg="2" class="text-truncate">
                  <span class="text-caption">Title</span>
                  <p class="text-subtitle-1 font-weight-medium mb-0">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          {{ ticketDetails.title }}
                        </div>
                      </template>
                      <span>{{ ticketDetails.title }}</span>
                    </v-tooltip>
                  </p>
                </v-col>

                <v-col cols="12" sm="6" md="4" lg="2">
                  <span class="text-caption">Reference Number</span>
                  <p class="text-subtitle-1 font-weight-medium mb-0">
                    {{ ticketDetails.reference_number }}
                  </p>
                </v-col>

                <v-col cols="12" sm="6" md="4" lg="2">
                  <span class="text-caption">Priority</span>
                  <p class="text-subtitle-1 font-weight-medium mb-0">
                    {{ ticketDetails.priority }}
                  </p>
                </v-col>

                <v-col cols="12" sm="6" md="4" lg="2">
                  <span class="text-caption">Module</span>
                  <p class="text-subtitle-1 font-weight-medium mb-0">
                    {{ ticketDetails.module }}
                  </p>
                </v-col>

                <v-col cols="12" sm="6" md="4" lg="2" v-if="ticketDetails.invalid_remarks">
                  <span class="text-caption">Invalid Remark</span>
                  <p class="text-subtitle-1 font-weight-medium mb-0">
                    {{ ticketDetails.invalid_remarks }}
                  </p>
                </v-col>

                <v-col cols="12" sm="6" md="4" lg="2">
                  <span class="text-caption">Date</span>
                  <p class="text-subtitle-1 font-weight-medium mb-0">
                    {{ ticketDetails.created | formatDate(true) }}
                  </p>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="2">
                  <span class="text-caption">Added by</span>
                  <p class="text-subtitle-1 font-weight-medium mb-0">
                    {{ ticketDetails.added_by_name }}
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card elevation="2">
            <v-card-title
              class="pt-4 pb-2 mt-4 heading text-primary body-1 d-flex justify-space-between"
            >
              <span
                class="cf-info-title font-weight-bold text-uppercase"
                style="opacity: 0.7"
              >
                comments
              </span>
            </v-card-title>

            <v-card-text
              id="adminComment"
              class="overflow-y-auto"
              style="max-height: 295px"
            >
              <v-row
                v-for="(ticket, i) in ticketDetails.ticket_comments"
                :key="i"
              >
                <v-col cols="1">
                  <v-img
                    :src="imageAdmin"
                    style="width: 60px !important; border-radius: 50%"
                    contain
                    alt="User"
                  />
                </v-col>
                <v-col cols="11">
                  <h6
                    class="cf-info-title pa-0 ma-0 font-weight-normal text-primary"
                  >
                    {{ ticket.added_by_name }}
                  </h6>
                  <p class="text-caption font-weight-normal text-primary">
                    {{ ticket.created | formatDate(true) }}
                  </p>
                  <h6 class="font-weight-normal text-subtitle-2 text-black">
                    {{ ticket.comment }}
                  </h6>
                  <h4 v-if="ticket.attachment">
                    <a :href="ticket.attachment" target="_blank">
                      View Attachment
                    </a>
                  </h4>
                  <br />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-row>
                <v-col cols="12" class="mb-0 pb-0">
                  <v-textarea
                    class="mt-2"
                    label="Comment Here..."
                    v-model="comment"
                    outlined
                    rows="3"
                    auto-grow
                  ></v-textarea>
                </v-col>
                <v-col cols="10" class="my-0 py-0">
                  <v-file-input
                    ref="attachmentInput"
                    :clearable="true"
                    hide-details
                    type="file"
                    v-model="attachment"
                    label="Attachment"
                    prepend-inner-icon="mdi-attachment mdi-rotate-90"
                    prepend-icon=""
                    accept="image/*"
                    outlined
                    dense
                    @change="getFileData($event)"
                  ></v-file-input>
                  <br />
                </v-col>
                <v-col cols="2" class="my-0 py-0">
                  <div class="text-right">
                    <v-btn
                      :disabled="!comment"
                      class="primary text-uppercase"
                      @click="sendComment"
                    >
                      <span>Comment</span>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card elevation="2">
            <v-card-title
              class="pt-4 pb-2 heading text-primary body-1 d-flex justify-space-between"
            >
              <span
                class="cf-info-title font-weight-bold text-uppercase"
                style="opacity: 0.7"
              >
                Attachments
              </span>
              <span
                class="cf-info-title text-caption font-weight-bold text-uppercase"
              >
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      color="white"
                      class="primary"
                      small
                      outlined
                      rounded
                      text
                    >
                      {{ ticketDetails.status | titlecase }}
                    </v-btn>
                  </template>
                  <span>Status</span>
                </v-tooltip>
              </span>
            </v-card-title>

            <v-card-text
              class="pa-4"
              v-if="
                ticketDetails.attachment && ticketDetails.attachment.length != 0
              "
            >
              <v-row>
                <v-col
                  cols="3"
                  v-for="(image, i) in ticketDetails.attachment"
                  :key="i"
                >
                  <v-card elevation="2" outlined>
                    <v-card-text class="pa-0">
                      <a :href="image.attachment" target="_blank">
                        <v-img
                          contain
                          :lazy-src="image.attachment"
                          width="100%"
                          min-height="40px"
                          :src="image.attachment"
                        ></v-img>
                      </a>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text
              v-if="
                ticketDetails.attachment && ticketDetails.attachment.length == 0
              "
              class="pa-4 d-flex justify-center align-center fill-height"
            >
              No attachment to show!
            </v-card-text>
          </v-card>
          <v-card elevation="2" class="mt-4" v-if="ticketLogs">
            <v-card-title
              class="pt-4 pb-2 heading text-primary body-1 d-flex justify-space-between"
            >
              <span
                class="cf-info-title font-weight-bold text-uppercase"
                style="opacity: 0.7"
              >
                Logs
              </span>
            </v-card-title>
            <v-card-text class="overflow-y-auto" style="max-height: 430px">
              <v-timeline dense>
                <v-timeline-item
                  small
                  v-for="(ticketLog, index) in ticketLogs"
                  :key="index"
                >
                  <v-card elevation="0" outlined>
                    <v-card-title class="py-1 text-body-2">
                      <span>{{ ticketLog.message }}</span>
                    </v-card-title>
                    <v-card-text class="pt-1">
                      <span>{{ ticketLog.created | formatDate(true) }}</span>
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </FormComponent>
</template>

<script>
import imageAdmin from "@/assets/images/user.png";
import FormComponent from "@/components/General/FormComponent.vue";
import Tooltip from "@/components/General/Tooltip.vue";

export default {
  layout: "mainLayout",
  components: { FormComponent, Tooltip },
  data() {
    return {
      imageAdmin: imageAdmin,
      comment: null,
      attachment: null,
      ticketDetails: {
        reference_number: "title",
      },
      ticketLogs: [],
      nonFieldError: [],

      backRoute: { path: "/app/admin/ticket/list" },
    };
  },
  methods: {
    sendComment() {
      this.$bus.$emit("showLoader", true);
      let commentForm = new FormData();
      commentForm.append("comment", this.comment);
      if (this.attachment) {
        commentForm.append("attachment", this.attachment);
      }

      let payload = {
        id: this.ticketDetails.id,
        data: commentForm,
      };
      this.$api.ticket
        .addTicketComment(payload)
        .then((response) => {
          let scrollingElement = document.getElementById("adminComment");
          scrollingElement.scrollTop = scrollingElement.scrollHeight;
          this.setTicketFormDetails(this.ticketDetails.id);
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$notifier.showMessage({
            content: "Couldn't perform this action!",
            color: "error",
          });
        });
      this.comment = null;
      this.attachment = null;
    },
    imageFileCheck(fileName) {
      let extension = fileName.slice(
        (Math.max(0, fileName.lastIndexOf(".")) || Infinity) + 1
      );
      if (extension == "png" || extension == "jpeg" || extension == "jpg") {
        return true;
      } else {
        this.$notifier.showMessage({
          content: `Select file(s) are not supported.Support file Types: PNG, JPEG, JPG `,
          color: "error",
        });
        return false;
      }
    },
    getFileData(attachmentInput) {
      if (
        attachmentInput &&
        attachmentInput.name &&
        !this.imageFileCheck(attachmentInput.name)
      ) {
        this.$refs.attachmentInput.reset();
        this.attachment = null;
        return false;
      }
      if (attachmentInput) {
        this.attachment = attachmentInput;
      }
    },
    setTicketFormDetails(id) {
      this.$api.ticket
        .getTicketDetails(id)
        .then((res) => {
          this.ticketDetails = res.data;
          this.ticketLogs = res.data.ticket_logs;
        })
        .catch((err) => {
          this.$notifier.showMessage({
            content: "Couln't fetch data",
            color: "error",
          });
        });
    },
  },
  filters: {
    titlecase: function (text) {
      try {
        let string = text.replace("_", " ").replace("-", " ");
        return string.replace(/(^|\s)\S/g, function (t) {
          return t.toUpperCase();
        });
      } catch (TypeError) {
        return text;
      }
    },
  },
  mounted() {
    if (document.getElementById("adminComment")) {
      let scrollingElement = document.getElementById("adminComment");
      scrollingElement.scrollTop = scrollingElement.scrollHeight;
    }
    if ("id" in this.$route.params) {
      this.setTicketFormDetails(this.$route.params.id);
    }
  },
};
</script>
