<template>
  <v-dialog max-width="80%" v-model="showHazardousForm">
    <v-card>
      <v-card-text class="pa-3">
        <FormComponent
          :nonFieldErrors="formErrors.non_field_errors"
          :title="dialogTitle"
          :stickyHeader="true"
        >
          <template #formActions>
            <v-col class="text-right" cols="12">
              <v-btn
                color="primary"
                depressed
                @click="submitForm"
                :disabled="!isValid"
              >
                Save
              </v-btn>

              <v-btn
                class="ml-3"
                color="secondary"
                depressed
                @click="resetForm"
              >
                Cancel
              </v-btn>
            </v-col>
          </template>
          <template #formContent>
            <v-form ref="daListForm" v-if="showHazardousForm" v-model="isValid">
              <v-row class="ma-0">
                <v-col cols="12" lg="6" class="Form-Columns-Dense">
                  <InputField
                    type="text"
                    label="Proper Shipping Name *"
                    required
                    :rules="[
                      (val) => !!val || 'Proper Shipping Name is required!',
                    ]"
                    hide-details="auto"
                    v-model="daHazardousCargoDetails.proper_shipping_name"
                    :error-messages="formErrors.proper_shipping_name"
                    @input="delete formErrors.proper_shipping_name"
                  />
                </v-col>

                <v-col cols="12" lg="6" class="Form-Columns-Dense">
                  <TextareaField
                    label="Comments"
                    rows="1"
                    hide-details="auto"
                    v-model="daHazardousCargoDetails.comments"
                    :error-messages="formErrors.comments"
                    @input="delete formErrors.comments"
                  />
                </v-col>

                <v-col cols="12" lg="6" class="Form-Columns-Dense">
                  <TextareaField
                    label="Emergency Contact *"
                    rows="3"
                    :clearable="false"
                    required
                    :rules="[
                      (val) => !!val || 'Emergency Contact is required!',
                    ]"
                    hide-details="auto"
                    v-model="daHazardousCargoDetails.emergency_contact"
                    :error-messages="formErrors.emergency_contact"
                    @input="delete formErrors.emergency_contact"
                  />
                </v-col>

                <v-col cols="12" lg="6">
                  <v-row>
                    <v-col cols="12" class="Form-Columns-Dense">
                      <InputField
                        type="text"
                        label="App Ref"
                        hide-details="auto"
                        v-model="daHazardousCargoDetails.app_ref"
                        :error-messages="formErrors.app_ref"
                        @input="delete formErrors.app_ref"
                      />
                    </v-col>

                    <v-col cols="12" lg="6" class="Form-Columns-Dense">
                      <v-switch
                        label="Marine Pollutant ?"
                        class="mt-1"
                        hide-details="auto"
                        v-model="daHazardousCargoDetails.is_marine_pollutant"
                        :error-messages="formErrors.is_marine_pollutant"
                        @change="delete formErrors.is_marine_pollutant"
                      ></v-switch>
                    </v-col>

                    <v-col cols="12" lg="6" class="Form-Columns-Dense">
                      <InputField
                        type="text"
                        label="Concentration"
                        hide-details="auto"
                        v-model="daHazardousCargoDetails.concentration"
                        :error-messages="formErrors.concentration"
                        @input="delete formErrors.concentration"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" lg="6">
                  <v-row>
                    <v-col cols="12" lg="6" class="Form-Columns-Dense">
                      <InputField
                        type="number"
                        label="Flash Point *"
                        required
                        :rules="[(val) => !!val || 'Flash Point is required!']"
                        hide-details="auto"
                        v-model="daHazardousCargoDetails.flash_point"
                        :error-messages="formErrors.flash_point"
                        @input="delete formErrors.flash_point"
                      />
                    </v-col>

                    <v-col cols="12" lg="6" class="Form-Columns-Dense">
                      <MultiSelect
                        label="Select Degree Type *"
                        :items="temperatureDegreeTypes"
                        :rules="[(val) => !!val || 'Degree Type is required']"
                        hide-details="auto"
                        v-model="daHazardousCargoDetails.flash_point_degree"
                        :error-messages="formErrors.flash_point_degree"
                        @input="delete formErrors.flash_point_degree"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" lg="6">
                  <v-row>
                    <v-col cols="12" lg="6" class="Form-Columns-Dense">
                      <InputField
                        type="number"
                        label="SADT"
                        hide-details="auto"
                        v-model="daHazardousCargoDetails.sadt"
                        :error-messages="formErrors.sadt"
                        @input="delete formErrors.sadt"
                      />
                    </v-col>

                    <v-col cols="12" lg="6" class="Form-Columns-Dense">
                      <MultiSelect
                        label="Select Degree Type"
                        :items="temperatureDegreeTypes"
                        hide-details="auto"
                        v-model="daHazardousCargoDetails.sadt_degree"
                        :error-messages="formErrors.sadt_degree"
                        @input="delete formErrors.sadt_degree"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" lg="6" class="Form-Columns-Dense">
                  <InputField
                    type="text"
                    label="EMS"
                    hide-details="auto"
                    v-model="daHazardousCargoDetails.ems"
                    :error-messages="formErrors.ems"
                    @input="delete formErrors.ems"
                  />
                </v-col>

                <v-col cols="12" class="Form-Columns-Dense">
                  <InputField
                    type="text"
                    label="NAD"
                    hide-details="auto"
                    v-model="daHazardousCargoDetails.nad"
                    :error-messages="formErrors.nad"
                    @input="delete formErrors.nad"
                  />
                </v-col>

                <v-col cols="12" class="pb-0">
                  <span class="text-h6 font-weight-regular">
                    Classifications
                  </span>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-row>
                    <v-col cols="12" class="Form-Columns-Dense">
                      <InputField
                        type="text"
                        label="Chemical Name"
                        hide-details="auto"
                        v-model="daHazardousCargoDetails.chemical_name"
                        :error-messages="formErrors.chemical_name"
                        @input="delete formErrors.chemical_name"
                      />
                    </v-col>

                    <v-col cols="12" lg="6" class="Form-Columns-Dense">
                      <MultiSelect
                        label="Select UN Class *"
                        :returnObject="true"
                        :items="allUNClassList"
                        itemText="un_no"
                        itemValue="id"
                        :rules="[(val) => !!val || 'UN Class is required']"
                        hide-details="auto"
                        v-model="daHazardousCargoDetails.un_class"
                        :error-messages="formErrors.un_class"
                        @input="
                          delete formErrors.un_class;
                          daHazardousCargoDetails.un_description =
                            daHazardousCargoDetails.un_class.un_description;
                        "
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      lg="6"
                      class="Form-Columns align-self-center"
                    >
                      <div v-if="daHazardousCargoDetails.un_class">
                        <Tooltip
                          v-if="daHazardousCargoDetails.un_description"
                          top
                          tooltipTitle="UN Class Description"
                          :tooltipText="daHazardousCargoDetails.un_description"
                          tooltipClass="text-subtitle-1 font-weight-medium"
                        />
                      </div>
                    </v-col>

                    <v-col cols="12" lg="6" class="Form-Columns-Dense">
                      <MultiSelect
                        label="Select IMO Class *"
                        :returnObject="true"
                        :items="allIMOClassList"
                        itemText="imo_class"
                        itemValue="id"
                        :rules="[(val) => !!val || 'IMO Class is required']"
                        hide-details="auto"
                        v-model="daHazardousCargoDetails.imo_class"
                        :error-messages="formErrors.imo_class"
                        @input="
                          delete formErrors.imo_class;
                          daHazardousCargoDetails.imo_description =
                            daHazardousCargoDetails.imo_class.imo_description;
                        "
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      lg="6"
                      class="Form-Columns align-self-center"
                    >
                      <div v-if="daHazardousCargoDetails.imo_description">
                        <Tooltip
                          v-if="daHazardousCargoDetails.imo_description"
                          top
                          tooltipTitle="IMO Class Description"
                          :tooltipText="daHazardousCargoDetails.imo_description"
                          tooltipClass="text-subtitle-1 font-weight-medium"
                        />
                      </div>
                    </v-col>

                    <v-col cols="12" lg="6" class="Form-Columns-Dense">
                      <MultiSelect
                        label="Select ADR Class *"
                        :returnObject="true"
                        :items="allADRClassList"
                        itemText="adr_no"
                        itemValue="id"
                        :rules="[(val) => !!val || 'ADR Class is required']"
                        hide-details="auto"
                        v-model="daHazardousCargoDetails.adr_class"
                        :error-messages="formErrors.adr_class"
                        @input="
                          delete formErrors.adr_class;
                          daHazardousCargoDetails.adr_description =
                            daHazardousCargoDetails.adr_class.adr_description;
                        "
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      lg="6"
                      class="Form-Columns align-self-center"
                    >
                      <div v-if="daHazardousCargoDetails.adr_description">
                        <Tooltip
                          v-if="daHazardousCargoDetails.adr_description"
                          top
                          tooltipTitle="ADR Class Description"
                          :tooltipText="daHazardousCargoDetails.adr_description"
                          tooltipClass="text-subtitle-1 font-weight-medium"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-row justify-lg="end">
                    <v-col cols="12" sm="7" class="Form-Columns-Dense">
                      <InputField
                        type="text"
                        label="EAC"
                        hide-details="auto"
                        v-model="daHazardousCargoDetails.eac"
                        :error-messages="formErrors.eac"
                        @input="delete formErrors.eac"
                      />
                    </v-col>

                    <v-col cols="12" sm="7" class="Form-Columns-Dense">
                      <InputField
                        type="text"
                        label="Tremcard No"
                        hide-details="auto"
                        v-model="daHazardousCargoDetails.tremcard_no"
                        :error-messages="formErrors.tremcard_no"
                        @input="delete formErrors.tremcard_no"
                      />
                    </v-col>

                    <v-col cols="12" sm="7" class="Form-Columns-Dense">
                      <InputField
                        type="text"
                        label="IBC Code"
                        hide-details="auto"
                        v-model="daHazardousCargoDetails.ibc_code"
                        :error-messages="formErrors.ibc_code"
                        @input="delete formErrors.ibc_code"
                      />
                    </v-col>

                    <v-col cols="12" sm="7" class="Form-Columns-Dense">
                      <InputField
                        type="text"
                        label="ADR HIN"
                        hide-details="auto"
                        v-model="daHazardousCargoDetails.adr_hin"
                        :error-messages="formErrors.adr_hin"
                        @input="delete formErrors.adr_hin"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" class="pb-0">
                  <span class="text-h6 font-weight-regular"> Packaging </span>
                </v-col>

                <v-col cols="12" lg="7">
                  <v-row>
                    <v-col cols="12" lg="4" class="Form-Columns-Dense">
                      <InputField
                        type="text"
                        label="Outer Package Number"
                        hide-details="auto"
                        v-model="daHazardousCargoDetails.outer_package_number"
                        :error-messages="formErrors.outer_package_number"
                        @input="delete formErrors.outer_package_number"
                      />
                    </v-col>

                    <v-col cols="12" lg="4" class="Form-Columns-Dense">
                      <InputField
                        type="text"
                        label="Outer Package Type"
                        hide-details="auto"
                        v-model="daHazardousCargoDetails.outer_package_type"
                        :error-messages="formErrors.outer_package_type"
                        @input="delete formErrors.outer_package_type"
                      />
                    </v-col>

                    <v-col cols="12" lg="4" class="Form-Columns-Dense">
                      <InputField
                        type="number"
                        label="Outer Package Weight"
                        hide-details="auto"
                        v-model="daHazardousCargoDetails.outer_package_weight"
                        :error-messages="formErrors.outer_package_weight"
                        @input="delete formErrors.outer_package_weight"
                      />
                    </v-col>

                    <v-col cols="12" lg="4" class="Form-Columns-Dense">
                      <InputField
                        type="text"
                        label="Inner Package Number"
                        hide-details="auto"
                        v-model="daHazardousCargoDetails.inner_package_number"
                        :error-messages="formErrors.inner_package_number"
                        @input="delete formErrors.inner_package_number"
                      />
                    </v-col>

                    <v-col cols="12" lg="4" class="Form-Columns-Dense">
                      <InputField
                        type="text"
                        label="Inner Package Type"
                        hide-details="auto"
                        v-model="daHazardousCargoDetails.inner_package_type"
                        :error-messages="formErrors.inner_package_type"
                        @input="delete formErrors.inner_package_type"
                      />
                    </v-col>

                    <v-col cols="12" lg="4" class="Form-Columns-Dense">
                      <InputField
                        type="number"
                        label="Inner Package Weight"
                        hide-details="auto"
                        v-model="daHazardousCargoDetails.inner_package_weight"
                        :error-messages="formErrors.inner_package_weight"
                        @input="delete formErrors.inner_package_weight"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" lg="5">
                  <v-row>
                    <v-col cols="12" lg="6" class="Form-Columns-Dense">
                      <v-switch
                        label="Limited Quantity"
                        class="mt-1"
                        hide-details="auto"
                        v-model="daHazardousCargoDetails.is_limited_quantity"
                        :error-messages="formErrors.is_limited_quantity"
                        @change="delete formErrors.is_limited_quantity"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" lg="6" class="Form-Columns-Dense">
                      <InputField
                        type="number"
                        label="Limit Quantity Max"
                        hide-details="auto"
                        v-model="daHazardousCargoDetails.limited_quantity_max"
                        :error-messages="formErrors.limited_quantity_max"
                        @input="delete formErrors.limited_quantity_max"
                      />
                    </v-col>
                    <v-col cols="12" lg="6" class="Form-Columns-Dense">
                      <InputField
                        type="number"
                        label="Packing Group *"
                        :rules="[(val) => !!val || 'Packing Group is required']"
                        hide-details="auto"
                        v-model="daHazardousCargoDetails.packing_group"
                        :error-messages="formErrors.packing_group"
                        @input="delete formErrors.packing_group"
                      />
                    </v-col>
                    <v-col cols="12" lg="6" class="Form-Columns-Dense">
                      <InputField
                        type="number"
                        label="Segregation"
                        hide-details="auto"
                        v-model="daHazardousCargoDetails.segregation"
                        :error-messages="formErrors.segregation"
                        @input="delete formErrors.segregation"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </template>
        </FormComponent>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import SelectField from "@/components/FormBaseComponents/SelectField.vue";
import { AgGridVue } from "ag-grid-vue";
import TextareaField from "@/components/FormBaseComponents/TextareaField.vue";
import FormComponent from "@/components/General/FormComponent.vue";
import Tooltip from "@/components/General/Tooltip.vue";

export default {
  components: {
    AgGridVue,
    InputField,
    SelectField,
    MultiSelect,
    TextareaField,
    FormComponent,
    Tooltip,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    dialogTitle: {
      type: String,
      default: "",
    },

    objID: {
      type: String,
      default: "",
    },
    hazardousId: {
      type: [String, Number, null],
      default: null,
    },
    legDetails: {
      default: false,
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.getUNClassList();
        this.getIMOClassList();
        this.getADRClassList();
        this.getdaHazardousCargoDetails();
        this.daHazardousCargoDetails = {};
      } else {
        this.resetForm();
      }
    },
  },
  computed: {
    showHazardousForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    context() {
      return { parentComponent: this };
    },
  },
  data() {
    return {
      formErrors: {},
      isValid: true,
      editMode: false,
      allUNClassList: [],
      allIMOClassList: [],
      allADRClassList: [],
      selectedDAList: [],
      daList: [],
      daHazardousCargoDetails: {},

      // ag-grid vars
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },
      temperatureDegreeTypes: [
        {
          text: "Celcius",
          value: "Celcius",
        },
        {
          text: "Farenheit",
          value: "Farenheit",
        },
      ],
    };
  },
  methods: {
    getUNClassList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        limit: "all",
      };
      this.$api.unClass
        .getUNClassList(params)
        .then((res) => {
          this.allUNClassList = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getIMOClassList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        limit: "all",
      };
      this.$api.imoClass
        .getIMOClassList(params)
        .then((res) => {
          this.allIMOClassList = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getADRClassList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        limit: "all",
      };
      this.$api.adrClass
        .getADRClassList(params)
        .then((res) => {
          this.allADRClassList = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    resizeGrid() {
      if (this.gridOptions && "api" in this.gridOptions) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },

    getdaHazardousCargoDetails() {
      if (this.hazardousId) {
        this.editMode = true;
        this.$bus.$emit("showLoader", true);
        this.$api.daHazardousCargo
          .getdaHazardousCargoDetails(this.hazardousId)
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.daHazardousCargoDetails = res.data;
          })
          .catch((err) => {
            console.error(err);
            this.$bus.$emit("showLoader", false);
          });
      }
    },
    submitForm() {
      this.$bus.$emit("showLoader", true);
      this.formErrors = {};

      let params = {
        ...this.daHazardousCargoDetails,
        delivery_advice: this.objID,
      };

      if (params.un_class) {
        params.un_class = params.un_class.id;
      }

      if (params.imo_class) {
        params.imo_class = params.imo_class.id;
      }

      if (params.adr_class) {
        params.adr_class = params.adr_class.id;
      }

      if (!this.editMode) {
        this.$api.daHazardousCargo
          .addDAHazardousCargo(params)
          .then(() => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "DA Hazardous Cargo added successfully!",
              color: "success",
            });
            this.resetForm();
            this.$emit("refreshList");
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't add DA Hazardous Cargo!",
              color: "error",
            });
            this.formErrors = err.data;
          });
      } else {
        this.$api.daHazardousCargo
          .updateDAHazardousCargo({
            id: this.hazardousId,
            data: params,
          })
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "DA Hazardous Cargo updated successfully!",
              color: "success",
            });
            this.resetForm();
            this.$emit("refreshList");
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't update DA Hazardous Cargo!",
              color: "error",
            });
            this.formErrors = err.data;
          });
      }
    },

    resetForm() {
      this.showHazardousForm = false;
      this.daHazardousCargoDetails = {};
      this.formErrors = {};
    },
  },
};
</script>
