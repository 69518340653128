<template>
  <div class="d-flex">
    <!-- <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-icon
          v-on="on"
          v-bind="attrs"
          @click="viewItem(params.data.id)"
          class="ml-1 mr-2 my-1"
        >
          mdi-eye
        </v-icon>
      </template>
      <span>View</span>
    </v-tooltip> -->

    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-icon
          v-if="
            params &&
            params.context &&
            params.context.parentComponent &&
            params.context.parentComponent.permissions &&
            params.context.parentComponent.permissions.add &&
            params.context.parentComponent.tab == 0
          "
          color="success"
          v-on="on"
          v-bind="attrs"
          @click="updateStatus(params.data, 'Accepted')"
          class="ml-2 mr-2"
        >
          mdi-check
        </v-icon>
      </template>
      <span>Accept</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-icon
          class="ml-2 mr-2"
          v-if="
            params &&
            params.context &&
            params.context.parentComponent &&
            params.context.parentComponent.permissions &&
            params.context.parentComponent.permissions.add &&
            params.context.parentComponent.tab == 0
          "
          color="error"
          v-on="on"
          v-bind="attrs"
          @click="updateStatus(params.data, 'Rejected')"
        >
          mdi-close
        </v-icon>
      </template>
      <span>Reject</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-icon
          class="ml-2 mr-1"
          color="primary"
          v-on="on"
          v-bind="attrs"
          @click="download"
        >
          mdi-file-download
        </v-icon>
      </template>
      <span>Download</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  methods: {
    download() {
      this.params.context.parentComponent.downloadInvoice(this.params.data.id);
    },
    updateStatus(data, status) {
      this.params.context.parentComponent.openRemarksForm(data, status);
    },
    viewItem(id) {
      this.params.context.parentComponent.viewInvoice(id);
    },
  },
};
</script>

<style>
</style>