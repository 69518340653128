var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      _vm.params &&
      _vm.params.context &&
      _vm.params.context.parentComponent &&
      _vm.params.context.parentComponent.da_permissions &&
      _vm.params.context.parentComponent.da_permissions.add &&
      (_vm.params.context.parentComponent.tab == 0 ||
        _vm.params.context.parentComponent.tab == 1)
    )?_c('v-btn',{attrs:{"depressed":"","color":"primary","small":"","disabled":_vm.params.data.all_container_utilized},on:{"click":_vm.createDeliveryAdvice}},[_vm._v(" Create DA ")]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":_vm.viewItem}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])]}}])},[_c('span',[_vm._v("View")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }