import Blank from "@/layouts/blank.vue"

import RoleManagementList from "@/pages/customer/role-management/RolesList.vue";
import RoleManagementAdd from "@/pages/customer/role-management/RoleAdd.vue";
import RoleManagementEdit from "@/pages/customer/role-management/RoleEdit.vue";

const roleManagementRoutes = [
  {
    path: "role-management",
    name: "app-customer-role-management",
    component: Blank,
    children: [
      {
        path: "/",
        redirect: "/app/customer/role-management/list",
      },
      {
        path: "list",
        name: "app-customer-role-management-list",
        component: RoleManagementList,
      },
      {
        path: "add",
        name: "app-customer-role-management-add",
        component: RoleManagementAdd,
      },
      {
        path: "edit",
        name: "app-customer-role-management-edit",
        component: Blank,
        children: [
          {
            path: "/",
            redirect: "/app/customer/role-management/list",
          },
          {
            path: ":id",
            name: "app-customer-role-management-edit-id",
            component: RoleManagementEdit,
          }
        ]
      },
    ]
  },
]

export default roleManagementRoutes