import Blank from "@/layouts/blank.vue"

import DriverList from "@/pages/admin/asset-management/driver/DriverList.vue";
import DriverAdd from "@/pages/admin/asset-management/driver/DriverAdd.vue";
import DriverEdit from "@/pages/admin/asset-management/driver/DriverEdit.vue";

const driverRoutes = [
  {
    path: "driver",
    name: "app-admin-asset-management-driver",
    component: Blank,
    children: [
      {
        path: "/",
        redirect: "/app/admin/asset-management/driver/list",
      },
      {
        path: "list",
        name: "app-admin-asset-management-asset-list",
        component: DriverList,
      },
      {
        path: "add",
        name: "app-admin-asset-management-asset-add",
        component: DriverAdd,
      },
      {
        path: "edit",
        name: "app-admin-asset-management-asset-edit",
        component: Blank,
        children: [
          {
            path: "/",
            redirect: "/app/admin/asset-management/driver/list",
          },
          {
            path: ":id",
            name: "app-admin-asset-management-asset-edit-id",
            component: DriverEdit,
          }
        ]
      },
    ]
  },
]

export default driverRoutes