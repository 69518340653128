<template>
  <div>
    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-icon
          v-if="isAllowed()"
          color="primary"
          v-on="on"
          v-bind="attrs"
          @click="editItem"
        >
          mdi-pencil
        </v-icon>
      </template>
      <span>Edit</span>
    </v-tooltip>
    <v-tooltip top>
      <template
        v-if="params.data.is_success == 'Yes'"
        #activator="{ on, attrs }"
      >
        <v-icon color="primary" v-on="on" v-bind="attrs" @click="viewDetails">
          mdi-eye
        </v-icon>
      </template>
      <span>View</span>
    </v-tooltip>
    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-icon
          v-if="params.data.is_success == 'No'"
          color="primary"
          v-on="on"
          v-bind="attrs"
          @click="viewError"
        >
          mdi-alert-circle
        </v-icon>
      </template>
      <span>View Error</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  methods: {
    editItem() {
      this.params.context.parentComponent.addManually(this.params.data);
    },
    viewDetails() {
      this.params.context.parentComponent.viewDetails(
        this.params.data.response_body
      );
    },
    viewError() {
      this.params.context.parentComponent.viewError(this.params.data);
    },
    isAllowed() {
      return (
        this.params &&
        this.params.data &&
        this.params.data.is_success == "No" &&
        this.params.context &&
        this.params.context.parentComponent &&
        this.params.data.request_body &&
        this.params.data.request_body.company_type &&
        ((this.params.data.request_body.company_type.toLowerCase() ==
          "customer" &&
          this.params.context.parentComponent.customerPermissions &&
          this.params.context.parentComponent.customerPermissions.add) ||
          (this.params.data.request_body.company_type.toLowerCase() ==
            "transporter" &&
            this.params.context.parentComponent.transporterPermissions &&
            this.params.context.parentComponent.transporterPermissions.add))
      );
    },
  },
};
</script>
