<template>
  <div>
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-icon
          v-if="
            params &&
            params.context &&
            params.context.parentComponent &&
            params.context.parentComponent.permissions &&
            params.context.parentComponent.permissions.change
          "
          color="primary"
          v-on="on"
          v-bind="attrs"
          @click="editItem"
          >mdi-pencil</v-icon
        >
      </template>
      <span>Edit</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  methods: {
    editItem() {
      this.params.context.parentComponent.editVehicleType(this.params.data.id);
    },
  },
};
</script>

<style>
</style>