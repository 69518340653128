var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1 mr-2",attrs:{"color":"primary"},on:{"click":_vm.viewItem}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])]}}])},[_c('span',[_vm._v("View")])]),(_vm.params.context.parentComponent.tab != 3)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
          _vm.params &&
          _vm.params.context &&
          _vm.params.context.parentComponent &&
          _vm.params.context.parentComponent.permissions &&
          _vm.params.context.parentComponent.permissions.change
        )?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 mr-2",attrs:{"color":"primary"},on:{"click":_vm.editItem}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")]):_vm._e()]}}],null,false,3559109468)},[_c('span',[_vm._v("Edit")])]):_vm._e(),(_vm.params.context.parentComponent.tab != 3)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
          _vm.params &&
          _vm.params.context &&
          _vm.params.context.parentComponent &&
          _vm.params.context.parentComponent.permissions &&
          _vm.params.context.parentComponent.permissions.add
        )?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 mr-2",attrs:{"color":"primary"},on:{"click":_vm.copyItem}},'v-icon',attrs,false),on),[_vm._v(" mdi-content-copy ")]):_vm._e()]}}],null,false,1357747710)},[_c('span',[_vm._v("Copy data to add form")])]):_vm._e(),(_vm.params.context.parentComponent.tab == 0)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
          _vm.params &&
          _vm.params.context &&
          _vm.params.context.parentComponent &&
          _vm.params.context.parentComponent.permissions &&
          _vm.params.context.parentComponent.permissions.add
        )?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 mr-2",attrs:{"color":"success"},on:{"click":function($event){return _vm.updateStatus(_vm.params.data, 'Active')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-check ")]):_vm._e()]}}],null,false,2153540967)},[_c('span',[_vm._v("Accept")])]):_vm._e(),(_vm.params.context.parentComponent.tab == 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
          _vm.params &&
          _vm.params.context &&
          _vm.params.context.parentComponent &&
          _vm.params.context.parentComponent.permissions &&
          _vm.params.context.parentComponent.permissions.add
        )?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 mr-1",attrs:{"color":"error"},on:{"click":function($event){return _vm.updateStatus(_vm.params.data, 'Rejected')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-close ")]):_vm._e()]}}],null,false,1849738541)},[_c('span',[_vm._v("Reject")])]):_vm._e(),(_vm.params.context.parentComponent.tab == 3)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
          _vm.params &&
          _vm.params.context &&
          _vm.params.context.parentComponent &&
          _vm.params.context.parentComponent.permissions &&
          _vm.params.context.parentComponent.permissions.add &&
          !_vm.params.data.has_been_renewed
        )?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 mr-1",attrs:{"color":"primary"},on:{"click":_vm.renew}},'v-icon',attrs,false),on),[_vm._v(" mdi-autorenew ")]):_vm._e()]}}],null,false,3777350904)},[_c('span',[_vm._v("Renew Contract")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }