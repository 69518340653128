var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.params.data.charge_type != 'Other')?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
            _vm.params.context &&
            _vm.params.context.parentComponent &&
            _vm.params.context.parentComponent.invoiceDetails &&
            _vm.params.context.parentComponent.invoiceDetails.status != 'Accepted'
          )?_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1 mr-2",attrs:{"depressed":"","icon":""},on:{"click":_vm.addCharges}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-plus")])],1):_vm._e()]}}],null,false,2335881132)},[_c('span',[_vm._v("Add Charge")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
            _vm.params.context &&
            _vm.params.context.parentComponent &&
            _vm.params.context.parentComponent.invoiceDetails &&
            _vm.params.context.parentComponent.invoiceDetails.status != 'Accepted' &&
            _vm.params.context.parentComponent.invoiceDetails.total_invoice_das >  1
          )?_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2 mr-1",attrs:{"depressed":"","icon":""},on:{"click":_vm.removeDa}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-trash-can")])],1):_vm._e()]}}],null,false,2458691927)},[_c('span',[_vm._v("Remove DA")])])],1):_vm._e(),(_vm.params.data.charge_type == 'Other')?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
            _vm.params.context &&
            _vm.params.context.parentComponent &&
            _vm.params.context.parentComponent.invoiceDetails &&
            _vm.params.context.parentComponent.invoiceDetails.status != 'Accepted'
          )?_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1 mr-2",attrs:{"depressed":"","icon":""},on:{"click":_vm.editCharges}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-pencil")])],1):_vm._e()]}}],null,false,1506036694)},[_c('span',[_vm._v("Edit Charge")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
            _vm.params.context &&
            _vm.params.context.parentComponent &&
            _vm.params.context.parentComponent.invoiceDetails &&
            _vm.params.context.parentComponent.invoiceDetails.status != 'Accepted'
          )?_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2 mr-1",attrs:{"depressed":"","icon":""},on:{"click":_vm.deleteCharge}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v(" mdi-trash-can-outline ")])],1):_vm._e()]}}],null,false,1653437179)},[_c('span',[_vm._v("Delete Charge")])])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }