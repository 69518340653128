import { charge } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
    getChargeList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(charge.chargeBase, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getChargeSelectList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${charge.chargeBase}select/`, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getChargeObject(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${charge.chargeBase}${id}/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    addCharge(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(charge.chargeBase, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getChargeOptions() {
        return new Promise((resolve, reject) => {
          axios
            .options(`${charge.chargeBase}`)
            .then((res) => {
              resolve(handleResponse(res));
            })
            .catch((err) => {
              reject(handleError(err));
            });
        });
      },
    updateCharge(payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(`${charge.chargeBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    partiallyUpdateCharge(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${charge.chargeBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
});