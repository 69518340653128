<template>
  <v-dialog width="500" v-model="showBankDetailsForm">
    <v-card>
      <v-card-title class="secondary white--text">
        <span> {{ editMode ? "Update" : "Add" }} Bank Details </span>

        <v-spacer></v-spacer>

        <v-btn icon @click="resetForm(), (showBankDetailsForm = false)">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-2">
        <v-form ref="BankDetailForm" v-if="showBankDetailsForm" v-model="isValid">
          <v-row class="my-0">
            <v-col cols="12" class="Form-Columns">
              <InputField
                type="text"
                label="Bank Code *"
                required
                :rules="[(val) => !!val || 'Bank Code is Required!']"
                hide-details="auto"
                v-model="formDetails.bank_code"
                :error-messages="formErrors.bank_code"
                @input="delete formErrors.bank_code"
              />
            </v-col>

            <v-col cols="12" class="Form-Columns">
              <InputField
                type="text"
                label="Bank Name *"
                hide-details="auto"
                :rules="[(val) => !!val || 'Bank Name is Required!']"
                v-model="formDetails.bank_name"
                :error-messages="formErrors.bank_name"
                @input="delete formErrors.bank_name"
              />
            </v-col>
            <v-col cols="12" class="Form-Columns">
              <InputField
                type="text"
                label="Sap Account No *"
                hide-details="auto"
                :rules="[(val) => !!val || 'Sap Account No is Required!']"
                v-model="formDetails.sap_account_no"
                :error-messages="formErrors.sap_account_no"
                @input="delete formErrors.sap_account_no"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          depressed
          :disabled="!isValid"
          @click="submitForm"
        >
          Submit
        </v-btn>

        <v-btn
          class="ml-2"
          color="secondary"
          v-if="!editMode"
          depressed
          @click="resetForm"
        >
          Reset
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import SelectField from "@/components/FormBaseComponents/SelectField.vue";

export default {
  components: {
    InputField,
    SelectField,
    MultiSelect,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    editBankDetailsID: {
      type: [Number, null],
      default: null,
    },
  },
  watch: {
    value(val) {
      if (val) {
        if (this.editMode) {
          this.getBankDetailsObject(this.editBankDetailsID);
        }
      } else {
        this.resetForm();
      }
    },
  },
  computed: {
    showBankDetailsForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    return {
      formDetails: {},
      formErrors: {},
      isTextInput: false,
      isValid: true,
    };
  },
  methods: {
    submitForm() {
      this.$bus.$emit("showLoader", true);
      if (!this.editMode) {
        this.formDetails = {
          ...this.formDetails,
        };
        this.$api.bankDetails
          .addbankDetails(this.formDetails)
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Bank Details added successfully!",
              color: "success",
            });
            this.resetForm();
            this.showBankDetailsForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't add Bank Detail!",
              color: "error",
            });
            this.formErrors = err.data;
          });
      } else {
        this.$api.bankDetails
          .updatebankDetails({ id: this.formDetails.id, data: this.formDetails })
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Bank Details updated successfully!",
              color: "success",
            });
            this.resetForm();
            this.showBankDetailsForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't update Bank Details!",
              color: "error",
            });
            this.formErrors = err.data;
          });
      }
    },

    resetForm() {
      if (this.$refs.BankDetailForm) {
        this.$refs.BankDetailForm.reset();
      }
      this.formDetails = {};
    },

    getBankDetailsObject(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.bankDetails
        .getbankDetailsObject(id)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.formDetails = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
};
</script>
