import { legCharges } from "@/utils/constants";
import {
  handleError,
  handleResponse
} from "@/utils/functions";

export default (axios) => ({
  getlegChargesList(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(legCharges.legChargesBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getLegChargesList(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(legCharges.legChargesBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getlegChargesDetails(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${legCharges.legChargesBase}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  addLegCharge(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(legCharges.legChargesBase, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  updateLegCharge(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${legCharges.legChargesBase}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  deleteLegCharge(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${legCharges.legChargesBase}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});