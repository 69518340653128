<template>
  <v-row class="ma-0">
    <v-col cols="12" class="pa-0">
      <span class="text-h5">Spot Rate</span>
    </v-col>

    <v-col cols="12" class="px-0">
      <v-divider></v-divider>
    </v-col>

    <v-col cols="12" md="12" class="Height-Class pa-0">
      <v-row class="ma-0">
        <v-col cols="12" class="pa-0">
          <v-form v-model="isValid" ref="generateQuotesForm">
            <v-row class="ma-0">
              <v-col cols="12" sm="6" lg="4" class="pa-2">
                <MultiSelect
                  hide-details="auto"
                  label="Source Location *"
                  :items="sourceList"
                  itemText="name"
                  itemValue="id"
                  :item-disabled="
                    (item) => {
                      return item.id == spotRateDetails.destination_location;
                    }
                  "
                  :returnObject="true"
                  :rules="[(v) => !!v || 'Source Location is Required']"
                  v-model="spotRateDetails.source_location"
                  :error-messages="formErrors.source_location"
                  @change="
                    (formErrors.source_location = ''),
                      checkData($event, 'source_location')
                  "
                />
              </v-col>

              <v-col cols="12" sm="6" lg="4" class="pa-2">
                <v-text-field
                  hide-details="auto"
                  label="Source State"
                  dense
                  outlined
                  readonly
                  :clearable="false"
                  v-model="source_state"
                />
              </v-col>

              <v-col cols="12" sm="6" lg="4" class="pa-2">
                <v-text-field
                  hide-details="auto"
                  label="Source Country"
                  dense
                  outlined
                  readonly
                  :clearable="false"
                  v-model="source_country"
                />
              </v-col>

              <v-col cols="12" sm="6" lg="4" class="pa-2">
                <MultiSelect
                  hide-details="auto"
                  label="Destination Location *"
                  :items="destinationList"
                  itemText="name"
                  itemValue="id"
                  :item-disabled="
                    (item) => {
                      return item.id == spotRateDetails.source_location;
                    }
                  "
                  :returnObject="true"
                  :rules="[(v) => !!v || 'Destination Location is Required']"
                  v-model="spotRateDetails.destination_location"
                  :error-messages="formErrors.destination_location"
                  @change="
                    (formErrors.destination_location = ''),
                      checkData($event, 'destination_location')
                  "
                />
              </v-col>

              <v-col cols="12" sm="6" lg="4" class="pa-2">
                <v-text-field
                  hide-details="auto"
                  label="Destination State"
                  dense
                  outlined
                  readonly
                  :clearable="false"
                  v-model="destination_state"
                />
              </v-col>

              <v-col cols="12" sm="6" lg="4" class="pa-2">
                <v-text-field
                  hide-details="auto"
                  label="Destination Country"
                  dense
                  outlined
                  readonly
                  :clearable="false"
                  v-model="destination_country"
                />
              </v-col>

              <v-col cols="12" sm="6" lg="4" class="pa-2">
                <v-text-field
                  hide-details="auto"
                  label="Distance"
                  dense
                  outlined
                  readonly
                  :clearable="false"
                  v-model="spotRateDetails.distance"
                />
              </v-col>

              <v-col cols="12" sm="6" lg="4" class="pa-2">
                <v-text-field
                  hide-details="auto"
                  label="Service Type"
                  dense
                  outlined
                  readonly
                  :clearable="false"
                  v-model="spotRateDetails.service_type"
                />
              </v-col>

              <v-col cols="12" sm="6" lg="4" class="pa-2">
                <DateField
                  :inputProps="{
                    dense: true,
                    label: 'Shipment Date *',
                    'hide-details': 'auto',
                    required: true,
                    rules: [(val) => !!val || 'Shipment Date is Required!'],
                    'error-messages': formErrors.shipment_date,
                    min: today,
                  }"
                  :min="today"
                  v-model="spotRateDetails.shipment_date"
                  @input="formErrors.shipment_date = ''"
                />
              </v-col>

              <v-col cols="12" sm="6" lg="4" class="pa-2">
                <MultiSelect
                  hide-details="auto"
                  label="Job Type *"
                  :items="allJobType"
                  itemText="display_name"
                  itemValue="value"
                  v-model="spotRateDetails.job_type"
                  :rules="[(v) => !!v || 'Job Type is Required']"
                  :error-messages="formErrors['job_type']"
                  @change="
                    formErrors['job_type'] = '';
                    checkData($event, 'job_type');
                  "
                />
              </v-col>
              <v-col cols="12" class="pa-2 d-flex">
                <v-btn
                  color="primary"
                  :disabled="!isValid"
                  depressed
                  @click="submitForm"
                >
                  Get Rates
                </v-btn>
                <v-switch
                  hide-details="auto"
                  :label="`Request Quotation Location : ${
                    showQuotationForm ? 'Yes' : 'No'
                  }`"
                  class="mt-1 ml-4"
                  v-show="requestedOnce"
                  v-model="showQuotationForm"
                ></v-switch>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col cols="12" class="px-0 py-2">
          <v-divider></v-divider>
        </v-col>

        <v-col cols="12" class="pa-0" v-show="responseGenerateQuotes">
          <v-row class="ma-0">
            <v-col cols="12" class="pa-2">
              <v-data-table
                v-if="rateInfoList.length > 0"
                v-model="selectedRate"
                :headers="headers"
                :items="rateInfoList"
                selectable-key="is_selectable"
                item-key="bmr"
                :show-select="
                  contract_permissions &&
                  (contract_permissions.add || contract_permissions.change)
                "
                class="elevation-0"
                hide-default-footer
              />
            </v-col>

            <v-col
              cols="12"
              class="px-2 pt-2 pb-0"
              v-if="existingContracts.length > 0"
            >
              <span class="text-h6"> Existing Tariff</span>
            </v-col>

            <v-col cols="12" class="pa-2" v-if="existingContracts.length > 0">
              <v-data-table
                :headers="existingContractHeaders"
                :items="existingContracts"
                item-key="bmr"
                class="elevation-0"
                hide-default-footer
              />
            </v-col>
            <v-col
              cols="12"
              class="px-0 py-2"
              v-if="existingContracts.length > 0"
            >
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-btn
                v-if="
                  contract_permissions &&
                  (contract_permissions.add || contract_permissions.change) &&
                  (showButton == 'Create' || showButton == 'Update')
                "
                color="primary"
                depressed
                :disabled="!selectedRate.length"
                @click="showContractForm"
              >
                {{ showButton }} Tariff
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" v-if="showQuotationForm">
          <v-form v-model="isQuotationFormValid" ref="requestQuotesForm">
            <v-row
              v-for="(cr, i) in quotationRates"
              :key="i"
              class="ma-0 heading rounded-lg mb-2"
            >
              <v-col cols="12">
                <div class="d-flex">
                  <span class="text-h6">
                    Container Details {{ i + 1 }}
                  </span>

                  <v-spacer></v-spacer>

                  <v-btn
                    color="primary"
                    depressed
                    small
                    @click="addContainer"
                    class="mr-2"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>

                  <v-btn
                    color="secondary"
                    depressed
                    small
                    :disabled="i == 0"
                    @click="removeContainer(i)"
                  >
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                </div>
                <v-divider></v-divider>
              </v-col>

              <v-col cols="12" sm="6" class="Form-Columns">
                <MultiSelect
                  label="Container Type *"
                  :items="allContainersType"
                  itemText="title"
                  itemValue="id"
                  :rules="[(v) => !!v || 'Container Type is Required']"
                  v-model="cr.container_type"
                  :error-messages="
                    quotationErrors &&
                    quotationErrors.quotation_rates &&
                    quotationErrors.quotation_rates[i] &&
                    quotationErrors.quotation_rates[i]['container_type']
                      ? quotationErrors.quotation_rates[i]['container_type']
                      : null
                  "
                  @change="
                    quotationErrors &&
                    quotationErrors.quotation_rates &&
                    quotationErrors.quotation_rates[i] &&
                    quotationErrors.quotation_rates[i]['container_type']
                      ? delete quotationErrors.quotation_rates[i][
                          'container_type'
                        ]
                      : null
                  "
                />
              </v-col>

              <v-col cols="12" sm="6" class="Form-Columns">
                <InputField
                  type="number"
                  label="Container Count*"
                  max="4"
                  min="1"
                  :rules="[
                    (v) => !!v || 'Count is Required',
                    (v) => (v > 0 && v < 5) || 'Count must be between 1 to 4',
                  ]"
                  v-model="cr.container_count"
                  :error-messages="
                    quotationErrors &&
                    quotationErrors.quotation_rates &&
                    quotationErrors.quotation_rates[i] &&
                    quotationErrors.quotation_rates[i]['container_count']
                      ? quotationErrors.quotation_rates[i]['container_count']
                      : null
                  "
                  @change="
                    quotationErrors &&
                    quotationErrors.quotation_rates &&
                    quotationErrors.quotation_rates[i] &&
                    quotationErrors.quotation_rates[i]['container_count']
                      ? delete quotationErrors.quotation_rates[i][
                          'container_count'
                        ]
                      : null
                  "
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="text-center">
                <v-divider></v-divider>
                <v-btn
                  color="primary"
                  class="mt-4"
                  depressed
                  :disabled="!isQuotationFormValid"
                  @click="showRemarksForm = true"
                >
                  Request Quotation(s)
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" md="4">
      <GoogleMap
        mapWidth="100%"
        mapHeight="100%"
        ref="shipperRateMap"
        class="Map-Height"
        style="display: none"
      >
        <template #mapInfo>
          <v-card-text>
            <v-row>
              <v-col cols="12" class="d-flex align-center pa-2">
                <img style="width: 18px" :src="sourceMarker" />
                <span class="ml-4 text-body-2 font-weight-bold">Source</span>
              </v-col>

              <v-col cols="12" class="d-flex align-center pa-2">
                <img style="width: 18px" :src="destinationMarker" />
                <span class="ml-4 text-body-2 font-weight-bold">
                  Destination
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </template>
      </GoogleMap>
    </v-col>

    <RejectionRemarksDialog
      v-model="showRemarksForm"
      :formDetails="spotRateDetails"
      fieldKey="customer_remarks"
      label="Remarks"
      :isRequiredRemarks="false"
      @submitRemarks="requestQuotation()"
    />

    <ContractCreateForm
      v-model="isContractForm"
      :formData="formData"
      :editMode="isEditing"
      @createContract="submitContractForm"
    />
  </v-row>
</template>

<script>
import GoogleMap from "@/components/General/GoogleMap.vue";
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import ContractCreateForm from "@/components/ModuleBased/dialogForms/ContractCreateForm.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";

import RejectionRemarksDialog from "@/components/ModuleBased/dialogs/RejectionRemarksDialog.vue";
import { sourceMarker, destinationMarker } from "@/assets/images/mapIcons.js";
import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    InputField,
    MultiSelect,
    GoogleMap,
    ContractCreateForm,
    DateField,
    RejectionRemarksDialog,
  },
  watch: {},
  data() {
    return {
      sourceMarker,
      destinationMarker,
      spotRateDetails: {
        round_trip: "No",
      },
      formErrors: {},
      quotationRates: [{}],
      isValid: true,

      // autocomplete vars
      source_name: null,
      source_state: null,
      source_country: null,
      destination_state: null,
      destination_country: null,

      sourceList: [],
      destinationList: [],

      rateInfoList: [],
      existingContracts: [],
      responseGenerateQuotes: false,
      showRemarksForm: false,
      isQuotationFormValid: true,
      requestedOnce: false,
      showQuotationForm: false,
      showButton: null,
      isEditing: false,
      customerContractID: null,
      permissions: null,
      contract_permissions: null,

      selectedRate: [],
      allContainersType: [],
      allJobType: [],

      headers: [
        {
          text: "Container Type",
          align: "start",
          sortable: false,
          value: "container_type_title",
        },
        { text: "Container Count", value: "container_count" },
        { text: `Rate (${this.$globals.currency})`, value: "rate" },
      ],
      existingContractHeaders: [
        {
          text: "Container Type",
          align: "start",
          sortable: false,
          value: "container_type_title",
        },
        { text: "Container Count", value: "container_count" },
        { text: "Tariff", value: "customer_contract_id" },
        { text: "Status", value: "customer_contract_status" },
      ],

      // contract var
      formData: {},
      isContractForm: false,
      today: new Date().toISOString().slice(0, 10),

      quotationErrors: {},

      directions: [],
      directionsTimeout: null,
      selectedRoute: {},
      markerTimeout: null,
    };
  },
  computed: {
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (!obj) {
        return null;
      } else {
        return obj;
      }
    },
  },
  methods: {
    addContainer() {
      this.quotationRates.push({});
    },
    removeContainer(index) {
      this.quotationRates.splice(index, 1);
      if (this.quotationErrors && this.quotationErrors.quotation_rates) {
        this.quotationErrors.quotation_rates.splice(index, 1);
      }
    },
    getContainersType() {
      this.$api.containerType
        .getContainerTypeList({ limit: "all" })
        .then((res) => {
          this.allContainersType = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    requestQuotation() {
      this.showRemarksForm = false;

      let params = {
        quotation_rates: this.quotationRates,
        service_type: this.spotRateDetails.service_type,
        customer_remarks: this.spotRateDetails.customer_remarks,
        distance: this.spotRateDetails.distance,
        job_type: this.spotRateDetails.job_type,
      };

      if (params.distance) {
        params.distance = parseFloat(params.distance.replace(/\,/g, ""));
      }

      if (this.formData.customer_contract) {
        params.customer_contract = this.formData.customer_contract;
      } else {
        params.start_date = this.spotRateDetails.shipment_date;
        params.customer = this.currentProfile.company;
        params.source_location = this.spotRateDetails.source_location;
        params.destination_location = this.spotRateDetails.destination_location;
        params.round_trip = this.spotRateDetails.round_trip;
      }

      this.$api.quotation
        .addQuotationFromLocation(params)
        .then(() => {
          this.quotationRates = [{}];
          this.showQuotationForm = true;
          this.showRemarksForm = false;
          this.$router.push({
            path: `/app/customer/rate/quotation/list`,
          });

          delete this.spotRateDetails.customer_remarks;

          this.$bus.$emit("showToastMessage", {
            message: "Quotation Requested!",
            color: "success",
          });
        })
        .catch((err) => {
          this.quotationErrors = err.data;
          if (err.data.non_field_errors) {
            this.$bus.$emit("showToastMessage", {
              message: err.data.non_field_errors.join(),
              color: "error",
            });
          }
          delete this.spotRateDetails.customer_remarks;
        });
    },
    checkData(obj, key) {
      if (obj) {
        if (key == "source_location") {
          this.sourceCoordinates = obj.coordinates;
          this.source_state = obj.location_state;
          this.source_country = obj.location_country;
          this.spotRateDetails[key] = obj.id;
        } else if (key == "destination_location") {
          this.destinationCoordinates = obj.coordinates;
          this.destination_state = obj.location_state;
          this.destination_country = obj.location_country;
          this.spotRateDetails[key] = obj.id;
        }
        this.responseGenerateQuotes = false;
        this.showQuotationForm = false;
        this.quotationRates = [{}];
        delete this.formData.customer_contract;

        this.getSavedRoute();
      }
    },

    showContractForm() {
      let obj = { ...this.spotRateDetails };
      this.formData.start_date = obj.shipment_date;
      if (this.showButton == "Update") {
        this.submitContractForm();
      } else {
        this.isContractForm = true;
      }
    },

    submitContractForm() {
      if (this.showButton == "Create") {
        this.formData = {
          ...this.formData,
          customer: this.currentProfile.company,
          created_from: "Rate Inquiry",
          round_trip: this.spotRateDetails.round_trip,
          customer_contract_rates: this.selectedRate,
        };
        this.$api.customerContract
          .addCustomerContract(this.formData)
          .then((res) => {
            this.$bus.$emit("showToastMessage", {
              message: "Tariff created successfully!",
              color: "success",
            });
            this.$router.push("/app/customer/contract");
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        this.formData = {
          customer_contract_rates: this.selectedRate,
        };
        let payload = {
          data: this.formData,
          id: this.customerContractID,
        };
        this.$api.customerContract
          .addRatesToExistingContract(payload)
          .then((res) => {
            this.$bus.$emit("showToastMessage", {
              message: "Tariff Updated successfully!",
              color: "success",
            });
            this.$router.push("/app/customer/contract");
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    submitForm() {
      this.$bus.$emit("showLoader", true);

      let data = {
        ...this.spotRateDetails,
        customer: this.currentProfile.company,
      };

      if (data.distance) {
        data.distance = parseFloat(data.distance.replace(/\,/g, ""));
      }

      this.selectedRate = [];
      this.rateInfoList = [];
      this.responseGenerateQuotes = false;
      this.requestedOnce = true;

      this.$api.enquiry
        .getGeneratedQuotes(data)
        .then((res) => {
          this.$bus.$emit("showLoader", false);

          this.responseGenerateQuotes = true;
          this.showButton = res.data.contract_button;
          this.customerContractID = res.data.customer_contract;
          this.existingContracts = res.data.customer_rate_enquiry_rates.filter(
            (obj) => obj.does_contract_exist
          );
          this.rateInfoList = res.data.customer_rate_enquiry_rates.filter(
            (obj) => {
              if (!obj.does_contract_exist) {
                return obj;
              }
            }
          );
          this.formData = res.data;
        })
        .catch((err) => {
          if (err.status === 404) {
            this.$bus.$emit("showToastMessage", {
              message: err.data.msg,
              color: "error",
            });
            this.$bus.$emit("showLoader", false);
          } else {
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't Generate Quotes!",
              color: "error",
            });
            this.$bus.$emit("showLoader", false);
            this.formErrors = err.data;
          }
        });
    },

    getSavedRoute() {
      if (
        this.spotRateDetails.source_location &&
        this.spotRateDetails.destination_location
      ) {
        if (this.directionsTimeout) {
          clearTimeout(this.directionsTimeout);
        }

        this.directionsTimeout = setTimeout(() => {
          this.$api.lane
            .getLaneRoute({
              source_location: this.spotRateDetails.source_location,
              destination_location: this.spotRateDetails.destination_location,
            })
            .then((res) => {
              this.$bus.$emit("showLoader", false);

              if (this.source_country == this.destination_country) {
                this.spotRateDetails.service_type = "local";
              } else {
                this.spotRateDetails.service_type = "Cross Border";
              }

              if (res && res.data && res.data.route) {
                this.selectedRoute.route =
                  this.$refs.shipperRateMap.generateGeoJsonFromLineString(
                    res.data.route
                  );
                if (res.data.distance) {
                  this.selectedRoute.distance = res.data.distance;
                }
                this.loadMarkers();
                this.loadRoute();
              } else {
                this.getRoute();
              }
            })
            .catch((err) => {
              console.error(err);
              this.$bus.$emit("showLoader", false);
            });
        }, 200);
      }
    },

    getRoute() {
      if (this.sourceCoordinates && this.destinationCoordinates) {
        this.spotRateDetails.source_coordinates = this.sourceCoordinates;
        this.spotRateDetails.destination_coordinates =
          this.destinationCoordinates;

        this.$bus.$emit("showLoader", true);

        const directionConfig = {
          origin: {
            lat: this.sourceCoordinates.latitude,
            lng: this.sourceCoordinates.longitude,
          },
          destination: {
            lat: this.destinationCoordinates.latitude,
            lng: this.destinationCoordinates.longitude,
          },
          travelMode: "DRIVING",
          provideRouteAlternatives: true,
        };

        if (this.waypoints && this.waypoints.length) {
          directionConfig.waypoints = this.waypoints;
        }

        this.$refs.shipperRateMap
          .getDirections(directionConfig)
          .then(async (resp) => {
            const directions = resp.routes;
            this.$bus.$emit("showLoader", false);
            this.directions = [];

            let index = 0;
            while (directions.length > this.directions.length) {
              this.directions.push({
                name: `Route ${index + 1}`,
                distance: directions[index].legs[0].distance.text,
                route: this.$refs.shipperRateMap.generateGeoJsonFromLineString(
                  await this.$refs.shipperRateMap.generateLineStringFromRoute(
                    directions[index]
                  )
                ),
              });
              index++;
            }

            this.selectedRoute = this.directions[0];

            this.loadRoute();
            this.loadMarkers();
          })
          .catch((err) => {
            console.error(err);
            this.$bus.$emit("showLoader", false);
          });
      }
    },

    loadRoute() {
      if (this.markerTimeout) {
        clearTimeout(this.markerTimeout);
      }
      this.markerTimeout = setTimeout(() => {
        this.spotRateDetails = {
          ...this.spotRateDetails,
          route: this.selectedRoute.route,
          distance: this.selectedRoute.distance,
        };

        this.$refs.shipperRateMap.addGeoCollection(this.spotRateDetails.route);
      }, 500);
    },

    loadMarkers() {
      this.$refs.shipperRateMap.addMultipleMarkers([
        { ...this.sourceCoordinates, type: "source" },
        { ...this.destinationCoordinates, type: "destination" },
      ]);
    },

    getLocationList() {
      this.$bus.$emit("showLoader", true);

      this.$api.location
        .getLocationList({ limit: "all" })
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.sourceList = res.data;
          this.destinationList = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getJobTypeOptions() {
      this.$bus.$emit("showLoader", true);
      this.$api.customerOrder
        .getOrderOptions()
        .then((res) => {
          let jobType = res.data.actions.POST.job_type.choices;
          this.allJobType = jobType.filter(
            (job) => job.display_name !== "Empty" && job.display_name !== "LCL"
          );
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },

  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("rate_enquiry");
      this.contract_permissions = getPermissions("contract");
      if (!permissions || !permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.permissions = { ...permissions };
        this.getLocationList();
        this.getContainersType();
        this.getJobTypeOptions();
      }
    }
  },
};
</script>

<style scoped>
.Map-Height {
  height: calc(100vh - 221px);
}

.Height-Class {
  height: calc(100vh - 197px);
  overflow-y: auto;
}
</style>
