<template>
  <v-textarea
    :value="value"
    :class="designClass"
    outlined
    clearable
    v-on="$listeners"
    v-bind="$attrs"
    dense
  ></v-textarea>
</template>

<script>
export default {
  name: 'TextareaTag',
  props: {
   
    value: {
      type: [String, Array, Date],
      default: '',
    },
  
    designClass: {
      type: String,
    },
  },
}
</script>