<template>
  <v-navigation-drawer
    app
    v-model="rightDrawer"
    right
    temporary
    class="px-2"
    width="350px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">Settings</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="rightDrawer = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
    </v-card>
    <v-card>
      <v-card-title> Theme </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-btn
              x-large
              block
              :color="!$vuetify.theme.dark ? 'primary' : ''"
              @click="$vuetify.theme.dark = false"
            >
              <span>Light</span>
              <v-icon class="ml-4">mdi-white-balance-sunny</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              x-large
              block
              :color="$vuetify.theme.dark ? 'primary' : ''"
              @click="$vuetify.theme.dark = true"
            >
              <span>Dark</span>
              <v-icon class="ml-4">mdi-weather-night</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!------------------------- Do not delete *For Future Scope* ------------------------->
    <!-- <v-card>
      <v-card-title> Language </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-btn
              x-large
              block
              :color="$vuetify.lang.current == 'en' ? 'primary' : ''"
              @click="$vuetify.lang.current = 'en'"
            >
              <span>English</span>
              <v-icon class="ml-4">mdi-white-balance-sunny</v-icon>
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              x-large
              block
              :color="$vuetify.lang.current == 'ar' ? 'primary' : ''"
              @click="$vuetify.lang.current = 'ar'"
            >
              <span>Arabic</span>
              <v-icon class="ml-4">mdi-white-balance-sunny</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card> -->
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
    },
  },
  computed: {
    rightDrawer: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  mounted() {},
}
</script>