import Blank from "@/layouts/blank.vue"

import VehicleList from "@/pages/admin/asset-management/vehicle/VehicleList.vue";
import VehicleAdd from "@/pages/admin/asset-management/vehicle/VehicleAdd.vue";
import VehicleEdit from "@/pages/admin/asset-management/vehicle/VehicleEdit.vue";

const vehicleRoutes = [
  {
    path: "vehicle",
    name: "app-admin-asset-management-vehicle",
    component: Blank,
    children: [
      {
        path: "/",
        redirect: "/app/admin/asset-management/vehicle/list",
      },
      {
        path: "list",
        name: "app-admin-asset-management-asset-list",
        component: VehicleList,
      },
      {
        path: "add",
        name: "app-admin-asset-management-asset-add",
        component: VehicleAdd,
      },
      {
        path: "edit",
        name: "app-admin-asset-management-asset-edit",
        component: Blank,
        children: [
          {
            path: "/",
            redirect: "/app/admin/asset-management/vehicle/list",
          },
          {
            path: ":id",
            name: "app-admin-asset-management-asset-edit-id",
            component: VehicleEdit,
          }
        ]
      },
    ]
  },
]

export default vehicleRoutes