<template>
  <v-dialog fullscreen v-model="showSubhireDetailDialog">
    <v-card>
      <v-card-title class="secondary white--text px-4 py-2">
        <span> Sub Hire Job Details</span>

        <v-spacer></v-spacer>

        <v-btn @click="viewSubHireJobLogs"> Sub Hire Job Logs </v-btn>

        <v-btn icon @click="showSubhireDetailDialog = false">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="heading pa-2">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12" lg="auto" v-if="subHireList.sub_hire_id">
                <span class="text-caption">Sub Hire Id</span>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  {{ subHireList.sub_hire_id }}
                </p>
              </v-col>
              <v-divider vertical class="mx-3"></v-divider>

              <v-col cols="12" lg="auto" v-if="subHireList.status">
                <span class="text-caption">Status</span>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  {{ subHireList.status }}
                </p>
              </v-col>
              <v-divider vertical class="mx-3"></v-divider>

              <v-col cols="12" lg="auto" v-if="subHireList.rate">
                <span class="text-caption"> Transporter Rate</span>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  {{ subHireList.rate }}
                </p>
              </v-col>
              <v-divider vertical class="mx-3"></v-divider>

              <v-col cols="12" lg="auto" v-if="subHireList.tax_percentage">
                <span class="text-caption">Tax Percentage</span>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  {{ subHireList.tax_percentage }}
                </p>
              </v-col>
              <v-divider vertical class="mx-3"></v-divider>

              <v-col cols="12" lg="auto" v-if="subHireList.sub_hire_leg_count">
                <span class="text-caption">Sub Hire Leg Count</span>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  {{ subHireList.sub_hire_leg_count }}
                </p>
              </v-col>
              <v-divider vertical class="mx-3"></v-divider>

              <v-col cols="12" lg="auto" v-if="subHireList.reason_for_sub_hire">
                <span class="text-caption">Reason For Sub Hire</span>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  {{ subHireList.reason_for_sub_hire }}
                </p>
              </v-col>
              <v-divider vertical class="mx-3"></v-divider>

              <v-col
                cols="12"
                lg="auto"
                v-if="subHireList.cargo_liability_insurance"
              >
                <span class="text-caption">Cargo Liability Insurance</span>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  {{ subHireList.cargo_liability_insurance }}
                </p>
              </v-col>
              <v-divider vertical class="mx-3"></v-divider>

              <v-col
                cols="12"
                lg="auto"
                v-if="subHireList.general_third_party_insurance"
              >
                <span class="text-caption">General Third Party Insurance</span>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  {{ subHireList.general_third_party_insurance }}
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card-text>

      <v-card-text class="heading pa-2">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12" lg="auto" v-if="subHireList.invoice_no">
                <span class="text-caption">Invoice No</span>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  {{ subHireList.invoice_no }}
                </p>
              </v-col>
              <v-divider
                vertical
                class="mx-3"
                v-if="subHireList.invoice_no"
              ></v-divider>

              <v-col cols="12" lg="auto" v-if="subHireList.invoice_date">
                <span class="text-caption">Invoice Date</span>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  {{ subHireList.invoice_date }}
                </p>
              </v-col>
              <v-divider
                vertical
                class="mx-3"
                v-if="subHireList.invoice_no"
              ></v-divider>

              <!-- <v-col cols="12" lg="auto" v-if="subHireList.voucher">
                <span class="text-caption">Voucher</span>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  {{ subHireList.voucher }}
                </p>
              </v-col>
              <v-divider
                vertical
                class="mx-3"
                v-if="subHireList.voucher"
              ></v-divider> -->

              <v-col cols="12" lg="auto" v-if="subHireList.added_by">
                <span class="text-caption">Added By</span>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  {{ subHireList.added_by }}
                </p>
              </v-col>
              <v-divider
                vertical
                class="mx-3"
                v-if="subHireList.added_by"
              ></v-divider>

              <v-col cols="12" lg="auto" v-if="subHireList.approved_by">
                <span class="text-caption">Approved By</span>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  {{ subHireList.approved_by }}
                </p>
              </v-col>
              <v-divider
                vertical
                class="mx-3"
                v-if="subHireList.approved_by"
              ></v-divider>

              <v-col cols="12" lg="auto" v-if="subHireList.invoice_by">
                <span class="text-caption">Invoice Detail Updated By</span>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  {{ subHireList.invoice_by }}
                </p>
              </v-col>
              <v-divider
                vertical
                class="mx-3"
                v-if="subHireList.invoice_by"
              ></v-divider>

              <v-col cols="12" lg="auto" v-if="subHireList.completed_by">
                <span class="text-caption">Completed By</span>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  {{ subHireList.completed_by }}
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card-text>

      <v-card-text class="pa-2 mt-1">
        <AgGridVue
          style="width: 100%; height: 300px"
          class="ag-theme-alpine mb-2"
          id="myGrid"
          :columnDefs="headers"
          :context="context"
          :grid-options="gridOptions"
          :suppressDragLeaveHidesColumns="true"
          :rowData="allRowList"
        ></AgGridVue>

        <AgGridVue
          style="width: 100%; height: 300px"
          class="ag-theme-alpine"
          id="myChargeGrid"
          :columnDefs="chargeHeaders"
          :grid-options="chargeGridOptions"
          :suppressDragLeaveHidesColumns="true"
          :rowData="allChargeList"
        ></AgGridVue>
      </v-card-text>
    </v-card>
    <DetailsParent v-model="subHireJobLogDetailsDrawer" :resizable="true">
      <template #details>
        <v-card elevation="0">
          <v-card-title class="heading">
            <span class="text-h6">Sub Hire Job Logs</span>
          </v-card-title>

          <v-card-text>
            <v-row class="Order-Log-Scrolling-Class mt-3">
              <v-col cols="12">
                <span
                  class="py-12"
                  v-if="subHireJobLogs && subHireJobLogs.length == 0"
                >
                  No logs to show!
                </span>

                <v-timeline dense clipped v-else>
                  <v-timeline-item
                    v-for="(log, i) in subHireJobLogs"
                    :key="i"
                    icon-color="primary"
                    small
                  >
                    <v-card class="elevation-2">
                      <v-card-text>
                        <table class="Full-Width">
                          <tbody>
                            <tr>
                              <th class="text-left">{{ log.updated_by }}</th>
                              <td class="text-caption text-right">
                                {{ log.created | formatDate(true) }}
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2">{{ log.message }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </v-card-text>
                    </v-card>
                  </v-timeline-item>
                </v-timeline>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </DetailsParent>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import SelectField from "@/components/FormBaseComponents/SelectField.vue";
import { AgGridVue } from "ag-grid-vue";
import DetailsParent from "@/components/General/DetailsParent.vue";

export default {
  components: {
    AgGridVue,
    InputField,
    SelectField,
    MultiSelect,
    DetailsParent,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    subHireObjId: {
      default: false,
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.subHireId = this.subHireObjId;
        this.getSubHireDetail();
      } else {
        this.subHireId = null;
      }
    },
    tab() {
      this.subHireJobLogDetailsDrawer = false;
    },
  },
  computed: {
    showSubhireDetailDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    context() {
      return { parentComponent: this };
    },
  },
  data() {
    return {
      selectedRows: [],
      subHireList: [],

      formErrors: {},
      isValid: true,
      allRowList: [],
      allChargeList: [],
      lastEndDate: null,
      isSearchEnabled: false,
      subHireId: null,

      filters: {},
      // pagination vars

      // ag-grid vars

      gridOptions: {
        defaultColDef: {
          resizable: true,
          flex: 1,
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },
      headers: [
        {
          headerName: "Leg",
          field: "leg_id",
          minWidth: 120,
        },
        {
          headerName: "DA",
          field: "da_no",
          minWidth: 100,
          tooltipField: "mid",
        },
        {
          headerName: "Customer",
          field: "customer_name",
          minWidth: 250,
          tooltipField: "customer_code",
        },
         {
          headerName: "Status",
          field: "status",
          minWidth: 100,
          tooltipField: "status",
        },
        {
          headerName: "BOE",
          field: "boe",
          minWidth: 80,
        },
        {
          headerName: `Customer Rate (${this.$globals.currency})`,
          field: "customer_rate",
          minWidth: 100,
        },
        {
          headerName: `Tax Amount (${this.$globals.currency})`,
          field: "tax_amount",
          minWidth: 100,
        },
        {
          headerName: `Final Rate (${this.$globals.currency})`,
          field: "total_rate",
          minWidth: 120,
        },
        {
          headerName: "Transporter",
          field: "company_name",
          minWidth: 200,
        },
        {
          headerName: `Transporter Rate (${this.$globals.currency})`,
          field: "transporter_rate",
          minWidth: 170,
        },
      ],

      // charge ag-grid vars
      chargeHeaders: [
        {
          headerName: "Charge Title",
          field: "charge_title",
          minWidth: 300,
        },
        {
          headerName: "Cost GL No.",
          field: "cost_gl_no",
          minWidth: 150,
        },
        {
          headerName: "TAX(%)",
          field: "tax_percentage",
          minWidth: 100,
        },
        {
          headerName: `Rate (${this.$globals.currency})`,
          field: "rate",
          minWidth: 100,
        },
      ],
      chargeGridApi: null,
      chargeGridColumnApi: null,
      subHireJobLogDetailsDrawer: false,
      subHireJobLogs: [],

      chargeGridOptions: {
        defaultColDef: {
          resizable: true,
          flex: 1,
        },

        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
      },
    };
  },
  methods: {
    viewSubHireJobLogs() {
      this.subHireJobLogDetailsDrawer = true;
      this.$bus.$emit("showLoader", false);
      this.$api.subHire
        .getSubHireJObLogs(this.subHireId)
        .then((res) => {
          this.subHireJobLogs = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't fetch sub hire job logs!",
            color: "error",
          });
          this.formErrors = err.data;
        });
    },
    getSubHireDetail() {
      this.$bus.$emit("showLoader", true);
      this.$api.subHire
        .getSubHireDetails(this.subHireId)
        .then((res) => {
          this.subHireList = res.data;
          this.allRowList = res.data.sub_hire_leg;
          this.allChargeList = res.data.sub_hire_job_charges;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
};
</script>
