<template>
  <div>
    <v-col cols="12" class="px-0 pb-0">
      <v-card height="100%">
        <v-card-text
          @scroll="getOldChats"
          id="chatCard"
          class="chatDialog Chat-Height-Class"
        >
          <!-- <v-btn
            elevation="12"
            v-if="newMessageAlert"
            class="new-msg-btn"
            @click="scrollChatToBottom"
          >
            New message
            <v-icon>mdi-arrow-down</v-icon>
          </v-btn> -->
          <div class="message-group" v-for="(msg, i) in messageList" :key="i">
            <v-row
              v-if="msg.message_format == 'Text'"
              :justify="`${msg.sender_type == userType ? 'end' : 'start'}`"
              no-gutters
              class="msg-container"
            >
              <div
                :class="`${
                  msg.sender_type == userType
                    ? 'rounded-br-0 grey lighten-2 send'
                    : 'rounded-bl-0 primary lighten-4 recieved'
                }`"
                class="message rounded-lg pa-4"
              >
                {{ msg.message }}
              </div>
            </v-row>
            <v-row
              v-if="msg.message_format == 'Image'"
              :justify="`${msg.sender_type == userType ? 'end' : 'start'}`"
              no-gutters
              class="msg-container"
            >
              <div
                :class="`${
                  msg.sender_type == userType
                    ? 'rounded-br-0 grey lighten-2 send'
                    : 'rounded-bl-0 primary lighten-4 recieved'
                }`"
                class="message rounded-lg pa-4"
              >
                <img width="100%" :src="msg.attachment_url" alt="File" />
              </div>
            </v-row>
            <v-row
              v-if="msg.message_format == 'Document'"
              :justify="`${msg.sender_type == userType ? 'end' : 'start'}`"
              no-gutters
              class="msg-container"
            >
              <div
                :class="`${
                  msg.sender_type == userType
                    ? 'rounded-br-0 grey lighten-2 send'
                    : 'rounded-bl-0 primary lighten-4 recieved'
                }`"
                class="message rounded-lg pa-4"
              >
                <v-list dense class="pa-0">
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <v-list-item
                        @click="downloadFile(msg.attachment_url)"
                        dense
                        v-bind="attrs"
                        v-on="on"
                        class="pa-0"
                        :class="`${
                          msg.sender_type == userType
                            ? 'grey lighten-2'
                            : 'primary lighten-4'
                        }`"
                      >
                        <v-list-item-avatar>
                          <v-icon size="20" class="grey lighten-1" dark>
                            mdi-file
                          </v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title
                            v-text="getFileName(msg.attachment_url)"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <span>{{ getFileName(msg.attachment_url) }}</span>
                  </v-tooltip>
                </v-list>
              </div>
            </v-row>
            <v-row
              v-if="msg.message_format == 'Audio'"
              :justify="`${msg.sender_type == userType ? 'end' : 'start'}`"
              no-gutters
              class="msg-container"
            >
              <div
                :class="`${
                  msg.sender_type == userType
                    ? 'rounded-br-0 grey lighten-2 send'
                    : 'rounded-bl-0 primary lighten-4 recieved'
                }`"
                class="message rounded-lg pa-4"
              >
                <audio controls :src="msg.attachment_url">
                  Your browser does not support the
                  <code>audio</code> element.
                </audio>
              </div>
            </v-row>
            <div
              :class="`${msg.sender_type == userType ? 'text-right' : ''}`"
              class="text-caption"
            >
              {{ msg.sender_type }} | {{ msg.sender_name }} |
              {{ msg.created | formatDate(true) }}
            </div>
          </div>
          <input
            ref="fileSelector"
            @change="selectionChanged"
            v-show="false"
            type="file"
            accept="audio/mp3,audio/aac,audio/wav,image/png,image/jpg,image/jpeg,.doc,.docx,.pdf,.xlsx,.xls"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            icon
            depressed
            class="mx-2"
            @click="toggleRecording"
            :disabled="
              tripDetails.status == 'Completed' ||
              Object.keys(tripDetails).length === 0
            "
          >
            <v-icon>mdi-microphone</v-icon>
          </v-btn>
          <span v-if="recording"> Recording ... </span>
          <audio v-if="audio" controls :src="audioPreview">
            Your browser does not support the
            <code>audio</code> element.
          </audio>
          <v-text-field
            v-if="!recording && !audio"
            placeholder="Type Here..."
            prepend-inner-icon="mdi-attachment"
            v-model="message"
            filled
            rounded
            hide-details
            :disabled="
              tripDetails.status == 'Completed' ||
              Object.keys(tripDetails).length === 0
            "
            :readonly="!!selectedFile"
            :loading="uploadLoading"
            @keyup.enter="sendMessage"
            @click:prepend-inner="triggerFileSelect()"
          >
          </v-text-field>
          <v-btn
            v-if="selectedFile || audio || message"
            icon
            depressed
            @click="resetMessage()"
            class="mx-2"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn
            :disabled="
              recording ||
              tripDetails.status == 'Completed' ||
              Object.keys(tripDetails).length === 0
            "
            icon
            depressed
            @click="sendMessage()"
            class="mx-2"
          >
            <v-icon>mdi-send</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </div>
</template>
  
  <script>
export default {
  components: {},
  props: {
    chatBoxDialog: {
      type: Boolean,
    },
    tripDetails: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    chatBoxDialog(val) {
      if (val) {
        this.getChatHistory();
      } else {
        this.initialLoad = true;
      }
    },
  },
  computed: {},
  data() {
    return {
      initialLoad: true,

      message: null,
      uploadLoading: false,
      selectedFile: null,
      newMessageAlert: false,
      milestone: null,
      newStatus: null,
      milestoneList: [],

      showLegDialog: false,
      showTimeStampDialog: false,
      mediaRecorder: null,
      recording: false,
      audio: null,
      audioPreview: null,
      messageList: [],
      userType: "Driver",
    };
  },
  methods: {
    resetMessage() {
      this.message = null;
      this.selectedFile = null;
      this.audio = null;
      this.audioPreview = null;
    },

    scrollChatToBottom() {
      this.newMessageAlert = false;
      setTimeout(() => {
        let messageContainer = document.getElementById("chatCard");
        if (messageContainer) {
          messageContainer.scrollTo({
            top: messageContainer.scrollHeight,
            behavior: "smooth",
          });
        }
      }, 300);
    },
    isActiveChat() {
      return true;
    },
    triggerFileSelect() {
      if (this.$refs.fileSelector) {
        this.$refs.fileSelector.click();
      }
    },
    selectionChanged(ev) {
      this.selectedFile = null;
      this.message = null;
      if (ev && ev.target && ev.target.files && ev.target.files.length > 0) {
        this.selectedFile = ev.target.files[0];
        this.message = this.selectedFile.name;
        ev.target.value = null;
      }
    },
    uploadFile() {
      this.uploadLoading = true;

      let payload = new FormData();

      if (this.selectedFile) {
        payload.append("attachment", this.selectedFile);
      } else if (this.audio) {
        payload.append(
          "attachment",
          this.audio,
          `${Date.parse(new Date())}.mp3`
        );
      }

      payload.append("trip", this.tripDetails.id);
      payload.append("driver", this.tripDetails.driver);

      this.resetMessage();

      this.$api.general
        .uploadFile(payload)
        .then((res) => {
          this.uploadLoading = false;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          this.$bus.$emit("showToastMessage", {
            message: err.data.attachment.join(),
            color: "error",
            hasActions: true,
          });
          this.uploadLoading = false;
          this.$bus.$emit("showLoader", false);
        });
    },
    async setupWS() {
      if (!this.$ws.instance) {
        await this.$ws.createInstance("driver");
      }
      this.getChatHistory();
    },
    downloadFile(url) {
      const a = document.createElement("a");
      a.href = url;
      a.download = this.getFileName(url);
      a.target = "blank";
      a.id = "custom_file_download_element";
      a.click();
    },

    toggleRecording(ev) {
      if (navigator && navigator.mediaDevices) {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((stream) => {
            if (this.recording && this.mediaRecorder) {
              this.mediaRecorder.stop();
            } else {
              this.recording = true;

              this.mediaRecorder = new MediaRecorder(stream);
              this.mediaRecorder.start();

              const chunks = [];
              this.mediaRecorder.addEventListener("dataavailable", (event) => {
                chunks.push(event.data);
              });

              this.mediaRecorder.addEventListener("stop", () => {
                this.recording = false;
                this.audio = new Blob(chunks, {
                  type: "audio/mp3",
                });
                this.audioPreview = URL.createObjectURL(this.audio);
                this.mediaRecorder = null;
              });
            }
          })
          .catch((err) => {
            alert(
              "Audio permissions denied or disabled from browser check permissions and try again."
            );
          });
      }
    },
    getOldChats(ev) {
      if (ev.target.scrollTop == 0) {
        this.getChatHistory();
      }
      if (this.isActiveChat()) {
        this.newMessageAlert = false;
      }
    },
    getFileName(path) {
      if (path) {
        path = path.split("/");
        return path[path.length - 1];
      } else {
        return "undefined";
      }
    },
    onMessage(ev) {
      if (ev && ev.data) {
        const data =
          typeof ev.data == typeof "" ? JSON.parse(ev.data) : ev.data;
        if (data.type && data.type == "chat_history") {
          this.messageList.unshift(...data.data);
          if (this.initialLoad) {
            this.scrollChatToBottom();
          }
          this.initialLoad = false;
        }
        if (
          data.type &&
          [
            "new_message",
            "message_from_admin",
            "message_from_transporter",
            "message_from_driver",
          ].indexOf(data.type) > -1
        ) {
          this.isActiveChat();
          if (data.data.trip_id == this.tripDetails.trip_id) {
            this.messageList.push(data.data);
          }
          if (this.isActiveChat()) {
            this.scrollChatToBottom();
          } else {
            this.newMessageAlert = true;
          }
        }
      }
    },

    sendMessage() {
      if (this.selectedFile || this.audio) {
        this.uploadFile();
      } else if (this.message && this.message.trim().length > 0) {
        this.$ws.instance.send(
          JSON.stringify({
            type: "new_message",
            data: {
              trip_id: this.tripDetails.id,
              message: this.message.trim(),
            },
          })
        );
        this.resetMessage();
      }
    },
    getChatHistory() {
      if (this.chatHistoryTimeout) {
        clearTimeout(this.chatHistoryTimeout);
      }
      this.chatHistoryTimeout = setTimeout(() => {
        this.$ws.instance.send(
          JSON.stringify({
            type: "chat_history",
            data: {
              trip_id: this.tripDetails.id,
              offset: this.messageList.length,
            },
          })
        );
      }, 500);
    },
  },
  mounted() {
    this.getChatHistory();
    this.$bus.$on("socketMessageReceived", (data) => {
      this.onMessage({ data: data });
    });
  },
};
</script>
    
<style scoped>
.card-top-outlined {
  border-top: 1px solid #0000004f !important;
}
.btn-position-fixed-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.Chat-Height-Class {
  height: calc(100vh - 200px) !important;
}
</style>