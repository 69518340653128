<template>
    <div>
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            depressed
            v-if="
              params.context &&
              params.context.parentComponent &&
              params.context.parentComponent.invoiceCreditNoteDetails &&
              params.context.parentComponent.invoiceCreditNoteDetails.status != 'Accepted'
            "
            icon
            v-on="on"
            v-bind="attrs"
            class="ml-1 mr-2"
            @click="editCharges"
          >
            <v-icon color="primary">mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Edit Charge</span>
      </v-tooltip>
  
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            depressed
            v-if="
              params.context &&
              params.context.parentComponent &&
              params.context.parentComponent.invoiceCreditNoteDetails &&
              params.context.parentComponent.invoiceCreditNoteDetails.status !=
                'Accepted'
            "
            icon
            v-on="on"
            v-bind="attrs"
            class="ml-2 mr-1"
            @click="removeDa"
          >
            <v-icon color="error">mdi-trash-can</v-icon>
          </v-btn>
        </template>
        <span>Remove From Credit Note</span>
      </v-tooltip>
    </div>
  </template>
  
  <script>
  export default {
    methods: {
      removeDa() {
        this.params.context.parentComponent.removeDA(this.params.data.id);
      },
  
      editCharges() {
        this.params.context.parentComponent.updateAdditionalCharges(
          this.params.data
        );
      },
    },
  };
  </script>
  
  <style>
  </style>