import { lane, demoExcelSheetDownload } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getLaneList(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(lane.laneBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getLaneSelectList(params = {}) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${lane.laneBase}select/`, {
                params: params,
            })
            .then((res) => {
                resolve(handleResponse(res));
            })
            .catch((err) => {
                reject(handleError(err));
            });
    });
},

  getLaneRoute(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(lane.laneRoute, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getLaneOptions(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .options(lane.laneBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getLaneDetails(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${lane.laneBase}${id}/view/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getLaneObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${lane.laneBase}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  addLane(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(lane.laneBase, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  updateLane(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${lane.laneBase}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  partiallyUpdateLane(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${lane.laneBase}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  bulkUpdateLane(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${lane.laneBase}bulk_lane_update/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  downloadRouteExcel(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${demoExcelSheetDownload.downloadCustomerContract}`, {
          responseType: "blob",
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
