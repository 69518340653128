var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
          _vm.params &&
          _vm.params.context &&
          _vm.params.context.parentComponent &&
          _vm.params.context.parentComponent.permissions && 
          _vm.params.context.parentComponent.permissions.change &&
          _vm.params.context.parentComponent.currentProfile.is_primary_user
        )?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":_vm.updatestatus}},'v-icon',attrs,false),on),[_vm._v("mdi-pencil")]):_vm._e()]}}])},[_c('span',[_vm._v("Update status")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.viewSupportDetails()}}},'v-icon',attrs,false),on),[_vm._v("mdi-eye")])]}}])},[_c('span',[_vm._v("View details")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }