<template>
  <div class="d-flex">
    <v-select
      dense
      outlined
      hide-details
      name="pagination-item-per-page"
      :items="itemsPerPageValues"
      v-model="itemsPerPage"
      class="Pagination-Page-Space-Class"
    ></v-select>
    <v-btn
      :disabled="pageNo == 1"
      x-small
      fab
      text
      name="pagination-previous"
      class="rounded-lg elevation-0"
      @click="previousPage()"
    >
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <span class="pa-2">
      <span>{{ pageNo }}</span>
      <span>/</span>
      <span>{{ totalPages }}</span>
    </span>
    <v-btn
      x-small
      fab
      text
      name="pagination-next"
      :disabled="pageNo == totalPages || totalPages == 0"
      class="rounded-lg elevation-0"
      @click="nextPage()"
    >
      <v-icon>mdi-arrow-right</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    pageNo: {
      require: true,
    },
    totalItems: {
      require: true,
    },
    pageSize: {
      require: true,
    },
    itemsPerPageValues: {
      type: Array,
      default: () => [10, 15, 20, 50, 100],
    },
  },
  computed: {
    itemsPerPage: {
      get() {
        return this.pageSize;
      },
      set(value) {
        this.$emit("itemsPerPageChange", value);
      },
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.pageSize);
    },
  },
  methods: {
    previousPage() {
      this.$emit("prevPage");
    },
    nextPage() {
      this.$emit("nextPage");
    },
  },
};
</script>

<style >
.Pagination-Page-Space-Class {
  max-width: 72px !important;
}
.Pagination-Page-Space-Class .v-input__slot {
  padding-right: 0px !important;
}
</style>