import Blank from "@/layouts/blank.vue"

import TripList from "@/pages/admin/trip/TripList.vue";
import TripDetails from "@/pages/admin/trip/TripDetails.vue";

const tripRoutes = [
  {
    path: "trip",
    name: "app-admin-trip",
    component: Blank,
    children: [
      {
        path: "/",
        redirect: "/app/admin/trip/list",
      },
      {
        path: "list",
        name: "app-admin-trip-list",
        component: TripList,
      },
      {
        path: "details",
        name: "app-admin-trip-details",
        component: Blank,
        children: [
          {
            path: "/",
            redirect: "/app/admin/trip/list",
          },
          {
            path: ":id",
            name: "app-admin-trip-details-id",
            component: TripDetails,
          }
        ]
      },
    ]
  },
]

export default tripRoutes