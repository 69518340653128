<template>
  <v-dialog fullscreen v-model="showPaymentReceiptForm">
    <v-card>
      <v-card-title class="secondary white--text px-4 py-2">
        <span
          class="text-capitalize"
          v-if="paymentReceiptDetails && paymentReceiptDetails.is_send_to_sap"
        >
          View Payment Receipt
        </span>
        <span class="text-capitalize" v-else>
          <!-- {{ paymentReceiptID ? "Edit " : "Add " }} Payment Receipt -->
          {{ isCopyOperation ? "Copy" : paymentReceiptID ? "Edit" : "Add" }}
          Payment Receipt
        </span>
        <v-spacer />

        <v-tooltip top v-if="paymentReceiptID && !isCopyOperation">
          <template #activator="{ on, attrs }">
            <v-btn depressed icon>
              <v-icon
                class="mr-4 white--text"
                v-on="on"
                v-bind="attrs"
                @click="getPaymentReceiptBlob()"
              >
                mdi-cloud-download
              </v-icon>
            </v-btn>
          </template>
          <span>Download</span>
        </v-tooltip>

        <v-btn
          icon
          @click="
            resetForm();
            showPaymentReceiptForm = false;
          "
        >
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pa-2">
        <v-form ref="chargeForm" v-model="isValid">
          <v-row class="ma-0 mb-4">
            <v-col cols="12" lg="3" class="Form-Columns-Dense">
              <MultiSelect
                label="Select Customer *"
                :items="allCustomerList"
                itemText="company_name"
                itemValue="id"
                :readonly="isReadOnly"
                :rules="[(val) => !!val || 'Customer is Required!']"
                hide-details="auto"
                v-model="paymentReceiptDetails.company"
                :returnObject="true"
                :error-messages="formErrors.company"
                @input="
                  delete formErrors.company;
                  checktaxAmount();
                  calculateReadonlyFieldsData();
                "
              />
            </v-col>
            <v-col cols="12" lg="3" class="Form-Columns-Dense">
              <InputField
                label="Reference Number *"
                :rules="[(val) => !!val || 'Reference Number is Required!']"
                :clearable="false"
                required
                hide-details="auto"
                v-model="paymentReceiptDetails.reference_number"
                :error-messages="formErrors.reference_number"
                @input="delete formErrors.reference_number"
              />
            </v-col>

            <v-col cols="12" lg="3" class="Form-Columns-Dense">
              <MultiSelect
                hide-details="auto"
                label="Payment Mode *"
                :items="allpaymentModes"
                itemText="display_name"
                itemValue="value"
                :readonly="isReadOnly"
                v-model="paymentReceiptDetails.payment_mode"
                :rules="[(v) => !!v || 'Payment Mode is Required']"
                :error-messages="formErrors.payment_mode"
                @input="
                  delete formErrors.payment_mode;
                  clearFields();
                "
              />
            </v-col>
            <v-col cols="12" lg="3" class="Form-Columns-Dense">
              <InputField
                label="Collected By"
                hide-details="auto"
                :clearable="false"
                :readonly="isReadOnly"
                v-model="paymentReceiptDetails.collected_by"
                :error-messages="formErrors.collected_by"
                @input="delete formErrors.collected_by"
              />
            </v-col>
            <v-col cols="12" lg="3" class="Form-Columns-Dense">
              <MultiSelect
                label="Cash/Bank Ac *"
                :items="allBanksList"
                itemText="bank_name"
                itemValue="id"
                hide-details="auto"
                :returnObject="false"
                :readonly="isReadOnly"
                v-model="paymentReceiptDetails.bank_details"
                :rules="[(v) => !!v || 'Bank account required']"
                :error-messages="formErrors.bank_details"
                @change="delete formErrors.bank_details"
                :item-disabled="
                  (item) => {
                    return item.is_active == 'No';
                  }
                "
              />
            </v-col>
            <v-col
              cols="12"
              lg="3"
              class="Form-Columns-Dense"
              v-if="paymentReceiptDetails.payment_mode == 'Cheque'"
            >
              <InputField
                label="Customer Bank *"
                :rules="[(val) => !!val || 'Customer Bank  is Required!']"
                required
                hide-details="auto"
                :readonly="isReadOnly"
                v-model="paymentReceiptDetails.customer_bank"
                :error-messages="formErrors.customer_bank"
                @input="delete formErrors.customer_bank"
              />
            </v-col>
            <v-col
              cols="12"
              lg="3"
              class="Form-Columns-Dense"
              v-if="paymentReceiptDetails.payment_mode == 'Cheque'"
            >
              <InputField
                label="Customer Branch *"
                :rules="[(val) => !!val || 'Customer Branch is Required!']"
                required
                hide-details="auto"
                :readonly="isReadOnly"
                v-model="paymentReceiptDetails.customer_branch"
                :error-messages="formErrors.customer_branch"
                @input="delete formErrors.customer_branch"
              />
            </v-col>
            <v-col
              cols="12"
              lg="3"
              class="Form-Columns-Dense"
              v-if="paymentReceiptDetails.payment_mode == 'Cheque'"
            >
              <InputField
                label="Cheque No *"
                :rules="[(val) => !!val || 'Cheque Number is Required!']"
                required
                hide-details="auto"
                :readonly="isReadOnly"
                v-model="paymentReceiptDetails.cheque_no"
                :error-messages="formErrors.cheque_no"
                @input="delete formErrors.cheque_no"
              />
            </v-col>
            <v-col
              cols="12"
              lg="3"
              class="Form-Columns-Dense"
              v-if="paymentReceiptDetails.payment_mode == 'Cheque'"
            >
              <DateField
                :inputProps="{
                  dense: true,
                  label: 'Cheque Date *',
                  rules: [(val) => !!val || 'Cheque Date is Required!'],
                  'hide-details': 'auto',
                  'error-messages': formErrors.cheque_date,
                }"
                required
                :readonly="isReadOnly"
                v-model="paymentReceiptDetails.cheque_date"
                @input="delete formErrors.cheque_date"
              />
            </v-col>
            <v-col cols="12" lg="3" class="Form-Columns-Dense">
              <Autocomplete
                :loading="listLoading"
                :totalItems="laneCount"
                :items="allLaneList"
                @updateList="getLaneList"
                label="Route *"
                itemText="title"
                itemValue="id"
                :rules="[(v) => !!v || 'Route is Required']"
                hide-details="auto"
                :disabled="isReadOnly"
                v-model="paymentReceiptDetails.lane"
                :error-messages="formErrors.lane"
                @input="
                  delete formErrors.lane;
                  checktaxAmount();
                  calculateReadonlyFieldsData();
                "
              />
            </v-col>
            <v-col cols="12" lg="3" class="Form-Columns-Dense">
              <MultiSelect
                label="Job Type *"
                :items="allJobType"
                itemText="display_name"
                itemValue="value"
                hide-details="auto"
                :readonly="isReadOnly"
                v-model="paymentReceiptDetails.job_type"
                :rules="[(v) => !!v || 'Job Type is Required']"
                :error-messages="formErrors.job_type"
                @change="delete formErrors.job_type"
              />
            </v-col>

            <v-col cols="12" lg="3" class="Form-Columns-Dense">
              <DateField
                :inputProps="{
                  dense: true,
                  label: 'Movement Date *',
                  rules: [(val) => !!val || 'Movement Date is Required!'],
                  'hide-details': 'auto',
                  'error-messages': formErrors.movement_date,
                }"
                required
                :readonly="isReadOnly"
                v-model="paymentReceiptDetails.movement_date"
                @input="delete formErrors.movement_date"
              />
            </v-col>

            <v-col cols="12" lg="3" class="Form-Columns-Dense">
              <InputField
                type="number"
                :label="`Transport Rate (${$globals.currency}) *`"
                :clearable="false"
                :readonly="
                  paymentReceiptDetails && paymentReceiptDetails.is_send_to_sap
                "
                hide-details="auto"
                v-model="paymentReceiptDetails.rate"
                :rules="[(val) => !!val || 'Rate is required']"
                :error-messages="formErrors.rate"
                @input="
                  delete formErrors.rate;
                  checktaxAmount();
                  calculateReadonlyFieldsData();
                "
              />
            </v-col>

            <v-col cols="12" lg="3" class="Form-Columns-Dense">
              <InputField
                type="text"
                label="Tax Amount"
                :clearable="false"
                hide-details="auto"
                v-model="taxAmount"
                readonly
                class="grey lighten-3"
              />
            </v-col>

            <v-col cols="12" lg="3" class="Form-Columns-Dense">
              <InputField
                type="text"
                label="Total Amount"
                :clearable="false"
                hide-details="auto"
                v-model="totalAmount"
                readonly
                class="grey lighten-3"
              />
            </v-col>

            <v-col cols="12" lg="3" class="Form-Columns-Dense">
              <InputField
                type="text"
                label="Remarks"
                hide-details="auto"
                v-model="paymentReceiptDetails.remarks"
                :error-messages="formErrors.remarks"
                @input="delete formErrors.remarks"
              />
            </v-col>
          </v-row>

          <v-card class="ma-1 mb-6">
            <v-card-title class="heading px-4 py-2">
              <span>Container Details</span>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                depressed
                small
                @click="
                  paymentReceiptDetails.payment_receipt_containers.push({})
                "
                :disabled="
                  paymentReceiptDetails.payment_receipt_containers.length >=
                    4 || isReadOnly
                "
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pa-3">
              <v-row
                v-for="(
                  con, i
                ) in paymentReceiptDetails.payment_receipt_containers"
                :key="i"
                class="ma-0 justify-space-between"
              >
                <v-col cols="auto" class="Form-Columns-Dense align-self-center">
                  <span class="text-h6 font-weight-regular">
                    # {{ i + 1 }}
                  </span>
                </v-col>

                <v-col cols="11">
                  <v-row>
                    <v-col cols="12" lg="3" class="Form-Columns-Dense">
                      <InputField
                        type="text"
                        placeholder="ABCD1234567"
                        label="Container Number *"
                        :clearable="false"
                        :readonly="isReadOnly"
                        :rules="[(v) => !!v || 'Container Number is Required']"
                        hide-details="auto"
                        persistent-hint
                        v-model="con.container_no"
                        :error-messages="
                          formErrors &&
                          formErrors.payment_receipt_containers &&
                          formErrors.payment_receipt_containers[i] &&
                          formErrors.payment_receipt_containers[i].container_no
                            ? formErrors.payment_receipt_containers[i]
                                .container_no
                            : []
                        "
                        @input="
                          formErrors &&
                          formErrors.payment_receipt_containers &&
                          formErrors.payment_receipt_containers[i] &&
                          formErrors.payment_receipt_containers[i].container_no
                            ? delete formErrors.payment_receipt_containers[i]
                                .container_no
                            : [];
                          checkFormErrors();
                        "
                      />
                    </v-col>
                    <v-col cols="12" lg="6" class="Form-Columns-Dense">
                      <MultiSelect
                        label="Container Type *"
                        :readonly="isReadOnly"
                        :items="allContainersType"
                        itemText="title"
                        itemValue="id"
                        :rules="[(v) => !!v || 'Container Type is Required']"
                        hide-details="auto"
                        v-model="con.container_type"
                        :error-messages="
                          formErrors &&
                          formErrors.payment_receipt_containers &&
                          formErrors.payment_receipt_containers[i] &&
                          formErrors.payment_receipt_containers[i]
                            .container_type
                            ? formErrors.payment_receipt_containers[i]
                                .container_type
                            : []
                        "
                        @change="
                          formErrors &&
                          formErrors.payment_receipt_containers &&
                          formErrors.payment_receipt_containers[i] &&
                          formErrors.payment_receipt_containers[i]
                            .container_type
                            ? delete formErrors.payment_receipt_containers[i]
                                .container_type
                            : [];
                          checkFormErrors();
                        "
                      />
                    </v-col>
                    <v-col cols="12" lg="3" class="Form-Columns-Dense">
                      <InputField
                        type="number"
                        label="Container Weight (Tons) *"
                        :clearable="false"
                        :readonly="isReadOnly"
                        aria-placeholder=""
                        step=".01"
                        :rules="[
                          (v) => !!v || 'Container Weight is Required',
                          (v) =>
                            v > 0 || 'Container Weight can not be less than 0',
                          (v) =>
                            $globals.containerWeightFormatRegExp.test(v) ||
                            'Container Weight must have the format 00.00',
                        ]"
                        hide-details="auto"
                        v-model="con.weight"
                        :error-messages="
                          formErrors &&
                          formErrors.payment_receipt_containers &&
                          formErrors.payment_receipt_containers[i] &&
                          formErrors.payment_receipt_containers[i].weight
                            ? formErrors.payment_receipt_containers[i].weight
                            : []
                        "
                        @input="
                          formErrors &&
                          formErrors.payment_receipt_containers &&
                          formErrors.payment_receipt_containers[i] &&
                          formErrors.payment_receipt_containers[i].weight
                            ? (delete formErrors.payment_receipt_containers[i]
                                .weight,
                              checkFormErrors())
                            : []
                        "
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col
                  cols="12"
                  lg="auto"
                  class="Form-Columns-Dense text-right align-self-center"
                >
                  <v-btn
                    color="secondary"
                    depressed
                    small
                    :disabled="i == 0 || isReadOnly"
                    @click="removeContainer(i)"
                  >
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="ma-1">
            <v-card-title class="heading px-4 py-2">
              <span>Add Charge</span>
              <v-spacer></v-spacer>
              <v-btn
                depressed
                small
                color="primary"
                @click="paymentReceiptDetails.payment_receipt_charges.push({})"
                :disabled="
                  paymentReceiptDetails && paymentReceiptDetails.is_send_to_sap
                "
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pa-3">
              <v-row
                v-for="(
                  charge, i
                ) in paymentReceiptDetails.payment_receipt_charges"
                :key="i + keyUpdate"
                class="ma-0 justify-space-between"
              >
                <v-col cols="1" class="Form-Columns-Dense">
                  <span class="text-h6"> # {{ i + 1 }} </span>
                </v-col>

                <v-col cols="10">
                  <v-row>
                    <v-col cols="12" lg="2" class="Form-Columns-Dense">
                      <MultiSelect
                        label="Charge *"
                        :readonly="
                          paymentReceiptDetails &&
                          paymentReceiptDetails.is_send_to_sap
                        "
                        :items="allCharges"
                        itemText="charge_title"
                        itemValue="id"
                        :rules="[(v) => !!v || 'Charge is Required']"
                        :returnObject="true"
                        hide-details="auto"
                        v-model="charge.charge"
                        :error-messages="
                          formErrors &&
                          formErrors.payment_receipt_charges &&
                          formErrors.payment_receipt_charges[i] &&
                          formErrors.payment_receipt_charges[i].charge
                            ? formErrors.payment_receipt_charges[i].charge
                            : []
                        "
                        @input="
                          formErrors &&
                          formErrors.payment_receipt_charges &&
                          formErrors.payment_receipt_charges[i] &&
                          formErrors.payment_receipt_charges[i].charge
                            ? delete formErrors.payment_receipt_charges[i]
                                .charge
                            : null;
                          setChargeDetails($event, i);
                          checkChargeErrors();
                          calculateReadonlyFieldsData();
                        "
                      />
                    </v-col>
                    <v-col cols="12" lg="2" class="Form-Columns-Dense">
                      <MultiSelect
                        outlined
                        readonly
                        label="Tax Type *"
                        :items="taxTypeList"
                        v-model="charge.tax_type"
                        :error-messages="formErrors.tax_type"
                      />
                    </v-col>
                    <v-col cols="12" lg="3" class="Form-Columns-Dense">
                      <InputField
                        label="Description"
                        :clearable="false"
                        hide-details="auto"
                        v-model="charge.description"
                        :error-messages="
                          formErrors &&
                          formErrors.payment_receipt_charges &&
                          formErrors.payment_receipt_charges[i] &&
                          formErrors.payment_receipt_charges[i].description
                            ? formErrors.payment_receipt_charges[i].description
                            : []
                        "
                        @input="
                          formErrors &&
                          formErrors.payment_receipt_charges &&
                          formErrors.payment_receipt_charges[i] &&
                          formErrors.payment_receipt_charges[i].description
                            ? delete formErrors.payment_receipt_charges[i]
                                .description
                            : null;
                          checkChargeErrors();
                        "
                      />
                    </v-col>
                    <v-col cols="12" lg="1" class="Form-Columns-Dense">
                      <InputField
                        label="Revenue GL No."
                        readonly
                        :clearable="false"
                        hide-details="auto"
                        v-model="charge.gl_no"
                        :error-messages="
                          formErrors &&
                          formErrors.payment_receipt_charges &&
                          formErrors.payment_receipt_charges[i] &&
                          formErrors.payment_receipt_charges[i].gl_no
                            ? formErrors.payment_receipt_charges[i].gl_no
                            : []
                        "
                        @input="
                          formErrors &&
                          formErrors.payment_receipt_charges &&
                          formErrors.payment_receipt_charges[i] &&
                          formErrors.payment_receipt_charges[i].gl_no
                            ? delete formErrors.payment_receipt_charges[i].gl_no
                            : null;
                          checkChargeErrors();
                        "
                      />
                    </v-col>
                    <v-col cols="12" lg="1" class="Form-Columns-Dense">
                      <InputField
                        type="number"
                        :label="`Rate (${$globals.currency}) *`"
                        :disabled="!charge.charge"
                        :clearable="false"
                        :rules="[
                          (val) =>
                            $globals.fourDecimalPlacesRegExp.test(val) ||
                            'Rate must have 4 decimal places only',
                          (val) =>
                            val > 0 ||
                            'Rate can not be less than or equal to 0',
                        ]"
                        hide-details="auto"
                        :readonly="
                          (paymentReceiptDetails &&
                            paymentReceiptDetails.da_status &&
                            paymentReceiptDetails.da_status ==
                              'Ready For Invoice') ||
                          paymentReceiptDetails.da_status == 'Invoice Generated'
                        "
                        v-model="charge.rate"
                        :error-messages="
                          formErrors &&
                          formErrors.payment_receipt_charges &&
                          formErrors.payment_receipt_charges[i] &&
                          formErrors.payment_receipt_charges[i].rate
                            ? formErrors.payment_receipt_charges[i].rate
                            : []
                        "
                        @input="
                          formErrors &&
                          formErrors.payment_receipt_charges &&
                          formErrors.payment_receipt_charges[i] &&
                          formErrors.payment_receipt_charges[i].rate
                            ? delete formErrors.payment_receipt_charges[i].rate
                            : null;
                          checkChargeErrors();
                          calculateAmount(i);
                        "
                      />
                    </v-col>
                    <v-col cols="12" lg="1" class="Form-Columns-Dense">
                      <InputField
                        type="number"
                        label="TAX (%)"
                        readonly
                        :clearable="false"
                        hide-details="auto"
                        v-model="charge.tax_percentage"
                      />
                    </v-col>
                    <v-col cols="12" lg="2" class="Form-Columns-Dense">
                      <InputField
                        hide-details="auto"
                        type="number"
                        :label="`Tax Amount (${$globals.currency})`"
                        :clearable="false"
                        readonly
                        :value="charge.tax_amount"
                      />
                    </v-col>
                    <v-col cols="12" lg="2" class="Form-Columns-Dense">
                      <InputField
                        hide-details="auto"
                        type="number"
                        :label="`Amount (${$globals.currency})`"
                        :clearable="false"
                        readonly
                        :value="charge.amount_with_tax"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col
                  cols="12"
                  lg="1"
                  class="Form-Columns-Dense text-right align-self-center"
                >
                  <v-btn
                    :disabled="
                      paymentReceiptDetails &&
                      paymentReceiptDetails.is_send_to_sap
                    "
                    color="secondary"
                    small
                    depressed
                    @click="removeCharge(i)"
                  >
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          depressed
          :disabled="
            !isValid ||
            (paymentReceiptDetails && paymentReceiptDetails.is_send_to_sap)
          "
          @click="submitForm"
        >
          {{ paymentReceiptID && !isCopyOperation ? "Update" : "Add" }}
        </v-btn>
        <v-btn
          v-if="!paymentReceiptID"
          class="ml-3"
          color="secondary"
          depressed
          @click="resetForm"
        >
          Reset
        </v-btn>
      </v-card-actions>
      <v-card-text class="pa-2">
        <v-row class="ma-0 mb-4">
          <v-col cols="12" lg="3" class="Form-Columns-Dense">
            <InputField
              type="text"
              label="Net Amount"
              :clearable="false"
              hide-details="auto"
              v-model="totalNetAmount"
              readonly
              class="grey lighten-3"
            />
          </v-col>

          <v-col cols="12" lg="3" class="Form-Columns-Dense">
            <InputField
              type="text"
              label="Tax Amount"
              :clearable="false"
              hide-details="auto"
              v-model="totalTaxAmount"
              readonly
              class="grey lighten-3"
            />
          </v-col>

          <v-col cols="12" lg="3" class="Form-Columns-Dense">
            <InputField
              type="text"
              label="Gross Amount"
              :clearable="false"
              hide-details="auto"
              v-model="totalGrossAmount"
              readonly
              class="grey lighten-3"
            /> </v-col
        ></v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import Autocomplete from "@/components/FormBaseComponents/Autocomplete.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";

export default {
  components: {
    InputField,
    MultiSelect,
    DateField,
    Autocomplete,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    isCopyOperation: {
      type: Boolean,
      default: false,
    },
    companyId: {
      default: null,
    },
    paymentReceiptID: {
      default: null,
    },
    daId: {
      default: null,
    },
    customer: {
      default: null,
    },
    laneId: {
      default: null,
    },
    jobType: {
      default: null,
    },
  },
  data() {
    return {
      getListCalled: false,

      formErrors: {},
      isValid: true,
      formFieldHints: [{}],
      paymentReceiptDetails: {
        rate: 0,
        payment_receipt_charges: [],
        payment_receipt_containers: [{}],
      },
      paymentReceiptList: [],
      taxTypeList: [
        "Standard Rated",
        "Zero Rated",
        "Exempt Rated",
        "Exclude Rated",
      ],
      allpaymentModes: [],
      allCustomerList: [],
      allJobType: [],
      allLaneList: [],
      allBanksList: [],
      allCharges: [],
      allBankList: [],
      allContainersType: [],
      keyUpdate: 0,
      taxAmount: 0,
      totalNetAmount: 0,
      totalTaxAmount: 0,
      totalGrossAmount: 0,

      listLoading: false,
      laneCount: 0,
    };
  },
  computed: {
    isReadOnly() {
      if (this.isCopyOperation) {
        return false;
      } else if (
        this.paymentReceiptID &&
        this.paymentReceiptDetails &&
        this.paymentReceiptDetails.is_used &&
        !this.isCopyOperation
      ) {
        return true;
      } else {
        return false;
      }
    },
    showPaymentReceiptForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    totalAmount() {
      if (this.paymentReceiptDetails.rate) {
        return (
          parseFloat(this.paymentReceiptDetails.rate) +
          parseFloat(this.taxAmount)
        ).toFixed(4);
      } else {
        return 0;
      }
    },
  },
  watch: {
    value(val) {
      if (val) {
        if (!this.getListCalled) {
          this.getListCalled = true;
          this.getLists([
            this.getPaymentReceiptOptions(),
            this.getCustomerList(),
            this.getJobTypeOptions(),
            this.getLaneList(),
            this.getCharges(),
            this.getBankList(),
            this.getContainersType(),
          ]);
        }

        this.paymentReceiptDetails.delivery_advice = this.daId;
        this.paymentReceiptDetails.company = this.customer;
        this.paymentReceiptDetails.lane = this.laneId;
        this.paymentReceiptDetails.job_type = this.jobType;
        if (this.companyId) {
          if (typeof this.companyId == typeof {}) {
            this.paymentReceiptDetails.company = this.companyId.id;
          } else {
            this.paymentReceiptDetails.company = this.companyId;
          }
        }
        if (this.paymentReceiptID) {
          this.getPaymentReceiptData();
        }
      } else {
        this.resetForm();
      }
    },
  },

  methods: {
    clearFields() {
      if (this.paymentReceiptDetails.payment_mode != "Cheque") {
        this.paymentReceiptDetails.customer_bank = null;
        this.paymentReceiptDetails.customer_branch = null;
        this.paymentReceiptDetails.cheque_no = null;
        this.paymentReceiptDetails.cheque_date = null;
      }
    },
    checktaxAmount() {
      const serviceType =
        this.paymentReceiptDetails.lane &&
        this.paymentReceiptDetails.lane.service_type;
      if (
        this.paymentReceiptDetails.company &&
        typeof this.paymentReceiptDetails.company != typeof {}
      ) {
        const companyObj = this.allCustomerList.find(
          (company) => company.id == this.paymentReceiptDetails.company
        );
        if (companyObj) {
          this.paymentReceiptDetails.company = companyObj;
        }
      }

      if (
        this.paymentReceiptDetails.company &&
        this.paymentReceiptDetails.company.customer_group &&
        this.paymentReceiptDetails.company.customer_group == "Third Party" &&
        serviceType &&
        serviceType == "local"
      ) {
        if (this.paymentReceiptDetails.rate) {
          this.taxAmount = parseFloat(
            (this.paymentReceiptDetails.rate / 100) * 15
          );
          this.taxAmount = this.taxAmount.toFixed(4);
        }
      } else {
        this.taxAmount = 0;
      }
    },
    calculateReadonlyFieldsData() {
      this.totalNetAmount = 0;
      this.totalTaxAmount = 0;
      this.totalGrossAmount = 0;

      let additionalRate = 0;
      let additionalRateTaxAmount = 0;
      let additionalGrossAmount = 0;
      if (this.paymentReceiptDetails.payment_receipt_charges) {
        const totalCharge =
          this.paymentReceiptDetails.payment_receipt_charges.length;
        let i = 0;

        while (i < totalCharge) {
          if (
            Number.isNaN(
              parseFloat(
                this.paymentReceiptDetails.payment_receipt_charges[i].rate
              )
            )
          ) {
            additionalRate += 0;
          } else {
            additionalRate += parseFloat(
              this.paymentReceiptDetails.payment_receipt_charges[i].rate
            );
          }
          if (
            Number.isNaN(
              parseFloat(
                this.paymentReceiptDetails.payment_receipt_charges[i].tax_amount
              )
            )
          ) {
            additionalRate += 0;
          } else {
            additionalRateTaxAmount += parseFloat(
              this.paymentReceiptDetails.payment_receipt_charges[i].tax_amount
            );
          }
          if (
            Number.isNaN(
              parseFloat(
                this.paymentReceiptDetails.payment_receipt_charges[i]
                  .amount_with_tax
              )
            )
          ) {
            additionalRate += 0;
          } else {
            additionalGrossAmount += parseFloat(
              this.paymentReceiptDetails.payment_receipt_charges[i]
                .amount_with_tax
            );
          }

          i++;
        }
      }
      let totalNetAmount1 = 0;
      let totalTaxAmount1 = 0;
      let totalGrossAmount1 = 0;

      totalNetAmount1 +=
        parseFloat(this.paymentReceiptDetails.rate) + additionalRate;
      totalTaxAmount1 += parseFloat(this.taxAmount) + additionalRateTaxAmount;
      totalGrossAmount1 += parseFloat(this.totalAmount) + additionalGrossAmount;

      this.totalNetAmount = totalNetAmount1.toFixed(4);
      this.totalTaxAmount = totalTaxAmount1.toFixed(4);
      this.totalGrossAmount = totalGrossAmount1.toFixed(4);
    },
    getLists(apis) {
      this.$bus.$emit("showLoader", true);

      return new Promise((resolve, reject) => {
        Promise.all(apis)
          .then((val) => {
            this.getListCalled = false;
            this.$bus.$emit("showLoader", false);
            resolve();
          })
          .catch((err) => {
            this.getListCalled = false;
            this.$bus.$emit("showLoader", false);
            reject();
          });
      });
    },
    checkFormErrors() {
      if (
        this.formErrors &&
        this.formErrors.payment_receipt_containers &&
        (this.formErrors.payment_receipt_containers.length == 0 ||
          this.formErrors.payment_receipt_containers.every(
            (obj) => !obj || Object.keys(obj).length == 0
          ))
      ) {
        delete this.formErrors.payment_receipt_containers;
      }
    },
    submitForm() {
      this.$bus.$emit("showLoader", true);
      this.formErrors = {};

      let params = {
        ...this.paymentReceiptDetails,
      };
      if (params.company && typeof params.company == typeof {}) {
        params.company = params.company.id;
      } else {
        params.company = params.company;
      }

      if (params.lane && typeof params.lane == typeof {}) {
        params.lane = params.lane.id;
      } else {
        params.lane = params.lane;
      }

      if (!this.paymentReceiptID && !this.isCopyOperation) {
        this.$api.paymentReceipt
          .addReceiptDetails(params)
          .then(() => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Payment Receipt added successfully!",
              color: "success",
            });

            this.$emit("refreshList");
            this.showPaymentReceiptForm = false;
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't add Payment Receipt!",
              color: "error",
            });
            this.formErrors = err.data;
          });
      } else if (this.isCopyOperation) {
        let containerIndex = 0;
        if (params && params.payment_receipt_containers) {
          while (containerIndex < params.payment_receipt_containers.length) {
            delete params.payment_receipt_containers[containerIndex].id;
            containerIndex++;
          }
        }
        let chargeIndex = 0;
        if (params && params.payment_receipt_charges) {
          while (chargeIndex < params.payment_receipt_charges.length) {
            delete params.payment_receipt_charges[chargeIndex].id;
            chargeIndex++;
          }
        }
        let payload = {
          payment_receipt_charges: params.payment_receipt_charges,
          payment_receipt_containers: params.payment_receipt_containers,
          company: params.company,
          lane: params.lane,
          job_type: params.job_type,
          reference_number: params.reference_number,
          payment_mode: params.payment_mode,
          collected_by: params.collected_by,
          bank_details: params.bank_details,
          rate: params.rate,
          remarks: params.remarks,
          movement_date: params.movement_date,
          customer_bank: params.customer_bank,
          customer_branch: params.customer_branch,
          cheque_no: params.cheque_no,
          cheque_date: params.cheque_date,
        };
        this.$api.paymentReceipt
          .addReceiptDetails(payload)
          .then(() => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Payment Receipt added successfully!",
              color: "success",
            });

            this.$emit("refreshList");
            this.showPaymentReceiptForm = false;
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't add Payment Receipt!",
              color: "error",
            });
            this.formErrors = err.data;
          });
      } else {
        this.$api.paymentReceipt
          .updateReceiptDetails({
            id: this.paymentReceiptDetails.id,
            data: params,
          })
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Payment Receipt updated successfully!",
              color: "success",
            });
            this.showPaymentReceiptForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't update Payment Receipt!",
              color: "error",
            });
            this.formErrors = err.data;
          });
      }
    },

    removeContainer(index) {
      this.paymentReceiptDetails.payment_receipt_containers.splice(index, 1);
    },

    getPaymentReceiptData() {
      this.$bus.$emit("showLoader", true);
      this.$api.paymentReceipt
        .getPaymentReceiptDetails(this.paymentReceiptID)
        .then(({ data }) => {
          this.$bus.$emit("showLoader", false);
          if (data) {
            this.paymentReceiptDetails = data;
            if (this.paymentReceiptDetails.lane) {
              this.getLaneObject(this.paymentReceiptDetails.lane);
            }
            setTimeout(() => {
              this.calculateReadonlyFieldsData();
            }, 500);
          }
          this.$bus.$emit("showLoader", false);
        })

        .catch((err) => {
          this.$bus.$emit("showLoader", false);
        });
    },

    getLaneObject(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.lane
        .getLaneObject(id)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          const data = res?.data;
          if (data) {
            this.paymentReceiptDetails.lane = data;
          }
          const serviceType = data?.service_type;
          if (
            this.paymentReceiptDetails &&
            this.paymentReceiptDetails.customer_group &&
            this.paymentReceiptDetails.customer_group == "Third Party" &&
            serviceType &&
            serviceType == "local"
          ) {
            this.taxAmount = parseFloat(
              (this.paymentReceiptDetails.rate / 100) * 5
            );
            this.taxAmount = this.taxAmount.toFixed(4);
          } else {
            this.taxAmount = 0;
          }
        })

        .catch((err) => {
          this.$bus.$emit("showLoader", false);
        });
    },

    getCustomerList(params = {}) {
      params = {
        company_type: "Customer",
        limit: "all",
        ...params,
        limited: true,
      };
      return this.$api.company
        .getCompanySelectList(params)
        .then((res) => {
          this.allCustomerList = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getPaymentReceiptBlob() {
      this.$bus.$emit("showLoader", true);

      this.$api.paymentReceipt
        .getPaymentReceiptBlob(this.paymentReceiptDetails.id)
        .then((resp) => {
          let blob = new Blob([resp.data], {
            type: resp.headers["content-type"],
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = this.paymentReceiptDetails.receipt_id + ".pdf";
          link.click();
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getPaymentReceiptOptions() {
      return this.$api.paymentReceipt
        .getPaymentReceiptOptions()
        .then((res) => {
          this.allpaymentModes = res.data.actions.POST.payment_mode.choices;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getJobTypeOptions() {
      return this.$api.bmr
        .getBMROptions()
        .then((res) => {
          this.allJobType = res.data.actions.POST.job_type.choices;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getLaneList(params = {}) {
      this.listLoading = true;
      params = {
        is_active: "true",
        limited: true,
        offset: 0,
        limit: 10,
        ...params,
      };

      this.$api.lane
        .getLaneSelectList(params)
        .then((res) => {
          this.listLoading = false;
          if ("count" in res) {
            this.laneCount = res.count;
          }

          if (params.offset == 0) {
            this.allLaneList = res.data;
          } else {
            this.allLaneList.push(...res.data);
          }
        })
        .catch((err) => {
          this.listLoading = false;
        });
    },
    getBankList(params = {}) {
      if (this.paymentReceiptID) {
        params = {
          limit: "all",
        };
      } else {
        params = {
          limit: "all",
          is_active: true,
        };
      }
      return this.$api.bankDetails
        .getbankDetailsList(params)
        .then((res) => {
          this.allBanksList = res.data;
        })
        .catch(() => {
          this.$bus.$emit("showLoader", false);
        });
    },
    getContainersType() {
      return this.$api.containerType
        .getContainerTypeSelectList({ limit: "all" })
        .then((res) => {
          this.allContainersType = res.data;
        })
        .catch(() => {
          this.$bus.$emit("showLoader", false);
        });
    },
    setChargeDetails(val, index) {
      if (val) {
        this.paymentReceiptDetails.payment_receipt_charges[index].charge =
          val.id;
        this.paymentReceiptDetails.payment_receipt_charges[index].charge_title =
          val.charge_title;
        this.paymentReceiptDetails.payment_receipt_charges[index].gl_no =
          val.gl_no;
        this.paymentReceiptDetails.payment_receipt_charges[index].description =
          val.description;
        this.paymentReceiptDetails.payment_receipt_charges[index].rate =
          val.rate;
        this.paymentReceiptDetails.payment_receipt_charges[
          index
        ].tax_percentage = val.tax_percentage;
        this.paymentReceiptDetails.payment_receipt_charges[index].tax_type =
          val.tax_type;

        const rate =
          this.paymentReceiptDetails.payment_receipt_charges[index].rate;
        const tax =
          this.paymentReceiptDetails.payment_receipt_charges[index]
            .tax_percentage;
        const quantity = 1;
        if (rate && quantity) {
          const totalGrossRate = parseFloat(rate) * parseFloat(quantity);
          if (totalGrossRate && tax) {
            const totalTaxAmount = totalGrossRate * (parseFloat(tax) / 100);
            this.paymentReceiptDetails.payment_receipt_charges[
              index
            ].tax_amount = totalTaxAmount.toFixed(4);
            this.paymentReceiptDetails.payment_receipt_charges[
              index
            ].amount_with_tax = (totalGrossRate + totalTaxAmount).toFixed(4);
          }
        }

        this.keyUpdate++;
      } else if (
        this.paymentReceiptDetails &&
        this.paymentReceiptDetails.payment_receipt_charges
      ) {
        this.paymentReceiptDetails.payment_receipt_charges[index] = {};
      }
    },
    calculateAmount(chargeIndex) {
      if (this.chargeLoadTimeout) {
        clearTimeout(this.chargeLoadTimeout);
      }
      this.chargeLoadTimeout = setTimeout(() => {
        const rate =
          this.paymentReceiptDetails.payment_receipt_charges[chargeIndex].rate;
        const tax =
          this.paymentReceiptDetails.payment_receipt_charges[chargeIndex]
            .tax_percentage;
        const quantity = 1;
        if (rate && quantity) {
          const totalGrossRate = parseFloat(rate) * parseFloat(quantity);
          if (totalGrossRate && tax) {
            const totalTaxAmount = totalGrossRate * (parseFloat(tax) / 100);
            this.paymentReceiptDetails.payment_receipt_charges[
              chargeIndex
            ].tax_amount = totalTaxAmount.toFixed(4);
            this.paymentReceiptDetails.payment_receipt_charges[
              chargeIndex
            ].amount_with_tax = (totalGrossRate + totalTaxAmount).toFixed(4);
            this.keyUpdate++;
          }
        }
        this.calculateReadonlyFieldsData();
      }, 500);
    },
    getCharges() {
      return this.$api.charge
        .getChargeSelectList({
          limit: "all",
          is_active: "true",
          is_revenue: "true",
        })
        .then((res) => {
          this.allCharges = res.data;
        })
        .catch(() => {
          this.$bus.$emit("showLoader", false);
        });
    },
    addCharge() {
      this.paymentReceiptDetails.payment_receipt_charges.push({});
    },

    removeCharge(i) {
      this.paymentReceiptDetails.payment_receipt_charges.splice(i, 1);
      this.calculateReadonlyFieldsData();
    },

    checkChargeErrors() {
      if (
        this.formErrors &&
        this.formErrors.payment_receipt_charges &&
        (this.formErrors.payment_receipt_charges.length == 0 ||
          this.formErrors.payment_receipt_charges.every(
            (obj) => Object.keys(obj).length == 0
          ))
      ) {
        delete this.formErrors.payment_receipt_charges;
      }
    },

    resetForm() {
      this.paymentReceiptDetails = {};
      this.formErrors = {};

      if (this.paymentReceiptID) {
        this.showPaymentReceiptForm = false;
      }

      this.paymentReceiptDetails = {
        payment_receipt_charges: [],
        payment_receipt_containers: [{}],
      };

      if (this.$refs && this.$refs.chargeForm) {
        this.$refs.chargeForm.resetValidation();
      }
      this.$emit("updateCopyOperation", this.value);

      this.taxAmount = 0;
      this.totalNetAmount = 0;
      this.totalTaxAmount = 0;
      this.totalGrossAmount = 0;
    },
  },
};
</script>
