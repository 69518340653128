<template>
  <v-row class="pa-0">
    <v-col>
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon color="primary" v-on="on" v-bind="attrs" @click="viewItem">
            mdi-eye
          </v-icon>
        </template>
        <span>View</span>
      </v-tooltip>
    </v-col>

    <!-- <v-col v-if="params.context.parentComponent.tab != 0">
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon color="primary" v-on="on" v-bind="attrs" @click="editItem"
            >mdi-pencil</v-icon
          >
        </template>
        <span>Edit</span>
      </v-tooltip>
    </v-col> -->
  </v-row>
</template>

<script>
export default {
  methods: {
    viewItem() {
      this.params.context.parentComponent.viewContract(this.params.data.id);
    },
  },
};
</script>

