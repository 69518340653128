import { customerOrder } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getCustomerOrderList(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(customerOrder.customerOrderBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addCustomerOrder(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(customerOrder.customerOrderBase, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updateCustomerOrder(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${customerOrder.customerOrderBase}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getCustomerOrderObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${customerOrder.customerOrderBase}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getCustomerOrderDetails(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${customerOrder.customerOrderBase}${id}/view/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getOrderOptions(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .options(customerOrder.customerOrderBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

});
