<template>
  <v-dialog width="500" v-model="showDARemarksDialog">
    <v-card>
      <v-card-title class="secondary white--text">
        <span> {{ dialogTitle }} </span>

        <v-spacer></v-spacer>

        <v-btn icon @click="showDARemarksDialog = false">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="mt-2">
        <v-form ref="daListForm" v-if="showDARemarksDialog" v-model="isValid">
          <v-row class="my-0">
            <v-col cols="12" class="Form-Columns">
              <TextareaField
                label="DA Remarks"
                hide-details="auto"
                :clearable="false"
                v-model="daRemarks"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          depressed
          @click="submitForm"
        >
          Save
        </v-btn>

        <v-btn class="ml-3" color="secondary" depressed @click="resetForm">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import SelectField from "@/components/FormBaseComponents/SelectField.vue";
import { AgGridVue } from "ag-grid-vue";
import TextareaField from "@/components/FormBaseComponents/TextareaField.vue";

export default {
  components: {
    AgGridVue,
    InputField,
    SelectField,
    MultiSelect,
    TextareaField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    dialogTitle: {
      type: String,
      default: "",
    },
    fieldValue: {
      type: String,
      default: "",
    },
    objID: {
      type: String,
      default: "",
    },
    legDetails: {
      default: false,
    },
  },
  watch: {
    value(val) {
      if (val) {
      }
    },
    fieldValue(val) {
      this.daRemarks = val;
    },
  },
  computed: {
    showDARemarksDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    context() {
      return { parentComponent: this };
    },
  },
  data() {
    return {
      formErrors: {},
      isValid: true,
      editMode: false,
      selectedDAList: [],
      daList: [],
      daRemarks: null,

      // ag-grid vars
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },
    };
  },
  methods: {
    resizeGrid() {
      if (this.gridOptions && "api" in this.gridOptions) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },

    submitForm() {
      this.$bus.$emit("showLoader", true);
      this.formErrors = {};
      let payload = {
        remarks: this.daRemarks,
      };

      let params = {
        id: this.objID,
        data: payload,
      };
      this.$api.deliveryAdvice
        .daRemarks(params)
        .then(() => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Delivery Advices remarks successfully updated!",
            color: "success",
          });
          this.resetForm();
          this.showDARemarksDialog = false;
          this.$emit("refreshList");
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't udpate delivery advice remarks!",
            color: "error",
          });
          this.formErrors = err.data;
        });
    },

    resetForm() {
      this.daRemarks = null
      this.showDARemarksDialog = false;
    },
  },
};
</script>
