<template>
  <FilterParent
    module="Invoice"
    maxWidth="500"
    v-model="showAdminTransporterInvoiceFilters"
  >
    <template #filterContent>
      <v-row class="mt-0">
        

        <v-col cols="12">
          <span class="text-h6 font-weight-medium">Invoice Date Filter</span>
        </v-col>

        <v-col cols="12" class="Form-Columns">
          <InputField
            type="date"
            label="Select Start Date"
            v-model="filterObject.start_date"
          />
        </v-col>

        <v-col cols="12" class="Form-Columns">
          <InputField
            type="date"
            label="Select End Date"
            v-model="filterObject.end_date"
          />
        </v-col>
      </v-row>
    </template>

    <template #filterAction>
      <v-btn
        color="secondary"
        depressed
        @click="
          $emit('resetFilters'), (showAdminTransporterInvoiceFilters = false)
        "
        >Reset</v-btn
      >
      <v-btn
        color="primary"
        class="ml-4"
        depressed
        @click="
          $emit('applyFilters'), (showAdminTransporterInvoiceFilters = false)"
        >Apply</v-btn
      >
    </template>
  </FilterParent>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import FilterParent from "@/components/General/FilterParent.vue";

export default {
  components: {
    FilterParent,
    MultiSelect,
    InputField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    bindingObject: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      allTransporterList: [],
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.getCustomerList();
      }
    },
  },
  computed: {
    showAdminTransporterInvoiceFilters: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    filterObject: {
      get() {
        return this.bindingObject;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (!obj) {
        return null;
      } else {
        return obj;
      }
    },
  },
  methods: {
    getCustomerList() {
      this.$bus.$emit("showLoader", true);
      this.$api.company
        .getCompanyList({ limit: "all", company_type: "Transporter" })
        .then((res) => {
          this.allTransporterList = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
};
</script>

<style>
</style>