<template>
  <v-dialog width="500" v-model="showManualSequenceDialog">
    <v-card>
      <v-card-title class="secondary white--text">
        <span> {{ dialogTitle }} </span>

        <v-spacer></v-spacer>

        <v-btn icon @click="showManualSequenceDialog = false">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-2">
        <v-form
          ref="daListForm"
          v-if="showManualSequenceDialog"
          v-model="isValid"
        >
          <v-row class="my-0">
            <v-col cols="12" class="Form-Columns">
              <InputField
                type="text"
                label="Manual Sequence"
                :dense="true"
                hide-details="auto"
                required
                v-model="manualSequence"
                :error-messages="formErrors.manual_sequence"
                @input="delete formErrors.manual_sequence"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn color="primary" depressed @click="submitForm"> Save </v-btn>

        <v-btn class="ml-3" color="secondary" depressed @click="resetForm">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import SelectField from "@/components/FormBaseComponents/SelectField.vue";
import { AgGridVue } from "ag-grid-vue";

export default {
  components: {
    AgGridVue,
    InputField,
    SelectField,
    MultiSelect,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    dialogTitle: {
      type: String,
      default: "",
    },
    fieldValue: {
      type: [String, Number],
      default: 0,
    },
    objID: {
      type: [String, Number],
      default: "",
    },
    legDetails: {
      default: false,
    },
  },
  watch: {
    value(val) {},
    fieldValue(val) {
      this.manualSequence = val;
    },
  },
  computed: {
    showManualSequenceDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    context() {
      return { parentComponent: this };
    },
  },
  data() {
    return {
      formErrors: {},
      isValid: true,
      editMode: false,
      manualSequence: null,
      today: new Date().toISOString().slice(0, 16),

      // ag-grid vars
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },
    };
  },
  methods: {
    resizeGrid() {
      if (this.gridOptions && "api" in this.gridOptions) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },

    submitForm() {
      this.$bus.$emit("showLoader", true);
      this.formErrors = {};
      let payload = {
        manual_sequence: this.manualSequence,
      };

      let params = {
        id: this.objID,
        data: payload,
      };
      this.$api.trip
        .updateManualSequence(params)
        .then(() => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Manual Sequence successfully updated!",
            color: "success",
          });
          this.resetForm();
          this.showManualSequenceDialog = false;
          this.manualSequence = null;

          this.$emit("refreshList");
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't udpate Manual Sequence!",
            color: "error",
          });
          this.formErrors = err.data;
        });
    },

    resetForm() {
      this.showManualSequenceDialog = false;
    },
  },
};
</script>
