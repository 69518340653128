var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.showDocumentForm),callback:function ($$v) {_vm.showDocumentForm=$$v},expression:"showDocumentForm"}},[_c('v-card',[_c('v-card-title',{staticClass:"secondary white--text"},[_c('span',{staticClass:"text-capitalize"},[_vm._v(" Add Document ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.resetForm(), (_vm.showDocumentForm = false)}}},[_c('v-icon',{staticClass:"white--text"},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pt-2"},[_c('v-form',{ref:"documentForm",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"Form-Columns",attrs:{"cols":"12"}},[_c('InputField',{attrs:{"type":"text","label":"Document Type","hide-details":"auto","error-messages":_vm.formErrors.document_type},on:{"input":function($event){delete _vm.formErrors.document_type}},model:{value:(_vm.documentDetails.document_type),callback:function ($$v) {_vm.$set(_vm.documentDetails, "document_type", $$v)},expression:"documentDetails.document_type"}})],1),_c('v-col',{staticClass:"Form-Columns",attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"label":"Document","accept":"image/x-png,image/jpg,image/jpeg,application/pdf","rules":[
                (file) =>
                  !file ||
                  file.size < 2e6 ||
                  'File size should be less than 2 MB!',
              ],"outlined":"","dense":"","hide-details":"auto","error-messages":_vm.formErrors.document},on:{"change":function($event){_vm.checkFileFormat($event), delete _vm.formErrors.document}},model:{value:(_vm.documentDetails.document),callback:function ($$v) {_vm.$set(_vm.documentDetails, "document", $$v)},expression:"documentDetails.document"}})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"primary","depressed":"","disabled":!_vm.documentDetails.document ||
          !_vm.documentDetails.document_type ||
          !_vm.isValid},on:{"click":_vm.uploadDADocuments}},[_vm._v("Upload")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }