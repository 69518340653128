export function formatDate(value, withTime) {
    if (value) {
        const _date = new Date(value);

        if (_date) {
            let date = _date.getDate();
            let month = _date.getMonth() + 1;
            let year = _date.getFullYear();

            date = date < 10 ? `0${date}` : `${date}`;
            month = month < 10 ? `0${month}` : `${month}`;

            let fullDate = [date, month, year].join("/");
            let time = [];

            if (withTime) {
                let hour = _date.getHours();
                let minute = _date.getMinutes();

                hour = hour < 10 ? `0${hour}` : `${hour}`;
                minute = minute < 10 ? `0${minute}` : `${minute}`;

                time = [hour, minute].join(":");

                fullDate = [fullDate, time].join(" ");
            }

            return fullDate;
        } else {
            return "";
        }
    }
    return "";
}

export function formatNumber(value) {
    if (value) {
        return parseFloat(value).toFixed(2);
    }
    return 0;
}