var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.showVehicleTypeForm),callback:function ($$v) {_vm.showVehicleTypeForm=$$v},expression:"showVehicleTypeForm"}},[_c('v-card',[_c('v-card-title',{staticClass:"secondary white--text py-2 px-4"},[_c('span',[_vm._v(" "+_vm._s(_vm.editMode ? "Update" : "Add")+" Vehicle Type ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.resetForm(), (_vm.showVehicleTypeForm = false)}}},[_c('v-icon',{staticClass:"white--text"},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pa-2"},[_c('v-form',{ref:"vehicleTypeForm",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"Form-Columns-Dense",attrs:{"cols":"12"}},[_c('InputField',{attrs:{"type":"text","label":"Type Name *","rules":[(v) => !!v || 'Type name is Required'],"required":"","hide-details":"auto","error-messages":_vm.formErrors.type_name},on:{"input":function($event){delete _vm.formErrors.type_name}},model:{value:(_vm.vehicleTypeDetails.type_name),callback:function ($$v) {_vm.$set(_vm.vehicleTypeDetails, "type_name", $$v)},expression:"vehicleTypeDetails.type_name"}})],1),_c('v-col',{staticClass:"Form-Columns-Dense",attrs:{"cols":"12"}},[_c('InputField',{attrs:{"type":"number","label":"Capacity in Ton *","step":"0.01","min":"0","rules":[
                (val) => !!val || 'Capacity is Required',
                (val) =>
                  val > 0 ||
                  'Number of Capacity can not be less than or equal to 0',
              ],"required":"","hide-details":"auto","error-messages":_vm.formErrors.payload_capacity},on:{"input":function($event){delete _vm.formErrors.payload_capacity}},model:{value:(_vm.vehicleTypeDetails.payload_capacity),callback:function ($$v) {_vm.$set(_vm.vehicleTypeDetails, "payload_capacity", $$v)},expression:"vehicleTypeDetails.payload_capacity"}})],1),_c('v-col',{staticClass:"Form-Columns-Dense",attrs:{"cols":"12"}},[_c('v-switch',{staticClass:"mt-1",attrs:{"label":`Trailer can be Attached : ${
                _vm.vehicleTypeDetails.attach_trailer == 'Yes' ? 'Yes' : 'No'
              }`,"true-value":"Yes","false-value":"No","hide-details":"auto","error-messages":_vm.formErrors.attach_trailer},on:{"change":function($event){delete _vm.formErrors.attach_trailer}},model:{value:(_vm.vehicleTypeDetails.attach_trailer),callback:function ($$v) {_vm.$set(_vm.vehicleTypeDetails, "attach_trailer", $$v)},expression:"vehicleTypeDetails.attach_trailer"}})],1),_c('v-col',{staticClass:"Form-Columns-Dense",attrs:{"cols":"12"}},[_c('v-switch',{staticClass:"mt-1",attrs:{"label":`Temperature Controlled : ${
                _vm.vehicleTypeDetails.temperature_controlled == 'Yes'
                  ? 'Yes'
                  : 'No'
              }`,"true-value":"Yes","false-value":"No","hide-details":"auto","error-messages":_vm.formErrors.temperature_controlled},on:{"change":function($event){delete _vm.formErrors.temperature_controlled}},model:{value:(_vm.vehicleTypeDetails.temperature_controlled),callback:function ($$v) {_vm.$set(_vm.vehicleTypeDetails, "temperature_controlled", $$v)},expression:"vehicleTypeDetails.temperature_controlled"}})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"primary","depressed":"","disabled":!_vm.isValid},on:{"click":_vm.submitForm}},[_vm._v(" Submit ")]),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"secondary","depressed":""},on:{"click":_vm.resetForm}},[_vm._v(" Reset ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }