import { trailerType } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
    getTrailerTypeList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(trailerType.trailerTypeBase, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getTrailerTypeSelectList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${trailerType.trailerTypeBase}select/`, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getTrailerTypeOptions() {
        return new Promise((resolve, reject) => {
            axios
                .options(trailerType.trailerTypeBase)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    addTrailerType(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(trailerType.trailerTypeBase, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getTrailerTypeObject(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${trailerType.trailerTypeBase}${id}/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    updateTrailerType(payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(`${trailerType.trailerTypeBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    partiallyUpdateTrailerType(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${trailerType.trailerTypeBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
});