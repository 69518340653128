import Blank from "@/layouts/blank.vue"

import UserManagementList from "@/pages/admin/user-management/UserManagementList.vue";
import UserManagementAdd from "@/pages/admin/user-management/UserManagementAdd.vue";
import UserManagementEdit from "@/pages/admin/user-management/UserManagementEdit.vue";

const userManagementRoutes = [
  {
    path: "user-management",
    name: "app-admin-user-management",
    component: Blank,
    children: [
      {
        path: "/",
        redirect: "/app/admin/user-management/list",
      },
      {
        path: "list",
        name: "app-admin-user-management-list",
        component: UserManagementList,
      },
      {
        path: "add",
        name: "app-admin-user-management-add",
        component: UserManagementAdd,
      },
      {
        path: "edit",
        name: "app-admin-user-management-edit",
        component: Blank,
        children: [
          {
            path: "/",
            redirect: "/app/admin/user-management/list",
          },
          {
            path: ":id",
            name: "app-admin-user-management-edit-id",
            component: UserManagementEdit,
          }
        ]
      },
    ]
  },
]

export default userManagementRoutes