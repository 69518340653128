<template>
    <FormComponent
      :title="`Edit Invoice ${invoiceDetails.invoice_no} Charges`"
      :backRoute="backRoute"
    >
      <template #formContent>
        <v-row class="ma-2">
          <v-col cols="4" class="Form-Columns-Dense">
            <DateField
              :inputProps="{
                dense: true,
                disabled: isReadOnly,
                label: 'Invoice Date *',
                'hide-details': 'auto',
                rules: [(val) => !!val || 'Invoice Date is Required!'],
              }"
              v-model="invoiceDetails.invoice_date"
            />
          </v-col>
          <v-col cols="4" class="Form-Columns-Dense">
            <InputField
              hide-details="auto"
              type="text"
              label="Remarks"
              :readonly="isReadOnly"
              :clearable="!isReadOnly"
              v-model="invoiceDetails.remarks"
            />
          </v-col>
          <v-col cols="4" class="Form-Columns-Dense d-flex align-center">
            <v-btn
              depressed
              :disabled="isReadOnly"
              color="primary"
              @click="partiallyUpdateCustomerInvoice()"
            >
              Update
            </v-btn>
          </v-col>
  
          <v-col cols="4" class="Form-Columns-Dense">
            <InputField
              hide-details="auto"
              type="number"
              :label="`Total Amount (${$globals.currency})`"
              readonly
              :clearable="false"
              v-model="invoiceDetails.total_amount"
            />
          </v-col>
          <v-col cols="4" class="Form-Columns-Dense">
            <InputField
              hide-details="auto"
              type="number"
              :label="`Total Tax Amount (${$globals.currency})`"
              readonly
              :clearable="false"
              v-model="invoiceDetails.total_tax_amount"
            />
          </v-col>
          <v-col cols="4" class="Form-Columns-Dense">
            <InputField
              hide-details="auto"
              type="number"
              :label="`Total Gross Amount (${$globals.currency})`"
              readonly
              :clearable="false"
              v-model="invoiceDetails.total_gross_amount"
            />
          </v-col>
          <v-col cols="3" class="Form-Columns-Dense">
            <v-text-field
              label="Search On Enter"
              outlined
              dense
              append-icon="mdi-magnify"
              clearable
              @click:clear="onEnter"
              hide-details="auto"
              v-model="filters.search"
              v-on:keyup.enter="onEnter"
            ></v-text-field>
          </v-col>
          <v-col>
            <b>Total Invoice DA</b>
            <v-badge
              v-if="invoiceDetails.total_invoice_das"
              top
              :content="invoiceDetails.total_invoice_das"
              overlap
              class="mb-4 ml-3"
            >
            </v-badge>
          </v-col>
  
          <v-col cols="12" class="py-2" v-if="invoiceAdditionalChargeList.length">
            <AgGridVue
              style="width: 100%; height: calc(100vh - 357px)"
              class="small-table ag-theme-alpine"
              id="legsGrid"
              :columnDefs="headers"
              :context="context"
              @grid-ready="onGridReady"
              :defaultColDef="defaultColDef"
              :grid-options="gridOptions"
              :suppressDragLeaveHidesColumns="true"
              :rowData="invoiceAdditionalChargeList"
            ></AgGridVue>
            <v-col cols="12" class="py-0 d-flex justify-end"> </v-col>
          </v-col>
          <v-col cols="12" class="Form-Columns-Dense text-right">
            <Pagination
              :pageNo="pageNo"
              :totalItems="totalInvoiceAdditionalCharge"
              :pageSize="itemsPerPage"
              :itemsPerPageValues="setItemsPerPage"
              @itemsPerPageChange="itemsPerPageChanged"
              @prevPage="prevPage"
              @nextPage="nextPage"
            />
          </v-col>
        </v-row>
        <NewCustomerInvoiceChargeFormDialog
          v-model="showInvoiceAdditionalChargeForm"
          :isEditing="isEditing"
          :chargeId="chargeId"
          :daId="selectedDaId"
          :invoiceId="invoiceDetails.id"
          @refreshList="refreshData"
          :isReadOnly="isReadOnly"
        />
      </template>
    </FormComponent>
  </template>
  
  <script>
  import ListComponent from "@/components/General/ListComponent.vue";
  import FormComponent from "@/components/General/FormComponent.vue";
  import { AgGridVue } from "ag-grid-vue";
  import Pagination from "@/components/General/Pagination.vue";
  import InputField from "@/components/FormBaseComponents/InputField.vue";
  import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
  import DateField from "@/components/FormBaseComponents/DateField.vue";
  import AdminInvoiceChargeButton from "@/components/AgGridComponents/AdminInvoiceChargeButton.vue";
  import NewCustomerInvoiceChargeFormDialog from "@/components/ModuleBased/dialogs/NewCustomerInvoiceChargeFormDialog.vue";
  
  export default {
    components: {
      ListComponent,
      AgGridVue,
      InputField,
      MultiSelect,
      AdminInvoiceChargeButton,
      DateField,
      Pagination,
      NewCustomerInvoiceChargeFormDialog,
      FormComponent,
    },
    data() {
      return {
        backRoute: { path: "/app/admin/invoice/credit-note/list" },
        invoiceDetails: {},
        invoiceAdditionalChargeList: [],
        setItemsPerPage: [10, 15, 20, 50, 100, 500],
  
        showInvoiceAdditionalChargeForm: false,
        showAddDAToInvoiceForm: false,
        isEditing: false,
        selectedDaId: null,
        selectedDaIndex: null,
        selectedChargeIndex: null,
        selectedChargeData: {},
        allChargeList: [],
        customerInvoiceDas: [],
        headers: [
          {
            headerName: "DA No",
            field: "da_no",
            cellStyle: (params) => {
              if (params.data && params.data.charge_type != "Other") {
                return { color: "#f07025" };
              }
            },
          },
          {
            headerName: "Job Type",
            field: "job_type",
            cellStyle: (params) => {
              if (params.data && params.data.charge_type != "Other") {
                return { color: "#f07025" };
              }
            },
          },
          {
            headerName: "Charge",
            field: "charge_title",
            cellStyle: (params) => {
              if (params.data && params.data.charge_type != "Other") {
                return { color: "#f07025" };
              }
            },
          },
  
          {
            headerName: "Description",
            field: "description",
            cellStyle: (params) => {
              if (params.data && params.data.charge_type != "Other") {
                return { color: "#f07025" };
              }
            },
          },
          {
            headerName: "Revenue Gl No",
            field: "gl_no",
            cellStyle: (params) => {
              if (params.data && params.data.charge_type != "Other") {
                return { color: "#f07025" };
              }
            },
          },
          {
            headerName: `Rate (${this.$globals.currency})`,
            field: "rate",
            cellStyle: (params) => {
              if (params.data && params.data.charge_type != "Other") {
                return { color: "#f07025" };
              }
            },
          },
          {
            headerName: "TAX(%)",
            field: "tax_percentage",
            cellStyle: (params) => {
              if (params.data && params.data.charge_type != "Other") {
                return { color: "#f07025" };
              }
            },
          },
          {
            headerName: `TAX Amount (${this.$globals.currency})`,
            field: "tax_amount",
            cellStyle: (params) => {
              if (params.data && params.data.charge_type != "Other") {
                return { color: "#f07025" };
              }
            },
          },
          {
            headerName: `Amount With Tax (${this.$globals.currency})`,
            field: "amount_with_tax",
            cellStyle: (params) => {
              if (params.data && params.data.charge_type != "Other") {
                return { color: "#f07025" };
              }
            },
          },
          {
            headerName: "Actions",
            field: "actions",
            pinned: "right",
            minWidth: 100,
  
            cellRendererFramework: "AdminInvoiceChargeButton",
          },
        ],
        chargeId: null,
        invoiceChargesTimeout: null,
        // pagination vars
        pageNo: 1,
        itemsPerPage: 100,
        totalInvoiceAdditionalCharge: 100,
        containerCentric: "No",
        filters: {},
  
        // ag-grid vars
        gridApi: null,
        gridColumnApi: null,
        defaultColDef: {
          resizable: true,
          initialWidth: 200,
          wrapHeaderText: true,
          autoHeaderHeight: true,
        },
        gridOptions: {
          onGridSizeChanged: () => {
            this.resizeGrid();
          },
          rowHeight: 35,
          suppressRowClickSelection: true,
          suppressDragLeaveHidesColumns: true,
          enableCellTextSelection: true,
          tooltipShowDelay: 0,
        },
      };
    },
    computed: {
      context() {
        return { parentComponent: this };
      },
      isReadOnly() {
        return this.invoiceDetails && this.invoiceDetails.status == "Accepted";
      },
      offset() {
        return this.itemsPerPage * (this.pageNo - 1);
      },
    },
    methods: {
      onEnter() {
        this.pageNo = 1;
        this.getDeliveryAdviceInvoiceCharges(this.$route.params.id);
      },
      addAdditionalCharges(data) {
        this.selectedChargeData = {};
        this.isEditing = false;
        this.selectedDaId = data.delivery_advice;
        this.showInvoiceAdditionalChargeForm = true;
      },
      updateAdditionalCharges(id) {
        this.chargeId = id;
        this.isEditing = true;
        this.showInvoiceAdditionalChargeForm = true;
      },
      deleteAdditionalCharges(id) {
        if (confirm("Are you sure you want to delete this charge?")) {
          this.$bus.$emit("showLoader", true);
  
          this.$api.customerInvoice
            .deleteSingleCharge(id)
            .then((res) => {
              this.getInvoiceObject(this.$route.params.id);
              this.getDeliveryAdviceInvoiceCharges(this.$route.params.id);
              this.$bus.$emit("showLoader", false);
            })
            .catch((err) => {
              console.error(err);
              this.$bus.$emit("showLoader", false);
            });
        }
      },
  
      removeDA(daId) {
        if (
          confirm("Are you sure you want to remove this DA from the Invoice?")
        ) {
          this.$bus.$emit("showLoader", true);
          this.$api.deliveryAdvice
            .removeDAFromCustomerInvoice(daId)
            .then((res) => {
              this.getInvoiceObject(this.$route.params.id);
              this.getDeliveryAdviceInvoiceCharges(this.$route.params.id);
              this.$bus.$emit("showLoader", false);
            })
            .catch((err) => {
              console.error(err);
              this.$bus.$emit("showLoader", false);
            });
        }
      },
  
      refreshData() {
        this.getInvoiceObject(this.$route.params.id);
        this.getDeliveryAdviceInvoiceCharges(this.$route.params.id);
  
        this.isEditing = false;
        this.selectedDaId = null;
        this.selectedChargeData = {};
      },
  
      partiallyUpdateCustomerInvoice() {
        this.$bus.$emit("showLoader", true);
        const payload = {
          invoice_date: this.invoiceDetails.invoice_date,
          remarks: this.invoiceDetails.remarks,
        };
        this.$api.customerInvoice
          .partiallyUpdateCustomerInvoice(this.invoiceDetails.id, payload)
          .then((res) => {
            this.$bus.$emit("showToastMessage", {
              message: "Invoice Updated",
              color: "success",
            });
            this.$bus.$emit("showLoader", false);
          })
          .catch((err) => {
            console.error(err);
            this.$bus.$emit("showLoader", false);
          });
      },
      getInvoiceObject(id) {
        this.$bus.$emit("showLoader", true);
        this.$api.customerInvoice
          .getCustomerInvoiceGroup(id)
          .then((res) => {
            this.invoiceDetails = res.data;
            this.$bus.$emit("showLoader", false);
          })
          .catch((err) => {
            console.error(err);
            this.$bus.$emit("showLoader", false);
          });
      },
      getDeliveryAdviceInvoiceCharges(id, params = {}) {
        if (this.invoiceChargesTimeout) {
          clearTimeout(this.invoiceChargesTimeout);
        }
        this.invoiceChargesTimeout = setTimeout(() => {
          params = {
            ...params,
            ...this.filters,
            offset: this.offset,
            limit: this.itemsPerPage,
            customer_invoice: id,
          };
          this.$bus.$emit("showLoader", true);
          this.$api.customerInvoice
            .getCustomerInvoiceAdditionalChargeList(params)
            .then((res) => {
              this.invoiceAdditionalChargeList = res.data;
              this.totalInvoiceAdditionalCharge = res.count;
              this.$bus.$emit("showLoader", false);
            })
            .catch((err) => {
              console.error(err);
              this.$bus.$emit("showLoader", false);
            });
        }, 100);
      },
      resizeGrid() {
        if (this.gridApi) {
          setTimeout(() => {
            this.gridApi.sizeColumnsToFit();
          }, 100);
        }
      },
      onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.resizeGrid();
      },
      itemsPerPageChanged(e) {
        this.pageNo = 1;
        this.itemsPerPage = e;
        this.getDeliveryAdviceInvoiceCharges(this.$route.params.id);
      },
      prevPage() {
        this.pageNo--;
        this.getDeliveryAdviceInvoiceCharges(this.$route.params.id);
      },
      nextPage() {
        this.pageNo++;
        this.getDeliveryAdviceInvoiceCharges(this.$route.params.id);
      },
      setBackRoute() {
        if ("backRoute" in this.$route.query) {
          this.backRoute.path = this.$route.query.backRoute;
        }
        if ("active_tab" in this.$route.query) {
          if (!this.backRoute.query) {
            this.backRoute.query = {};
          }
          this.backRoute.query.active_tab = this.$route.query.active_tab;
        }
      },
    },
  
    mounted() {
      if (
        "id" in this.$route.params &&
        typeof parseInt(this.$route.params.id) == typeof 1
      ) {
        // this.getChargeList();
        this.getDeliveryAdviceInvoiceCharges(this.$route.params.id);
        this.getInvoiceObject(this.$route.params.id);
      }
      this.setBackRoute();
    },
  };
  </script>
  