<template>
  <v-btn x-small fab icon text @click="removeRow">
    <v-icon color="red">mdi-delete</v-icon>
  </v-btn>
</template>

<script>
export default {
  methods: {
    removeRow() {
      this.params.context.parentComponent.removeErrorRow(
        this.params.node.rowIndex,
        this.params.data.oldIndex
      );
    },
  },
};
</script>

