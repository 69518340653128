import Contract from "@/pages/customer/contract/Contract.vue";

const contractRoutes = [
  {
    path: "contract",
    name: "app-customer-contract",
    component: Contract,
  },
]

export default contractRoutes