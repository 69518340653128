<template>
  <v-dialog width="500" v-model="showContractForm">
    <v-card>
      <v-card-title class="secondary white--text px-4 py-2">
        <span class="text-capitalize">
          {{ editMode ? "Update" : "Add" }} Tariffs
        </span>

        <v-spacer></v-spacer>

        <v-btn icon @click="resetForm(), (showContractForm = false)">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-2">
        <v-form
          ref="CustomerContractCreationForm"
          v-if="showContractForm"
          v-model="isValid"
        >
          <v-row class="my-0">
            <v-col cols="12" class="Form-Columns">
              <DateField
                :inputProps="{
                  dense: true,
                  label: 'Start Date *',
                  'hide-details': 'auto',
                  rules: [(val) => !!val || 'Start Date is Required!'],
                  'error-messages': formErrors.start_date,
                }"
                :min="today"
                required
                v-model="childFormDetails.start_date"
                @input="delete formErrors.start_date"
              />
            </v-col>

            <v-col cols="12" class="Form-Columns">
              <InputField
                type="text"
                label="Customer Remarks *"
                required
                :rules="[(val) => !!val || 'Remarks is Required!']"
                hide-details="auto"
                v-model="childFormDetails.customer_remarks"
                :error-messages="formErrors.customer_remarks"
                @input="delete formErrors.customer_remarks"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          depressed
          :disabled="!isValid"
          @click="submitForm"
          >Submit</v-btn
        >
        <v-btn class="ml-3" color="secondary" depressed @click="resetForm">
          Reset
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import SelectField from "@/components/FormBaseComponents/SelectField.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";

export default {
  components: {
    DateField,
    InputField,
    SelectField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showContractForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    childFormDetails: {
      get() {
        return this.formData;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    return {
      formErrors: {},
      isValid: true,
      isTextInput: false,
      today: new Date().toISOString().slice(0, 10),
    };
  },
  methods: {
    submitForm() {
      this.$emit("createContract");
    },
    resetForm() {
      delete this.childFormDetails.start_date;
      delete this.childFormDetails.customer_remarks;
      const form = this.$refs.CustomerContractCreationForm;
      if (form) {
        form.reset();
      }
    },
  },
};
</script>
