import Blank from "@/layouts/blank.vue"

import CustomerRoutes from "@/router/admin/companyManagement/customer"
import TransporterRoutes from "@/router/admin/companyManagement/transporter"

const companyManagementRoutes = [
  {
    path: "company-management",
    name: "app-admin-company-management",
    component: Blank,
    children: [
      ...CustomerRoutes,
      ...TransporterRoutes,
    ]
  },
]

export default companyManagementRoutes